import useRouter from 'Commons/components/business/provider/useRouter';

export function withRouter(Component) {
    const CustomWithRouter = (props) => {
        const { match: { path } = {} } = props || {};
        const { location, match, history } = useRouter(path);

        return <Component {...props} history={history} location={location} match={match} />;
    };

    CustomWithRouter.propTypes = { match: PropTypes.string };

    return CustomWithRouter;
}
