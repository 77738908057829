import { SOURCE, WS_EVENT_TYPE } from 'Commons/config/constants/Constants';
import { RESOURCE_NAME_TO_STORE_KEY } from 'Commons/components/business/basecomponent/config/Constants';
import { convertToNumber } from 'Commons/helpers/utils/Utils';

const isUnitUpdated = (wsMessage, classRef) => {
    const { resourceId, eventType, resourceName } = wsMessage;
    const { formProps: { values: { unitId } = {} } = {}, state: { isButtonLoading } = {} } = classRef;
    if (RESOURCE_NAME_TO_STORE_KEY[resourceName] === SOURCE.unit.value
        && unitId && convertToNumber(unitId) === resourceId
        && eventType === WS_EVENT_TYPE.UPDATED
        && !isButtonLoading) {
        return true;
    }
    return false;
};

const isConfigurationChanged = (wsMessage, classRef) => {
    const { resourceName, eventType } = wsMessage;
    const { state: { isButtonLoading } = {} } = classRef;
    if (['financial_configuration', 'rental_configuration'].includes(resourceName)
        && eventType === WS_EVENT_TYPE.UPDATED
        && !isButtonLoading) {
        return true;
    }
    return false;
};


const isTenantInfoUpdated = (wsMessage, tenantId, isButtonLoading) => {
    const { resourceId, eventType, resourceName } = wsMessage;
    if (RESOURCE_NAME_TO_STORE_KEY[resourceName] === SOURCE.tenant.value
        && tenantId && convertToNumber(tenantId) === resourceId
        && eventType === WS_EVENT_TYPE.UPDATED
        && !isButtonLoading) {
        return true;
    }
    return false;
};

export { isUnitUpdated, isConfigurationChanged, isTenantInfoUpdated };
