import { RESERVATION_STATUS, SOURCE, STATUS, UNIT_AGGREGATION, VIEW } from 'Commons/config/constants/Constants';
import {
    CONFIG_ENDPOINTS,
    getEditEndpoints,
    RESERVATION_ENDPOINTS,
    TENANT_ENDPOINTS,
    UNIT_ENDPOINTS,
} from 'Commons/config/constants/Endpoints';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import STORE_KEY from 'Commons/redux/config/StoreKey';
import RequestTypes from 'Commons/config/constants/RequestTypes';

const callApi = (onAction, endPoint, body, callback = () => {}, method = 'read',
    methodType = 'POST', sendCustomHeaderOptions = true) => {
    if (onAction) {
        onAction({
            config: [{
                api: {
                    method,
                    action: {
                        methodType,
                        endPoint,
                        body,
                        ...sendCustomHeaderOptions && {
                            customHeaderOptions: {
                                type: 'store',
                                key: 'facility',
                            },
                        },
                    },
                },
                callback,
            }],
        });
    }
};

const getUnitTypeApiConfig = (facilityId) => {
    const apiConfig = {
        api: {
            dynamic: {
                endPoint: CONFIG_ENDPOINTS.search,
                body: {
                    view: VIEW.detail.value,
                    filter: [{
                        terms: {
                            configType: ['Unit_Type'],
                            entityType: ['Facility'],
                            entityId: [facilityId],
                        },
                    }],
                },
            },
        },
    };
    return {
        search: apiConfig,
        autoSuggest: apiConfig,
        sendCustomHeader: false,
    };
};

const defaultApiConfig = (source, filter, view = VIEW.detail.value) => ({
    search: {
        api: {
            dynamic: {
                endPoint: `${source}/search`,
                body: { view, ...(filter && { filter }) },
            },
        },
    },
    autoSuggest: {
        api: {
            dynamic: {
                endPoint: `${source}/autosuggest`,
                body: { view, ...(filter && { filter }) },
            },
        },
    },
});

const getSearchConfig = (source, filter) => {
    if (source === SOURCE.unit.value) {
        return defaultApiConfig(source, filter);
    }
    return undefined;
};


const fetchUnits = (fetchLazy, level, requestType = RequestTypes.INIT) => {
    const options = level
        ? {
            api: {
                dynamic: {
                    body: {
                        filter: [
                            { terms: { bev: { level: [level] } } }, // Fetching units for first configured level
                        ],
                        size: 1000,
                    },
                },
            },
            requestType,
        } : undefined;

    if (fetchLazy) {
        fetchLazy('read', options);
    }
};

const fetchTenant = (onAction, id, callback, view = VIEW.mini.value, size, fetchInBatch = false) => {
    if (fetchInBatch) {
        const idList = Array.isArray(id) ? id : [id];
        const idSet = new Set(idList);
        const updatedIdList = [...idSet];

        let currentIndex = 0;
        const batchSize = 1000;

        const fetchNextTenantBatch = () => {
            const remainingIds = updatedIdList.slice(currentIndex, currentIndex + batchSize);
            if (remainingIds.length > 0) {
                const body = {
                    view,
                    filter: [{ terms: { unit_list: { id: remainingIds } } }],
                    size: batchSize,
                };
                callApi(onAction, TENANT_ENDPOINTS.search, body, (apiError, response) => {
                    callback(apiError, response);
                    currentIndex += batchSize;
                    fetchNextTenantBatch();
                });
            }
        };
        fetchNextTenantBatch();
    } else {
        const idList = Array.isArray(id) ? id : [id];
        const body = {
            view,
            filter: [{ terms: { unit_list: { id: idList } } }],
            ...(size && { size }),
        };
        callApi(onAction, TENANT_ENDPOINTS.search, body, callback);
    }
};

const fetchUnitCountByLevelData = (onAction, callback) => {
    const body = { aggregation_name: UNIT_AGGREGATION.ALL_UNITS_COUNT_BY_LEVEL };
    callApi(onAction, UNIT_ENDPOINTS.aggregation, body, callback);
};

const fetchReservation = (onAction, unitId, callback, size, fetchInBatch = false) => {
    if (fetchInBatch) {
        const idList = Array.isArray(unitId) ? unitId : [unitId];
        const idSet = new Set(idList);
        const updatedIdList = [...idSet];

        let currentIndex = 0;
        const batchSize = 1000;

        const fetchNextReservationBatch = () => {
            const remainingIds = updatedIdList.slice(currentIndex, currentIndex + batchSize);
            if (remainingIds.length > 0) {
                const body = {
                    view: VIEW.list.value,
                    filter: [{
                        terms: {
                            unit_id: idList,
                            status: [RESERVATION_STATUS.Reserved],
                        },
                    }],
                    sort: [
                        { rentalInfo: { moveInDate: { order: 'desc' } } },
                        { reservationDate: { order: 'desc' } },
                    ],
                    size: batchSize,
                };
                callApi(onAction, RESERVATION_ENDPOINTS.search, body, (apiError, response) => {
                    callback(apiError, response);
                    currentIndex += batchSize;
                    fetchNextReservationBatch();
                });
            }
        };
        fetchNextReservationBatch();
    } else {
        const idList = Array.isArray(unitId) ? unitId : [unitId];
        const body = {
            view: VIEW.list.value,
            filter: [{
                terms: {
                    unit_id: idList,
                    status: [RESERVATION_STATUS.Reserved],
                },
            }],
            sort: [
                { rentalInfo: { moveInDate: { order: 'desc' } } },
                { reservationDate: { order: 'desc' } },
            ],
            ...(size && { size }),
        };
        callApi(onAction, RESERVATION_ENDPOINTS.search, body, callback);
    }
};

const fetchPlaceholders = (onAction, level, facilityId, requestType = RequestTypes.INIT, callback) => {
    const body = {
        size: 1000,
        filter: [{
            terms: {
                configType: [CONFIG_TYPE.PLACEHOLDER],
                entityType: ['Facility'],
                entityId: [facilityId],
                value: {
                    bev: { level: [level] },
                    status: [STATUS.Active.value],
                },
            },
        }],
    };

    if (onAction) {
        onAction({
            config: [{
                api: { dynamic: { body } },
                store: { key: STORE_KEY.PLACEHOLDER },
                requestType,
                callback,
            }],
        });
    }
};

const saveUnitsData = (onAction, body, callback) => {
    callApi(onAction, UNIT_ENDPOINTS.bulk, body, callback);
};
const savePlaceholdersData = (onAction, body, callback) => {
    callApi(onAction, CONFIG_ENDPOINTS.placeholderBulk, body, callback);
};

const saveLevelData = (onAction, configId, levels, callback) => {
    const endPoint = `${SOURCE.configuration.value}/${CONFIG_TYPE.LEVEL}${configId ? `/${configId}` : ''}`;
    const method = configId ? 'PUT' : 'POST';
    const body = { value: { levels } };
    callApi(onAction, endPoint, body, callback, undefined, method);
};

const saveZoomValue = (onAction, configId, bevZoomValue, callback) => {
    let endPoint;
    let method;
    const body = { value: { bevZoomValue } };
    if (!configId) {
        endPoint = `${SOURCE.configuration.value}/${CONFIG_TYPE.FACILITY_GENERAL}`;
    } else {
        endPoint = getEditEndpoints(configId, `${SOURCE.configuration.value}/${CONFIG_TYPE.FACILITY_GENERAL}`);
        method = 'PUT';
    }
    callApi(onAction, endPoint, body, callback, undefined, method);
};

const refreshColor = (onAction, callback) => {
    const endPoint = UNIT_ENDPOINTS.syncColor;
    callApi(onAction, endPoint, {}, callback);
};

const deactivatePlaceholder = (onAction, configId, callback) => {
    callApi(onAction, CONFIG_ENDPOINTS.deactivate(CONFIG_TYPE.PLACEHOLDER, configId), {}, callback);
};

export {
    getUnitTypeApiConfig, saveUnitsData, fetchUnits, fetchTenant,
    saveLevelData, fetchUnitCountByLevelData, saveZoomValue, fetchReservation, refreshColor,
    savePlaceholdersData, getSearchConfig, fetchPlaceholders, deactivatePlaceholder,
};
