import { useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Tooltip as MuiTooltip, makeStyles }
from 'Generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils';
import tooltipStyle from '../styles/TooltipStyle';
import { EVENT, DISABLED_LISTENERS, DEFAULT_TRANSITION } from '../config/Constants';

const useStyles = makeStyles(tooltipStyle, { name: 'Tooltip' });

const Tooltip = ({
    open,
    event,
    title,
    onOpen,
    onClose,
    children,
    disablePortal,
    trackValue,
    tooltipTargetClassName,
    ...props
}) => {
    const { classes: _, ...others } = props;
    const { deviceInfo: { isDesktop } = {} } = window;
    const classes = useStyles(props);
    const [isOpen, setIsOpen] = useState(trackValue ? open : false);
    const isEventOnClick = useMemo(() => event === EVENT.ON_CLICK || !isDesktop, [isDesktop]);

    const handleOpen = (e) => {
        if (trackValue) {
            setIsOpen(isEventOnClick ? !isOpen : true);
        }
        onOpen(e);
    };

    const handleClose = (e) => {
        if (trackValue) {
            setIsOpen(false);
        }
        onClose(e);
    };

    let eventProp = {};
    let listenersToDisable = {};
    if (isEventOnClick) {
        eventProp = { onClick: handleOpen };
        listenersToDisable = DISABLED_LISTENERS;
    }

    const renderTooltip = () => (
        <MuiTooltip
            arrow
            interactive
            title={title}
            open={trackValue ? isOpen : open}
            onOpen={handleOpen}
            onClose={handleClose}
            classes={{
                arrow: classes.arrow,
                popper: classes.arrowShadow,
                tooltip: classes.lightTooltip,
            }}
            PopperProps={{ disablePortal }}
            TransitionProps={DEFAULT_TRANSITION}
            {...listenersToDisable}
            {...others}
        >
            <div
                className={clsx(classes.tooltipTarget, tooltipTargetClassName)}
                {...eventProp}
            >
                {children}
            </div>
        </MuiTooltip>
    );

    return (
        isEventOnClick
            ? (
                // Need ClickAwayListener for click events only, hover is handled implicitly by MuiTooltip
                <ClickAwayListener
                    mouseEvent={(trackValue ? isOpen : open) && EVENT.ON_CLICK}
                    onClickAway={handleClose}
                >
                    {
                        disablePortal
                            ? (
                                <div className={classes.root}>
                                    {renderTooltip()}
                                </div>
                            )
                            : renderTooltip()
                    }
                </ClickAwayListener>
            )
            : renderTooltip()
    );
};


Tooltip.propTypes = {
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    classes: PropTypes.object,
    trackValue: PropTypes.bool,
    disablePortal: PropTypes.bool,
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    tooltipTargetClassName: PropTypes.string,
    event: PropTypes.oneOf([EVENT.ON_HOVER, EVENT.ON_CLICK]),
};

Tooltip.defaultProps = {
    open: false,
    onOpen: () => {},
    onClose: () => {},
    trackValue: true,
    event: EVENT.ON_HOVER,
};

export default memo(Tooltip);
