import {
    Stepper as MUIStepper, Step, StepLabel,
    makeStyles, Grid, StepContent,
} from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import Page from 'Commons/components/business/page/components/Page';
import { clsx } from 'Commons/helpers/utils/clsx';
import { memo, useState } from 'react';
import { EMPTY_FUNC, EMPTY_OBJECT } from 'Commons/config/constants/Constants';
import { isPrimitiveType } from 'Commons/helpers/api/Formatter';
import stepperStyle from '../styles/StepperStyle';
import { STEPPER_ORIENTATION } from '../config/Constants';

const useStyles = makeStyles(stepperStyle, { name: 'Stepper' });

//  TODO: Make it more generic and config driven
const Stepper = ({
    steps, activeStep, onStepChange, stepCompletionConfig, fullHeight, ContainerProps, detachStepperBody,
    StepperProps, StepProps, StepLabelProps, StepBodyProps, unmountStepContentOnExit, trackValue,
    updateStepCompletionConfig, ...props
}) => {
    const classes = useStyles(props);
    const [currentStep, setCurrentStep] = useState(activeStep || 0);

    const onClickStepLabel = (index) => {
        if (trackValue) setCurrentStep(index);
        if (onStepChange) onStepChange(index);
    };

    const onNextStep = () => {
        const stepValue = trackValue ? currentStep : activeStep;
        if (stepValue < steps.length) {
            onClickStepLabel(stepValue + 1);
            updateStepCompletionConfig(stepValue + 1);
        }
    };

    const onBack = () => {
        const stepValue = trackValue ? currentStep : activeStep;
        if (stepValue > 0) onClickStepLabel(stepValue - 1);
    };

    const renderStepBody = () => steps.map(({ label, component: Component = Grid } = {}, index) => {
        const { classes: ignore, ...restProps } = props;
        return (
            !unmountStepContentOnExit || (activeStep === index)
                ? (
                    <Grid
                        container
                        key={`${label}-${index}`}
                        className={clsx({ [classes.hidden]: index !== activeStep })}
                    >
                        <Component
                            onBack={onBack}
                            stepIndex={index}
                            activeStep={activeStep}
                            onNextStep={onNextStep}
                            key={`${label}-${index}`}
                            stepCompletionConfig={stepCompletionConfig}
                            {...restProps}
                        />
                    </Grid>
                )
                : null
        );
    });

    const onClickStepIcon = (index) => {
        const {
            [index]: { isCompleted: isTargetStepCompleted = false } = {},
            [index - 1]: { isCompleted: isPrevStepCompleted = false } = {},
            [activeStep]: { isCompleted: isActiveStepCompleted = false } = {},
        } = stepCompletionConfig;
        if (isTargetStepCompleted
            || (index > activeStep && isActiveStepCompleted && isPrevStepCompleted)
            || (index < activeStep)) {
            onClickStepLabel(index);
        }
    };

    return (
        <>
            <Page
                fullHeight={fullHeight}
                {...ContainerProps}
            >
                <MUIStepper
                    activeStep={trackValue ? currentStep : activeStep}
                    {...StepperProps}
                >
                    {
                        steps.map(({ label } = {}, index) => {
                            const { [index]: { isCompleted = false } = {} } = stepCompletionConfig;
                            return (
                                <Step
                                    key={label}
                                    onClick={() => onClickStepIcon(index)}
                                    {...StepProps}
                                >
                                    <StepLabel
                                        completed={isCompleted}
                                        {...StepLabelProps}
                                    >
                                        {
                                            isPrimitiveType(label)
                                                ? (
                                                    <Typography
                                                        variant="subtitle2"
                                                        fontFamily="Roboto"
                                                        className={classes.stepText}
                                                    >
                                                        {label}
                                                    </Typography>
                                                )
                                                : { label }
                                        }

                                    </StepLabel>
                                    {
                                        !detachStepperBody
                                        && (
                                            <StepContent {...StepBodyProps}>
                                                {renderStepBody()}
                                            </StepContent>
                                        )
                                    }
                                </Step>
                            );
                        })
                    }
                </MUIStepper>
            </Page>
            {
                detachStepperBody
                && (
                    <Page bodyClassName={classes.pageBody} {...StepBodyProps}>
                        {renderStepBody()}
                    </Page>
                )}
        </>
    );
};

Stepper.propTypes = {
    classes: PropTypes.object,
    fullHeight: PropTypes.bool,
    trackValue: PropTypes.bool,
    onStepChange: PropTypes.func,
    StepBodyProps: PropTypes.object,
    ContainerProps: PropTypes.object,
    detachStepperBody: PropTypes.bool,
    steps: PropTypes.array.isRequired,
    stepCompletionConfig: PropTypes.object,
    activeStep: PropTypes.number.isRequired,
    unmountStepContentOnExit: PropTypes.bool,
    updateStepCompletionConfig: PropTypes.func,
    StepperProps: PropTypes.shape({
        classes: PropTypes.object,
        nonLinear: PropTypes.bool,
        connector: PropTypes.element,
        component: PropTypes.elementType,
        alternativeLabel: PropTypes.bool,
        orientation: PropTypes.oneOf([STEPPER_ORIENTATION.HORIZONTAL, STEPPER_ORIENTATION.VERTICAL]),
    }),
    StepProps: PropTypes.shape({
        last: PropTypes.bool,
        index: PropTypes.bool,
        active: PropTypes.bool,
        expanded: PropTypes.bool,
        disabled: PropTypes.bool,
        completed: PropTypes.bool,
        classes: PropTypes.object,
        component: PropTypes.elementType,
    }),
    StepLabelProps: PropTypes.shape({
        icon: PropTypes.node,
        error: PropTypes.bool,
        optional: PropTypes.node,
        classes: PropTypes.object,
        StepIconProps: PropTypes.object,
        stepIconComponent: PropTypes.elementType,
        slotProps: PropTypes.shape({ label: PropTypes.object }),
        componentsProps: PropTypes.shape({ label: PropTypes.object }),
    }),
};

Stepper.defaultProps = {
    fullHeight: false,
    trackValue: false,
    StepProps: EMPTY_OBJECT,
    detachStepperBody: false,
    StepperProps: EMPTY_OBJECT,
    StepBodyProps: EMPTY_OBJECT,
    StepLabelProps: EMPTY_OBJECT,
    ContainerProps: EMPTY_OBJECT,
    unmountStepContentOnExit: false,
    stepCompletionConfig: EMPTY_OBJECT,
    updateStepCompletionConfig: EMPTY_FUNC,
};

export default memo(Stepper);
