import { useEffect, useMemo, useRef, useState } from 'react';
import { FormTextField } from 'Generic/textfield/components/TextField';
import { SM_5, SM_6_XS_6 } from 'Commons/config/constants/GridSpacingConstants';
import {
    IS_MAX_LENGTH_20, IS_MAX_LENGTH_10, IS_MAX_LENGTH_50,
    IS_MIN_LENGTH_3, IS_MAX_LENGTH_5, IS_POSITIVE_NUMERIC,
}
from 'Commons/config/constants/Validators';
import { isValidCardNumber } from 'Commons/helpers/utils/validator/Validator';
import { Button, Grid, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import Typography from 'Generic/typography/components/Typography';
import { clsx } from 'Commons/helpers/utils/clsx';
import { removeWhiteSpaceAndSpecialChars } from 'Commons/helpers/utils/Utils';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import paymentMethodStyle from '../styles/PaymentMethodStyle';

const useStyles = makeStyles(paymentMethodStyle, { name: 'paymentMethodStyle' });

const LeftLabel = ({ children, variant, ...props }) => (
    <Typography variant={variant} {...props}>{children}</Typography>
);

LeftLabel.propTypes = { children: PropTypes.node, variant: PropTypes.string };
LeftLabel.defaultProps = { variant: 'caption' };

// TODO: Change the name to Card, since we'll be supporting Debit Card in future
const CreditCard = ({
    ledgerIndex, disabled, baseFormFieldName, needCVV, isInEditMode, skipFieldsValidation,
    tenantInfo: { name: { firstName, lastName } = {}, address: { line1, zipCode } = {} } = {},
    formProps: {
        setFieldValue,
        initialValues: { cardInfo: { expirationDate: { month: initialMonth, year: initialYear } = {} } = {} } = {},
        values: { cardInfo: { expirationDate: { month: editedMonth, year: editedYear } = {} } = {} } = {},
        touched: {
            cardInfo: {
                cvvOrPin: cvvTouched = false,
                expirationDate: { month: monthTouched = false, year: yearTouched = false } = {},
            } = {},
        } = {},
        errors: {
            cardInfo: {
                cvvOrPin: cvvErrorText = '',
                expirationDate: { month: monthErrorText = '', year: yearErrorText = '' } = {},
            } = {},
        },
    } = {},
    formProps,
    ...props
}) => {
    const classes = useStyles(props);

    const expirationYearRef = useRef();
    const currentExpirationDate = useRef({ monthOfExpiration: editedMonth, yearOfExpiration: editedYear });
    const { current: { monthOfExpiration = '', yearOfExpiration = '' } = {} } = currentExpirationDate;

    const [{ cvvError, monthError, yearError }, setErrorState] = useState(
        { cvvError: '', monthError: '', yearError: '' },
    );

    const hideError = useMemo(() => baseFormFieldName === 'cardInfo' && editedMonth === initialMonth
        && editedYear === initialYear,
    [editedYear, editedMonth, baseFormFieldName]);

    // const dateValidator = useMemo(() => (
    //     baseFormFieldName === 'cardInfo'
    //     && (!skipFieldsValidation || (skipFieldsValidation && editedMonth && editedYear))
    //         ? { isExpiryLessThanToday: { value: { month: monthOfExpiration, year: yearOfExpiration } } }
    //         : {}
    // ), [monthOfExpiration, yearOfExpiration, baseFormFieldName]);

    const {
        expirationYearInputProps, inputClasses, cardNumberValidator, formFieldPrefix,
        zipCodeInputProps, cvvValidator,
    } = useConstructor(() => {
        const inputClassesToApply = { className: classes.largeFont };
        return ({
            inputClasses: inputClassesToApply,
            cardNumberValidator: { ...isInEditMode ? { ...IS_MAX_LENGTH_20, isValidCardNumber } : {} },
            formFieldPrefix: baseFormFieldName || `ledger.${ledgerIndex}.financials.cardInfo`,
            expirationYearInputProps: { ...inputClassesToApply, ref: expirationYearRef },
            zipCodeInputProps: { className: classes.leftAlignText },
            cvvValidator: { ...IS_MAX_LENGTH_5, ...IS_MIN_LENGTH_3, ...IS_POSITIVE_NUMERIC },
        });
    });

    useEffect(() => {
        setErrorState({
            monthError: monthTouched ? monthErrorText : undefined,
            yearError: yearTouched ? yearErrorText : undefined,
            cvvError: cvvTouched || (!cvvTouched && cvvErrorText !== localisable.isRequired)
                ? cvvErrorText : undefined,
        });
    }, [cvvErrorText, monthErrorText, yearErrorText]);

    const onChangeExpirationMonth = (_, date) => {
        currentExpirationDate.current = { monthOfExpiration: Number(date), yearOfExpiration };
        if (date.length === 2) {
            expirationYearRef.current.focus();
        }
    };

    const onChangeExpirationYear = (_, date) => {
        currentExpirationDate.current = { yearOfExpiration: Number(`20${date}`), monthOfExpiration };
        expirationYearRef.current.focus();
    };

    const onChangeCardNumber = (_, value) => {
        const trimmedCardNumber = removeWhiteSpaceAndSpecialChars(value);
        setFieldValue(`${formFieldPrefix}.maskedCardNumber`, trimmedCardNumber);
    };

    const setBillingDetails = () => {
        const tenantName = firstName ? `${firstName} ${lastName}` : lastName;
        setFieldValue(`${formFieldPrefix}.billingInfo.name`, tenantName);
        if (line1) {
            setFieldValue(`${formFieldPrefix}.billingInfo.address.line1`, line1);
        }
        if (zipCode) {
            setFieldValue(`${formFieldPrefix}.billingInfo.address.zipCode`, zipCode);
        }
    };

    return (
    <>
        <FormTextField
            name={`${formFieldPrefix}.maskedCardNumber`}
            placeholder={localisable.exampleCreditCardNumber}
            autoComplete="new-password"
            // required={!!formFieldPrefix && !skipFieldsValidation}
            noIndicatorWrapper
            fullWidth
            validate={cardNumberValidator}
            requiredPath="ledger.financials.cardInfo.maskedCardNumber"
            disabled={disabled}
            formVariant={VARIANT.INLINE}
            inputProps={inputClasses}
            className={`${classes.placeholderAlign} ${classes.placeholderFontSize}`}
            onChange={onChangeCardNumber}
        />

        <Grid container justify="space-between" className={classes.expiryCvvContainer}>
            <Grid container direction="column" item xs={8} md={7}>
                <Grid container alignItems="center">
                    <FormTextField
                        name={`${formFieldPrefix}.expirationDate.month`}
                        placeholder={localisable.monthFormat}
                        // required={!!formFieldPrefix && !skipFieldsValidation}
                        noIndicatorWrapper
                        format="MM"
                        additionalIcon={
                            { left: <LeftLabel disabled={disabled}>{localisable.creditCardExpiry}</LeftLabel> }
                        }
                        requiredPath="ledger.financials.cardInfo.expirationDate"
                        disabled={disabled}
                        addon={{ end: null }}
                        noGrid
                        hideErrorText
                        width={32}
                        className={classes.placeholderAlign}
                        containerClassName={classes.autoWidth}
                        inputProps={inputClasses}
                        FormControlProps={{ fullWidth: false }}
                        onChange={onChangeExpirationMonth}
                    />
                    <div className={clsx(classes.angledLine, disabled && classes.disabled)} />
                    <FormTextField
                        name={`${formFieldPrefix}.expirationDate.year`}
                        placeholder={localisable.yearFormat}
                        // required={!!formFieldPrefix && !skipFieldsValidation}
                        format="YY"
                        requiredPath="ledger.financials.cardInfo.expirationDate"
                        disabled={disabled}
                        addon={{ end: null }}
                        noGrid
                        hideErrorText
                        width={32}
                        className={classes.placeholderAlign}
                        containerClassName={classes.autoWidth}
                        noIndicatorWrapper
                        inputProps={expirationYearInputProps}
                        FormControlProps={{ fullWidth: false }}
                        onChange={onChangeExpirationYear}
                    />
                </Grid>
                {
                    (!hideError && (monthError || yearError))
                        && (
                            <Grid>
                                <Typography variant="body2" className={classes.error}>
                                    {monthError || yearError}
                                </Typography>
                            </Grid>
                        )
                }
            </Grid>
            {
                needCVV
                && (
                    <FormTextField
                        name={`${formFieldPrefix}.cvvOrPin`}
                        additionalIcon={
                            { left: <LeftLabel disabled={disabled}>{localisable.creditCardCvv}</LeftLabel> }
                        }
                        requiredPath="ledger.financials.cardInfo.cvvOrPin"
                        disabled={disabled}
                        autoComplete="new-password"
                        type="number"
                        formVariant={VARIANT.INLINE}
                        containerClassName={classes.autoWidth}
                        validate={cvvValidator}
                        hideErrorText
                        noGrid
                        width={60}
                        inputProps={inputClasses}
                        FormControlProps={{ fullWidth: false }}
                    />
                )
            }
            {
                (needCVV && cvvError && !monthError && !yearError)
                    && (
                        <Grid container justify="flex-end">
                            <Grid container item xs={6}>
                                <Typography variant="body2" className={clsx(classes.cvvError, classes.error)}>
                                    {cvvError}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
            }
        </Grid>

        <Grid container>
            <FormTextField
                name={`${formFieldPrefix}.billingInfo.name`}
                placeholder={localisable.creditCardBillingName}
                fullWidth
                validate={IS_MAX_LENGTH_50}
                GridProps={SM_6_XS_6}
                noIndicatorWrapper
                requiredPath="ledger.financials.cardInfo.billingInfo.name"
                disabled={disabled}
                formVariant={VARIANT.INLINE}
            />

            <FormTextField
                name={`${formFieldPrefix}.billingInfo.address.line1`}
                placeholder={localisable.addressLine1}
                fullWidth
                noIndicatorWrapper
                requiredPath="ledger.financials.cardInfo.billingInfo.address"
                disabled={disabled}
                formVariant={VARIANT.INLINE}
            />

            <Grid container alignItems="center" justify="space-between">

                <FormTextField
                    name={`${formFieldPrefix}.billingInfo.address.zipCode`}
                    type="number"
                    placeholder={localisable.zipCode}
                    fullWidth
                    validate={IS_MAX_LENGTH_10}
                    GridProps={SM_5}
                    noIndicatorWrapper
                    inputProps={zipCodeInputProps}
                    requiredPath="ledger.financials.cardInfo.billingInfo.address"
                    disabled={disabled}
                    formVariant={VARIANT.LAST_INLINE}
                />
                {
                    isInEditMode
                    && (
                        <Button
                            color="primary"
                            onClick={setBillingDetails}
                            disabled={disabled}
                            cy-id="cp-use-tenant-address"
                        >
                            <Typography variant="body1">{localisable.useTenantAddress}</Typography>
                        </Button>
                    )
                }
            </Grid>
        </Grid>
    </>
    );
};

CreditCard.propTypes = {
    needCVV: PropTypes.bool,
    disabled: PropTypes.bool,
    isInEditMode: PropTypes.bool,
    ledgerIndex: PropTypes.number,
    baseFormFieldName: PropTypes.string,
    skipFieldsValidation: PropTypes.bool,
    formProps: PropTypes.object.isRequired,
    tenantInfo: PropTypes.object.isRequired,
};

CreditCard.defaultProps = { ledgerIndex: 0, skipFieldsValidation: false };

export default CreditCard;
