import { FormTextField } from 'Generic/textfield/components/TextField';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { IS_MAX_LENGTH_20 } from 'Commons/config/constants/Validators';
import paymentMethodStyle from '../styles/PaymentMethodStyle';

const useStyles = makeStyles(paymentMethodStyle, { name: 'Check' });

const Check = ({
    disabled, classes: classesProp,
    mapping: { number: { visibility: numberVisibility = true, ...numberProps } = {} } = {},
}) => {
    const classes = useStyles({ classes: classesProp });
    return (
        <>
            <Grid container justify="center" className={classes.paymentField}>
                {
                    numberVisibility
                    && (
                        <FormTextField
                            noGrid
                            required
                            type="integer"
                            name="paymentNumber"
                            disabled={disabled}
                            validate={IS_MAX_LENGTH_20}
                            className={classes.numberFields}
                            helperText={localisable.checkNumber}
                            placeholder={localisable.checkPlaceHolder}
                            FormHelperTextProps={{ classes: { root: classes.helperText } }}
                            useField
                            {...numberProps}
                        />
                    )
                }
            </Grid>
        </>
    );
};

Check.propTypes = {
    disabled: PropTypes.bool,
    source: PropTypes.string,
    mapping: PropTypes.object,
    classes: PropTypes.object,
};

export default Check;
