export default {
    documentType: [
        'Letter',
        'Nacha_Entries',
        'Nacha_File',
        'Others',
        'Report',
        'Tenant_Photo',
        'Financial_Export',
    ],
    subDocumentType: [
        'Addendum',
        'All_Charges',
        'Cancellation_Letter',
        'Collection_Summary',
        'Contact_History',
        'Contact_Log',
        'Credit_Card_Decline_Letter',
        'Estimate_Quote',
        'Income_Detail',
        'Invoice_All_Charges_Due_Detailed',
        'Invoice_All_Charges_Due_Summarized',
        'Invoice_Pre_Billing_Detailed',
        'Invoice_Pre_Billing_Summarized',
        'Late_Event',
        'Lease_Agreement',
        'Lease_Letter',
        'Ledger_History',
        'Ledger_History',
        'Management_Summary',
        'Move_In_Out_Activity',
        'Move_Out_Document',
        'Move_Out_Document',
        'Net_Change_Of_Account',
        'Occupancy_History',
        'Open_Unit',
        'Others',
        'Overlock_Unlock_Pending',
        'Partial_Payment_Letter',
        'Purchase_Order',
        'Receipt',
        'Receipt_Facility',
        'Rent_Adjustment',
        'Rent_Roll',
        'Reservation_Quote',
        'Retail_Sale',
        'Square_Footage',
        'Super_Summary',
        'Tenant_With_Balance_Due',
        'Thank_You_Letter',
        'Unit_Transfer',
        'Unit_Transfer',
        'Welcome_Letter',
    ],
    documentStatus: [
        'Active',
        'Inactive',
        'Generate_Error',
        'Upload_Error',
        'Delivery_Error',
        'Ready_For_Delivery',
    ],
};
