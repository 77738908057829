import { Component } from 'react';
import { Loader, Grid } from 'Generic/componentlibrary/components/Components';
import Snackbar from 'Generic/snackbar/components/Snackbar';
import { getSnackbarProps } from 'Commons/helpers/utils/Utils';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import { VARIANT } from 'Generic/snackbar/config/Constants';
import Typography from 'Commons/components/generic/typography/components/Typography';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import { camelCase, deCapitalize } from 'Commons/helpers/utils/DataHelpers';
import localisable from '../../../../config/strings/localisable';
import { DEFAULT_CALLBACK_FOR_SNACKBAR_ACTION_UPDATE, EXCEPTION_ERROR_CODE } from '../config/Constants';
import { getStoreKeyToClear } from '../config/Utils';


class BaseComponent extends Component {
    /**
    * @description Override this method to show snackbar when websocket sends a message
    * @param {Object} data Websocket message
    * @param {string} data.eventType Created or Updated
    * @param {string} data.resourceName Created or Updated resource name
    * @param {Number} data.resourceName Created or Updated resource id
    * @param {Object} data.fields Updated Fields (only if event type is updated otherwise empty)
    * */

    onWebSocketMessage = () => { };

    performAdditionalActionOnGlobalMessage = () => { };

    getSnackbarVariant = (errorCode, isError) => {
        if (errorCode === EXCEPTION_ERROR_CODE.NO_DATA_ERROR) {
            return VARIANT.info.value;
        }
        if (isError) {
            return VARIANT.error.value;
        }
        return VARIANT.success.value;
    }

    handleGlobalWsMessage = (message) => {
        const { displayMessage, isError, resourceName = '', errorCode = '' } = message;
        if (!resourceName || resourceName === this.globalWsResource) {
            const variant = this.getSnackbarVariant(errorCode, isError);
            this.setSnackbarProps(true,
                errorCode === EXCEPTION_ERROR_CODE.NO_DATA_ERROR ? localisable.noNewData : displayMessage,
                variant);
            // eslint-disable-next-line react/no-unused-state
            this.setState({ receivedWSMessage: false });
        }
        this.performAdditionalActionOnGlobalMessage(message);
    };

    receiveWSMessage = (message = {}) => {
        const { isGlobal } = message;
        if (isGlobal) {
            this.handleGlobalWsMessage(message);
        } else {
            this.handleWsMessage(message);
        }
    };

    handleWsMessage = (message = {}) => {
        /** Clearing store keys silently. Override this method in only if store key should not be
         * cleared silently (in list) or performing any other operation before showing RTNS message
         */
        const { resourceName } = message;
        const { props: { clearStoreKeys = EMPTY_FUNC }, storesToNotClearSilently = [] } = this;
        if (resourceName) {
            const storeKey = getStoreKeyToClear(message, storesToNotClearSilently);
            if (storeKey) clearStoreKeys([storeKey]);
        }
        this.onWebSocketMessage(message);
    };

    setSnackbarProps = (isSnackbarOpen, title, variant, subtitle, onSnackbarMount, classes, rtnsWithAutoClose) => {
        const { snackbarProps = {} } = this;
        this.snackbarProps = {
            ...snackbarProps,
            ...getSnackbarProps(isSnackbarOpen, title, variant, subtitle, onSnackbarMount, classes),
            rtnsWithAutoClose,
        };
    };

    getSnackbarActions = (isForm = false, message = {}) => {
        const { onRefresh, onKeepCurrentChanges } = this;
        return [
            ...isForm ? [{ label: localisable.keepCurrentChanges, onClick: onKeepCurrentChanges }] : [],
            { label: localisable.refresh, onClick: () => onRefresh(message) },
        ];
    };

    hideSnackbar = () => {
        this.setSnackbarProps();
    };

    /**
     * Removes the "Keep Current Changes" button from the WS snackbar when the user is forced to refresh
     * the component on change of certain fields which was triggered by some other client.
     * @param {Array} nonOverridableFields Fields for which user is forced to refresh
     * @param {Object} wsMessage Websocket message received
     */
    updateSnackbarActionsFor = (nonOverridableFields = [],
        wsMessage = {},
        callback = DEFAULT_CALLBACK_FOR_SNACKBAR_ACTION_UPDATE,
        noActionItem = false) => {
        const { snackbarProps, snackbarProps: { snackbarActions = [] } = {}, getSnackbarActions } = this;
        if (noActionItem) {
            this.snackbarProps = { ...snackbarProps, snackbarActions: [] };
        } else {
            let isNonOverridableFieldChanged = callback(wsMessage);
            const { fields } = wsMessage;

            if (!isNonOverridableFieldChanged) {
                nonOverridableFields.some((eachField) => {
                    if (fields[eachField] !== undefined) { // Checking for undefined values only, because field value can have 0 or false as well
                        isNonOverridableFieldChanged = true;
                    }
                    return isNonOverridableFieldChanged;
                });
            }

            if (isNonOverridableFieldChanged) {
                this.positiveButtonToolTipMessage = localisable.rtnsBlockedSave;
                this.snackbarProps = { ...snackbarProps, snackbarActions: getSnackbarActions(false, wsMessage) };
            } else if (snackbarActions.length === 1) {
                this.snackbarProps = { ...snackbarProps, snackbarActions: getSnackbarActions(true, wsMessage) };
            }
        }
    };

    /**
    * Override this method to handle the refresh of rtns snackbar differently
    * */
    onRefresh = (message = {}) => {
        const { props: { remountComponent, clearStoreKeys = EMPTY_FUNC } = {}, postProcessRefresh } = this;
        const { resourceName, otherStoresToClear = [] } = message;
        this.positiveButtonToolTipMessage = '';
        if (remountComponent) {
            remountComponent();
        }
        if (resourceName || otherStoresToClear) {
            const storeKey = getStoreKeyToClear(message);
            const storesToClear = (otherStoresToClear || []).map((store = '') => {
                if (CONFIG_TYPE[store.toUpperCase()]) return deCapitalize(camelCase(store));
                return store;
            }) || [];
            clearStoreKeys((storeKey ? [storeKey] : []).concat(storesToClear));
        }
        postProcessRefresh(message);
    };

    postProcessRefresh = () => { };

    onKeepCurrentChanges = () => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ receivedWSMessage: false });
    };

    /**
    * evaluates if component is loaded
    * return true/false
    * */
    isLoading() {
        const { loading } = this.props;
        return loading;
    }

    /**
    * evaluates if any error exists
    * return true/false
    * */
    hasError() {
        const { isError } = this.props;
        return isError;
    }


    /**
    * evaluates if user has access to the component
    * return true/false
    * */
    hasAccess() {
        const { unauthorised } = this.props;
        return !unauthorised;
    }

    /**
    * default spinner on load
    * */
    renderLoader() {
        return <Loader />;
    }

    /**
    * default message on error
    * */
    renderError() {
        return <Grid container> Something went wrong!! </Grid>;
    }

    /**
    * default access violation message
    * */
    renderUnauthorise() {
        return (
            <Grid container alignItems="center" justify="center" style={{ height: '100%' }}>
                <Typography fontStyle="italic" variant="body1">
                    {localisable.noPermissions}
                </Typography>
            </Grid>
        );
    }


    /**
    *
    * */
    renderComponent() {
        return <div> Test </div>;
    }

    render() {
        const {
            snackbarProps: { title, variant, isSnackbarOpen, snackbarActions, ...rest } = {},
            hideSnackbar, props: { updateWsProps = () => { } } = {}, receiveWSMessage,
            formProps, snackbarProps = {}, getSnackbarActions,
        } = this;


        if (this.isLoading()) {
            return this.renderLoader();
        }

        if (this.hasError()) {
            return this.renderError();
        }

        if (!this.hasAccess()) {
            return this.renderUnauthorise();
        }

        if (!this.isRefUpdated) {
            updateWsProps({ messageCallback: receiveWSMessage });
            this.isRefUpdated = true;
        }

        if (!snackbarActions) {
            if (formProps) {
                this.snackbarProps = { ...snackbarProps, snackbarActions: getSnackbarActions(true) };
            } else {
                this.snackbarProps = { ...snackbarProps, snackbarActions: getSnackbarActions() };
            }
        }

        const { snackbarProps: { snackbarActions: updatedSnackbarActions = [] } = {} } = this;

        return (
            <>
                {this.renderComponent()}
                {
                    isSnackbarOpen
                    && (
                        <Snackbar
                            variant={variant}
                            title={title}
                            key={`${variant}-${title}`}
                            onExit={hideSnackbar}
                            actions={updatedSnackbarActions}
                            {...rest}
                        />
                    )
                }
            </>
        );
    }
}

BaseComponent.propTypes = {
    loading: PropTypes.bool,
    isError: PropTypes.bool,
    unauthorised: PropTypes.bool,
    clearStoreKeys: PropTypes.func,
    updateWsProps: PropTypes.func,
};

BaseComponent.defaultProps = {
    loading: false,
    isError: false,
    unauthorised: false,
};

export default BaseComponent;
