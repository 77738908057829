import { ERROR_MESSAGES } from 'Public/containers/login/config/LoginConstants';


const validateForm = (values) => {
    const { oldPassword = '', confirmPassword = '', newPassword = '' } = values;
    const errors = {};
    if (!!(newPassword && oldPassword) && oldPassword === newPassword) {
        errors.newPassword = ERROR_MESSAGES.SAME_AS_PREVIOUS;
    }
    if (!!confirmPassword && confirmPassword !== newPassword) {
        errors.confirmPassword = ERROR_MESSAGES.NEW_PASSWORD_MATCH;
    }
    return errors;
};


export default validateForm;
