import { FormCheckboxGroup } from 'Commons/components/generic/checkboxgroup/components/CheckBoxGroup';
import Chip from 'Commons/components/generic/chip/components/Chip';
import { makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { VARIANT } from 'Commons/components/generic/form/config/FormComponentsConfig';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import { Formik, Form } from 'formik';
import { memo, useRef } from 'react';
import { setIn } from 'Commons/helpers/utils/DataHelpers';
import quickFiltersStyle from '../styles/QuickFilterStyle';

const useStyles = makeStyles(quickFiltersStyle, { name: 'QuickFilters' });

// TODO: Initial implementation, figure out the design. It'll make more sense to have it inside FiltersAnd Sorts component
const QuickFilters = ({ list, initialValues, applyFilter, classes: classesProps }) => {
    const classes = useStyles({ classes: classesProps });
    const formPropsRef = useRef({});
    const { typographyProps, chipClasses, filledChipClasses } = useConstructor(() => ({
        typographyProps: { variant: 'subtitle2', className: classes.chipLabel },
        chipClasses: { root: classes.chip },
        filledChipClasses: { root: classes.filledChip },
    }));

    const onChangeFilter = (_, value, fieldName) => {
        const { current: { values = {} } = {} } = formPropsRef;
        // TODO: use setValues once that is fixed.
        const updatedValues = setIn(deepCopy(values), fieldName, value);
        applyFilter(updatedValues);
    };

    return (
        <Formik
            initialValues={initialValues}
            render={(formProps) => {
                formPropsRef.current = formProps;
                return (
                    <Form className={classes.form}>
                        {
                            list.map(({ label, fieldName, value }) => (
                                <FormCheckboxGroup
                                    name={fieldName}
                                    key={`${fieldName}_${label}`}
                                    noGrid
                                    noIndicatorWrapper
                                    variant={VARIANT.NO_PADDING}
                                    onChange={(event, fieldValue) => onChangeFilter(event, fieldValue, fieldName)}
                                    containerClassName={classes.checkboxContainer}
                                    list={[{
                                        value,
                                        icon: (
                                            <Chip
                                                data={{ label, value: fieldName }}
                                                labelTypographyProps={typographyProps}
                                                classes={chipClasses}
                                            />
                                        ),
                                        checkedIcon: (
                                            <Chip
                                                data={{ label, value: fieldName }}
                                                labelTypographyProps={typographyProps}
                                                classes={filledChipClasses}
                                            />
                                        ),
                                    }]}
                                />
                            ))
                        }
                    </Form>
                );
            }}
        />
    );
};

QuickFilters.propTypes = {
    applyFilter: PropTypes.func,
    initialValues: PropTypes.object,
    list: PropTypes.array.isRequired,
    classes: PropTypes.object,
};

QuickFilters.defaultProps = { initialValues: {} };

export default memo(QuickFilters);
