import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import { clsx } from 'Commons/helpers/utils/clsx';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Icon from 'Generic/icon/components/Icon';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import { useCallback } from 'react';
import sideIconStyles from '../styles/SideIconStyles';

const useStyles = makeStyles(sideIconStyles, { name: 'SideIcon' });

const SideIcon = ({
    open,
    icon,
    tooltip,
    onClick,
    children,
    className,
    disabled,
    ...props
}) => {
    const classes = useStyles();

    const renderIcon = useCallback(() => {
        const iconComponent = (
            <Icon
                type="custom"
                icon={icon}
                size="medium"
                disabled={disabled}
                className={classes.sideIcon}
            />
        );
        return disabled
            ? iconComponent
            : (
                <Tooltip title={tooltip}>
                    {iconComponent}
                </Tooltip>
            );
    }, [icon, disabled]);

    return (
        <Grid
            container
            justify="center"
            onClick={onClick}
            alignItems="center"
            className={clsx(classes.iconContainer, disabled && classes.disabled, className)}
            {...props}
        >
            { icon && renderIcon() }
        </Grid>
    );
};

SideIcon.propTypes = {
    open: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    tooltip: PropTypes.node,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

SideIcon.defaultProps = {
    open: false,
    disabled: false,
    onClick: EMPTY_FUNC,
};

export default React.memo(SideIcon);
