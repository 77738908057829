import { Grid, makeStyles, Tab as MaterialUiTab, Tabs as MaterialUiTabs }
from 'Generic/componentlibrary/components/Components';
import React, { useState } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import tabsStyle from '../styles/TabStyle';
import { HIGHLIGHT_TYPE, EMPTY_LIST } from '../config/Constants';

const useStyles = makeStyles(tabsStyle, { name: 'Tabs' });

const Separator = ({ className }) => <span className={className}>|</span>;

Separator.propTypes = { className: PropTypes.string };

const TabPanel = ({ children, index, className, ...other }) => (
    <Grid
        key={index}
        className={className}
        {...other}
    >
        {children}
    </Grid>
);

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
};

const Tabs = ({
    tabsData = EMPTY_LIST, classes: classesProp, childClasses, highlightType, activeTab,
    tabsClasses, getActiveTab, separator, fullWidth, separatorPadding, tabClasses,
    TabIndicatorProps: { className: tabIndicatorClassName, ...TabIndicatorProps }, ...props
}) => {
    const classes = useStyles({ classes: classesProp });
    const { rootClass, flexContainerClass } = tabsClasses;
    const [selectedTab, setSelectedTab] = useState(activeTab);

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
        getActiveTab(newValue);
    };

    const TabsDataRenderer = tabsData.map(({ id, children, disabled, mountAlways = false }) => (
        mountAlways || selectedTab === id
            ? (
                <TabPanel
                    key={id}
                    index={id}
                    className={clsx(
                        {
                            [classes.disabledTab]: disabled,
                            [classes.displayNone]: selectedTab !== id,
                        },
                        childClasses,
                    )}
                >
                    {children}
                </TabPanel>
            )
            : null
    ));

    const getClasses = (disabled, color) => ({
        root: clsx(classes.tabs, !disabled && {
            [classes[`${color}Border`]]: highlightType !== HIGHLIGHT_TYPE.DEFAULT,
            [classes.default]: highlightType === HIGHLIGHT_TYPE.DEFAULT,
            [classes.halfBottomAlways]: highlightType === HIGHLIGHT_TYPE.HALF,
            [classes.halfBottomOnHover]: highlightType === HIGHLIGHT_TYPE.HALF_ON_HOVER,
        }),
        selected: classes.selected,
        ...tabClasses,
    });
    return (
        <>
            <MaterialUiTabs
                value={selectedTab}
                onChange={handleChange}
                classes={{
                    root: clsx(classes.tabsRoot, fullWidth && classes.fullWidth, rootClass),
                    flexContainer: clsx(classes.flexContainer, flexContainerClass),
                }}
                TabIndicatorProps={{
                    className: clsx(classes.displayNone, { [classes.defaultIndicator]: highlightType === HIGHLIGHT_TYPE.DEFAULT }, tabIndicatorClassName),
                    ...TabIndicatorProps,
                }}
                {...props}
            >
                {
                    tabsData.map(({ id, label, disabled, color = 'primary' }, index) => (
                        [
                            <MaterialUiTab
                                key={id}
                                label={label}
                                value={id}
                                disabled={disabled}
                                classes={{
                                    ...getClasses(disabled, color),
                                    textColorInherit: classes.tabOpacity,
                                }}
                                className={clsx({ [classes.disabledPadding]: disabled })}
                            />,
                            ...((separator && index !== tabsData.length - 1)
                                ? [<Separator className={clsx(separatorPadding && classes.separatorPadding)} />]
                                : []),
                        ]
                    ))
                }
            </MaterialUiTabs>
            {TabsDataRenderer}
        </>
    );
};

Tabs.propTypes = {
    tabsData: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.any,
        disabled: PropTypes.bool,
        mountAlways: PropTypes.bool,
        color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'rolesGroupPrimary', 'rolesGroupSecondary', 'rolesGroupTertiary']),
    })).isRequired,
    classes: PropTypes.object,
    highlightType: PropTypes.oneOf(Object.values(HIGHLIGHT_TYPE)),
    activeTab: PropTypes.number,
    childClasses: PropTypes.string,
    tabsClasses: PropTypes.object,
    getActiveTab: PropTypes.func,
    separator: PropTypes.bool,
    TabIndicatorProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    separatorPadding: PropTypes.bool,
    tabClasses: PropTypes.object,
};

Tabs.defaultProps = {
    activeTab: 0,
    getActiveTab: () => { },
    tabsClasses: {},
    separator: false,
    TabIndicatorProps: {},
    highlightType: HIGHLIGHT_TYPE.DEFAULT,
    separatorPadding: false,
};

export default React.memo(Tabs);
