import NUMBER_OF_TIMES_REPROCESSED
    from 'Commons/components/business/filtersAndSorts/components/automaticCreditCardDeclines/config/Constants';
import { FormDatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import { SM_6 } from 'Commons/config/constants/GridSpacingConstants';
import localisable from 'Commons/config/strings/localisable';
import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import AutomaticCreditCardDeclinesFilterStyle from '../styles/AutomaticCreditCardDeclinesFilterStyle';

const useStyles = makeStyles(AutomaticCreditCardDeclinesFilterStyle, { name: 'AutomaticCreditCardDeclinesFilter' });

const AutomaticCreditCardDeclinesFilter = ({ formProps: { setFieldValue } }) => {
    const classes = useStyles();
    const onClearDate = () => { setFieldValue('range', {}); };
    return (
        <>
            <Typography variant="h6" className={classes.firstLabel}>
                {localisable.filter}
            </Typography>
            <Grid container>
                <Typography variant="subtitle1" className={classes.restLabels}>
                    {localisable.paymentAttempts}
                </Typography>
                <FormCheckboxGroup
                    name="terms.retryCount"
                    list={Object.values(NUMBER_OF_TIMES_REPROCESSED)}
                    noGrid
                    noIndicatorWrapper
                    required
                    hideErrorText
                />
            </Grid>
            <Typography variant="subtitle1" className={classes.restLabels}>
                {localisable.date}
            </Typography>
            <Grid container>
                <FormDatePicker
                    name="range.lastModifiedDate.fromDate"
                    label={localisable.fromDate}
                    GridProps={SM_6}
                    formVariant={VARIANT.NO_LEFT_RIGHT_PADDING}
                />
                <FormDatePicker
                    name="range.lastModifiedDate.toDate"
                    label={localisable.toDate}
                    GridProps={SM_6}
                    formVariant={VARIANT.NO_LEFT_RIGHT_PADDING}
                />
            </Grid>
            <LabelWithIcon
                classes={{ labelWithIcon: classes.autoWidth }}
                className={classes.clearDate}
                onClick={onClearDate}
            >
                <Typography color="primary" variant="body2">
                    {localisable.clearDate}
                </Typography>
            </LabelWithIcon>
        </>
    );
};

AutomaticCreditCardDeclinesFilter.propTypes = { formProps: PropTypes.object };

export default memo(AutomaticCreditCardDeclinesFilter);
