import { FIVE_MINUTES, ONE_MINUTE, STATUS, TOKEN_TYPE, VIEW } from 'Commons/config/constants/Constants';
import { CONFIG_ENDPOINTS, FINANCIAL_ENDPOINTS, RENTAL_ENDPOINTS, TENANT_ENDPOINTS } from 'Commons/config/constants/Endpoints';
import { LATE_TYPE } from 'External/containers/rentalConfiguration/config/LateEventConfig';
import { getCurrentDate } from 'Commons/helpers/utils/DateTime';
import { PAYMENT_ACTION } from './Constants';

const callApi = (onAction, endPoint, body, callback = () => {}, method = 'POST', timeout = ONE_MINUTE,
    tokenType = TOKEN_TYPE.STANDARD) => {
    if (onAction) {
        onAction({
            config: [{
                api: {
                    method: 'read',
                    action: {
                        methodType: `${method}`,
                        endPoint,
                        body,
                        customHeaderOptions: {
                            type: 'store',
                            key: 'facility',
                        },
                        timeout,
                        tokenType,
                    },
                },
                callback,
            }],
        });
    }
};

const fetchTenant = (onAction, id, callback) => {
    const body = {
        view: VIEW.detail.value,
        filter: [{ terms: { id: [id], status: [STATUS.Active.value], ledger: { status: [STATUS.Active.value] } } }],
    };
    callApi(onAction, 'tenant/search', body, callback);
};

const fetchUnits = (onAction, ledgerId = '', callback = () => {}) => {
    const body = {
        view: VIEW.detail.value,
        filter: [{ terms: { ledger: { id: [ledgerId] } } }],
    };
    callApi(onAction, 'unit/search', body, callback);
};

const pay = (onAction, body, callback, isRefund = false, tokenType = TOKEN_TYPE.STANDARD) => {
    const { cardInfo: { cardNumber = '' } = {}, useCardOnFile, cardProcessorId, action } = body;
    let timeout = ONE_MINUTE;
    if (cardProcessorId && !useCardOnFile && !cardNumber && action !== PAYMENT_ACTION.PAY_VALIDATED_AMOUNT) {
        timeout = FIVE_MINUTES; // For card swipes, we need to wait for the card to be read.
    }
    const endPoint = isRefund ? FINANCIAL_ENDPOINTS.refund : FINANCIAL_ENDPOINTS.pay;
    callApi(onAction, endPoint, body, callback, 'POST', timeout, tokenType);
};

const updateLedger = (onAction, tenantId, body, callback) => {
    callApi(onAction, `tenant/${tenantId}`, body, callback, 'PUT');
};

const fetchTenantDetailsFromLedger = (onAction, ledger, callback) => {
    const body = {
        view: VIEW.detail.value,
        filter: [{ terms: { ledger: { id: [ledger] } } }],
    };
    callApi(onAction, 'tenant/search', body, callback);
};

const fetchTenantAlert = (onAction, tenantId = '', callback = () => {}) => {
    const body = {
        view: VIEW.detail.value,
        filter: [{ terms: { tenant: [tenantId] } }],
        size: 50,
    };
    callApi(onAction, 'tenant/alert/search', body, callback);
};

const fetchLedgerBalance = (onAction, ledgerId, callback) => {
    callApi(onAction, `tenant/ledger/${ledgerId}`, {}, callback, 'GET');
};

const billAhead = (onAction, body, callback) => {
    callApi(onAction, RENTAL_ENDPOINTS.billAhead, body, callback, 'POST');
};

const getPaymentMethodApiConfig = (facilityId, view = VIEW.detail.value) => ({
    view,
    filter: [{
        terms: {
            configType: ['Payment_Method'],
            entityType: ['Facility'],
            entityId: [facilityId],
            value: { status: [STATUS.Active.value] },
        },
    }],
});

const fetchPaymentMethod = (onAction, facilityId, callback) => {
    const body = getPaymentMethodApiConfig(facilityId);
    callApi(onAction, CONFIG_ENDPOINTS.search, body, callback, 'POST');
};

const fetchPendingDelinquency = (onAction, ledgerId, callback) => {
    const body = {
        view: VIEW.detail.value,
        filter: [{
            terms: {
                status: [STATUS.Active.value],
                type: [LATE_TYPE.Dollar_Per_Day.value],
                ledgerId,
            },
        }],
    };
    callApi(onAction, 'delinquency/search', body, callback, 'POST');
};

const processLateEvent = (onAction, idList, callback) => {
    const body = { process: { idList }, processSynchronously: true, effectiveDate: getCurrentDate() };
    callApi(onAction, 'delinquency/process-late-event', body, callback);
};

const authenticateCardProcessorIntegrator = (onAction, body, callback) => {
    callApi(onAction, FINANCIAL_ENDPOINTS.authenticateCardProcessorIntegrator, body, callback);
};

const getSyrapayDevices = (onAction, cardProcessorId, callback) => {
    callApi(onAction, FINANCIAL_ENDPOINTS.getSyrapayDevices, { cardProcessorId }, callback);
};

const updatedPromoPlans = (onAction, promoPlanData, callback) => {
    callApi(onAction, TENANT_ENDPOINTS.promoPlan, promoPlanData, callback);
};

const fetchStripePaymentIntentSecret = (onAction, apiBody, callback) => {
    callApi(onAction, FINANCIAL_ENDPOINTS.stripeIntent, apiBody, callback);
};

const getPaymentInfo = (onAction, apiBody, callback) => {
    callApi(onAction, FINANCIAL_ENDPOINTS.paymentInfo, apiBody, callback);
};

export {
    fetchUnits, pay, updateLedger, fetchTenant, billAhead, fetchPaymentMethod, processLateEvent,
    fetchTenantDetailsFromLedger, fetchTenantAlert, fetchLedgerBalance, fetchPendingDelinquency,
    authenticateCardProcessorIntegrator, getSyrapayDevices, updatedPromoPlans,
    fetchStripePaymentIntentSecret, getPaymentInfo,
};
