import Loadable from 'react-loadable';

const loading = () => <div />;
const DateRange = Loadable({
    loader: () => import('./sections/components/DateRange' /* webpackChunkName: "DateRange" */),
    loading,
});
const NumberRange = Loadable({
    loader: () => import('./sections/components/NumberRange' /* webpackChunkName: "NumberRange" */),
    loading,
});
const TenantStatusFilter = Loadable({
    loader: () => import('External/containers/tenant/list/components/TenantStatusFilter' /* webpackChunkName: "TenantStatusFilter" */),
    loading,
});
const UnitStatusFilter = Loadable({
    loader: () => import('External/containers/unit/list/components/UnitStatusFilter' /* webpackChunkName: "UnitStatusFilter" */),
    loading,
});
const UnitRentalStatusFilter = Loadable({
    loader: () => import('External/containers/unit/list/components/UnitRentalStatusFilter' /* webpackChunkName: "UnitRentalStatusFilter" */),
    loading,
});

export {
    DateRange,
    NumberRange,
    TenantStatusFilter,
    UnitStatusFilter,
    UnitRentalStatusFilter,
};
