import { useRef } from 'react';
import { EMPTY_FUNC } from '../../config/constants/Constants';

/**
 * @description useConstructor Hook which simulates constructor. It gets called before mount.
 * @param {function} func Function to execute
 */

const useConstructor = (func = EMPTY_FUNC) => {
    const isInitialRender = useRef(true);
    const memoizedFuncValue = useRef();

    if (isInitialRender.current) {
        isInitialRender.current = false;
        memoizedFuncValue.current = func();
    }
    return memoizedFuncValue.current;
};

export default useConstructor;
