import { VIEW } from '../../../../config/constants/Constants';

const getConfig = ({
    store, sortConfig, filters, requestType,
    endPoint, start, callback, view, ...rest
} = {}, useAccountContext = false) => [{
    method: 'read',
    api: {
        dynamic: {
            methodType: 'POST',
            ...(endPoint && { endPoint }),
            body: {
                // todo make it dynamic
                view: view || VIEW.list.value,
                sort: sortConfig,
                filter: filters,
                ...(start && { start }),
                ...rest,
            },
            customHeaderOptions: {
                type: 'store',
                key: useAccountContext ? 'account' : 'facility',
            },
        },
    },
    store: { key: store },
    requestType,
    callback,
}];

export default getConfig;
