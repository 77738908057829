// import { createEnumMap } from '../../../../../helpers/utils/Formatter'; // Issue while importing directly

/**
 * @name createEnumMap
 * @description Given a list of objects, creates a map
 * @param {Array} enums Array of enums
 */
export const createEnumMap = (enums = []) => {
    const enumMapToReturn = {};
    enums.forEach((enumKey = '') => {
        enumMapToReturn[(enumKey.replace(/ /g, '_')).toUpperCase()] = enumKey;
    });
    return enumMapToReturn;
};


const FilterTypes = createEnumMap(['DATE RANGE', 'NUMBER RANGE', 'SINGLE SELECT', 'MULTI SELECT', 'CUSTOM']);

const FiltersAvailable = ['reservationDate', 'reservationStatus', 'tenantType', 'depositRange'];

const MinSections = { isDesktop: 1 };

export {
    FilterTypes,
    FiltersAvailable,
    MinSections,
};
