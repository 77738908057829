import localisable from 'Commons/config/strings/localisable';

const setFormValuesForUseCardOnFile = (setFieldValue, isAutoPayEnabled) => {
    if (setFieldValue) {
        setFieldValue('extra.payUsing', localisable.useCardOnFile);
        setFieldValue('useCardOnFile', true, false);
        setFieldValue('cardInfo', {
            saveCardForFutureTransactions: true,
            autoPayEnabled: isAutoPayEnabled,
        }, false);
    }
};

export { setFormValuesForUseCardOnFile };
