const SM_FALSE = { sm: false };
const SM_1 = { sm: 1 };
const SM_2 = { sm: 2 };
const SM_3 = { sm: 3 };
const SM_4 = { sm: 4 };
const SM_5 = { sm: 5 };
const SM_6 = { sm: 6 };
const SM_7 = { sm: 7 };
const SM_8 = { sm: 8 };
const SM_9 = { sm: 9 };
const SM_10 = { sm: 10 };
const SM_11 = { sm: 11 };
const SM_12 = { sm: 12 };
const XS_3 = { xs: 3 };
const XS_6 = { xs: 6 };
const MD_2 = { md: 2 };
const MD_3 = { md: 3 };
const MD_4 = { md: 4 };
const MD_5 = { md: 5 };
const MD_6 = { md: 6 };
const XS_12 = { xs: 12 };
const XS_2 = { xs: 2 };
const XS_4 = { xs: 4 };
const XS_8 = { xs: 8 };
const XL_4 = { xl: 4 };
const XL_8 = { xl: 8 };
const XL_9 = { xl: 9 };

const SM_1_XS_1 = { sm: 1, xs: 1 };
const SM_1_XS_4 = { sm: 1, xs: 4 };
const SM_2_XS_4 = { sm: 2, xs: 4 };
const SM_2_XS_6 = { sm: 2, xs: 6 };

const SM_3_XS_4 = { sm: 3, xs: 4 };
const SM_3_XS_5 = { sm: 3, xs: 5 };
const SM_3_XS_6 = { sm: 3, xs: 6 };
const SM_3_XS_8 = { sm: 3, xs: 8 };

const SM_3_XS_5_MD_6 = { sm: 3, xs: 5, md: 6 };

const SM_4_XS_8 = { sm: 4, xs: 8 };
const SM_4_XS_6 = { sm: 4, xs: 6 };
const SM_4_XS_5 = { sm: 4, xs: 5 };
const SM_4_XS_12 = { sm: 4, xs: 12 };

const SM_4_LG_5_XL_5_XS_6 = { xs: 6, sm: 4, lg: 5, xl: 5 };

const SM_5_XS_6 = { sm: 5, xs: 6 };
const SM_5_XS_8 = { sm: 5, xs: 8 };
const SM_5_XS_12 = { sm: 5, xs: 12 };

const SM_6_XS_5 = { sm: 6, xs: 5 };
const SM_6_XS_6 = { sm: 6, xs: 6 };
const SM_6_XS_8 = { sm: 6, xs: 8 };
const SM_6_XS_9 = { sm: 6, xs: 9 };
const SM_6_XS_12 = { sm: 6, xs: 12 };
const SM_6_XS_10 = { sm: 6, xs: 10 };
const SM_6_XS_11 = { sm: 6, xs: 11 };
const SM_8_XS_11 = { sm: 8, xs: 11 };
const SM_8_XS_12 = { sm: 8, xs: 12 };
const SM_3_LG_4_XL_3 = { sm: 3, lg: 4, xl: 3 };
const SM_7_XS_12 = { sm: 7, xs: 12 };
const SM_7_XS_8 = { sm: 7, xs: 8 };
const SM_10_XS_12 = { sm: 10, xs: 12 };
const SM_12_XS_12 = { sm: 12, xs: 12 };

const SM_5_LG_3 = { sm: 5, lg: 3 };
const SM_7_LG_9 = { sm: 7, lg: 9 };

const SM_7_XS_10 = { sm: 7, xs: 10 };

const SM_6_MD_4 = { sm: 6, md: 4 };
const SM_6_MD_3 = { sm: 6, md: 3 };

const SM_12_MD_6_LG_6 = { sm: 12, md: 6, lg: 6 };

const XS_6_MD_6_XL_4 = { xs: 6, md: 6, xl: 4 };

const XS_12_XL_6 = { xs: 12, xl: 6 };
const XS_12_MD_6_XL_4 = { xs: 12, md: 6, xl: 4 };
const XS_12_XL_4 = { xs: 12, xl: 4 };
const XS_12_MD_6_XL_3 = { xs: 12, md: 6, xl: 3 };

const XS_7 = { xs: 7 };
const XS_10 = { xs: 10 };
const XS_5 = { xs: 5 };
const XS_9 = { xs: 9 };
const XS_4_MD_3 = { xs: 4, md: 3 };
const XS_6_MD_4 = { xs: 6, md: 4 };
const XS_8_MD_4 = { xs: 8, md: 4 };
const XS_6_MD_5 = { xs: 6, md: 5 };
const XS_6_MD_6 = { xs: 6, md: 6 };
const XS_12_MD_6 = { xs: 12, md: 6 };
const XS_12_MD_7 = { xs: 12, md: 7 };
const XS_12_MD_5 = { xs: 12, md: 5 };
const XS_12_MD_8 = { xs: 12, md: 8 };
const XS_5_XL_4 = { xs: 5, xl: 4 };
const XS_FALSE = { xs: false };
const LG_12_MD_8_XS_12 = { lg: 12, md: 8, xs: 12 };
const LG_5_MD_4_XS_6 = { lg: 5, md: 4, xs: 6 };
const SM_4_LG_6_XL_4 = { sm: 4, lg: 6, xl: 4 };
const XS_4_LG_6_XL_4 = { xs: 4, lg: 6, xl: 4 };
const XS_6_LG_8_XL_6 = { xs: 6, lg: 8, xl: 6 };
const XL_6_LG_8_SM_6_XS_8 = { xl: 6, lg: 8, sm: 6, xs: 8 };
const XS_12_XL_5 = { xs: 12, xl: 5 };
const XS_10_MD_4_LG_4 = { lg: 4, md: 5, xs: 10 };
const XS_7_MD_4 = { xs: 7, md: 4 };
const XS_6_MD_3 = { xs: 6, md: 3 };
const XS_4_MD_2 = { xs: 4, md: 2 };
const XS_10_MD_6_LG_4 = { xs: 10, md: 6, lg: 4 };
const XS_6_MD_3_LG_2 = { xs: 6, md: 3, lg: 2 };
const XS_8_MD_4_LG_3 = { xs: 8, md: 4, lg: 3 };
const XS_8_MD_2 = { xs: 8, md: 2 };
const XS_12_SM_6_XL_5 = { xs: 12, sm: 6, xl: 5 };
const XS_12_MD_6_LG_4 = { xs: 12, md: 6, lg: 4 };
const XS_12_SM_6 = { xs: 12, sm: 6 };
const XS_12_LG_6 = { xs: 12, lg: 6 };
const XS_9_LG_6 = { xs: 9, lg: 6 };
const XS_4_MD_4_LG_2 = { xs: 4, md: 4, lg: 2 };
const XS_5_MD_3_LG_4 = { xs: 5, md: 3, lg: 4 };
const XS_9_MD_6_LG_10 = { xs: 9, md: 6, lg: 10 };
const XS_12_SM_12_MD_7_LG_6 = { xs: 12, sm: 12, md: 7, lg: 6 };

export {
    SM_FALSE,
    SM_1,
    SM_2,
    SM_3,
    SM_4,
    SM_5,
    SM_6,
    SM_7,
    SM_8,
    SM_9,
    SM_10,
    SM_11,
    SM_12,
    XS_12,
    SM_1_XS_1,
    SM_1_XS_4,
    SM_2_XS_4,
    SM_2_XS_6,
    SM_3_XS_4,
    SM_3_XS_6,
    SM_3_XS_8,
    SM_4_XS_8,
    SM_4_XS_5,
    SM_4_XS_6,
    SM_4_XS_12,
    SM_5_XS_6,
    SM_5_XS_8,
    SM_5_XS_12,
    SM_6_XS_5,
    SM_6_XS_6,
    SM_6_XS_8,
    SM_6_XS_9,
    SM_6_XS_10,
    SM_6_XS_11,
    SM_6_XS_12,
    SM_8_XS_11,
    SM_8_XS_12,
    SM_7_XS_12,
    SM_10_XS_12,
    SM_12_XS_12,
    SM_3_LG_4_XL_3,
    SM_5_LG_3,
    SM_7_LG_9,
    SM_6_MD_3,
    SM_6_MD_4,
    XS_6_MD_5,
    XS_6_MD_6,
    SM_7_XS_8,
    SM_7_XS_10,
    SM_12_MD_6_LG_6,
    XS_6_MD_6_XL_4,
    MD_2,
    MD_3, MD_4,
    MD_5, MD_6,
    XS_3,
    XS_4,
    XS_6,
    XS_7,
    XS_10,
    XS_8,
    XS_9,
    XS_FALSE,
    XS_6_MD_4,
    XS_8_MD_4,
    XS_2,
    SM_3_XS_5,
    SM_3_XS_5_MD_6,
    LG_12_MD_8_XS_12,
    LG_5_MD_4_XS_6,
    XS_12_MD_5,
    XS_12_MD_6,
    XS_12_MD_7,
    XS_12_XL_6,
    XS_12_MD_6_XL_4,
    XS_12_MD_6_XL_3,
    XS_5_XL_4,
    XS_12_XL_4,
    SM_4_LG_6_XL_4,
    XS_6_LG_8_XL_6,
    XL_6_LG_8_SM_6_XS_8,
    XS_12_XL_5, XS_5,
    XS_10_MD_4_LG_4,
    XS_7_MD_4,
    XS_6_MD_3,
    XS_4_MD_2,
    XS_4_LG_6_XL_4,
    XS_10_MD_6_LG_4,
    XS_6_MD_3_LG_2,
    XS_8_MD_4_LG_3,
    XL_9,
    XS_8_MD_2,
    XS_12_SM_6_XL_5,
    XS_12_MD_6_LG_4,
    XS_12_SM_6,
    SM_4_LG_5_XL_5_XS_6,
    XS_12_LG_6,
    XL_4,
    XL_8,
    XS_9_LG_6,
    XS_12_MD_8,
    XS_4_MD_3,
    XS_4_MD_4_LG_2,
    XS_5_MD_3_LG_4,
    XS_9_MD_6_LG_10,
    XS_12_SM_12_MD_7_LG_6,
};
