import { FormTextField } from 'Generic/textfield/components/TextField';
import { XS_12 } from 'Commons/config/constants/GridSpacingConstants';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import HelperText from 'Generic/helpertext/HelperText';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { IS_INTEGER, IS_MAX_LENGTH_17, IS_MAX_LENGTH_30, IS_MAX_LENGTH_9 }
from 'Commons/config/constants/Validators';
import paymentMethodStyle from '../styles/PaymentMethodStyle';

const useStyles = makeStyles(paymentMethodStyle, { name: 'Ach' });

const ACH = ({ baseFormFieldName, ledgerIndex, disabled, classes: classesProp, isInEditMode }) => {
    const classes = useStyles({ classes: classesProp });

    const { formFieldPrefix, accountNumberValidator, routingNumberValidator } = useConstructor(() => ({
        formFieldPrefix: baseFormFieldName || `ledger.${ledgerIndex}.financials.achPpd`,
        accountNumberValidator: { ...isInEditMode ? { ...IS_INTEGER, ...IS_MAX_LENGTH_17 } : {} },
        routingNumberValidator: { ...IS_INTEGER, ...IS_MAX_LENGTH_9 },
    }));

    return (
        <>
            <Grid item xs={3} />
            <Grid container item xs={6}>
                <FormTextField
                    name={`${formFieldPrefix}.displayAccountNumber`}
                    required={!!formFieldPrefix}
                    requiredPath="ledger.financials.achPpd.accountNumber"
                    placeholder={localisable.checkPlaceHolder}
                    helperText={<HelperText>{localisable.achAccountNumber}</HelperText>}
                    className={classes.numberFields}
                    fullWidth
                    formVariant={VARIANT.INLINE}
                    GridProps={XS_12}
                    disabled={disabled}
                    validate={accountNumberValidator}
                />
                <FormTextField
                    name={`${formFieldPrefix}.routingNumber`}
                    required={!!formFieldPrefix}
                    requiredPath="ledger.financials.achPpd.routingNumber"
                    placeholder={localisable.routingNumberPlaceHolder}
                    helperText={<HelperText>{localisable.achRoutingNumber}</HelperText>}
                    className={classes.numberFields}
                    GridProps={XS_12}
                    formVariant={VARIANT.INLINE}
                    disabled={disabled}
                    fullWidth
                    validate={routingNumberValidator}
                />
                <FormTextField
                    name={`${formFieldPrefix}.bankName`}
                    requiredPath="ledger.financials.achPpd.bankName"
                    helperText={<HelperText>{localisable.achBankName}</HelperText>}
                    GridProps={XS_12}
                    formVariant={VARIANT.INLINE}
                    disabled={disabled}
                    validate={IS_MAX_LENGTH_30}
                    fullWidth
                />
            </Grid>
            <Grid item xs={3} />
        </>
    );
};

ACH.propTypes = {
    ledgerIndex: PropTypes.number,
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    baseFormFieldName: PropTypes.string,
    isInEditMode: PropTypes.bool,
};

ACH.defaultProps = { ledgerIndex: 0 };

export default React.memo(ACH);
