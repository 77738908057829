import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import localisable from 'Commons/config/strings/localisable';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { STATUS } from '../config/Constants';
import UserFilterStyle from '../styles/UserFilterStyle';

const useStyles = makeStyles(UserFilterStyle, { name: 'UserFilterStyle' });

const UserFilter = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={Object.values(STATUS)}
                row
                noGrid
                noIndicatorWrapper
            />
        </>
    );
};

export default memo(UserFilter);
