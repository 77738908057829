import { VIEW } from 'Commons/config/constants/Constants';
import { CONFIG_ENDPOINTS } from 'Commons/config/constants/Endpoints';
import { deepMerge } from 'Commons/helpers/utils/DeepCopy';
import { isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';

const getConfig = ({
    filter, requestType, endPoint, start, callback, sendCustomHeader = true,
    isStoreUpdate = false, payload,
} = {}, passedConfig) => {
    const {
        api: { dynamic: { endPoint: passedEndpoint } = {} } = {}, store,
        sendCustomHeader: sendHeader = true,
    } = passedConfig || {};

    const storeConfig = {
        ...store,
        ...(payload && { payload }),
    };

    const initConfig = {
        method: 'read',
        requestType,
        callback,
        ...(passedEndpoint || endPoint) === CONFIG_ENDPOINTS.search && { resourceName: 'configSD' },
    };

    if (isObjWithKeys(store)) {
        initConfig.store = storeConfig;
    }

    if (!isStoreUpdate) {
        initConfig.api = {
            dynamic: {
                methodType: 'POST',
                ...(endPoint && { endPoint }),
                body: {
                    view: VIEW.list.value,
                    filter,
                    ...(start && { start }),
                },
                ...(sendCustomHeader && sendHeader ? {
                    customHeaderOptions: {
                        type: 'store',
                        key: 'facility',
                    },
                } : {}),
            },
        };
    }

    let config = initConfig;
    if (passedConfig) {
        config = deepMerge(initConfig, passedConfig);
    }
    return [config];
};

export default getConfig;
