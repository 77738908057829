import Icon from 'Commons/components/generic/icon/components/Icon';
import { withStyles, Grid, Typography, Loader } from 'Commons/components/generic/componentlibrary/components/Components';
import { FormDatePicker } from 'Generic/datepicker/components/DatePicker';
import { memo, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import hasPermission from 'Commons/helpers/utils/PermissionChecker';
import { FormTextField, TextField } from 'Generic/textfield/components/TextField';
import { FormCheckbox } from 'Generic/checkbox/components/CheckBox';
import localisable from 'Commons/config/strings/localisable';
import { isPositiveNumeric, isTwoDecimalPlaces } from 'Commons/helpers/utils/validator/Validator';
import round from 'Commons/helpers/utils/Round';
import { FEATURE, SOURCE, TIMEOUT } from 'Commons/config/constants/Constants';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { debounce } from 'Commons/helpers/utils/Utils';
import { clsx } from 'Commons/helpers/utils/clsx';
import PaymentInformationStyle from '../styles/PaymentInformationStyle';
import { MODIFIER_TYPE, PAYMENT_METHOD } from '../config/Constants';
import { calculateNextAmountPayable } from '../utils/BalanceCalculator';
import PAYMENT_PERMISSION_KEYS from '../config/PermissionKeys';
import shouldModifyNegativeAmount from '../utils/CheckAmount';
import { getPaymentInfo } from '../config/ApiRequests';

const PaymentInformation = ({
    classes,
    formProps: {
        values: {
            ledgerId: ledger, waiveSurchargeOrDiscount, extra: {
                amount: extraAmount,
                amountWithoutModifier: extraAmountWithoutModifier,
            },
            doNotProcessCard, extra: { ledgerBalance = null, unitList = [] } = {}, paymentMethodId,
        } = {},
        status,
        setStatus,
        setFieldValue,
        status: {
            selectedPaymentMethod: { value: { modifier, type } = {} } = {},
            amountWithoutModifier,
            modifierValue,
            ledgerInfo: { balance: statusLedgerBalance = 0, financials: { exemptFromSurcharge: isLedgerExemptedFromSurcharge } = {}, id = '' } = {},
        } = {},
    },
    permission,
    reFetchTenantBalance,
    setPrefilledFalse,
    isUnpaidBillsOpen,
    onShowUnpaidBills,
    source,
    isRefund,
    disableAmountEdit,
    showUnpaidBills,
    getIsAmountWithoutModifierTouched,
    getResetIsAmountWithoutModifierTouched,
    onAction,
    fetchingAmountField,
    setFetchingAmountField,
}) => {
    const { deviceInfo: { isDesktop } } = window;
    const [isWaived, setIsWaived] = useState(false);
    const paymentInfoArguments = useRef({});
    const [isUpdatingAmountWithoutModifier, setIsUpdatingAmountWithoutModifier] = useState(false);
    const [canModifyEffectiveDate, setEffectiveDatePermission] = useState(false);
    const [amountValidator, setAmountValidator] = useState({ isTwoDecimalPlaces, isPositiveNumeric });
    const isAmountWithoutModifierTouched = useRef(false);
    let amountWithoutModifierUpdated = reFetchTenantBalance
        && ledgerBalance !== null
        && !isAmountWithoutModifierTouched.current
        ? ledgerBalance
        : amountWithoutModifier;
    if (!isRefund && amountWithoutModifierUpdated <= 0 && !isAmountWithoutModifierTouched.current) {
        amountWithoutModifierUpdated = source === SOURCE.retailSale.value
            ? 0 : calculateNextAmountPayable(unitList, id);
    }
    const isViewUnpaidBillsVisible = useMemo(() => (!isUnpaidBillsOpen && showUnpaidBills
        && ledger && ![SOURCE.ledger.value, FEATURE.movein.value, FEATURE.moveout.value].includes(source)),
    [source, ledger, isUnpaidBillsOpen]);

    const doesPaymentMethodUseCard = useMemo(() => type === PAYMENT_METHOD.CREDIT_CARD
        || type === PAYMENT_METHOD.DEBIT_CARD,
    [type]);

    const getPlusMinusSymbol = useMemo(() => {
        if (modifier === MODIFIER_TYPE.DISCOUNT) return isRefund ? '+' : '-';
        return isRefund ? '-' : '+';
    });

    const resetIsAmountWithoutModifierTouched = () => {
        isAmountWithoutModifierTouched.current = false;
    };

    const getDollarIcon = (props = {}) => (
        <Icon
            type="custom"
            icon="cp-dollar"
            className={classes.dollarIcon}
            {...props}
        />
    );

    const { amountWithoutModifierAddon, modifierAddon, inputProps } = useConstructor(() => ({
        amountWithoutModifierAddon: { start: getDollarIcon() },
        modifierAddon: { start: getDollarIcon({ disabled: true }) },
        inputProps: { className: classes.input },
    }));

    const totalAmountAddon = useMemo(() => ({ start: getDollarIcon({ disabled: !ledger }) }),
        [ledger]);

    const shouldShowModifiers = modifier && modifier !== MODIFIER_TYPE.NO_MODIFIER
        && !(modifier === MODIFIER_TYPE.SURCHARGE
            && isLedgerExemptedFromSurcharge);

    useEffect(() => {
        setEffectiveDatePermission(hasPermission(permission, PAYMENT_PERMISSION_KEYS.EFFECTIVE_DATE));
        // TODO: Discuss and see if it still make sense
        if (!hasPermission(permission, PAYMENT_PERMISSION_KEYS.NEGATIVE_PAYMENT)) {
            setAmountValidator({
                ...amountValidator,
                isPositiveNumeric,
            });
        }
    }, [permission]);

    useEffect(() => {
        getIsAmountWithoutModifierTouched(isAmountWithoutModifierTouched.current);
    }, [isAmountWithoutModifierTouched.current]);

    useEffect(() => {
        getResetIsAmountWithoutModifierTouched(resetIsAmountWithoutModifierTouched);
    }, []);

    const updatePaymentFields = (changedAmountWithoutModifier, surchargeOrDiscount,
        amount, touchedValue = true) => {
        setStatus({
            ...status,
            amountWithoutModifier: changedAmountWithoutModifier,
            modifierValue: surchargeOrDiscount,
        });
        if (touchedValue) {
            isAmountWithoutModifierTouched.current = true;
        }
        setFieldValue('amount', round(amount, 2, true));
        setPrefilledFalse();
    };

    const onPaymentInfo = isOnChange => (__, response) => {
        paymentInfoArguments.current = {};
        if (response) {
            const {
                data: {
                    amount,
                    amountWithoutModifier: changedAmountWithoutModifier,
                    surchargeOrDiscount,
                } = {},
            } = response;
            updatePaymentFields(changedAmountWithoutModifier, surchargeOrDiscount, amount, isOnChange);
        }
        setFetchingAmountField();
    };

    const paymentInfo = (body, isOnChange) => {
        if (isOnChange || (body.paymentMethodId && (body.amountWithoutModifier || body.amount)
            && (paymentInfoArguments.current.amountWithoutModifier !== round(body.amountWithoutModifier, 2, true)
                || paymentInfoArguments.current.paymentMethodId !== body.paymentMethodId))) {
            if (body.amountWithoutModifier) setFetchingAmountField('amount');
            else setFetchingAmountField('amountWithoutModifier');
            getPaymentInfo(onAction, body, onPaymentInfo(isOnChange));
            paymentInfoArguments.current = {
                amountWithoutModifier: round(body.amountWithoutModifier, 2, true),
                paymentMethodId,
            };
        }
    };

    const onWaiveDiscountOrSurcharge = (_, waived = false) => {
        setIsWaived(waived);
        if (!waived && round(amountWithoutModifierUpdated, 2, true) !== round(0, 2, true)) {
            paymentInfo({
                amountWithoutModifier: amountWithoutModifierUpdated,
                paymentMethodId,
                source: isRefund ? 'Refund' : 'Payment',
            });
        } else {
            updatePaymentFields(amountWithoutModifierUpdated, round(0, 2, true), amountWithoutModifierUpdated, false);
        }
    };

    useEffect(() => {
        if (shouldShowModifiers && !isAmountWithoutModifierTouched.current) {
            onWaiveDiscountOrSurcharge('', isWaived);
        }
    }, [amountWithoutModifierUpdated]);

    useEffect(() => {
        shouldModifyNegativeAmount(isRefund, unitList, setFieldValue, id, source, ledgerBalance || statusLedgerBalance);
        if (shouldShowModifiers) {
            onWaiveDiscountOrSurcharge('', isWaived);
        }
        resetIsAmountWithoutModifierTouched();
    }, [JSON.stringify(unitList)]);


    const onChangeAmountWithoutModifier = (isOnChange, value) => {
        if (value) {
            if (waiveSurchargeOrDiscount || round(value, 2, true) === round(0, 2, true)) {
                updatePaymentFields(value, round(0, 2, true), value, isOnChange);
            } else {
                paymentInfo({
                    amountWithoutModifier: round(value, 2, true),
                    paymentMethodId,
                    source: isRefund ? 'Refund' : 'Payment',
                }, isOnChange);
            }
        }
    };

    const onChangeAmount = (isOnChange, value) => {
        if (value) {
            if (waiveSurchargeOrDiscount || round(value, 2, true) === round(0, 2, true)) {
                updatePaymentFields(value, round(0, 2, true), value);
            } else {
                paymentInfo({
                    amount: round(value, 2, true),
                    paymentMethodId,
                    source: isRefund ? 'Refund' : 'Payment',
                }, isOnChange);
            }
        }
    };

    useEffect(() => {
        if (extraAmount && paymentMethodId) {
            onChangeAmount(null, extraAmount);
        }
    }, [extraAmount]);

    const debounceAmountWithoutModifierChange = useCallback(debounce((e, value) => {
        onChangeAmountWithoutModifier(e, value);
    }, TIMEOUT), [paymentMethodId]);

    useEffect(() => {
        if (extraAmountWithoutModifier && paymentMethodId) {
            if (isUpdatingAmountWithoutModifier) {
                debounceAmountWithoutModifierChange(true, extraAmountWithoutModifier);
            } else {
                onChangeAmountWithoutModifier(null, extraAmountWithoutModifier);
            }
        }
    }, [extraAmountWithoutModifier, paymentMethodId]);

    const onChangeAmountField = useCallback(debounce((e, value) => {
        if (modifier !== MODIFIER_TYPE.NO_MODIFIER) onChangeAmount(e, value);
    }, TIMEOUT), [paymentMethodId]);

    const onBlurAmountField = (event) => {
        if (modifier === MODIFIER_TYPE.NO_MODIFIER) {
            const currentAmountValue = round(event.target.value || 0, 2, true);
            updatePaymentFields(currentAmountValue, round(0, 2, true), currentAmountValue);
        }
    };

    const onChangeAmountWithoutModifierField = (e, value) => {
        setIsUpdatingAmountWithoutModifier(true);
        setFieldValue('extra.amountWithoutModifier', value);
    };

    const renderEffectiveDatePicker = () => {
        const isDisabled = !ledger || !canModifyEffectiveDate || (doesPaymentMethodUseCard && !doNotProcessCard);
        return (
            <Grid container alignItems="flex-end" wrap="nowrap" className={classes.datePickerWidth}>
                <FormDatePicker
                    name="effectiveDate"
                    noGrid
                    noIndicatorWrapper
                    label={localisable.effectiveDate}
                    disabled={isDisabled}
                    key={isDisabled}
                    FormControlProps={{ fullWidth: false }}
                    onChange={() => setPrefilledFalse()}
                />
            </Grid>
        );
    };

    const renderModifierTitle = () => (
        // Checkbox size will be reduced
        <Grid container alignItems="center" justify="center">
            <FormCheckbox
                name="waiveSurchargeOrDiscount"
                noGrid
                trackValue
                onChange={onWaiveDiscountOrSurcharge}
                key={modifier}
                className={clsx(classes.checkbox, classes.modifierCheckbox)}
                label={modifier}
                size="small"
                FormControlProps={{ fullWidth: false }}
                FormControlLabelProps={{
                    className: classes.formControlCheckbox,
                    classes: { label: classes.checkboxLabel },
                }}
            />
        </Grid>
    );

    const renderAmountWithoutModifierField = () => (
        <Grid item xs={5} container justify="center" alignItems="center">
            <Grid item xs={11} className={classes.textAlignCenter}>
                {
                    fetchingAmountField === 'amountWithoutModifier'
                        ? <Loader />
                        : (
                            <TextField
                                helperText={localisable.amount}
                                type="number"
                                trackValue={false}
                                value={round(isUpdatingAmountWithoutModifier
                                    ? extraAmountWithoutModifier
                                    : amountWithoutModifierUpdated, 2, !isUpdatingAmountWithoutModifier)}
                                onChange={onChangeAmountWithoutModifierField}
                                FormHelperTextProps={{ classes: { root: classes.helperText } }}
                                inputProps={{ ...inputProps, onBlur: () => setIsUpdatingAmountWithoutModifier(false) }}
                                typographyVariant="h5"
                                addon={amountWithoutModifierAddon}
                                disabled={disableAmountEdit}
                            />
                        )
                }
            </Grid>
        </Grid>
    );

    const renderModifierField = () => (
        <Grid item xs={5} container justify="center" alignItems="center">
            <Grid item xs={11} className={classes.textAlignCenter}>
                {
                    fetchingAmountField
                        ? <Loader />
                        : (
                            <TextField
                                disabled
                                value={round(Math.abs(modifierValue), 2, true)}
                                key={modifierValue}
                                inputProps={inputProps}
                                typographyVariant="h5"
                                addon={modifierAddon}
                            />
                        )
                }
                {renderModifierTitle()}
            </Grid>
        </Grid>
    );

    const renderTotalAmountField = () => (
        <Grid item xs={8} container justify="center" alignItems="center">
            <Grid item xs={11} className={classes.textAlignCenter}>
                {
                    fetchingAmountField === 'amount'
                        ? <Loader />
                        : (
                            <FormTextField
                                name="amount"
                                helperText={localisable.totalAmount}
                                noGrid
                                required
                                noIndicatorWrapper
                                type="number"
                                onChange={onChangeAmountField}
                                onBlur={onBlurAmountField}
                                disabled={!ledger || disableAmountEdit}
                                useField
                                FormHelperTextProps={{ classes: { root: classes.helperText } }}
                                FormControlProps={{ fullWidth: false }}
                                inputProps={inputProps}
                                validate={amountValidator}
                                typographyVariant="h5"
                                addon={totalAmountAddon}
                                rateField
                            />
                        )
                }
            </Grid>
        </Grid>
    );

    const renderAmountInfo = () => (
        <Grid
            container
            alignItems="center"
            justify="center"
            item
            md={12}
            className={classes.amountContainer}
            wrap={isDesktop ? 'wrap' : 'nowrap'}
            direction={isDesktop ? 'row' : 'column'}
        >
            {
                shouldShowModifiers
                && (
                    <Grid
                        container
                        item
                        xl={7}
                        justify="space-between"
                    >
                        {renderAmountWithoutModifierField()}
                        <Grid item xs={1} container alignItems="center" justify="center">
                            <Typography variant="h5">
                                {getPlusMinusSymbol}
                            </Typography>
                        </Grid>
                        {renderModifierField()}
                        <Grid item xs={1} container alignItems="center" justify="center">
                            <Typography variant="h5">=</Typography>
                        </Grid>
                    </Grid>
                )
            }
            <Grid container item xs={8} xl={5} alignItems="center">
                {renderTotalAmountField()}
                {isViewUnpaidBillsVisible && (
                    <Grid className={classes.viewIconContainer}>
                        <Icon
                            type="custom"
                            icon="cp-view"
                            className={classes.viewIcon}
                            onClick={() => onShowUnpaidBills()}
                            color="primary"
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );

    return (
        <Grid container className={classes.paymentInfoContainer}>
            <Grid container justify="space-between" item md={12} wrap="nowrap" className={classes.dateAndReceipt}>
                {renderEffectiveDatePicker()}
            </Grid>
            {renderAmountInfo()}
        </Grid>
    );
};

PaymentInformation.propTypes = {
    classes: PropTypes.object,
    formProps: PropTypes.object,
    permission: PropTypes.object,
    reFetchTenantBalance: PropTypes.bool,
    setPrefilledFalse: PropTypes.func,
    onShowUnpaidBills: PropTypes.func,
    isUnpaidBillsOpen: PropTypes.bool,
    source: PropTypes.string,
    isRefund: PropTypes.bool,
    disableAmountEdit: PropTypes.bool,
    showUnpaidBills: PropTypes.bool,
    getIsAmountWithoutModifierTouched: PropTypes.func,
    getResetIsAmountWithoutModifierTouched: PropTypes.func,
    onAction: PropTypes.func,
    fetchingAmountField: PropTypes.string,
    setFetchingAmountField: PropTypes.func,
};
PaymentInformation.defaultProps = {
    formProps: {},
    permission: {},
    reFetchTenantBalance: false,
    isUnpaidBillsOpen: false,
    onShowUnpaidBills: () => {
    },
    disableAmountEdit: false,
    showUnpaidBills: true,
};

export default withStyles(PaymentInformationStyle, { index: 1 })(memo(PaymentInformation));
