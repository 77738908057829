import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import { memo, useCallback, useMemo } from 'react';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { DROPDOWN_MAX_HEIGHT, EMPTY_LIST } from 'Commons/config/constants/Constants';
import { labelFormatter } from 'Commons/helpers/utils/Formatter';
import { getOptionProps } from 'Commons/helpers/utils/SDFormatter';
import Typography from 'Commons/components/generic/typography/components/Typography';
import JournalUserRoleFilterStyle from '../styles/JournalUserRoleFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';
import { getUserRoleApiConfig } from '../config/ApiRequests';
import { USER_ROLE_CATEGORY_TYPE } from '../config/Constants';

const useStyles = makeStyles(
    JournalUserRoleFilterStyle, { name: 'JournalUserRoleFilter' },
);

const JournalUserRoleFilter = ({
    formProps: {
        setFieldValue,
        values: { terms: { categodyId: selectedUserRoles = EMPTY_LIST, metaInfo__category: selecteUserRoleCategories = EMPTY_LIST } = {} },
    } = {},
    formProps, entityId: selectedAccountId,
}) => {
    const classes = useStyles();

    const userRoleApiConfig = useMemo(() => getUserRoleApiConfig(selectedAccountId), [selectedAccountId]);

    const userRoleCategoryList = useConstructor(() => Object.values(USER_ROLE_CATEGORY_TYPE).map((category = {}) => {
        const { value = '' } = category;
        return { id: value, ...category, value };
    }));

    const userRoleOptionFormatter = useCallback(({ data = [] } = {}) => data.map(({ name, ...account }) => (
        { label: name, value: name, ...account }
    )), [selectedAccountId]);


    const onSelectOrRemoveUserRole = (selectedUserRoleIds) => {
        setFieldValue('terms.categoryId', Object.keys(selectedUserRoleIds));
    };

    const onSelectOrRemoveCategoryType = (selectedCategories) => {
        const selectedConfigValues = Object.values(selectedCategories).map(({ value }) => value);
        setFieldValue('terms.metaInfo__category', selectedConfigValues);
    };

    return (
        <>
            <RangeFilter
                isDateRange
                disableFuture
                name="createdDate"
                formProps={formProps}
                setFieldValue={setFieldValue}
                label={localisable.modifiedEffectiveDate}
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.selectUserRole}
            </Typography>
            <MultiSelect
                placeholder={localisable.selectUserRole}
                onSelect={onSelectOrRemoveUserRole}
                onRemove={onSelectOrRemoveUserRole}
                keyToMatch="id"
                apiConfig={userRoleApiConfig}
                maxHeight={150}
                initiallySelectedValues={selectedUserRoles}
                inputValueFormatter={labelFormatter}
                getOptionProps={getOptionProps}
                optionFormatter={userRoleOptionFormatter}
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.selectCategory}
            </Typography>
            <MultiSelect
                list={userRoleCategoryList}
                placeholder={localisable.searchConfiguration}
                onSelect={onSelectOrRemoveCategoryType}
                getOptionProps={getOptionProps}
                keyToMatch="id"
                inputValueFormatter={labelFormatter}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                autoSuggestKey="matchPhrasePrefix.label"
                onRemove={onSelectOrRemoveCategoryType}
                initiallySelectedValues={selecteUserRoleCategories}
            />
        </>
    );
};

JournalUserRoleFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
};

JournalUserRoleFilter.defaultProps = { formProps: {} };

export default memo(JournalUserRoleFilter);
