import { useState } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import Icon from 'Generic/icon/components/Icon';
import { Radio as MaterialUiRadio, FormControlLabel, makeStyles } from 'Generic/componentlibrary/components/Components';
import { processValue } from 'Commons/helpers/api/Formatter';
import Form from 'Generic/form/components/Form';
import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import radioStyle from '../styles/RadioStyle';

const useStyles = makeStyles(radioStyle, { name: 'Radio' });

const Radio = (props) => {
    const {
        trackValue, value, label, form, classes: overriddenClasses, formLabelClassName, size,
        FormControlLabelProps: { className: formLabelClass, ...otherLabelProps } = {},
        radioClasses, onChange: propOnChange, className, dataType, readOnly, ...others
    } = props;
    const [stateValue, setStateValue] = useState(() => (trackValue ? value : null));
    const classes = useStyles(props);

    const onChange = (event, currentValue) => {
        if (trackValue) {
            setStateValue(currentValue);
        }
        const processedValue = processValue(dataType === 'boolean' ? currentValue : event.target.value, dataType);
        propOnChange(event, processedValue);
    };

    const renderRadio = () => (
        <MaterialUiRadio
            disableRipple
            color={readOnly ? 'default' : 'primary'}
            onChange={onChange}
            className={clsx(classes.root, className, readOnly && classes.readOnly)}
            classes={radioClasses}
            checked={trackValue ? stateValue : value}
            checkedIcon={<Icon icon="cp-radio-on" type="custom" size={size} />}
            icon={<Icon icon="cp-radio-off" type="custom" size={size} />}
            value={value}
            {...others}
        />
    );


    return (
        label
            ? (
                <FormControlLabel
                    className={clsx(classes.formLabel,
                        formLabelClass,
                        formLabelClassName,
                        readOnly && classes.InheritedConfigLabel)}
                    control={renderRadio()}
                    label={label}
                    value={value}
                    {...otherLabelProps}
                />
            )
            : renderRadio()
    );
};

Radio.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.node,
    trackValue: PropTypes.bool,
    readOnly: PropTypes.bool,
    form: PropTypes.object,
    classes: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    FormControlLabelProps: PropTypes.object,
    radioClasses: PropTypes.object,
    formLabelClassName: PropTypes.string,
    className: PropTypes.string,
    dataType: PropTypes.oneOf(['string', 'number', 'boolean']),
    size: PropTypes.string,
};

Radio.defaultProps = {
    form: { setFieldValue: () => { } },
    onChange: () => { },
    label: '',
    value: false,
    trackValue: true,
    dataType: 'boolean',
    readOnly: false,
};

Radio.displayName = FORM_COMPONENTS.RADIO;
const FormRadio = Form(Radio);

export {
    Radio,
    FormRadio,
};
