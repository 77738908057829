import { useRef, useEffect } from 'react';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { DEMO_FACILITY_ID_LIST } from 'Commons/config/constants/Constants';
import { isProduction, convertToNumber } from 'Commons/helpers/utils/Utils';
import { AUTHENTICATION_KEY } from '../config/Constants';
import syrapayHostedFormStyle from '../styles/SyrapayHostedFormStyle';

const useStyle = makeStyles(syrapayHostedFormStyle, { name: 'SyrapayMerchantPortal' });

const SyrapayMerchantPortal = ({ facilityId, authenticationKey }) => {
    const classes = useStyle();
    const submitButtonRef = useRef(null);

    useEffect(() => {
        if (authenticationKey) submitButtonRef.current.click();
    }, [authenticationKey]);

    const getMerchantPortalUrl = () => {
        if (isProduction()) {
            return DEMO_FACILITY_ID_LIST.includes(convertToNumber(facilityId))
                ? process.env.TEST_MERCHANT_PORTAL_URL : process.env.MERCHANT_PORTAL_URL;
        }
        return process.env.MERCHANT_PORTAL_URL;
    };

    return (
        <form
            action={getMerchantPortalUrl()}
            method="post"
            target="_blank"
        >
            <input
                type="hidden"
                id={AUTHENTICATION_KEY}
                name={AUTHENTICATION_KEY}
                defaultValue={authenticationKey}
                key={authenticationKey}
            />
            <button
                type="submit"
                className={classes.hidden}
                ref={submitButtonRef}
            />
        </form>
    );
};

SyrapayMerchantPortal.propTypes = {
    facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    authenticationKey: PropTypes.string,
};

SyrapayMerchantPortal.defaultProps = { authenticationKey: '' };

export default SyrapayMerchantPortal;
