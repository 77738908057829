import theme from 'Commons/theme/Theme';
import { POSITIONS } from 'Commons/components/business/bev/config/Constants';
import localisable from '../../../../config/strings/localisable';

const { CENTER, LEFT, TOP } = POSITIONS;

const CANVAS_CONFIG = {
    selectionKey: ['ctrlKey', 'shiftKey'],
    selectionFullyContained: true,
    hoverCursor: 'Pointer',
    preserveObjectStacking: true,
    backgroundColor: theme.palette.common.white,
    selectionColor: theme.hexWithOpacity(theme.palette.primary.main, 2),
    renderOnAddRemove: false,
};

const DEFAULT_VIEWPORT_TRANSFORM = [1, 0, 0, 1, 0, 0];

const CANVAS_DIMENSIONS = {
    setupModeDimensions: {
        width: 16 * 300,
        height: 9 * 200,
    },
    viewModeDimensions: {
        width: 16 * 150,
        height: 9 * 150,
    },
};

const CANVAS_BOUNDARY_CONFIG = {
    width: CANVAS_DIMENSIONS.setupModeDimensions.width,
    height: CANVAS_DIMENSIONS.setupModeDimensions.height,
    fill: '',
    opacity: 0,
    selectable: false,
};

const OBJECT_CONFIG = {
    hasBorders: false,
    perPixelTargetFind: true,
    cornerSize: 17,
    _controlsVisibility: {
        bl: false, // bottom left
        br: false, // bottom right
        mb: false, // mid bottom
        ml: false, // mid left
        mr: false, // mid right
        mt: false, // mid top
        tl: false, // top left
        tr: true, // top right
        mtr: false, // mid top rotate
    },
};

const UNIT_CONFIG = {
    getMagnification: (isFacilityMigrated = false) => (isFacilityMigrated ? 0.6 : 0.7),
    DEFAULT_BACKGROUND: theme.palette.bev.defaultUnitBackground,
    PADDING: 2,
    TOP_BOTTOM_TOOLTIP_PADDING: 16,
    LEFT_RIGHT_TOOLTIP_PADDING: 16,
    TOOLTIP_OFFSET_FROM_POINTER: 10,
    RECT_CONFIG: {
        stroke: theme.hexWithOpacity(theme.palette.common.black, 7),
        originX: CENTER,
        originY: CENTER,
        strokeUniform: true,
    },
    LABEL_CONFIG: {
        fontSize: 10,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        originX: CENTER,
        originY: CENTER,
    },
    TRIANGLE_CONFIG: {
        width: 12,
        height: 6,
        selectable: false,
    },
    TOOLTIP_TEXT_CONFIG: {
        fontSize: 12,
        fontFamily: 'Open sans',
        fontWeight: 600,
        fill: theme.palette.text.primary,
    },
    TOOLTIP_BODY_CONFIG: {
        fill: theme.palette.common.white,
        stroke: theme.palette.common.white,
        shadow: theme.boxShadows[9],
    },
    TOOLTIP_CONFIG: {
        originX: LEFT,
        originY: TOP,
        selectable: false,
    },
    getLabelOverflowTolerance: (isFacilityMigrated = false) => (isFacilityMigrated ? 1 : 0),
    ERRED_COLOR: theme.hexWithOpacity(theme.palette.bev.erredUnit, 10),
};

const ZOOM_BY = {
    IN: 0.101,
    OUT: -0.1,
};

const ZOOM_LIMITS = {
    UPPER: 4.2,
    LOWER: 1,
};

const TIMEOUT = 500; // 500 milliseconds

const ZOOM_VALUE_DISPLAY_OFFSET = (ZOOM_BY.IN + 0.5) * 100; // In percentage

const shortcuts = [
    {
        label: localisable.bevKeyboardShortcutLabel1,
        description: localisable.bevKeyboardShortcutDescription1,
    },
    // {
    //     label: localisable.bevKeyboardShortcutLabel2,
    //     description: localisable.bevKeyboardShortcutDescription2,
    // },
    // {
    //     label: localisable.bevKeyboardShortcutLabel3,
    //     description: localisable.bevKeyboardShortcutDescription3,
    // },
    {
        label: localisable.bevKeyboardShortcutLabel4,
        description: localisable.bevKeyboardShortcutDescription4,
    },
];

const defaultNewObjXY = { x: 20, y: 20 };

const INITIAL_REQUIRED_FIELDS_CONFIG = {
    placeholderType: true,
    unitType: true,
    label: true,
    width: true,
    depth: true,
};

export {
    ZOOM_BY,
    shortcuts,
    UNIT_CONFIG,
    ZOOM_LIMITS,
    CANVAS_CONFIG,
    OBJECT_CONFIG,
    defaultNewObjXY,
    CANVAS_DIMENSIONS,
    CANVAS_BOUNDARY_CONFIG,
    ZOOM_VALUE_DISPLAY_OFFSET,
    DEFAULT_VIEWPORT_TRANSFORM,
    INITIAL_REQUIRED_FIELDS_CONFIG,
    TIMEOUT,
};
