
const DEFAULT_PADDING = 28;
const PADDING_HIKE = 16;
const SCROLL_TIME = 66;
const DEFAULT_SECTION_ID = '-1';

const ALIAS = { ALL: 'all' };

export {
    DEFAULT_PADDING,
    PADDING_HIKE,
    SCROLL_TIME,
    DEFAULT_SECTION_ID,
    ALIAS,
};
