import media from 'Commons/theme/MediaQueries';

const tabsStyle = theme => ({
    tabsRoot: {},
    fullWidth: { width: '100%' },
    flexContainer: { alignItems: 'center' },
    displayNone: { display: 'none' },
    defaultIndicator: { display: 'inline' },
    disabledTab: {
        cursor: 'default',
        pointerEvents: 'none',
    },
    disabledPadding: { paddingBottom: 8 },
    tabs: {
        minWidth: 'auto',
        '& > span': {
            display: 'inline-block',
            textAlign: 'left',
            fontFamily: 'Open Sans',
            textTransform: 'capitalize',
            [media.mobileUp]: { textTransform: 'uppercase' },
            '&:after': {
                content: 'close-quote',
                display: 'block',
                transition: 'transform 150ms ease-in-out',
                transformOrigin: '0% 50%',
            },
        },
        fontSize: '1rem',
        padding: theme.spacing(1, 2),
    },
    default: {},
    halfBottomAlways: { '& > span': { '&:after': { transform: 'scaleX(0.2)' } } },
    halfBottomOnHover: {
        '& > span': {
            '&:after': { transform: 'scaleX(0)' },
            '&:hover': { '&:after': { transform: 'scaleX(0.2)' } },
        },
    },
    selected: {
        '& > span': {
            '&:after': { transform: 'scaleX(1)' },
            '&:hover': { '&:after': { transform: 'scaleX(1)' } },
        },
        color: theme.palette.primary.main,
    },
    tabOpacity: { opacity: 1 },

    primaryBorder: { '& > span': { '&:after': { borderBottom: `solid 3px ${theme.palette.primary.main}` } } },
    secondaryBorder: { '& > span': { '&:after': { borderBottom: `solid 3px ${theme.palette.secondary.main}` } } },
    tertiaryBorder: { '& > span': { '&:after': { borderBottom: `solid 3px ${theme.palette.tertiary.main}` } } },

    /* Specific to Roles Group. TODO: Give generic color name */
    rolesGroupPrimaryBorder: { '& > span': { '&:after': { borderBottom: `solid 3px ${theme.palette.rolesGroup.primary}` } } },
    rolesGroupSecondaryBorder: { '& > span': { '&:after': { borderBottom: `solid 3px ${theme.palette.rolesGroup.secondary}` } } },
    rolesGroupTertiaryBorder: { '& > span': { '&:after': { borderBottom: `solid 3px ${theme.palette.rolesGroup.tertiary}` } } },
    separatorPadding: { paddingRight: theme.spacing(2) },
});

export default tabsStyle;
