import localisable from 'Commons/config/strings/localisable';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import { Loader } from 'Commons/components/generic/componentlibrary/components/Components';
import Icon from 'Commons/components/generic/icon/components/Icon';
import Typography from 'Commons/components/generic/typography/components/Typography';

const ScanBarcodeButton = ({ open, onClick, LabelWithIconProps }) => (
    <LabelWithIcon
        addon={{
            start: open
                ? <Loader disableShrink size={20} />
                : <Icon type="custom" icon="cp-bar-code" color="primary" />, // Change to barcode icon when uploaded
        }}
        onClick={onClick}
        {...LabelWithIconProps}
    >
        <Typography variant="body2" color="primary" noWrap>
            {open ? localisable.scanningBarcode : localisable.scanBarcode}
        </Typography>
    </LabelWithIcon>
);

ScanBarcodeButton.propTypes = {
    open: PropTypes.bool,
    onClick: PropTypes.func,
    LabelWithIconProps: PropTypes.any,
};

export default ScanBarcodeButton;
