import { CONFIG_RESOURCE, SOURCE } from 'Commons/config/constants/Constants';
import STORE_KEY from 'Commons/redux/config/StoreKey';
import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const RESOURCE_NAME_TO_STORE_KEY = {
    user_role: SOURCE.userRole.value,
    unit: SOURCE.unit.value,
    ledger: SOURCE.ledger.value,
    tenant: SOURCE.tenant.value,
    account_receivables: SOURCE.ar.value,
    saved_document: STORE_KEY.SAVED_DOCUMENT,
};

const CONFIGURATION_RESOURCE_NAME_MAPPING = {
    customer_configuration: CONFIG_RESOURCE.CUSTOMER_CONFIGURATION,
    financial_configuration: CONFIG_RESOURCE.FINANCIAL_CONFIGURATION,
    facility_configuration: CONFIG_RESOURCE.FACILITY_CONFIGURATION,
};

const DEFAULT_CALLBACK_FOR_SNACKBAR_ACTION_UPDATE = () => false;

const EXCEPTION_ERROR_CODE = createEnumMap(['No_Data_Error']);

export {
    RESOURCE_NAME_TO_STORE_KEY, DEFAULT_CALLBACK_FOR_SNACKBAR_ACTION_UPDATE,
    CONFIGURATION_RESOURCE_NAME_MAPPING, EXCEPTION_ERROR_CODE,
};
