import { mapEnum } from 'Commons/helpers/utils/Formatter';

const additionalDeliveryMethod = ['Email_Lease_For_Esign'];

const deliveryMethod = ['Email', 'Text', 'Print_And_Email', 'Print', 'Preview', 'Save_Document'];
const dropdownPrinterSelectTypes = ['Inline', 'Stacked', 'Popover'];
const DROPDOWN_PRINTER_SELECTION_TYPES = mapEnum(dropdownPrinterSelectTypes);
// const deliveryMethod = ['Email', 'Text', 'Print_And_Email', 'Text_And_Email', 'Print'];

// const deliveryMethod = ['Print', 'Email', 'Text', 'Print_And_Email', 'Text_And_Email', 'Print_To_Mail'];
const DELIVERY_METHOD = mapEnum(deliveryMethod);

const ADDITIONAL_DELIVERY_METHOD = mapEnum(additionalDeliveryMethod);

const PRINT_COPIES_TEXTFIELD_WIDTH = 85;

const RETRY = 5;

const INTERVAL = 5000;

export { INTERVAL, RETRY, DELIVERY_METHOD, deliveryMethod, ADDITIONAL_DELIVERY_METHOD, PRINT_COPIES_TEXTFIELD_WIDTH, DROPDOWN_PRINTER_SELECTION_TYPES, dropdownPrinterSelectTypes };
