
import Typography from 'Commons/components/generic/typography/components/Typography';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import { getYear } from 'Commons/helpers/utils/DateTime';
import localisable from 'Commons/config/strings/localisable';
import Icon from 'Commons/components/generic/icon/components/Icon';
import { memo } from 'react';
import listItemsStyle from '../styles/ListItemsStyle';

const useStyles = makeStyles(listItemsStyle, { name: 'Copyright' });

const Copyright = ({ open }) => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" justify="center" className={classes.footerTextDiv}>
            {
                open
                    ? (
                        <Typography
                            className={classes.footerText}
                            variant="subtitle1"
                            noWrap
                            style={{ overflow: 'hidden' }}
                        >
                            {localisable.allRightReserved} {getYear()}
                        </Typography>
                    )
                    : <Icon className={classes.copyrightIcon}>copyright</Icon>
            }
        </Grid>
    );
};

Copyright.propTypes = { open: PropTypes.bool };

Copyright.defaultProps = { open: false };

export default memo(Copyright);
