const RangeFilterStyle = theme => ({
    firstLabel: { paddingBottom: '16px' },
    restLabel: {
        paddingTop: '32px',
        paddingBottom: '16px',
    },
    dateRangeLabel: { padding: theme.spacing(3, 0, 2, 0) },
    input: { paddingBottom: theme.spacing(2) },
    clearBalance: {
        alignSelf: 'flex-end',
        paddingTop: theme.spacing(1),
    },
});

export default RangeFilterStyle;
