/**
 * @description Calculates the priorities based on the critical config
 * @param {Object} critical The critical config
 */
const calculate = (critical = {}) => {
    const priorities = {};
    const criticalKeys = Object.keys(critical);
    if (critical) {
        criticalKeys.forEach((key) => {
            // If no dependency, just push with priority 1
            if (!critical[key].dependency) {
                priorities[key] = 1;
            } else if (critical[key].dependency) {
                // If there is a dependency, then calculate the current keys priority, and increment by
                // adding dependee keys priorities
                const newPriority = critical[key].dependency.reduce(
                    (prev, cur) => ({
                        priority: (priorities[prev] || 1) + (priorities[cur] || 1),
                        key,
                    }),
                    {
                        priority: 1,
                        key,
                    },
                );
                // Once calculated push to the priorities
                // priorities.push(newPriority);
                priorities[key] = newPriority.priority;
            }
        });
    }
    return priorities;
};

const indexByPriorities = (priorities) => {
    const prioritiesIndex = {};
    // TODO: Object.keys is currently retaining the order, need to revisit in ES8
    Object.keys(priorities).forEach((key) => {
        if (prioritiesIndex[priorities[key]] && prioritiesIndex[priorities[key]].length > 0) {
            prioritiesIndex[priorities[key]].push(key);
        } else {
            prioritiesIndex[priorities[key]] = [key];
        }
    });
    return prioritiesIndex;
};

const groupPriorities = (priorities) => {
    const prioritiesListByIndex = indexByPriorities(priorities);
    return Object.values(prioritiesListByIndex);
};
/**
 * @description Given a config, this returns the priority for each critical keys
 * and its dependencies
 * @param {*} config The keys that were extracted from dependency config for a route/component
 * @param {*} pathTraversed The path that has been traversed so far
 */
const calculatePriority = ({ critical = {} }) => {
    const priorities = calculate(critical);
    return groupPriorities(priorities);
};

export default calculatePriority;
