import { renderInPdf } from 'Commons/components/business/report/utils/Utils';
import { ENTITY_LEVEL } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Snackbar from 'Generic/snackbar/components/Snackbar';
import { VARIANT } from 'Generic/snackbar/config/Constants';
import ThreeDotProgress from 'Generic/threedotprogress/components/ThreeDotProgress';
import Typography from 'Generic/typography/components/Typography';
import { useEffect, useRef, useState } from 'react';
import { generateReport } from '../config/Api';
import reportStyle from '../styles/ReportStyle';

const useStyles = makeStyles(reportStyle, { name: 'ViewReport' });

const ViewReport = ({
    onAction,
    onClose,
    reportId,
    entityId,
    accountId,
    reportTitle,
    reportType,
    entityType,
    requestBody,
}) => {
    const [openModal, setOpenModal] = useState(true);
    const classes = useStyles();
    const DialogContentProps = useConstructor(() => ({ classes: { root: classes.dialogContentRoot } }));
    const error = useRef(false);

    const onReportGenerate = (err, response) => {
        if (err) {
            error.current = true;
        } else if (response) {
            error.current = false;
            const { data: pdfUrl } = response;
            if (pdfUrl) {
                renderInPdf(pdfUrl, reportTitle);
            } else {
                error.current = true;
            }
        }
        setOpenModal(false);
        onClose(false);
    };

    const getReport = () => {
        setOpenModal(true);
        error.current = false;
        const reportIdOrType = reportId || reportType;
        if (!reportIdOrType) {
            throw new Error('Report ID or Report Type is required.');
        }
        generateReport(onAction, requestBody, reportIdOrType, entityType, entityId, accountId, onReportGenerate);
    };

    useEffect(() => {
        getReport(); // Fetching Report
    }, []);

    return (
        <>
            <AlertDialog
                open={openModal}
                DialogContentProps={DialogContentProps}
                useContentText={false}
                fullWidth
                hideCloseButton
                onClose={onClose}
                maxWidth="sm"
            >
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <ThreeDotProgress />
                    </Grid>
                    <Grid item>
                        <Typography>{localisable.fetchingReport}</Typography>
                    </Grid>
                </Grid>
            </AlertDialog>
            {error.current && <Snackbar variant={VARIANT.error.value} title={localisable.somethingWentWrong} />}
        </>
    );
};

ViewReport.propTypes = {
    onClose: PropTypes.func,
    reportType: PropTypes.string,
    entityType: PropTypes.string,
    reportTitle: PropTypes.string,
    onAction: PropTypes.func.isRequired,
    requestBody: PropTypes.object.isRequired,
    reportId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

ViewReport.defaultProps = { entityType: ENTITY_LEVEL.Facility.value };


export default React.memo(ViewReport);
