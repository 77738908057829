import { SketchPicker } from 'react-color';
import { useRef, useState } from 'react';
import {
    makeStyles, Popper, FormControlLabel,
    ClickAwayListener,
} from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import { clsx } from 'Commons/helpers/utils/clsx';
import Form from 'Generic/form/components/Form';
import DEFAULT_COLOR_PICKER_COLOR from 'Generic/colorPicker/config/Constants';
import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import colorPickerStyle from '../styles/ColorPickerStyle';

const useStyles = makeStyles(colorPickerStyle, { name: 'ColorPicker' });

const ColorPicker = ({
    onChange, label, disableAlpha = true, classes: classesProp, buttonClassName, trackValue, value, readOnly,
    PopperProps: { className: popperClassName, parentRef, ...PopperProps }, formLabelClassName,
    FormControlLabelProps: { className: formLabelClass, ...otherLabelProps } = {},
}) => {
    const coloredSquareRef = useRef();
    const [isColorPickerOpen, openColorPicker] = useState(false);
    const [color, setColor] = useState({ hex: value || DEFAULT_COLOR_PICKER_COLOR.hex });

    const classes = useStyles({ classes: classesProp, color });

    const handleOpenClose = () => {
        openColorPicker(!isColorPickerOpen);
    };

    const handleChange = (newColor) => {
        const rgbColorValue = newColor.rgb;
        const alphaInHex = (Math.round(rgbColorValue.a * 255)).toString(16);
        let hexWithAlpha = newColor.hex + (alphaInHex.length === 1 ? `0${alphaInHex}` : alphaInHex);
        if (disableAlpha) { // This will override the alpha channel value and set it to FF (0 transparency)
            rgbColorValue.a = 1;
            hexWithAlpha = `${newColor.hex}ff`;
        }
        const colorValues = {
            hex: hexWithAlpha,
            rgb: rgbColorValue,
        };
        setColor(colorValues);
        onChange({}, hexWithAlpha);
    };

    const renderColorPicker = () => (
        <>
            <Button
                ref={coloredSquareRef}
                type="button"
                className={clsx(classes.coloredSquare, buttonClassName, readOnly && classes.readOnly)}
                onClick={label ? EMPTY_FUNC : handleOpenClose}
            />
            {isColorPickerOpen && (
                <Popper
                    open={isColorPickerOpen}
                    anchorEl={coloredSquareRef.current}
                    placement="bottom-start"
                    className={clsx(classes.popper, popperClassName)}
                    disablePortal
                    modifiers={{
                        flip: { enabled: true },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: parentRef || 'scrollParent',
                        },
                    }}
                    {...PopperProps}
                >
                    <ClickAwayListener mouseEvent={isColorPickerOpen && 'onClick'} onClickAway={handleOpenClose}>
                        <SketchPicker
                            color={trackValue ? color : { hex: value || DEFAULT_COLOR_PICKER_COLOR.hex }}
                            onChange={handleChange}
                            presetColors={[]}
                            disableAlpha={disableAlpha}
                        />
                    </ClickAwayListener>
                </Popper>
            )}
        </>
    );

    return (
        label
            ? (
                <FormControlLabel
                    className={clsx(classes.formLabel,
                        formLabelClass,
                        formLabelClassName,
                        readOnly && classes.readOnly)}
                    control={renderColorPicker()}
                    label={label}
                    onClick={handleOpenClose}
                    {...otherLabelProps}
                />
            )
            : renderColorPicker()
    );
};

ColorPicker.propTypes = {
    onChange: PropTypes.func,
    classes: PropTypes.object,
    buttonClassName: PropTypes.string,
    PopperProps: PropTypes.object,
    label: PropTypes.node,
    name: PropTypes.string,
    trackValue: PropTypes.bool,
    formLabelClassName: PropTypes.string,
    FormControlLabelProps: PropTypes.object,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    disableAlpha: PropTypes.bool,
};

ColorPicker.defaultProps = {
    onChange: () => { },
    PopperProps: {},
    trackValue: true,
    readOnly: false,
    disableAlpha: true,
};

ColorPicker.displayName = FORM_COMPONENTS.COLOR_PICKER;
const FormColorPicker = Form(ColorPicker);

export default ColorPicker;

export { FormColorPicker };
