import localisable from 'Commons/config/strings/localisable';
import { capitalize } from 'Commons/helpers/utils/DataHelpers';

export const accountData = ({
    isModeCreate,
    isStandardUser = false,
}) => [
    {
        parent: true,
        children: [

            {
                label: localisable.userManagement,
                name: 'permission.account.setup.userManagement',
                subTitle: [
                    { subLabel: localisable.userManagementSubTitle },
                    { subLabel: localisable.userManagementSubTitle2 },
                ],
                header: true,
                isVisible: !isStandardUser || (!isModeCreate && isStandardUser),
            },
            {
                label: localisable.businessGroupManagement,
                name: 'permission.account.setup.businessGroupManagement',
                subTitle: [
                    { subLabel: localisable.businessGroupPermissions },
                ],
                header: true,
            },
            {
                label: localisable.editFacility,
                name: 'permission.account.setup.facilityManagement',
                subTitle: [
                    { subLabel: localisable.facilityManagementSubtitle },
                ],
                header: true,
            }],
        permissionLevel: [{ label: localisable.accountCamel }],
    },
];

export const configurationData = (facilityConfig, businessGroupConfig) => [
    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.configurationDeactivatePermission,
                name: 'permission.facility.configurations.deactivateConfig',
                subTitle: [
                    { subLabel: localisable.configurationDeactivatePermissionLabel },
                ],
                header: true,
            },
            {
                label: localisable.createOrEditConfigs,
                name: 'permission.facility.configurations.createEdit.access',
                subTitle: [
                    { subLabel: localisable.createOrEditConfigsLabel },
                ],
                header: true,
            },
            {
                label: localisable.financials,
                name: 'permission.facility.configurations.createEdit.financials',
                subTitle: [
                    { subLabel: localisable.financialsConfigLevel },
                ],
                key: facilityConfig,
                disabled: !facilityConfig,
            },
            {
                label: localisable.financialGrouping,
                name: 'permission.facility.configurations.createEdit.financialGrouping',
                subTitle: [
                    { subLabel: localisable.financialGroupingLabel },
                ],
                key: facilityConfig,
                disabled: !facilityConfig,
            },
            {
                label: localisable.unitType,
                name: 'permission.facility.configurations.createEdit.unitType',
                subTitle: [
                    { subLabel: localisable.unitTypeConfigLabel },
                ],
                key: facilityConfig,
                disabled: !facilityConfig,
            },
            {
                label: localisable.tfwConfigPerm,
                name: 'permission.facility.configurations.createEdit.tenantFacingWebsite',
                subTitle: [
                    { subLabel: localisable.tfwConfigPermLabel },
                ],
                key: facilityConfig,
                disabled: !facilityConfig,
            },
            {
                label: localisable.custRelatedPerm,
                name: 'permission.facility.configurations.createEdit.customer',
                subTitle: [
                    { subLabel: localisable.custRelatedPermLabel },
                ],
                key: facilityConfig,
                disabled: !facilityConfig,
            },
            {
                label: localisable.occupancy,
                name: 'permission.facility.configurations.createEdit.occupancy',
                subTitle: [
                    { subLabel: localisable.occupancyPermLabel },
                ],
                key: facilityConfig,
                disabled: !facilityConfig,
            },
            {
                label: localisable.delinquencyAndGates,
                name: 'permission.facility.configurations.createEdit.delinquency',
                subTitle: [
                    { subLabel: localisable.delinquencyAndGatesLabel },
                ],
                key: facilityConfig,
                disabled: !facilityConfig,
            },
            {
                label: localisable.letterConfigPerm,
                name: 'permission.facility.configurations.createEdit.letter',
                subTitle: [
                    { subLabel: localisable.letterPermLabel },
                ],
                key: facilityConfig,
                disabled: !facilityConfig,
            },
        ],
        permissionLevel: [{ label: localisable.facility }],

    },
    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.configurationDeactivatePermission,
                name: 'permission.businessGroup.configurations.deactivateConfig',
                subTitle: [
                    { subLabel: localisable.configurationDeactivatePermissionLabel },
                ],
                header: true,
            },
            {
                label: localisable.createOrEditConfigs,
                name: 'permission.businessGroup.configurations.createEdit.access',
                subTitle: [
                    { subLabel: localisable.createOrEditConfigsLabel },
                ],
                header: true,
            },
            {
                label: localisable.financialGrouping,
                name: 'permission.businessGroup.configurations.createEdit.financialGrouping',
                subTitle: [
                    { subLabel: localisable.financialGroupingLabel },
                ],
                key: businessGroupConfig,
                disabled: !businessGroupConfig,
            },
            {
                label: localisable.occupancy,
                name: 'permission.businessGroup.configurations.createEdit.occupancy',
                subTitle: [
                    { subLabel: localisable.occupancyPermLabel },
                ],
                key: businessGroupConfig,
                disabled: !businessGroupConfig,
            },
            {
                label: localisable.delinquencyAndGates,
                name: 'permission.businessGroup.configurations.createEdit.delinquency',
                subTitle: [
                    { subLabel: localisable.delinquencyAndGatesLabel },
                ],
                key: businessGroupConfig,
                disabled: !businessGroupConfig,
            },
            {
                label: localisable.letterConfigPerm,
                name: 'permission.businessGroup.configurations.createEdit.letter',
                subTitle: [
                    { subLabel: localisable.letterPermLabel },
                ],
                key: businessGroupConfig,
                disabled: !businessGroupConfig,
            },
        ],
        permissionLevel: [{ label: localisable.businessGroup }],

    },
];

export const reportsData = [
    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.corporateReports,
                name: 'permission.account.reports.consolidatedReports',
                subTitle: [
                    { subLabel: localisable.corporateReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.standardCategories,
                name: 'permission.account.reports.standardCategories.general',
                subTitle: [
                    { subLabel: localisable.reportsGeneral },
                ],
                header: true,
            },
            {
                label: localisable.auditReports,
                name: 'permission.account.reports.standardCategories.auditReports',
                subTitle: [
                    { subLabel: localisable.auditReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.incomeReports,
                name: 'permission.account.reports.standardCategories.incomeReports',
                subTitle: [
                    { subLabel: localisable.incomeReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.managementReportsTitle,
                name: 'permission.account.reports.standardCategories.managementReports',
                subTitle: [
                    { subLabel: localisable.managementReports },
                ],
                header: true,
            },
            {
                label: localisable.customCategories,
                name: 'permission.account.reports.customCategories.addOrEdit',
                subTitle: [
                    { subLabel: localisable.addOrEdit },
                ],
                header: true,
            },
            {
                label: localisable.reprintReceipts,
                subTitle: [
                    { subLabel: localisable.reprintReceiptsPermission },
                ],
                header: true,
                shallOmit: true,
            },
        ],
        permissionLevel: [{ label: localisable.accountCamel }],
    },
    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.corporateReports,
                name: 'permission.businessGroup.reports.consolidatedReports',
                subTitle: [
                    { subLabel: localisable.corporateReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.standardCategories,
                name: 'permission.businessGroup.reports.standardCategories.general',
                subTitle: [
                    { subLabel: localisable.reportsGeneral },
                ],
                header: true,
            },
            {
                label: localisable.auditReports,
                name: 'permission.businessGroup.reports.standardCategories.auditReports',
                subTitle: [
                    { subLabel: localisable.auditReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.incomeReports,
                name: 'permission.businessGroup.reports.standardCategories.incomeReports',
                subTitle: [
                    { subLabel: localisable.incomeReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.managementReportsTitle,
                name: 'permission.businessGroup.reports.standardCategories.managementReports',
                subTitle: [
                    { subLabel: localisable.managementReports },
                ],
                header: true,
            },
            {
                label: localisable.customCategories,
                name: 'permission.businessGroup.reports.customCategories.addOrEdit',
                subTitle: [
                    { subLabel: localisable.addOrEdit },
                ],
                header: true,
            },
            {
                label: localisable.reprintReceipts,
                subTitle: [
                    { subLabel: localisable.reprintReceiptsPermission },
                ],
                header: true,
                shallOmit: true,
            },
        ],
        permissionLevel: [{ label: localisable.businessGroup }],
    },
    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.corporateReports,
                name: 'permission.facility.reports.consolidatedReports',
                subTitle: [
                    { subLabel: localisable.corporateReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.standardCategories,
                name: 'permission.facility.reports.standardCategories.general',
                subTitle: [
                    { subLabel: localisable.reportsGeneral },
                ],
                header: true,
            },
            {
                label: localisable.auditReports,
                name: 'permission.facility.reports.standardCategories.auditReports',
                subTitle: [
                    { subLabel: localisable.auditReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.incomeReports,
                name: 'permission.facility.reports.standardCategories.incomeReports',
                subTitle: [
                    { subLabel: localisable.incomeReportsPermission },
                ],
                header: true,
            },
            {
                label: localisable.managementReportsTitle,
                name: 'permission.facility.reports.standardCategories.managementReports',
                subTitle: [
                    { subLabel: localisable.managementReports },
                ],
                header: true,
            },
            {
                label: localisable.customCategories,
                name: 'permission.facility.reports.customCategories.addOrEdit',
                subTitle: [
                    { subLabel: localisable.addOrEdit },
                ],
                header: true,
            },
            {
                label: localisable.reprintReceipts,
                name: 'permission.facility.documentation.reprintReceipts',
                subTitle: [
                    { subLabel: localisable.reprintReceiptsPermission },
                ],
                header: true,
            },
        ],
        permissionLevel: [{ label: localisable.facility }],
    },
];

export const tenantData = [
    {
        parent: true,
        label: '',
        header: true,
        children: [

            {
                label: capitalize(localisable.tenantInformation, true),
                name: 'permission.facility.tenant.edit.general',
                subTitle: [
                    { subLabel: localisable.tenantsGeneral },
                ],
            },
            { label: localisable.secureInformation, shallOmit: true, isHeadingOnly: true },
            {
                subTitle: [{ subLabel: localisable.tenantsAllowSecureInfo }],
                name: 'permission.facility.tenant.edit.allowSecureInfo',
            },
            {
                subTitle: [{ subLabel: localisable.viewSecureInfo }],
                name: 'permission.facility.tenant.viewSecureInfo',
            },
            {
                label: localisable.lateExemption,
                name: 'permission.facility.tenant.edit.allowLateExemption',
                subTitle: [
                    { subLabel: localisable.AllowLateExemption },
                ],
            },
            {
                label: localisable.gateExemption,
                name: 'permission.facility.tenant.edit.allowGateExemption',
                subTitle: [
                    { subLabel: localisable.AllowGateExemption },
                ],
            },
            {
                label: localisable.contactLog,
                name: 'permission.facility.tenant.contactLog',
                subTitle: [
                    { subLabel: localisable.tenantsContactLog },
                ],
            },
            {
                label: localisable.modifyDeposit,
                name: 'permission.facility.tenant.edit.deposit',
                subTitle: [
                    { subLabel: localisable.tenantsDeposit },
                ],
            },
            {
                label: localisable.composeEmailText,
                name: 'permission.facility.tenant.composeCustomEmail',
                subTitle: [
                    { subLabel: localisable.composeCustomEmail },
                ],
            },
            {
                label: localisable.modifyActiveStatus,
                name: 'permission.facility.tenant.edit.status',
                subTitle: [
                    { subLabel: localisable.tenantsStatus },
                ],
            },

        ],
        permissionLevel: [{ label: localisable.facility }],

    },


];
export const unitData = general => [
    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.generalDetails,
                name: 'permission.facility.unit.edit.general',
                subTitle: [
                    { subLabel: localisable.unitsGeneral },
                ],
                header: true,
            },
            {
                label: localisable.currentRate,
                name: 'permission.facility.unit.edit.currentRate',
                subTitle: [
                    { subLabel: localisable.currentRateDescription },
                ],
                key: general,
                disabled: !general,
            },
            {
                label: localisable.NextRentAssessmentDateLabel,
                name: 'permission.facility.unit.edit.nextRentAssessmentDate',
                subTitle: [
                    { subLabel: localisable.NextRentAssessmentDate },
                ],
                key: general,
                disabled: !general,
            },
            {
                label: localisable.PaidToDateLabel,
                name: 'permission.facility.unit.edit.paidToDate',
                subTitle: [
                    { subLabel: localisable.PaidToDate },
                ],
                key: general,
                disabled: !general,
            },
            {
                label: localisable.unitTypeDimension,
                name: 'permission.facility.unit.edit.unitType',
                subTitle: [
                    { subLabel: localisable.unitsUnitType },
                ],
                key: general,
                disabled: !general,
            },
            {
                label: localisable.inventoryReport,
                name: 'permission.facility.unit.edit.inventoryReport',
                subTitle: [
                    { subLabel: localisable.unitsInventoryReport },
                ],
                key: general,
                disabled: !general,
            },
            {
                label: capitalize(localisable.moveInDate, true),
                name: 'permission.facility.unit.edit.moveInDate',
                subTitle: [
                    { subLabel: localisable.unitsMoveInDate },
                ],
                key: general,
                disabled: !general,
            },
            {
                label: localisable.rentalStatus,
                name: 'permission.facility.unit.edit.rentalStatus',
                subTitle: [
                    { subLabel: localisable.unitsRentalStatus },
                ],
                key: general,
                disabled: !general,
            },

        ],
        permissionLevel: [
            { label: localisable.facility },
        ],
    },
];

export const occupancyData = (isMoveInDisabled, isMoveOutDisabled, performReservation) => [{
    parent: true,
    headlabel: '',
    children: [
        {
            label: localisable.moveIn,
            name: 'permission.facility.occupancy.moveIn.access',
            header: true,
            subTitle: [{ subLabel: localisable.userMoveIn }],
        },
        {
            label: capitalize(localisable.moveInDate, true),
            name: 'permission.facility.occupancy.moveIn.editMoveInDate',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.editMoveInDate }],
        },
        {
            label: localisable.increaseCurrentRate,
            name: 'permission.facility.occupancy.moveIn.unitRateUp',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.unitRateUp }],
        },
        {
            label: localisable.decreaseCurrentRate,
            name: 'permission.facility.occupancy.moveIn.unitRateDown',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.unitRateDown }],
        },
        {
            label: localisable.setupFee,
            name: 'permission.facility.occupancy.moveIn.editSetupFee',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.editSetupFee }],

        },
        {
            label: localisable.waiveSetupFeeLabel,
            name: 'permission.facility.occupancy.moveIn.waiveSetupFee',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.waiveSetupFee }],
        },
        {
            label: localisable.depositLabel,
            name: 'permission.facility.occupancy.moveIn.editDeposits',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.editDeposits }],
        },
        {
            label: localisable.waiveDepositLabel,
            name: 'permission.facility.occupancy.moveIn.waiveDeposits',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.waiveDeposits }],
        },
        {
            label: localisable.prorateAmount,
            name: 'permission.facility.occupancy.moveIn.waiveProrate',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.waiveProrate }],
        },
        {
            label: capitalize(localisable.billToDate, true),
            name: 'permission.facility.occupancy.moveIn.billToDate',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.modifyBillTodate }],
        },
        {
            label: localisable.periodLabel,
            name: 'permission.facility.occupancy.moveIn.nonStandardPeriods',
            disabled: isMoveInDisabled,
            key: isMoveInDisabled,
            subTitle: [{ subLabel: localisable.nonStandardPeriods }],
        },

        {
            label: localisable.moveOut,
            name: 'permission.facility.occupancy.moveOut.access',
            header: true,
            subTitle: [{ subLabel: localisable.performMoveOut }],

        },
        {
            label: localisable.moveOutDateLabel,
            name: 'permission.facility.occupancy.moveOut.editMoveOutDate',
            disabled: isMoveOutDisabled,
            key: isMoveOutDisabled,
            subTitle: [{ subLabel: localisable.moveOutDate }],
        },
        {
            label: localisable.waiveNotificationPenaltyLabel,
            name: 'permission.facility.occupancy.moveOut.waiveNotificationPenalty',
            disabled: isMoveOutDisabled,
            key: isMoveOutDisabled,
            subTitle: [{ subLabel: localisable.waiveNotificationPenalty }],
        },
        {
            label: localisable.overrideResolveChargesLabel,
            name: 'permission.facility.occupancy.moveOut.overrideResolveCharges',
            disabled: isMoveOutDisabled,
            key: isMoveOutDisabled,
            subTitle: [{ subLabel: localisable.overrideResolveCharges }],

        },

        {
            label: localisable.reservation,
            header: true,
            name: 'permission.facility.reservation.access',
            subTitle: [{ subLabel: localisable.reservationSubtitle }],
        },
        {
            label: localisable.waiveDepositLabel,
            name: 'permission.facility.reservation.waiveDeposit',
            subTitle: [{ subLabel: localisable.reservationWaiveDeposit }],
            key: performReservation,
            disabled: performReservation,
        },
    ],
    permissionLevel: [{ label: localisable.facility }],

}];
export const financialsData = (ispayment, isManualCharge, isReverse, isDelete) => [

    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.payment,
                header: true,
                name: 'permission.facility.financials.payment.access',
                subTitle: [{ subLabel: localisable.userPayment }],
            },
            {
                label: localisable.effectiveDate,
                name: 'permission.facility.financials.payment.editEffectiveDate',
                subTitle: [{ subLabel: localisable.paymentModifyEffectiveDate }],
                key: !ispayment,
                disabled: !ispayment,
            },
            {
                label: localisable.paymentReverseLabel,
                name: 'permission.facility.financials.payment.reverse',
                subTitle: [{ subLabel: localisable.paymentReverse }],
                key: !ispayment,
                disabled: !ispayment,
            },
            {
                label: localisable.paymentDeleteLabel,
                name: 'permission.facility.financials.payment.delete',
                subTitle: [{ subLabel: localisable.paymentDelete }],
                key: !ispayment,
                disabled: !ispayment,
            },
            {
                label: localisable.refunds,
                name: 'permission.facility.financials.refunds',
                header: true,
                subTitle: [{ subLabel: localisable.refundsConfig }],
            },
            {
                label: localisable.manualCharges,
                subTitle: [{ subLabel: localisable.chargeAccess }],
                header: true,
                name: 'permission.facility.financials.charge.manualCharge.access',

            },
            {
                label: localisable.negativeAmountLabel,
                name: 'permission.facility.financials.charge.manualCharge.negativeAmount',
                subTitle: [{ subLabel: localisable.chargeNegativeAmount }],
                key: !isManualCharge,
                disabled: !isManualCharge,
            },
            {
                label: localisable.secureChargeCategory,
                name: 'permission.facility.financials.charge.manualCharge.secureCategory',
                subTitle: [{ subLabel: localisable.chargeSecureCategory }],
                key: !isManualCharge,
                disabled: !isManualCharge,

            },
            {
                label: localisable.chargeReverse,
                subTitle: [{ subLabel: localisable.chargeReversePermission }],
                header: true,
                name: 'permission.facility.financials.charge.reverse.access',

            },
            {
                label: localisable.secureChargeCategory,
                name: 'permission.facility.financials.charge.reverse.secureCategory',
                subTitle: [{ subLabel: localisable.chargeReverseSecureCategory }],
                key: !isReverse,
                disabled: !isReverse,
            },
            {
                label: localisable.chargeDelete,
                subTitle: [{ subLabel: localisable.chargeDeletePermission }],
                header: true,
                name: 'permission.facility.financials.charge.delete.access',

            },
            {
                label: localisable.secureChargeCategory,
                name: 'permission.facility.financials.charge.delete.secureCategory',
                subTitle: [{ subLabel: localisable.chargeDeleteSecureCategory }],
                key: !isDelete,
                disabled: !isDelete,
            },
            {
                label: localisable.rentAssessment,
                name: 'permission.facility.occupancy.rentAssessment.access',
                subTitle: [{ subLabel: localisable.rentAssessmentText }],
                header: true,
            },
            { label: localisable.rentAdjustment, header: true, shallOmit: true, isHeadingOnly: true },
            {
                subTitle: [{ subLabel: localisable.schedule }],
                name: 'permission.facility.occupancy.rentAdjustment.schedule',
                header: true,
            },
            // To do: Hided for time being. Need to be incorporated in future
            // {
            //     subTitle: [{ subLabel: localisable.rentAdjustmentApply }],
            //     name: 'permission.facility.occupancy.rentAdjustment.apply',
            //     header: true,
            // },
            {
                subTitle: [{ subLabel: localisable.rentAdjustmentWaive }],
                name: 'permission.facility.occupancy.rentAdjustment.waive',
                header: true,
            },
            // To do: Hided for time being. Need to be incorporated in future
            // {
            //     subTitle: [{ subLabel: localisable.rentAdjustmentLease }],
            //     name: 'permission.facility.occupancy.rentAdjustment.lease',
            //     header: true,
            // },
        ],
        permissionLevel: [{ label: localisable.facility }],

    },

];

export const syrapayMerchantPortal = [
    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.transactionSearch,
                name: 'permission.facility.financials.payment.syrapayMerchantPortal.transactionSearch',
                subTitle: [{ subLabel: localisable.transactionSearchSubtitle }],

            },
            {
                label: localisable.reverseOpenTransactions,
                name: 'permission.facility.financials.payment.syrapayMerchantPortal.reverseOpenTransactions',
                subTitle: [{ subLabel: localisable.reverseOpenTransactionsSubtitle }],

            },
            {
                label: localisable.reverseSettledTransactions,
                name: 'permission.facility.financials.payment.syrapayMerchantPortal.reverseSettledTransactions',
                subTitle: [{ subLabel: localisable.reverseSettledTransactions }],

            },
            {
                label: localisable.reporting,
                name: 'permission.facility.financials.payment.syrapayMerchantPortal.reporting',
                subTitle: [{ subLabel: localisable.reportingSubtitle }],

            },
            // {
            //     label: localisable.virtualTerminal,
            //     name: 'permission.facility.financials.payment.syrapayMerchantPortal.virtualTerminal',
            //     subTitle: [{ subLabel: localisable.virtualTerminalSubtitle }],

            // },
            {
                label: localisable.terminalManagement,
                name: 'permission.facility.financials.payment.syrapayMerchantPortal.terminalManagement',
                subTitle: [{ subLabel: localisable.terminalManagementSubtitle }],

            },
            {
                label: localisable.pciManagement,
                name: 'permission.facility.financials.payment.syrapayMerchantPortal.pciManagement',
                subTitle: [{ subLabel: localisable.pciManagementSubtitle }],

            },
        ],
        permissionLevel: [{ label: localisable.facility }],

    },

];

// export const lateEvents = [
//     {
//         parent: true,
//         label: '',
//         children: [
//             {
//                 label: localisable.viewDelinquencyWc,
//                 subTitle: [{ subLabel: localisable.viewDelinquencyWcSubtitle }],
//                 header: true,
//                 children: [
//                     {
//                         label: localisable.assessLateCharges,
//                         name: 'permission.facility.lateEvents.assessLateCharges',
//                         subTitle: [{ subLabel: localisable.assessLateChargesSubtitle }],

//                     },
//                     {
//                         label: localisable.waiveLateCharges,
//                         name: 'permission.facility.lateEvents.waiveLateCharges',
//                         subTitle: [{ subLabel: localisable.waiveLateChargesSubtitle }],

//                     },
//                 ],

//             },


//         ],
//         permissionLevel: [{ label: localisable.facility }],
//     },

// ];

export const retailSaleData = (isSell, isInventoryAudit) => [
    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.sellInventory,
                name: 'permission.facility.retailSale.sell',
                subTitle: [{ subLabel: localisable.retailSaleSell }],
                header: true,
            },
            {
                label: localisable.negativeInventory,
                name: 'permission.facility.retailSale.negativeSale',
                subTitle: [{ subLabel: localisable.retailSaleNegativeSale }],
                key: !isSell,
                disabled: !isSell,
            },
            {
                label: localisable.addInventory,
                name: 'permission.facility.retailSale.addInventoryAtPos',
                subTitle: [{ subLabel: localisable.retailSaleAddInventoryPOS }],
                key: !isSell,
                disabled: !isSell,
            },
            {
                label: localisable.modifyInventory,
                name: 'permission.facility.retailSale.editInventory',
                header: true,
                subTitle: [{ subLabel: localisable.retailSaleEditInventory }],

            },
            {
                label: localisable.restockInventory,
                name: 'permission.facility.retailSale.restock',
                subTitle: [{ subLabel: localisable.retailSaleRestock }],
                header: true,

            },
            {
                label: localisable.returnInventoryLabel,
                name: 'permission.facility.retailSale.returnInventory',
                header: true,
                subTitle: [{ subLabel: localisable.returnInventory }],
            },

            {
                label: localisable.inventoryAuditName,
                name: 'permission.facility.retailSale.inventoryAudit',
                subTitle: [{ subLabel: localisable.inventoryAuditSubTitle }],
                header: true,
            },
            {
                label: localisable.negativeInventory,
                name: 'permission.facility.retailSale.negativeInventory',
                subTitle: [{ subLabel: localisable.negativeInventorySubtitle }],
                key: !isInventoryAudit,
                disabled: !isInventoryAudit,
            },
        ],
        permissionLevel: [{ label: localisable.facility }],
    },


];

export const maintenanceEventData = [

    {
        parent: true,
        label: '',
        children: [
            {
                label: localisable.closeEvent,
                name: 'permission.facility.maintenanceEvent.close',
                subTitle: [{ subLabel: localisable.maintenanceEventClosePermissionDesc }],
                header: true,
            },
            {
                label: localisable.cancelEvent,
                name: 'permission.facility.maintenanceEvent.cancel',
                subTitle: [{ subLabel: localisable.maintenanceEventCancelPermissionDesc }],
                header: true,
            },
        ],
        permissionLevel: [{ label: localisable.facility }],
    },

];

export const systemData = [
    {
        parent: true,
        label: localisable.system,
        children: [
            {
                label: localisable.disableUiAccess,
                name: 'permission.system.disableUiAccess',
                subTitle: [{ subLabel: localisable.disableUiAccessLabel }],
            },
            {
                label: localisable.systemConfigManagement,
                name: 'permission.system.systemConfigManagement',
                subTitle: [{ subLabel: localisable.systemConfigManagementLabel }],
            },
            {
                label: localisable.reportManagement,
                name: 'permission.system.reportManagement',
            },
            {
                label: localisable.esManagement,
                children: [
                    {
                        label: localisable.indexManagement,
                        name: 'permission.system.esManagement.indexManagement',
                    },
                    {
                        label: localisable.dataManagement,
                        name: 'permission.system.esManagement.dataManagement',
                    },
                ],
            },
            {
                label: localisable.startOfDay,
                name: 'permission.system.startOfDay',
            },
            {
                label: localisable.accessLiveData,
                name: 'permission.system.accessLiveData',
            },
            {
                label: localisable.sandbox,
                children: [
                    {
                        label: localisable.modifyRestrictions,
                        name: 'permission.system.sandbox.modifyRestrictions',
                    },
                    {
                        label: localisable.deleteSandbox,
                        name: 'permission.system.sandbox.delete',
                    },
                ],
            },
        ],
        permissionLevel: [{ label: localisable.system }],
    },
];
