import { Grid, withStyles } from '../../../generic/componentlibrary/components/Components';
import actionStyle from '../styles/ActionsStyle';

class Actions extends React.Component {
    render() {
        const { classes, config, ...props } = this.props;
        return (
            <Grid container className={classes.actions} alignItems="center" {...props}>
                {
                    config.map((item = {}, index) => {
                        const {
                            component,
                            action,
                            disabled = false,
                        } = item;
                        const isDisabled = typeof disabled === 'function' ? disabled() : disabled;
                        const eventHandler = isDisabled ? {} : { ...action };
                        const key = `${index}-${index}`;
                        return (
                            component && (
                                <Grid
                                    className={`${classes.item} ${isDisabled ? classes.disabled : ''}`}
                                    key={key}
                                    item
                                    {...eventHandler}
                                >
                                    {component}
                                </Grid>
                            )
                        );
                    })
                }
            </Grid>
        );
    }
}

Actions.propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.arrayOf(PropTypes.shape({
        component: PropTypes.node,
        disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        action: PropTypes.object,
    })),
};

Actions.defaultProps = {};

export default withStyles(actionStyle)(Actions);
