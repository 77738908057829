const enums = {
    migrationStages:
        ['Not_Started',
            'Dump_Generated_Successfully',
            'In_Progress',
            'Completed',
            'Deleted',
            'Errored',
        ],
};

export default enums;
