import { memo, useEffect, useState } from 'react';
import ThreeDotProgress from 'Generic/threedotprogress/components/ThreeDotProgress';
import { renderInPdf } from 'Commons/components/business/report/utils/Utils';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import localisable from 'Commons/config/strings/localisable';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import DocumentFetcherStyles from '../styles/DocumentFetcherStyles';
import { INTERVAL, RETRY } from '../config/Constants';

const useStyles = makeStyles(DocumentFetcherStyles, { name: 'DocumentFetcherStyles' });
const DocumentFetcher = ({ download, documentation, onClose, retryCount, intervalCount }) => {
    const classes = useStyles();
    const DialogContentProps = useConstructor(() => ({ classes: { root: classes.dialogContentRoot } }));
    const [isFetching, setIsFetching] = useState(false);

    const endPreviewing = (previewCount) => {
        if (!previewCount) {
            setIsFetching(false);
            onClose();
        }
    };

    useEffect(() => {
        const documentsWithPreview = {};
        Object.keys(documentation).forEach((document) => {
            const { deliveryMethod } = documentation[document];
            if (deliveryMethod === 'Preview') {
                documentsWithPreview[document] = documentation[document];
            }
        });
        if (Object.keys(documentsWithPreview).length) {
            setIsFetching(true);
            let previewCount = Object.keys(documentsWithPreview).length;
            Object.values(documentsWithPreview).forEach((document) => {
                let documentUrl = document;
                if (typeof document === 'object') {
                    const { url } = document;
                    documentUrl = url;
                }
                let retry = retryCount;
                const interval = setInterval(() => {
                    if (retry === 0) {
                        clearInterval(interval);
                        previewCount -= 1;
                        endPreviewing(previewCount);
                    } else {
                        fetch(documentUrl).then((res) => { // TODO: Use onAction
                            if (res.ok) {
                                if (download) {
                                    window.open(documentUrl);
                                } else {
                                    renderInPdf(documentUrl, 'Document');
                                }
                                clearInterval(interval);
                                retry = 0;
                                previewCount -= 1;
                                endPreviewing(previewCount);
                            }
                        });
                        retry -= 1;
                    }
                }, intervalCount);
            });
        }
    }, [Object.keys(documentation).length]);

    return (
        <>
            <AlertDialog
                open={isFetching}
                DialogContentProps={DialogContentProps}
                useContentText={false}
                fullWidth
                hideCloseButton
                maxWidth="sm"
            >
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <ThreeDotProgress />
                    </Grid>
                    <Grid item>
                        <Typography>{localisable.fetchingDocuments}</Typography>
                    </Grid>
                </Grid>
            </AlertDialog>
        </>
    );
};

DocumentFetcher.propTypes = {
    documentation: PropTypes.object,
    onClose: PropTypes.func,
    retryCount: PropTypes.number,
    intervalCount: PropTypes.number,
    download: PropTypes.bool,
};

DocumentFetcher.defaultProps = {
    download: false,
    onClose: EMPTY_FUNC,
    retryCount: RETRY,
    intervalCount: INTERVAL,
};

export default memo(DocumentFetcher);
