import { isInternalDashboard } from 'Commons/helpers/utils/Utils';

/* eslint-disable global-require */
const hasPermission = (permission = {}, permissionKey = '') => {
    const { location: { pathname } } = window;
    const permissionKeys = isInternalDashboard(pathname)
        ? require('Internal/config/constants/PermissionKeys').default
        : require('External/config/constants/PermissionKeys').default;
    const { data: { data: permissionData = {} } = {} } = permission;
    return permissionKeys[permissionKey].every(flattenKey => !!permissionData[flattenKey]) || false;
};
export default hasPermission;
