import { IOS_PLATFORMS, MAC_PLATFORMS, OS_LIST, TIMEOUT, USER_TYPE } from 'Commons/config/constants/Constants';
import { DELIVERY_METHOD } from 'Commons/components/generic/deliveryMethodDropdown/config/Constants';
import { VARIANT } from 'Generic/snackbar/config/Constants';

const getSnackbarProps = (isSnackbarOpen = false, title = '', variant = VARIANT.success.value,
    subtitle = '', onSnackbarMount, classes = {}) => ({
    isSnackbarOpen,
    title,
    variant: isSnackbarOpen ? variant : '',
    subtitle,
    onSnackbarMount,
    classes,
});

const isPrintDeliveryMethod = deliveryMethod => [DELIVERY_METHOD.Print.value, DELIVERY_METHOD.Print_And_Email.value].includes(deliveryMethod);
const isTextDeliveryMethod = deliveryMethod => [DELIVERY_METHOD.Text.value].includes(deliveryMethod);
const isEmailDeliveryMethod = deliveryMethod => [DELIVERY_METHOD.Email.value, DELIVERY_METHOD.Print_And_Email.value].includes(deliveryMethod);

const isBetween = (value, min, max) => value >= min && value <= max;

const debounce = (func, timeout = TIMEOUT) => {
    let inDebounce;
    return (...args) => {
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func(...args), timeout);
    };
};

const maskCharacters = (stringToMask, noOfCharsToRemainUnmasked = 4, character = '*') => {
    if (!stringToMask) return '';

    const regex = new RegExp(`.(?=.{${noOfCharsToRemainUnmasked}})`, 'g');
    return stringToMask.replace(regex, character);
};

const removeWhiteSpaceAndSpecialChars = (string = '') => string.replace(/[^A-Z0-9]/ig, '');

const convertToNumber = (value) => {
    if (typeof value === 'number' && !Number.isNaN(value)) {
        return value;
    }

    if (typeof value === 'string') {
        const trimmedValue = value.trim();
        if (trimmedValue) {
            return Number(trimmedValue);
        }
    }

    return NaN;
};

const isExternalDashboard = (url) => {
    if (url) {
        return !!url.match(/^\/a/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/a/g);
};

const isInternalDashboard = (url) => {
    if (url) {
        return !!url.match(/^\/i/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/i/g);
};

const isSettings = (url) => {
    if (url) {
        return !!url.match(/^\/a\/s/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/a\/s/g);
};

const isLiveSettings = (url) => {
    if (url) {
        return !!url.match(/^\/l\/s/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/l\/s/g);
};

const isPulledSettings = (url) => {
    if (url) {
        return !!url.match(/^\/p\/s/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/p\/s/g);
};

const isConfiguration = (url) => {
    if (url) {
        return !!url.match(/^\/a\/s\/c/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/a\/s\/c/g);
};

const isReports = (url) => {
    if (url) {
        return !!url.match(/^\/a\/s\/report/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/a\/s\/report/g);
};

const isLiveData = (url) => {
    if (url) {
        return !!url.match(/^\/l/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/l/g);
};

const isPulledData = (url) => {
    if (url) {
        return !!url.match(/^\/p/g);
    }
    const { location: { pathname } } = window;
    return !!pathname.match(/^\/p/g);
};
// TODO: Above functions can be merged using a config, it will avoid code duplication.

const isNegative = value => convertToNumber(value) < 0;

/**
 * @description Computes diff of set1 and set2 i.e. set1 - set2
 * @param set1
 * @param set2
 * @return {Set} Diff resultant set
 */
const setDifference = (set1, set2) => {
    if (!set1 || !set2) return new Set();
    return new Set(Array.from(set1).filter(x => !set2.has(x)));
};

/**
 * @description Computes union of set1 and set2 i.e. set1 + set2
 * @param set1
 * @param set2
 * @return {Set} Union resultant set
 */
const setUnion = (set1, set2) => {
    const union = new Set(set1);
    set2.forEach(ele => union.add(ele));
    return union;
};

const isKeyPrefixForField = (key = '', field = '') => {
    const keys = key.split('.');
    const fieldKeys = field.split('.');
    return keys.every(eachKey => fieldKeys.includes(eachKey));
};

const isStaging = () => process.env.MODE === 'STAGING';

const isProduction = () => process.env.MODE === 'PROD';
const getJsonFromUrl = (url) => {
    const query = url.substr(url.indexOf('?') + 1);
    if (query) {
        const result = {};
        query.split('&').forEach((part) => {
            const item = part.split('=');
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }
    return {};
};

const getPlatformOs = () => {
    const { navigator: { userAgent = '', userAgentData = {} } = {}, navigator = {} } = window;
    const platform = userAgentData.platform || navigator.platform || '';
    const macosPlatforms = new Set(MAC_PLATFORMS);
    const iosPlatforms = new Set(IOS_PLATFORMS);
    let os = OS_LIST.WINDOWS;

    if (macosPlatforms.has(platform)) {
        os = OS_LIST.MAC;
    } else if (iosPlatforms.has(platform)) {
        os = OS_LIST.IOS;
    } else if (/Android/.test(userAgent)) {
        os = OS_LIST.ANDROID;
    } else if (/Linux/.test(platform)) {
        os = OS_LIST.LINUX;
    }

    return os;
};

/**
 * @description Restricts input to numeric keys and allows backspace, left arrow, right arrow and delete
 * @param e Event
 * @return {void}
 */
const restrictInputToNumericKeys = (e) => {
    // 48 to 57: Top row numeric keys 0 to 9
    // 96 to 105: Numpad numeric keys 0 to 9
    // 8: Backspace
    // 9: Tab
    // 37: Left Arrow
    // 39: Right Arrow
    // 46: Delete
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)
        || [8, 9, 37, 39, 46].includes(e.keyCode)) return;
    e.preventDefault();
};

const isValidNumber = (value) => {
    const number = convertToNumber(value);
    return typeof number === 'number' && !Number.isNaN(number);
};

/**
 * @description Checks if a userType is of Syrasoft type or not. If given an argument, it'll check that otherwise compare the saved userType in window object
 * @param userType String
 * @return {boolean}
 */
const isSyrasoftUser = (userType = '') => {
    const { userType: savedUserType } = window;
    return (userType || savedUserType) !== USER_TYPE.Standard.value;
};

const isSyrasoftAdminOrDevLead = (userType) => {
    const { userType: savedUserType } = window;
    return [USER_TYPE.Admin.value, USER_TYPE.Dev_Lead.value].includes(userType || savedUserType);
};

const isMigrationUser = (userType) => {
    const { userType: savedUserType } = window;
    return (userType || savedUserType) === USER_TYPE.Migration.value;
};

const isSyrasoftSales = (userType) => {
    const { userType: savedUserType } = window;
    return [USER_TYPE.Sales.value].includes(userType || savedUserType);
};

const ensureArray = (value) => {
    if (!Array.isArray(value)) {
        return [value];
    }
    return value;
};

export {
    getSnackbarProps, isBetween, debounce, convertToNumber, isSettings, isLiveData, isNegative,
    maskCharacters, removeWhiteSpaceAndSpecialChars, isInternalDashboard, isExternalDashboard,
    setDifference, setUnion, isKeyPrefixForField, isConfiguration, isReports, isPulledData,
    isLiveSettings, isPulledSettings, isStaging, isProduction, getJsonFromUrl, getPlatformOs, isPrintDeliveryMethod,
    restrictInputToNumericKeys, isValidNumber, isSyrasoftUser, ensureArray, isSyrasoftAdminOrDevLead,
    isSyrasoftSales, isMigrationUser, isEmailDeliveryMethod, isTextDeliveryMethod,
};
