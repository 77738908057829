import media from '../../../../../../theme/MediaQueries';

/* eslint-disable no-unused-vars */
const NumberRangeStyle = theme => ({
    container: { width: '100%' },
    mobileSectionHeader: { borderBottom: '0.9px solid rgba(239, 239, 239, 0.5)' },
    title: {
        fontWeight: 'bolder',
        [media.smallMobile]: {
            '& span': { fontWeight: 'bolder' },
            padding: 0,
        },
        [media.mobile]: {
            '& span': { fontWeight: 'bolder' },
            padding: 0,
        },
        [media.phablet]: {
            '& span': { fontWeight: 'bolder' },
            padding: 0,
        },
        [media.tablet]: {
            '& span': { fontWeight: 'bolder' },
            paddingLeft: 8,
        },
    },
});

export default NumberRangeStyle;
