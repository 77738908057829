const CHIP_COLOR_MAPPING = {
    ratehistorystatusPending: 'leadstatusActive',
    ratehistorystatusApplied: 'leadstatusDuplicate',
    ratehistorystatusReversed: 'estimatestatusExpired',
    ratehistorystatusMovedIn: 'leadstatusConverted',
    ratehistorystatusMovedOut: 'ratehistoryMovedOut',
    ratehistorystatusWaived: 'ratehistoryWaived',
    ratehistorystatusReversedTo: 'leadstatusLost',
    ratehistorystatusUnknown: 'bgrentalStatusUnavailable',
};

export { CHIP_COLOR_MAPPING };
