import media from 'Commons/theme/MediaQueries';
import theme from 'Commons/theme/Theme';

const GeneralPagesStyles = () => ({
    pageContainer: { height: '100%', backgroundColor: theme.palette.common.white },
    fullHeight: { height: '100vh' },
    gridContainer: { [media.laptopUp]: { display: 'inline-flex' } },
    image: { width: '80%', height: 'auto' },
    header: {
        fontSize: '1.5rem',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        [media.tabletUp]: { fontSize: '3rem' },
    },
    title: {
        fontSize: '1rem',
        textAlign: 'center',
        [media.tabletUp]: { fontSize: '2rem' },
    },
    linkContainer: { marginTop: theme.spacing(3.75), display: 'inline-flex' },
    pageLink: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1rem',
        [media.tabletUp]: { fontSize: '1.25rem' },
    },
    pageLinkButton: {
        backgroundColor: theme.palette.tertiary.main,
        cursor: 'pointer',
        border: 'none',
        padding: theme.spacing(1, 2.25),
        outline: 'none',
        borderRadius: theme.spacing(1.5),
    },
});
export default GeneralPagesStyles;
