import theme from '../Theme';
import { GLOBAL_SCROLL_WIDTH, GLOBAL_SCROLL_HEIGHT } from '../Constants';

const globalClasses = Object.values(theme.globalClasses).reduce((classes, { className, style }) => ({
    ...classes,
    [`.${className}`]: style,
}), {});

const baseStyle = {
    // Snackbar Base Style
    bottomLeftSnackbarContainer: { bottom: theme.spacing(13) },

    '@global': {
        body: {
            margin: 0,
            fontFamily: 'Roboto',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            scrollBehavior: 'smooth',

            '& *::-webkit-scrollbar': {
                width: GLOBAL_SCROLL_WIDTH,
                height: GLOBAL_SCROLL_HEIGHT,
            },

            '& *::-webkit-scrollbar-track': { background: 'transparent' },

            '& *::-webkit-scrollbar-thumb': {
                background: theme.palette.button.hover,
                borderRadius: 16,
            },
        },
        '*[tooltip]': {
            '&:hover': {
                position: 'relative',
                '&:after': {
                    content: 'attr(tooltip)', // Use the text provided in tooltip attribute
                    padding: '4px 6px',
                    left: 0,
                    top: 'calc(100% + 4px)',
                    position: 'absolute',
                    whiteSpace: 'nowrap',
                    zIndex: 1500,
                    fontFamily: 'Open Sans',
                    fontWeight: 600,
                    color: theme.palette.text.primary,
                    background: theme.palette.common.white,
                    borderRadius: 4,
                    minHeight: theme.spacing(4),
                    minWidth: theme.spacing(13),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: theme.shadows[2],
                    ...theme.typography.body1,
                },
            },
        },
        html: { fontSize: 16 },
        '@font-face': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
        },
        '*': { '-webkit-font-smoothing': 'antialiased' },
        ...globalClasses,
    },
};

export default baseStyle;
