import { Grid, Typography } from 'Commons/components/generic/componentlibrary/components/Components';
import { STATUS, TABLE_STATE } from 'Commons/config/constants/Constants';
import { clsx } from 'Commons/helpers/utils/clsx';
import localisable from '../../../../config/strings/localisable';
import ThreeDotProgress from '../../threedotprogress/components/ThreeDotProgress';

export function EmptyOrLoadingTable(emptyBodyRenderer, classes, rowHeight, state) {
    let element;
    if (emptyBodyRenderer && typeof emptyBodyRenderer === 'function') {
        element = emptyBodyRenderer();
    } else {
        element = localisable.messages.defaultNoContent;
    }
    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            style={{ minHeight: rowHeight, height: state === TABLE_STATE.LOADING ? '100%' : '' }}
            className={classes.noContentTable}
        >
            <Typography component="div">{element}</Typography>
        </Grid>
    );
}

function TableFooterOrHeader(props) {
    const { classes, verticalScrollSize, children, rowHeight: height, isHeader, headerRowHeight, leftAndRightPadding } = props;
    return (
        <Grid
            style={{ width: `calc(100% - ${verticalScrollSize}px - ${leftAndRightPadding}px)`, height }}
            container
            alignItems="center"
            justify="center"
            className={clsx(classes.tableFooter, isHeader ? classes[`atTop${headerRowHeight}`] : classes.atBottom)}
        >
            {children}
        </Grid>
    );
}
TableFooterOrHeader.propTypes = {
    classes: PropTypes.object,
    verticalScrollSize: PropTypes.number,
    rowHeight: PropTypes.number,
    children: PropTypes.node,
    isHeader: PropTypes.bool,
    headerRowHeight: PropTypes.number,
    leftAndRightPadding: PropTypes.number,
};


export function TableLoading(props) {
    return (
        <TableFooterOrHeader {...props}>
            <Grid item><ThreeDotProgress /></Grid>
        </TableFooterOrHeader>
    );
}

export function TableNoMoreRows(props) {
    return (
        <TableFooterOrHeader {...props}>
            <Grid item><Typography variant="h6">{localisable.noMoreResults}</Typography></Grid>
        </TableFooterOrHeader>
    );
}

export function StatusIndicator({ classes, isConfigurationList, activeLabel, inactiveLabel }) {
    return (
        <Grid
            container
            className={clsx(
                classes.tableGrid, classes.rowBorder,
                isConfigurationList
                    ? classes.activeInactiveSettingsPage
                    : classes.activeInactiveListPage,
            )}
        >
            <Grid item className={classes.activeStatusContainer}>
                <Grid
                    container
                    item
                    className={clsx(classes.coloredSquare, classes.activeStatusColor)}
                >
                    <Grid item className={classes.activeInactiveLabel}>
                        { activeLabel || STATUS.Active.value}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.inactiveStatusContainer}>
                <Grid
                    container
                    item
                    className={clsx(classes.coloredSquare, classes.inactiveStatusColor)}
                >
                    <Grid item className={classes.activeInactiveLabel}>
                        {inactiveLabel || STATUS.Inactive.value}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
StatusIndicator.propTypes = {
    classes: PropTypes.object,
    isConfigurationList: PropTypes.bool,
    inactiveLabel: PropTypes.string,
    activeLabel: PropTypes.string,
};
