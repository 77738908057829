import { SOURCE } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';

const tenantNameAscSort = {
    name: {
        lastName: { order: 'asc' },
        firstName: { order: 'asc' },
    },
};
const tenantNameDescSort = {
    name: {
        lastName: { order: 'desc' },
        firstName: { order: 'desc' },
    },
};

const sortConfig = {
    [SOURCE.unit.value]: [
        {
            label: 'Unit Number',
            value: 'Unit Number',
            sort: [{ label: { order: 'asc' } }],
        },
        {
            label: 'Unit Size',
            value: 'Unit Size',
            sort: [{ unitType: { value: { description: { order: 'asc' } } } }],
        },
        {
            label: 'Next Rent Assessment Date',
            value: 'Next Rent Assessment Date',
            sort: [{ nextRentAssessmentDate: { order: 'asc' } }],
        },
    ],
    [SOURCE.tenant.value]: [
        {
            label: 'Tenant Name (Asc)',
            value: 'Tenant Name (Asc)',
            sort: [{
                ...tenantNameAscSort,
                ledger: { createdDate: { order: 'desc' } },
            }],
        },
        {
            label: 'Tenant Name (Desc)',
            value: 'Tenant Name (Desc)',
            sort: [{
                ...tenantNameDescSort,
                ledger: { createdDate: { order: 'desc' } },
            }],
        },
        {
            label: 'Balance (Asc)',
            value: 'Balance (Asc)',
            sort: [
                { totalBalance: { order: 'asc' } },
                { ...tenantNameAscSort },
            ],
        },
        {
            label: 'Balance (Desc)',
            value: 'Balance (Desc)',
            sort: [
                { totalBalance: { order: 'desc' } },
                { ...tenantNameAscSort },
            ],
        },
        {
            label: 'Email (Asc)',
            value: 'Email (Asc)',
            sort: [
                { email: { emailAddress: { order: 'asc' } } },
                { ...tenantNameAscSort },
            ],
        },
        {
            label: 'Email (Desc)',
            value: 'Email (Desc)',
            sort: [
                { email: { emailAddress: { order: 'desc' } } },
                { ...tenantNameAscSort },
            ],
        },
    ],
    [SOURCE.reservation.value]: [
        {
            label: 'Expected Move In Date',
            value: 'Expected Move In Date',
            sort: [
                { rentalInfo: { moveInDate: { order: 'desc' } } },
                { reservationDate: { order: 'desc' } },
            ],
        },
        {
            label: 'Reservation Code',
            value: 'Reservation Code',
            sort: [{ reservationCode: { order: 'asc' } }],
        },
        {
            label: 'Tenant Name',
            value: 'Tenant Name',
            sort: [{ tenant: { name: { lastName: { order: 'asc' } } } }],
        },
    ],
    [SOURCE.configuration.value]: [
        {
            label: 'Description',
            value: 'Description',
            sort: [{ value: { description: { order: 'asc' } } }],
        },
    ],
    [SOURCE.user.value]: [
        {
            label: 'Name',
            value: 'Name',
            sort: [{ firstName: { order: 'asc' } }],
        },
    ],
    [SOURCE.vendor.value]: [
        {
            label: 'Vendor Name',
            value: 'Vendor Name',
            sort: [{ vendorName: { order: 'asc' } }],
        },
    ],
    [SOURCE.inventoryList.value]: [
        {
            label: 'Description',
            value: 'Description',
            sort: [{ productDescription: { order: 'asc' } }],
        },
    ],
    [SOURCE.maintenanceEvent.value]: [
        {
            label: 'Start Date',
            value: 'Start Date',
            sort: [{ startDate: { order: 'asc' } }],
        },
    ],
    [SOURCE.lead.value]: [
        {
            label: 'Last Contacted Date',
            value: 'Last Contacted Date',
            sort: [{ lastContactedDate: { order: 'desc' } }],
        },
        {
            label: 'Name',
            value: 'Name',
            sort: [{ name: { lastName: { order: 'asc' } } }],
        },
    ],
    [SOURCE.pendingOverlockUnlockList.value]: [
        {
            label: 'Unit Number',
            value: 'Unit Number',
            sort: [{ label: { order: 'asc' } }],
        },
    ],
    [SOURCE.delinquency.value]: [
        {
            label: localisable.noOfDaysLateAsc,
            value: localisable.noOfDaysLateAsc,
            sort: [{ noOfDaysLate: { order: 'asc' } }],
        },
        {
            label: localisable.noOfDaysLateDesc,
            value: localisable.noOfDaysLateDesc,
            sort: [{ noOfDaysLate: { order: 'desc' } }],
        },
        {
            label: localisable.tenantNameAsc,
            value: localisable.tenantNameAsc,
            sort: [{ tenant: { name: { lastName: { order: 'asc' } } } }],
        },
        {
            label: localisable.tenantNameDesc,
            value: localisable.tenantNameDesc,
            sort: [{
                tenant: {
                    name: {
                        lastName: { order: 'desc' },
                        firstName: { order: 'desc' },
                    },
                },
            },
            ],
        },
    ],
    [SOURCE.rentAdjustment.value]: [
        {
            label: 'Effective Date (Desc)',
            value: 'Effective Date (Desc)',
            sort: [{ effectiveDate: { order: 'desc' } }],
        },
    ],
    [SOURCE.unitDepositUpdate.value]: [
        {
            label: 'Unit Number (Asc)',
            value: 'Unit Number (Asc)',
            sort: [{ label: { order: 'asc' } }],
        },
        {
            label: 'Unit Number (Desc)',
            value: 'Unit Number (Desc)',
            sort: [{ label: { order: 'desc' } }],
        },
    ],
    [SOURCE.tenantDepositUpdate.value]: [
        {
            label: 'Tenant Name (Asc)',
            value: 'Tenant Name (Asc)',
            sort: [{ ...tenantNameAscSort }],
        },
        {
            label: 'Tenant Name (Desc)',
            value: 'Tenant Name (Desc)',
            sort: [{ ...tenantNameDescSort }],
        },
    ],
};

export default sortConfig;
