import { encryptWithPublicKey } from './Cryptography';
import { deepCopy } from './DeepCopy';


const encryptPasswords = async (formValues, globalEncryptionKey) => {
    const formValuesWithEncryptedPasswords = deepCopy(formValues);
    await Promise.all(['oldPassword', 'newPassword', 'password'].map(async (key) => {
        const password = formValues[key];
        if (password) {
            formValuesWithEncryptedPasswords[key] = await encryptWithPublicKey(password, globalEncryptionKey);
        }
    }));
    return formValuesWithEncryptedPasswords;
};

export { encryptPasswords };
