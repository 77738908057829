import { memo, useCallback, useMemo } from 'react';
import { FormDropdown } from 'Generic/dropdown/components/Dropdown';
import MultiSelect from 'Generic/multiselect/components/MultiSelect';
import localisable from 'Commons/config/strings/localisable';
import SavedDocumentsListFilterStyle
from 'Commons/components/business/filtersAndSorts/components/savedDocumentsListFilter/styles/SavedDocumentsListFilterStyle';
import { getValue } from 'Commons/helpers/utils/DataHelpers';
import getName from 'Commons/helpers/utils/NameHelper';
import { getTenantApiConfig } from 'Commons/components/business/filtersAndSorts/components/savedDocumentsListFilter/config/ApiRequests';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { DELIVERY_METHOD } from 'Generic/deliveryMethodDropdown/config/Constants';
import {
    DOCUMENT_STATUS,
    DOCUMENT_TYPE,
    SUB_DOCUMENT_TYPE,
} from 'Commons/components/business/filtersAndSorts/components/savedDocumentsListFilter/config/Constants';
import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';

const useStyles = makeStyles(SavedDocumentsListFilterStyle, { name: 'SavedDocumentsListFilterStyle' });

const SavedDocumentsListFilter = ({ formProps: { values, setFieldValue } = {}, selectedFacility }) => {
    const classes = useStyles();
    const tenantFilterKey = 'terms.tenantId';
    const selectedTenants = getValue(values, tenantFilterKey) || [];

    const tenantApiConfig = useMemo(() => getTenantApiConfig(selectedFacility), [selectedFacility]);

    const onSelectOrRemoveTenant = (selectedTenantsIds) => {
        setFieldValue(tenantFilterKey, Object.keys(selectedTenantsIds));
    };

    const deliveryMethodList = useConstructor(() => Object.values(DELIVERY_METHOD));
    const documentTypeList = useConstructor(() => Object.values(DOCUMENT_TYPE));
    const subDocumentTypeList = useConstructor(() => Object.values(SUB_DOCUMENT_TYPE));
    const documentStatusList = useConstructor(() => Object.values(DOCUMENT_STATUS));

    const tenantOptionFormatter = useCallback(({ data = [] } = {}) => data.map(({ name, ...tenant }) => (
        { label: getName(name), name, ...tenant }
    )), [selectedFacility]);

    return (
        <>
            <Typography
                variant="subtitle1"
                className={classes.firstLabel}
            >
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={documentStatusList}
                row
                noGrid
                noIndicatorWrapper
            />
            <Grid container>
                <Grid item xs={6}>
                    <Typography
                        variant="subtitle1"
                        className={classes.label}
                    >
                        {localisable.documentType}
                    </Typography>
                    <FormDropdown
                        name="terms.documentType"
                        list={documentTypeList}
                        noIndicatorWrapper
                        noGrid
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        variant="subtitle1"
                        className={classes.label}
                    >
                        {localisable.subDocumentType}
                    </Typography>
                    <FormDropdown
                        name="terms.subDocumentType"
                        list={subDocumentTypeList}
                        noIndicatorWrapper
                        noGrid
                    />
                </Grid>
            </Grid>
            <Typography
                variant="subtitle1"
                className={classes.label}
            >
                {localisable.deliveryMethod}
            </Typography>
            <FormDropdown
                name="terms.deliveryMethod"
                list={deliveryMethodList}
                noIndicatorWrapper
                noGrid
            />
            {selectedFacility !== -1 && (
                <MultiSelect
                    placeholder={localisable.selectTenant}
                    onSelect={onSelectOrRemoveTenant}
                    onRemove={onSelectOrRemoveTenant}
                    keyToMatch="id"
                    apiConfig={tenantApiConfig}
                    maxHeight={150}
                    initiallySelectedValues={selectedTenants}
                    inputValueFormatter={({ name }) => getName(name)}
                    getOptionProps={({ name }) => ({ label: getName(name) })}
                    optionFormatter={tenantOptionFormatter}
                    componentContainerLabel={localisable.tenants}
                    containerProps={{ className: classes.tenantMultiSelect }}
                    classes={{ chipWrapper: classes.chipWrapper }}
                    PopdownProps={{ PopperProps: { modifiers: { preventOverflow: { enabled: false } } } }}
                />
            )}
        </>
    );
};

SavedDocumentsListFilter.propTypes = {
    formProps: PropTypes.object,
    selectedFacility: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default memo(SavedDocumentsListFilter);
