import { memo } from 'react';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import { BOARD }
from 'External/containers/facilityConfiguration/components/bevColorSetup/components/config/Constants';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { calculateYIQRatio } from 'Commons/components/business/bev/utils/Utils';
import { COLORED_SQUARE_BORDER_SHOW_TOLERANCE } from 'Commons/components/business/bev/config/Constants';
import colorLegendStyles from '../../styles/ColorLegendStyles';

const useStyles = makeStyles(colorLegendStyles, { name: 'ColorLegend' });


const SingleColorEntry = ({ color, description }) => {
    const classes = useStyles({ color });

    const borderRequired = !color ? true : calculateYIQRatio(color) > COLORED_SQUARE_BORDER_SHOW_TOLERANCE; // TO show a border around the colored square in case the color is hard to see against the white background (in cases where the color is white, lighter yellow etc.)

    return (
        <Grid container className={classes.coloredSquareAndDescriptionContainer} alignItems="center">
            <Grid
                item
                className={`${classes.coloredSquare} ${borderRequired ? classes.coloredSquareBorder : ''}`}
            />
            <Typography variant="body1">
                {description}
            </Typography>
        </Grid>
    );
};

SingleColorEntry.propTypes = {
    color: PropTypes.string,
    description: PropTypes.string.isRequired,
};

const LegendBoardEntry = (props) => {
    const { index, title = '', items = [] } = props;
    const classes = useStyles();

    return (
        items.length
            ? (
            <>
                <Typography variant="subtitle1" className={index > 0 ? classes.legendBoardEntry : ''}>{title}</Typography>
                {
                    items.map(({
                        color,
                        description,
                    }) => (
                        <SingleColorEntry
                            key={`${title}-${index}-${description}`}
                            color={color}
                            description={description}
                        />
                    ))
                }
            </>
            ) : null
    );
};

LegendBoardEntry.propTypes = {
    index: PropTypes.number,
    title: PropTypes.string,
    items: PropTypes.array,
};

const ColorLegend = (props) => {
    const { colorLegendData = {} } = props;
    const getTitle = (board) => {
        if (board === BOARD.RENTAL_AVAILABILITY_STATUS) return localisable.bevColorSetupBoard1;
        if (board === BOARD.LOCK_OVERLOCK_STATUS) return localisable.bevColorSetupBoard2;
        if (board === BOARD.MAINTENANCE_ACTIVITY) return localisable.bevColorSetupBoard3;
        if (board === BOARD.DELINQUENCY_STATUS) return localisable.bevColorSetupBoard4;
        return '';
    };
    return (
        <>
            {
                // To maintain order did not use Object.keys(colorLegendData)
                [BOARD.RENTAL_AVAILABILITY_STATUS, BOARD.LOCK_OVERLOCK_STATUS,
                    BOARD.MAINTENANCE_ACTIVITY, BOARD.DELINQUENCY_STATUS].map((board, index) => {
                    const { [board]: { items: boardItems } = {} } = colorLegendData;
                    const title = getTitle(board);
                    return title && (
                        <LegendBoardEntry
                            key={board}
                            index={index}
                            title={title}
                            items={boardItems}
                        />
                    );
                })
            }
        </>
    );
};

ColorLegend.propTypes = { colorLegendData: PropTypes.object };

export default memo(ColorLegend);
