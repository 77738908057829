import Icon from 'Commons/components/generic/icon/components/Icon';
import { withStyles, Grid } from 'Commons/components/generic/componentlibrary/components/Components';
import { AutoSuggest } from 'Commons/components/business/autosuggest/components/AutoSuggest';
import { FormDropdown } from 'Commons/components/generic/dropdown/components/Dropdown';
import tenantAutosuggest from 'External/apihelpers/Tenant';
import { AUTO_SUGGEST_RESOURCE, SOURCE } from 'Commons/config/constants/Constants';
import getName from 'Commons/helpers/utils/NameHelper';
import { useState, memo, useEffect, useRef } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import Button from 'Commons/components/generic/button/components/Button';
import AlertDialog from 'Commons/components/generic/alertdialog/components/AlertDialog';
import { PERFORM_BILLING } from 'External/containers/rentalConfiguration/config/LateEventConfig';
import getFormattedAmount from 'Commons/helpers/utils/AmountHelper';
import { round } from 'Commons/helpers/utils';
import { convertToNumber } from 'Commons/helpers/utils/Utils';
import Typography from 'Generic/typography/components/Typography';
import TenantInformationStyle from '../styles/TenantInformationStyle';
import {
    fetchUnits, fetchTenant, fetchTenantDetailsFromLedger,
    fetchTenantAlert, fetchLedgerBalance, fetchPendingDelinquency, processLateEvent,
} from '../config/ApiRequests';
import { ITEM, PAYMENT_METHOD } from '../config/Constants';
import localisable from '../../../../config/strings/localisable';
import shouldModifyNegativeAmount from '../utils/CheckAmount';
import { setFormValuesForUseCardOnFile } from '../utils/DataHandler';
import { calculateNextAmountPayable } from '../utils/BalanceCalculator';

const TenantInformation = ({
    classes,
    formProps: {
        status = {},
        setStatus,
        setFieldValue,
        status: {
            tenantInfo,
            ledgerInfo,
            tenantInfo: { ledger: ledgerList = [], comments, id: tenantId = '', name: tName } = {},
            ledgerInfo: { id: ledgerId, balance = 0 } = {},
        } = {},
    } = {},
    isRefund,
    onAction,
    currency,
    onApiError,
    ledgerDetails,
    tenantDetails,
    clearStoreKeys,
    setPrefilledFalse,
    getAutoSuggestRef,
    setFetchedBalance,
    toggleLoadingState,
    showOnlyTenantName,
    getOnLedgerBalance,
    updateCurrentStatus,
    reFetchTenantBalance,
    updateIgnoreWsMessage,
    showTenantInformation,
    source,
    tenantId: preSelectedTenantId,
    ledgerId: preSelectedLedgerId,
    changeToRefundIfNegativeBalance = false,
    shouldAutoFocus,
}) => {
    const { deviceInfo: { isDesktop, isMobile } } = window;
    const [unitList, setUnitList] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [tenantName, setTenantName] = useState('');
    const [lateFeeInfo, setLateFeeInfo] = useState(0);
    const ledgerRef = useRef({});
    const tenantRef = useRef({});
    const ledgerIdRef = useRef(false);
    const tenantIdRef = useRef(false);
    let autoSuggestRef;
    const [tenantInfoBalance, setTenantInfoBalance] = useState(balance);

    useEffect(() => { setTenantInfoBalance(round(balance)); }, [balance]);

    useEffect(() => { setTenantName(getName(tName)); }, [tName]);

    const onUnitResponse = (error, response) => {
        if (error) {
            onApiError(error);
        } else if (response) {
            const { data } = response;
            setUnitList(data);
            setFieldValue('extra.unitList', data);
            setFieldValue('extra.unitUpdateTime', Date.now());
        }
        toggleLoadingState();
    };

    const onLedgerBalance = (error, response) => {
        if (error) {
            onApiError(error);
        } else if (response) {
            const { data: [{ balance: ledgerBalance } = {}] = {} } = response;
            if (ledgerBalance) {
                let resultantBalance = ledgerBalance;
                if (isRefund || (changeToRefundIfNegativeBalance && ledgerBalance < 0)) {
                    resultantBalance = ledgerBalance < 0 ? -ledgerBalance : 0;
                } else {
                    resultantBalance = ledgerBalance > 0 ? ledgerBalance : 0;
                }
                setFetchedBalance(ledgerBalance);
                setFieldValue('extra.ledgerBalance', resultantBalance);
                setFieldValue('extra.amountWithoutModifier', round(resultantBalance, 2, true));
                setTenantInfoBalance(round(ledgerBalance));
            }
        }
        toggleLoadingState();
    };

    useEffect(() => { getOnLedgerBalance(onLedgerBalance); }, []);

    useEffect(() => { getAutoSuggestRef(autoSuggestRef); }, [autoSuggestRef]);

    const onPendingDelinquency = (error, response) => {
        if (error) {
            onApiError(error);
        } else if (response) {
            const { data = [], relationalData: { lateEvent: lateEventConfig = [] } = {} } = response;
            if (data && data.length) {
                const lateEventConfigMap = {};
                lateEventConfig.forEach((config) => {
                    const { id } = config;
                    lateEventConfigMap[id] = config;
                });

                let totalLateFee = 0;
                const idListToProcess = [];
                data.forEach(({ fee, id, lateEventConfigId } = {}) => {
                    if (lateEventConfigId) {
                        const {
                            [lateEventConfigId]: {
                                value: {
                                    late: {
                                        charge: { performBilling = PERFORM_BILLING.Daily.value }
                                        = {},
                                    } = {},
                                } = {},
                            } = {},
                        } = lateEventConfigMap;
                        if (performBilling === PERFORM_BILLING.At_Payment.value) {
                            totalLateFee += convertToNumber(fee);
                            idListToProcess.push(id);
                        }
                    }
                });
                if (totalLateFee) {
                    setLateFeeInfo({ totalLateFee, idListToProcess });
                    setOpenAlert(true);
                }
            }
        }
    };

    const setCardInfoFromLedger = (cardInfo = {}, isAutoPayEnabled = false) => {
        const { expirationDate: { month, year } = {}, ...restCardInfo } = cardInfo;
        const { token = '' } = cardInfo;
        setFieldValue('cardInfo', {});
        if (token) {
            setFieldValue('extra.cardInfo', { ...restCardInfo, expirationDate: { month, year } });
            setFormValuesForUseCardOnFile(setFieldValue, isAutoPayEnabled);
        } else setFieldValue('extra.payUsing', localisable.enterCardDetails);
    };

    const setAchInfoFromLedger = (achInfo = {}) => {
        setFieldValue('extra.achInfo', achInfo);
    };

    const updateLedgerDetails = (ledger) => {
        const {
            id, balance: ledgerBalance = 0,
            financials: { preferredPaymentMethodId, isAutoPayEnabled, cardInfo, achPpd: achInfo } = {},
        } = ledger;
        let resultantBalance = ledgerBalance;
        if (isRefund) {
            resultantBalance = ledgerBalance < 0 ? -ledgerBalance : 0;
        }
        setAchInfoFromLedger(achInfo);
        setCardInfoFromLedger(cardInfo, isAutoPayEnabled);
        if (JSON.stringify(ledgerRef.current) !== JSON.stringify(ledger)) {
            ledgerRef.current = ledger;
            if (reFetchTenantBalance) {
                toggleLoadingState();
                fetchLedgerBalance(onAction, id, onLedgerBalance);
            }
            toggleLoadingState();
            fetchUnits(onAction, id, onUnitResponse);
            fetchPendingDelinquency(onAction, id, onPendingDelinquency);
        }
        if (!shouldModifyNegativeAmount(isRefund, unitList, setFieldValue, id, source, ledgerBalance)) {
            setFieldValue('extra.amountWithoutModifier', round(resultantBalance, 2, true));
        }
        setTenantInfoBalance(round(ledgerBalance));
        return {
            ledgerInfo: ledger,
            amountWithoutModifier: round(resultantBalance, 2, true),
            preferredMethod: preferredPaymentMethodId,
            autoPayEnabled: isAutoPayEnabled,
        };
    };

    useEffect(() => {
        if (ledgerInfo) {
            const updatedStatus = { ...status, ...updateLedgerDetails(ledgerInfo) };
            updateCurrentStatus(updatedStatus);
            setStatus(updatedStatus);
        }
    }, [JSON.stringify(ledgerInfo)]);

    const onTenantAlertResponse = (error, response) => {
        if (error) {
            onApiError(error);
        } else if (response) {
            const tenantAlerts = {};
            const paymentRelatedAlerts = { [PAYMENT_METHOD.CHECK]: { alertType: ['Do_Not_Accept_Checks'] } };
            const { data } = response;
            let paymentSpecificAlerts = [];
            const nonPaymentSpecificAlerts = [];
            Object.keys(paymentRelatedAlerts).forEach((paymentType) => {
                paymentRelatedAlerts[paymentType].alertType.forEach((paymentSpecificAlert) => {
                    data.forEach((alert) => {
                        const { alertType, status: alertStatus, visibleAt } = alert;
                        if (alertStatus === 'Active' && (visibleAt.includes('Payment') || visibleAt.includes('All'))) {
                            if (alertType === paymentSpecificAlert) {
                                paymentSpecificAlerts.push(alert);
                            } else {
                                nonPaymentSpecificAlerts.push(alert);
                            }
                        }
                    });
                });
                if (paymentSpecificAlerts.length > 0) {
                    tenantAlerts.paymentRelatedAlert = { [paymentType]: paymentSpecificAlerts };
                    paymentSpecificAlerts = [];
                }
            });
            if (nonPaymentSpecificAlerts.length > 0) {
                tenantAlerts.general = nonPaymentSpecificAlerts;
            }
            setFieldValue('extra.tenantAlerts', tenantAlerts);
        }
        toggleLoadingState();
    };

    const onSelectTenant = (tenant = {}) => {
        const { name, ledger: ledgers = [], unitList: units = [], id: selectedTenantId } = tenant;
        const newTenantName = getName(name);
        if (tenantName !== newTenantName) {
            setTenantName(newTenantName);
        }
        if (tenantId !== selectedTenantId) {
            const ledgerListWithLabelValue = ledgers.map((ledger) => {
                const { id } = ledger;
                return { ...ledger, value: id };
            });
            let updatedStatus = { ...status, tenantInfo: { ...tenant, ledger: ledgerListWithLabelValue } };
            updatedStatus = {
                ...updatedStatus,
                ...(ledgerDetails ? updateLedgerDetails(ledgerDetails)
                    : updateLedgerDetails(ledgerListWithLabelValue[0])),
            };
            const { id, financials: { cardInfo, isAutoPayEnabled } = {} } = ledgerDetails || ledgers[0];
            updateCurrentStatus(updatedStatus);
            setStatus(updatedStatus);
            setFieldValue('ledgerId', id);
            setCardInfoFromLedger(cardInfo, isAutoPayEnabled);
            setUnitList(units);
            if (JSON.stringify(tenantRef.current) !== JSON.stringify(tenant)) {
                tenantRef.current = tenant;
                fetchTenantAlert(onAction, selectedTenantId, onTenantAlertResponse);
            }
        }
    };

    const onTenantResponse = (error, response) => {
        if (error) {
            onApiError(error);
        } else if (response) {
            const { data } = response;
            if (autoSuggestRef) {
                autoSuggestRef.selectCard(data[0]);
            } else {
                onSelectTenant(data[0]);
            }
        }
        toggleLoadingState();
    };

    useEffect(() => {
        if (preSelectedTenantId && !tenantIdRef.current) {
            tenantIdRef.current = true;
            toggleLoadingState();
            fetchTenant(onAction, preSelectedTenantId, onTenantResponse);
        } else if (preSelectedLedgerId && !ledgerIdRef.current) {
            ledgerIdRef.current = true;
            toggleLoadingState();
            fetchTenantDetailsFromLedger(onAction, preSelectedLedgerId, onTenantResponse);
        } else {
            if (tenantDetails) {
                onSelectTenant(tenantDetails);
            }
            if (ledgerDetails) {
                updateLedgerDetails(ledgerDetails);
            }
        }
    }, [tenantName]);

    const onSelectLedger = (_, selectedLedgerId) => {
        if (selectedLedgerId !== ledgerId) {
            const ledger = ledgerList.find(({ id }) => id === selectedLedgerId) || {};
            setStatus({
                ...status,
                ...updateLedgerDetails(ledger),
            });
            setPrefilledFalse();
            clearStoreKeys(['unpaidAr']);
        }
    };

    const renderTenantAutosuggest = () => (
        <Grid container alignItems="flex-end" wrap="nowrap">
            <AutoSuggest
                onRef={(ref) => { autoSuggestRef = ref; }}
                fullWidth
                PopdownProps={{
                    addon: { start: null },
                    placeholder: 'Search Tenant',
                    classes: { popdown: classes.fullWidth },
                    additionalIcon: {
                        left: <Icon
                            className={classes.defaultCursor}
                            type="custom"
                            icon="cp-individual"
                        />,
                    },
                    // TODO: Find better solution to handle smaller widths
                    PaperProps: { width: isMobile ? '280px' : '350px' },
                    autoFocus: true,
                    shouldAutoFocus,
                }}
                resource={AUTO_SUGGEST_RESOURCE.TENANT}
                labelRenderer={({ name }) => getName(name)}
                onSelectCard={onSelectTenant}
                textFieldClasses={{
                    input: classes.input,
                    alignment: classes.iconAlignment,
                }}
                onActionConfig={tenantAutosuggest()}
                highlightInputTextInCard
            />
        </Grid>
    );

    const renderLedgerDropDown = () => (
        <Grid container alignItems="flex-end" wrap="nowrap" className={classes.ledger}>
            <FormDropdown
                name="ledgerId"
                placeholder="Select Ledger"
                list={ledgerList}
                fullWidth
                noGrid
                noIndicatorWrapper
                onChange={onSelectLedger}
                disabled={!tenantInfo}
                key={JSON.stringify(ledgerList)}
                PopdownProps={{
                    additionalIcon: {
                        left: <Icon
                            type="custom"
                            icon="cp-ledger"
                            disabled={!tenantInfo}
                            className={classes.defaultCursor}
                        />,
                    },
                }}
                textFieldClasses={{ alignment: classes.iconAlignment }}
            />
        </Grid>
    );

    const useNextAmountPayable = () => {
        const nextAmountPayable = calculateNextAmountPayable(unitList, ledgerId, false);
        setFieldValue('extra.amountWithoutModifier', round(convertToNumber(nextAmountPayable), 2, true));
    };

    const renderItems = (iconName = '', itemName) => {
        const ledgerBalance = getFormattedAmount(tenantInfoBalance);
        let label = `${currency} ${ledgerBalance}`;
        let isDisabled = !tenantInfo;
        let shouldShowPrepayButton = false;
        switch (itemName) {
            case ITEM.COMMENT:
                label = comments || localisable.noComments;
                isDisabled = !comments;
                break;
            case ITEM.UNIT:
                label = unitList.map(({ label: unitName }) => unitName).join(', ') || localisable.noUnits;
                break;
            case ITEM.TENANT_NAME:
                label = tenantName;
                break;
            case ITEM.LEDGER_NAME:
                // eslint-disable-next-line prefer-destructuring
                label = ledgerDetails.label;
                break;
            default:
                shouldShowPrepayButton = itemName === ITEM.BALANCE && (ledgerBalance || 0) - 0 === 0
                && !!unitList.length && source !== SOURCE.retailSale.value && !isRefund;
                break;
        }
        return (
            <Grid
                container
                alignItems={shouldShowPrepayButton ? 'flex-start' : 'center'}
                wrap="nowrap"
                className={clsx(classes.rightContentTopMargin,
                    itemName === ITEM.LEDGER_NAME && classes.ledgerPrefilled)}
                {...(!isDesktop && { xs: 6 })}
                item={!isDesktop}
            >
                <Icon
                    type="custom"
                    icon={iconName}
                    disabled={!tenantInfo}
                    className={classes.defaultCursor}
                />
                <Grid
                    container
                    wrap="nowrap"
                    direction="column"
                    className={clsx(classes.marginFromIconInRightContainer, classes.itemLabelContainer)}
                >
                    <Typography
                        disabled={!tenantInfo}
                        noWrap
                        className={clsx(
                            {
                                [classes.selected]: isDisabled,
                                [classes.prefilled]: itemName === ITEM.TENANT_NAME || itemName === ITEM.LEDGER_NAME,
                            },
                        )}
                    >
                        {tenantInfo ? label : '--'}
                    </Typography>
                    {
                        shouldShowPrepayButton
                        && (
                            <Button variant="text" className={classes.prepayButton} onClick={useNextAmountPayable}>
                                <Typography variant="caption" fontFamily="Roboto">
                                    {localisable.prepayNextPeriodAmount}
                                </Typography>
                            </Button>
                        )
                    }
                </Grid>
            </Grid>
        );
    };

    const onAlertClose = () => {
        setOpenAlert(false);
    };

    const { totalLateFee = 0, idListToProcess } = lateFeeInfo;

    const onProcessLateEvent = (error, response) => {
        setOpenAlert(false);
        if (error) {
            onApiError(error);
        } else if (response) {
            updateIgnoreWsMessage(false);
            const updatedBalance = balance + totalLateFee;
            const updatedLedgerInfo = { ...ledgerInfo };
            updatedLedgerInfo.balance = convertToNumber(updatedBalance);
            setStatus({
                ...status,
                ledgerInfo: updatedLedgerInfo,
            });
            if (!shouldModifyNegativeAmount(isRefund, unitList, setFieldValue, ledgerId, source, updatedLedgerInfo.balance)) {
                setFieldValue('extra.amountWithoutModifier', round(updatedBalance, 2, true));
            }
        }
    };

    const onClickProcess = () => {
        updateIgnoreWsMessage(true);
        processLateEvent(onAction, idListToProcess, onProcessLateEvent);
    };

    const renderLateEventProcessingAlert = () => (
        !!totalLateFee
        && (
            <AlertDialog
                open={openAlert}
                onClose={onAlertClose}
                title={localisable.lateFee}
                actions={(
                    <>
                        <Button
                            onClick={onAlertClose}
                            variant="text"
                            className={classes.alertDialogButton}
                        >
                            {localisable.skipNow.toUpperCase()}
                        </Button>
                        <Button
                            onClick={onClickProcess}
                            variant="text"
                            className={classes.alertDialogButton}
                        >
                            {localisable.process.toUpperCase()}
                        </Button>
                    </>
                )}
            >
                <Typography>
                    {localisable.shouldProcessLateFee.replace(':fee', `${currency}${totalLateFee}`)}
                </Typography>
            </AlertDialog>
        )
    );

    return (
        <>
            {
                showTenantInformation
                && (
                    <Grid container className={classes.tenantInfoContainer}>
                        <Grid container direction="column" item md={6} className={classes.leftContainer} wrap="nowrap">
                            {tenantDetails ? renderItems('cp-individual', ITEM.TENANT_NAME) : renderTenantAutosuggest()}
                            {ledgerDetails
                                ? tenantName && !showOnlyTenantName && renderItems('cp-ledger', ITEM.LEDGER_NAME)
                                : tenantName && !showOnlyTenantName && renderLedgerDropDown()}
                        </Grid>
                        {tenantName && !showOnlyTenantName && (
                            <Grid
                                container
                                direction="column"
                                item
                                md={6}
                                className={classes.rightContainer}
                                wrap="nowrap"
                            >
                                {renderItems('cp-comment', ITEM.COMMENT)}
                                {renderItems('cp-unit', ITEM.UNIT)}
                                {renderItems('cp-balance', ITEM.BALANCE)}
                            </Grid>
                        )}
                    </Grid>
                )
            }
            {renderLateEventProcessingAlert()}
        </>
    );
};

TenantInformation.propTypes = {
    classes: PropTypes.object,
    isRefund: PropTypes.bool,
    onAction: PropTypes.func,
    onApiError: PropTypes.func,
    currency: PropTypes.string,
    formProps: PropTypes.object,
    clearStoreKeys: PropTypes.func,
    ledgerDetails: PropTypes.object,
    tenantDetails: PropTypes.object,
    setFetchedBalance: PropTypes.func,
    setPrefilledFalse: PropTypes.func,
    getAutoSuggestRef: PropTypes.func,
    getOnLedgerBalance: PropTypes.func,
    showOnlyTenantName: PropTypes.bool,
    toggleLoadingState: PropTypes.func,
    updateCurrentStatus: PropTypes.func,
    reFetchTenantBalance: PropTypes.bool,
    showTenantInformation: PropTypes.bool,
    updateIgnoreWsMessage: PropTypes.func,
    ledgerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tenantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    changeToRefundIfNegativeBalance: PropTypes.bool,
    source: PropTypes.string,
    shouldAutoFocus: PropTypes.bool,
};

TenantInformation.defaultProps = {
    formProps: {},
    currency: '$',
    showOnlyTenantName: false,
    reFetchTenantBalance: false,
    showTenantInformation: true,
    updateIgnoreWsMessage: () => { },
    setFetchedBalance: () => { },
    updateCurrentStatus: () => {},
    source: '',
};

export default withStyles(TenantInformationStyle)(memo(TenantInformation));
