
import { useState } from 'react';
import Button from 'Generic/button/components/Button';
import TreeView from 'Generic/treeview/components/TreeView';
import FormTreeView from 'Generic/treeview/components/FormTreeView';
import { TextField } from 'Generic/textfield/components/TextField';
import Typography from 'Generic/typography/components/Typography';
import useDidUpdateEffect from 'Commons/helpers/hooks/useDidUpdateEffect';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles, Loader } from 'Generic/componentlibrary/components/Components';
import { setObjectValueWithDefault, deepMerge } from 'Commons/helpers/utils/DataHelpers';
import { clsx } from 'Commons/helpers/utils/clsx';
import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import searchableTreeViewStyle from '../styles/SearchableTreeViewStyle';
import { TEXTFIELD_ADDON, CHECKBOX_PROPS } from '../config/Constants';

const useStyles = makeStyles(searchableTreeViewStyle, { name: 'SearchableTreeView' });

const SearchableTreeView = ({
    title,
    config,
    loading,
    GridProps,
    className,
    formProps,
    fullHeight,
    keyToClear,
    placeholder,
    onNodeSelect,
    TextFieldProps,
    showActionItem,
    useFormTreeView,
    ...props
}) => {
    const TreeViewComponent = useFormTreeView ? FormTreeView : TreeView;
    const TreeViewProps = useFormTreeView ? { formProps, CheckboxProps: CHECKBOX_PROPS } : { onNodeSelect };
    const [treeViewItems, setTreeViewItems] = useState(config);
    const classes = useStyles();

    useDidUpdateEffect(() => {
        setTreeViewItems(config);
    }, [config]);

    const onTextChange = (_, inputText) => {
        // NOTE: Filter is supported only for flat trees (i.e only for parent nodes)
        const pattern = new RegExp(`^${inputText}`, 'i');
        const filteredTreeItems = config.filter(({ searchField }) => pattern.test(searchField));
        setTreeViewItems(filteredTreeItems);
    };

    const onClickClearSelection = () => {
        const { setValues, setFieldValue } = formProps;
        if (keyToClear) {
            setFieldValue(keyToClear, {});
        } else {
            setValues({});
        }
    };

    const onClickSelectAll = () => {
        const { setValues, values: formValues = {} } = formProps;
        const values = {};
        config.forEach(({ name }) => {
            setObjectValueWithDefault(values, name, true); // Setting value for nested names
        });
        setValues(deepCopy(deepMerge(formValues, values)));
    };

    return (
        <Grid container direction="column" className={classes.root} {...GridProps}>
            {title && <Typography variant="h6">{title}</Typography>}
            <TextField
                fullWidth
                placeholder={placeholder}
                onChange={onTextChange}
                addon={TEXTFIELD_ADDON}
                {...TextFieldProps}
            />
            {
                showActionItem
                    && (
                        <Grid container justify="flex-end" className={classes.treeActions}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={onClickClearSelection}
                                className={`${classes.button} ${classes.clearButton}`}
                            >
                                {localisable.clearSelection}
                            </Button>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={onClickSelectAll}
                                className={`${classes.button} ${classes.selectAll}`}
                            >
                                {localisable.selectAll}
                            </Button>
                        </Grid>
                    )
            }
            {
                loading
                    ? (
                        <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
                            <Loader />
                        </Grid>
                    )
                    : (
                        <TreeViewComponent
                            className={clsx(classes.treeView, fullHeight && classes.fullHeight, className)}
                            config={treeViewItems}
                            {...TreeViewProps}
                            {...props}
                        />
                    )
            }
        </Grid>
    );
};

SearchableTreeView.propTypes = {
    title: PropTypes.node,
    loading: PropTypes.bool,
    config: PropTypes.array,
    fullHeight: PropTypes.bool,
    className: PropTypes.string,
    GridProps: PropTypes.object,
    formProps: PropTypes.object,
    keyToClear: PropTypes.string,
    onNodeSelect: PropTypes.func,
    placeholder: PropTypes.string,
    showActionItem: PropTypes.bool,
    useFormTreeView: PropTypes.bool,
    TextFieldProps: PropTypes.object,
};

SearchableTreeView.defaultProps = {
    config: [],
    formProps: {},
    TextFieldProps: {},
    placeholder: localisable.search,
};

export default React.memo(SearchableTreeView);
