import localisable from 'Commons/config/strings/localisable';
import useFormatter from 'Commons/components/business/formatters/components/FormatterHOC';
import { SOURCE } from 'Commons/config/constants/Constants';
import userListFormatterStyle from '../style/UserListFormatterStyle';

const {
    Label: labelFormatter,
    Action: actionFormatter,
    Status: statusFormatter,
    Phone: phoneFormatter,
// eslint-disable-next-line react-hooks/rules-of-hooks
} = useFormatter({ source: SOURCE.user.value, formattersRequested: ['Label', 'Action', 'Status', 'Phone'], stylesheet: userListFormatterStyle });

const {
    userListEmail,
    listName,
    userListPhoneNumber,
    listStatus,
    actions,
} = localisable;

const getUserColumns = () => [
    // {                    TODO
    //     label: <Checkbox
    //         color="primary"
    //         disableRipple
    //     />,
    //     key: '',
    //     formatter: RowSelector,
    //     width: 10,
    // },
    {
        label: listName,
        key: 'label',
        formatter: labelFormatter,
        width: 22,
    },
    {
        label: userListEmail,
        key: 'email',
        width: 24,
    },
    {
        label: userListPhoneNumber,
        key: 'phoneNumber',
        formatter: phoneFormatter,
        width: 22,
    },
    {
        label: listStatus,
        key: 'status',
        width: 18,
        formatter: statusFormatter,
    },
    {
        label: actions,
        key: 'actions',
        formatter: actionFormatter,
        width: 14,
    },
];

export default getUserColumns;
