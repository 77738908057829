import media from 'Commons/theme/MediaQueries';

const weekStyle = (theme) => {
    const minHeight = 66.5;
    return {
        paper: {
            padding: theme.spacing(1),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            border: '1px solid #E6E7EB',
            minHeight: `${minHeight}px`,
        },
        dayContainer: ({ renderInRows, maxVisibleContent }) => ({ padding: theme.spacing(1), minHeight: renderInRows ? `${minHeight / 10 * 12}px` : `calc(80px + ${maxVisibleContent - 1}*15px)`, minWidth: '70px' }),
        weekDays: ({ renderInRows }) => ({ ...(!renderInRows && { width: '55px', minWidth: '50px', [media.desktopUp]: { width: '100px', minWidth: '100px' } }) }),
    };
};

export default weekStyle;
