const FacilityFilterStyle = theme => ({
    firstLabel: { paddingBottom: theme.spacing(2) },
    restLabel: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    withoutTokenCheckbox: { marginLeft: theme.spacing(4) },
});

export default FacilityFilterStyle;
