import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { useMemo, memo, useCallback } from 'react';
import highlightSelectedTextStyle from '../styles/HighlightSelectedTextStyle';

const useStyles = makeStyles(highlightSelectedTextStyle, { name: 'HighlightAutoSuggestText' });

const HighlightAutoSuggestText = ({ children, autoSuggestText, disabled }) => {
    const classes = useStyles();
    const textsToHighlight = useMemo(() => autoSuggestText.replace(/^[^a-zA-Z0-9 ]+|[^a-zA-Z0-9 ]+$/g, '')
        .split(' ').filter(el => el), [autoSuggestText]);

    const regExpToSplitTextByTextsToHighlight = () => {
        const regExp = textsToHighlight.reduce(
            (acc, text, index) => acc + text + (index !== textsToHighlight.length - 1 ? '|' : ''), '',
        );
        return new RegExp(`(${regExp})`, 'gi');
    };

    const textArray = useMemo(() => {
        if (autoSuggestText) return children.split(regExpToSplitTextByTextsToHighlight()).filter(el => el);
        return [children];
    }, [children, autoSuggestText]);

    const shouldHighLightText = (text = '') => textsToHighlight.some(
        searchedText => text.toLowerCase().startsWith(searchedText.toLowerCase()),
    );

    const renderChildren = useCallback(() => textArray.map((text, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${text}-${index}`}>
            {shouldHighLightText(text) && !index
                ? <span className={classes.highlighted}>{text}</span>
                : <span className={disabled ? classes.disabled : undefined}>{text}</span>}
        </React.Fragment>
    )), [children, autoSuggestText]);

    return renderChildren();
};

HighlightAutoSuggestText.propTypes = {
    children: PropTypes.string,
    autoSuggestText: PropTypes.string,
};

HighlightAutoSuggestText.defaultProps = {
    label: '',
    children: '',
};

export default memo(HighlightAutoSuggestText);
