/* eslint-disable no-use-before-define */
/**
 * @description This utility provides a way to merge two objects.
 * It is based off: https://github.com/TehShrike/deepmerge
 */

const isMergeableObject = (val) => {
    const nonNullObject = val && typeof val === 'object';

    return nonNullObject
        && Object.prototype.toString.call(val) !== '[object RegExp]'
        && Object.prototype.toString.call(val) !== '[object Date]';
};

const emptyTarget = val => (Array.isArray(val) ? [] : {});

const cloneIfNecessary = (value, optionsArgument) => {
    const clone = optionsArgument && optionsArgument.clone === true;
    return (clone && isMergeableObject(value)) ? deepMerge(emptyTarget(value), value, optionsArgument) : value;
};

const defaultArrayMerge = (target, source, optionsArgument) => {
    const destination = target.slice();
    source.forEach((e, i) => {
        if (typeof destination[i] === 'undefined') {
            destination[i] = cloneIfNecessary(e, optionsArgument);
        } else if (isMergeableObject(e)) {
            destination[i] = deepMerge(target[i], e, optionsArgument);
        } else if (target.indexOf(e) === -1) {
            destination.push(cloneIfNecessary(e, optionsArgument));
        }
    });
    return destination;
};

const mergeObject = (target, source, optionsArgument) => {
    const destination = {};
    if (isMergeableObject(target)) {
        Object.keys(target).forEach((key) => {
            destination[key] = cloneIfNecessary(target[key], optionsArgument);
        });
    }
    Object.keys(source).forEach((key) => {
        if (!isMergeableObject(source[key]) || !target[key]) {
            destination[key] = cloneIfNecessary(source[key], optionsArgument);
        } else {
            destination[key] = deepMerge(target[key], source[key], optionsArgument);
        }
    });
    return destination;
};

/**
 * @param {*} target The target object to be merged into
 * @param {*} source The source object that is getting merged
 * @param {Object} optionsArgument https://github.com/TehShrike/deepmerge#options
 * @param {String} optionsArgument.arrayMerge Possible Options: overwriteMerge, combineMerge
 * OverwriteMerge: Overwrites the existing array values completely rather than concatenating them
 * CombineMerge: Combine arrays, such as overwriting existing defaults while also adding/keeping values that are different names
 */
const deepMerge = (target, source, optionsArgument = {}) => {
    const array = Array.isArray(source);
    const options = optionsArgument || { arrayMerge: defaultArrayMerge };
    const arrayMerge = options.arrayMerge || defaultArrayMerge;

    if (array) {
        return Array.isArray(target) ? arrayMerge(target, source, optionsArgument) : cloneIfNecessary(source, optionsArgument);
    }
    return mergeObject(target, source, optionsArgument);
};

// eslint-disable-next-line no-unused-vars
const deepMergeAll = (array, optionsArgument) => {
    if (!Array.isArray(array) || array.length < 2) {
        throw new Error('first argument should be an array with at least two elements');
    }

    // we are sure there are at least 2 values, so it is safe to have no initial value
    return array.reduce((prev, next) => deepMerge(prev, next, optionsArgument));
};

const deepCopy = object => JSON.parse(JSON.stringify(object));

export {
    deepMerge,
    deepMergeAll,
    deepCopy,
};
