import Icon from 'Commons/components/generic/icon/components/Icon';
import ThreeDotProgress from 'Commons/components/generic/threedotprogress/components/ThreeDotProgress';
import localisable from 'Commons/config/strings/localisable';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { clsx } from 'Commons/helpers/utils';
import { Divider, Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import LabelWithAddons from 'Generic/labelwithaddons/components/LabelWithAddons';
import Modal from 'Generic/modal/components/Modal';
import Typography from 'Generic/typography/components/Typography';
import { FILE_UPLOAD_STATUS } from '../config/Constants';
import uploadStatusStyle from '../styles/UploadStatusStyles';

const getStatusConfig = classes => ({
    [FILE_UPLOAD_STATUS.ERROR]: { icon: 'cp-reset', nameClassName: classes.uploadError, key: 'error' },
    [FILE_UPLOAD_STATUS.SUCCESS]: { className: classes.uploadSuccess, icon: 'cp-success', key: 'success' },
    [FILE_UPLOAD_STATUS.LOADING]: { node: <ThreeDotProgress className={classes.uploading} />, key: 'pending' },
});

const useStyles = makeStyles(uploadStatusStyle, { name: 'UploadStatus' });

const FileItem = ({ id, status, name, classes, onRetry }) => {
    const { icon, className, node, nameClassName } = getStatusConfig(classes)[status] || {};
    const start = useConstructor(() => <Icon type="custom" icon="cp-image" display="block" />);
    const isErrored = status === FILE_UPLOAD_STATUS.ERROR;
    const end = isErrored ? (
        <Icon
            type="custom"
            icon="cp-info"
            display="block"
            className={classes.uploadError}
        />
    ) : null;
    return (
        <>
            <Grid container justify="space-between" alignItems="center" className={classes.marginTop8}>
                <LabelWithAddons
                    end={end}
                    className={classes.autoWidth}
                    labelClassName={classes.photoName}
                    start={start}
                >
                    <Typography className={nameClassName} noWrap>{name}</Typography>
                </LabelWithAddons>
                {
                    node || (
                        <Icon
                            icon={icon}
                            type="custom"
                            color="primary"
                            onClick={isErrored ? () => onRetry(id) : undefined}
                            className={className}
                        />
                    )
                }
            </Grid>
            <Divider />
        </>
    );
};

FileItem.propTypes = {
    name: PropTypes.string,
    onRetry: PropTypes.func,
    id: PropTypes.number,
    status: PropTypes.string,
    classes: PropTypes.object,
};

const UploadStatus = ({ open, fileList, onRetry, onClose }) => {
    const classes = useStyles();
    const { modalHeader, modalClasses, bodyContainerProps } = useConstructor(() => ({
        modalHeader: <Typography variant="h6">{localisable.uploadStatus}</Typography>,
        modalClasses: {
            modalGrid: classes.uploadModalGrid,
            modalHeader: classes.modalHeader,
            closeButton: classes.closeButton,
            modalFooter: classes.modalFooter,
        },
        bodyContainerProps: { direction: 'column' },
    }));

    const getFooter = () => {
        const uploadSummary = { loading: 0, success: 0, error: 0 };

        fileList.forEach(({ status } = {}) => {
            if (status) {
                uploadSummary[status.toLowerCase()] += 1;
            }
        });
        const { loading, success, error } = uploadSummary;

        return (
            <Grid container alignItems="center" className={classes.modalFooter}>
                <Typography variant="body2" fontStyle="italic">
                    {`${loading} ${localisable.uploadPending}, ${success} ${localisable.successful}, ${error} ${localisable.unsuccessful}`}
                </Typography>
            </Grid>
        );
    };

    return (
        <Modal
            open={open}
            header={modalHeader}
            footer={getFooter()}
            classes={modalClasses}
            bodyContainerProps={bodyContainerProps}
            onClose={onClose}
        >
            <Grid
                container
                wrap="nowrap"
                direction="column"
                className={clsx(classes.photosToUpload, classes.uploadStatus)}
            >
                {
                    fileList.map(({ file, status }, index) => (
                        <FileItem
                            key={index}
                            id={index}
                            name={file.name}
                            status={status}
                            classes={classes}
                            onRetry={onRetry}
                        />
                    ))
                }
            </Grid>
        </Modal>
    );
};

UploadStatus.propTypes = {
    open: PropTypes.bool,
    onRetry: PropTypes.func,
    onClose: PropTypes.func,
    fileList: PropTypes.array,
};

export default UploadStatus;
