const JournalLockoutEventFilterStyle = theme => ({
    addButton: {
        minWidth: theme.spacing(4),
        minHeight: theme.spacing(4),
        marginLeft: theme.spacing(2),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
    },
    chipWrapper: {
        maxWidth: theme.spacing(36),
        marginTop: theme.spacing(2),
        overflow: 'auto',
    },
    chip: {
        height: theme.spacing(4),
        background: theme.palette.chip.reserved,
        borderColor: theme.palette.chip.reserved,
        borderRadius: theme.spacing(2),
    },
    chipLabel: {
        color: theme.palette.common.white,
        fontSize: theme.elements.input.label.fontSize,
    },
    restLabel: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },

});

export default JournalLockoutEventFilterStyle;
