import { VARIANT } from 'Generic/form/config/FormComponentsConfig';

const BevFormField = ({ component: Component, ...props }) => (
    <Component
        formVariant={VARIANT.NO_LEFT_RIGHT_PADDING}
        {...props}
    />
);


BevFormField.propTypes = {
    component: PropTypes.object.isRequired,
    required: PropTypes.bool,
};

BevFormField.defaultProps = { required: false };

export default BevFormField;
