import { memo } from 'react';
import { makeStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle }
from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import alertDialogStyle from '../styles/AlertDialogStyle';

const useStyles = makeStyles(alertDialogStyle);

const AlertDialog = ({
    title, actions, hideCloseButton, useContentText, children, dialogClasses,
    DialogTitleProps, DialogContentProps, DialogContentTextProps, DialogActionProps,
    onClose, open, ...props
}) => {
    const { classes: ignore, ...others } = props; // Ignoring classes, to avoid passing it to Dialog
    const classes = useStyles(props);

    const content = (children && useContentText) ? (
        <DialogContentText id="alert-dialog-description" {...DialogContentTextProps}>
            {children}
        </DialogContentText>
    ) : children;
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                disableBackdropClick
                transitionDuration={{ exit: 0 }}
                classes={dialogClasses}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ classes: { root: classes.backdrop } }}
                {...others}
            >
                {title && (
                    <div className={classes.padding}>
                        <div className={classes.positionRelative}>
                            <DialogTitle
                                id="alert-dialog-title"
                                className={classes.dialogTitle}
                                {...DialogTitleProps}
                            >
                                {title}
                            </DialogTitle>
                            {
                                !hideCloseButton
                                && (
                                    <Button
                                        onClick={onClose}
                                        variant="icon"
                                        icon="cp-close"
                                        iconType="custom"
                                        color="primary"
                                        className={classes.closeButton}
                                        IconProps={{ classes: { default: classes.iconSize } }}
                                    />
                                )
                            }
                        </div>
                    </div>
                )}
                {
                    content
                    && (
                        <DialogContent {...DialogContentProps}>
                            {content}
                        </DialogContent>
                    )
                }
                {
                    actions
                    && (
                        <DialogActions className={classes.padding} {...DialogActionProps}>
                            {actions}
                        </DialogActions>
                    )
                }
            </Dialog>
        </>
    );
};

AlertDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.node,
    onClose: PropTypes.func,
    children: PropTypes.node,
    actions: PropTypes.node,
    classes: PropTypes.object,
    useContentText: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    dialogClasses: PropTypes.object,
    DialogTitleProps: PropTypes.object,
    DialogActionProps: PropTypes.object,
    DialogContentProps: PropTypes.object,
    DialogContentTextProps: PropTypes.object,
};

AlertDialog.defaultProps = { open: false };

export default memo(AlertDialog);
