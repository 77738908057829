import Button from 'Generic/button/components/Button';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { getValue, hasValue, isObjWithKeys, isValidValue } from 'Commons/helpers/utils/DataHelpers';
import FieldsCache from 'Commons/helpers/FieldsCache';
import DeleteOverlay from 'Commons/components/business/deleteOverlay/components/DeleteOverlay';
import { DELETE_OVERLAY_ACTION, DELETE_OVERLAY_DELETION_STATUS } from 'Commons/config/constants/Constants';
import { isPrimitiveType } from 'Commons/helpers/api/Formatter';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import localisable from 'Commons/config/strings/localisable';
import Section from './Section';
import onDemandSectionStyle from '../styles/OnDemandStyle';

const useStyles = makeStyles(onDemandSectionStyle, { name: 'OnDemandSection' });

const doesSectionContainValue = (sectionObj) => {
    if (Array.isArray(sectionObj)) {
        return sectionObj.some(item => doesSectionContainValue(item));
    }

    if (!isPrimitiveType(sectionObj)) {
        const keys = Object.keys(sectionObj);
        return keys.length ? keys.some(key => doesSectionContainValue(sectionObj[key])) : false;
    }

    return isValidValue(sectionObj);
};

const OnDemandSection = ({
    id,
    open,
    values,
    touched,
    children,
    formikKey,
    fieldsCache,
    isFormActive,
    initialValues,
    setFieldValue,
    onClick: onSectionClick,
    onAddOrRemoveSection,
    defaultValues,
    setValues,
    resetForm,
    setTouched,
    validateForm,
    classes: onDemandClasses,
    isAnySectionFieldVisible,
    ...props
}) => {
    const isNew = useRef();
    const [{ existInTheForm, deleteStatus, markedForDeletion }, setSectionStatus] = useState(() => {
        const sectionValue = getValue(initialValues, formikKey);
        const sectionHasValue = doesSectionContainValue(sectionValue);
        isNew.current = !sectionHasValue;
        return { existInTheForm: sectionHasValue, markedForDeletion: false, deleteStatus: '' };
    });
    const [deletedValues, setDeletedValues] = useState({});
    const [sectionTouched, setSectionTouched] = useState({});

    const classes = useStyles();

    const getDeleteStatus = () => (isNew.current
        ? DELETE_OVERLAY_DELETION_STATUS.NEW : DELETE_OVERLAY_DELETION_STATUS.EXISTING);

    const isTouched = () => hasValue(touched, formikKey);

    useEffect(() => {
        if (open !== existInTheForm) {
            onSectionClick(id, existInTheForm);
        }
        onAddOrRemoveSection(id, existInTheForm);
    }, [existInTheForm]);

    const onAction = (currentKey, action = DELETE_OVERLAY_ACTION.DELETE) => {
        let newSectionStatus = {};
        switch (action) {
            case DELETE_OVERLAY_ACTION.ADD:
                // User clicked on plus button to add new add on demand section
                newSectionStatus = {
                    existInTheForm: true,
                    markedForDeletion: false,
                    deleteStatus: '',
                };
                if (setValues && defaultValues) {
                    setValues({ ...values, [formikKey]: defaultValues });
                }
                break;
            case DELETE_OVERLAY_ACTION.OK:
                // User clicked on ok after marking for deletion
                // Valid only for newly added on demand section
                newSectionStatus = {
                    existInTheForm: false,
                    markedForDeletion: false,
                    deleteStatus: '',
                };
                setFieldValue(currentKey, undefined);
                if (setValues && defaultValues) {
                    const updatedValue = { ...values };
                    delete updatedValue[formikKey];
                    setValues(updatedValue);
                }
                break;
            case DELETE_OVERLAY_ACTION.UNDO:
                // User Clicked on cancel after marking for deletion
                newSectionStatus = {
                    existInTheForm: true,
                    markedForDeletion: false,
                    deleteStatus: '',
                };
                if (isObjWithKeys(deletedValues)) {
                    setValues({ ...values, [formikKey]: { ...deletedValues, markedForDelete: undefined } });
                    setDeletedValues({});
                }
                if (isObjWithKeys(sectionTouched)) {
                    setTouched(sectionTouched);
                    setSectionTouched({});
                }
                fieldsCache.delete(formikKey);
                break;
            default:
                // User Clicked on remove button
                newSectionStatus = {
                    existInTheForm: true,
                    markedForDeletion: true,
                    deleteStatus: getDeleteStatus(),
                };
                if (existInTheForm) {
                    const updatedValues = { ...values };
                    const updatedInitialValues = { ...initialValues };
                    const updatedTouched = { ...touched };
                    delete updatedValues[formikKey];
                    delete updatedInitialValues[formikKey];
                    delete updatedTouched[formikKey];
                    resetForm(updatedInitialValues);
                    setValues({ ...updatedValues, [formikKey]: { markedForDelete: true } });
                    setTouched(updatedTouched);
                    setSectionTouched(touched);
                    fieldsCache.add(formikKey);
                    setDeletedValues(values[formikKey]);
                }
                break;
        }
        setSectionStatus(newSectionStatus);
    };

    const onClick = () => {
        let action = DELETE_OVERLAY_ACTION.ADD;
        if (existInTheForm) {
            action = DELETE_OVERLAY_ACTION.DELETE;
            if (isNew.current && !isTouched()) {
                action = DELETE_OVERLAY_ACTION.OK;
            }
        }
        onAction(formikKey, action);
    };

    return (
        <Section
            id={id}
            {...(isAnySectionFieldVisible
                ? {
                    addon: {
                        end: (
                            <Tooltip title={existInTheForm ? localisable.remove : localisable.add}>
                                <Button
                                    variant="icon"
                                    icon={existInTheForm ? 'cp-close' : 'cp-plus'}
                                    iconType="custom"
                                    disabled={!isFormActive}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        ),
                    },
                }
                : {})
            }
            open={open}
            classes={{
                sectionChildren: `${classes.sectionChildren} ${existInTheForm ? classes.borderSolid : ''} ${!isAnySectionFieldVisible ? classes.paddingLeft : ''}`,
                header: open ? classes.openHeader : classes.closedHeader,
                section: classes.section,
                children: classes.onDemandSection,
                ...onDemandClasses,
            }}
            onDemandSection
            controlled
            onClick={onSectionClick}
            {...props}
        >
            {children}
            {markedForDeletion && (
                <DeleteOverlay
                    onDelete={onAction}
                    currentKey={formikKey}
                    deleteStatus={deleteStatus}
                />
            )}
        </Section>
    );
};

OnDemandSection.propTypes = {
    id: PropTypes.any,
    open: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    values: PropTypes.object,
    touched: PropTypes.object,
    setFieldValue: PropTypes.func,
    isFormActive: PropTypes.bool,
    initialValues: PropTypes.object,
    fieldsCache: PropTypes.instanceOf(FieldsCache),
    formikKey: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    onAddOrRemoveSection: PropTypes.func,
    defaultValues: PropTypes.object,
    setValues: PropTypes.func,
    resetForm: PropTypes.func,
    setTouched: PropTypes.func,
    validateForm: PropTypes.func,
    classes: PropTypes.object,
    isAnySectionFieldVisible: PropTypes.bool,
};

OnDemandSection.defaultProps = {
    touched: () => { },
    values: {},
    formikKey: [],
    setFieldValue: () => { },
    classes: {},
};

export default OnDemandSection;
