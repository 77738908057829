const alertDialogStyle = theme => ({
    padding: { padding: theme.spacing(2, 3) },
    dialogTitle: {
        padding: 0,
        width: 'calc(100% - 2.5rem)', // Removing Icon Width
    },
    positionRelative: { position: 'relative' },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 0,
        margin: 'auto',
    },
    iconSize: { fontSize: '1.8rem' },
    backdrop: { backgroundColor: `${theme.palette.common.white}B3` },
});

export default alertDialogStyle;
