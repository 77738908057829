/**
 * @description A class which keeps unique set of cache, with helper function such as add, delete and isEmpty
 */
class FieldsCache {
    constructor() {
        this.fields = new Set();
    }

    /**
     * @description Returns true if the field exist in the cache otherwise false
     * @param {string} fieldName
     */
    has = fieldName => this.fields.has(fieldName);

    /**
     * @description Returns the field cache set
     */
    get = () => this.fields;

    /**
     * @description Helper function to add a field into cache
     * @param {string} fieldName
     */
    add = fieldName => this.fields.add(fieldName)

    /**
     * @description Helper function to delete a field from cache
     * @param {string} fieldName
     */
    delete = (fieldName) => {
        this.fields.delete(fieldName);
    }

    /**
     * @description Returns true if the cache is empty otherwise false
     */
    isEmpty = () => !!(this.fields && this.fields.length)
}

export default FieldsCache;
