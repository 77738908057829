const ExpansionPanelStyle = theme => ({
    panel: {
        width: '100%',
        boxShadow: 'none',
        boxSizing: 'border-box',
        backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='black' stroke-width='1' stroke-dasharray='4%2c4 ' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
        borderColor: theme.palette.black[500],
        marginTop: theme.spacing(2),
    },
    panelOpen: { height: 'auto' },
    panelClose: { height: theme.spacing(5) },
    panelSummary: { padding: theme.spacing(0, 3, 0, 2) },
    panelSummaryAdjustment: { marginTop: theme.spacing(-1.25) },
    panelSummaryIcon: { color: theme.palette.primary.main },
    panelDetails: { paddingLeft: theme.spacing(2) },
    expanded: {},
    root: { '&$expanded': { minHeight: theme.spacing(6) } },
    content: { '&$expanded': { margin: theme.spacing(2, 0) } },
});

export default ExpansionPanelStyle;
