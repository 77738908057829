import React, { useState, memo } from 'react';
import localisable from 'Commons/config/strings/localisable';
import Icon from 'Generic/icon/components/Icon';
import { Form, Formik } from 'formik';
import Button from 'Generic/button/components/Button';
import { FormCheckbox } from 'Generic/checkbox/components/CheckBox';
import Tabs from 'Generic/tabs/components/Tabs';
import { HIGHLIGHT_TYPE } from 'Generic/tabs/config/Constants';
import SmartList from 'Commons/components/business/smartlist/components/SmartList';
import { TextField } from 'Generic/textfield/components/TextField';
import { STATUS, TABLE_ROW_HEIGHT_VARIANT } from 'Commons/config/constants/Constants';
import { ClickAwayListener, Grid, makeStyles, Paper, Popper } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { Dropdown } from 'Generic/dropdown/components/Dropdown';
import {
    getFacilityColumns, getBusinessGroupColumns, getAccountColumns,
    ENTITY_TYPE,
} from '../config/RoleSelectionConfig';
import roleSelectionStyle from '../styles/RoleSelectionStyle';


const useStyles = makeStyles(roleSelectionStyle);

const RoleSelection = ({
    userRole,
    selectAllPreviewData,
    onApplyRole,
    userPermissions,
    onCopyClick,
    userList,
    userSearch,
    fetchSelectedUserPermission,
    onCopyUser,
    formProps,
    isModeCreate,
    roleSelectionContainerRef,
    ...props
}) => {
    const { deviceInfo: { isDesktop } } = window;
    const classes = useStyles();
    const [selectedRoleData, setSelectedRoleData] = useState(userRole[Object.keys(userRole)[0]] || {});
    const { id: selectedRoleId, permission: selectedRolePermission = {} } = selectedRoleData;
    const [open, setOpenState] = useState(false);
    const [userSelected, setUserSelected] = useState('');
    const [anchorEl, setanchorEl] = useState(null);
    const getRoleList = () => Object.keys(userRole)
        .filter((role) => {
            const { status = STATUS.Inactive.value } = userRole[role];
            return status === STATUS.Active.value;
        })
        .map(role => ({ label: userRole[role].name, value: role }));
    const roleList = getRoleList();

    const onClick = (event) => {
        if (!anchorEl) {
            setanchorEl(event.currentTarget);
        }
        setUserSelected('');
        setOpenState(true);
        onCopyClick();
    };

    const onCopy = (entityList) => {
        setOpenState(false);
        onCopyUser(entityList);
    };

    const showEntityListToCopy = () => (
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                onCopy(values);
                actions.setSubmitting(false);
            }}
            className="login"
            render={
                () => (
                    <Form>
                        <Grid container direction="column">
                            <Grid item>
                                <FormCheckbox
                                    name="account"
                                    label={localisable.accountCamel}
                                    color="primary"
                                    noGrid
                                />
                            </Grid>
                            <Grid item>
                                <FormCheckbox
                                    name="businessGroup"
                                    label={localisable.businessGroup}
                                    color="primary"
                                    noGrid
                                />
                            </Grid>
                            <Grid item>
                                <FormCheckbox
                                    name="facility"
                                    label={localisable.facility}
                                    color="primary"
                                    noGrid
                                />
                            </Grid>
                            <Button variant="contained" type="submit" color="primary">Copy</Button>
                        </Grid>
                    </Form>
                )}
        />
    );

    const onClickOfUser = (user) => {
        const { firstName, lastName } = user;
        fetchSelectedUserPermission(user);
        setUserSelected(`${firstName} ${lastName}`);
    };

    const showUserList = () => (
        <Grid container direction="column" wrap="nowrap" spacing={1} item className={classes.listRenderer}>
            {userList.map(user => (
                <Grid item key={user.id} className={classes.onHoverRole} onClick={() => onClickOfUser(user)}>
                    {user.firstName + user.lastName}
                </Grid>
            ))
            }
        </Grid>
    );

    const getCopyList = () => (
        <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            transition
            className={classes.popper}
        >
            <ClickAwayListener
                onClickAway={() => setOpenState(false)}
            >
                <Paper
                    className={classes.paperPadding}
                >
                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Typography variant="h5">{localisable.copyRoleFrom}</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                name="user"
                                placeholder="Search User"
                                addon={{
                                    start: <Icon
                                        icon="cp-search"
                                        type="custom"
                                    />,
                                }}
                                trackValue={!userSelected}
                                value={userSelected}
                                onChange={(event) => {
                                    userSearch(event.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        { userSelected
                            ? showEntityListToCopy()
                            : showUserList()
                        }
                    </Grid>
                </Paper>
            </ClickAwayListener>
        </Popper>
    );

    const getTitle = () => (
        <Grid container item xs={12} alignItems="center" justify="space-between" className={classes.title}>
            <Grid item xs={6}>
                <Typography variant="h6">
                    {localisable.roleSelection}
                </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
                <Icon
                    icon="cp-search"
                    type="custom"
                    disabled
                    className={classes.iconPadding}
                />
                <Icon
                    icon="cp-copy"
                    type="custom"
                    onClick={onClick}
                    className={classes.iconPadding}
                />
                {getCopyList()}
            </Grid>
        </Grid>
    );

    const selectAll = (tabName) => {
        selectAllPreviewData({ tabName, selectedRoleData });
    };

    const getRespectedTableData = (tabName) => {
        switch (tabName) {
            case ENTITY_TYPE.ACCOUNT: return getAccountColumns(selectAll);
            case ENTITY_TYPE.BUSINESSGROUP: return getBusinessGroupColumns(selectAll);
            default: return getFacilityColumns(selectAll);
        }
    };

    const onRoleSelect = (value) => {
        onApplyRole({ selectedRole: selectedRoleData, selectedEntity: value });
    };

    const createCustomDataForRequiredTab = ({ tabName, type }) => {
        const { [`${tabName}Data`]: entityData } = props;
        return Object.keys(entityData).map((entityId) => {
            const [{ permission: { [tabName]: permissions = {} } = {} } = {}] = userPermissions;
            return ({
                ...entityData[entityId],
                type,
                onSelectRow: onRoleSelect,
                ...permissions[entityId] && {
                    appliedRoleData: userRole[permissions[entityId].roleId],
                    checked: true,
                },
            });
        });
    };

    const getListConfig = (tabName) => {
        const { clientHeight, clientWidth } = roleSelectionContainerRef || {};
        return ({
            store: `${tabName}`,
            columns: getRespectedTableData(tabName),
            emptyBodyText: localisable.noDataFound,
            tableProps: {
                headerRowHeight: TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER,
                rowHeight: 48,
                tableWidth: isDesktop ? clientWidth - 64 : 500,
                tableHeight: isDesktop ? clientHeight - 150 : 300,
                hideLastColumn: false,
                recalculate: true,
                leftAndRightPadding: 0,
            },
            tablePaperProps: { hideSearch: true },
            getCustomData: () => {
                switch (tabName) {
                    case ENTITY_TYPE.ACCOUNT: {
                        return createCustomDataForRequiredTab({ tabName, type: ENTITY_TYPE.ACCOUNT });
                    }
                    case ENTITY_TYPE.BUSINESSGROUP: {
                        return createCustomDataForRequiredTab({ tabName, type: ENTITY_TYPE.BUSINESSGROUP });
                    }
                    default: {
                        return createCustomDataForRequiredTab({ tabName, type: ENTITY_TYPE.FACILITY });
                    }
                }
            },
        });
    };

    const getTableData = tabName => (
        <SmartList
            listConfig={getListConfig(tabName)}
            key={tabName}
        />
    );

    const getTabData = () => ([
        {
            id: 0,
            label: localisable.account,
            color: 'rolesGroupPrimary',
            children: getTableData(ENTITY_TYPE.ACCOUNT),
            disabled: !(ENTITY_TYPE.ACCOUNT in selectedRolePermission),
        },
        {
            id: 1,
            label: localisable.businessGroup,
            color: 'rolesGroupSecondary',
            children: getTableData(ENTITY_TYPE.BUSINESSGROUP),
            disabled: !(ENTITY_TYPE.BUSINESSGROUP in selectedRolePermission),
        },
        {
            id: 2,
            label: localisable.facility,
            color: 'rolesGroupTertiary',
            children: getTableData(ENTITY_TYPE.FACILITY),
            disabled: !(ENTITY_TYPE.FACILITY in selectedRolePermission),
        },
    ]);

    const selectedRolePostProcess = (_, value) => {
        setSelectedRoleData(userRole[value]);
    };

    const renderPermissionTabs = () => {
        const tabs = (
            <Tabs
                activeTab={2}
                highlightType={HIGHLIGHT_TYPE.HALF}
                tabsData={getTabData()}
                tabsClasses={{ rootClass: classes.rootClass, flexContainerClass: classes.flexContainer }}
            />
        );
        return isDesktop ? tabs : <Grid item sm={6}>{tabs}</Grid>;
    };

    const getRolesType = () => (
        <Grid container>
            <Grid item sm={6} className={classes.dropDownGrid}>
                <Dropdown
                    value={selectedRoleId}
                    placeholder="Enter Role"
                    className={classes.dropdown}
                    list={roleList}
                    fullWidth
                    onChange={selectedRolePostProcess}
                    maxHeight={200}
                />
            </Grid>
            { renderPermissionTabs() }
        </Grid>
    );
    return (
        <Grid
            container
            className={classes.block}
        >
            {getTitle()}
            {getRolesType()}
        </Grid>
    );
};

RoleSelection.propTypes = {
    userRole: PropTypes.object,
    onApplyRole: PropTypes.func,
    facilityData: PropTypes.object,
    businessGroupData: PropTypes.object,
    accountData: PropTypes.object,
    userPermissions: PropTypes.array,
    selectAllPreviewData: PropTypes.func,
    onCopyClick: PropTypes.func,
    userList: PropTypes.array,
    userSearch: PropTypes.func,
    fetchSelectedUserPermission: PropTypes.func,
    onCopyUser: PropTypes.func,
    formProps: PropTypes.object,
    isModeCreate: PropTypes.bool,
    roleSelectionContainerRef: PropTypes.object,
};

RoleSelection.defaultProps = {
    userRole: {},
    selectAllPreviewData: () => {},
    onApplyRole: () => {},
    facilityData: {},
    businessGroupData: {},
    accountData: {},
    userPermissions: [],
    onCopyClick: () => {},
    userList: [],
    userSearch: () => {},
    fetchSelectedUserPermission: () => {},
    onCopyUser: () => {},
};

export default memo(RoleSelection);
