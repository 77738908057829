const ChipStyle = theme => ({
    root: {
        backgroundColor: theme.palette.disabled,
        padding: '4px 8px',
    },
    leftIcon: { margin: 0 },
    frontCloseIcon: {
        backgroundColor: theme.palette.common.white,
        opacity: 0.8,
        width: 24,
        height: 24,
        borderRadius: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 16,
        marginLeft: 0,
        fontSize: 16,
    },
    label: { padding: 0 },
    labelText: {},
    labelPadding: { paddingRight: 8 },
    closeIcon: {
        fontSize: 14,
        marginLeft: 16,
        backgroundColor: theme.palette.common.white,
        opacity: 0.6,
        height: 14,
        width: 14,
        borderRadius: '50%',

    },
    nestedChipPadding: {
        '& :first-child': { marginLeft: 0 },
        '& :last-child': { marginRight: 0 },
        '& *': { marginLeft: 4, marginRight: 4 },
    },

    bgrentalStatusVacant: { backgroundColor: theme.palette.rentalStatus.vacant, color: theme.palette.common.white },
    bgrentalStatusRented: { backgroundColor: theme.palette.rentalStatus.rented, color: theme.palette.common.white },
    bgrentalStatusUnavailable: { backgroundColor: theme.palette.rentalStatus.unavailable, color: theme.palette.common.white },

    bgerror: { backgroundColor: theme.palette.red, color: theme.palette.common.white },
    bgstatusError: { backgroundColor: theme.palette.error.main, color: theme.palette.common.white },

    bgstatusActive: { backgroundColor: theme.palette.status.active, color: theme.palette.common.white },
    bgstatusInactive: { backgroundColor: theme.palette.status.inactive },
    bgstatusLocked: { backgroundColor: theme.palette.status.locked, color: theme.palette.common.white },
    bgstatusCreated: { backgroundColor: theme.palette.status.created, color: theme.palette.common.white },
    bgrolesPrimary: { backgroundColor: theme.palette.rolesGroup.primary, color: theme.palette.common.white },
    bgrolesSecondary: { backgroundColor: theme.palette.rolesGroup.secondary, color: theme.palette.common.white },
    bgrolesTertiary: { backgroundColor: theme.palette.rolesGroup.tertiary, color: theme.palette.common.white },

    iconstatusActive: { color: theme.palette.status.active },
    iconstatusInactive: { color: theme.palette.status.inactive },
    iconstatusLocked: { color: theme.palette.status.locked },
    iconstatusCreated: { color: theme.palette.status.created },
    iconrolesPrimary: { color: theme.palette.rolesGroup.primary },
    iconrolesSecondary: { color: theme.palette.rolesGroup.secondary },
    iconrolesTertiary: { color: theme.palette.rolesGroup.tertiary },
    disabled: {
        cursor: 'default',
        pointerEvents: 'none',
        color: theme.palette.disabled,
    },

    bglight: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.text.primary}`,
    },

    bgstatusReserved: { backgroundColor: theme.palette.chip.reserved, color: theme.palette.common.white },
    bgstatusCancelled: { backgroundColor: theme.palette.status.cancelled, color: theme.palette.common.white },

    bgmaintenanceeventstatusUpcoming: { backgroundColor: theme.palette.status.upcoming, color: theme.palette.common.white },
    bgmaintenanceeventstatusPending: { backgroundColor: theme.palette.status.locked, color: theme.palette.common.white },
    bgmaintenanceeventstatusCompleted: { backgroundColor: theme.palette.status.created, color: theme.palette.common.white },
    bgmaintenanceeventstatusClosed: { backgroundColor: theme.palette.status.active, color: theme.palette.common.white },
    bgmaintenanceeventstatusCancelled: { backgroundColor: theme.palette.status.inactive, color: theme.palette.common.black },
    bgdelinquencyScheduled: { backgroundColor: theme.palette.tertiary.main, color: theme.palette.common.black },

    bgautoPayment: { backgroundColor: theme.palette.status.created, color: theme.palette.common.white },
    bgbatchPayment: { backgroundColor: theme.palette.status.upcoming, color: theme.palette.common.white },

    bgleadstatusActive: { backgroundColor: theme.palette.primary.main, color: theme.palette.common.white },
    bgleadstatusInactive: { backgroundColor: theme.palette.status.inactive, color: theme.palette.common.white },
    bgleadstatusConverted: { backgroundColor: theme.palette.status.active, color: theme.palette.common.white },
    bgleadstatusCold: { backgroundColor: theme.palette.chip.estimate, color: theme.palette.common.white },
    bgleadstatusLost: { backgroundColor: theme.palette.status.leadLost, color: theme.palette.common.white },
    bgleadstatusDuplicate: { backgroundColor: theme.palette.status.leadDuplicate, color: theme.palette.common.white },
    bgratehistoryWaived: { backgroundColor: theme.palette.warning.icon, color: theme.palette.common.white },
    bgratehistoryMovedOut: { backgroundColor: theme.palette.occupancy.moveout, color: theme.palette.common.white },

    bghighlight: { backgroundColor: theme.palette.highlight, color: theme.palette.secondary.main },

    bgestimatestatusActive: { backgroundColor: theme.palette.status.active, color: theme.palette.common.white },
    bgestimatestatusMovedIn: { backgroundColor: theme.palette.status.created, color: theme.palette.common.white },
    bgestimatestatusExpired: { backgroundColor: theme.palette.status.expired, color: theme.palette.common.white },

    bgsetUpPending: { backgroundColor: theme.palette.setUp.pending, color: theme.palette.common.white },

    bgmigrationDumpGenerationSuccess: {
        backgroundColor: theme.palette.migration.dumpGenerationSuccess,
        color: theme.palette.common.white,
    },
    bgmigrationNotStarted: { backgroundColor: theme.palette.migration.notStarted },
    bgmigrationProgress: { backgroundColor: theme.palette.migration.inProgress, color: theme.palette.common.white },
    bgmigrationCompleted: { backgroundColor: theme.palette.migration.completed, color: theme.palette.common.white },
    bgmigrationErrored: { backgroundColor: theme.palette.migration.errored, color: theme.palette.common.white },
    bgmigrationDeleted: { backgroundColor: theme.palette.migration.deleted, color: theme.palette.common.white },
    bgmigrationServerActive: {
        backgroundColor: theme.palette.migration.serverActive,
        color: theme.palette.common.white,
    },
    bgmigrationServerInactive: { backgroundColor: theme.palette.migration.serverInactive },
    bgtransparent: { backgroundColor: theme.palette.migration.transparent },

    bgdelinquencystatusProcessed: { backgroundColor: theme.palette.status.active, color: theme.palette.common.white },
    bgdelinquencystatusNotProcessed: { backgroundColor: theme.palette.primary.main, color: theme.palette.common.white },

    bgrentAdjustmentPending: { backgroundColor: theme.palette.status.created, color: theme.palette.common.white },
    bgrentAdjustmentCompleted: { backgroundColor: theme.palette.status.active, color: theme.palette.common.white },
    bgrentAdjustmentStopped: { backgroundColor: theme.palette.status.cancelled, color: theme.palette.common.white },
    bgemailAuditYes: { backgroundColor: theme.palette.emailAudit.yes, color: theme.palette.common.white },
    bgemailAuditNo: { backgroundColor: theme.palette.emailAudit.no, color: theme.palette.common.white },

});

export default ChipStyle;
