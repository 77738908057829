import { EMPTY_FUNC, EMPTY_OBJECT } from 'Commons/config/constants/Constants';
import { SmartListProps } from 'Commons/components/business/smartlist/config/SmartListProps';
import { PageProps } from 'Commons/components/business/page/config/PageProps';

const SmartListWithFormProps = {
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onSubmit: PropTypes.func,
    fullHeight: PropTypes.bool,
    getAddRowFunc: PropTypes.func,
    getDeleteRowFunc: PropTypes.func,
    FormikProps: PropTypes.object,
    updateDeletedRow: PropTypes.func, // Pass all the row details to this function from the custom delete formatter. Alternatively this can be passed directly via listConfig
    ContainerProps: PropTypes.shape({
        ...PageProps,
        footer: PropTypes.func,
    }),
    initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    formConfigForColumns: PropTypes.objectOf( // keys of formConfigForColumns will be the index of columns which were provided in the list config
        PropTypes.shape({
            formFieldInfo: PropTypes.shape({
                name: PropTypes.string, // The field name will be index.name
                Component: PropTypes.elementType, // Default will be FormTextField if not provided
                // .. rest Field specific props, They'll be passed to the above-mentioned Component
            }),
        }),
    ),
    getFormProps: PropTypes.func,
    invalidData: PropTypes.object,
    autoSave: PropTypes.bool,
    handleEditFormData: PropTypes.bool, // If we want to send only the changeset in editFormData
    pageKey: PropTypes.any,
    ...SmartListProps,
};

const SmartListWithFormDefaultProps = {
    header: null,
    fullHeight: false,
    autoSave: false,
    FormikProps: EMPTY_OBJECT,
    getAddRowFunc: EMPTY_FUNC,
    getDeleteRowFunc: EMPTY_FUNC,
};

export { SmartListWithFormProps, SmartListWithFormDefaultProps };
