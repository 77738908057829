import Button from 'Generic/button/components/Button';
import Icon from 'Commons/components/generic/icon/components/Icon';
import ButtonText from 'Generic/buttontext/components/ButtonText';
import { ClickAwayListener, Grid, withStyles } from 'Generic/componentlibrary/components/Components';
import { Dropdown } from 'Generic/dropdown/components/Dropdown';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import Transition from 'Generic/transition/components/Transition';
import { EMPTY_FUNC, SLIDE_DIRECTION, TABLE_ROW_HEIGHT_VARIANT } from 'Commons/config/constants/Constants';
import { capitalize } from 'Commons/helpers/utils/DataHelpers';
import { TextField } from 'Commons/components/generic/textfield/components/TextField';
import { clsx } from 'Commons/helpers/utils/clsx';
import BaseForm from 'Commons/components/business/baseform/components/BaseForm';
import { Form, Formik } from 'formik';
import Page from 'Commons/components/business/page/components/Page';
import Modal from 'Commons/components/generic/modal/components/Modal';
import { TEXT_FIELD_WIDTH } from 'External/containers/configuration/config/Constants';
import TableRowCount from 'Generic/tablerowcount/TableRowCount';
import FiltersAndSortsStyle from './styles/FiltersAndSortsStyle';
import * as Filters from './components/index';
import sortConfig from './config/SortConfig';
import { calculateFilters } from './utils/utils';

class FiltersAndSorts extends BaseForm {
    constructor(props) {
        super(props);
        const {
            getFilters,
            fixedFiltersKeys = [],
            getResetFilter,
            defaultSort: { label: sortLabel } = {},
            prefilledSearchValue = '',
            valuesToIgnoreInCount = {},
        } = props;
        const filters = getFilters();
        this.state = {
            isFilterComponentOpen: false,
            searchButtonOpen: false,
            searchValue: prefilledSearchValue || '',
            sort: sortLabel,
            filterCount: calculateFilters(filters, fixedFiltersKeys, valuesToIgnoreInCount),
            initialValues: filters,
            isSortOpen: false,
        };
        this.filtersRef = React.createRef();
        const { resetFilters } = this;
        if (getResetFilter) getResetFilter(resetFilters);
    }

    resetFilters = () => {
        const {
            fixedFiltersKeys = [],
            defaultFilters = {},
            defaultSort: { label: sortLabel },
            valuesToIgnoreInCount = {},
        } = this.props;
        this.setState({
            searchValue: '',
            sort: sortLabel || 'Recommended',
            filterCount: calculateFilters(defaultFilters, fixedFiltersKeys, valuesToIgnoreInCount),
            initialValues: defaultFilters,
        });
    };

    openFilter = () => {
        const { filtersRef } = this;
        filtersRef.current = null;
        this.setState({ isFilterComponentOpen: true });
    };

    setFilterCount = filterCount => this.setState({ filterCount });

    closeFilter = () => this.setState({ isFilterComponentOpen: false });

    toggleSearchButton = () => this.setState(prevState => ({ searchButtonOpen: !prevState.searchButtonOpen }));

    toggleIsSortOpen = () => this.setState(prevState => ({ isSortOpen: !prevState.isSortOpen }));

    closeSearch = (e) => {
        e.preventDefault();
        const {
            state: { searchValue },
            toggleSearchButton,
        } = this;
        if (!searchValue) {
            toggleSearchButton();
        }
    };

    onClearAll = () => {
        // const { props: { fixedFiltersKeys = [] } } = this;
        const {
            setValues,
            // values,
        } = this.formProps;
        // const clearedValue = clearAllFields(values, fixedFiltersKeys);
        setValues({});
    };

    componentDidMount() {
        const { setClearFilter, setFilterCount: setFilterCountFunc } = this.props;
        const { onClearAll, setFilterCount } = this;
        if (setClearFilter) {
            setClearFilter(onClearAll);
        }
        if (setFilterCountFunc) {
            setFilterCountFunc(setFilterCount);
        }
    }

    componentDidUpdate(_, prevState) {
        const { state: { filterCount }, props: { getTotalCount } } = this;
        const { filterCount: prevFilterCount } = prevState || {};
        if (filterCount !== prevFilterCount && getTotalCount) {
            getTotalCount(filterCount);
        }
    }

    onSearch = (event = {}) => {
        const { props: { onSearchChange } } = this;
        const { target: { value = '' } = {} } = event;
        this.setState({ searchValue: value });
        onSearchChange(event);
    };

    setSort = (selectedSort) => {
        const {
            props: {
                source,
                onSortChange,
            },
            toggleIsSortOpen,
        } = this;
        const sortList = sortConfig[source] || [];
        const selectedSortConfig = sortList.find(({ value }) => value === selectedSort);
        onSortChange(selectedSortConfig.sort);
        toggleIsSortOpen();
    };

    onSort = (_, selectedSort) => {
        this.setState({ sort: selectedSort });
        const { deviceInfo: { isDesktop } } = window;
        if (isDesktop) {
            this.setSort(selectedSort);
        }
    };

    onChangeFilter = (filters) => {
        const {
            props: {
                onApplyFilter,
                fixedFiltersKeys = [],
                valuesToIgnoreInCount = {},
            },
            filtersRef,
            formProps: { resetForm } = {},
        } = this;
        if (filtersRef.current === null) {
            filtersRef.current = filters;
            onApplyFilter(filters);
            this.setState({
                filterCount: calculateFilters(filters, fixedFiltersKeys, valuesToIgnoreInCount),
                isFilterComponentOpen: false,
                initialValues: filters,
            });
            resetForm(filters);
        }
    };

    renderSearchTextField = () => {
        const {
            props: {
                classes,
                hideSearch,
                hideSort,
                placeholder = localisable.search,
                label = '',
            },
            state: { searchValue },
            onSearch,
        } = this;
        const { deviceInfo: { isDesktop } } = window;
        return (
            !hideSearch
            && (
                <TextField
                    value={searchValue}
                    onChange={onSearch}
                    className={
                        clsx(classes.searchTextField,
                            !hideSort && classes.searchBorder,
                            classes.searchIcon,
                            label && classes.searchBottomMargin)}
                    placeholder={placeholder}
                    addon={{
                        start:
                            <Icon
                                icon="cp-search"
                                type="custom"
                                color="primary"
                            />,
                    }}
                    label={label}
                    classes={{ alignment: classes.autoWidth }}
                    trackValue={false}
                    fullWidth
                    inputProps={{ ...(!isDesktop && { autoFocus: true }) }}
                />
            )
        );
    };

    renderSearchIcon = () => {
        const {
            props: {
                classes,
                hideSearch = false,
            },
            toggleSearchButton,
        } = this;
        return !hideSearch && (
            <Icon
                icon="cp-search"
                type="custom"
                className={clsx(classes.searchIcon, classes.border)}
                onClick={toggleSearchButton}
                color="primary"
            />
        );
    };

    renderFilter = () => {
        const {
            state: { filterCount },
            openFilter,
            props: {
                classes,
                showFilterText,
                totalRowsInTable,
                hideFilter,
                showTotalCount,
            },
        } = this;
        const { deviceInfo: { isMobile } = {} } = window;
        return (
            !hideFilter ? (
                <Grid
                    container
                    wrap="nowrap"
                    spacing={isMobile ? 1 : 2}
                    alignItems="center"
                >
                    <Grid item>
                        <Icon type="custom" icon="cp-filter" color="primary" onClick={openFilter} />
                    </Grid>
                    {showFilterText && (
                        <Typography
                            color="primary"
                            className={classes.filterText}
                            onClick={openFilter}
                        >
                            {localisable.filters}
                        </Typography>
                    )}
                    {
                        (filterCount > 0)
                            ? (
                                <Grid item>
                                    <Button
                                        disableElevation
                                        className={classes.filtersAppliedBtn}
                                        onClick={openFilter}
                                    >
                                        {filterCount}
                                        {' '}
                                        {localisable.applied}&ensp;
                                        <TableRowCount
                                            className={classes.noPadding}
                                            totalRows={totalRowsInTable}
                                            color="textPrimary"
                                        />
                                    </Button>
                                </Grid>
                            )
                            : (
                                <TableRowCount
                                    className={classes.noPadding}
                                    totalRows={totalRowsInTable}
                                />
                            )
                    }
                </Grid>
            ) : (
                <>
                    {
                        showTotalCount && (
                            <TableRowCount
                                className={classes.noPadding}
                                totalRows={totalRowsInTable}
                            />
                        )
                    }
                </>
            )
        );
    };

    renderSortDropdown = () => {
        const {
            props: {
                classes,
                source,
                hideSort,
            },
            state: { sort },
            onSort,
        } = this;
        return (
            !hideSort
            && (
                <>
                    <Typography
                        variant="body2"
                        className={classes.sortLabel}
                    >
                        {`${localisable.sortBy}:`}
                    </Typography>
                    <Dropdown
                        list={sortConfig[source]}
                        value={sort}
                        trackValue={false}
                        fullWidth
                        onChange={onSort}
                        InputProps={{ className: classes.sortDropdown }}
                        ListItemProps={{ className: classes.listItem }}
                        width={TEXT_FIELD_WIDTH}
                    />
                </>
            )
        );
    };

    renderSort = () => {
        const {
            props: {
                classes,
                hideSort,
            },
            state: {
                isSortOpen,
                sort,
            },
            renderSortDropdown,
            toggleIsSortOpen,
            setSort,
        } = this;
        const { deviceInfo: { isDesktop } } = window;
        return isDesktop ? renderSortDropdown()
            : !hideSort
            && (
                <>
                    <Icon
                        type="custom"
                        icon="cp-sort"
                        color="primary"
                        onClick={toggleIsSortOpen}
                    />
                    <Modal
                        open={isSortOpen}
                        hideCloseButton
                        classes={{ modalGrid: classes.sortModal }}
                        onClose={toggleIsSortOpen}
                    >
                        <Grid container alignItems="center" className={classes.sortBody}>
                            {renderSortDropdown()}
                            <Grid container justify="flex-end">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setSort(sort)}
                                >
                                    <ButtonText label={localisable.apply} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Modal>
                </>
            );
    };

    renderFilterOverlay = () => {
        const {
            props: {
                classes, source, currentFacility = {}, tableHeight, extraFilterHeight = 0,
                headerRowHeight = TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER,
                negativeButtonProps = {}, isLiveData, additionalFilterProps = {},
            },
            state: { isFilterComponentOpen, initialValues },
            closeFilter, onChangeFilter, onClearAll,
        } = this;
        const Filter = Filters[`${capitalize(source)}Filter`];
        const { data: { id: entityId } = {} } = currentFacility;
        return (
            <Transition
                in={isFilterComponentOpen}
                direction={SLIDE_DIRECTION.RIGHT}
                timeout={300}
            >
                <Grid className={classes.filterComponent}>
                    <Icon
                        type="custom"
                        icon="cp-close"
                        color="primary"
                        onClick={closeFilter}
                        className={classes.closeIcon}
                    />
                    {Filter && (
                        <Formik
                            onSubmit={onChangeFilter}
                            initialValues={initialValues}
                            render={(formProps) => {
                                this.formProps = formProps;
                                return (
                                    <Form>
                                        <Page
                                            footer={this.getFooter({
                                                positiveButtonProps: { label: localisable.apply },
                                                negativeButtonProps: {
                                                    label: localisable.clearAll,
                                                    onClick: onClearAll,
                                                    ...negativeButtonProps,
                                                },
                                                classes: { buttonWidth: classes.buttonWidth },
                                            })}
                                        >
                                            <Grid
                                                container
                                                className={classes.autoWidth}
                                                direction="column"
                                                style={{ maxHeight: tableHeight - headerRowHeight - extraFilterHeight }}
                                                wrap="nowrap"
                                            >
                                                <Filter
                                                    entityId={entityId}
                                                    formProps={formProps}
                                                    isLiveData={isLiveData}
                                                    {...additionalFilterProps}
                                                />
                                            </Grid>
                                        </Page>
                                    </Form>
                                );
                            }
                            }
                        />
                    )
                    }
                </Grid>
            </Transition>
        );
    };

    render() {
        const {
            props: {
                classes,
                hideSearch,
                hideSort,
                renderAddOns,
                containerProps,
                filterContainerProps,
                searchBarJustification,
                filterContainerProps: { className: filterContainerClassName } = {},
                renderFilterEnd,
            },
            state: { searchButtonOpen },
            closeSearch,
            renderFilter,
            renderFilterOverlay,
            renderSearchIcon,
            renderSort,
            renderSearchTextField,
        } = this;
        const { deviceInfo: { isDesktop } } = window;
        return (
            <Grid
                container
                className={clsx(classes.body, hideSearch && hideSort && classes.bottomPadding)}
                justify="space-between"
                {...containerProps}
            >
                <Grid
                    container
                    justify={searchBarJustification}
                    className={clsx(classes.filterContainer, filterContainerClassName)}
                    {...filterContainerProps}
                >
                    <Grid container className={classes.autoWidth}>
                        {renderFilter()}
                        {renderFilterOverlay()}
                    </Grid>
                    <Grid container className={classes.autoWidth} alignItems="center">
                        {!isDesktop && !searchButtonOpen && renderSearchIcon()}
                        {isDesktop && renderSearchTextField()}
                        {renderSort()}
                        {isDesktop && renderFilterEnd()}
                    </Grid>
                </Grid>
                {renderAddOns()}
                {!isDesktop && searchButtonOpen
                    && (
                        <Grid container justify="center">
                            <ClickAwayListener
                                mouseEvent={searchButtonOpen && 'onClick'}
                                onClickAway={closeSearch}
                            >
                                {renderSearchTextField()}
                            </ClickAwayListener>
                        </Grid>
                    )
                }
            </Grid>
        );
    }
}

FiltersAndSorts.propTypes = {
    source: PropTypes.string,
    currentFacility: PropTypes.object,
    onAction: PropTypes.func,
    getFilters: PropTypes.func,
    onApplyFilter: PropTypes.func,
    onSearchChange: PropTypes.func,
    onSortChange: PropTypes.func,
    hideSearch: PropTypes.bool,
    defaultSort: PropTypes.object,
    hideSort: PropTypes.bool,
    renderAddOns: PropTypes.func,
    renderFilterEnd: PropTypes.func,
    showTotalCount: PropTypes.bool,
    showFilterText: PropTypes.bool,
    containerProps: PropTypes.object,
    negativeButtonProps: PropTypes.object,
    filterContainerProps: PropTypes.object,
    additionalFilterProps: PropTypes.object,
    searchBarJustification: PropTypes.string,
};

FiltersAndSorts.defaultProps = {
    hideSearch: false,
    hideFilter: false,
    showFilterText: false,
    showTotalCount: true,
    hideSort: false,
    containerProps: {},
    negativeButtonProps: {},
    renderAddOns: EMPTY_FUNC,
    renderFilterEnd: EMPTY_FUNC,
    filterContainerProps: {},
    additionalFilterProps: {},
    searchBarJustification: 'space-between',
};

export default withStyles(FiltersAndSortsStyle)(FiltersAndSorts);
