import { DASHBOARD_TYPE } from 'Commons/config/constants/Constants';
import urls from 'Commons/config/constants/Urls';
import INTERNAL_ROUTES from 'Internal/redux/config/RouteNames';
import { isPulledData } from 'Commons/helpers/utils/Utils';

const getSandboxIdIfExistsInPath = () => {
    const { location: { pathname } } = window;
    if (isPulledData(pathname)) {
        const pathnameWithSandboxId = pathname.match(/\/p(?:\/s)?\/(\d+)\/\d+\//);
        if (pathnameWithSandboxId) return pathnameWithSandboxId[1];
    }
    return '';
};

const getBaseUrl = (dashboardType, accountId) => {
    const sandboxId = getSandboxIdIfExistsInPath();
    switch (dashboardType) {
        case DASHBOARD_TYPE.SETTINGS:
            if (accountId) return sandboxId ? `/p/s/${sandboxId}/${accountId}` : `/l/s/${accountId}`;
            return '/a/s';
        case DASHBOARD_TYPE.INTERNAL: return `/${INTERNAL_ROUTES.INTERNAL_MAIN}`;
        case DASHBOARD_TYPE.EXTERNAL:
        default:
            if (accountId) return sandboxId ? `/p/${sandboxId}/${accountId}/facility` : `/l/${accountId}/facility`;
            return '/a/facility';
    }
};

const buildUrl = (name, options, dashboardType, accountId) => {
    const baseUrl = getBaseUrl(dashboardType, accountId);
    return urls[name](baseUrl, options);
};

export default buildUrl;
export { getBaseUrl };
