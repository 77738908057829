const confirmationAlertStyles = theme => ({
    noBtn: {
        marginRight: theme.spacing(2),
        backgroundColor: theme.hexWithOpacity(theme.palette.error.main, 1),
        '&:hover': {
            color: theme.palette.error.main,
            backgroundColor: theme.hexWithOpacity(theme.palette.error.main, 3),
        },
    },
    invoiceAlert: { maxWidth: theme.spacing(67) },
});

export default confirmationAlertStyles;
