import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { memo } from 'react';
import { RadioGroup } from 'Generic/radiogroup/components/RadioGroup';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { BATCH_PAYMENT_FILTER_TYPE } from '../config/Constants';
import CreateCCBatchStyle from '../styles/createCCBatchStyle';

const useStyles = makeStyles(CreateCCBatchStyle, { name: 'CreateCCBatchFilter' });


const CreateCCBatchFilter = ({ formProps: { setFieldValue } = {} }) => {
    const onChange = (_, value) => {
        if (value === BATCH_PAYMENT_FILTER_TYPE.Autopay_Enabled_Tenants.value) {
            setFieldValue('terms.autoPayEnabled', [true]);
            setFieldValue('exists.token', undefined);
        } else if (value === BATCH_PAYMENT_FILTER_TYPE.Saved_Cards_With_Balance_Due.value) {
            setFieldValue('exists.token', [true]);
            setFieldValue('terms.autoPayEnabled', undefined);
        }
    };

    const filterRadioList = useConstructor(() => Object.values(BATCH_PAYMENT_FILTER_TYPE));

    const classes = useStyles();


    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.paymentPreference}
            </Typography>
            <RadioGroup
                list={filterRadioList}
                dataType="string"
                value={BATCH_PAYMENT_FILTER_TYPE.Saved_Cards_With_Balance_Due.value}
                onChange={onChange}
            />
        </>
    );
};

CreateCCBatchFilter.propTypes = { formProps: PropTypes.object };

export default memo(CreateCCBatchFilter);
