import STATUS from '../../config/constants/StoreKeyStatus';
import REQUEST_TYPES from '../../config/constants/RequestTypes';


/**
 * Concatenate the data for pagination calls
 * @param {*} reduxData The Store data currently
 * @param {*} payloadData The payload data
 */
const concatData = ({ data: reduxData = [] } = {}, { data: payloadData = [], ...rest } = {}, isDataReversed) => {
    const data = isDataReversed ? payloadData.concat(reduxData) : reduxData.concat(payloadData);
    return { data, ...rest };
};

const getLoadingStatus = (status, requestType) => {
    switch (requestType) {
        case REQUEST_TYPES.PAGINATE:
        case REQUEST_TYPES.UPDATE:
        case REQUEST_TYPES.REFRESH:
            return (status >= STATUS.LOADED)
                ? STATUS.UPDATING
                : STATUS.LOADING;
        default:
            return STATUS.LOADING;
    }
};

const processPayload = (payload = {}, status, data, requestType = REQUEST_TYPES.INIT, isDataReversed, api) => {
    let localPayload = Object.assign({}, payload);
    if (localPayload) {
        const { data: payloadData = {}, status: payloadStatus = STATUS.UNLOADED } = localPayload;
        const { body: { filter, sort } = {} } = api;
        localPayload = { ...localPayload, filter, sort };
        if (payloadStatus === STATUS.INVALID) {
            localPayload.data = {};
            localPayload.status = payloadStatus;
            return localPayload;
        } if (payloadStatus === STATUS.ERROR || payloadStatus === STATUS.PAGINATE_ERROR || payloadStatus === STATUS.TOKEN_EXPIRED) {
            localPayload.data = data;
            localPayload.status = payloadStatus;
            return localPayload;
        }
        switch (requestType) {
        // TODO: Handle UPDATE case
        // TODO: Handle Delete Flow

            case REQUEST_TYPES.INIT:
            case REQUEST_TYPES.REFRESH:
                localPayload.data = payloadData;
                localPayload.status = STATUS.LOADED;
                break;
            case REQUEST_TYPES.PAGINATE:
                localPayload.data = concatData(data, payloadData, isDataReversed);
                localPayload.status = status > STATUS.LOADED ? STATUS.UPDATED : STATUS.LOADED;
                break;
            case REQUEST_TYPES.UPDATE:
                localPayload.data = data; // Retain state data
                localPayload.status = status;
                break;
            default:
                localPayload.data = data;
                localPayload.status = status;
        }
    }
    return localPayload;
};


const processStorePayload = (requestType = REQUEST_TYPES.INIT, state, status, data, payload, keys, isDataReversed,
    api) => (
    (Array.isArray(keys) && keys.length) ? keys.reduce((processedData, key) => {
        const { data: keyData = {}, status: keyStatus = STATUS.UNLOADED } = state[key] || {};
        const singlePayload = payload[key];
        return {
            ...processedData,
            [key]: processPayload(singlePayload, keyStatus, keyData, requestType, isDataReversed, api),
        };
    }, {}) : processPayload(payload, status, data, requestType, isDataReversed, api));


const getLoadingData = (requestType, state, keys) => (
    keys.reduce((newState, key) => {
        const { data = {}, status = STATUS.UNLOADED } = state[key] || {};
        return (status > STATUS.INVALID ? {
            ...newState,
            [key]: {
                data,
                status: getLoadingStatus(status, requestType),
            },
        } : newState);
    }, state));

const reducer = (
    state = {},
    action,
) => {
    const {
        type = '',
        storeKey = '',
        payload,
        requestType = REQUEST_TYPES.INIT,
        keys = [],
        isDataReversed,
        api = {},
    } = action;
    const [, method] = type.split('_');
    const actionKey = storeKey || '';
    const { data = {}, status = STATUS.UNLOADED } = state[storeKey] || {};
    const newState = {};

    const processedPayload = processStorePayload(requestType, state, status, data, payload, keys, isDataReversed, api);

    switch (type) {
        case 'permission_update':
        case 'currentFacility_update': {
            const { userProfile, facility, permissionRoles } = state;
            return {
                userProfile,
                facility,
                permissionRoles,
                [storeKey]: processedPayload,
            };
        }
        case 'timezone_read': {
            return state;
        }
        case `${actionKey}_clear`:
            return {
                ...state,
                [storeKey]: {},
            };
        case 'multikeys_clear':
            keys.forEach((key) => {
                newState[key] = {};
            });
            return {
                ...state,
                ...newState,
            };
        case 'config_read_loading':
            return getLoadingData(requestType, state, keys);
        case 'facility_read':
        case 'config_read':
            return {
                ...state,
                ...processedPayload,
            };

        case `${actionKey}_${method}`:
            return {
                ...state,
                [storeKey]: processedPayload,
            };
        case `${actionKey}_${method}_loading`:
            return getLoadingData(requestType, state, [storeKey]);
        case 'clear_store':
            return {};
        default:
            return state;
    }
};

export default reducer;
