const deleteOverlayStyle = theme => ({
    // Deletion Overlay
    overlayMessage: { height: '100%' },
    overlayMessageCancel: {
        color: theme.palette.common.red,
        cursor: 'pointer',
    },
    overlayMessageCancelStart: {
        color: theme.palette.common.red,
        padding: '0px 16px',
        cursor: 'pointer',
    },
    overlayMessageOk: {
        color: theme.palette.common.black,
        padding: '0px 16px',
        cursor: 'pointer',
    },
    deletionOverlay: {
        ...theme.globalClasses.deactivatedOverlay.style,
        position: 'absolute',
        backgroundColor: `${theme.palette.common.white}D9`,
        opacity: 1,
    },

});

export default deleteOverlayStyle;
