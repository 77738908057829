import {
    withStyles, List, ListItem,
    Typography,
} from 'Commons/components/generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Icon from 'Commons/components/generic/icon/components/Icon';
import autoSuggestStyle from '../styles/AutoSuggestStyle';
import TenantLeadCard from '../../cards/tenantlead/components/TenantLeadCard';
import OccupancyCard from '../../cards/occupancy/components/OccupancyCard';
import UnitCard from '../../cards/unit/components/UnitCard';
import Popdown from '../../../generic/popdown/components/Popdown';
import ThreeDotProgress from '../../../generic/threedotprogress/components/ThreeDotProgress';
import { TIMEOUT, VIEW, AUTO_SUGGEST_RESOURCE } from '../../../../config/constants/Constants';
import { deepMerge } from '../../../../helpers/utils/DeepCopy';
import CARD_TYPE from '../config/Constants';

class AutoSuggest extends React.PureComponent {
    constructor(props) {
        super(props);
        const { initialValue } = props;
        this.state = {
            open: false,
            value: initialValue,
        };
    }

    componentDidMount() {
        const { onRef } = this.props;
        if (onRef) {
            onRef(this);
        }
    }

    componentWillUnmount() {
        const { onRef } = this.props;
        if (onRef) {
            onRef(undefined);
        }
    }

    onClear = () => {
        const { clearAutosuggest } = this.props;
        const value = '';
        this.prevInputValue = value;
        this.setState({ cards: undefined, open: false, value });
        clearAutosuggest();
    }

    onChange = (event) => {
        const { value: inputValue } = event.target;
        let cards = this.renderCard(<ThreeDotProgress />);
        if (inputValue && inputValue !== this.prevInputValue) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.fetchData(inputValue);
            }, TIMEOUT);
        } else {
            if (this.timeout) clearTimeout(this.timeout);
            cards = undefined;
        }
        this.prevInputValue = inputValue;
        this.setState({ cards, open: true, error: undefined, value: inputValue });
    };

    fetchData = (userInput) => {
        const { onAction, onActionConfig, resource } = this.props;
        let endPoint = 'autosuggest';
        let view = VIEW.detail.value;
        if (resource === AUTO_SUGGEST_RESOURCE.OCCUPANCY) endPoint += '/occupancy';
        if (resource === AUTO_SUGGEST_RESOURCE.MOVE_OUT) endPoint += '/move-out';
        if (resource === AUTO_SUGGEST_RESOURCE.GLOBAL) {
            view = VIEW.card.value;
        }
        if (resource === AUTO_SUGGEST_RESOURCE.TENANT_LEAD) {
            endPoint += '/customer';
            view = VIEW.card.value;
        }
        const defaultConfig = {
            config: [{
                method: 'read',
                api: {
                    dynamic: {
                        methodType: 'POST',
                        endPoint,
                        body: {
                            view,
                            filter: [{ autoSuggest: userInput }],
                            size: 5,
                        },
                        customHeaderOptions: {
                            type: 'store',
                            key: 'facility',
                        },
                    },
                },
                callback: this.onFetchData,
            }],
        };
        const config = deepMerge(defaultConfig, onActionConfig);
        onAction(config);
    }

    onFetchData = (apiError, response) => {
        if (apiError) {
            const err = <Typography color="error">{localisable.somethingWentWrong}</Typography>;
            this.setState({ error: err });
        } else if (response) {
            this.processData(response);
        }
    }

    processData = (response) => {
        let { data = {} } = response;
        const { state: { value } = {} } = this;
        const { relationalData = {}, totalCount: total = 0 } = response;
        const {
            currentFacility, classes, resource, allowNavigation, onSelectCard,
            highlightInputTextInCard, currentAccountId,
        } = this.props;
        let cards = [];


        if (resource === AUTO_SUGGEST_RESOURCE.UNIT) {
            data = { unit: { data, totalCount: total } };
        }

        if (resource === AUTO_SUGGEST_RESOURCE.TENANT) {
            data = { tenant: { data, totalCount: total } };
        }

        if (resource === AUTO_SUGGEST_RESOURCE.OCCUPANCY) {
            const {
                reservation: { data: reservationData = [] } = {},
                estimate: { data: estimateData = [] } = {},
            } = data;
            data = { occupancy: { data: reservationData.concat(estimateData), totalCount: total } };
        }

        if (total) {
            Object.keys(data).forEach((type) => {
                const { [type]: { data: cardData = [], totalCount = 0 } = {} } = data;
                if (totalCount) {
                    cardData.forEach(({
                        name, email, address, address: { line1 } = {}, balance, comments, unitList, primaryContact,
                        otherAddress: { alternateContact } = {}, type: tenantLeadType, id,
                        phone: { primaryNumber: phone = '' } = {},
                        label, ledger, reservationCode, tenant, estimateCode, customer,
                        reservationDate, expirationDate, unitTypeId, unitId,
                    }, index) => {
                        if (resource) {
                            if (resource === AUTO_SUGGEST_RESOURCE.OCCUPANCY) {
                                cards.push(
                                    this.renderCard(
                                        <OccupancyCard
                                            align="left"
                                            onClick={() => this.selectCard(cardData[index], type, currentFacility, relationalData)}
                                            id={id}
                                            reservationCode={reservationCode}
                                            estimateCode={estimateCode}
                                            comments={comments}
                                            cardType={type}
                                            customer={customer || tenant}
                                            reservationDate={reservationDate}
                                            expirationDate={expirationDate}
                                            relationalData={relationalData}
                                            unitTypeId={unitTypeId}
                                            unitId={unitId}
                                            CardProps={{ classes: { cardContent: classes.cardContent } }}
                                            currentAccountId={currentAccountId}
                                        />,
                                        `${type}${id}`,
                                    ),
                                );
                            } else if (type === CARD_TYPE.UNIT) {
                                cards.push(
                                    this.renderCard(
                                        <UnitCard
                                            align="left"
                                            onSelectCard={onSelectCard}
                                            onClickAway={this.onClickAway}
                                            onClick={() => this.selectCard(cardData[index], type, currentFacility)}
                                            id={id}
                                            currentFacility={currentFacility}
                                            resource={resource}
                                            name={label}
                                            currentAccountId={currentAccountId}
                                        />,
                                        `${type}${id}`,
                                    ),
                                );
                            } else if (resource === AUTO_SUGGEST_RESOURCE.TENANT_LEAD) {
                                cards.push(
                                    this.renderCard(
                                        <TenantLeadCard
                                            id={id}
                                            name={name}
                                            email={email}
                                            phone={`${phone}`}
                                            cardType={type}
                                            address={{ line1 }}
                                            currentFacility={currentFacility}
                                            resource={resource}
                                            className={classes.autoSuggestCard}
                                            onSelectCard={onSelectCard}
                                            onClickAway={this.onClickAway}
                                            onClick={() => this.selectCard(cardData[index], type, currentFacility)}
                                            CardProps={{ classes: { cardContent: classes.cardContent } }}
                                            currentAccountId={currentAccountId}
                                            {...highlightInputTextInCard && { autoSuggestText: value }}
                                        />,
                                        `${type}${id}`,
                                    ),
                                );
                            } else {
                                cards.push(
                                    this.renderCard(
                                        <TenantLeadCard
                                            allowNavigation={allowNavigation}
                                            id={id}
                                            name={name}
                                            email={email}
                                            phone={`${phone}`}
                                            cardType={type}
                                            units={unitList}
                                            label={label}
                                            ledgers={ledger}
                                            address={address}
                                            balance={balance}
                                            comments={comments}
                                            type={tenantLeadType}
                                            primaryContact={primaryContact}
                                            currentFacility={currentFacility}
                                            alternateContact={alternateContact}
                                            resource={resource}
                                            className={classes.autoSuggestCard}
                                            onSelectCard={onSelectCard}
                                            onClickAway={this.onClickAway}
                                            onClick={() => this.selectCard(cardData[index], type, currentFacility)}
                                            currentAccountId={currentAccountId}
                                            {...highlightInputTextInCard && { autoSuggestText: value }}
                                        />,
                                        `${type}${id}`,
                                    ),

                                );
                            }
                        } else {
                            cards.push(
                                this.renderCard(
                                    <Typography
                                        align="left"
                                        className={classes.unitListpadding}
                                        onClick={() => this.selectCard(cardData[index])}
                                        variant="body2"
                                    >
                                        {label}
                                    </Typography>,
                                    index,
                                ),
                            );
                        }
                    });
                }
            });
        } else {
            cards = this.renderCard(
                <Typography>
                    {localisable.noResultFound}
                </Typography>,
            );
        }

        this.setState({ cards });
    }

    selectCard = (data, type, facility, relationalData) => {
        const { onSelectCard, labelRenderer } = this.props;
        const dataToReturn = { ...data };
        if (type && facility) {
            dataToReturn.cardType = type;
            dataToReturn.currentFacility = facility;
        }
        if (relationalData) {
            dataToReturn.relationalData = relationalData;
        }
        if (onSelectCard) {
            this.onClickAway();
            onSelectCard(dataToReturn);
        }
        if (labelRenderer) {
            this.setState({ value: labelRenderer(data) });
        }
    }

    onClick = () => {
        this.setState(prevState => (!prevState.open ? { open: true } : undefined));
    }

    onClickAway = () => {
        this.setState({ open: false });
    }

    getAddOn = () => {
        const { clearable, PopdownProps: { addon } = {}, classes, endIconProps = {} } = this.props;
        const { value } = this.state;
        const defaultAddon = addon || { start: <Icon type="custom" icon="cp-search-e2e" size="small" className={classes.searchIcon} /> };
        return clearable && value
            ? {
                ...defaultAddon,
                end:
                    <Icon
                        type="custom"
                        icon="cp-close"
                        size="medium"
                        onClick={this.onClear}
                        {...endIconProps}
                    />,
            }
            : defaultAddon;
    }

    renderCard = (card, key) => {
        const { classes } = this.props;
        return (
            <ListItem key={key} className={classes.listItem}>
                {card}
            </ListItem>
        );
    }

    render() {
        const { classes, className, PopdownProps, textFieldClasses } = this.props;
        const { cards, error, value, open } = this.state;
        return (
            <Popdown
                placeholder={localisable.search}
                addon={this.getAddOn()}
                onChange={this.onChange}
                onClick={this.onClick}
                onClickAway={this.onClickAway}
                open={open}
                value={value}
                className={className}
                TextFieldClasses={textFieldClasses}
                PaperProps={{ className: classes.paper }}
                trackValue={false}
                {...PopdownProps}
                fullWidth
            >
                <List
                    className={classes.autoSuggest}
                >
                    {(error && this.renderCard(error)) || cards}
                </List>
            </Popdown>

        );
    }
}

AutoSuggest.propTypes = {
    currentFacility: PropTypes.object,
    onAction: PropTypes.func,
    onActionConfig: PropTypes.object,
    classes: PropTypes.object,
    className: PropTypes.string,
    PopdownProps: PropTypes.object,
    inputProps: PropTypes.string,
    textFieldClasses: PropTypes.object,
    resource: PropTypes.number,
    onSelectCard: PropTypes.func,
    labelRenderer: PropTypes.func,
    onRef: PropTypes.func,
    allowNavigation: PropTypes.bool,
    clearable: PropTypes.bool,
    clearAutosuggest: PropTypes.func,
    initialValue: PropTypes.string,
    endIconProps: PropTypes.object,
    highlightInputTextInCard: PropTypes.bool,
    currentAccountId: PropTypes.object,
};

AutoSuggest.defaultProps = {
    className: '',
    onAction: () => {},
    onActionConfig: {},
    currentFacility: {},
    PopdownProps: {},
    textFieldClasses: {},
    endIconProps: {},
    resource: AUTO_SUGGEST_RESOURCE.TENANT_LEAD,
    allowNavigation: false,
    clearable: false,
    initialValue: '',
    clearAutosuggest: () => {},
    highlightInputTextInCard: false,
};

export default (withStyles(autoSuggestStyle)(AutoSuggest));

const SmartAutoSuggest = window.withProvider('AutoSuggest');

export { SmartAutoSuggest as AutoSuggest };
