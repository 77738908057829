/* This piece of code has been taken form https://github.com/clauderic/react-sortable-hoc */

import * as React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { provideDisplayName, omit } from '../utils';

const propTypes = {
    index: PropTypes.number.isRequired,
    collection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
};

const omittedProps = Object.keys(propTypes);

export default function SortableElement(
    WrappedComponent,
    config = { withRef: false },
) {
    return class WithSortableElement extends React.Component {
    static displayName = provideDisplayName(
        'sortableElement',
        WrappedComponent,
    );

    static contextTypes = { manager: PropTypes.object.isRequired };

    static propTypes = propTypes;

    static defaultProps = { collection: 0 };

    componentDidMount() {
        this.register();
    }

    componentDidUpdate(prevProps) {
        const { collection, disabled, index, node } = this;
        if (node) {
            if (prevProps.index !== index) {
                node.sortableInfo.index = index;
            }

            if (prevProps.disabled !== disabled) {
                node.sortableInfo.disabled = disabled;
            }
        }

        if (prevProps.collection !== collection) {
            this.unregister(prevProps.collection);
            this.register();
        }
    }

    componentWillUnmount() {
        this.unregister();
    }

    register = () => {
        const { props: { collection, disabled, index }, context: { manager } } = this;
        // eslint-disable-next-line react/no-find-dom-node
        const node = findDOMNode(this);

        node.sortableInfo = {
            collection,
            disabled,
            index,
            manager,
        };

        this.node = node;
        this.ref = { node };

        manager.add(collection, this.ref);
    };

    // eslint-disable-next-line react/destructuring-assignment
    unregister = (collection = this.props.collection) => {
        const { context: { manager } } = this;
        manager.remove(collection, this.ref);
    };

    render() {
        const { props } = this;
        const ref = config.withRef ? 'wrappedInstance' : null;

        return <WrappedComponent ref={ref} {...omit(props, omittedProps)} />;
    }
    };
}
