import { createEnumMap } from 'Commons/helpers/utils/Formatter';

export const EVENT = createEnumMap(['onClick', 'onHover'],
    key => key.replace(/([A-Z])/g, match => `_${match}`).toUpperCase());

export const DISABLED_LISTENERS = {
    disableFocusListener: true,
    disableHoverListener: true,
    disableTouchListener: true,
};

export const DEFAULT_TRANSITION = { timeout: { enter: 0, exit: 0 } };
