import media from 'Commons/theme/MediaQueries';

const iconStyle = theme => ({
    '@global @font-face': {
        fontFamily: "'cp-icons'",
        src: [`url('${process.env.ASSETS_BUCKET_LOCATION}/cp-assets/cp-icons.woff?37i377') format('woff')`, /* Pretty Modern Browsers */
            `url('${process.env.ASSETS_BUCKET_LOCATION}/cp-assets/cp-icons.ttf?37i377') format('ttf')`, /* Safari, Android, iOS */
            `url('${process.env.ASSETS_BUCKET_LOCATION}/cp-assets/cp-icons.svg?37i377') format('svg')`], /* Legacy iOS */

        fontWeight: 'normal',
        fontStyle: 'normal',
        fontDisplay: 'swap',
    },
    icon: {
        cursor: 'pointer',
        '&$primaryicon': { color: theme.palette.primary.main },
        '&$secondaryicon': { color: theme.palette.secondary.main },
        '&$erroricon': { color: theme.palette.error.main },
        '&$disabled': {
            cursor: 'default',
            pointerEvents: 'none',
            color: theme.palette.disabled,
        },
    },
    noCursorPointer: { cursor: 'default' },
    primaryicon: {},
    secondaryicon: {},
    erroricon: {},
    disabled: {},
    fullWidth: { width: '100%' },
    'inline-block': { display: 'inline-block' },
    block: { display: 'block' },
    cpIcon: {
        /* use !important to prevent issues with browser extensions that change fonts */
        fontFamily: "'cp-icons' !important",
        speak: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: 'normal',
        color: 'currentColor',

        /* Better Font Rendering =========== */
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
    },
    small: { fontSize: '1rem' },
    medium: { fontSize: '1.5rem' },
    default: {
        fontSize: '1.5rem',
        [media.desktopUp]: { fontSize: '2rem' },
    },
    large: { fontSize: '2rem' },
    'cp-view': { '&:before': { content: '"\\E900"' } },
    'cp-move': { '&:before': { content: '"\\E901"' } },
    'cp-delete': { '&:before': { content: '"\\E902"' } },
    'cp-maintenance-events': { '&:before': { content: '"\\E903"' } },
    'cp-individual': { '&:before': { content: '"\\E904"' } },
    'cp-signout': { '&:before': { content: '"\\E905"' } },
    'cp-settings': { '&:before': { content: '"\\E906"' } },
    'cp-search': { '&:before': { content: '"\\E907"' } },
    'cp-save': { '&:before': { content: '"\\E908"' } },
    'cp-reservation': { '&:before': { content: '"\\E909"' } },
    'cp-close-event': { '&:before': { content: '"\\E90A"' } },
    'cp-move-out': { '&:before': { content: '"\\E90B"' } },
    'cp-move-in': { '&:before': { content: '"\\E90C"' } },
    'cp-menu': { '&:before': { content: '"\\E90D"' } },
    'cp-lead': { '&:before': { content: '"\\E90F"' } },
    'cp-info': { '&:before': { content: '"\\E910"' } },
    'cp-sort': { '&:before': { content: '"\\E911"' } },
    'cp-deposit-log': { '&:before': { content: '"\\E912"' } },
    'cp-filter': { '&:before': { content: '"\\E913"' } },
    'cp-facility': { '&:before': { content: '"\\E914"' } },
    'cp-edit': { '&:before': { content: '"\\E915"' } },
    'cp-pending-tasks': { '&:before': { content: '"\\E916"' } },
    'cp-payment': { '&:before': { content: '"\\E917"' } },
    'cp-close': { '&:before': { content: '"\\E918"' } },
    'cp-collapse': { '&:before': { content: '"\\E919"' } },
    'cp-chevron-right': { '&:before': { content: '"\\E91A"' } },
    'cp-chevron-left': { '&:before': { content: '"\\E91B"' } },
    'cp-expand': { '&:before': { content: '"\\E91C"' } },
    'cp-delinquent-tenant': { '&:before': { content: '"\\E91D"' } },
    'cp-lock-gate': { '&:before': { content: '"\\E91E"' } },
    'cp-calendar': { '&:before': { content: '"\\E91F"' } },
    'cp-calculator': { '&:before': { content: '"\\E920"' } },
    'cp-business': { '&:before': { content: '"\\E921"' } },
    'cp-backspace': { '&:before': { content: '"\\E922"' } },
    'cp-unlock': { '&:before': { content: '"\\E923"' } },
    'cp-add': { '&:before': { content: '"\\E924"' } },
    'cp-lock': { '&:before': { content: '"\\E925"' } },
    'cp-transfer': { '&:before': { content: '"\\E926"' } },
    'cp-warning': { '&:before': { content: '"\\E927"' } },
    'cp-close-round': { '&:before': { content: '"\\E928"' } },
    'cp-history': { '&:before': { content: '"\\E929"' } },
    'cp-ledger': { '&:before': { content: '"\\E92A"' } },
    'cp-dollar': { '&:before': { content: '"\\E92B"' } },
    'cp-check-simple': { '&:before': { content: '"\\E92C"' } },
    'cp-balance': { '&:before': { content: '"\\E92D"' } },
    'cp-comment': { '&:before': { content: '"\\E92E"' } },
    'cp-reset': { '&:before': { content: '"\\E92F"' } },
    'cp-unit': { '&:before': { content: '"\\E930"' } },
    'cp-copy': { '&:before': { content: '"\\E931"' } },
    'cp-2FA': { '&:before': { content: '"\\E932"' } },
    'cp-preferred': { '&:before': { content: '"\\E933"' } },
    'cp-asterisk': { '&:before': { content: '"\\E934"' } },
    'cp-waive-on': { '&:before': { content: '"\\E93F"' } },
    'cp-waive-off': { '&:before': { content: '"\\E940"' } },
    'cp-star-outlined': { '&:before': { content: '"\\E935"' } },
    'cp-star-filled': { '&:before': { content: '"\\E936"' } },
    'cp-arrow-down': { '&:before': { content: '"\\E937"' } },
    'cp-email-opened': { '&:before': { content: '"\\E938"' } },
    'cp-text-outlined': { '&:before': { content: '"\\E939"' } },
    'cp-bar-code': { '&:before': { content: '"\\E93A"' } },
    'cp-email-outlined': { '&:before': { content: '"\\E93B"' } },
    'cp-download': { '&:before': { content: '"\\E93C"' } },
    'cp-plus': { '&:before': { content: '"\\E93D"' } },
    'cp-radio-off': { '&:before': { content: '"\\E93E"' } },
    'cp-fold': { '&:before': { content: '"\\E941"' } },
    'cp-unfold': { '&:before': { content: '"\\E942"' } },
    'cp-check-box-outlined': { '&:before': { content: '"\\E943"' } },
    'cp-check-box': { '&:before': { content: '"\\E944"' } },
    'cp-search-e2e': { '&:before': { content: '"\\E945"' } },
    'cp-indeterminate-check-box': { '&:before': { content: '"\\E946"' } },
    'cp-radio-on': { '&:before': { content: '"\\E947"' } },
    'cp-rotate-90': { '&:before': { content: '"\\E948"' } },
    'cp-align-vertical': { '&:before': { content: '"\\E949"' } },
    'cp-align-horizontal': { '&:before': { content: '"\\E94A"' } },
    'cp-align-bottom': { '&:before': { content: '"\\E94B"' } },
    'cp-align-top': { '&:before': { content: '"\\E94C"' } },
    'cp-align-right': { '&:before': { content: '"\\E94D"' } },
    'cp-align-left': { '&:before': { content: '"\\E94E"' } },
    'cp-hide': { '&:before': { content: '"\\E94F"' } },
    'cp-retailsale': { '&:before': { content: '"\\E950"' } },
    'cp-vendor': { '&:before': { content: '"\\E951"' } },
    'cp-restock': { '&:before': { content: '"\\E952"' } },
    'cp-inventory': { '&:before': { content: '"\\E953"' } },
    'cp-pos': { '&:before': { content: '"\\E954"' } },
    'cp-audit': { '&:before': { content: '"\\E955"' } },
    'cp-attachment': { '&:before': { content: '"\\E956"' } },
    'cp-back': { '&:before': { content: '"\\E957"' } },
    'cp-bold': { '&:before': { content: '"\\E958"' } },
    'cp-bullet-list': { '&:before': { content: '"\\E959"' } },
    'cp-text-align-center': { '&:before': { content: '"\\E95A"' } },
    'cp-content-save': { '&:before': { content: '"\\E95B"' } },
    'cp-equal-spacing': { '&:before': { content: '"\\E95C"' } },
    'cp-refund': { '&:before': { content: '"\\E95D"' } },
    'cp-italic': { '&:before': { content: '"\\E95E"' } },
    'cp-text-align-left': { '&:before': { content: '"\\E95F"' } },
    'cp-numbered-list': { '&:before': { content: '"\\E960"' } },
    'cp-text-align-right': { '&:before': { content: '"\\E961"' } },
    'cp-table': { '&:before': { content: '"\\E962"' } },
    'cp-report': { '&:before': { content: '"\\E963"' } },
    'cp-underline': { '&:before': { content: '"\\E964"' } },
    'cp-image': { '&:before': { content: '"\\E965"' } },
    'cp-activate': { '&:before': { content: '"\\E966"' } },
    'cp-deactivate': { '&:before': { content: '"\\E967"' } },
    'cp-error': { '&:before': { content: '"\\E968"' } },
    'cp-play': { '&:before': { content: '"\\E969"' } },
    'cp-info-colored': { '&:before': { content: '"\\E96A"' } },
    'cp-location': { '&:before': { content: '"\\E96B"' } },
    'cp-ach': { '&:before': { content: '"\\E96C"' } },
    'cp-phone': { '&:before': { content: '"\\E96D"' } },
    'cp-query': { '&:before': { content: '"\\E96E"' } },
    'cp-refresh': { '&:before': { content: '"\\E96F"' } },
    'cp-view-tenant-placeholder': { '&:before': { content: '"\\E970"' } },
    'cp-document': { '&:before': { content: '"\\E971"' } },
    'cp-promo-plan': { '&:before': { content: '"\\E972"' } },
    'cp-unit-type': { '&:before': { content: '"\\E973"' } },
    'cp-moveout-success': { '&:before': { content: '"\\E974"' } },
    'cp-rent-assessment': { '&:before': { content: '"\\E975"' } },
    'cp-fax': { '&:before': { content: '"\\E976"' } },
    'cp-print': { '&:before': { content: '"\\E977"' } },
    'cp-return': { '&:before': { content: '"\\E978"' } },
    'cp-deliver-report': { '&:before': { content: '"\\E979"' } },
    'cp-clock': { '&:before': { content: '"\\E97A"' } },
    'cp-tenant-add': { '&:before': { content: '"\\E97B"' } },
    'cp-tenant-search': { '&:before': { content: '"\\E97C"' } },
    'cp-manual-charge': { '&:before': { content: '"\\E97D"' } },
    'cp-circled-up': { '&:before': { content: '"\\E97E"' } },
    'cp-circled-down': { '&:before': { content: '"\\E97F"' } },
    'cp-vertical-ellipses': { '&:before': { content: '"\\E980"' } },
    'cp-checkbox-marked-circle-outline': { '&:before': { content: '"\\E981"' } },
    'cp-success': { '&:before': { content: '"\\E982"' } },
    'cp-retail-sale-pay': { '&:before': { content: '"\\E983"' } },
    'cp-mark-duplicate': { '&:before': { content: '"\\E984"' } },
    'cp-line-spacing': { '&:before': { content: '"\\E985"' } },
    'cp-operations': { '&:before': { content: '"\\E986"' } },
    'cp-recurring-charges': { '&:before': { content: '"\\E987"' } },
    'cp-split': { '&:before': { content: '"\\E988"' } },
    'cp-collapse-section': { '&:before': { content: '"\\E98A"' } },
    'cp-expand-section': { '&:before': { content: '"\\E98B"' } },
    'cp-admin-util': { '&:before': { content: '"\\E98C"' } },
    'cp-gate': { '&:before': { content: '"\\E98D"' } },
    'cp-business-group': { '&:before': { content: '"\\E98E"' } },
    'cp-customer-accounts': { '&:before': { content: '"\\E98F"' } },
    'cp-letter-migration': { '&:before': { content: '"\\E989"' } },
    'cp-all-accounts': { '&:before': { content: '"\\E990"' } },
    'cp-pending': { '&:before': { content: '"\\E90E"' } },
    'cp-rate-history': { '&:before': { content: '"\\E991"' } },
    'cp-pause': { '&:before': { content: '"\\E992"' } },
    'cp-arrow-expand': { '&:before': { content: '"\\E993"' } },
    'cp-arrow-collapse': { '&:before': { content: '"\\E994"' } },
    'cp-insert-link': { '&:before': { content: '"\\E995"' } },
    'cp-rent-adjustment-grid': { '&:before': { content: '"\\E996"' } },
    'cp-rent-adjustment': { '&:before': { content: '"\\E9997"' } },
    'cp-migration-check': { '&:before': { content: '"\\E998"' } },
    'cp-migration': { '&:before': { content: '"\\E999"' } },
    'cp-insurance': { '&:before': { content: '"\\E99A"' } },
    'cp-redirect': { '&:before': { content: '"\\E99B"' } },
    'cp-reject-request': { '&:before': { content: '"\\E99C"' } },
    'cp-local-drive': { '&:before': { content: '"\\E99D"' } },
    'cp-crash-photo': { '&:before': { content: '"\\E99E"' } },
    'cp-primary-photo': { '&:before': { content: '"\\E99F"' } },
    'cp-webcam': { '&:before': { content: '"\\E9A1"' } },
    'cp-home': { '&:before': { content: '"\\E9A4"' } },
    'cp-late-events': { '&:before': { content: '"\\E9A5"' } },
    'cp-printer-warning': { '&:before': { content: '"\\E9A6"' } },
    'cp-repair': { '&:before': { content: '"\\E9A7"' } },
    'cp-validate': { '&:before': { content: '"\\E9A9"' } },
    'cp-select-printer': { '&:before': { content: '"\\E9AB"' } },
    'cp-dashboard': { '&:before': { content: '"\\E9AC"' } },
    'cp-percentage': { '&:before': { content: '"\\E9AE"' } },
    'cp-unit-late-event': { '&:before': { content: '"\\E9AF"' } },
    'cp-unit-maintenance-event': { '&:before': { content: '"\\E9B0"' } },
    'cp-vehicle': { '&:before': { content: '"\\E9B1"' } },
    'cp-devices': { '&:before': { content: '"\\E9B2"' } },
    'cp-growth': { '&:before': { content: '"\\E9B3"' } },
    'cp-printer-available': { '&:before': { content: '"\\E9B4"' } },
    'cp-printer-unavailable': { '&:before': { content: '"\\E9B8"' } },
    'cp-import': { '&:before': { content: '"\\E9BD"' } },
    'cp-help': { '&:before': { content: '"\\E9BE"' } },
    'cp-reply': { '&:before': { content: '"\\E9AD"' } },
    'cp-website': { '&:before': { content: '"\\E9C0"' } },
    'cp-bev-walk-around': { '&:before': { content: '"\\E9C1"' } },
    'cp-bulk-deposit': { '&:before': { content: '"\\E9C3"' } },
});

export default iconStyle;
