const AllAchBatchesFilterStyle = theme => ({
    autoWidth: { width: 'auto' },
    dateRangeLabel: { padding: theme.spacing(2, 0) },
    clearDate: {
        alignSelf: 'flex-end',
        paddingTop: theme.spacing(1),
    },
});

export default AllAchBatchesFilterStyle;
