const searchableTreeViewStyle = theme => ({
    treeActions: { marginTop: theme.spacing(2) },
    treeView: {
        marginTop: theme.spacing(3),
        overflow: 'auto',
    },
    fullHeight: {
        flex: '1 1 0px',
        overflow: 'auto',
    },
    root: { flex: 1 },
    button: { '&, &:hover': { background: 'transparent' } },
    selectAll: { marginLeft: theme.spacing(2) },
    clearButton: {},
});

export default searchableTreeViewStyle;
