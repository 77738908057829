import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker as MuipKeyboardDatePicker, DatePicker as MuipDatePicker }
from '@material-ui/pickers';
import { Grid, withStyles, RootRef } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import { SEARCH_DATE_FORMAT } from 'Commons/config/constants/Constants';
import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import Form from '../../form/components/Form';
import { TextField } from '../../textfield/components/TextField';
import { dateFormatter } from '../../../../helpers/utils/DateTime';
import datePickerStyle from '../styles/DatePickerStyle';

class DatePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { open: false };
        const { trackValue, value } = props;
        if (trackValue) {
            this.state = { ...this.state, value };
        }
        this.anchorEl = React.createRef();
    }

    componentDidMount = () => {
        this.componentMounted = true;
    }

    formatDate = (date) => {
        const { dataType } = this.props;
        return dataType === 'date' ? dateFormatter(date, SEARCH_DATE_FORMAT) : dateFormatter(date, null);
    }

    onChange = (date) => {
        const { onChange, trackValue } = this.props;
        if (trackValue) {
            this.setState({ value: date });
        }
        const value = this.formatDate(date) || date;
        this.setFieldTouchedOnChanged();
        onChange(date, value);
    }

    setFieldTouchedOnChanged = () => {
        const { name, form: { setFieldTouched } = {} } = this.props;
        if (setFieldTouched) {
            setFieldTouched(name, true);
        }
    }

    setOpen = open => () => {
        this.setState({ open });
    }

    render() {
        const {
            classes, trackValue, value, keyboard, format, onChange, dataType, PopoverProps, name,
            disabled, additionalIcon: { left, right, isClickable } = {},
            textFieldClasses, readOnly, openPickerOnInputFieldFocus, ...other
        } = this.props;
        const { value: stateValue, open } = this.state;
        const { componentMounted } = this;
        const { deviceInfo: { isDesktop } } = window;
        const MuiDatePicker = keyboard ? MuipKeyboardDatePicker : MuipDatePicker;
        const popoverProps = isDesktop ? {
            PopoverProps: {
                ...PopoverProps,
                ...(componentMounted && { anchorEl: this.anchorEl.current }),
            },
        } : {};
        return (
            <Grid container alignItems="center" wrap="nowrap" className={classes.container}>
                {left
                    && (
                        <div
                            role="presentation"
                            className={classes.rightMarginIcon}
                            onClick={isClickable ? this.setOpen(true) : () => { }}
                        >
                            {left}
                        </div>
                    )
                }
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <RootRef rootRef={this.anchorEl}>
                        <Grid container className={classes.datePickerContainer}>
                            <MuiDatePicker
                                name={name}
                                format={format}
                                value={trackValue ? stateValue : value}
                                onChange={this.onChange}
                                TextFieldComponent={TextField}
                                trackValue={false}
                                allowKeyboardControl
                                disabled={disabled}
                                helperText={null}
                                {...(openPickerOnInputFieldFocus && { onClick: this.setOpen(true) })}
                                addon={{
                                    end: !readOnly
                                        && (
                                            <Button
                                                onClick={this.setOpen(true)}
                                                variant="icon"
                                                icon="cp-calendar"
                                                iconType="custom"
                                                color="primary"
                                                className={classes.datePickerIcon}
                                                disabled={disabled}
                                            />
                                        ),
                                }}
                                onClose={this.setOpen(false)}
                                open={open}
                                autoOk
                                classes={textFieldClasses}
                                readOnly={readOnly}
                                {...popoverProps}
                                {...other}
                            />
                        </Grid>
                    </RootRef>
                </MuiPickersUtilsProvider>
                {
                    right
                    && (
                        <div
                            role="presentation"
                            className={classes.leftMarginIcon}
                            onClick={isClickable ? this.setOpen(true) : () => { }}
                        >
                            {right}
                        </div>
                    )
                }
            </Grid>
        );
    }
}

DatePicker.propTypes = {
    onChange: PropTypes.func,
    form: PropTypes.object,
    trackValue: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
    ]),
    keyboard: PropTypes.bool,
    variant: PropTypes.string,
    format: PropTypes.string,
    dataType: PropTypes.oneOf(['date', 'dateTime']),
    classes: PropTypes.object,
    name: PropTypes.string,
    PopoverProps: PropTypes.object,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    additionalIcon: PropTypes.shape({
        left: PropTypes.node,
        right: PropTypes.node,
        isClickable: PropTypes.bool,
    }),
    textFieldClasses: PropTypes.object,
    readOnly: PropTypes.bool,
    openPickerOnInputFieldFocus: PropTypes.bool,
};

DatePicker.defaultProps = {
    onChange: () => { },
    trackValue: true,
    value: null,
    variant: window.deviceInfo.isDesktop ? 'inline' : 'dialog',
    format: 'MM-DD-YYYY',
    dataType: 'date',
    keyboard: true,
    disabled: false,
    readOnly: false,
    openPickerOnInputFieldFocus: false,
};

const StyledDatePicker = withStyles(datePickerStyle)(DatePicker);
StyledDatePicker.displayName = FORM_COMPONENTS.DATE_PICKER;
const DatePickerForm = Form(StyledDatePicker, {}, { error: true });
// eslint-disable-next-line react/no-multi-comp
const FormDatePicker = React.forwardRef(({ disablePast, disableFuture, validate, ...props }, ref) => (
    <DatePickerForm
        disablePast={disablePast}
        disableFuture={disableFuture}
        validate={{
            isDate: true,
            ...(disablePast && { isPastDate: true }),
            ...(disableFuture && { isFutureDate: true }),
            ...validate,
        }}
        {...props}
        ref={ref}
    />
));

FormDatePicker.propTypes = { disablePast: PropTypes.bool, validate: PropTypes.object, disableFuture: PropTypes.bool };

export {
    StyledDatePicker as DatePicker,
    FormDatePicker,
};
