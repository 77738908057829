/* eslint-disable prefer-spread */
function memoize(fn, resolver) {
    const cache = new Map();
    return (...args) => {
        // if we have a resolver defined, use that, otherwise, default to the first arg
        const key = resolver ? resolver.apply(null, args) : args[0];
        if (cache.has(key)) {
            return cache.get(key);
        }
        const result = fn.apply(null, args);
        cache.set(key, result);
        return result;
    };
}

export { memoize };
