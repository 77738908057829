/* eslint-disable no-plusplus */
import md5 from './md5';

const array = new Uint32Array(10);

const generateRandomNumber = () => Math.random() * 10000;
/* const testUUID = () => {
    const ids = {};
    let duplicates = 0;
    for (let index = 0; index < 10000; index += 1) {
        const uuid = getUUID();
        ids[uuid] = ids[uuid] ? ids[uuid] + 1 : 1;
        if (ids[uuid] > 1) {
            duplicates += 1;
        }
    }
    console.log('No of dupes:', duplicates);
    console.log('IDs:', JSON.stringify(ids));
}; */

const getSeed = () => {
    if (window.crypto) {
        window.crypto.getRandomValues(array);
        const randomIndex = Math.floor(Math.random() * 10);
        return array[randomIndex];
    }
    return `${generateRandomNumber()}`;
};

const getUUID = () => `${md5(`${Date.valueOf()}-${getSeed()}`)}`;
export default getUUID;
