import { FormTextField } from 'Generic/textfield/components/TextField';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { SOURCE } from 'Commons/config/constants/Constants';
import { IS_MAX_LENGTH_20 } from 'Commons/config/constants/Validators';
import paymentMethodStyle from '../styles/PaymentMethodStyle';

const useStyles = makeStyles(paymentMethodStyle);

const MoneyOrder = ({ disabled, source }) => {
    const classes = useStyles();
    return (
        <Grid container justify="center" className={classes.paymentField}>
            <FormTextField
                helperText={localisable.moneyOrderNumber}
                name="moneyOrderNumber"
                placeholder={localisable.checkPlaceHolder}
                disabled={disabled}
                FormControlProps={{ fullWidth: false }}
                validate={IS_MAX_LENGTH_20}
                noGrid
                noIndicatorWrapper
                FormHelperTextProps={{ classes: { root: classes.helperText } }}
                className={classes.numberFields}
                type="number"
                {...source === SOURCE.payment.value
                    && {
                        name: 'paymentNumber',
                        noIndicatorWrapper: false,
                        required: true,
                    }
                }
            />
        </Grid>
    );
};

MoneyOrder.propTypes = { disabled: PropTypes.bool, source: PropTypes.string };

export default MoneyOrder;
