import { VIEW } from 'Commons/config/constants/Constants';
import { CONFIG_ENDPOINTS } from 'Commons/config/constants/Endpoints';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import { ENTITY_TYPE } from 'External/containers/configuration/config/Constants';

const callApi = (onAction, endPoint, body, callback = () => { }, method = 'POST', storeMethod = 'read', customHeaderOptions) => {
    if (onAction) {
        onAction({
            config: [{
                api: {
                    method: storeMethod,
                    action: {
                        methodType: `${method}`,
                        endPoint,
                        ...(body && { body }),
                        ...customHeaderOptions && { customHeaderOptions },
                    },
                },
                callback,
            }],
        });
    }
};

const fetchConfigs = (onAction, callback, configTypes = [], entityId,
    entityType = ENTITY_TYPE.FACILITY, size = 200) => {
    const body = {
        size,
        view: VIEW.detail.value,
        filter: [{
            terms: {
                configType: configTypes,
                entityType: [entityType],
                ...(entityId && { entityId: [entityId] }),
            },
        }],
    };
    callApi(onAction, CONFIG_ENDPOINTS.search, body, callback);
};

const fetchLetter = (onAction, entityId, callback) => {
    fetchConfigs(onAction, callback, [CONFIG_TYPE.LETTER, CONFIG_TYPE.FACILITY_GENERAL], entityId);
};

const fetchStandaloneApp = (onAction, entityId, callback) => {
    const body = {
        view: VIEW.detail.value,
        filter: [{ terms: { status: ['Active'] } }],
        size: 200,
    };
    const customHeaderOptions = entityId ? {
        type: 'default',
        context: { id: entityId, type: 'Facility' },
    } : {
        type: 'store',
        key: 'facility',
    };
    callApi(onAction, 'standalone-app/search', body, callback, 'POST', 'read', customHeaderOptions);
};

const fetchLeaseStyle = (onAction, entityId, callback) => {
    fetchConfigs(onAction, callback, [CONFIG_TYPE.LEASE_STYLE], entityId);
};

export { fetchLetter, fetchStandaloneApp, fetchLeaseStyle };
