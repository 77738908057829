import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { memo } from 'react';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { FormCheckboxGroup } from 'Commons/components/generic/checkboxgroup/components/CheckBoxGroup';
import { RENT_ADJUSTMENT_STATUS_LIST } from 'External/containers/operations/components/rentAdjustment/config/Constants';
import RentAdjustmentFilterStyle from '../styles/RentAdjustmentFilterStyle';

const useStyles = makeStyles(RentAdjustmentFilterStyle, { name: 'RentAdjustmentFilterStyle' });


const RentAdjustmentFilter = () => {
    const rentAdjustmentStatusList = useConstructor(() => Object.values(RENT_ADJUSTMENT_STATUS_LIST));

    const classes = useStyles();


    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={rentAdjustmentStatusList}
            />
        </>
    );
};

export default memo(RentAdjustmentFilter);
