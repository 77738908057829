import { makeStyles, Grid, Button } from 'Commons/components/generic/componentlibrary/components/Components';
import { useState, memo } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import Icon from 'Commons/components/generic/icon/components/Icon';
import { TextField } from 'Commons/components/generic/textfield/components/TextField';
import baseUserRoleStyle from 'Commons/components/business/baseUserRole/styles/BaseUserRoleStyle';

const useStyles = makeStyles(baseUserRoleStyle, { name: 'UserRoleSideBar' });

const UserRoleSideBar = ({
    selectedRoleKey, copyRoleName, onSearch, onRoleSelection,
    userRoles, clearSearch, isSourceInternalDashboard,
} = {}) => {
    const classes = useStyles();
    const [isSearchbarOpened, openSearchbar] = useState(false);
    return (
        <Grid
            container
            direction="column"
            item
            xs={12}
            lg={2}
            className={`${classes.sideBar} ${classes.sideBarContainer}`}
        >
            <Grid container direction="row" className={classes.sideBarIcons}>
                {!isSourceInternalDashboard && (
                    <Icon
                        icon="cp-copy"
                        type="custom"
                        display="block"
                        onClick={copyRoleName}
                    />
                )}
                {
                    isSearchbarOpened
                        ? (
                            <TextField
                                className={classes.searchBar}
                                name="roleName"
                                placeholder="search"
                                onChange={(event) => {
                                    onSearch(event.target.value);
                                }}
                                addon={{
                                    end: <Icon
                                        icon="cp-close"
                                        type="custom"
                                        display="block"
                                        onClick={() => {
                                            openSearchbar(false);
                                            clearSearch();
                                        }}
                                    />,
                                }}
                            />
                        )
                        : (
                            <Icon
                                icon="cp-search"
                                type="custom"
                                display="block"
                                onClick={() => { openSearchbar(true); }}
                            />
                        )
                }
            </Grid>
            {Object.keys(userRoles).map((roleIdOrUserType) => {
                const { [roleIdOrUserType]: { name, userTypeLabel } = {} } = userRoles;
                return (
                    <Button
                        key={roleIdOrUserType}
                        className={clsx(classes.buttons, { [classes.selected]: selectedRoleKey === roleIdOrUserType })}
                        onClick={() => onRoleSelection(roleIdOrUserType)}
                    >{name || userTypeLabel}
                    </Button>
                );
            })}
        </Grid>
    );
};

export default memo(UserRoleSideBar);
