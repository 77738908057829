import media from 'Commons/theme/MediaQueries';

const basicInformationStyle = theme => ({
    block: { padding: theme.spacing(1, 4) },
    title: {
        minHeight: 48,
        maxHeight: 48,
    },
    checkbox: { marginTop: theme.spacing(2) },
    modalGrid: {
        [media.tabletUp]: { width: '529px' },
        [media.phablet]: { width: '95vw' },
        borderRadius: '4px',
        padding: '16px',
        gap: '16px',
        minHeight: 'fit-content',
    },
    modalHeader: { display: 'flex', alignItems: 'center', minHeight: '30px' },
    closeButton: { minHeight: '30px' },
    headerTitle: { fontFamily: 'Open Sans', fontSize: '20px', lineHeight: '30px', fontWeight: '600' },
    bodyEnclosure: { display: 'grid', gap: theme.spacing(1), marginBottom: '16px' },
    bodyText: { fontSize: '16px', lineHeight: '20px' },
    customerSupportBold: { fontWeight: 'bold' },
    customerSupportEnclosure: { display: 'grid', gap: theme.spacing(1) },
    customerSupport: { fontSize: '16px', lineHeight: '20px' },
    footerEnclosure: { display: 'flex', justifyContent: 'flex-end', gap: '16px' },
    buttonProps: { minHeight: '33px', borderRadius: '8px', padding: '6px 8px' },
    buttonText: { fontSize: '14px', lineHeight: '21px', fontWeight: '400' },
});

export default basicInformationStyle;
