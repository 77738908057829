/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import {
    Grid,
    makeStyles,
    TreeItem,
    TreeView as MuiTreeView,
    Typography,
} from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import { flattenObject } from 'Commons/helpers/utils/DataHelpers';
import useDidUpdateEffect from 'Commons/helpers/hooks/useDidUpdateEffect';
import localisable from 'Commons/config/strings/localisable';
import { VARIANT } from 'Commons/components/generic/form/config/FormComponentsConfig';
import treeViewStyles from '../styles/TreeViewStyle';
import { Checkbox, FormCheckbox } from '../../checkbox/components/CheckBox';
import { NODE_STATE } from '../config/Constants';


const useStyles = makeStyles(treeViewStyles, { name: 'FormTreeView' });
let flattenInitialValues = {};

const getNodeState = (totalChildren, childrenChecked) => {
    if (totalChildren === childrenChecked) {
        return NODE_STATE.CHECKED;
    }
    if (childrenChecked === 0) {
        return NODE_STATE.UNCHECKED;
    }
    return NODE_STATE.INTERMEDIATE;
};

const getMyParentId = nodeId => nodeId.substring(0, nodeId.lastIndexOf('.'));

/**
 * @description Accumulate the state of a parent/child node
 * @param {string} name Form name of node, null in case of parent node
 * @param {string} nodeId Id of the node
 * @param {object} initialValues Form's initial values
 */
const getNodesInitialState = ({
    name,
    label,
    expanded = true,
    children = [],
    searchField,
    shallOmit,
    ...others
}, nodeId, initialValues) => {
    let currentNodeState = {};
    const totalChildren = children.length;
    const effectiveChildren = children.reduce((accumulator, childrenItem) => {
        let count = accumulator;
        if (!childrenItem.shallOmit) {
            count += 1;
        }
        return count;
    }, 0);
    const config = { label, nodeId, name, shallOmit, ...others };

    if (children.length) { // For parent node, initialize the children state first
        const {
            config: childConfig,
            treeState: childState,
            childrenExpanded,
            defaultExpanded,
            // eslint-disable-next-line no-use-before-define
        } = initializeState(children, initialValues, nodeId);

        currentNodeState = {
            ...childState,
            [nodeId]: {
                state: getNodeState(effectiveChildren, childrenExpanded),
                totalChildren,
                effectiveChildren,
                childrenExpanded,
                ...name && { name },
            },
            config: {
                ...config,
                children: childConfig,
            },
            expanded,
            defaultExpanded,
        };
    } else {
        const hasValue = initialValues[name];
        currentNodeState = {
            [nodeId]: {
                state: hasValue ? NODE_STATE.CHECKED : NODE_STATE.UNCHECKED,
                name,
            },
            hasValue,
            config: {
                ...config,
                name,
            },
            shallOmit,
        };
    }
    return currentNodeState;
};

/**
 * @description It calculates the total number of childrenExpanded for a node
 * @param {number} nodeId Parent node for which we are calculating total childrenExpanded
 * @param {bool} hasValue If the child node has already a value
 * @param {object} currentNodeState Current state of node
 * @param {object} accumulator Reducer Accumulator
 */
const getChildrenExpanded = (nodeId, hasValue, currentNodeState, accumulator) => {
    let childrenExpanded = hasValue ? accumulator.childrenExpanded + 1 : accumulator.childrenExpanded;
    const { [nodeId]: { totalChildren, state } = {} } = currentNodeState;

    if (totalChildren) {
        if (state === NODE_STATE.CHECKED) {
            childrenExpanded += 1;
        } else if (state === NODE_STATE.UNCHECKED) {
            childrenExpanded += 0;
        } else {
            childrenExpanded += 0.5;
        }
    }

    return childrenExpanded;
};

/**
 * @description Initializes the state for TreeView along with creating nodeIds and finding the expanded nodes
 * @param {array} initialConfig Master and children config to be processed
 * @param {object} initialValues Form's Initial Values
 * @param {string} parentId Node's parentId, null in case of root parents
 */
const initializeState = (
    initialConfig,
    initialValues,
    parentId,
) => initialConfig.reduce((accumulator, treeItem, index) => {
    const nodeId = `${parentId ? `${parentId}.` : ''}${index}`;
    const {
        config,
        expanded,
        hasValue,
        defaultExpanded = [],
        ...currentNodeState
    } = getNodesInitialState(treeItem, nodeId, initialValues);

    const childrenExpanded = getChildrenExpanded(nodeId, hasValue, currentNodeState, accumulator);

    const { isVisible = true, ...restConfig } = config;
    if (!isVisible) return accumulator;

    // Creating config with generated Ids
    const { config: finalConfig } = accumulator;
    finalConfig[index] = restConfig;

    return {
        treeState: {
            ...accumulator.treeState,
            ...currentNodeState,
        },
        config: finalConfig,
        childrenExpanded,
        defaultExpanded: [...accumulator.defaultExpanded, ...defaultExpanded, ...(expanded ? [nodeId] : [])],
    };
}, { childrenExpanded: 0, config: [], defaultExpanded: [] });

const TreeView = ({
    config: masterConfig, TreeItemProps, CheckboxProps, formProps,
    formProps: {
        initialValues = {},
        values = {},
        setFieldValue = () => {},
        setFieldTouched = () => {},
    },
    ...props
}) => {
    const classes = useStyles();

    useEffect(() => { // To ensure updated initialValues gets flatten if enableReinitialize is true for Formik
        flattenInitialValues = flattenObject(values || initialValues);
    }, [initialValues, values]);

    const [nodeState, setNodeState] = useState(() => {
        flattenInitialValues = flattenObject(values || initialValues);
        return initializeState(masterConfig, flattenInitialValues);
    });
    const { treeState, config, defaultExpanded } = nodeState;
    const [expanded, setExpanded] = useState(defaultExpanded);

    useDidUpdateEffect(() => {
        // Execute following only when config change, but not on component mount
        const newState = initializeState(masterConfig, flattenObject(values));
        setNodeState(newState);
    }, [masterConfig]);

    const modifyChildrenCount = (nodeId, childState) => {
        const parentId = getMyParentId(nodeId);
        if (parentId && treeState[nodeId].state !== childState) {
            if (treeState[nodeId].state === NODE_STATE.INTERMEDIATE) {
                treeState[parentId].childrenExpanded += childState === NODE_STATE.CHECKED ? 0.5 : -0.5;
            } else if (treeState[nodeId].state === NODE_STATE.CHECKED) {
                treeState[parentId].childrenExpanded += childState === NODE_STATE.UNCHECKED ? -1 : -0.5;
            } else if (treeState[nodeId].state === NODE_STATE.UNCHECKED) {
                treeState[parentId].childrenExpanded += childState === NODE_STATE.CHECKED ? 1 : 0.5;
            }
        }
    };

    const markParentsChecked = (nodeId) => {
        const parentId = getMyParentId(nodeId);
        if (parentId) {
            const { [parentId]: { childrenExpanded, name: nodeName, effectiveChildren } } = treeState;
            if (effectiveChildren) {
                const state = getNodeState(effectiveChildren, childrenExpanded);

                if (treeState[parentId].state !== state) {
                    if (nodeName && !!treeState[parentId].state !== !!state) {
                        /* modifies only if parent has nodeName and state goes from unchecked to
                        checked/partial-checked (0 to 1/2) or vice versa (1/2 to 0) */
                        setFieldValue(nodeName, !!state);
                        setFieldTouched(nodeName, true, false);
                    }
                    modifyChildrenCount(parentId, state);
                    treeState[parentId].state = state;
                }
                markParentsChecked(parentId);
            }
        }
    };

    const markChildrenChecked = (checked, nodeId, parentId, nodeName) => {
        if (treeState[nodeId]) {
            const state = checked ? NODE_STATE.CHECKED : NODE_STATE.UNCHECKED;
            if (parentId && state !== treeState[nodeId].state) {
                if (treeState[nodeId].state === NODE_STATE.INTERMEDIATE) {
                    treeState[parentId].childrenExpanded = checked
                        ? treeState[parentId].childrenExpanded + 0.5 : treeState[parentId].childrenExpanded - 0.5;
                } else {
                    treeState[parentId].childrenExpanded = checked
                        ? treeState[parentId].childrenExpanded + 1 : treeState[parentId].childrenExpanded - 1;
                }
            }
            treeState[nodeId].state = state;

            const { [nodeId]: { totalChildren, shallOmit } } = treeState;
            if (nodeName) {
                setFieldValue(nodeName, checked);
                setFieldTouched(nodeName, true, false);
            }

            for (let child = 0; child < totalChildren; child += 1) {
                if (!shallOmit) {
                    const childNodeId = `${nodeId}.${child}`;
                    const { [childNodeId]: { name } } = treeState;
                    if (name) {
                        setFieldValue(name, checked);
                        setFieldTouched(name, true, false);
                    }
                    markChildrenChecked(checked, childNodeId, nodeId);
                }
            }
        }
    };

    const onChange = (checked, nodeId, name) => {
        const parentId = getMyParentId(nodeId);
        markChildrenChecked(checked, nodeId, parentId, name);
        markParentsChecked(nodeId);
        setNodeState({ ...nodeState });
    };

    // eslint-disable-next-line react/prop-types
    const processTreeItem = ({ children, nodeId, label, name, ...others }) => {
        const hasChildren = children && children.length;
        const { [nodeId]: { state } = {} } = treeState;
        const Component = hasChildren ? Checkbox : FormCheckbox;
        const checkboxClass = { formLabel: classes.formLabel };
        const ComponentProps = hasChildren ? {
            classes: checkboxClass,
            checked: !!(hasChildren && state === NODE_STATE.CHECKED),
        } : { name, noGrid: true, componentClasses: checkboxClass };

        return (
            <TreeItem
                classes={{
                    root: classes.root,
                    content: classes.content,
                }}
                key={nodeId}
                nodeId={nodeId}
                label={(
                    <Grid container alignItems="center">
                        <div role="presentation" onClick={e => e.stopPropagation()}>
                            <Component
                                label={label}
                                onChange={(e, checked) => {
                                    e.stopPropagation();
                                    onChange(checked, nodeId, name);
                                }}
                                trackValue={false}
                                indeterminate={!!(state === NODE_STATE.INTERMEDIATE)}
                                classes={hasChildren ? {} : { indicatorWrapper: classes.indicatorWrapper }}
                                checkboxClasses={{ root: classes.checkboxRoot, checked: classes.checked }}
                                {...ComponentProps}
                                {...CheckboxProps}
                                {...others}
                            />
                        </div>
                        {
                            hasChildren
                                ? (
                                    <Button
                                        variant="icon"
                                        iconType="custom"
                                        className={classes.toggleIcon}
                                        icon={expanded.includes(nodeId) ? 'cp-expand' : 'cp-chevron-right'}
                                    />
                                ) : null
                        }
                    </Grid>
                )}
                {...TreeItemProps}
            >
                {/* eslint-disable-next-line no-use-before-define */}
                {children ? renderNodeList(children, nodeId) : null}
            </TreeItem>
        );
    };

    // eslint-disable-next-line react/prop-types
    const renderCheckbox = ({ children, nodeId, label, name, subTitle, shallOmit, isHeadingOnly, header, parent, headLabel, permissionLevel, ...others }) => {
        const hasChildren = children && children.length;
        const { [nodeId]: { state } = {} } = treeState;
        const Component = hasChildren ? Checkbox : FormCheckbox;
        const checkboxClass = { formLabel: classes.formLabel };
        const ComponentProps = hasChildren ? {
            classes: checkboxClass,
            checked: !!(hasChildren && state === NODE_STATE.CHECKED),
        } : {
            name,
            noGrid: false,
            componentClasses: checkboxClass,
            formVariant: VARIANT.NO_PADDING,
            noIndicatorWrapper: true,
        };
        const FormControlProps = hasChildren ? {} : { FormControlProps: { fullWidth: false } };
        const { disabled: disabledFromChild = false, ...restOthers } = others;
        const { disabled: disabledFromParent = false, restCheckboxProps } = CheckboxProps;
        const isCheckboxDisabled = disabledFromParent || disabledFromChild; // Prioritizing parent disabled value
        return (
            <Grid item xs={!parent ? 2 : 4} className={`${parent ? classes.parent : classes.gridCheckBoxChildren} ${shallOmit ? classes.noCheckbox : ''}`}>
                {/* eslint-disable-next-line no-mixed-operators */}
                {label && !parent && !name
                    ? ((shallOmit && !isHeadingOnly) ? '_' : null)
                    : (
                        <Component
                            onChange={(e, checked) => {
                                onChange(checked, nodeId, name);
                            }}
                            trackValue={false}
                            indeterminate={!!(state === NODE_STATE.INTERMEDIATE)}
                            checkboxClasses={{ root: classes.checkboxRoot, checked: classes.checked }}
                            disabled={isCheckboxDisabled}
                            {...ComponentProps}
                            {...restCheckboxProps}
                            {...restOthers}
                            {...FormControlProps}
                        />
                    )}
            </Grid>
        );
    };

    const renderTreeNodes = (textItem, parentAndChildList) => {
        const { nodeId, label, subTitle, header, parent } = textItem;

        return (
            <React.Fragment key={nodeId}>
                {
                    !parent && (
                        <Grid container className={(!label || !subTitle) ? classes.gridContainer : classes.gridPadding}>
                            {(
                                <Grid item xs={12} className={classes.gridStyle}>
                                    <Typography
                                        variant="subtitle1"
                                        className={header ? classes.fontClass : classes.childClass}
                                    >
                                        {label}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12} className={classes.innerBox}>
                                {(
                                    <Grid item xs={6}>
                                        {subTitle && subTitle.length ? subTitle.map(({ subLabel }, idx) => (
                                            <Typography
                                                key={idx}
                                                className={header ? classes.sublabel : classes.sublabelChild}
                                            >
                                                {subLabel}
                                            </Typography>
                                        ))
                                            : null
                                        }
                                    </Grid>
                                )}
                                {parentAndChildList.length
                                    ? parentAndChildList.map(item => (item.label === textItem.label ? renderCheckbox(item) : null))
                                    : renderCheckbox(textItem)
                                }
                            </Grid>
                        </Grid>
                    )
                }
            </React.Fragment>
        );
    };

    const renderNodeList = itemList => itemList.map(treeItem => processTreeItem(treeItem));

    const renderUserRole = (itemList) => {
        const totalItemList = [];
        itemList.forEach((item) => {
            totalItemList.push(item);
            if (item.parent) {
                item.children.forEach((childItem) => {
                    totalItemList.push(childItem);
                });
            }
        });
        const checkboxRenderList = itemList.length > 1 ? totalItemList : [];
        const textRenderItemList = totalItemList.filter(treeItem => treeItem.nodeId.split('.')[0] === '0');
        return textRenderItemList.map(treeItem => renderTreeNodes(treeItem, checkboxRenderList));
    };

    const { className, children, isSourceUserRole, ...others } = props;

    const renderPermissionLevels = () => (
        <div className={classes.mainContainer}>
            <div className={classes.headerWrapper}>
                <Grid container className={classes.permissionHeader}>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle1"
                            className={classes.selectAllPadding}
                        >
                            {localisable.setPermissionAt}
                        </Typography>
                    </Grid>
                    {config && config.length
                        ? config.map(({ permissionLevel: [{ label = '' }] = [] }, idx) => (
                            <Grid item xs={2} className={classes.gridCheckBox}>
                                <Typography
                                    key={idx}
                                    variant="subtitle1"
                                    className={classes.permissionStyle}
                                >
                                    {label}
                                </Typography>
                            </Grid>
                        ))
                        : null}
                </Grid>
                <Grid container className={classes.selectionHeader}>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle1"
                            className={classes.selectAllPadding}
                        >
                            {localisable.selectAll}
                        </Typography>
                    </Grid>
                    {config && config.length
                        ? config.map(configItem => (
                            <Grid item xs={2} className={classes.parentCheckBox}>
                                {renderCheckbox(configItem)}
                            </Grid>
                        ))
                        : null}
                </Grid>
            </div>
            <Grid className={classes.permissionsBody}>{config ? renderUserRole(config) : null}</Grid>
        </div>
    );
    return (
        isSourceUserRole ? (
            renderPermissionLevels()
        )
            : (config
                ? (
                    <MuiTreeView
                        className={className}
                        onNodeToggle={(_, nodeIds) => setExpanded(nodeIds)}
                        defaultExpanded={expanded}
                        {...others}
                    >
                        {children || renderNodeList(config)}
                    </MuiTreeView>
                )
                : null)
    );
};

TreeView.propTypes = {
    config: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.node,
        name: PropTypes.string,
        children: PropTypes.array,
    })),
    children: PropTypes.node,
    className: PropTypes.string,
    TreeItemProps: PropTypes.object,
    CheckboxProps: PropTypes.object,
    formProps: PropTypes.object,
    isSourceUserRole: PropTypes.bool,
};

TreeView.defaultProps = {
    CheckboxProps: {},
    formProps: {},
    config: [],
};

export default TreeView;
