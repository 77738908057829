import { Grid, ScrollSync } from 'react-virtualized';
import { EMPTY_FIELD_TEXT, STATUS, TABLE_ROW_HEIGHT_VARIANT, TABLE_STATE } from 'Commons/config/constants/Constants';
import { clsx } from 'Commons/helpers/utils/clsx';
import { Grid as MuiGrid, withStyles } from 'Generic/componentlibrary/components/Components';
import { capitalize } from 'Commons/helpers/utils/DataHelpers';
import TableRowCount from 'Generic/tablerowcount/TableRowCount';
import theme from 'Commons/theme/Theme';
import Typography from 'Generic/typography/components/Typography';
import { screenMaxWidth } from 'Commons/config/constants/ScreenWidths';
import { isBetween } from 'Commons/helpers/utils/Utils';
import { ALIGN, ERROR, OVERSCAN_COLUMN_COUNT, OVERSCAN_ROW_COUNT, PERCENTAGE, PIXEL, VALIGN } from './Constants';
import HeaderOrFooterCell from './HeaderOrFooterCell';
import { EmptyOrLoadingTable, StatusIndicator, TableLoading, TableNoMoreRows } from './Renderers';
import { dataConverter, getGridHeightValues, getHeaderVariantMapping } from './Utils';
import tableStyle from '../styles/TableStyle';

const { innerWidth } = window;
class GenericTable extends React.Component {
    constructor(props, context) {
        super(props, context);
        const { leftAndRightPadding } = props;
        this.leftAndRightPadding = 2 * leftAndRightPadding;
        this.lastPaginationStart = 0;
        const { deviceInfo: { isDesktop, isTablet } } = window;
        this.state = {
            horizontalScrollSize: 0,
            numOfRows: props.data.length,
            convertedTableRows: [],
            verticalScrollSize: 0,
            showLoader: false,
            noMoreRows: false,
        };
        this.headerRef = {};
        this.tableContainerRef = React.createRef();
        this.bodyRef = React.createRef();
        this.fixedGridWidth = this.getFixedGridWidth();
        this.headerVariantMapping = getHeaderVariantMapping(!(isDesktop || isTablet));
    }

    static getDerivedStateFromProps = (nextProps) => {
        const { data, columns, recalculate } = nextProps;
        if (recalculate) {
            const convertedTableRows = dataConverter(columns, data);
            return {
                convertedTableRows,
                numOfRows: convertedTableRows.length,
            };
        }
        return null;
    }

    componentDidUpdate = ({ resetScrollPosition: prevResetScrollPositionPropValue = false } = {}) => {
        const { noMoreRows } = this.state;
        const { resetScrollPosition = false } = this.props;
        if (prevResetScrollPositionPropValue !== resetScrollPosition && resetScrollPosition) {
            /* To reset the lastScrollPosition to 0. For the cases where pagination has been applied and then,
                data gets reset (For Ex:- In SmartList after the pagination, if filter is applied then data is refreshed,
                in this case the scrollPosition needs to be reset to 0), otherwise the pagination will not work.
            )
            */
            this.lastPaginationStart = 0;
        }
        if (noMoreRows) {
            if (this.noMoreRowsTimeout) clearTimeout(this.noMoreRowsTimeout);
            this.noMoreRowsTimeout = setTimeout(() => this.setState({ noMoreRows: false }), 1000);
        }
    }

    componentDidMount = () => {
        const { getBodyRef } = this.props;
        const { current: tableContainerRef } = this.tableContainerRef;
        const { offsetWidth, clientWidth, offsetHeight, clientHeight } = tableContainerRef || {};
        // Initializing the size for horizontal and vertical scrollbar
        this.setState({
            verticalScrollSize: offsetWidth - clientWidth,
            horizontalScrollSize: offsetHeight - clientHeight,
        });
        if (getBodyRef) {
            getBodyRef(this.bodyRef);
        }
    }

    getFixedGridWidth = () => {
        let width = 0;
        const { tableWidth, fixedColumns } = this.props;
        for (let i = 0; i < fixedColumns; i += 1) {
            width += this.columnWidthFinder(i, tableWidth);
        }
        return width;
    }

    columnWidthFinder = (columnIndex, gridWidth, defaultColumnWidth) => {
        const { columns, columnWidthUnit } = this.props;
        const { width } = columns[columnIndex];
        if (!width) {
            if (defaultColumnWidth) {
                const { verticalScrollSize } = this.state;
                return defaultColumnWidth - (verticalScrollSize / columns.length);
            }
            return gridWidth / columns.length;
        }
        if (columnWidthUnit === PERCENTAGE) {
            return (width / 100) * gridWidth;
        }
        return columns[columnIndex].width;
    }

    onLoadMoreRows = (_, response) => {
        const { data = [] } = response || {};
        this.scrollToRow = data.length;
        this.setState({ showLoader: false });
    }

    loadMoreRows = () => {
        const { numOfRows, numOfRows: nextPaginationStart } = this.state;
        if (nextPaginationStart <= this.lastPaginationStart) {
            return; // debounce calls for same page call
        }
        this.lastPaginationStart = numOfRows;
        const { rowCount, dataGetter } = this.props;
        if (!rowCount) { return; }
        if (dataGetter) {
            if (numOfRows >= rowCount) {
                this.setState({ noMoreRows: true });
            } else {
                this.setState({ showLoader: true });
                dataGetter(this.onLoadMoreRows);
            }
        }
    }

    /**
     * @description Called whenever a horizontal or vertical scrollbar is added or removed.
     * @param {Boolean} horizontal
     * @param {Number} size
     * @param {Boolean} vertical
     */
    scrollbarChanged = ({ horizontal, size, vertical }) => {
        const getSize = (prevSize, hasScrollbar) => ((prevSize || hasScrollbar) ? (size || prevSize) : 0);

        this.setState((prevState) => {
            const {
                horizontalScrollSize: prevHorizontalScrollSize,
                verticalScrollSize: prevVerticalScrollSize,
            } = prevState;
            const horizontalScrollSize = getSize(prevHorizontalScrollSize, horizontal);
            const verticalScrollSize = getSize(prevVerticalScrollSize, vertical);
            return { horizontalScrollSize, verticalScrollSize };
        }, () => {
            const { headerRef } = this;
            if (headerRef) { headerRef.recomputeGridSize(); }
        });
    }

    renderRightGridBodyCell = ({ columnIndex, key, rowIndex, style }) => {
        const { fixedColumns } = this.props;
        if (fixedColumns && columnIndex < fixedColumns) { return null; }
        return this.renderBodyCell({ columnIndex, key, rowIndex, style });
    }

    renderRightGridHeaderCell = ({ columnIndex, key, rowIndex, style }, isFooter = false) => {
        const { fixedColumns } = this.props;
        if (fixedColumns && columnIndex < fixedColumns) { return null; }
        return this.renderHeaderOrFooterCell({ columnIndex, key, rowIndex, style }, isFooter);
    }

    getColumnStyle = (index) => {
        const { props: { tableWidth: gridWidth }, leftAndRightPadding } = this;
        return {
            flex: `1 1 ${this.columnWidthFinder(index,
                gridWidth - leftAndRightPadding)}px`,
        };
    }

    getHeaderHeight = (height) => {
        const { headerVariantMapping } = this;
        return headerVariantMapping[height] || height;
    }

    renderHeaderOrFooterCell = ({ key, rowIndex, style }, isFooter) => {
        const { columns, classes, needFirstColumnPadding, showListPageRibbon } = this.props;
        return (
            <div
                style={style}
                key={key}
                className={clsx(classes.row,
                    isFooter ? classes.marginalsBorderTop : classes.marginalsBorder)}
            >
                {
                    columns.map(({
                        headerClassName, label, footer,
                        footerClassName, align = ALIGN.LEFT, valign = VALIGN.CENTER,
                    } = {}, columnIndex) => (
                        <HeaderOrFooterCell
                            key={`${rowIndex}-${columnIndex}`} // eslint-disable-line react/no-array-index-key
                            index={columnIndex}
                            style={this.getColumnStyle(columnIndex)}
                            className={clsx({
                                [classes.firstColumn]: columnIndex === 0 && needFirstColumnPadding,
                                [classes.noLeftPadding]: columnIndex === 0 && !needFirstColumnPadding && !showListPageRibbon,
                                [classes.firstColumnDefaultHeader]: columnIndex === 0 && showListPageRibbon,
                                [classes.lastColumn]: columnIndex === columns.length - 1,
                            }, classes[`align${capitalize(align)}`],
                            classes[`vAlign${capitalize(valign)}`], isFooter ? footerClassName : headerClassName)}
                            classes={classes}
                            text={isFooter ? footer : label}
                            isFooter={isFooter}
                        />
                    ))
                }
            </div>
        );
    }

    renderBodyCell = ({ key, rowIndex, style }) => {
        const { convertedTableRows = [] } = this.state;
        const {
            columns, classes, data, showLastColumnOnHover,
            formatterProps = {}, rowDivider, needFirstColumnPadding, getRowProps, showListPageRibbon,
        } = this.props;
        const { className: rowClassName, disabled = false, ...restRowProps } = getRowProps(rowIndex, data) || {};
        const currentRow = convertedTableRows[rowIndex] || [];
        const { status: responseStatus = '', value: { status: responseValueStatus = '' } = {} } = data[rowIndex] || [];
        const status = (responseStatus || responseValueStatus);
        let ribbonClassName = '';
        switch (status) {
            case STATUS.Active.value:
                ribbonClassName = classes.activeRibbon;
                break;
            case STATUS.Inactive.value:
            case STATUS.Inactive_For_Future_Use.value:
                ribbonClassName = classes.inactiveRibbon;
                break;
            default:
                break;
        }
        if (convertedTableRows.length > 0) {
            return (
                <div
                    style={style}
                    key={key}
                    className={clsx(classes.row, classes.bodyRow, rowDivider && classes.rowBorder, rowClassName)}
                    {...restRowProps}
                >
                    {
                        currentRow.map((item = EMPTY_FIELD_TEXT, columnIndex) => {
                            const {
                                formatter, className, formFieldInfo = {},
                                align = ALIGN.LEFT, valign = VALIGN.CENTER,
                            } = columns[columnIndex] || {};
                            return (
                                <div
                                    key={`${rowIndex}-${columnIndex}`} // eslint-disable-line react/no-array-index-key
                                    style={this.getColumnStyle(columnIndex)}
                                    className={clsx({
                                        [classes.firstColumn]: columnIndex === 0 && needFirstColumnPadding,
                                        [classes.noLeftPadding]: columnIndex === 0 && !needFirstColumnPadding && !showListPageRibbon,
                                        [classes.firstColumnDefaultPadding]: columnIndex === 0 && showListPageRibbon,
                                        [ribbonClassName]: columnIndex === 0 && showListPageRibbon,
                                        [classes.lastColumn]: columnIndex === columns.length - 1,
                                        [classes.hiddenColumn]: showLastColumnOnHover,
                                    }, classes[`align${capitalize(align)}`],
                                    classes[`vAlign${capitalize(valign)}`], classes.cell, className)}
                                >
                                    {
                                        formatter
                                            ? formatter(item, columnIndex, rowIndex, data[rowIndex],
                                                { ...formatterProps, formFieldInfo }, status)
                                            : (
                                                <Typography
                                                    variant="body1"
                                                    disabled={status === STATUS.Inactive.value}
                                                >
                                                    {typeof item === 'string' ? item.toString() : item}
                                                </Typography>
                                            )
                                    }
                                    {
                                        disabled && <MuiGrid container className={classes.disabledCell} />
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
        return EMPTY_FIELD_TEXT;
    }

    renderFixedHeaderOrFooter = (isFooter = false) => {
        const heightPropInitial = isFooter ? 'footer' : 'header';
        const {
            props: { classes, [`${heightPropInitial}RowHeight`]: rowHeight, tableWidth, fixedColumns } = {},
            fixedGridWidth, getHeaderHeight,
        } = this;
        const height = getHeaderHeight(rowHeight);
        return (
            <div className={classes.fixedGridContainer}>
                <Grid
                    cellRenderer={data => this.renderHeaderOrFooterCell(data, isFooter)}
                    className={classes.headerGrid}
                    width={fixedGridWidth}
                    height={height}
                    rowHeight={height}
                    columnWidth={({ index }) => this.columnWidthFinder(index, tableWidth)}
                    rowCount={1}
                    columnCount={fixedColumns}
                />
            </div>
        );
    }

    renderHeaderOrFooter = (scrollLeft, isFooter = false) => {
        const heightPropInitial = isFooter ? 'footer' : 'header';
        const { classes, [`${heightPropInitial}RowHeight`]: rowHeight, tableWidth: gridWidth } = this.props;
        const { leftAndRightPadding, getHeaderHeight } = this;
        const height = getHeaderHeight(rowHeight);

        return (
            <Grid
                ref={(ref) => { this.headerRef = ref; }}
                autoContainerWidth
                className={classes.marginalsGrid}
                columnWidth={gridWidth - leftAndRightPadding}
                columnCount={1}
                height={height}
                overscanColumnCount={OVERSCAN_COLUMN_COUNT}
                cellRenderer={data => this.renderRightGridHeaderCell(data, isFooter)}
                rowHeight={height}
                rowCount={1}
                scrollLeft={scrollLeft}
                width={gridWidth - leftAndRightPadding}
            />
        );
    }

    calculateRowHeight = ({ index }) => {
        const { calculateRowHeight, data } = this.props;
        return calculateRowHeight(index, data);
    }

    render() {
        const {
            numOfRows,
            verticalScrollSize,
            horizontalScrollSize,
            showLoader,
            noMoreRows,
        } = this.state;
        const {
            state,
            rowHeight,
            headerRowHeight,
            rowCount,
            fixedColumns,
            emptyOrLoadingBodyRenderer,
            classes,
            tableHeight,
            tableWidth: gridWidth,
            hasFooter,
            startFromBottom,
            data,
            showErrorOrLoadingInMiddle,
            showTotalCount,
            calculateRowHeight,
            overscanIndicesGetter,
            scrollToRow: scrollToRowProp,
            showListPageRibbon,
            isConfigurationList,
            renderTotalCount,
            ribbonProps,
        } = this.props;
        const { fixedGridWidth, hasDataBeenLoaded, getHeaderHeight, leftAndRightPadding } = this;
        const totalRows = rowCount || numOfRows;
        const contentBoxWidth = gridWidth ? `${gridWidth}px` : 'auto';
        if (!hasDataBeenLoaded) {
            this.scrollToRow = numOfRows;
            if (data.length > 0) {
                this.hasDataBeenLoaded = true;
            }
        }
        const { scrollToRow } = this;
        let lastScrollAt = 0;
        const headerHeight = getHeaderHeight(headerRowHeight);
        const calculatedTableHeight = tableHeight - headerHeight - horizontalScrollSize;
        let modifiedTableHeight = calculatedTableHeight;
        if (!showListPageRibbon && isConfigurationList) {
            modifiedTableHeight = calculatedTableHeight - 24;
        } else if (showListPageRibbon && isConfigurationList) {
            modifiedTableHeight = calculatedTableHeight - 16;
        }
        return (
            <div
                className={classes.table}
                style={window.innerWidth > 1024
                    ? { width: (showListPageRibbon && !isConfigurationList) ? contentBoxWidth - 10 : contentBoxWidth }
                    : undefined}
            >
                {
                    showTotalCount && (renderTotalCount(totalRows, leftAndRightPadding) || (
                        <TableRowCount totalRows={totalRows} leftAndRightPadding={leftAndRightPadding} />
                    ))
                }
                {
                    showListPageRibbon && (
                        <StatusIndicator
                            classes={classes}
                            isConfigurationList={isConfigurationList}
                            {...ribbonProps}
                        />
                    )
                }
                <ScrollSync>
                    {({ onScroll, scrollLeft, scrollTop, clientHeight, scrollHeight }) => {
                        const gridContainerHeight = getGridHeightValues(
                            clientHeight,
                            scrollHeight,
                            horizontalScrollSize,
                        );
                        return (
                            <div
                                className={classes.tableGrid}
                                ref={this.tableContainerRef}
                                style={{ padding: theme.spacing(0, leftAndRightPadding / 16) }}
                            >
                                {this.renderFixedHeaderOrFooter()}
                                <div className={classes.fixedGridContainer}>
                                    <Grid
                                        overscanColumnCount={OVERSCAN_COLUMN_COUNT}
                                        overscanRowCount={OVERSCAN_ROW_COUNT}
                                        cellRenderer={this.renderBodyCell}
                                        columnWidth={({ index }) => this.columnWidthFinder(index, gridWidth)}
                                        columnCount={fixedColumns}
                                        className={classes.fixedGrid}
                                        height={tableHeight - horizontalScrollSize}
                                        rowHeight={rowHeight}
                                        rowCount={numOfRows}
                                        scrollTop={scrollTop}
                                        width={fixedGridWidth}
                                        style={{
                                            height: gridContainerHeight - horizontalScrollSize,
                                            top: headerHeight,
                                        }}
                                        scrollToRow={scrollToRowProp || (startFromBottom && (scrollToRow || undefined))}
                                    />
                                </div>

                                {hasFooter && this.renderFixedHeaderOrFooter(hasFooter)}

                                <div className={classes.gridColumn}>
                                    <>
                                        {this.renderHeaderOrFooter(scrollLeft)}
                                        <Grid
                                            autoContainerWidth
                                            className={classes.bodyGrid}
                                            columnWidth={gridWidth - leftAndRightPadding}
                                            columnCount={1}
                                            height={modifiedTableHeight}
                                            onScroll={({ clientHeight, clientWidth, scrollHeight, scrollWidth, scrollLeft, scrollTop }) => { // eslint-disable-line no-shadow
                                                const computedScrollHeight = (clientHeight + scrollTop) - horizontalScrollSize;
                                                const isAtEnd = isBetween(scrollHeight, computedScrollHeight - ERROR, computedScrollHeight + ERROR);
                                                if (
                                                    (startFromBottom && scrollTop === 0 && hasDataBeenLoaded)
                                                    || (isAtEnd && lastScrollAt < scrollTop && !startFromBottom)
                                                ) {
                                                    lastScrollAt = scrollTop; // to avoid loadMoreRows being called in horizontal scroll
                                                    if (totalRows >= numOfRows) { this.loadMoreRows(); }
                                                }
                                                return onScroll({ clientHeight, clientWidth, scrollHeight, scrollWidth, scrollLeft, scrollTop });
                                            }
                                            }
                                            onScrollbarPresenceChange={this.scrollbarChanged}
                                            overscanColumnCount={OVERSCAN_COLUMN_COUNT}
                                            // Setting overscan as 0 to avoid flickering(since dynamic row height, height change for rows
                                            // on top and bottom edge results in flicker)
                                            overscanRowCount={calculateRowHeight ? 0 : OVERSCAN_ROW_COUNT}
                                            cellRenderer={this.renderRightGridBodyCell}
                                            noContentRenderer={() => EmptyOrLoadingTable(
                                                emptyOrLoadingBodyRenderer,
                                                classes,
                                                showErrorOrLoadingInMiddle ? tableHeight : rowHeight,
                                                state,
                                            )}
                                            rowHeight={calculateRowHeight ? this.calculateRowHeight : rowHeight}
                                            rowCount={numOfRows}
                                            scrollToRow={scrollToRowProp || (startFromBottom && (scrollToRow || undefined))}
                                            width={gridWidth - leftAndRightPadding}
                                            ref={this.bodyRef}
                                            {...overscanIndicesGetter && { overscanIndicesGetter }}
                                        />
                                        {hasFooter && this.renderHeaderOrFooter(scrollLeft, hasFooter)}

                                        {
                                            showLoader && (
                                                <TableLoading
                                                    classes={classes}
                                                    rowHeight={rowHeight}
                                                    verticalScrollSize={verticalScrollSize}
                                                    isHeader={startFromBottom}
                                                    headerRowHeight={headerHeight}
                                                    leftAndRightPadding={leftAndRightPadding}
                                                />
                                            )
                                        }

                                        {
                                            noMoreRows && (
                                                <TableNoMoreRows
                                                    classes={classes}
                                                    rowHeight={rowHeight}
                                                    verticalScrollSize={verticalScrollSize}
                                                    isHeader={startFromBottom}
                                                    headerRowHeight={headerHeight}
                                                    leftAndRightPadding={leftAndRightPadding}
                                                />
                                            )
                                        }
                                    </>
                                </div>
                            </div>
                        );
                    }}
                </ScrollSync>
            </div>
        );
    }
}

GenericTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        className: PropTypes.string,
        width: PropTypes.number,
        label: PropTypes.node,
        formatter: PropTypes.func,
        headerClassName: PropTypes.string,
        footerClassName: PropTypes.string,
        align: PropTypes.oneOf(Object.values(ALIGN)),
        valign: PropTypes.oneOf(Object.values(VALIGN)),
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape),
    tableHeight: PropTypes.number,
    tableWidth: PropTypes.number,
    hasFooter: PropTypes.bool,
    headerRowHeight: PropTypes.oneOfType([PropTypes.number,
        PropTypes.oneOf(Object.values(TABLE_ROW_HEIGHT_VARIANT))]),
    footerRowHeight: PropTypes.oneOfType([PropTypes.number,
        PropTypes.oneOf(Object.values(TABLE_ROW_HEIGHT_VARIANT))]),
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(Object.values(TABLE_ROW_HEIGHT_VARIANT))]),
    rowCount: PropTypes.number,
    fixedColumns: PropTypes.number,
    columnWidthUnit: PropTypes.oneOf([PIXEL, PERCENTAGE]),
    dataGetter: PropTypes.func,
    emptyOrLoadingBodyRenderer: PropTypes.func,
    classes: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    recalculate: PropTypes.bool,
    showLastColumnOnHover: PropTypes.bool, // We may change this
    formatterProps: PropTypes.object,
    rowDivider: PropTypes.bool,
    startFromBottom: PropTypes.bool,
    state: PropTypes.oneOf([TABLE_STATE.LOADING]),
    needFirstColumnPadding: PropTypes.bool,
    showErrorOrLoadingInMiddle: PropTypes.bool,
    getRowProps: PropTypes.func,
    showTotalCount: PropTypes.bool,
    leftAndRightPadding: PropTypes.number,
    calculateRowHeight: PropTypes.func,
    getBodyRef: PropTypes.func,
    overscanIndicesGetter: PropTypes.func,
    scrollToRow: PropTypes.number,
    showListPageRibbon: PropTypes.bool,
    isConfigurationList: PropTypes.bool,
    renderTotalCount: PropTypes.func,
    resetScrollPosition: PropTypes.bool,
    ribbonProps: PropTypes.object,
};

GenericTable.defaultProps = {
    data: [],
    tableWidth: 500,
    tableHeight: 500,
    rowHeight: TABLE_ROW_HEIGHT_VARIANT.ONE_LINER,
    rowCount: 0,
    hasFooter: false,
    headerRowHeight: TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER,
    footerRowHeight: TABLE_ROW_HEIGHT_VARIANT.ONE_LINER,
    fixedColumns: 0,
    columnWidthUnit: PERCENTAGE,
    recalculate: true,
    showLastColumnOnHover: false,
    formatterProps: {},
    rowDivider: true,
    needFirstColumnPadding: false,
    showErrorOrLoadingInMiddle: false,
    getRowProps: () => {},
    leftAndRightPadding: innerWidth <= screenMaxWidth.laptop ? 16 : 32,
    showListPageRibbon: false,
    isConfigurationList: false,
    renderTotalCount: () => {},
    ribbonProps: {},
};

export default withStyles(tableStyle)(GenericTable);
