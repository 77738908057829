/* eslint-disable import/prefer-default-export */
import localisable from 'Commons/config/strings/localisable';
import { PROCESSOR_TYPE } from 'External/containers/facilityConfiguration/config/Constants';
import { INPUT_TYPE } from 'Commons/components/generic/form/config/FormComponentsConfig';

const getFooterProps = (paymentRef) => {
    // eslint-disable-next-line prefer-const
    let { footerProps: { positiveButtonProps = {}, negativeButtonProps = {}, ...FooterProps } = {} } = paymentRef.props;
    const { onClose, history = {}, isRefund } = paymentRef.props;
    const { deviceInfo: { isMobile } } = window;
    const {
        toggleAlertState, onClosePayment, state: { isButtonLoading, timeOutMessage, fetchingAmountField } = {},
        positiveButtonToolTipMessage, cardProcessorData = {},
    } = paymentRef;
    const onCloseFunction = onClose ? onClosePayment : history.goBack;
    const {
        dirty = false, isValid,
        values: { amount, cardProcessorId, extra: { payUsing } = {} } = {},
    } = paymentRef.formProps;
    const { [cardProcessorId]: { data: { processorType } = {} } = {} } = cardProcessorData;
    const { prefilled } = paymentRef.state;
    const { disabled: isPositiveButtonDisabled } = positiveButtonProps;
    let positiveButtonLabelSuffix = '';
    if (processorType === PROCESSOR_TYPE.Syrapay.value && payUsing === localisable.enterCardDetails) {
        positiveButtonLabelSuffix = localisable.viaSyrapay;
    }
    positiveButtonProps = {
        type: INPUT_TYPE.BUTTON,
        label: `${isRefund ? localisable.refund : localisable.pay} ${positiveButtonLabelSuffix}`,
        loadingText: isRefund ? localisable.refunding : localisable.makingPayment,
        disabled: fetchingAmountField || isPositiveButtonDisabled || timeOutMessage || !amount || Number(amount) === 0
        || !(isValid && dirty && !isButtonLoading && !positiveButtonToolTipMessage),
        ...positiveButtonProps,
    };
    negativeButtonProps = {
        onClick: () => (dirty && !prefilled
            ? toggleAlertState(isMobile && { shouldGoBack: true, discard: true })
            : onCloseFunction()),
        ...negativeButtonProps,
    };
    if (isMobile) {
        positiveButtonProps = {
            ...positiveButtonProps,
            variant: 'icon',
            icon: 'cp-payment',
            type: 'button',
            iconType: 'custom',
        };
        negativeButtonProps = {
            ...negativeButtonProps,
            variant: 'icon',
            icon: 'cp-close',
            iconType: 'custom',
        };
    }
    return { positiveButtonProps, negativeButtonProps, FooterProps };
};

export { getFooterProps };
