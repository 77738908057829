/* eslint-disable eqeqeq */
import { labelFormatter } from 'Commons/helpers/utils/Formatter';
import { getCurrentDate, convertToMomentObj } from 'Commons/helpers/utils/DateTime';
import { CARD_LABEL, CARD_TYPE } from './Constants';


const isEstimateExpired = (expirationDate) => {
    const today = getCurrentDate(false);
    const expiryDate = convertToMomentObj(expirationDate);
    return today > expiryDate;
};

const getCardType = (estimateCode, isExpired) => {
    if (estimateCode) { return isExpired ? CARD_TYPE.EXPIRED : CARD_TYPE.ESTIMATE; }
    return CARD_TYPE.RESERVED;
};

const getCardLabel = (estimateCode, isExpired) => {
    if (estimateCode) { return isExpired ? CARD_LABEL.EXPIRED_ESTIMATE : CARD_LABEL.ESTIMATE; }
    return CARD_LABEL.RESERVATION;
};

const getUnitTypeDescription = (unitTypeId, unitTypeList) => {
    const { value: { description = '', category = [] } = {} } = unitTypeList
        .find(({ id }) => id == unitTypeId) || {};
    return `${description}  ${category.map(c => labelFormatter(c)).join(', ')}`;
};


const getUnitDescription = (unitId, unitList) => {
    const { label = '' } = unitList.find(({ id }) => id == unitId) || {};
    return label;
};


export { isEstimateExpired, getCardLabel, getCardType, getUnitTypeDescription, getUnitDescription };
