import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { DROPDOWN_MAX_HEIGHT, STATUS } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { Checkbox, FormCheckbox } from 'Generic/checkbox/components/CheckBox';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { memo, useEffect, useState } from 'react';
import MultiSelect from 'Generic/multiselect/components/MultiSelect';
import { Dropdown } from 'Commons/components/generic/dropdown/components/Dropdown';
import { TEXT_FIELD_WIDTH } from 'External/containers/configuration/config/Constants';
import RangeFilter from '../../rangeFilter/components/RangeFilter';
import TenantFilterStyle from '../styles/TenantFilterStyle';
import { EXEMPT_FROM_LIST } from '../config/Constants';


const useStyles = makeStyles(TenantFilterStyle, { name: 'TenantFilterStyle' });

const TenantFilter = ({
    isLiveData,
    formProps: {
        setFieldValue,
        values: {
            terms: {
                ledger: { financials: { isAutoPayEnabled: [isAutoPayEnabledChecked = false] = [] } = {} } = {},
                exemptFromEvent: { gateLockout, overlock, otherLateEvents } = {},
                taxExempt: { isTaxExempt } = {},
            } = {},
        } = {},
    },
}) => {
    const getInitialValues = () => {
        const initialValues = [];
        if (gateLockout) initialValues.push(EXEMPT_FROM_LIST.GATE_LOCKOUT.value);
        if (overlock) initialValues.push(EXEMPT_FROM_LIST.OVERLOCK.value);
        if (otherLateEvents) initialValues.push(EXEMPT_FROM_LIST.OTHER_LATE_EVENTS.value);
        if (isTaxExempt) initialValues.push(EXEMPT_FROM_LIST.TAX_EXEMPT.value);
        return initialValues;
    };

    const [initiallySelectedValues, setInitiallySelectedValues] = useState(getInitialValues());
    useEffect(() => {
        if ((!gateLockout || !gateLockout.length)
            && (!overlock || !overlock.length)
            && (!otherLateEvents || !otherLateEvents.length)
            && (!isTaxExempt || !isTaxExempt.length)) {
            setInitiallySelectedValues([]);
        }
    }, [gateLockout, overlock, otherLateEvents, isTaxExempt]);

    const classes = useStyles();

    const { exemptFromList } = useConstructor(() => ({
        exemptFromList: Object.values(EXEMPT_FROM_LIST),
        initiallySelectedValues: [],
    }));

    const [isNoTokenFilterEnabled, setIsNoTokenFilterEnabled] = useState(false);

    const resetNoTokenFilter = () => {
        setFieldValue('notExists.ledger.financials.cardInfo.token', undefined);
        setFieldValue('exists.ledger.financials.cardInfo.masked_card_number', undefined);
        setIsNoTokenFilterEnabled(false);
    };

    const onSelectOrRemoveExemptItems = (selectedEvents) => {
        setFieldValue('terms.exemptFromEvent.gateLockout', EXEMPT_FROM_LIST.GATE_LOCKOUT.value in selectedEvents ? [true] : null);
        setFieldValue('terms.exemptFromEvent.overlock', EXEMPT_FROM_LIST.OVERLOCK.value in selectedEvents ? [true] : null);
        setFieldValue('terms.exemptFromEvent.otherLateEvents', EXEMPT_FROM_LIST.OTHER_LATE_EVENTS.value in selectedEvents ? [true] : null);
        setFieldValue('terms.taxExempt.isTaxExempt', EXEMPT_FROM_LIST.TAX_EXEMPT.value in selectedEvents ? [true] : null);
    };

    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={[STATUS.Active, STATUS.Inactive]}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography variant="subtitle1" className={classes.restLabel}>
                {localisable.advancedFilter}
            </Typography>
            <FormCheckbox
                name="exists.unitList.0"
                label={localisable.atleastOneUnit}
                noGrid
                noIndicatorWrapper
                onChange={(_, value) => {
                    if (!value) {
                        setFieldValue('exists', {});
                    }
                }}
            />
            {
                isLiveData && (
                    <>
                        <FormCheckbox
                            name="terms.ledger.financials.isAutoPayEnabled.0"
                            label={localisable.autoPayEnabled}
                            noGrid
                            noIndicatorWrapper
                            onChange={(_, value) => {
                                if (!value) {
                                    resetNoTokenFilter();
                                    setFieldValue('terms.ledger.financials.isAutoPayEnabled', undefined);
                                }
                            }}
                        />
                        <Checkbox
                            value={isNoTokenFilterEnabled}
                            trackValue={false}
                            label={localisable.autopayEnabledWithoutToken}
                            className={classes.withoutTokenCheckbox}
                            disabled={!isAutoPayEnabledChecked}
                            onChange={(_, value) => {
                                setIsNoTokenFilterEnabled(true);
                                if (value) {
                                    setFieldValue('notExists.ledger.financials.cardInfo.token.0', true);
                                    setFieldValue('exists.ledger.financials.cardInfo.masked_card_number.0', true);
                                } else {
                                    resetNoTokenFilter();
                                }
                            }}
                        />
                        <RangeFilter
                            name="totalBalance"
                            setFieldValue={setFieldValue}
                            label={localisable.ledgerBalance}
                        />
                        <Typography variant="subtitle1" className={classes.restLabel}>
                            {localisable.exemptedFrom}
                        </Typography>
                        <MultiSelect
                            placeholder={localisable.selectExemptedFrom}
                            list={exemptFromList}
                            component={Dropdown}
                            maxHeight={DROPDOWN_MAX_HEIGHT}
                            width={TEXT_FIELD_WIDTH}
                            onSelect={updatedSelectedOptions => onSelectOrRemoveExemptItems(updatedSelectedOptions)}
                            onRemove={updatedSelectedOptions => onSelectOrRemoveExemptItems(updatedSelectedOptions)}
                            initiallySelectedValues={initiallySelectedValues}
                        />
                    </>
                )
            }
        </>
    );
};

TenantFilter.propTypes = { formProps: PropTypes.object, isLiveData: PropTypes.bool };

export default memo(TenantFilter);
