import media from 'Commons/theme/MediaQueries';

const onDemandSectionStyle = theme => ({
    borderSolid: { '&$sectionChildren': { borderStyle: 'solid' } },
    openHeader: {
        padding: theme.spacing(1, 2, 1.5, 2),
        [media.desktop]: { padding: theme.spacing(1, 2, 2, 2) },
    },
    closedHeader: { padding: theme.spacing(1, 2, 0, 2) },
    section: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        '& .subsectionPadding': { paddingRight: theme.spacing(2) },
    },
    sectionChildren: {
        border: `1px dashed ${theme.palette.black[500]}`,
        borderRadius: 4,
        paddingBottom: theme.spacing(1),
    },
    paddingLeft: { paddingLeft: theme.spacing(5) },
    // Deletion Overlay
    onDemandSection: { position: 'relative' },
});

export default onDemandSectionStyle;
