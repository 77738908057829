import { deepCamelize, deepDecamelize } from 'Commons/helpers/api/Formatter';
import { getCookie } from 'Commons/redux/helper/CookieManager';
import COOKIE_KEY from 'Commons/redux/config/CookieKey';
import { isBaseUrlAlpha } from 'Commons/helpers/utils/RouteHelpers';
import getUUID from 'Commons/helpers/utils/generateUUID';

class WebSocketService {
    static instance = null;

    callback = {};

    static getInstance() {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }

    constructor() {
        this.socketRef = null;
        this.socketId = getUUID();
    }

    connect(currentFacility, prevFacility) {
        const { socketId } = this;
        const baseUrl = isBaseUrlAlpha() ? process.env.ALPHA_WEBSOCKET_URL : process.env.WEBSOCKET_URL;
        const path = `${baseUrl}user?access_token=${getCookie(COOKIE_KEY.TOKEN)}&socket_id=${socketId}`;
        this.socketRef = new WebSocket(path);

        this.socketRef.onopen = () => {
            this.updateChannelsGroup(currentFacility, prevFacility);
        };
        this.socketRef.onmessage = (message) => {
            this.socketNewMessage(message.data);
        };

        // this.socketRef.onerror = () => this.retry(currentFacility, 'error');
        this.socketRef.onclose = () => this.retry(currentFacility);
    }

    retry = (currentFacility) => {
        setTimeout(() => { this.connect(currentFacility); }, 5000);
    };

    disConnect() {
        this.socketRef.close(1000, 'expired_group');
    }

    getSocketId() {
        return this.socketId;
    }

    socketNewMessage(data) {
        const parsedData = JSON.parse(data);
        if (Object.keys(this.callback).length === 0) {
            return;
        }
        this.callback.onMessage(deepCamelize(parsedData.message));
    }

    updateChannelsGroup(currFacility, prevFacility) {
        this.sendMessage(deepDecamelize({
            command: 'update_channels_group',
            currFacility,
            prevFacility,
        }));
    }

    discardChannelsGroup(currFacility) {
        this.sendMessage(deepDecamelize({
            command: 'discard_channels_group',
            currFacility,
        }));
    }

    addCallback(onWebSocketMessage) {
        this.callback.onMessage = onWebSocketMessage;
    }

    sendMessage(data) {
        try {
            this.socketRef.send(JSON.stringify({ ...data }));
        } catch {
            // handle the error
        }
    }

    state() {
        return this.socketRef.readyState;
    }
}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;
