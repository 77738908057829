import { memo, useEffect } from 'react';
import { Grid, makeStyles, Typography } from 'Generic/componentlibrary/components/Components';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import localisable from 'Commons/config/strings/localisable';
import Button from 'Generic/button/components/Button';
import { FormRadioGroup } from 'Commons/components/generic/radiogroup/components/RadioGroup';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import Toolbox from './components/Toolbox';
import UnitFormComponent from './components/UnitFormComponent';
import PlaceholderFormComponent from './components/PlaceholderFormComponent';
import Add from './components/Add';
import bevLeftSidebarStyles from './styles/BevLeftSidebarStyles';
import { TYPE_OF_ENTITY } from '../../../config/Constants';

const useStyles = makeStyles(bevLeftSidebarStyles, { name: 'BevLeftSideBar' });

const BevLeftSideBar = (props) => {
    const {
        currentFacility,
        setupModeCanvasRef,
        formProps,
        formProps: { values: { entityType }, errors: { label: labelError } = {}, setFieldValue } = {},
        unitTypeList,
        activeDirection,
        onDirectionChange,
        requiredFieldsConfig,
        labelToIdMap,
        addToUnitTypeMap,
        modifyLabelToIdMap,
        setUnitLabelBeforeDuplicateError,
        handleMoveToCurrentLocation,
        showDuplicateUnitLabelAlert,
        setShowDuplicateUnitLabelAlertDialog,
        shouldDisableToolBox,
        handleAddingEntityOnPlusButtonClick,
        setRequiredFieldsConfig,
    } = props;

    const classes = useStyles();

    const entityTypeRadioList = useConstructor(() => Object.values(TYPE_OF_ENTITY));

    useEffect(() => {
        if (labelError === localisable.unitAlreadyExists) {
            if (setupModeCanvasRef) {
                const fabricCanvasInstance = setupModeCanvasRef.getFabricCanvasInstance();
                const actObject = fabricCanvasInstance.getActiveObject();
                const { type } = actObject || {};
                if (actObject && type === 'group') {
                    setShowDuplicateUnitLabelAlertDialog(true);
                }
            }
        }
    }, [labelError]);

    const handleChangeUnitNumberOrAlertClose = () => {
        setShowDuplicateUnitLabelAlertDialog(false);
    };

    const shouldDisableMoveToCurrentLocationButton = () => {
        if (setupModeCanvasRef && showDuplicateUnitLabelAlert) {
            const fabricCanvasInstance = setupModeCanvasRef.getFabricCanvasInstance();
            const actObject = fabricCanvasInstance.getActiveObject();
            const { type } = actObject || {};
            return type !== 'group' || entityType !== TYPE_OF_ENTITY.Unit.value;
        }
        return false;
    };

    const switchComponentForm = () => {
        switch (entityType) {
            case TYPE_OF_ENTITY.Unit.value:
                return (
                    <UnitFormComponent
                        currentFacility={currentFacility}
                        setupModeCanvasRef={setupModeCanvasRef}
                        formProps={formProps}
                        unitTypeList={unitTypeList}
                        requiredFieldsConfig={requiredFieldsConfig}
                        labelToIdMap={labelToIdMap}
                        addToUnitTypeMap={addToUnitTypeMap}
                        modifyLabelToIdMap={modifyLabelToIdMap}
                        setUnitLabelBeforeDuplicateError={setUnitLabelBeforeDuplicateError}
                        setShowDuplicateUnitLabelAlertDialog={setShowDuplicateUnitLabelAlertDialog}
                    />
                );
            case TYPE_OF_ENTITY.Placeholder.value:
                return (
                    <PlaceholderFormComponent
                        currentFacility={currentFacility}
                        setupModeCanvasRef={setupModeCanvasRef}
                        formProps={formProps}
                        requiredFieldsConfig={requiredFieldsConfig}
                        labelToIdMap={labelToIdMap}
                        modifyLabelToIdMap={modifyLabelToIdMap}
                        setUnitLabelBeforeDuplicateError={setUnitLabelBeforeDuplicateError}
                        setShowDuplicateUnitLabelAlertDialog={setShowDuplicateUnitLabelAlertDialog}
                        setRequiredFieldsConfig={setRequiredFieldsConfig}
                    />
                );
            default:
                return null;
        }
    };

    const handleChangeEntityType = (_, value) => {
        const fabricInstance = setupModeCanvasRef ? setupModeCanvasRef.getFabricCanvasInstance() : null;
        if (fabricInstance) {
            fabricInstance.discardActiveObject();
            fabricInstance.renderAll();
        }
        setFieldValue('entityType', value); // force update after de-selecting
    };

    return (
        <>
             <Grid container item direction="column" className={classes.leftPanelContainer}>
                 <Toolbox
                     setupModeCanvasRef={setupModeCanvasRef}
                     shouldDisableToolBox={shouldDisableToolBox}
                     entityType={entityType}
                 />
                 <Grid container item direction="row" className={classes.sideBarTopMargin}>
                     <Typography variant="subtitle1">
                         {localisable.bevCreateEdit}
                     </Typography>
                     <FormRadioGroup
                         key={entityType}
                         name="entityType"
                         dataType="string"
                         className={classes.radioGroupMarginRight}
                         list={entityTypeRadioList}
                         onChange={handleChangeEntityType}
                     />
                     {switchComponentForm()}
                <>
                 {entityType && (
                     <Add
                         formProps={formProps}
                         activeDirection={activeDirection}
                         onDirectionChange={onDirectionChange}
                         handleAddingEntityOnPlusButtonClick={handleAddingEntityOnPlusButtonClick}
                         entityType={entityType}
                     />
                 )}
                </>
                 </Grid>
             </Grid>
            <AlertDialog
                open={showDuplicateUnitLabelAlert}
                onClose={handleChangeUnitNumberOrAlertClose}
                title={localisable.alert}
                actions={(
                    <>
                        <Button
                            onClick={handleChangeUnitNumberOrAlertClose}
                            variant="text"
                            className={classes.alertDialogButton}
                        >
                            {localisable.changeUnitNumber.toUpperCase()}
                        </Button>
                        <Button
                            onClick={handleMoveToCurrentLocation}
                            variant="text"
                            className={classes.alertDialogButton}
                            disabled={shouldDisableMoveToCurrentLocationButton()}
                        >
                            {localisable.moveToCurrentLocation.toUpperCase()}
                        </Button>
                    </>
                )}
            >
                <Typography>
                    {`Unit ${formProps.values.label} already exists. What do you want to do ?`}
                </Typography>
            </AlertDialog>
        </>
    );
};

BevLeftSideBar.propTypes = {
    currentFacility: PropTypes.object,
    setupModeCanvasRef: PropTypes.object,
    formProps: PropTypes.object,
    unitTypeList: PropTypes.array,
    activeDirection: PropTypes.string,
    addToUnitTypeMap: PropTypes.func,
    onDirectionChange: PropTypes.func,
    requiredFieldsConfig: PropTypes.object.isRequired,
    labelToIdMap: PropTypes.object.isRequired,
    modifyLabelToIdMap: PropTypes.func.isRequired,
    handleMoveToCurrentLocation: PropTypes.func.isRequired,
    showDuplicateUnitLabelAlert: PropTypes.bool.isRequired,
    setShowDuplicateUnitLabelAlertDialog: PropTypes.func.isRequired,
    setUnitLabelBeforeDuplicateError: PropTypes.func.isRequired,
    shouldDisableToolBox: PropTypes.bool,
    handleAddingEntityOnPlusButtonClick: PropTypes.func.isRequired,
    setRequiredFieldsConfig: PropTypes.func,
};

BevLeftSideBar.defaultProps = {
    setupModeCanvasRef: {},
    unitTypeList: {},
    onDirectionChange: () => {
    },
    shouldDisableToolBox: false,
};


export default memo(BevLeftSideBar);
