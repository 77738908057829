import { Form, Formik } from 'formik';
import BaseForm from 'Commons/components/business/baseform/components/BaseForm';
import Page from 'Commons/components/business/page/components/Page';
import { Grid, Loader, Typography, withStyles } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import localisable from 'Commons/config/strings/localisable';
import STORE_KEY_STATUS from 'Commons/config/constants/StoreKeyStatus';
import RequestTypes from 'Commons/config/constants/RequestTypes';
import { VARIANT as SNACKBAR_VARIANT } from 'Generic/snackbar/config/Constants';
import {
    getActualCanvasCoords,
    getEllipsedText,
    getEllipsesTextObj,
    getEntitiesDataFromCanvas,
    getFontSize,
    getNextLabel,
    getProcessedBevValues,
    getReconvertedWH,
} from 'Commons/components/business/bev/utils/Utils';
import CanvasHeader from 'Commons/components/business/bev/components/common/components/CanvasHeader';
import {
    fetchPlaceholders,
    fetchUnitCountByLevelData,
    fetchUnits,
    saveLevelData,
    savePlaceholdersData,
    saveUnitsData,
    saveZoomValue,
    fetchTenant,
    fetchReservation,
} from 'Commons/components/business/bev/config/ApiRequests';
import { clsx } from 'Commons/helpers/utils/clsx';
import {
    DEFAULT_VIEWPORT_TRANSFORM,
    INITIAL_REQUIRED_FIELDS_CONFIG,
    TIMEOUT,
    UNIT_CONFIG,
    ZOOM_LIMITS,
} from 'Commons/components/business/bev/config/Config';
import {
    BEV_MODES,
    COUNT_TYPE,
    DIRECTIONS,
    EMPTY_LIST,
    KEY_CODES,
    PLACEHOLDER_TYPE,
    POSITIONS,
    STATUS_TO_UPDATE_ON,
    TYPE_OF_ENTITY,
} from 'Commons/components/business/bev/config/Constants';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import { DASHBOARD_TYPE, EMPTY_FUNC, EMPTY_OBJECT, SOURCE, STATUS, VIEW, WS_EVENT_TYPE } from 'Commons/config/constants/Constants';
import InfoCard from 'Commons/components/business/bev/components/common/components/infoCard/InfoCard';
import { CREATE_ON_TYPE } from 'External/containers/facilityConfiguration/config/MaintenanceEventConfig';
import { BOARD } from 'External/containers/facilityConfiguration/components/bevColorSetup/components/config/Constants';
import { getIn, getValue, isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import { round } from 'Commons/helpers/utils';
import { convertToNumber } from 'Commons/helpers/utils/Utils';
import { getWhiteSpaceTrimmedString } from 'Commons/helpers/utils/StringHelpers';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import STORE_KEY from 'Commons/redux/config/StoreKey';
import LevelManagement from './components/setup/levelManagement/components/LevelManagement';
import BevLeftSideBar from './components/setup/bevLeftSideBar/BevLeftSideBar';
import BevSetup from './components/setup/BevSetup';
import BevView from './components/view/BevView';
import bevStyles from './styles/BevStyles';

const LoadBev = ({ isDataLoaded, children, classes, isView, setCanvasGridRef }) => (
    <Grid
        ref={setCanvasGridRef}
        container
        item
        lg={12}
        justify="center"
        alignItems="center"
        className={`${classes.canvasGrid} ${isView ? classes.viewCanvasGrid : ''}`}
    >
        {isDataLoaded ? children : <Loader disableShrink />}
    </Grid>
);

class Bev extends BaseForm {
    constructor(props) {
        super(props);
        const {
            level = {},
            currentFacility = {},
            unitBevMini: { data: { data: labelToIdMap = {} } = {} } = {},
            facilityGeneral: {
                data: {
                    data: [{
                        id: facilityGeneralConfigId,
                        value: { bevZoomValue = '1.4' } = {},
                    } = {}] = [],
                } = {},
            } = {},
            bevColors: { data: bevData = {} },
            rentalGeneral: { data: { data: [{ value: { period: { standard: standardPeriod } = {} } = {} } = {}] = [] } = {} } = {},
        } = props;

        let colorLegendData = {};
        if (bevData) {
            const { data: [{ value: { boards = {} } = {} } = {}] = [] } = bevData;
            if (boards) {
                colorLegendData = boards;
            }
        }

        const { data: { metaInfo: { migrated: isFacilityMigrated = false } = {} } = {} } = currentFacility || {};
        const [{ id: levelConfigId, value: { levels: levelsObj = {} } = {} } = {}] = getIn(level, 'data.data') || [];
        this.isFacilityMigrated = isFacilityMigrated;
        this.standardPeriod = standardPeriod;
        this.unitTypeMap = {};
        this.levelConfigId = levelConfigId;
        this.levelsList = this.getLevelsListFromLevelsObj(levelsObj);
        const [{ value: firstLevelKey } = {}] = this.levelsList;
        this.defaultLevel = firstLevelKey;
        this.initialValues = {
            label: '',
            bev: { level: this.defaultLevel, angle: 0 },
            countType: COUNT_TYPE.INCREMENT,
            entityType: TYPE_OF_ENTITY.Unit.value,
            count: 1,
            extra: {
                initialLevelsObj: levelsObj,
                levelsList: this.levelsList,
            },
        };
        this.newAndUpdatedUnits = {};
        this.newAndUpdatedPlaceholders = {};
        this.unitBevStoreStatus = STORE_KEY_STATUS.UNLOADED;
        this.placeholderStoreStatus = STORE_KEY_STATUS.UNLOADED;
        this.units = EMPTY_LIST;
        this.placeholders = EMPTY_LIST;
        this.isDragging = false;
        this.facilityGeneralConfigId = facilityGeneralConfigId;
        this.defaultZoomValue = convertToNumber(bevZoomValue);
        this.colorLegendData = colorLegendData;
        this.initialLabelToIdMap = { ...labelToIdMap };
        this.labelToIdMap = { ...labelToIdMap };
        this.unitLabelBeforeDuplicateError = {};
        this.activeDirection = DIRECTIONS.RIGHT;
        this.bulkRequestErredUnitsMap = {};
        this.bulkRequestErredPlaceholdersMap = {};
        this.shouldReMapUnitTypeIds = false;
        this.colorLegendDataUpdated = false;
        this.zoomToSet = this.defaultZoomValue;
        this.entitiesBulkSaveAwaitResponseCount = 0;
        this.unitIdTenantMapper = {};
        this.unitIdReservationMapper = {};
        this.state = {
            setupModeCanvasRef: null,
            canvasGridRef: null,
            isButtonLoading: false,
            savedUnitsData: null,
            zoomValue: this.defaultZoomValue,
            openLevelManagementModal: false,
            panningMode: false,
            requiredFieldsConfig: INITIAL_REQUIRED_FIELDS_CONFIG,
            showDuplicateUnitLabelAlert: false,
            shouldDisableToolBox: false,
            unitTypeList: [],
            showInfoCard: false,
            showLoader: false,
            showFullPageLoader: false,
            scrollToIndex: -1,
            searchedUnit: {},
            dependentDataFetched: false,
        };
        this.storesToNotClearSilently = [CONFIG_TYPE.BEV_COLORS, CONFIG_TYPE.LATE_EVENT, CONFIG_TYPE.MAINTENANCE_EVENT,
            CONFIG_TYPE.LEVEL, CONFIG_TYPE.FACILITY_GENERAL];
        this.clearStoreOnUnmount = true;
        this.unitBulkSaveResponse = {};
        this.placeholderBulkSaveResponse = {};
    }

    onRefresh = (message = {}) => {
        const { formProps: { values: { bev: { level: currentLevel = {} } = {} } = {} } = {} } = this;
        const {
            resourceName = {},
            fields: {
                configType = {},
                id: levelConfigId,
                value: { levels: wsLevelsObj = {} } = {},
            } = {},
        } = message;
        if (configType === CONFIG_TYPE.LEVEL) {
            const mockResponseWs = {
                data: [{ id: levelConfigId, value: { levels: wsLevelsObj } }],
                isMockResponseWs: true,
            };
            this.onLevelsSave(undefined, mockResponseWs);
        } else if (resourceName === SOURCE.unit.value || resourceName === SOURCE.reservation.value || resourceName === SOURCE.ledger.value) {
            this.onLevelChange(undefined, currentLevel,
                [STORE_KEY.UNIT_BEV_MINI, STORE_KEY.PLACEHOLDER]);
        }
    }

    shouldShowSnackbar = (wsMessage) => {
        const {
            resourceName = {},
            eventType = {},
            fields: {
                configType = {},
                value: { levels: wsLevelsObj = {} } = {},
                bev: wsBev = {},
            } = {},
        } = wsMessage;
        const { formProps: { values: { extra: { initialLevelsObj: formLevels = {} } = {} } = {} } = {} } = this;
        if (configType === CONFIG_TYPE.LEVEL) {
            const levelDiff = Object.keys(formLevels).length - Object.keys(wsLevelsObj).length;
            if (levelDiff === 0) return localisable.levelNameChanged;
            if (levelDiff < 0) return localisable.levelAdded;
            return localisable.levelDeleted;
        } if (resourceName === SOURCE.unit.value) {
            if (eventType === WS_EVENT_TYPE.CREATED) return localisable.unitAdded;
            if (Object.keys(wsBev).length > 1) return localisable.unitLayoutChanged;
            return localisable.unitStatusChanged;
        }
        if (resourceName === SOURCE.reservation.value) {
            if (eventType === WS_EVENT_TYPE.CREATED) return localisable.reservationMade;
        }
        if (resourceName === SOURCE.ledger.value) {
            if (eventType === WS_EVENT_TYPE.UPDATED) return localisable.ledgerChanged;
        }
        return null;
    };

    onWebSocketMessage = (wsMessage = {}) => {
        const msg = this.shouldShowSnackbar(wsMessage);
        if (msg) {
            this.updateSnackbarActionsFor([], wsMessage, () => true, false, this.test);
            this.setSnackbarProps(true, msg, SNACKBAR_VARIANT.rtns.value);
            this.setState({ receivedWSMessage: true });
        }
    }

    componentDidMount = () => {
        const { props: { defaultLevel } = {} } = this;
        this.unblock = this.allowNavigation(this.shouldLeave);
        this.fetchOrRefreshEntitiesData(defaultLevel);
    };

    componentWillUnmount() {
        const { unblock = EMPTY_FUNC, shouldClearStore = false } = this;
        unblock();
        const {
            clearStoreKeys = EMPTY_FUNC,
            facilityGeneral: { data: { data: [{ value: { bevZoomValue = 1 } = {} } = {}] = [] } = {} } = {},
        } = this.props;
        const storeKeysToClear = this.clearStoreOnUnmount ? [STORE_KEY.LEVEL, STORE_KEY.UNIT_BEV,
            STORE_KEY.UNIT_BEV_MINI, STORE_KEY.PLACEHOLDER] : [];
        if (this.defaultZoomValue !== bevZoomValue) storeKeysToClear.push('facilityGeneral');
        if (shouldClearStore) clearStoreKeys(storeKeysToClear);
    }

    postProcessRefresh = () => { this.clearStoreOnUnmount = false; };

    componentDidUpdate(prevProps) {
        const { mode: prevMode } = prevProps;
        const {
            unitBev: { data: { relationalData: { unitType: unitTypeData = {} } = {} } = {} } = {},
            maintenanceEvent,
            lateEvent, mode,
        } = this.props;
        const maintenanceEventData = getValue(
            maintenanceEvent,
            'data.data',
        );
        const lateEventData = getValue(lateEvent, 'data.data');
        const {
            [BOARD.MAINTENANCE_ACTIVITY]: { items: board3Items = [] } = {},
            [BOARD.DELINQUENCY_STATUS]: { items: board4Items = [] } = {},
        } = this.colorLegendData;
        let areSomeUnitTypeIdsNotInMap = false;
        if (isObjWithKeys(this.unitTypeMap) && isObjWithKeys(unitTypeData) && this.shouldReMapUnitTypeIds) {
            areSomeUnitTypeIdsNotInMap = Object.values(unitTypeData).some(({ id } = {}) => !this.unitTypeMap[id]);
        }
        if (!isObjWithKeys(this.unitTypeMap) || areSomeUnitTypeIdsNotInMap) {
            this.unitTypeMap = Object.values(unitTypeData).reduce((prevValue, { value: { description, dimension } = {}, id }) => ({
                ...prevValue,
                [id]: {
                    label: description,
                    id,
                    data: { dimension },
                },
            }), {});
            this.shouldReMapUnitTypeIds = false;
        }
        const isBoard3ItemsEmpty = !board3Items || board3Items.length === 0;
        if (maintenanceEventData.length && isBoard3ItemsEmpty) {
            const items = [];
            maintenanceEventData.forEach(({ value: { color, description, createOn } = {} } = {}) => {
                if (color && createOn === CREATE_ON_TYPE.Unit.value) {
                    items.push(
                        { color, description },
                    );
                }
            });
            this.colorLegendData[BOARD.MAINTENANCE_ACTIVITY] = { items };
            this.colorLegendDataUpdated = true;
        }
        if (lateEventData.length && !board4Items.length) {
            const items = lateEventData.map(({ value: { color, description } = {} } = {}) => ({ color, description }));
            this.colorLegendData[BOARD.DELINQUENCY_STATUS] = { items };
            this.colorLegendDataUpdated = true;
        }
        if (prevMode !== mode) {
            this.unblock = this.allowNavigation(this.shouldLeave);
        }
    }

    shouldLeave = () => {
        const {
            props: { mode } = {},
            formProps: { dirty } = {},
            newAndUpdatedUnits = {},
            newAndUpdatedPlaceholders = {},
        } = this;
        const areNewEntitiesAddedOrUpdated = isObjWithKeys({ ...newAndUpdatedUnits, ...newAndUpdatedPlaceholders });
        if ((dirty || areNewEntitiesAddedOrUpdated) && mode === BEV_MODES.SETUP) {
            this.setState({ discard: true });
            return false;
        }
        return true;
    };

    getLevelsListFromLevelsObj = levelsObj => Object.keys(levelsObj || {}).map(levelKey => ({ label: levelsObj[levelKey], value: levelKey }));

    getLevelsObjFromLevelsList = (levelsList = []) => {
        const levelsObj = {};
        for (let index = 0; index < levelsList.length; index += 1) {
            const { [index]: { label, value: levelKey } = {} } = levelsList;
            levelsObj[levelKey] = label === null ? null : getWhiteSpaceTrimmedString(label);
        }
        return levelsObj;
    };

    onLevelChange = (_, level, storeKeysToClear = [], setLevelInForm = false) => {
        const {
            props: { mode, clearStoreKeys = EMPTY_FUNC } = {},
            formProps: { setFieldValue } = {},
        } = this;
        if (mode === BEV_MODES.SETUP) {
            this.newAndUpdatedUnits = {};
            this.newAndUpdatedPlaceholders = {};
            this.bulkRequestErredUnitsMap = {};
            this.bulkRequestErredPlaceholdersMap = {};
            this.unitLabelBeforeDuplicateError = {};
            this.labelToIdMap = { ...this.initialLabelToIdMap };
            this.shouldReMapUnitTypeIds = true;
        }
        this.fetchOrRefreshEntitiesData(level, RequestTypes.REFRESH, RequestTypes.REFRESH);
        if (setLevelInForm) setFieldValue('bev.level', level);
        if (storeKeysToClear.length) clearStoreKeys(storeKeysToClear);
    };

    setSearchedUnit = (unitDetails) => {
        this.setState({ searchedUnit: unitDetails });
    };

    onFetchTenant = (_, response) => {
        const { data: tenantBevData = [] } = response || {};
        const unitIdTenantMapping = EMPTY_OBJECT;
        tenantBevData.forEach((tenant) => {
            const { unitList = [] } = tenant;
            if (unitList.length) {
                unitList.forEach(({ id: currentUnitId }) => {
                    if (currentUnitId) {
                        unitIdTenantMapping[currentUnitId] = tenant;
                    }
                });
            }
        });
        this.unitIdTenantMapper = { ...this.unitIdTenantMapper, ...unitIdTenantMapping };
        this.setState(prevState => ({ dependentDataFetched: !prevState.dependentDataFetched }));
    };

    onFetchReservation = (_, response) => {
        const { data: reservationBevData = [] } = response || {};
        const unitIdReservationMapping = EMPTY_OBJECT;
        reservationBevData.forEach((reservation) => {
            const { unitId: reservedUnitId = '' } = reservation;
            if (reservedUnitId) {
                unitIdReservationMapping[reservedUnitId] = reservation;
            }
        });
        this.unitIdReservationMapper = { ...this.unitIdReservationMapper, ...unitIdReservationMapping };
        this.setState(prevState => ({ dependentDataFetched: !prevState.dependentDataFetched }));
    };

    fetchOrRefreshEntitiesData = (levelToFetch, unitDataReqType = RequestTypes.INIT,
        placeholderDataReqType = RequestTypes.INIT) => {
        const {
            props: { onAction, fetchLazy, currentFacility: { data: { id: fid } = {} }, unitBevMini },
            formProps: { values: { bev: { level } = {} } = {} } = {},
        } = this;
        const { data: { data: unitBevArray = [] } = {} } = unitBevMini || {};
        const unitIds = Object.values(unitBevArray);
        const levelToFetchFor = levelToFetch || level;
        fetchPlaceholders(onAction, levelToFetchFor, fid, placeholderDataReqType);
        fetchUnits(fetchLazy, levelToFetchFor, unitDataReqType);
        if (unitIds.length) {
            fetchTenant(onAction, unitIds, this.onFetchTenant, VIEW.detail.value, 1000, true);
            fetchReservation(onAction, unitIds, this.onFetchReservation, 1000, true);
        }
    };

    setSetupModeCanvasRef = (ref) => {
        this.setState({ setupModeCanvasRef: ref });
    };

    setCanvasGridRef = (ref) => {
        this.setState({ canvasGridRef: ref });
    };

    setPanningMode = (value) => {
        this.setState({ panningMode: value });
    };

    setRequiredFieldsConfig = (value, shouldReset = false) => {
        this.setState({ requiredFieldsConfig: shouldReset ? INITIAL_REQUIRED_FIELDS_CONFIG : value });
    };

    setShowDuplicateUnitLabelAlertDialog = value => (this.setState({ showDuplicateUnitLabelAlert: value }));

    setUnitLabelBeforeDuplicateError = (id, label) => {
        if (!id || !label) return;
        this.unitLabelBeforeDuplicateError[id] = label;
    };

    setShouldDisableToolBox = (value) => {
        this.setState({ shouldDisableToolBox: value });
    };

    setUnitTypeList = (unitTypeId, shouldClearList = false, shouldClearFormField = true) => {
        const { formProps: { setFieldValue } = {} } = this;
        const unitTypeData = this.unitTypeMap[unitTypeId];
        if (shouldClearFormField) setFieldValue('unitType', undefined);
        if (unitTypeData || shouldClearList) this.setState({ unitTypeList: shouldClearList ? [] : [unitTypeData] });
    };

    addToUnitTypeMap = (unitTypeData) => {
        const { id, label, data: { dimension } = {} } = unitTypeData;
        if (!id || this.unitTypeMap[id]) return;
        this.unitTypeMap[id] = {
            id,
            label,
            data: { dimension },
        };
    }

    setShowInfoCard = (value) => {
        const { showInfoCard } = this.state;
        if (showInfoCard === value) return;
        this.setState({ showInfoCard: value });
    }

    setShowLoader = (value) => {
        this.setState({ showLoader: value });
    }

    setShowFullPageLoader = (value) => {
        this.setState({ showFullPageLoader: value });
    }

    addToLabelToIdMap = (label, unitId) => {
        if (this.labelToIdMap[label]) return;
        this.labelToIdMap[label] = unitId;
    };

    modifyLabelToIdMap = (newKeyToReplaceOldKeyWith, idOfSelectedUnit) => {
        const oldKey = this.unitLabelBeforeDuplicateError[idOfSelectedUnit];
        const valueOfOldKey = this.labelToIdMap[oldKey];

        if (valueOfOldKey !== idOfSelectedUnit) {
            if (oldKey !== newKeyToReplaceOldKeyWith) {
                this.labelToIdMap[newKeyToReplaceOldKeyWith] = idOfSelectedUnit;
                this.setUnitLabelBeforeDuplicateError(idOfSelectedUnit, newKeyToReplaceOldKeyWith);
            }
            return;
        }

        delete this.labelToIdMap[oldKey];
        this.labelToIdMap[newKeyToReplaceOldKeyWith] = valueOfOldKey;
        this.setUnitLabelBeforeDuplicateError(idOfSelectedUnit, newKeyToReplaceOldKeyWith);
    };

    changeMode = () => {
        const {
            props: {
                mode,
                history,
                currentFacility: { data: { id: fid } = {} } = {},
                currentAccountId: { data: { id: accountId } = {} } = {},
            } = {},
        } = this;
        history.push(buildUrl(mode === BEV_MODES.SETUP ? 'bevView' : 'bevSetup', { fid }, DASHBOARD_TYPE.EXTERNAL, accountId));
    };

    onDirectionChange = (direction) => {
        this.activeDirection = direction;
    };

    getActiveDirection = () => this.activeDirection;

    zoomCanvas = (incrementValue, canvasWrapperRef, fabricCanvasInstance, shouldReset = false, fabricInstance) => {
        const {
            props: { mode } = {}, state: { zoomValue: currentZoomValue } = {},
            zoomToSet: prevZoomToSet,
        } = this;
        const { SETUP } = BEV_MODES;
        const zoomToSet = round((shouldReset ? this.defaultZoomValue
            : (prevZoomToSet || currentZoomValue)) + incrementValue, 1);
        if (zoomToSet < ZOOM_LIMITS.LOWER || zoomToSet > ZOOM_LIMITS.UPPER) return;
        if (this.timeout) clearTimeout(this.timeout);
        if (prevZoomToSet && prevZoomToSet !== zoomToSet) {
            this.timeout = setTimeout(() => {
                if (mode === SETUP) {
                    fabricCanvasInstance.setZoom(zoomToSet);
                    let canvasObjects = fabricCanvasInstance.getObjects('group');
                    canvasObjects.forEach((obj) => {
                        const { _objects: [, textObj, ellipsesTextObj] = [] } = obj;
                        textObj.set('fontSize', getFontSize(zoomToSet));
                        if (ellipsesTextObj) ellipsesTextObj.set('fontSize', getFontSize(zoomToSet));
                    });
                    if (zoomToSet < 0.8) {
                        const { viewportTransform } = fabricCanvasInstance;
                        viewportTransform[4] = 0;
                        viewportTransform[5] = 0;
                    }
                    canvasWrapperRef.focus();
                    fabricCanvasInstance.renderAll();
                    canvasObjects = fabricCanvasInstance.getObjects('group');
                    canvasObjects.forEach((obj) => {
                        this.checkAndHandleLabelOverflow(fabricInstance, fabricCanvasInstance, obj, false, zoomToSet);
                    });
                    fabricCanvasInstance.renderAll();
                }
                this.setState({ zoomValue: zoomToSet });
            }, TIMEOUT);
        }
        this.zoomToSet = zoomToSet;
    };

    saveCurrentZoomAsDefault = () => {
        const {
            props: { onAction } = {},
            facilityGeneralConfigId,
            onSaveCurrentZoomAsDefault,
            state: { zoomValue } = {},
        } = this;
        this.setState({ isButtonLoading: true });
        saveZoomValue(onAction, facilityGeneralConfigId, zoomValue, onSaveCurrentZoomAsDefault);
    };

    onSaveCurrentZoomAsDefault = (apiError, response) => {
        if (apiError) {
            this.handleErrors(apiError);
            this.setSnackbarProps(true, localisable.zoomValueSaveFailed, 'error');
        } else if (response) {
            const { data: [{ id: facilityGeneralConfigId, value: { bevZoomValue = '1' } = {} } = {}] = [] } = response;
            if (!this.facilityGeneralConfigId) {
                this.facilityGeneralConfigId = facilityGeneralConfigId;
            }
            this.setSnackbarProps(true, localisable.zoomValueSaved);
            this.defaultZoomValue = convertToNumber(bevZoomValue);
            this.setState({ zoomValue: this.defaultZoomValue });
            this.shouldClearStore = true;
        }
        this.setState({ isButtonLoading: false });
    };

    handlePanning = (event, fabricCanvasInstance) => {
        const { e: { clientX, clientY } } = event;
        const { state: { panningMode } = {} } = this;
        if (panningMode) {
            fabricCanvasInstance.discardActiveObject(undefined);
            fabricCanvasInstance.setCursor('grabbing');
            this.isDragging = true;
            // eslint-disable-next-line no-param-reassign,no-underscore-dangle
            fabricCanvasInstance._previousPointer.x = clientX;
            // eslint-disable-next-line no-param-reassign,no-underscore-dangle
            fabricCanvasInstance._previousPointer.y = clientY;
        }
    };

    /**
     * @description Handles the keyDown event based on the keyCode
     * @param {Object} target target object from the fired event
     * @param {fabric.Canvas}  fabricCanvasInstance The canvas instance
     */
    handleKeyDown = (target, fabricCanvasInstance) => {
        const { isDragging, props: { mode } = {}, state: { panningMode } = {} } = this;
        const { SPACE_BAR } = KEY_CODES;
        switch (target.keyCode) {
            // case DELETE:
            //     if (target.repeat) {
            //         return;
            //     }
            //     setupModeCanvasRef.delete();
            //     break;
            case SPACE_BAR:
                target.preventDefault();
                if (isDragging && target.repeat) {
                    fabricCanvasInstance.setCursor('grabbing');
                } else {
                    fabricCanvasInstance.setCursor('grab');
                }
                if (!panningMode) this.setPanningMode(true);
                if (mode === BEV_MODES.SETUP) {
                    // eslint-disable-next-line no-param-reassign
                    fabricCanvasInstance.selection = false;
                    fabricCanvasInstance.forEachObject((obj) => {
                        // eslint-disable-next-line no-param-reassign
                        obj.selectable = false;
                    }, this);
                }
                break;
            default:
                break;
        }
    };

    /**
     * @description Handles the keyUp event based on the keyCode
     * @param {Object} target target object from the fired event
     * @param {fabric.Canvas}  fabricCanvasInstance The canvas instance
     */
    handleKeyUp = (target, fabricCanvasInstance) => {
        const { props: { mode } = {}, state: { panningMode } = {} } = this;
        const { SPACE_BAR } = KEY_CODES;
        switch (target.keyCode) {
            case SPACE_BAR:
                this.isDragging = false;
                fabricCanvasInstance.setCursor('default');
                if (panningMode) this.setPanningMode(false);
                // eslint-disable-next-line no-param-reassign
                fabricCanvasInstance.selection = true;
                fabricCanvasInstance.forEachObject((obj) => {
                    // eslint-disable-next-line no-param-reassign
                    if (mode === BEV_MODES.SETUP) obj.selectable = true;
                    obj.setCoords();
                }, this);
                break;
            default:
                break;
        }
    };

    moveTooltipWithPointer = (event, fabricCanvasInstance, fabricInstance, labelTooltipInstance) => {
        if (labelTooltipInstance.visible) {
            const { e } = event;
            const zoomValue = fabricCanvasInstance.getZoom();
            const pointer = fabricCanvasInstance.getPointer(e, false);
            labelTooltipInstance.setPositionByOrigin(new fabricInstance.Point(pointer.x,
                pointer.y + labelTooltipInstance.height + UNIT_CONFIG.TOOLTIP_OFFSET_FROM_POINTER / zoomValue),
            'center', 'center');
            fabricCanvasInstance.renderAll();
        }
    };

    handleMouseMove = (event, fabricCanvasInstance, fabricInstance, labelTooltipInstance) => {
        const { state: { panningMode } = {} } = this;
        if (panningMode) {
            this.restrictPanning(event, fabricCanvasInstance);
        } else {
            this.moveTooltipWithPointer(event, fabricCanvasInstance, fabricInstance, labelTooltipInstance);
        }
    };

    handleCanvasMouseUp = (fabricCanvasInstance) => {
        const { state: { panningMode } = {} } = this;
        if (panningMode) {
            this.isDragging = false;
            fabricCanvasInstance.setCursor('grab');
        }
    };

    handleEntityMouseOver = (event, fabricCanvasInstance, labelTooltipInstance) => {
        const { target: { hasLabelOverflowed, _objects: [, { text }] = [] } = {} } = event;
        if (hasLabelOverflowed) {
            const { _objects: [tooltipRectObj, tooltipTextObj] = [] } = labelTooltipInstance;
            tooltipTextObj.set('text', text);
            const tooltipWidth = tooltipTextObj.width + UNIT_CONFIG.LEFT_RIGHT_TOOLTIP_PADDING;
            const tooltipHeight = tooltipTextObj.height + UNIT_CONFIG.TOP_BOTTOM_TOOLTIP_PADDING;
            ['width', 'height', 'visible'].forEach((property) => {
                let value = true;
                if (property === 'width') {
                    value = tooltipWidth;
                } else if (property === 'height') value = tooltipHeight;
                tooltipRectObj.set(property, value);
                labelTooltipInstance.set(property, value);
            });
            fabricCanvasInstance.renderAll();
        }
    };

    handleEntityMouseOut = (fabricCanvasInstance, labelTooltipInstance) => {
        labelTooltipInstance.set('visible', false);
        fabricCanvasInstance.renderAll();
    };

    scrollCanvas = (direction, fabricInstance) => {
        const multiplier = 5; // Adjust as needed
        let dx = 0;
        let dy = 0;
        switch (direction) {
            case DIRECTIONS.UP:
                dy = -10 * multiplier;
                break;
            case DIRECTIONS.DOWN:
                dy = 10 * multiplier;
                break;
            case DIRECTIONS.LEFT:
                dx = 10 * multiplier;
                break;
            case DIRECTIONS.RIGHT:
                dx = -10 * multiplier;
                break;
            default:
                break;
        }
        const { _previousPointer: lastPos, _previousPointer: { x: clientX = 0, y: clientY = 0 } = {} } = fabricInstance;
        if (lastPos === undefined) {
            // eslint-disable-next-line no-underscore-dangle, no-param-reassign
            fabricInstance._previousPointer = { x: 0, y: 0 };
        }
        this.isDragging = true;
        this.restrictPanning({ e: { clientX: clientX + dx, clientY: clientY + dy } }, fabricInstance);
        this.isDragging = false;

        fabricInstance.forEachObject((obj) => {
            // eslint-disable-next-line no-param-reassign
            obj.selectable = true;
            obj.setCoords();
        }, this);
    };

    restrictPanning = (event, fabricCanvasInstance) => {
        const { isDragging, state: { canvasGridRef: { clientWidth: gridWidth, clientHeight: gridHeight } = {} } = {} } = this;
        const { viewportTransform, _previousPointer: lastPos } = fabricCanvasInstance;
        fabricCanvasInstance.setCursor('grab');
        const { e: { clientX, clientY } } = event;
        if (isDragging) {
            fabricCanvasInstance.setCursor('grabbing');
            const zoom = fabricCanvasInstance.getZoom();
            if (zoom < 0.6) { // When zoom value is < 0.6, the canvas is pinned to top-left corner
                viewportTransform[4] = 0;
                viewportTransform[5] = 0;
            } else { // When zoom value is > 0.6, the panning is restricted to the canvas boundary
                const canvasWidth = fabricCanvasInstance.getWidth();
                const canvasHeight = fabricCanvasInstance.getHeight();

                viewportTransform[4] += clientX - lastPos.x;
                viewportTransform[5] += clientY - lastPos.y;

                if (viewportTransform[4] >= 0) {
                    // PANNING LEFT
                    viewportTransform[4] = 0;
                } else if (viewportTransform[4] < -(canvasWidth * zoom - gridWidth)) {
                    // PANNING RIGHT
                    viewportTransform[4] = -(canvasWidth * zoom - gridWidth);
                }

                if (viewportTransform[5] >= 0) {
                    // PANNING UP
                    viewportTransform[5] = 0;
                } else if (viewportTransform[5] < -(canvasHeight * zoom - gridHeight)) {
                    // PANNING DOWN
                    viewportTransform[5] = -(canvasHeight * zoom - gridHeight);
                }
            }

            fabricCanvasInstance.renderAll();
            lastPos.x = clientX;
            lastPos.y = clientY;
        }
    };

    checkAndHandleLabelOverflow = (fabricInstance, fabricCanvasInstance, unit,
        shouldCallRenderAll = true, zoomToSet) => {
        const { _objects: [rectObj, textObj, ellipsesTextObj], angle } = unit;
        const unitCoords = unit.getPointByOrigin('center', 'center');

        const allLabelCornerCoords = (() => {
            const coordsTemplate = [
                {
                    x: -1,
                    y: -1,
                },
                {
                    x: 1,
                    y: -1,
                },
                {
                    x: 1,
                    y: 1,
                },
                {
                    x: -1,
                    y: 1,
                },
            ];
            const { width, height } = textObj;
            return coordsTemplate.map((coord) => {
                const { x: templateX, y: templateY } = coord;
                return {
                    x: templateX * width / 2,
                    y: templateY * height / 2,
                };
            });
        })();

        const originalZoom = fabricCanvasInstance.getZoom();
        const originalViewportTransform = fabricCanvasInstance.viewportTransform;
        fabricCanvasInstance.setZoom(1);
        fabricCanvasInstance.setViewportTransform(DEFAULT_VIEWPORT_TRANSFORM);

        const isOverflowing = allLabelCornerCoords.some((cornerCoord, index) => {
            const actualCanvasCoords = getActualCanvasCoords(index, cornerCoord,
                unitCoords, this.isFacilityMigrated);
            return fabricCanvasInstance.isTargetTransparent(rectObj, actualCanvasCoords.x, actualCanvasCoords.y);
        });

        unit.set('hasLabelOverflowed', isOverflowing);
        if (isOverflowing) {
            textObj.set('visible', !isOverflowing);
            const ellipsedLabel = getEllipsedText(fabricCanvasInstance, rectObj,
                textObj, allLabelCornerCoords, unitCoords, this.isFacilityMigrated);
            if (!ellipsesTextObj) {
                const newEllipsesTextObj = getEllipsesTextObj(fabricInstance, ellipsedLabel, zoomToSet);
                newEllipsesTextObj.set('angle', -angle);
                newEllipsesTextObj.setCoords();
                unit.add(newEllipsesTextObj);
            } else {
                ellipsesTextObj.set('visible', isOverflowing);
                ellipsesTextObj.set('text', ellipsedLabel);
            }
        } else {
            textObj.set('visible', !isOverflowing);
            if (ellipsesTextObj) {
                unit.remove(ellipsesTextObj);
            }
        }

        fabricCanvasInstance.setZoom(originalZoom);
        fabricCanvasInstance.setViewportTransform(originalViewportTransform);

        if (shouldCallRenderAll) fabricCanvasInstance.renderAll();
    };

    getNewAndUpdatedEntityDataVarNameByType = (entityType) => {
        if (entityType === TYPE_OF_ENTITY.Unit.value) return 'newAndUpdatedUnits';
        return 'newAndUpdatedPlaceholders';
    }

    getBulkErredEntitiesMapVarName = (entityType) => {
        if (entityType === TYPE_OF_ENTITY.Unit.value) return 'bulkRequestErredUnitsMap';
        return 'bulkRequestErredPlaceholdersMap';
    }

    addToNewAndUpdatedEntitiesList = (entity, isUpdate = false, updatedPropertyAndValue = {},
        shouldClearPrevValues = false) => {
        let key;
        let value;
        const { entityType } = entity;
        const isEntityPlaceholder = entityType === TYPE_OF_ENTITY.Placeholder.value;
        const newAndUpdatedEntityDataVarName = this.getNewAndUpdatedEntityDataVarNameByType(entityType);

        if (!isUpdate) {
            const [{
                tempId, entityType: exclude,
                placeholderType: type, ...restData
            }] = getEntitiesDataFromCanvas(entity, undefined, this.isFacilityMigrated);
            key = tempId;
            value = { ...restData, ...(isEntityPlaceholder && { type }) };
        } else if (isObjWithKeys(updatedPropertyAndValue)) {
            const { id, tempId } = entity;
            key = id || tempId;
            const {
                bev: prevBev,
                ...restPrevValues
            } = shouldClearPrevValues ? {} : this[newAndUpdatedEntityDataVarName][key] || {};
            const {
                bev, placeholderType: type, placeholderStatus: status,
                ...restUpdatedProperties
            } = updatedPropertyAndValue;
            value = {
                ...restPrevValues,
                ...((bev || prevBev) && { bev: { ...prevBev, ...bev } }),
                ...restUpdatedProperties,
                ...(id && {
                    id,
                    __action_type: 'Update',
                    ...(isEntityPlaceholder && { ...(status && { status }) }),
                }),
                ...(isEntityPlaceholder && {
                    ...(type && {
                        type,
                        ...type !== PLACEHOLDER_TYPE.Others.value && { label: type },
                    }),
                }),
            };

            if (((entityType === TYPE_OF_ENTITY.Unit.value && !value.unitType)
                    || (entityType === TYPE_OF_ENTITY.Placeholder.value && !value.type)) && !value.label && !value.id) {
                delete this[newAndUpdatedEntityDataVarName][key];
                return;
            }
        }

        const { bev: unprocessedBevValues } = value || {};
        if (isObjWithKeys(unprocessedBevValues)) {
            value.bev = getProcessedBevValues(unprocessedBevValues);
        }

        if (key && value) this[newAndUpdatedEntityDataVarName][key] = value;
    };

    /**
     * @description Handles the deletion of given entities from canvas based on whether
     * it's a new entity or an existing one
     * @param {fabric.Group[]} entities Array of fabric.Group objects
     */
    handleDeletionOfEntityFromCanvas = (entities) => {
        let entityList = entities;
        if (!Array.isArray(entities)) {
            entityList = [entities];
        }
        entityList.forEach((entity) => {
            const { id, tempId, entityType } = entity;

            const isEntityUnit = entityType === TYPE_OF_ENTITY.Unit.value;
            if (isEntityUnit) return; // Delete is not allowed for units currently

            const isEntityPlaceholder = entityType === TYPE_OF_ENTITY.Placeholder.value;
            const newAndUpdatedEntityDataVarName = this.getNewAndUpdatedEntityDataVarNameByType(entityType);
            const bulkErredEntitiesMapVarName = this.getBulkErredEntitiesMapVarName(entityType);

            if (tempId) {
                delete this[newAndUpdatedEntityDataVarName][tempId];
                if (isEntityUnit) {
                    const { _objects: [, textObj] = [] } = entity;
                    const { text: label } = textObj;
                    delete this.labelToIdMap[label];
                    const idAgainstLabel = this.labelToIdMap[label];
                    if (idAgainstLabel === tempId) delete this.labelToIdMap[label];
                }
            } else if (id) {
                this.addToNewAndUpdatedEntitiesList(entity, true, {
                    bev: {
                        x: 0,
                        y: 0,
                    },
                    ...(isEntityPlaceholder && { placeholderStatus: STATUS.Inactive.value }),
                }, true);
                delete this[bulkErredEntitiesMapVarName][id];
            }
        });
        const { formProps: { setFieldValue } = {} } = this;
        setFieldValue('id', undefined, true);
    };

    removeSuccessfulEntitiesAndGetErredEntities = (responseUnitsList = [], responsePlaceholdersList = []) => {
        const idListOfUnitsInRequest = Object.keys(this.newAndUpdatedUnits);
        const idListOfPlaceholdersInRequest = Object.keys(this.newAndUpdatedPlaceholders);
        const bulkRequestErredUnitsMap = {};
        const bulkRequestErredPlaceholdersMap = {};
        responseUnitsList.forEach((unit, index) => {
            const unitId = idListOfUnitsInRequest[index];
            const { label, id, errors } = unit;
            if (!errors) {
                delete this.newAndUpdatedUnits[unitId];
                // Update the newly assigned id in labelToIdMap
                const tempId = this.labelToIdMap[label];

                this.labelToIdMap[label] = id;
                this.initialLabelToIdMap[label] = id;

                const valueAtTempId = this.unitLabelBeforeDuplicateError[tempId];
                delete this.unitLabelBeforeDuplicateError[tempId];
                this.unitLabelBeforeDuplicateError[id] = valueAtTempId;
            } else if (unit.errors) {
                bulkRequestErredUnitsMap[unitId] = {
                    id: unitId,
                    errors,
                };
            }
        });
        responsePlaceholdersList.forEach((placeholder, index) => {
            const placeholderId = idListOfPlaceholdersInRequest[index];
            const { errors } = placeholder;
            if (!errors) {
                delete this.newAndUpdatedPlaceholders[placeholderId];
            } else if (placeholder.errors) {
                bulkRequestErredPlaceholdersMap[placeholderId] = {
                    id: placeholderId,
                    errors,
                };
            }
        });
        return { bulkRequestErredUnitsMap, bulkRequestErredPlaceholdersMap };
    };

    highlightErredEntities = (bulkRequestErredEntitiesMap, entityType) => {
        const { state: { setupModeCanvasRef } = {} } = this;
        setupModeCanvasRef.controlEntityHighlighting(bulkRequestErredEntitiesMap, entityType);
    };

    getFormattedPlaceholderReqBody = (newAndUpdatedPlaceholdersParam) => {
        const newAndUpdatedMappedPlaceholders = Object.values(newAndUpdatedPlaceholdersParam);
        // eslint-disable-next-line camelcase
        return newAndUpdatedMappedPlaceholders.map(({ id, __action_type, ...restPlaceholderData }) => ({ ...(id && { id, __action_type }), value: { ...restPlaceholderData } }));
    };

    handleOnSubmit = () => {
        const {
            props: { onAction }, newAndUpdatedUnits,
            newAndUpdatedPlaceholders,
            getFormattedPlaceholderReqBody,
        } = this;
        const stateToSet = {};
        this.entitiesBulkSaveAwaitResponseCount = 0;
        const unitBulkReqBody = Object.values(newAndUpdatedUnits);
        const placeholderBulkReqBody = getFormattedPlaceholderReqBody(newAndUpdatedPlaceholders);
        if (unitBulkReqBody.length) {
            stateToSet.isButtonLoading = true;
            this.setState(stateToSet);
            this.entitiesBulkSaveAwaitResponseCount += 1;
            saveUnitsData(onAction, unitBulkReqBody,
                (err, res) => this.onEntityBulkSaveResponse(err, res, TYPE_OF_ENTITY.Unit.value));
        }
        if (placeholderBulkReqBody.length) {
            stateToSet.isButtonLoading = true;
            this.setState(stateToSet);
            this.entitiesBulkSaveAwaitResponseCount += 1;
            savePlaceholdersData(onAction, placeholderBulkReqBody,
                (err, res) => this.onEntityBulkSaveResponse(err, res, TYPE_OF_ENTITY.Placeholder.value));
        }
    };

    checkAndCallOnEntitiesSave = () => {
        if ((this.entitiesBulkSaveAwaitResponseCount > 1 && isObjWithKeys(this.unitBulkSaveResponse)
            && isObjWithKeys(this.placeholderBulkSaveResponse)) || this.entitiesBulkSaveAwaitResponseCount === 1) {
            this.onEntitiesSave();
        }
    }

    onEntityBulkSaveResponse = (apiError, response, entityType) => {
        switch (entityType) {
            case TYPE_OF_ENTITY.Unit.value:
                this.unitBulkSaveResponse = { apiError, response };
                break;
            case TYPE_OF_ENTITY.Placeholder.value:
                this.placeholderBulkSaveResponse = { apiError, response };
                break;
            default:
                break;
        }
        this.checkAndCallOnEntitiesSave();
    }

    onEntitiesSave = () => {
        const {
            props: { clearStoreKeys } = {},
            formProps: {
                resetForm,
                initialValues, initialValues: {
                    extra: initialExtraValues,
                    bev: prevInitialBevValues = {},
                } = {},
                values: { bev: { level: currentLevel } = {} } = {},
            } = {},
            unitBulkSaveResponse: {
                apiError: unitApiError,
                response: unitBulkSaveResponseBody,
            } = {},
            placeholderBulkSaveResponse: {
                apiError: placeholderApiError,
                response: placeholderBulkSaveResponseBody,
            } = {},
        } = this;

        if (unitApiError || placeholderApiError) {
            this.bulkRequestErredUnitsMap = this.newAndUpdatedUnits;
            this.highlightErredEntities(this.bulkRequestErredUnitsMap, TYPE_OF_ENTITY.Unit.value);

            this.bulkRequestErredPlaceholdersMap = this.newAndUpdatedPlaceholders;
            this.highlightErredEntities(this.bulkRequestErredPlaceholdersMap, TYPE_OF_ENTITY.Placeholder.value);

            this.setSnackbarProps(true, localisable.bevUnitDataSaveFailed, 'error');
        } else {
            const { data: unitResponseData = [] } = unitBulkSaveResponseBody || {};
            const { data: placeholderResponseData = [] } = placeholderBulkSaveResponseBody || {};
            const {
                bulkRequestErredUnitsMap,
                bulkRequestErredPlaceholdersMap,
            } = this.removeSuccessfulEntitiesAndGetErredEntities(unitResponseData, placeholderResponseData);

            this.bulkRequestErredUnitsMap = bulkRequestErredUnitsMap;
            this.bulkRequestErredPlaceholdersMap = bulkRequestErredPlaceholdersMap;

            if (isObjWithKeys(bulkRequestErredUnitsMap) || isObjWithKeys(bulkRequestErredPlaceholdersMap)) {
                const { state: { setupModeCanvasRef } = {} } = this;
                const fabricCanvasInstance = setupModeCanvasRef.getFabricCanvasInstance();
                fabricCanvasInstance.discardActiveObject(undefined);
                this.setSnackbarProps(true, localisable.bevSetupDataPartiallySaved, 'error');
                this.highlightErredEntities(bulkRequestErredUnitsMap, TYPE_OF_ENTITY.Unit.value);
                this.highlightErredEntities(bulkRequestErredPlaceholdersMap, TYPE_OF_ENTITY.Placeholder.value);
            } else {
                this.setSnackbarProps(true, localisable.bevSetupDataSaved);
                clearStoreKeys([STORE_KEY.UNIT_BEV, STORE_KEY.PLACEHOLDER]);
                this.fetchOrRefreshEntitiesData(currentLevel);
            }
            resetForm({
                ...initialValues,
                extra: { ...initialExtraValues, levelsList: this.levelsList },
                bev: { ...prevInitialBevValues, level: currentLevel },
            });
            this.shouldClearStore = true;
        }

        // Reset the response related variables
        this.unitBulkSaveResponse = {};
        this.placeholderBulkSaveResponse = {};
        this.entitiesBulkSaveAwaitResponseCount = 0;

        this.setState({
            isButtonLoading: false,
            shouldDisableToolBox: false,
        });
    };

    shouldDataUpdate = () => {
        const {
            unitBev: { status: unitDataStatus = STORE_KEY_STATUS.UNLOADED } = {},
            placeholders: { status: placeholderDataStatus = STORE_KEY_STATUS.UNLOADED } = {},
        } = this.props;
        const isUnitBevDataLoaded = unitDataStatus !== null
            ? STATUS_TO_UPDATE_ON.includes(unitDataStatus)
            : false;
        const isPlaceholderDataLoaded = placeholderDataStatus !== null
            ? STATUS_TO_UPDATE_ON.includes(placeholderDataStatus)
            : false;
        this.isDataLoaded = isUnitBevDataLoaded && isPlaceholderDataLoaded;

        const shouldUpdate = (this.unitBevStoreStatus !== unitDataStatus
            || this.placeholderStoreStatus !== placeholderDataStatus) && this.isDataLoaded;

        this.unitBevStoreStatus = unitDataStatus;
        this.placeholderStoreStatus = placeholderDataStatus;

        return shouldUpdate;
    };

    getEntitiesData = () => {
        if (this.shouldDataUpdate()) {
            const { unitBev: { data: { data = EMPTY_LIST } = {} } = {} } = this.props;
            this.placeholders = this.getPlaceholders();
            this.units = data.map(({
                id,
                label,
                rentalStatus,
                bev,
                entityType = TYPE_OF_ENTITY.Unit.value,
                unitType: { id: unitType } = {},
                availabilityStatus,
            }) => ({
                id,
                label,
                rentalStatus,
                bev,
                unitType,
                entityType,
                availabilityStatus,
            }));
        }
        return [...this.units, ...this.placeholders];
    };

    getPlaceholders = () => {
        const { props: { placeholder: { data: { data = EMPTY_LIST } = {} } = {} } = {} } = this;
        return data.map(({ id, value: { label, type, bev } = {} } = {}) => ({
            id,
            label,
            placeholderType: type,
            entityType: TYPE_OF_ENTITY.Placeholder.value,
            bev,
        }));
    }


    onLevelManagementClick = () => {
        this.setState({ openLevelManagementModal: true });
    };

    closeLevelManagementModal = () => {
        this.setState({ openLevelManagementModal: false });
    };

    fetchUnitCountData = (callback) => {
        const { props: { onAction } = {} } = this;
        fetchUnitCountByLevelData(onAction, callback);
    };

    handleLevelsSave = () => {
        const {
            props: { onAction } = {},
            formProps: { values: { extra: { levelsList = [] } = {} } = {} } = {},
            onLevelsSave, levelConfigId,
        } = this;
        const processedLevelsData = this.getLevelsObjFromLevelsList(levelsList);
        this.setState({ isButtonLoading: true });
        saveLevelData(onAction, levelConfigId, processedLevelsData, onLevelsSave);
    };

    onLevelsSave = (apiError, response) => {
        if (apiError) {
            this.handleErrors(apiError);
            this.setSnackbarProps(true, localisable.levelsSaveFailed, 'error');
        } else if (response) {
            const { data: [{ id: levelConfigId, value: { levels: levelsObj = {} } = {} } = {}] = [], isMockResponseWs = false } = response;
            const {
                formProps: {
                    resetForm, setValues,
                    initialValues, initialValues: { bev: prevInitialBevValues = {} } = {},
                    values,
                    values: { bev: { level: currentLevel, ...restCurrentBevValues } = {} } = {},
                },
            } = this;
            const [firstLevel] = Object.keys(levelsObj);
            this.levelsList = this.getLevelsListFromLevelsObj(levelsObj);
            const extraValueObj = {
                initialLevelsObj: levelsObj,
                levelsList: this.levelsList,
            };
            resetForm({
                ...initialValues,
                extra: extraValueObj,
                bev: { ...prevInitialBevValues, level: firstLevel },
            });
            setValues({
                ...values,
                extra: extraValueObj,
                ...((!this.levelConfigId || !levelsObj[currentLevel])
                    && { bev: { ...restCurrentBevValues, level: firstLevel } }),
            });
            if (!this.levelConfigId) this.levelConfigId = levelConfigId;
            if (!levelsObj[currentLevel]) this.onLevelChange(undefined, firstLevel);
            if (!isMockResponseWs) {
                this.setSnackbarProps(true, localisable.levelsSaved);
                this.closeLevelManagementModal();
            }
            this.shouldClearStore = true;
        }
        this.setState({ isButtonLoading: false });
    };

    getCanvasObjectOfExistingUnit = (unitId, fabricCanvasInstance) => {
        let canvasObjects = [];
        if (fabricCanvasInstance) canvasObjects = fabricCanvasInstance.getObjects('group');
        return canvasObjects.find((object) => {
            const { id, tempId } = object;
            return id === unitId || tempId === unitId;
        });
    };

    handleMoveToCurrentLocation = () => {
        const {
            formProps: {
                values: {
                    label,
                    entityType,
                    placeholderType,
                    bev: { level: currentLevel } = {},
                } = {},
                errors: { label: labelError } = {},
            } = {},
            state: { setupModeCanvasRef } = {},
            labelToIdMap,
        } = this;
        if (labelError === localisable.unitAlreadyExists) {
            const fabricCanvasInstance = setupModeCanvasRef.getFabricCanvasInstance();
            const actObject = fabricCanvasInstance.getActiveObject();
            const { type, id, tempId } = actObject || {};

            if (fabricCanvasInstance && actObject && type === 'group') {
                const { CENTER } = POSITIONS;
                const idOfExistingUnit = labelToIdMap[label];

                const canvasObjectOfDuplicateUnit = actObject;
                const canvasObjectOfExistingUnit = this.getCanvasObjectOfExistingUnit(idOfExistingUnit, fabricCanvasInstance);

                if (canvasObjectOfExistingUnit) { // This means the existing unit is on the current level
                    const { angle, width, height, unitType } = canvasObjectOfDuplicateUnit;
                    const propertyToValueMap = {
                        'bev.angle': angle,
                        'bev.width': width,
                        'bev.depth': height,
                        unitType,
                        entityType,
                        placeholderType,
                    };
                    // The following line will update the canvas object as well as make an entry into newAndUpdatedUnits
                    Object.keys(propertyToValueMap)
                        .forEach(property => setupModeCanvasRef.modifyEntityData(property, propertyToValueMap[property], canvasObjectOfExistingUnit, true));

                    const centerCoordsOfDuplicateUnit = canvasObjectOfDuplicateUnit.getCenterPoint();
                    canvasObjectOfExistingUnit.setPositionByOrigin(centerCoordsOfDuplicateUnit, CENTER, CENTER);
                    canvasObjectOfExistingUnit.setCoords();
                    const { left: x, top: y } = canvasObjectOfExistingUnit;
                    this.addToNewAndUpdatedEntitiesList(canvasObjectOfExistingUnit, true, {
                        bev: {
                            x,
                            y,
                        },
                    });

                    fabricCanvasInstance.remove(canvasObjectOfDuplicateUnit);
                    this.handleDeletionOfEntityFromCanvas(canvasObjectOfDuplicateUnit);
                } else { // This means the existing unit is on a different level --OR-- has x,y = 0,0 and is not on canvas
                    if (id) {
                        this.addToNewAndUpdatedEntitiesList({
                            id,
                            tempId,
                            entityType,
                        }, true, {
                            bev: {
                                x: 0,
                                y: 0,
                            },
                        }, true);
                    } else {
                        delete this.newAndUpdatedUnits[tempId];
                    }
                    canvasObjectOfDuplicateUnit.set('id', idOfExistingUnit);
                    canvasObjectOfDuplicateUnit.set('tempId', undefined);
                    setupModeCanvasRef.modifyEntityData('bev.level', currentLevel, canvasObjectOfDuplicateUnit);
                    const { left: x, top: y, angle, width, height, unitType } = canvasObjectOfDuplicateUnit;
                    this.addToNewAndUpdatedEntitiesList(canvasObjectOfDuplicateUnit, true, {
                        bev: {
                            x,
                            y,
                            angle,
                            ...getReconvertedWH(width, height, this.isFacilityMigrated),
                        },
                        unitType,
                    });
                }
                let initialLabelOfDuplicateUnit = '';
                if (id) {
                    Object.keys(this.initialLabelToIdMap)
                        .some((initialLabel) => {
                            if (this.initialLabelToIdMap[initialLabel] === (id || tempId).toString()) {
                                initialLabelOfDuplicateUnit = initialLabel;
                                return true;
                            }
                            return false;
                        });
                }
                const labelBeforeDuplicateError = this.unitLabelBeforeDuplicateError[id || tempId];
                delete this.unitLabelBeforeDuplicateError[id || tempId];
                delete this.labelToIdMap[labelBeforeDuplicateError];
                if (id) this.labelToIdMap[initialLabelOfDuplicateUnit] = id;
                fabricCanvasInstance.setActiveObject(canvasObjectOfExistingUnit || canvasObjectOfDuplicateUnit);
                fabricCanvasInstance.renderAll();
            }
            setupModeCanvasRef.loadEntityDataIntoForm(fabricCanvasInstance.getActiveObject());
        }
        this.setState({ showDuplicateUnitLabelAlert: false });
    };

    handleYes = () => {
        const { history } = this.props;
        const { targetLocation } = this;
        this.shouldChangeRoute = true;
        this.newAndUpdatedUnits = {};
        this.newAndUpdatedPlaceholders = {};
        this.bulkRequestErredUnitsMap = {};
        this.bulkRequestErredPlaceholdersMap = {};
        this.unitLabelBeforeDuplicateError = {};
        this.labelToIdMap = { ...this.initialLabelToIdMap };
        if (targetLocation) {
            history.push(targetLocation);
        } else {
            history.goBack();
        }
    };

    renderFooter = () => {
        const {
            props: { classes } = {},
            state: { isButtonLoading, setupModeCanvasRef } = {},
            formProps: { isValid, setStatus, dirty, status: { errors, values, ...status } = {} } = {},
            newAndUpdatedUnits = {},
            newAndUpdatedPlaceholders = {},
            bulkRequestErredUnitsMap = {},
            bulkRequestErredPlaceholdersMap = {},
        } = this;
        let isFormValid = isValid;
        if (setupModeCanvasRef) {
            const fabricCanvasInstance = setupModeCanvasRef.getFabricCanvasInstance();
            const activeObject = fabricCanvasInstance.getActiveObject();
            const { type } = activeObject || {};
            if (!isValid && (!activeObject || type === 'activeSelection')) isFormValid = true;
        }
        const areNewEntitiesAddedOrUpdated = isObjWithKeys({ ...newAndUpdatedUnits, ...newAndUpdatedPlaceholders });
        const areThereErredEntities = isObjWithKeys({ ...bulkRequestErredUnitsMap, ...bulkRequestErredPlaceholdersMap });
        const positiveButtonProps = {
            disabled: (!isFormValid || !dirty || isButtonLoading || !areNewEntitiesAddedOrUpdated) && !areThereErredEntities,
            onClick: () => {
                setStatus(status);
                this.handleOnSubmit();
            },
        };
        return this.getFooter({ positiveButtonProps, ...{ className: classes.footer } });
    };

    getHeader = (isSetupMode) => {
        const { props: { classes } = {}, onLevelManagementClick, changeMode } = this;
        return (
            <Grid
                container
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Typography variant="h5">
                        {isSetupMode ? (localisable.bevSetup) : (localisable.bevView)}
                    </Typography>
                </Grid>
                <Grid item>
                    {isSetupMode && (
                        <Button
                            variant="text"
                            onClick={onLevelManagementClick}
                            light
                            color="primary"
                            className={classes.levelManagementButton}
                        >
                            {
                                localisable.levelManagement
                            }
                        </Button>
                    )}
                    <Button
                        variant="text"
                        onClick={changeMode}
                        light
                        color="primary"
                    >
                        {
                            isSetupMode
                                ? (localisable.bevViewModeLinkText)
                                : (localisable.bevSetupModeLinkText)
                        }
                    </Button>
                </Grid>
            </Grid>
        );
    };

    validate = (values) => {
        const { bev: { width, depth, angle } = {} } = values || {};
        if (width <= 0 || depth <= 0 || angle < 0 || angle > 360) {
            return {
                bev: {
                    ...width <= 0 && { width: localisable.isPositiveNumeric },
                    ...depth <= 0 && { depth: localisable.isPositiveNumeric },
                    ...((angle < 0 || angle > 360) && { angle: angle < 0 ? localisable.isPositiveNumeric : localisable.isValidAngle }),
                },
            };
        }
        return {};
    };

    checkForDuplicateUnitLabelError = () => {
        const { errors: { label: labelError } = {} } = this.formProps;
        if (labelError === localisable.unitAlreadyExists) {
            this.setShowDuplicateUnitLabelAlertDialog(true);
        }
    };

    handleAddingEntityOnPlusButtonClick = () => {
        const { setupModeCanvasRef } = this.state;
        const { type } = setupModeCanvasRef.fabricCanvas.getActiveObject() || {};
        if (type !== 'activeSelection') {
            const {
                values,
                values: { unitType, entityType, placeholderType, bev: { width, depth, angle, level } = {} } = {},
            } = this.formProps;

            const newLabel = getNextLabel(values, type === 'group');

            const isEntityUnit = entityType === TYPE_OF_ENTITY.Unit.value;
            const isEntityPlaceholder = entityType === TYPE_OF_ENTITY.Placeholder.value;

            const newEntity = {
                entityType,
                label: newLabel,
                ...(isEntityUnit && { unitType }),
                ...(isEntityPlaceholder && { placeholderType }),
                bev: {
                    width,
                    depth,
                    angle,
                    level,
                },
            };
            setupModeCanvasRef.addEntity(newEntity, true);
            if (isEntityUnit) this.checkForDuplicateUnitLabelError();
        }
    };

    handleAddingUnitOnEnter = (event) => {
        const { keyCode } = event;
        const { isValid } = this.formProps;
        const { openLevelManagementModal } = this.state;
        if (keyCode === KEY_CODES.ENTER) {
            event.stopPropagation();
            event.preventDefault();
            if (isValid && !openLevelManagementModal) {
                this.handleAddingEntityOnPlusButtonClick();
            }
        }
    };

    // Used for search in view mode
    setScrollToIndex = (index) => {
        this.setState({ scrollToIndex: index });
    };

    renderComponent() {
        const {
            validate,
            zoomCanvas,
            levelsList,
            handleKeyUp,
            labelToIdMap,
            initialValues,
            onLevelChange,
            handlePanning,
            handleKeyDown,
            scrollCanvas,
            setShowLoader,
            getEntitiesData,
            colorLegendData,
            activeDirection,
            handleMouseMove,
            restrictPanning,
            setUnitTypeList,
            setShowInfoCard,
            addToUnitTypeMap,
            setCanvasGridRef,
            defaultZoomValue,
            handleLevelsSave,
            setSnackbarProps,
            addToLabelToIdMap,
            onDirectionChange,
            isFacilityMigrated,
            fetchUnitCountData,
            modifyLabelToIdMap,
            handleEntityMouseOut,
            handleCanvasMouseUp,
            handleEntityMouseOver,
            moveTooltipWithPointer,
            colorLegendDataUpdated,
            setShouldDisableToolBox,
            saveCurrentZoomAsDefault,
            closeLevelManagementModal,
            setRequiredFieldsConfig,
            handleMoveToCurrentLocation,
            checkAndHandleLabelOverflow,
            setUnitLabelBeforeDuplicateError,
            handleAddingEntityOnPlusButtonClick,
            setShowDuplicateUnitLabelAlertDialog,
            setShowFullPageLoader, handleErrors,
            setScrollToIndex, setSearchedUnit,
            unitIdTenantMapper, standardPeriod,
            unitIdReservationMapper,
            state: {
                setupModeCanvasRef, requiredFieldsConfig,
                zoomValue, openLevelManagementModal, isButtonLoading, canvasGridRef,
                showDuplicateUnitLabelAlert, shouldShowAlert, discard = false,
                shouldDisableToolBox, unitTypeList, showInfoCard, showLoader,
                showFullPageLoader, scrollToIndex, searchedUnit,
            } = {},
            props: { classes, currentFacility, currentAccountId, onAction, history, mode, unitBev } = {},
            formProps: {
                dirty = false,
                values: { bev: { level } = {} } = {},
            } = {},
        } = this;
        const { SETUP } = BEV_MODES;
        const isSetupMode = mode === SETUP;
        const entities = getEntitiesData();
        return (
            <>
                <Formik
                    initialValues={initialValues}
                    onSubmit={EMPTY_FUNC}
                    validate={validate}
                    render={(formProps) => {
                        this.formProps = formProps;
                        const { handleSubmit } = formProps;
                        return (
                            <Form
                                className={classes.form}
                                onSubmit={handleSubmit}
                                onKeyDown={this.handleAddingUnitOnEnter}
                            >
                                {showFullPageLoader ? (
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        className={classes.fullPageLoader}
                                    >
                                        <Loader disableShrink />
                                    </Grid>
                                ) : (
                                    <Page
                                        header={this.getHeader(isSetupMode)}
                                        fullHeight
                                        bodyClassName={classes.pageBody}
                                        title={isSetupMode ? localisable.setupBev : localisable.bevViewTitle}
                                    >
                                        {
                                            isSetupMode && (
                                                <>
                                                    <LevelManagement
                                                        formProps={this.formProps}
                                                        fetchUnitCountData={fetchUnitCountData}
                                                        handleLevelsSave={handleLevelsSave}
                                                        openLevelManagementModal={openLevelManagementModal}
                                                        closeLevelManagementModal={closeLevelManagementModal}
                                                        isButtonLoading={isButtonLoading}
                                                    />
                                                    <BevLeftSideBar
                                                        currentFacility={currentFacility}
                                                        setupModeCanvasRef={setupModeCanvasRef}
                                                        formProps={this.formProps}
                                                        activeDirection={activeDirection}
                                                        onDirectionChange={onDirectionChange}
                                                        unitTypeList={unitTypeList}
                                                        addToUnitTypeMap={addToUnitTypeMap}
                                                        requiredFieldsConfig={requiredFieldsConfig}
                                                        labelToIdMap={labelToIdMap}
                                                        addToLabelToIdMap={addToLabelToIdMap}
                                                        modifyLabelToIdMap={modifyLabelToIdMap}
                                                        setUnitLabelBeforeDuplicateError={setUnitLabelBeforeDuplicateError}
                                                        setShowDuplicateUnitLabelAlertDialog={setShowDuplicateUnitLabelAlertDialog}
                                                        showDuplicateUnitLabelAlert={showDuplicateUnitLabelAlert}
                                                        handleMoveToCurrentLocation={handleMoveToCurrentLocation}
                                                        shouldDisableToolBox={shouldDisableToolBox}
                                                        handleAddingEntityOnPlusButtonClick={handleAddingEntityOnPlusButtonClick}
                                                        setRequiredFieldsConfig={setRequiredFieldsConfig}
                                                    />
                                                </>
                                            )
                                        }
                                        <Page
                                            header={(
                                                <CanvasHeader
                                                    isSetupMode={isSetupMode}
                                                    canvasRef={setupModeCanvasRef}
                                                    zoomValue={zoomValue}
                                                    zoomCanvas={zoomCanvas}
                                                    defaultZoomValue={defaultZoomValue}
                                                    saveCurrentZoomAsDefault={saveCurrentZoomAsDefault}
                                                    levelsList={levelsList}
                                                    onLevelChange={onLevelChange}
                                                    colorLegendData={colorLegendData}
                                                    isButtonLoading={isButtonLoading}
                                                    setShowInfoCard={setShowInfoCard}
                                                    onAction={onAction}
                                                    setShowFullPageLoader={setShowFullPageLoader}
                                                    setSnackbarProps={setSnackbarProps}
                                                    handleErrors={handleErrors}
                                                    unitList={entities}
                                                    setScrollToIndex={setScrollToIndex}
                                                    selectedLevel={level}
                                                    searchedUnit={searchedUnit}
                                                    setSearchedUnit={setSearchedUnit}
                                                    scrollCanvas={scrollCanvas}
                                                />
                                            )}
                                            headerClassName={clsx(classes.canvasHeader, !isSetupMode && classes.viewCanvasHeader)}
                                            bodyClassName={classes.pageBody}
                                            {...(isSetupMode && { footer: this.renderFooter() })}
                                            className={classes.page}
                                        >
                                            <InfoCard
                                                key={colorLegendDataUpdated}
                                                isSetupMode={isSetupMode}
                                                colorLegendData={colorLegendData}
                                                showInfoCard={showInfoCard}
                                                setShowInfoCard={setShowInfoCard}
                                                canvasGridRef={canvasGridRef}
                                            />
                                            {
                                                isSetupMode ? (
                                                    <>
                                                        <LoadBev
                                                            setCanvasGridRef={setCanvasGridRef}
                                                            isDataLoaded={this.isDataLoaded}
                                                            classes={classes}
                                                        >
                                                            <BevSetup
                                                                ref={this.setSetupModeCanvasRef}
                                                                isFacilityMigrated={isFacilityMigrated}
                                                                canvasGridRef={canvasGridRef}
                                                                handlePanning={handlePanning}
                                                                handleKeyDown={handleKeyDown}
                                                                handleKeyUp={handleKeyUp}
                                                                handleMouseMove={handleMouseMove}
                                                                handleCanvasMouseUp={handleCanvasMouseUp}
                                                                handleEntityMouseOver={handleEntityMouseOver}
                                                                handleEntityMouseOut={handleEntityMouseOut}
                                                                restrictPanning={restrictPanning}
                                                                entities={entities}
                                                                formProps={this.formProps}
                                                                zoomValue={zoomValue}
                                                                zoomCanvas={zoomCanvas}
                                                                getActiveDirection={this.getActiveDirection}
                                                                addToNewAndUpdatedEntitiesList={this.addToNewAndUpdatedEntitiesList}
                                                                handleDeletionOfEntityFromCanvas={this.handleDeletionOfEntityFromCanvas}
                                                                setRequiredFieldsConfig={setRequiredFieldsConfig}
                                                                addToLabelToIdMap={addToLabelToIdMap}
                                                                setUnitLabelBeforeDuplicateError={setUnitLabelBeforeDuplicateError}
                                                                setShouldDisableToolBox={setShouldDisableToolBox}
                                                                setUnitTypeList={setUnitTypeList}
                                                                checkAndHandleLabelOverflow={checkAndHandleLabelOverflow}
                                                                moveTooltipWithPointer={moveTooltipWithPointer}
                                                            />
                                                        </LoadBev>
                                                    </>
                                                ) : (
                                                    <LoadBev isDataLoaded={this.isDataLoaded} classes={classes} isView setCanvasGridRef={setCanvasGridRef}>
                                                        <BevView
                                                            isFacilityMigrated={isFacilityMigrated}
                                                            canvasGridRef={canvasGridRef}
                                                            entities={entities}
                                                            formProps={this.formProps}
                                                            zoomValue={zoomValue}
                                                            onAction={onAction}
                                                            history={history}
                                                            currentFacility={currentFacility}
                                                            currentAccountId={currentAccountId}
                                                            unitBev={unitBev}
                                                            setShowLoader={setShowLoader}
                                                            setSnackbarProps={setSnackbarProps}
                                                            scrollToIndex={scrollToIndex}
                                                            unitIdTenantMapper={unitIdTenantMapper}
                                                            standardPeriod={standardPeriod}
                                                            unitIdReservationMapper={unitIdReservationMapper}
                                                        />
                                                    </LoadBev>
                                                )
                                            }
                                        </Page>
                                        {
                                            showLoader && (
                                                <Grid
                                                    container
                                                    justify="center"
                                                    alignItems="center"
                                                    className={classes.loader}
                                                >
                                                    <Loader disableShrink />
                                                </Grid>
                                            )
                                        }
                                    </Page>
                                )}
                            </Form>
                        );
                    }
                    }
                />
                <AlertDialog
                    open={shouldShowAlert || (discard && dirty)}
                    onClose={this.onAlertClose}
                    title={localisable.warning}
                    actions={(
                        <Button
                            onClick={this.onAlertYesAction}
                            variant="text"
                        >
                            {localisable.ok}
                        </Button>
                    )}
                >
                    <Typography>{localisable.discardConfirmation}</Typography>
                </AlertDialog>
            </>
        );
    }
}

LoadBev.propTypes = {
    isDataLoaded: PropTypes.bool,
    classes: PropTypes.object,
    children: PropTypes.node,
    isView: PropTypes.bool,
    setCanvasGridRef: PropTypes.func,
};

export default withStyles(bevStyles)(Bev);
