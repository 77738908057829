import media from '../../../../theme/MediaQueries';

const footerStyle = theme => ({
    footer: {
        borderRadius: 4,
        background: theme.palette.common.white,
        padding: theme.spacing(1),
        [media.laptopUp]: { padding: theme.spacing(1, 2) },
        [media.desktop]: { padding: theme.spacing(2, 4) },
    },
    divider: { backgroundColor: theme.palette.text.disabled },
    buttonBack: {},
    buttonPositive: { boxShadow: 'none' },
    buttonNegative: {},
    button: {},
    buttonWrapper: { width: 'auto' },
    buttonWidth: {
        minWidth: 114,
        minHeight: 30,
        [media.desktop]: {
            minWidth: 120,
            minHeight: 32,
        },
        [media.desktopUp]: {
            minWidth: 144,
            minHeight: 40,
        },
    },
});


export default footerStyle;
