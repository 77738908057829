import { makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { STATUS } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import { FormCheckbox } from 'Generic/checkbox/components/CheckBox';
import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import VehicleFilterStyle from '../styles/VehicleFilterStyle';

const useStyles = makeStyles(VehicleFilterStyle, { name: 'VehicleFilterStyle' });

const VehicleFilter = ({ formProps: { setFieldValue } = {} }) => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={[STATUS.Active, STATUS.Inactive]}
                noGrid
                noIndicatorWrapper
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.unitAssociation}
            </Typography>
            <FormCheckbox
                name="exists.unitId.0"
                label={localisable.associated}
                noGrid
                noIndicatorWrapper
                onChange={(_, value) => {
                    if (!value) setFieldValue('exists', {});
                }}
            />
            <FormCheckbox
                name="notExists.unitId.0"
                label={localisable.notAssociated}
                noGrid
                noIndicatorWrapper
                onChange={(_, value) => {
                    if (!value) setFieldValue('notExists', {});
                }}
            />
        </>
    );
};

VehicleFilter.propTypes = { formProps: PropTypes.object };

export default memo(VehicleFilter);
