import PropTypes from 'prop-types';
import { Drawer, withStyles }
from 'Commons/components/generic/componentlibrary/components/Components';
import enums from 'Commons/config/constants/Enums';
import ListItems from './ListItems';
import sideBarStyle from '../styles/SideBarStyle';

class SideBar extends React.Component {
    render() {
        const { classes, open, className, match, closeDrawer, dashboardType, ...props } = this.props;
        return (
            <Drawer
                variant="permanent"
                classes={{ paper: `${classes.drawerPaper} ${!open ? classes.drawerPaperClose : ''}` }}
                open={open}
                className={`${classes.sidebar} ${className}`}
            >
                {/* <div className={classes.firstItem} /> */}
                {/* To keep the first item style */}

                <ListItems
                    open={open}
                    match={match}
                    closeDrawer={closeDrawer}
                    dashboardType={dashboardType}
                    {...props}
                />
            </Drawer>

        );
    }
}

SideBar.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    closeDrawer: PropTypes.func,
    dashboardType: PropTypes.oneOf(enums.dashboardType),
};

SideBar.defaultProps = { className: '' };

export default withStyles(sideBarStyle)(SideBar);
