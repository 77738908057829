import media from 'Commons/theme/MediaQueries';

const FilterAndSortsStyle = theme => ({
    body: {
        padding: theme.spacing(2),
        [media.desktop]: { padding: theme.spacing(2, 4) },
    },
    bottomPadding: { paddingBottom: theme.spacing(2) },
    searchIcon: {
        display: 'flex',
        alignItems: 'center',
        height: 34,
    },
    searchBorder: {
        borderRight: `1px solid ${theme.palette.black[300]}`,
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [media.tabletUp]: {
            marginRight: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    autoWidth: { width: 'auto' },
    sortLabel: {
        alignSelf: 'flex-end',
        paddingRight: theme.spacing(2),
    },
    filterComponent: {
        position: 'absolute',
        left: 0,
        zIndex: 803,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.boxShadows[0],
        borderRadius: 4,
    },
    closeIcon: {
        position: 'absolute',
        zIndex: 804,
        top: 0,
        right: 0,
    },
    sortDropdown: { ...theme.typography.body2 },
    listItem: { ...theme.typography.body2 },
    chip: { height: theme.spacing(4) },
    sortModal: { height: '20%', width: '80%' },
    sortBody: { padding: theme.spacing(2) },
    searchInputField: { width: 'auto' },
    searchTextField: {
        width: '266px',
        '& input': { padding: 0 },
    },
    filtersAppliedBtn: {
        borderRadius: 50,
        ...theme.typography.body2,
        background: theme.palette.highlight,
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.button.hover,
        },
    },
    noPadding: { padding: 0 },
    filterText: { '&:hover': { cursor: 'pointer' } },
    buttonWidth: {
        minWidth: 92,
        minHeight: 35,
    },
    filterContainer: {},
    searchBottomMargin: { marginBottom: theme.spacing(4), paddingTop: theme.spacing(1) },
});

export default FilterAndSortsStyle;
