import { convertToNumber } from 'Commons/helpers/utils/Utils';
import { ALL_ROUNDING_TYPES } from 'Commons/config/constants/Constants';

const roundingTypeToMathFuncMap = {
    [ALL_ROUNDING_TYPES.Nearest_Cent.value]: value => value,
    [ALL_ROUNDING_TYPES.Round_To_Nearest_Dollar.value]: Math.round,
    [ALL_ROUNDING_TYPES.Round_Up_To_Nearest_Dollar.value]: Math.ceil,
    [ALL_ROUNDING_TYPES.Round_Down_To_Nearest_Dollar.value]: Math.floor,
};

/**
 * @description The round() method rounds a number to the given precision. *** If you want to display amount in UI then use getFormattedAmount() util instead ***
 * @param {number | string} value  value to round
 * @param {number} precision number of decimal places
 * @param {boolean} returnAsString If true, the return value type will be string, else float
 * @param {string} roundingType Rounding type to apply
 * @return {number | string}
 */
const round = (value = 0, precision = 2, returnAsString = false, roundingType = '') => {
    const multiplier = 10 ** (precision);
    let modifiedValue = value;

    if (roundingType) {
        const innerMathFunc = roundingTypeToMathFuncMap[roundingType];
        modifiedValue = innerMathFunc ? innerMathFunc(value) : value;
    }

    const roundedValue = ((Math.round(convertToNumber(modifiedValue) * multiplier) / multiplier) || 0).toFixed(precision);
    // This is required as the parseFloat function will truncate 0s after the decimal and return only the integer.
    // E.g. 23.00 will be returned as 23. If returnString is true, then "23.00" will be returned
    return returnAsString ? roundedValue : parseFloat(roundedValue);
};

export default round;
