import eventEmitter from 'Commons/helpers/EventEmitters';
import STATUS from 'Commons/config/constants/StoreKeyStatus';
import REQUEST_TYPES from 'Commons/config/constants/RequestTypes';
import { isPrimitiveType } from 'Commons/helpers/api/Formatter';
import localisable from 'Commons/config/strings/localisable';
import { SUBSTATUS_CODES } from '../../../public/containers/login/config/LoginConstants';

// TODO: act on specific cases
const handleError = (err, callback) => {
    if (callback) { callback(err, null); }
};

export const handleStatusError = ({ status, message, body: { subStatus = '' } = {} }, dispatch, { requestType }) => {
    if (status) {
        if (status >= 400 && status < 500) {
            switch (status) {
                case 401:
                // TODO: Handle on substatus code, when available
                    if (subStatus === SUBSTATUS_CODES.INVALID_TOKEN) {
                        eventEmitter.emit('logout', {});
                    }
                    return {
                        data: {},
                        status: STATUS.TOKEN_EXPIRED,
                    };
                default:
                    return requestType === REQUEST_TYPES.PAGINATE
                        ? { status: STATUS.PAGINATE_ERROR }
                        : {
                            data: {},
                            status: STATUS.INVALID,
                        };
            }
        }
        // Other Series Error's
        return requestType === REQUEST_TYPES.PAGINATE
            ? { status: STATUS.PAGINATE_ERROR }
            : {
                data: {},
                status: STATUS.ERROR, // Error
            };
    }
    return {
        data: { err: message || 'Something Failed' },
        status: STATUS.ERROR,
    };
};

// Method to reduce the errors into one object
const deepTraverse = (object) => {
    if (!object) {
        return object;
    }

    if (!isPrimitiveType(object)) {
        return Object.keys(object).reduce((accumulator, key) => {
            const [isFieldArray, firstField] = [Array.isArray(object[key]), object[key][0]];
            const { errorMessage } = firstField || {};
            const errorMessages = errorMessage ? (object[key].map(({ errorMessage: error }) => error)) : undefined;
            return {
                ...accumulator,
                [key]: errorMessages || deepTraverse(isFieldArray ? firstField : object[key]),
            };
        }, {});
    }

    return object;
};

export const formatErrors = (errors = {}, raiseErrorCodeForFiveHundreds = true) => {
    if (errors.type && errors.type !== 'InvalidJSON') {
        const {
            body: {
                errors: [{
                    errorCode,
                    errorMessage,
                    __all__: [{ errorMessage: integrityError } = {}] = [],
                } = {}],
            } = {},
            status,
        } = errors;

        // No Multiple Errors
        if (errorCode || integrityError) {
            if (status === 500 && raiseErrorCodeForFiveHundreds) {
                return { errorMessage: localisable.somethingWentWrong };
            }
            return { errorMessage: errorMessage || integrityError };
        }

        // There are multiple errors, need to flatten it
        const { body: { errors: responseErrors = [] } } = errors;

        return responseErrors.reduce((errorFields, error = {}) => ({
            ...errorFields,
            ...deepTraverse(error),
        }), {});
    }
    return { errorMessage: localisable.somethingWentWrong };

    // Extract error body,status & message
};


export default handleError;
