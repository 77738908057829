import { memo, useState } from 'react';
import { FormTextField } from 'Generic/textfield/components/TextField';
import Icon from 'Commons/components/generic/icon/components/Icon';

const PasswordField = (props) => {
    const { name, disabled } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormTextField
            type={showPassword ? 'text' : 'password'}
            key={`${name} ${showPassword}`}
            addon={{
                end: <Icon
                    type="custom"
                    onClick={() => setShowPassword(!showPassword)}
                    color="primary"
                    disabled={disabled}
                    icon={!showPassword ? 'cp-view' : 'cp-hide'}
                />,
            }}
            {...props}
        />
    );
};

PasswordField.propTypes = { name: PropTypes.string.isRequired, disabled: PropTypes.bool };
PasswordField.defaultProps = { disabled: false };


export default memo(PasswordField);
