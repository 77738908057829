import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import { useRef, useEffect } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import localisable from 'Commons/config/strings/localisable';
import BreadCrumb from 'Commons/components/generic/breadcrumb/components/BreadCrumb';
import Loader from 'Commons/components/generic/pageloader/components/Loader';
import pageStyle from '../styles/PageStyle';
import { HEADER_VARIANT } from '../config/Constants';
import { PageDefaultProps, PageProps } from '../config/PageProps';

const useStyles = makeStyles(pageStyle, { name: 'Page' });

const Page = ({
    header, children, footer, classes: classesProp, getHeaderRef, getBodyRef, getFooterRef,
    headerClassName, bodyClassName, footerClassName, className,
    fullHeight, loading, headerVariant, loaderClassName, title,
    headerContainerProps, showBreadCrumb, breadCrumbList,
    ...props
}) => {
    const classes = useStyles({ classes: classesProp });
    const headerRef = useRef();
    const bodyRef = useRef();
    const footerRef = useRef();

    const getPageTitle = () => {
        const defaultTitle = localisable.syrasoftConnect;
        return title ? `${title} | ${defaultTitle}` : defaultTitle;
    };

    useEffect(() => {
        if (getHeaderRef) {
            getHeaderRef(headerRef);
        }
        if (getBodyRef) {
            getBodyRef(bodyRef);
        }
        if (getFooterRef) {
            getFooterRef(footerRef);
        }
        document.title = getPageTitle();
    }, [title]);

    return (
        <>
            <Grid
                container
                direction="column"
                className={clsx(classes.page, fullHeight && classes.fullHeight, className)}
                {...props}
            >
                {
                    header && (
                        <Grid
                            container
                            alignItems="center"
                            className={clsx(classes.pageHeader,
                                headerVariant === HEADER_VARIANT.WITH_CONTENT && classes.withContent, headerClassName)}
                            ref={headerRef}
                            {...headerContainerProps}
                        >
                            <Grid container direction="column">
                                {showBreadCrumb
                                    ? (
                                        <Grid item container>
                                            {showBreadCrumb && <BreadCrumb breadCrumbList={breadCrumbList} />}
                                        </Grid>
                                    ) : <></>
                                }
                                {header}
                            </Grid>
                        </Grid>
                    )
                }
                {
                    children && (
                        <Grid
                            ref={bodyRef}
                            container
                            item
                            className={clsx(classes.pageBody, bodyClassName)}
                        >
                            {children}
                        </Grid>
                    )
                }
                {
                    footer && (
                        <Grid
                            ref={footerRef}
                            container
                            item
                            className={clsx(classes.pageFooter, footerClassName)}
                        >
                            {footer}
                        </Grid>
                    )}
                {
                    loading
                    && (<Loader loaderClassName={loaderClassName} />)
                }
            </Grid>
        </>
    );
};

Page.propTypes = PageProps;

Page.defaultProps = PageDefaultProps;

export default Page;
