import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo, useEffect, useMemo, useState } from 'react';
import { DELINQUENCY_STATUS, EMPTY_LIST } from 'Commons/config/constants/Constants';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import getName from 'Commons/helpers/utils/NameHelper';
import { XS_6 } from 'Commons/config/constants/GridSpacingConstants';
import { SmartSearchableDropdown } from 'Commons/components/generic/dropdown/components/SearchableDropdown';
import { getTenantApiConfig } from '../config/ApiRequests';
import JournalLateEventFilterStyle from '../styles/JournalLateEventFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';

const useStyles = makeStyles(
    JournalLateEventFilterStyle, { name: 'DelinquencyTrackingLateEventFilter' },
);

const DelinquencyTrackingLateEventFilter = (props) => {
    const {
        entityId: selectedFacility, formProps,
        formProps: {
            setFieldValue,
            values: { terms: { ledgerId: selectedTenants = EMPTY_LIST } = {} } = {},
        } = {},
    } = props;
    const classes = useStyles();
    const tenantApiConfig = useMemo(() => getTenantApiConfig(selectedFacility), [selectedFacility]);
    const ALLOWED_LATE_EVENT_STATUS = useConstructor(() => [DELINQUENCY_STATUS.Waived]);
    // TODO: Add support for Reversed and Deleted

    const [selectedTenant, setSelectedTenant] = useState('');

    useEffect(() => {
        if (!selectedTenants.length) setSelectedTenant('');
    }, [selectedTenants.length]);

    const onSelectTenant = (_, tenantId, tenantDetails = {}) => {
        const { ledger = [] } = tenantDetails;
        const ledgerIds = ledger.map(({ id }) => id);
        setFieldValue('terms.ledgerId', ledgerIds);
        setSelectedTenant(tenantId);
    };

    return (
        <>
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={ALLOWED_LATE_EVENT_STATUS}
                row
                noGrid
                noIndicatorWrapper
            />
            <RangeFilter
                isDateRange
                disableFuture
                name="effectiveDate"
                formProps={formProps}
                setFieldValue={setFieldValue}
                label={localisable.effectiveDate}
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.selectTenant}
            </Typography>
            <SmartSearchableDropdown
                fullWidth
                value={selectedTenant}
                placeholder={localisable.search}
                label={localisable.searchTenant}
                onChange={onSelectTenant}
                keyToMatch="id"
                inputValueFormatter={({ name }) => getName(name)}
                getOptionProps={({ name }) => ({ label: getName(name) })}
                apiConfig={tenantApiConfig}
                GridProps={XS_6}
            />
        </>
    );
};

DelinquencyTrackingLateEventFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
};

DelinquencyTrackingLateEventFilter.defaultProps = { entityId: '', formProps: {} };

export default memo(DelinquencyTrackingLateEventFilter);
