import { setCookie, getCookie } from 'Commons/redux/helper/CookieManager';
import COOKIE_KEY from 'Commons/redux/config/CookieKey';

/**
 * Util to manage the API Build Version
 */

export const setApiBuildVersion = (currentApiVersion) => {
    const cachedBuildVersion = getCookie(COOKIE_KEY.BUILD_VERSION);
    if (cachedBuildVersion !== currentApiVersion) {
        setCookie(COOKIE_KEY.BUILD_VERSION, currentApiVersion);
    }
};

export const getApiBuildVersion = () => getCookie(COOKIE_KEY.BUILD_VERSION);
