import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { DELINQUENCY_STATUS, LOCKOUT_TYPE } from 'Commons/config/constants/Constants';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import DelinquencyTrackingLockoutEventFilterStyle from '../styles/DelinquencyTrackingLockoutEventFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';


const useStyles = makeStyles(
    DelinquencyTrackingLockoutEventFilterStyle, { name: 'DelinquencyTrackingLockoutEventFilterStyle' },
);

const DelinquencyTrackingLockoutEventFilter = ({ formProps: { setFieldValue } = {}, formProps = {} }) => {
    const classes = useStyles();
    const STATUS = useConstructor(() => [
        {
            label: 'Pending',
            value: DELINQUENCY_STATUS.Active.value,
        },
        {
            label: 'Applied',
            value: DELINQUENCY_STATUS.Processed.value,
        },
    ]);

    return (
        <>
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={Object.values(STATUS)}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.events}
            </Typography>
            <FormCheckboxGroup
                name="terms.type"
                list={Object.values(LOCKOUT_TYPE)}
                row
                noGrid
                noIndicatorWrapper
            />
            <RangeFilter
                name="effectiveDate"
                isDateRange
                disableFuture
                formProps={formProps}
                setFieldValue={setFieldValue}
                label={localisable.dateRange}
            />
        </>
    );
};

DelinquencyTrackingLockoutEventFilter.propTypes = { formProps: PropTypes.object };

export default memo(DelinquencyTrackingLockoutEventFilter);
