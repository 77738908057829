import { memo, useMemo } from 'react';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import { DROPDOWN_MAX_HEIGHT, TEXT_FIELD_WIDTH } from 'External/containers/configuration/config/Constants';
import { Dropdown } from 'Generic/dropdown/components/Dropdown';
import PromoPlanStyle from '../styles/PromoPlanStyle';

const useStyles = makeStyles(PromoPlanStyle, { name: 'PaymentPromoPlan' });

const PromoPlan = ({ promoPlanData, selectedPromoPlans, setSelectedPromoPlans }) => {
    const classes = useStyles();

    const promoPlans = useConstructor(() => Object.entries(promoPlanData).map(([id, value]) => {
        const { title } = value;
        return { label: title, value: id, id };
    }));

    const initiallySelectedPromoPlans = useMemo(() => Object.keys(selectedPromoPlans), [selectedPromoPlans]);

    return (
        <Grid container alignItems="center" className={classes.container}>
            <MultiSelect
                label={localisable.selectPromoPlans}
                placeholder={localisable.selectPromoPlans}
                list={promoPlans}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                onSelect={setSelectedPromoPlans}
                component={Dropdown}
                onRemove={setSelectedPromoPlans}
                initiallySelectedValues={initiallySelectedPromoPlans}
                showRequiredIcon
                width={TEXT_FIELD_WIDTH}
            />
        </Grid>
    );
};

PromoPlan.propTypes = {
    promoPlanData: PropTypes.object,
    selectedPromoPlans: PropTypes.object,
    setSelectedPromoPlans: PropTypes.func,
};

export default memo(PromoPlan);
