import media from 'Commons/theme/MediaQueries';


const DeliveryMethodDropdownStyles = theme => ({
    printCopiesinput: {
        paddingBottom: theme.spacing(0.25),
        paddingTop: 0,
    },
    lastInline: {
        padding: theme.spacing(2, 2, 0, 0),
        [media.laptopUp]: { padding: theme.spacing(3, 0, 0, 2) },
        [media.mobile]: { padding: theme.spacing(3, 0, 0, 0) },
    },
    mobileContainer: { marginTop: theme.spacing(2) },
    container: { },
    printCopiesContainer: {},
    previewLabel: { padding: theme.spacing(2), cursor: 'pointer' },
    changePrinterLabel: { cursor: 'pointer', paddingTop: theme.spacing(1), [media.mobile]: { paddingLeft: theme.spacing(1) } },
    previewLoader: { padding: theme.spacing(2), margin: theme.spacing(2) },
    modalGrid: {
        width: '90%',
        height: '90%',
        [media.tabletUp]: { width: '80%', height: '80%' },
        [media.laptopUp]: { width: '80%', height: '80%' },
        flexWrap: 'nowrap',
    },
    printer: { borderBottomColor: theme.palette.common.black },
    customLink: { marginLeft: theme.spacing(2), paddingRight: theme.spacing(1), [media.mobile]: { paddingRight: 0 } },
    printerIcon: {
        color: theme.palette.common.black,
        [media.mobile]: { fontSize: '1.75rem' },
    },
    menuPopper: { zIndex: 1 },
    anchorElDiv: {
        position: 'relative',
        padding: '12px 0',
        margin: 'auto 16px auto 16px',
        [media.tabletUp]: { margin: 'auto 28px auto 36px' },
    },
    myShadow: {
        '&[x-placement*="bottom"] $arrow': {
            '&::before': { /* Overriden arrow shadow by using classes prop */
                '-webkit-filter': 'drop-shadow(0px -1px 0px rgba(0,0,0,.1))',
                filter: 'drop-shadow(0px -2px 0px rgba(0,0,0,.1))',
            },
        },
    },
    printAlertDialogContainerSm: {
        width: '40%',
        height: '40%',
        [media.smallMobile]: { width: '80%', height: '80%' },
        [media.mobile]: { width: '80%', height: '70%' },
        [media.tabletUp]: { width: '60%', height: '50%' },
        [media.laptopUp]: { width: '60%', height: '60%' },
        [media.desktopUp]: { width: '40%', height: '40%' },
    },
    printerAlertNote: { padding: theme.spacing(2) },
    printerSelectionPopover: { marginLeft: 0 },
});


export default DeliveryMethodDropdownStyles;
