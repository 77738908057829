import { memo } from 'react';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import tableRowCountStyle from './styles/TableRowCountStyle';

const useStyles = makeStyles(tableRowCountStyle, { name: 'TableRowCount' });

const TableRowCount = ({ totalRows, className, leftAndRightPadding, ...props }) => {
    const classes = useStyles({ leftAndRightPadding });
    return totalRows ? (
        <Typography variant="body2" fontStyle="italic" className={clsx(classes.rowCount, className)} {...props}>
            {totalRows}
            {' '}
            {totalRows === 1 ? localisable.result : localisable.results}
            {' '}
            {localisable.found}
        </Typography>
    ) : null;
};

TableRowCount.propTypes = {
    totalRows: PropTypes.number,
    className: PropTypes.string,
    leftAndRightPadding: PropTypes.number,
};

TableRowCount.defaultProps = { totalRows: 0, leftAndRightPadding: 0 };

export default memo(TableRowCount);
