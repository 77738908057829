import Loadable from 'react-loadable';
import { Grid, Loader } from 'Generic/componentlibrary/components/Components';

const loading = () => <div />;

const loader = () => (
    <Grid container justify="center" alignItems="center">
        <Loader disableShrink />
    </Grid>
);

const HomePage = Loadable({
    loader: () => import('Public/containers/homepage/HomePage' /* webpackChunkName: "homepage" */),
    loading,
});
const Dashboard = Loadable({
    loader: () => import('Commons/components/business/dashboard/Dashboard' /* webpackChunkName: "dashboard" */),
    loading,
});
const Header = Loadable({
    loader:
        () => import('External/containers/dashboard/widgets/header/components/Header' /* webpackChunkName: "header" */),
    loading,
});
const DailySummary = Loadable({
    loader: () => import('Internal/containers/dailysummary/list/components/DailySummary' /* webpackChunkName: "daily-summary" */),
    loading,
});
const RentAssessmentHistory = Loadable({
    loader: () => import('Internal/containers/dailysummary/list/components/DailySummaryList' /* webpackChunkName: "rent-assessment-history" */),
    loading,
});
const AutoPaymentHistory = Loadable({
    loader: () => import('Internal/containers/dailysummary/list/components/DailySummaryList' /* webpackChunkName: "auto-payment-history" */),
    loading,
});

const AutoInvoicingHistory = Loadable({
    loader: () => import('Internal/containers/dailysummary/list/components/DailySummaryList' /* webpackChunkName: "auto-invoicing-history" */),
    loading,
});

const RentAdjustmentSummary = Loadable({
    loader: () => import('Internal/containers/dailysummary/list/components/DailySummaryList' /* webpackChunkName: "rent-adjustment-summary" */),
    loading,
});
const DelinquencySummary = Loadable({
    loader: () => import('Internal/containers/dailysummary/list/components/DailySummaryList' /* webpackChunkName: "delinquency-summary" */),
    loading,
});
const TaskSummary = Loadable({
    loader: () => import('Internal/containers/dailysummary/list/components/TaskSummary' /* webpackChunkName: "task-summary" */),
    loading,
});
const SavedDocumentsList = Loadable({
    loader: () => import('Internal/containers/savedDocuments/list/components/SavedDocumentsList' /* webpackChunkName: "saved-documents-list" */),
    loading,
});
const ScheduleList = Loadable({
    loader: () => import('Internal/containers/schedule/list/components/ScheduleList' /* webpackChunkName: "schedule-list" */),
    loading,
});
const AsyncTaskList = Loadable({
    loader: () => import('Internal/containers/asyncTasks/list/components/AsyncTaskList' /* webpackChunkName: "async-task-list" */),
    loading,
});
const HeaderInt = Loadable({
    loader: () => import('Internal/containers/dashboard/widgets/header/components/Header' /* webpackChunkName: "header-int" */),
    loading,
});
const Main = Loadable({
    loader: () => import('External/containers/dashboard/widgets/main/components/Main' /* webpackChunkName: "Main" */),
    loading,
});
const MainSettings = Loadable({
    loader: () => import('External/containers/dashboardSettings/widgets/main/components/Main' /* webpackChunkName: "MainSettings" */),
    loading,
});
const MainInt = Loadable({
    loader: () => import('Internal/containers/dashboard/widgets/main/components/Main' /* webpackChunkName: "MainInt" */),
    loading,
});
const SmartList = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "smart-list" */),
    loading,
});
const DelinquencyOverview = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "delinquency-overview" */),
    loading,
});

const LateEventsTracking = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "late-events-tracking" */),
    loading,
});

const LockoutEventsTracking = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "lockout-events-tracking" */),
    loading,
});

const ScheduledEventsTracking = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "scheduled-events-tracking" */),
    loading,
});

const WrongEntriesTracking = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "wrong-entry-tracking" */),
    loading,
});

const JournalConfigurationChanges = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "journal-configuration-changes" */),
    loading,
});

const JournalLockoutEvents = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "journal-lockout-events" */),
    loading,
});

const JournalUserRoles = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "journal-user-roles" */),
    loading,
});

const JournalUserManagements = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "journal-user-managements" */),
    loading,
});

const JournalLateEvents = Loadable({
    loader: () => import('../components/business/smartlist/components/SmartList' /* webpackChunkName: "journal-late-events" */),
    loading,
});

const FacilityForm = Loadable({
    loader: () => import('Internal/containers/facility/form/FacilityForm' /* webpackChunkName: "facility-form" */),
    loading,
});
const AccountForm = Loadable({
    loader: () => import('Internal/containers/account/form/AccountForm' /* webpackChunkName: "-account-form" */),
    loading,
});
const UserForm = Loadable({
    loader: () => import('Internal/containers/user/form/UserForm' /* webpackChunkName: "user-form" */),
    loading,
});
const UserList = Loadable({
    loader: () => import('Internal/containers/user/list/components/UserList' /* webpackChunkName: "user-form" */),
    loading,
});
const Logout = Loadable({
    loader: () => import('../components/business/logout/components/Logout' /* webpackChunkName: "log-out" */),
    loading,
});
const UnitForm = Loadable({
    loader: () => import('../../external/containers/unit/form/components/UnitForm' /* webpackChunkName: "unit-form" */),
    loading,
});
const SideBar = Loadable({
    loader: () => import('../components/business/sidebar/components/SideBar' /* webpackChunkName: "side-bar" */),
    loading,
});
const TenantForm = Loadable({
    loader: () => import('../../external/containers/tenant/form/components/TenantForm' /* webpackChunkName: "create-tenant-form" */),
    loading: loader,
});
const TenantList = Loadable({
    loader: () => import('../../external/containers/tenant/list/components/TenantList' /* webpackChunkName: "tenant-list" */),
    loading: loader,
});
const LeadForm = Loadable({
    loader: () => import('../../external/containers/lead/form/components/LeadForm' /* webpackChunkName: "lead-form" */),
    loading,
});
const VendorForm = Loadable({
    loader: () => import('../../external/containers/vendor/form/components/VendorForm' /* webpackChunkName: "vendor-form" */),
    loading: loader,
});
const InventoryAudit = Loadable({
    loader: () => import('../../external/containers/inventory/audit/components/InventoryAudit' /* webpackChunkName: "inventory-audit" */),
    loading: loader,
});
const InventoryForm = Loadable({
    loader: () => import('../../external/containers/inventory/form/components/InventoryForm' /* webpackChunkName: "inventory-form" */),
    loading: loader,
});
const UnitListFormatter = Loadable({
    loader: () => import('../../external/containers/unit/list/components/UnitListFormatters' /* webpackChunkName: "unit-list-formatter" */),
    loading,
});
const AutoSuggest = Loadable({
    loader: () => import('Commons/components/business/autosuggest/components/AutoSuggest' /* webpackChunkName: "autosuggest" */),
    loading,
});
const MoveIn = Loadable({
    loader: () => import('External/containers/createoccupancy/Wrapper' /* webpackChunkName: "movein" */),
    loading,
});

const MoveOut = Loadable({
    loader: () => import('../../external/containers/moveout/MoveOut' /* webpackChunkName: "moveout" */),
    loading,
});

const UserFormExt = Loadable({
    loader: () => import('../components/business/user/form/UserForm' /* webpackChunkName: "user-form-ext" */),
    loading,
});

const TenantFilter = Loadable({
    loader: () => import('../components/business/filterOverlay/components/FilterOverlay' /* webpackChunkName: "tenant-filters" */),
    loading,
});

const Payment = Loadable({
    loader: () => import('../components/business/payment/Payment' /* webpackChunkName: "payment" */),
    loading,
});

const UnitFilter = Loadable({
    loader: () => import('../components/business/filterOverlay/components/FilterOverlay' /* webpackChunkName: "unit-filters" */),
    loading,
});

const Configuration = Loadable({
    loader: () => import('External/containers/configuration/Configuration' /* webpackChunkName: "configuration" */),
    loading,
});

const FacilityGeneral = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/facilityGeneral/FacilityGeneral' /* webpackChunkName: "facility-general" */),
    loading,
});

const FinancialGeneral = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/FinancialGeneral' /* webpackChunkName: "financial-general" */),
    loading,
});

const FinancialExport = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/FinancialExport' /* webpackChunkName: "financial-export" */),
    loading,
});

const CardProcessor = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/cardprocessor/CardProcessor' /* webpackChunkName: "card-processor" */),
    loading,
});

const GateConfiguration = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/gates/GateConfiguration' /* webpackChunkName: "gates" */),
    loading,
});

const CustomerGeneral = Loadable({
    loader: () => import('External/containers/customerConfiguration/components/CustomerGeneral' /* webpackChunkName: "customer-general" */),
    loading,
});

const RentalGeneral = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/RentalGeneral' /* webpackChunkName: "rental-general" */),
    loading,
});

const InsuranceConfiguration = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/insurance/InsuranceConfig' /* webpackChunkName: "insurance" */),
    loading,
});

const UnitTypeForm = Loadable({
    loader: () => import('External/containers/unitConfiguration/components/UnitTypeForm' /* webpackChunkName: "unit-type-form" */),
    loading,
});

const LateEventForm = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/lateEvent/LateEventForm' /* webpackChunkName: "late-event-form" */),
    loading,
});

const MoveInConfiguration = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/MoveInConfiguration' /* webpackChunkName: "move-in-configuration" */),
    loading,
});

const MoveOutConfiguration = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/MoveOutConfiguration' /* webpackChunkName: "move-out-configuration" */),
    loading,
});

const ReservationConfiguration = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/ReservationConfiguration' /* webpackChunkName: "reservation-configuration" */),
    loading,
});

const PromoPlanForm = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/PromoPlan/PromoPlanForm' /* webpackChunkName: "promo-plan-configuration-form" */),
    loading,
});

const ChargeCategory = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/ChargeCategory' /* webpackChunkName: "charge-category" */),
    loading,
});

const RetailSaleConfiguration = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/RetailSale' /* webpackChunkName: "retail-sale" */),
    loading,
});

const BevColorSetup = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/bevColorSetup/BevColorSetup' /* webpackChunkName: "bev-color-setup" */),
    loading,
});

const MaintenanceEventForm = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/MaintenanceEventForm' /* webpackChunkName: "maintenance-event-form" */),
    loading,
});

const PaymentMethod = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/PaymentMethod' /* webpackChunkName: "payment-method" */),
    loading,
});

const TaxCode = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/TaxCode' /* webpackChunkName: "tax-code" */),
    loading,
});

const TaxExemptCategory = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/TaxExemptCategory' /* webpackChunkName: "tax-exempt-category" */),
    loading,
});

const PaymentConfiguration = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/PaymentConfiguration' /* webpackChunkName: "payment-configuration" */),
    loading,
});

const ChargeCategoryMapping = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/ChargeCategoryMapping' /* webpackChunkName: "charge-category-mapping" */),
    loading,
});
const TenantChangeTracking = Loadable({
    loader: () => import('External/containers/customerConfiguration/components/TenantChangeTracking' /* webpackChunkName: "tenant-change-tracking" */),
    loading,
});

const TenantAccountType = Loadable({
    loader: () => import('External/containers/customerConfiguration/components/TenantAccountType' /* webpackChunkName: "tenant-account-type" */),
    loading,
});

const LetterConfiguration = Loadable({
    loader: () => import('External/containers/customerengagement/components/LetterConfiguration' /* webpackChunkName: "letter-configuration" */),
    loading,
});

const ViolationConfiguration = Loadable({
    loader: () => import('External/containers/violation/components/ViolationConfiguration' /* webpackChunkName: "violation-configuration" */),
    loading,
});


const TenantSections = Loadable({
    loader: () => import('External/containers/customerConfiguration/components/TenantSections' /* webpackChunkName: "tenant-sections" */),
    loading,
});
const SearchableDropdown = Loadable({
    loader: () => import('Generic/dropdown/components/SearchableDropdown' /* webpackChunkName: "searchable-dropdown" */),
    loading,
});


const WaitingList = Loadable({
    loader: () => import('External/containers/waiting/list/components/WaitingList' /* webpackChunkName: "waiting-list" */),
    loading,
});

const ReservationAction = Loadable({
    loader: () => import('../../external/containers/reservation/list/config/Formatters' /* webpackChunkName: "reservation-action-formatter" */),
    render(loaded, props) {
        const Component = loaded.Action;
        return <Component {...props} />;
    },
    loading,
});

const waitingListAction = Loadable({
    loader: () => import('../../external/containers/waiting/list/config/Formatters' /* webpackChunkName: "waiting-list-action-formatter" */),
    render(loaded, props) {
        const Component = loaded.Action;
        return <Component {...props} />;
    },
    loading,
});
// NOTE: ManualChargesForm is temporary for financial List
const ManualChargesForm = Loadable({
    loader: () => import('../../external/containers/financials/form/components/ManualChargeForm' /* webpackChuckName: "manual-charge-form" */),
    loading,
});
// NOTE: ManualCharge is temporary for financial List
const ManualCharge = Loadable({
    loader: () => import('../../external/containers/financials/form/components/ManualCharge' /* webpackChunkName: "manual-charge" */),
    loading,
});

const UserProfile = Loadable({
    loader: () => import('Commons/components/business/userProfile/components/UserProfile.js' /* webpackChuckName: "user-profile" */),
    loading,
});

const Bev = Loadable({
    loader: () => import('Commons/components/business/bev/Bev' /* webpackChuckName: "bev" */),
    loading,
});

const PointOfSale = Loadable({
    loader: () => import('External/containers/pointOfSale/components/PointOfSale.js' /* webpackChuckName: "user-profile" */),
    loading,
});

const RetailSaleReturns = Loadable({
    loader: () => import('External/containers/retailSaleReturns/Returns.js' /* webpackChuckName: "retail-sale-returns" */),
    loading,
});

const Operations = Loadable({
    loader: () => import('External/containers/operations/components/Operations.js' /* webpackChuckName: "operations" */),
    loading,
});

const UserRole = Loadable({
    loader: () => import('../../external/containers/userrole/UserRole' /* webpackChunkName: "user-role" */),
    loading,
});

const ReportWorkCenter = Loadable({
    loader: () => import('External/containers/report/components/ReportWorkCenter' /* webpackChunkName: "report-wc" */),
    loading,
});

const InventoryList = Loadable({
    loader: () => import('External/containers/inventory/list/components/InventoryList' /* webpackChunkName: "inventory-list" */),
    loading: () => <div />,
});

const VendorList = Loadable({
    loader: () => import('External/containers/vendor/list/components/VendorList' /* webpackChunkName: "vendor-list" */),
    loading: () => <div />,
});

const InventoryBulkRestock = Loadable({
    loader: () => import('External/containers/inventory/restock/components/InventoryBulkRestock' /* webpackChunkName: "inventory-bulk-restock" */),
    loading: () => <div />,
});

const InventoryIndividualRestock = Loadable({
    loader: () => import('External/containers/inventory/restock/components/InventoryIndividualRestock' /* webpackChunkName: "inventory-individual-restock" */),
    loading: () => <div />,
});

const TenantInvalidAccessCodeList = Loadable({
    loader: () => import('External/containers/wrappedGateAccessCodeComponentsForPendingTasks/TenantInvalidAccessCodeList' /* webpackChunkName: "tenant-invalid-access-code-list" */),
    loading: () => <div />,
});

const UnitInvalidAccessCodeList = Loadable({
    loader: () => import('External/containers/wrappedGateAccessCodeComponentsForPendingTasks/UnitInvalidAccessCodeList' /* webpackChunkName: "unit-invalid-access-code-list" */),
    loading: () => <div />,
});

const ViewTenant = Loadable({
    loader: () => import('../../external/containers/viewtenant/ViewTenant' /* webpackChunkName: "view-tenant" */),
    loading: () => <div />,
});

const TenantAlerts = Loadable({
    loader: () => import('External/containers/viewtenant/components/crmcomponents/TenantAlerts' /* webpackChunkName: "tenant-alerts" */),
    loading: () => <div />,
});

const Documentation = Loadable({
    loader: () => import('External/containers/viewtenant/components/headerComponents/Documentation' /* webpackChunkName: "view-tenant" */),
    loading: () => <div />,
});

const UnpaidBills = Loadable({
    loader: () => import('External/containers/viewtenant/components/crmcomponents/UnpaidBills' /* webpackChunkName: "unpaid-bills" */),
    loading: () => <div />,
});

const ReservationCRM = Loadable({
    loader: () => import('External/containers/viewtenant/components/crmcomponents/Reservation' /* webpackChunkName: "reservation-crm" */),
    loading: () => <div />,
});

const ContactLog = Loadable({
    loader: () => import('External/containers/viewtenant/components/crmcomponents/ContactLog' /* webpackChunkName: "contact-log" */),
    loading: () => <div />,
});

const LedgerReview = Loadable({
    loader: () => import('../../external/containers/ledgerreview/LedgerReview' /* webpackChunkName: "ledger-review" */),
    loading: () => <div />,
});

const ArActions = Loadable({
    loader: () => import('../../external/containers/ledgerreview/formatters/Formatters' /* webpackChunkName: "ar-actions" */),
    render(loaded, props) {
        const Component = loaded.ArActions;
        return <Component {...props} />;
    },
    loading,
});

const AchOperations = Loadable({
    loader: () => import('External/containers/operations/components/achOperations/components/AchOperations' /* webpackChunkName: "ach-operations" */),
    loading: () => <div />,
});

const LetterUtil = Loadable({
    loader: () => import('External/containers/operations/components/letterUtil/components/LetterUtil' /* webpackChunkName: "letter-util" */),
    loading: () => <div />,
});

const ManualLateEvent = Loadable({
    loader: () => import('External/containers/operations/components/lateEvent/ManualLateEvent' /* webpackChunkName: "manual-late-event" */),
    loading: () => <div />,
});

const DepositLog = Loadable({
    loader: () => import('External/containers/operations/components/depositLog/DepositLog' /* webpackChunkName: "deposit-log" */),
    loading: () => <div />,
});

const DepositUpdate = Loadable({
    loader: () => import('External/containers/operations/components/depositUpdate/DepositUpdate' /* webpackChunkName: "deposit-update" */),
    loading: () => <div />,
});

const UnitDepositUpdate = Loadable({
    loader: () => import('External/containers/operations/components/depositUpdate/components/UnitDepositUpdate' /* webpackChunkName: "unit-deposit-update" */),
    loading: () => <div />,
});

const TenantDepositUpdate = Loadable({
    loader: () => import('External/containers/operations/components/depositUpdate/components/TenantDepositUpdate' /* webpackChunkName: "tenant-deposit-update" */),
    loading: () => <div />,
});

const TenantWebsites = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/tenantFacingWebsites/components/TenantFacingWebsites' /* webpackChunkName: "tenant-facing-website" */),
    loading,
});

const DeliveryMethodDropdown = Loadable({
    loader: () => import('Generic/deliveryMethodDropdown/components/DeliveryMethodDropdown' /* webpackChunkName: "delivery-method-config" */),
    loading: () => <div />,
});

const LeaseGeneral = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/lease/components/LeaseGeneral' /* webpackChunkName: "lease-general" */),
    loading: () => <div />,
});

const LeaseStyleForm = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/lease/components/LeaseStyleForm' /* webpackChunkName: "lease-style-form" */),
    loading: () => <div />,
});

const PendingTasks = Loadable({
    loader: () => import('External/containers/pendingTasks/PendingTasks' /* webpackChunkName: "pending-tasks" */),
    loading: () => <div />,
});

const ExternalDashboard = Loadable({
    loader: () => import('External/containers/externalDashboard/ExternalDashboard' /* webpackChunkName: "external-dashboard" */),
    loading: () => <div />,
});

const Docs = Loadable({
    loader: () => import('External/containers/documentation/Documentation' /* webpackChunkName: "docs" */),
    loading: () => <div />,
});
const PendingOverlockUnlockList = Loadable({
    loader: () => import('External/containers/pendingOverlockUnlock/list/components/PendingOverlockUnlockList' /* webpackChunkName: "pending-tasks" */),
    loading: () => <div />,
});

const WrappedInventoryAudit = Loadable({
    loader: () => import('External/containers/wrappedInventoryComponentsForPendingTasks/components/wrappedInventoryAudit/WrappedInventoryAudit' /* webpackChunkName: "pending-tasks" */),
    loading: () => <div />,
});

const WrappedInventoryBulkRestock = Loadable({
    loader: () => import('External/containers/wrappedInventoryComponentsForPendingTasks/components/WrappedInventoryBulkRestock/WrappedInventoryBulkRestock' /* webpackChunkName: "pending-tasks" */),
    loading: () => <div />,
});

const Invoicing = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/Invoicing' /* webpackChunkName: "invoicing-config" */),
    loading: () => <div />,
});

const Conversations = Loadable({
    loader: () => import('External/containers/conversation/Conversation' /* webpackChunkName: "conversations" */),
    loading: () => <div />,
});

const Ach = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/Ach' /* webpackChunkName: "ach-config" */),
    loading: () => <div />,
});

const TransferConfiguration = Loadable({
    loader: () => import('External/containers/rentalConfiguration/components/TransferConfiguration' /* webpackChunkName: "transfer-config" */),
    loading: () => <div />,
});

const AllBatches = Loadable({
    loader: () => import('External/containers/operations/components/achOperations/components/AllBatches' /* webpackChunkName: "all-batch" */),
    loading: () => <div />,
});

const CurrentBatch = Loadable({
    loader: () => import('External/containers/operations/components/achOperations/components/CurrentBatch' /* webpackChunkName: "current-batch" */),
    loading: () => <div />,
});

const TextList = Loadable({
    loader: () => import('External/containers/conversation/components/TextList' /* webpackChunkName: "text-list" */),
    loading: () => <div />,
});

const EmailList = Loadable({
    loader: () => import('External/containers/conversation/components/EmailList' /* webpackChunkName: "text-list" */),
    loading: () => <div />,
});

const TextOrEmailSelection = Loadable({
    loader: () => import('External/containers/conversation/components/TextOrEmailSelection' /* webpackChunkName: "text-or-email" */),
    loading: () => <div />,
});

const ListOrCompose = Loadable({
    loader: () => import('External/containers/conversation/components/ListOrCompose' /* webpackChunkName: "list-or-compose" */),
    loading: () => <div />,
});

const SyrasoftUserRoles = Loadable({
    loader: () => import('Internal/containers/syrasoftUserRoles/SyrasoftUserRoles' /* webpackChunkName: "syrasoft-user-roles" */),
    loading,
});

const AccountManagement = Loadable({
    loader: () => import('Internal/containers/accountManagement/AccountManagement' /* webpackChunkName: "account-management" */),
    loading: () => <div />,
});

const FacilityView = Loadable({
    loader: () => import('Internal/containers/accountManagement/components/facilityView/FacilityView' /* webpackChunkName: "facility-view" */),
    loading: () => <div />,
});

const UserSetup = Loadable({
    loader: () => import('Internal/containers/accountManagement/components/userSetup/UserSetup' /* webpackChunkName: "user-setup" */),
    loading: () => <div />,
});

const UserSetupAction = Loadable({
    loader: () => import('Internal/containers/accountManagement/components/userSetup/UserSetupAction' /* webpackChunkName: "user-edit" */),
    loading: () => <div />,
});

const FacilityList = Loadable({
    loader: () => import('Internal/containers/facility/list/components/FacilityList' /* webpackChunkName: "facility-list" */),
    loading: () => <div />,
});

const AccountList = Loadable({
    loader: () => import('Internal/containers/account/list/components/AccountList' /* webpackChunkName: "account-list" */),
    loading: () => <div />,
});

const UnitTransfer = Loadable({
    loader: () => import('External/containers/unitTransfer/UnitTransfer' /* webpackChunkName: "unit-transfer" */),
    loading,
});

const BusinessGroupManagement = Loadable({
    loader: () => import('Internal/containers/accountManagement/components/businessGroupManagement/BusinessGroupManagement' /* webpackChunkName: "business-group-management" */),
    loading: () => <div />,
});

const RentAdjustmentList = Loadable({
    loader: () => import('External/containers/operations/components/rentAdjustment/components/list/RentAdjustmentList' /* webpackChunkName: "rent-adjustment-list" */),
    loading: () => <div />,
});

const AutoRentAdjustment = Loadable({
    loader: () => import('External/containers/operations/components/rentAdjustment/components/auto/AutoRentAdjustment' /* webpackChunkName: "auto-rent-adjustment" */),
    loading,
});

const ViewRentAdjustment = Loadable({
    loader: () => import('External/containers/operations/components/rentAdjustment/components/view/ViewRentAdjustment' /* webpackChunkName: "rent-adjustment-view" */),
    loading,
});

const ManualRentAdjustment = Loadable({
    loader: () => import('External/containers/operations/components/rentAdjustment/components/manual/ManualRentAdjustment' /* webpackChunkName: "manual-rent-adjustment" */),
    loading,
});

const RateHistory = Loadable({
    loader: () => import('External/containers/unit/form/components/rateHistory/RateHistory' /* webpackChunkName: "rate-history" */),
    loading: () => <div />,
});

const LateEvents = Loadable({
    loader: () => import('External/containers/unit/form/components/lateEvents/LateEvents' /* webpackChunkName: "late-events" */),
    loading: () => <div />,
});

const RateHistoryAction = Loadable({
    loader: () => import('External/containers/unit/form/components/rateHistory/RateHistoryFormatters' /* webpackChunkName: "rate-history-action" */),
    render(loaded, props) {
        const Component = loaded.RateHistoryAction;
        return <Component {...props} />;
    },
    loading,
});

const InternalAppConfiguration = Loadable({
    loader: () => import('External/containers/internalappconfiguration/components/InternalApp' /* webpackChunkName: "internal-app-configuration" */),
    loading: () => <div />,
});

const AutomaticCreditCardDeclines = Loadable({
    loader: () => import('External/containers/automaticCreditCardDeclines/list/components/AutomaticCreditCardDeclines' /* webpackChunkName: "pending-tasks" */),
    loading: () => <div />,
});

const FailedReturns = Loadable({
    loader: () => import('External/containers/retailSaleReturns/components/FailedReturns' /* webpackChunkName: "failed-returns" */),
    loading: () => <div />,
});

const TenantChanges = Loadable({
    loader: () => import('External/containers/pendingTasks/components/externalActivity/TenantChanges/TenantChanges' /* webpackChunkName: "tenant-changes" */),
    loading: () => <div />,
});

const OtherExternalActivity = Loadable({
    loader: () => import('External/containers/pendingTasks//components/externalActivity/components/OtherExternalActivity/OtherExternalActivity' /* webpackChunkName: "other-external-activities" */),
    loading: () => <div />,
});

const TenantEmailChanges = Loadable({
    loader: () => import('External/containers/pendingTasks/components/externalActivity/TenantChanges/EmailOrPhoneList' /* webpackChunkName: "tenant-email-changes" */),
    loading: () => <div />,
});

const TenantDeposit = Loadable({
    loader: () => import('External/containers/viewtenant/components/crmcomponents/TenantDeposit' /* webpackChunkName: "tenant-deposit" */),
    loading: () => <div />,
});

const TenantPhoneChanges = Loadable({
    loader: () => import('External/containers/pendingTasks/components/externalActivity/TenantChanges/EmailOrPhoneList' /* webpackChunkName: "tenant-phone-changes" */),
    loading: () => <div />,
});

const TenantAddressChanges = Loadable({
    loader: () => import('External/containers/pendingTasks/components/externalActivity/TenantChanges/AddressOrAlternateContact' /* webpackChunkName: "tenant-address-changes" */),
    loading: () => <div />,
});

const TenantAlternateContactChanges = Loadable({
    loader: () => import('External/containers/pendingTasks/components/externalActivity/TenantChanges/AddressOrAlternateContact' /* webpackChunkName: "tenant-alternate-contact-changes" */),
    loading: () => <div />,
});

const GenerateLeaseAgreement = Loadable({
    loader: () => import('External/containers/generateLeaseAgreement/GenerateLeaseAgreement' /* webpackChunkName: "generate-lease-agreement" */),
    loading: () => <div />,
});

const Sandbox = Loadable({
    loader: () => import('Internal/containers/sandbox/Sandbox' /* webpackChunkName: "sandbox" */),
    loading: () => <div />,
});

const Onboarding = Loadable({
    loader: () => import('External/containers/onboarding/Onboarding' /* webpackChunkName: "onboarding-main" */),
    loading: () => <div />,
});

const FacilitySetup = Loadable({
    loader: () => import('External/containers/onboarding/components/facility/FacilitySetup' /* webpackChunkName: "facility-setup-onboarding" */),
    loading: () => <div />,
});

const OccupancySetup = Loadable({
    loader: () => import('External/containers/onboarding/components/occupancy/OccupancySetup' /* webpackChunkName: "occupancy-setup" */),
    loading: () => <div />,
});

const TaxCodeSetup = Loadable({
    loader: () => import('External/containers/onboarding/components/taxCodeSetup/TaxCodeSetup' /* webpackChunkName: "tax-code-setup" */),
    loading: () => <div />,
});

const Quickbooks = Loadable({
    loader: () => import('External/containers/financialConfiguration/components/Quickbooks' /* webpackChunkName: "quickbooks-config" */),
    loading: () => <div />,
});

const MigrationList = Loadable({
    loader: () => import('Internal/containers/migration/List/components/MigrationList' /* webpackChunkName: "migration-list" */),
    loading: () => <div />,
});

const MigrationForm = Loadable({
    loader: () => import('Internal/containers/migration/form/components/MigrationForm' /* webpackChunkName: "migration-form" */),
    loading: () => <div />,
});

const MigrationServerModal = Loadable({
    loader: () => import('Internal/containers/migration/components/MigrationServerModal' /* webpackChunkName: "migration-server-modal" */),
    loading: () => <div />,
});

const MigrationDetails = Loadable({
    loader: () => import('Internal/containers/migration/List/components/MigrationDetails' /* webpackChunkName: "migration-details" */),
    loading: () => <div />,
});
const AccountFacilityForm = Loadable({
    loader: () => import('Internal/containers/facility/form/AccountFacilityForm' /* webpackChunkName: "account-facility-form" */),
    loading: () => <div />,
});

const VehicleConfig = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/vehicles/VehicleConfig' /* webpackChunkName: "vehicle-config" */),
    loading: () => <div />,
});

const VehicleList = Loadable({
    loader: () => import('External/containers/vehicle/list/components/VehicleList' /* webpackChunkName: "vehicle-list" */),
    loading: () => <div />,
});

const WalkAroundConfig = Loadable({
    loader: () => import('External/containers/facilityConfiguration/components/walkAround/WalkAroundConfig' /* webpackChunkName: "walk-around-config" */),
    loading: () => <div />,
});

const WalkAround = Loadable({
    loader: () => import('External/containers/walkAround/components/WalkAround' /* webpackChunkName: "walk-around" */),
    loading: () => <div />,
});

const BatchPayment = Loadable({
    loader: () => import('External/containers/operations/components/batchPayment/components/BatchPayment' /* webpackChunkName: "batch-payment" */),
    loading: () => <div />,
});

const FinancialExportOperation = Loadable({
    loader: () => import('External/containers/operations/components/financialExport/components/FinancialExportOperation' /* webpackChunkName: "financial-export-operation" */),
    loading: () => <div />,
});

const CreditCardBatches = Loadable({
    loader: () => import('External/containers/operations/components/batchPayment/components/CreditCardBatches' /* webpackChunkName: "credit-card-batches" */),
    loading: () => <div />,
});

const CCBatchDetails = Loadable({
    loader: () => import('External/containers/operations/components/batchPayment/components/CCBatchDetails' /* webpackChunkName: "batch-details" */),
    loading: () => <div />,
});

const CCBatchForm = Loadable({
    loader: () => import('External/containers/operations/components/batchPayment/components/CCBatchForm' /* webpackChunkName: "create-credit-card-batch" */),
    loading: () => <div />,
});

const LetterTemplateList = Loadable({
    loader: () => import('External/containers/customerengagement/components/LetterTemplateList' /* webpackChunkName: "letter-template-list" */),
    loading: () => <div />,
});

const ReservationList = Loadable({
    loader: () => import('External/containers/reservation/list/components/ReservationList' /* webpackChunkName: "reservation-list" */),
    loading: () => <div />,
});

const UnitTypeBulk = Loadable({
    loader: () => import('External/containers/unitConfiguration/components/UnitTypeBulk' /* webpackChunkName: "unit-type-bulk" */),
    loading: () => <div />,
});

const LeaseStyleBulk = Loadable({
    loader: () => import('External/containers/unitConfiguration/components/LeaseStyleBulk' /* webpackChunkName: "lease-style-bulk" */),
    loading: () => <div />,
});

const SingleUnitRentAdjustment = Loadable({
    loader: () => import('External/containers/operations/components/rentAdjustment/components/singleUnit/components/SingleUnitRentAdjustment' /* webpackChunkName: "single-unit-rent-adjustment" */),
    loading: () => <div />,
});

const DelinquencyTracking = Loadable({
    loader: () => import('Internal/containers/delinquencyTracking/list/components/DelinquencyTracking' /* webpackChunkName: "delinquency-tracking" */),
    loading: () => <div />,
});

const Journal = Loadable({
    loader: () => import('Internal/containers/journal/list/components/Journal' /* webpackChunkName: "journal" */),
    loading: () => <div />,
});

const PrintFailuresList = Loadable({
    loader: () => import('External/containers/printFailures/PrintFailuresList' /* webpackChunkName: "print-failures" */),
    loading: () => <div />,
});

const PendingInvoiceDelivery = Loadable({
    loader: () => import('External/containers/pendinginvoicedelivery/PendindInvoiceDelivery' /* webpackChunkName: "pending-invoice-delivery" */),
    loading: () => <div />,
});

const DelinquencyList = Loadable({
    loader: () => import('../../external/containers/delinquency/list/components/DelinquencyList' /* webpackChunkName: "delinquency-list" */),
    loading: () => <div />,
});

const CCAboutToExpireList = Loadable({
    loader: () => import('External/containers/ccAboutToExpire/CCAboutToExpireList' /* webpackChunkName: "cc-about-to-expire-list" */),
    loading: () => <div />,
});

const SchedulerCreation = Loadable({
    loader: () => import('Internal/containers/delinquencyTracking/list/components/SchedulerCreation' /* webpackChunkName: "scheduler-creation" */),
    loading: () => <div />,
});

const EmailAudit = Loadable({
    loader: () => import('External/containers/conversation/EmailAudit' /* webpackChunkName: "scheduler-creation" */),
    loading: () => <div />,
});

export {
    ManualChargesForm, ManualCharge, Header, HomePage, Dashboard, Main, SmartList,
    TenantForm, Logout, UnitForm, LeadForm, HeaderInt, MainInt, FacilityForm, AccountForm, UserForm,
    SideBar, UnitListFormatter, AutoSuggest, MoveIn, MoveOut, TenantFilter, Payment, UnitFilter,
    MainSettings, Configuration, FacilityGeneral, FinancialGeneral, CustomerGeneral, UserList,
    RentalGeneral, TenantChangeTracking, TenantAccountType, LetterConfiguration, TenantSections,
    UnitTypeForm, MoveInConfiguration, MoveOutConfiguration, ReservationConfiguration, ChargeCategory,
    PaymentMethod, TaxCode, TaxExemptCategory, PaymentConfiguration, ChargeCategoryMapping, UserFormExt,
    SearchableDropdown, UserRole, UserProfile, ReservationAction, waitingListAction, VendorForm, Bev, InventoryForm, InventoryAudit,
    PointOfSale, ReportWorkCenter, InventoryList, InventoryBulkRestock, InventoryIndividualRestock, VendorList,
    ViewTenant, PromoPlanForm, TenantAlerts, UnpaidBills, ReservationCRM, ContactLog, RetailSaleConfiguration,
    LedgerReview, ArActions, Operations, LateEventForm, BevColorSetup, MaintenanceEventForm, ManualLateEvent,
    DeliveryMethodDropdown, CardProcessor, DailySummary, ScheduleList, TenantWebsites, AsyncTaskList,
    LeaseGeneral, LeaseStyleForm, PendingTasks, Docs, PendingOverlockUnlockList, WrappedInventoryAudit,
    WrappedInventoryBulkRestock, Invoicing, GateConfiguration, Ach, Conversations, TransferConfiguration,
    AchOperations, AllBatches, CurrentBatch, AccountManagement, UnitTransfer, FacilityList, AccountList, TextList,
    TextOrEmailSelection, ListOrCompose, BusinessGroupManagement, SyrasoftUserRoles, EmailList, Documentation,
    RentAdjustmentList, AutoRentAdjustment, ManualRentAdjustment, ViewRentAdjustment, InternalAppConfiguration,
    RateHistoryAction, RateHistory, LateEvents, AutomaticCreditCardDeclines, RetailSaleReturns, InsuranceConfiguration,
    FailedReturns, TenantChanges, TenantEmailChanges, TenantAddressChanges, TenantAlternateContactChanges,
    TenantPhoneChanges, TenantDeposit, RentAssessmentHistory, AutoPaymentHistory, TaskSummary, OtherExternalActivity,
    AutoInvoicingHistory, RentAdjustmentSummary, DelinquencySummary, LetterUtil, SavedDocumentsList,
    GenerateLeaseAgreement, FacilitySetup, TaxCodeSetup, Onboarding, OccupancySetup, Sandbox, FacilityView, UserSetup, UserSetupAction,
    DepositLog, Quickbooks, AccountFacilityForm, MigrationList, MigrationForm, MigrationServerModal, MigrationDetails, VehicleConfig,
    ExternalDashboard, VehicleList, BatchPayment, CreditCardBatches, CCBatchDetails, CCBatchForm, LetterTemplateList, TenantList,
    ReservationList, UnitTypeBulk, LeaseStyleBulk, SingleUnitRentAdjustment, PrintFailuresList, DelinquencyList, DelinquencyTracking,
    CCAboutToExpireList, WalkAroundConfig, WalkAround, DelinquencyOverview, LateEventsTracking, LockoutEventsTracking,
    ScheduledEventsTracking, WrongEntriesTracking, SchedulerCreation,
    FinancialExport, FinancialExportOperation, PendingInvoiceDelivery, EmailAudit, WaitingList,
    Journal, JournalConfigurationChanges, JournalLockoutEvents, JournalUserRoles, JournalUserManagements, JournalLateEvents,
    TenantInvalidAccessCodeList, UnitInvalidAccessCodeList, ViolationConfiguration, DepositUpdate, UnitDepositUpdate, TenantDepositUpdate,
};
