import { createEnumMap } from 'Commons/helpers/utils/Formatter';
import commonEnums from 'Commons/config/constants/Enums';

const ITEM = createEnumMap(['unit', 'comment', 'balance', 'tenant_name', 'ledger_name']);
const MODIFIER_TYPE = createEnumMap(['No_Modifier', 'Discount', 'Surcharge']);
const PAYMENT_METHOD = createEnumMap(['Credit_Card', 'Debit_Card', 'Cash', 'Check', 'Ach', 'Money_Order', 'Other']);
const CHARGE_STYLE = createEnumMap(commonEnums.chargeStyles);
const REDUCER_ACTION = createEnumMap(['On_Generate_Bills', 'On_Get_Bills_Success', 'Pre_Get_Bills',
    'On_Get_Bills_Error', 'On_Period_Toggle']);
const PAYMENT_ACTION = createEnumMap(['Payment', 'Cancel_Transaction', 'Get_Transaction_Status',
    'Pay_Validated_Amount']);

const BILLS_TABLE_LEFT_AND_RIGHT_PADDING = 96;
const BILLS_TABLE_TOP_AND_BOTTOM_PADDING = {
    desktop: 436,
    laptop: 330,
};
const BILLS_TABLE_MIN_HEIGHT = 296;
const BILLS_TABLE_RESPONSIVE_HEIGHT = 300;
const BILLS_TABLE_RESPONSIVE_WIDTH = 1024;

const FAILED_CVV_CODES = ['N', 'S'];
const FAILED_AVS_CODES = ['A', 'B', 'C', 'E', 'G', 'I', 'P', 'N', 'S', 'T', 'U', 'W', 'Z'];
const HOSTED_FORM_ID = 'payment-form';
const TRANSACTION_ID = 'TransactionId';
const ACCOUNT_TOKEN = 'AccountToken';
const HOSTED_FORM_DATA = 'fullsteam-hosted-card-data';
const HOSTED_PAYMENTS_RESPONSE = 'HostedPaymentsResponse';
const AUTHENTICATION_KEY = 'AuthenticationKey';

const keyFormatter = key => key[0] + key.substring(1).replace(/([A-Z])/g, '_$1').toUpperCase();
const HOSTED_FORM_AUTH_TYPE = createEnumMap(['Payment', 'Token', 'Merchant_Portal']);
const HOSTED_FORM_OPERATION = createEnumMap(['Payment', 'Authorization', 'Token']);
const HOSTED_FORM_PAYMENT_SOURCE_TYPE = createEnumMap(['Card']);
const HOSTED_FORM_CARD_ENTRY_CONTEXT = createEnumMap(['WebMerchantEnteredPhoneOrder'], keyFormatter);
const ERROR_TYPE = createEnumMap(['PREPAY', 'DELINQUENT_TENANT', 'OTHER_ERROR']);

export {
    ITEM, MODIFIER_TYPE, PAYMENT_METHOD, CHARGE_STYLE, REDUCER_ACTION, BILLS_TABLE_LEFT_AND_RIGHT_PADDING,
    BILLS_TABLE_MIN_HEIGHT, BILLS_TABLE_TOP_AND_BOTTOM_PADDING, BILLS_TABLE_RESPONSIVE_HEIGHT, HOSTED_FORM_ID,
    BILLS_TABLE_RESPONSIVE_WIDTH, PAYMENT_ACTION, FAILED_AVS_CODES, FAILED_CVV_CODES,
    TRANSACTION_ID, ACCOUNT_TOKEN, HOSTED_PAYMENTS_RESPONSE, HOSTED_FORM_OPERATION, HOSTED_FORM_PAYMENT_SOURCE_TYPE,
    HOSTED_FORM_CARD_ENTRY_CONTEXT, HOSTED_FORM_AUTH_TYPE, AUTHENTICATION_KEY, HOSTED_FORM_DATA, ERROR_TYPE,
};
