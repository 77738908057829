import media from 'Commons/theme/MediaQueries';

const triangleStyle = theme => ({
    triangle: {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        [media.mobileUp]: { borderWidth: '0 120px 50px 0' },
        [media.tabletUp]: { borderWidth: '0 150px 70px 0' },
    },
    triangleContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    title: {
        position: 'absolute',
        [media.mobileUp]: { right: theme.spacing(1), top: theme.spacing(1) },
        [media.tabletUp]: { right: theme.spacing(1.5) },
        color: theme.palette.common.white,
    },
    statusActive: { borderColor: `transparent ${theme.palette.status.active} transparent transparent` },
    statusInactive: { borderColor: `transparent ${theme.palette.status.inactive} transparent transparent` },
    statusLocked: { borderColor: `transparent ${theme.palette.status.locked} transparent transparent` },
    statusUnavailable: { borderColor: `transparent ${theme.palette.rentalStatus.unavailable} transparent transparent` },

    statusLeadActive: { borderColor: `transparent ${theme.palette.primary.main} transparent transparent` },
    statusLeadCold: { borderColor: `transparent ${theme.palette.chip.estimate} transparent transparent` },
    statusLeadLost: { borderColor: `transparent ${theme.palette.status.leadLost} transparent transparent` },
    statusLeadConverted: { borderColor: `transparent ${theme.palette.status.active} transparent transparent` },
    statusLeadDuplicate: { borderColor: `transparent ${theme.palette.status.leadDuplicate} transparent transparent` },
});

export default triangleStyle;
