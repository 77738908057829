const configurationBgPermissions = [
    'permission.businessGroup.configurations.deactivateConfig',
    'permission.businessGroup.configurations.createEdit.access',
    'permission.businessGroup.configurations.createEdit.financialGrouping',
    'permission.businessGroup.configurations.createEdit.occupancy',
    'permission.businessGroup.configurations.createEdit.delinquency',
    'permission.businessGroup.configurations.createEdit.letter',
];

const configurationFacilityPermissions = [
    'permission.facility.configurations.deactivateConfig',
    'permission.facility.configurations.createEdit.access',
    'permission.facility.configurations.createEdit.financials',
    'permission.facility.configurations.createEdit.financialGrouping',
    'permission.facility.configurations.createEdit.unitType',
    'permission.facility.configurations.createEdit.tenantFacingWebsite',
    'permission.facility.configurations.createEdit.customer',
    'permission.facility.configurations.createEdit.occupancy',
    'permission.facility.configurations.createEdit.delinquency',
    'permission.facility.configurations.createEdit.letter',
];

const financialsPaymentPermissions = [
    'permission.facility.financials.payment.editEffectiveDate',
    'permission.facility.financials.payment.reverse',
    'permission.facility.financials.payment.delete',
];

const financialsManualChargePemissions = [
    'permission.facility.financials.charge.manualCharge.negativeAmount',
    'permission.facility.financials.charge.manualCharge.secureCategory',
];

const financialsReverseAccessPermissions = [
    'permission.facility.financials.charge.reverse.secureCategory',
];

const financialsDeleteAccessPermissions = [
    'permission.facility.financials.charge.delete.secureCategory',
];

const occupancyMoveInPermissions = [
    'permission.facility.occupancy.moveIn.editMoveInDate',
    'permission.facility.occupancy.moveIn.unitRateUp',
    'permission.facility.occupancy.moveIn.unitRateDown',
    'permission.facility.occupancy.moveIn.editSetupFee',
    'permission.facility.occupancy.moveIn.waiveSetupFee',
    'permission.facility.occupancy.moveIn.editDeposits',
    'permission.facility.occupancy.moveIn.waiveDeposits',
    'permission.facility.occupancy.moveIn.waiveProrate',
    'permission.facility.occupancy.moveIn.billToDate',
    'permission.facility.occupancy.moveIn.nonStandardPeriods',
];

const occupancyMoveOutPermissions = [
    'permission.facility.occupancy.moveOut.editMoveOutDate',
    'permission.facility.occupancy.moveOut.waiveNotificationPenalty',
    'permission.facility.occupancy.moveOut.overrideResolveCharges',
];

const occupancyReservationPermissions = [
    'permission.facility.reservation.waiveDeposit',
];

const retailSaleSellInventoryPermissions = [
    'permission.facility.retailSale.negativeSale',
    'permission.facility.retailSale.addInventoryAtPos',
];

const retailSaleInventoryAuditPermissions = [
    'permission.facility.retailSale.negativeInventory',
];

const unitGeneralPermissions = [
    'permission.facility.unit.edit.currentRate',
    'permission.facility.unit.edit.nextRentAssessmentDate',
    'permission.facility.unit.edit.paidToDate',
    'permission.facility.unit.edit.unitType',
    'permission.facility.unit.edit.inventoryReport',
    'permission.facility.unit.edit.moveInDate',
    'permission.facility.unit.edit.rentalStatus',
];

export {
    configurationBgPermissions, configurationFacilityPermissions, financialsPaymentPermissions, financialsManualChargePemissions,
    financialsReverseAccessPermissions, financialsDeleteAccessPermissions, occupancyMoveInPermissions, occupancyMoveOutPermissions,
    occupancyReservationPermissions, retailSaleSellInventoryPermissions, retailSaleInventoryAuditPermissions, unitGeneralPermissions,
};
