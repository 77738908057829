import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { STATUS } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo, useMemo, useState } from 'react';
import { DROPDOWN_MAX_HEIGHT } from 'External/containers/configuration/config/Constants';
import { getOptionProps, optionsFormatter } from 'Commons/helpers/utils/SDFormatter';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import { Checkbox } from 'Generic/checkbox/components/CheckBox';
import useDidUpdateEffect from 'Commons/helpers/hooks/useDidUpdateEffect';
import { isNonEmptyArray } from 'Commons/helpers/utils/DataHelpers';
import { RENTAL_STATUS, AVAILABILITY_STATUS, INVENTORY_REPORT_STATUS } from '../config/Constants';
import { getUnitTypeApiConfig } from '../config/ApiRequests';
import UnitFilterStyle from '../styles/UnitFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';


const useStyles = makeStyles(UnitFilterStyle, { name: 'UnitFilterStyle' });

const UnitFilter = ({
    entityId = '', isLiveData,
    formProps: {
        setFieldValue,
        values: {
            terms: {
                unitType: { id: unitTypeIds = [] } = {},
                rentalStatus = [], availabilityStatus = [],
            } = {},
        } = {},
    } = {},
}) => {
    const classes = useStyles();
    const unitTypeApiConfig = useMemo(() => getUnitTypeApiConfig(entityId), []);
    const acceptableAvailableStatus = useMemo(() => [AVAILABILITY_STATUS.Available.value,
        AVAILABILITY_STATUS.Unavailable_On_Move_Out.value], []);
    const [isAvailable, setIsAvailable] = useState(availabilityStatus.includes(AVAILABILITY_STATUS.Available.value));
    const [isUnavailable, setIsUnavailable] = useState(availabilityStatus.includes(AVAILABILITY_STATUS.Unavailable.value));
    const onSelectOrRemoveUnitType = (selectedUnitTypes) => {
        setFieldValue('terms.unitType.id', Object.keys(selectedUnitTypes));
    };

    useDidUpdateEffect(() => {
        // For clearing availability status
        if (!isNonEmptyArray(availabilityStatus)) {
            setIsAvailable(false);
            setIsUnavailable(false);
        }
    }, [availabilityStatus]);

    const handleRentalStatusChange = (_, value) => {
        if (value.includes(RENTAL_STATUS.Rented.value)) {
            setFieldValue('terms.availabilityStatus', acceptableAvailableStatus);
            setIsUnavailable(false);
            setIsAvailable(true);
        }
    };

    const handleAvailableCheckboxChange = (_, value) => {
        setFieldValue('terms.availabilityStatus',
            value
                ? [...availabilityStatus, ...acceptableAvailableStatus]
                : availabilityStatus.filter(status => !acceptableAvailableStatus.includes(status)));
        setIsAvailable(value);
    };

    const handleUnavailableCheckboxChange = (_, value) => {
        setFieldValue('terms.availabilityStatus',
            value
                ? [...availabilityStatus, AVAILABILITY_STATUS.Unavailable.value]
                : availabilityStatus.filter(status => status !== AVAILABILITY_STATUS.Unavailable.value));
        setIsUnavailable(value);
    };

    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={[STATUS.Active, STATUS.Inactive]}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.rentalStatus}
            </Typography>
            <FormCheckboxGroup
                name="terms.rentalStatus"
                list={Object.values(RENTAL_STATUS)}
                row
                noGrid
                noIndicatorWrapper
                onChange={handleRentalStatusChange}
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.availabilityStatus}
            </Typography>
            <Grid container>
                <Checkbox
                    value={isAvailable}
                    trackValue={false}
                    label={localisable.available}
                    onChange={handleAvailableCheckboxChange}
                    disabled={rentalStatus.includes(RENTAL_STATUS.Rented.value)}
                />
                <Checkbox
                    value={isUnavailable}
                    trackValue={false}
                    label={localisable.unavailable}
                    onChange={handleUnavailableCheckboxChange}
                    disabled={rentalStatus.includes(RENTAL_STATUS.Rented.value)}
                />
            </Grid>
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.inventoryReport}
            </Typography>
            <FormCheckboxGroup
                name="terms.additionalInfo.includeInRentableInventoryCount"
                list={INVENTORY_REPORT_STATUS}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.unitType}
            </Typography>
            <MultiSelect
                placeholder={localisable.selectUnitType}
                onSelect={onSelectOrRemoveUnitType}
                getOptionProps={getOptionProps}
                apiConfig={unitTypeApiConfig}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                autoSuggestKey="matchPhrasePrefix.value.description"
                optionFormatter={optionsFormatter}
                onRemove={onSelectOrRemoveUnitType}
                initiallySelectedValues={unitTypeIds}
            />
            {
                isLiveData && (
                    <>
                        <RangeFilter
                            name="nextRentAssessmentDate"
                            setFieldValue={setFieldValue}
                            label={localisable.nextRentAssessmentDate}
                            isDateRange
                        />
                        <RangeFilter
                            name="paidToDate"
                            setFieldValue={setFieldValue}
                            label={localisable.paidToDate}
                            isDateRange
                        />
                    </>
                )
            }
        </>
    );
};

UnitFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
    isLiveData: PropTypes.bool,
};

UnitFilter.defaultProps = { entityId: '' };

export default memo(UnitFilter);
