const radioGroupStyle = theme => ({
    preferred: { color: theme.palette.primary.main },
    required: { color: theme.palette.error.main },
    radioButtonGroup: {},
    paddingTop: { paddingTop: 12 },
    root: { fontSize: 12 },
    formControlLabelRoot: {},
    readOnly: {
        color: theme.palette.text.disabled,
        pointerEvents: 'none',
    },
    InheritedConfigLabel: { pointerEvents: 'none' },
});

export default radioGroupStyle;
