import { convertToNumber, removeWhiteSpaceAndSpecialChars } from '../Utils';

const MIN_DIGITS_OF_A_CARD = 13;

const isNumberHavingMoreThanOneDigit = number => (number > 9);

// Luhn's algorithm,
const validateCardNumber = (cardNumber) => {
    const trimmedCardNumber = removeWhiteSpaceAndSpecialChars(cardNumber);
    if (convertToNumber(trimmedCardNumber)) {
        const cardNumberLength = trimmedCardNumber.length;
        if (cardNumberLength < MIN_DIGITS_OF_A_CARD) {
            return false;
        }
        const isCardLengthEven = cardNumberLength % 2 === 0;
        let sum = 0;
        for (let cardDigitIndex = cardNumberLength - 1; cardDigitIndex >= 0; cardDigitIndex -= 1) {
            let cardDigit = convertToNumber(trimmedCardNumber.charAt(cardDigitIndex));
            if (isCardLengthEven ? (cardDigitIndex % 2 !== 0) : (cardDigitIndex % 2 === 0)) {
                sum += cardDigit;
            } else {
                cardDigit *= 2;
                if (isNumberHavingMoreThanOneDigit(cardDigit)) {
                    sum += (Math.floor(cardDigit / 10) + (cardDigit % 10));
                } else {
                    // Duplicate code to improve readability
                    sum += cardDigit;
                }
            }
        }
        return sum % 10 === 0;
    }
    return false;
};

export default validateCardNumber;

export { validateCardNumber };
