import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { FACILITY_LICENSE_STATUS } from 'Commons/config/constants/Constants';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { SOFTWARE_ADOPTION_STATUS } from 'Internal/containers/facility/form/Constants';
import FacilityFilterStyle from '../styles/facilityFilterStyle';

const useStyles = makeStyles(FacilityFilterStyle, { name: 'FacilityFilterStyle' });

const FacilityFilter = () => {
    const classes = useStyles();

    return (
        <>
        <Typography
            variant="subtitle1"
            className={classes.firstLabel}
        >
            {localisable.softwareAdoptionStatusHeading}
        </Typography>
        <FormCheckboxGroup
            name="terms.metaInfo_SoftwareAdoptionStatus"
            list={Object.values(SOFTWARE_ADOPTION_STATUS)}
            noGrid
            noIndicatorWrapper
        />
            <Typography variant="subtitle1" className={classes.restLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.licenseStatus"
                list={Object.values(FACILITY_LICENSE_STATUS)}
                row
                noGrid
                noIndicatorWrapper
            />
        </>
    );
};

export default memo(FacilityFilter);
