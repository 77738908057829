import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Icon from 'Generic/icon/components/Icon';
import Typography from 'Generic/typography/components/Typography';
import { useDropzone } from 'react-dropzone';
import dragAndDropStyles from '../styles/DragAndDropStyles';

const useStyles = makeStyles(dragAndDropStyles, { name: 'DragAndDrop' });

const DragAndDrop = ({ accept, onDrop, subtitle }) => {
    const classes = useStyles();
    const { getRootProps, getInputProps } = useDropzone({ accept, onDrop });
    const { onClick, ...rootProps } = getRootProps({ className: classes.dragOrCreateContainer });

    return (
        <Grid
            item
            container
            {...rootProps}
        >
            <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                className={classes.dragOrCreate}
            >
                <Icon
                    type="custom"
                    color="primary"
                    icon="cp-local-drive"
                    className={classes.localDriveIcon}
                />
                <input className="input-zone" {...getInputProps()} />
                <Grid container className={classes.autoWidth}>
                    <Typography
                        role="button"
                        color="primary"
                        onClick={onClick}
                        className={classes.chooseAFile}
                    >
                        {localisable.chooseAFile}
                    </Typography>
                        &nbsp;
                    <Typography>{localisable.orDragItHere}</Typography>
                </Grid>
                <Typography
                    variant="body2"
                    fontStyle="italic"
                    className={classes.acceptedFormatText}
                >
                    {subtitle}
                </Typography>
            </Grid>
        </Grid>
    );
};


DragAndDrop.propTypes = {
    onDrop: PropTypes.func,
    accept: PropTypes.any,
    subtitle: PropTypes.string,
};

export default React.memo(DragAndDrop);
