import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { DROPDOWN_MAX_HEIGHT } from 'External/containers/configuration/config/Constants';
import { getOptionProps } from 'Commons/helpers/utils/SDFormatter';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import { GATE_VENDOR } from 'External/containers/facilityConfiguration/config/GateConfig';
import { labelFormatter } from 'Commons/helpers/utils/Formatter';
import { EMPTY_LIST } from 'Commons/config/constants/Constants';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { FACILITY_TYPE } from '../config/Constants';
import DelinquencyTrackingFilterStyle from '../styles/delinquencyTrackingOverviewFilterStyle';


const useStyles = makeStyles(DelinquencyTrackingFilterStyle, { name: 'DelinquencyTrackingFilterStyle' });

const DelinquencyTrackingOverviewFilter = ({
    formProps: {
        setFieldValue,
        values: { terms: { gate: { id: gateIds = EMPTY_LIST } = {} } = {} } = {},
    } = {},
}) => {
    const classes = useStyles();
    const gateConfigList = useConstructor(() => {
        const configs = [];
        Object.values(GATE_VENDOR).forEach((value = {}, id) => {
            const { value: vendor } = value || {};
            if (vendor !== 'NONE') configs.push({ id, ...value });
        });
        return configs;
    });

    const onSelectOrRemoveGateType = (selectedGates) => {
        const selectedGateValues = Object.values(selectedGates).map(({ value }) => value);
        setFieldValue('terms.gateVendors', selectedGateValues);
    };

    return (
        <>
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.facilityType}
            </Typography>
            <FormCheckboxGroup
                name="terms.anniversary"
                list={FACILITY_TYPE}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.gates}
            </Typography>
            <MultiSelect
                list={gateConfigList}
                placeholder={localisable.selectGateVendors}
                onSelect={onSelectOrRemoveGateType}
                getOptionProps={getOptionProps}
                keyToMatch="id"
                inputValueFormatter={labelFormatter}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                autoSuggestKey="matchPhrasePrefix.value"
                onRemove={onSelectOrRemoveGateType}
                initiallySelectedValues={gateIds}
            />
        </>
    );
};

DelinquencyTrackingOverviewFilter.propTypes = { formProps: PropTypes.object };

export default memo(DelinquencyTrackingOverviewFilter);
