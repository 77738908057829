import React from 'react';
import { Form } from 'formik';
import getUUID from 'Commons/helpers/utils/generateUUID';
import { clsx } from 'Commons/helpers/utils/clsx';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import { withStyles } from '../../componentlibrary/components/Components';
import FormikFormWrapperStyle from '../styles/FormikFormWrapperStyle';

const FormikFormWrapper = (props) => {
    const {
        children,
        classes,
        formId,
        onSubmit,
        fullWidth,
        fullHeight,
        validateForm,
        className,
    } = props;

    const validateAndSubmitForm = (eventId) => {
        if (eventId === formId) {
            onSubmit();
        }
    };
    return (
        <Form
            id={formId}
            onSubmit={(e) => {
                if (validateForm) {
                    validateAndSubmitForm(e.target.id);
                } else {
                    onSubmit();
                }
            }}
            className={clsx(`${classes.form}`,
                fullWidth && `${classes.fullWidth}`,
                fullHeight && `${classes.fullHeight}`, className)}
        >
            {children}
        </Form>
    );
};

FormikFormWrapper.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    classes: PropTypes.object,
    formId: PropTypes.string,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,
    validateForm: PropTypes.bool,
    className: PropTypes.string,
};

FormikFormWrapper.defaultProps = {
    formId: getUUID(),
    fullWidth: false,
    fullHeight: false,
    validateForm: true,
    onSubmit: EMPTY_FUNC,
};


export default withStyles(FormikFormWrapperStyle)(FormikFormWrapper);
