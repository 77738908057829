import { PureComponent } from 'react';
import { capitalize } from 'Commons/helpers/utils/DataHelpers';
import { clsx } from 'Commons/helpers/utils/clsx';
import Icon from '../../icon/components/Icon';
import { Button as MaterialUiButton, IconButton, withStyles } from '../../componentlibrary/components/Components';
import buttonStyle from '../styles/ButtonStyle';

class Button extends PureComponent {
    renderButton(variant) {
        if (['text', 'fab', 'contained', 'outlined'].includes(variant)) {
            return this.renderBasicButton();
        }
        return this[`render${capitalize(variant)}Button`]();
    }

    renderBasicButton() {
        const {
            icon, className, children, classes, light, color: buttonColor = 'secondary',
            forwardedRef, variant, ...buttonProps
        } = this.props;
        const color = buttonColor !== 'error' ? buttonColor : 'inherit';
        const basicClassName = clsx(className, classes.button, classes[buttonColor], light && classes.light,
            buttonColor === 'error' && variant === 'text' && classes.backgroundForErrorText);
        return (
            <MaterialUiButton
                ref={forwardedRef}
                color={color}
                variant={variant}
                {...buttonProps}
                className={basicClassName}
                classes={{ disabled: classes.disabled }}
            >
                <>
                    { !(buttonProps.variant === 'fab') && children }
                    { icon && <Icon>{icon}</Icon> }
                </>
            </MaterialUiButton>
        );
    }

    renderIconButton() {
        const {
            icon, className, classes, IconProps, light, iconType,
            color: iconColor, forwardedRef, onClick, ...buttonProps
        } = this.props;
        let color = iconColor !== 'textPrimary' ? iconColor : 'primary';
        if (onClick && !color) {
            color = 'primary';
        }
        const iconClassName = clsx(className, classes[iconColor]);
        return (
            <IconButton
                ref={forwardedRef}
                color={color}
                onClick={onClick}
                {...buttonProps}
                className={iconClassName}
            >
                {icon && <Icon type={iconType} {...IconProps} icon={icon} />}
            </IconButton>
        );
    }

    renderUploadButton() {
        const {
            icon, accept, multiple, children, className, light,
            classes, forwardedRef, ...buttonProps
        } = this.props;
        const basicClassName = `${className} ${classes.button}`;
        return (
            <div>
                <input
                    accept={accept}
                    className={classes.displayNone}
                    id="contained-button-file"
                    multiple
                    variant="file"
                />
                {
                    (children
                    && (
                        <label htmlFor="contained-button-file">
                            <MaterialUiButton
                                ref={forwardedRef}
                                color="secondary"
                                {...buttonProps}
                                component="span"
                            >
                                <div className={basicClassName}>
                                    {children}
                                    {icon && <Icon>{icon}</Icon>}
                                </div>
                            </MaterialUiButton>
                        </label>
                    ))
                        || (
                            <label htmlFor="contained-button-file">
                                <IconButton
                                    ref={forwardedRef}
                                    color="primary"
                                    {...buttonProps}
                                    component="span"
                                >
                                    {
                                        icon && <Icon>{icon}</Icon>
                                    }
                                </IconButton>
                            </label>
                        )
                }
            </div>
        );
    }

    render() {
        const { variant } = this.props;
        return this.renderButton(variant);
    }
}
Button.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'text', 'contained', 'fab', 'icon', 'upload']),
    type: PropTypes.oneOf(['button', 'submit']),
    icon: PropTypes.node,
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    IconProps: PropTypes.object,
    light: PropTypes.bool,
    iconType: PropTypes.string,
    color: PropTypes.string,
    forwardedRef: PropTypes.object,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    className: '',
    icon: '',
    variant: 'text',
    accept: '',
    multiple: true,
    children: null,
    type: 'button',
    light: false,
};

// eslint-disable-next-line react/no-multi-comp
const ButtonWithRef = React.forwardRef((props, ref) => <Button {...props} forwardedRef={ref} />);

export default withStyles(buttonStyle)(ButtonWithRef);
