import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import { memo, useCallback, useMemo } from 'react';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import { EMPTY_LIST } from 'Commons/config/constants/Constants';
import { labelFormatter } from 'Commons/helpers/utils/Formatter';
import { getOptionProps } from 'Commons/helpers/utils/SDFormatter';
import Typography from 'Commons/components/generic/typography/components/Typography';
import getName from 'Commons/helpers/utils/NameHelper';
import JournalUserManagementFilterStyle from '../styles/JournalUserManagementFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';
import { getUserApiConfig } from '../config/ApiRequests';

const useStyles = makeStyles(
    JournalUserManagementFilterStyle, { name: 'JournalUserRoleFilter' },
);

const JournalUserManagementFilter = ({
    formProps: {
        setFieldValue,
        values: { terms: { metaInfo__user_id: selectedUserIds = EMPTY_LIST } = {} },
    } = {},
    formProps, entityId: selectedAccountId,
}) => {
    const classes = useStyles();

    const userApiConfig = useMemo(() => getUserApiConfig(selectedAccountId), [selectedAccountId]);

    const onSelectOrRemoveUser = (selectedUsersIds) => {
        setFieldValue('terms.metaInfo__user_id', Object.keys(selectedUsersIds));
    };

    const userOptionFormatter = useCallback(({ data = [] } = {}) => data.map(({ firstName, lastName, ...user }) => (
        { label: getName({ firstName, lastName }), ...user }
    )), [selectedAccountId]);

    return (
        <>
            <RangeFilter
                isDateRange
                name="createdDate"
                formProps={formProps}
                setFieldValue={setFieldValue}
                label={localisable.modifiedEffectiveDate}
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.selectUser}
            </Typography>
            <MultiSelect
                placeholder={localisable.selectUser}
                onSelect={onSelectOrRemoveUser}
                onRemove={onSelectOrRemoveUser}
                keyToMatch="id"
                apiConfig={userApiConfig}
                maxHeight={150}
                initiallySelectedValues={selectedUserIds}
                inputValueFormatter={labelFormatter}
                getOptionProps={getOptionProps}
                optionFormatter={userOptionFormatter}
                autoSuggestKey="match.email"
            />
        </>
    );
};

JournalUserManagementFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
};

JournalUserManagementFilter.defaultProps = { formProps: {} };

export default memo(JournalUserManagementFilter);
