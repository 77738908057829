const unitCardStyles = theme => ({
    card: { position: 'relative' },
    fonts: { fontSize: '0.875rem' },
    disabled: { color: theme.palette.disabled },
    icon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 'auto',
    },
    singleLineEllipsis: {
        ...theme.globalClasses.multilineEllipsis.style,
        lineClamp: 1,
        width: '80%',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
    },
    fullWidth: { width: '100%' },
});
export default unitCardStyles;
