import STATUS from 'Commons/config/constants/StoreKeyStatus';
import { createEnumMap } from 'Commons/helpers/utils/Formatter';
import { MAIN_PADDING } from 'External/containers/dashboard/widgets/main/styles/MainStyle';
import Theme from '../../../../theme/Theme';

const MAIN_CONTENT_PADDING = MAIN_PADDING.DESKTOP_UP;
const MOBILE_MAIN_CONTENT_PADDING = MAIN_PADDING.DEFAULT;
const TABLET_MAIN_CONTENT_PADDING = MAIN_PADDING.TABLET_UP;
const SIDEBAR_OPEN_EXTRA_SPACE = Theme.spacing(27.5);
const SIDEBAR_CLOSE_EXTRA_SPACE = Theme.spacing(8);
const PAGE_TOP_MARGIN = 92;
const LAPTOP_PAGE_TOP_MARGIN = 80;
const MOBILE_PAGE_TOP_MARGIN = 120;
const TABLE_HEADER_CONTENT_MARGIN = 14;
const MOBILE_BOTTOM_PADDING = 24;
const CAN_I_SHOW_CONFIG = {
    appliedFilter: {
        isMobile: false,
        isTablet: true,
        isPhablet: true,
        isDesktop: true,
    },
};
const STATUS_TO_UPDATE_ON = [STATUS.LOADED, STATUS.UPDATED, STATUS.UNLOADED, STATUS.INVALID, STATUS.PAGINATE_ERROR];
const SEARCH_TYPE = createEnumMap(['autosuggest', 'pagination', 'search']);
const FILTERS_AND_SORTS_HEIGHT = 68;
const ACTION_ITEMS_PANEL_HEIGHT = 66;

export {
    SIDEBAR_OPEN_EXTRA_SPACE,
    SIDEBAR_CLOSE_EXTRA_SPACE,
    PAGE_TOP_MARGIN,
    MOBILE_PAGE_TOP_MARGIN,
    MOBILE_BOTTOM_PADDING,
    CAN_I_SHOW_CONFIG,
    STATUS_TO_UPDATE_ON,
    SEARCH_TYPE,
    TABLE_HEADER_CONTENT_MARGIN,
    FILTERS_AND_SORTS_HEIGHT,
    ACTION_ITEMS_PANEL_HEIGHT,
    MAIN_CONTENT_PADDING,
    MOBILE_MAIN_CONTENT_PADDING,
    TABLET_MAIN_CONTENT_PADDING,
    LAPTOP_PAGE_TOP_MARGIN,
};
