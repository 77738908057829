import { deepMerge, isValidObject, isNumber } from 'Commons/helpers/utils/DataHelpers';

const geUpdatedTouchedOnAdd = (touched) => {
    const touchedData = {};
    Object.keys(touched).forEach((touchedKey) => {
        touchedData[(Number(touchedKey) + 1).toString()] = touched[touchedKey];
    });
    return touchedData;
};

const shiftKeysUponDelete = (indexToDeleteFrom, values) => {
    const shiftedValues = {};
    Object.keys(values).forEach((editedKey) => {
        const intTouchedKey = Number(editedKey);
        if (intTouchedKey < indexToDeleteFrom) {
            shiftedValues[editedKey] = values[editedKey];
        } else if (intTouchedKey > indexToDeleteFrom) {
            shiftedValues[(intTouchedKey - 1).toString()] = values[editedKey];
        }
    });
    return shiftedValues;
};

const getValuesAndTouchedUponDelete = (indexToDeleteFrom, data, initialValues, editedValues, touched) => {
    const cleanedEditedValues = shiftKeysUponDelete(indexToDeleteFrom, editedValues);

    // Creating data for Reset Form - Empty objects for new rows, previous initial values for existing rows, removing row at index given
    const updatedValues = [
        ...data.slice(0, data.length - initialValues.length).map(() => ({})),
        ...initialValues,
    ].filter((_, index) => indexToDeleteFrom !== index);

    const touchedData = shiftKeysUponDelete(indexToDeleteFrom, touched);

    const resetFormValue = Object.assign({}, updatedValues);
    const valueToSet = deepMerge(resetFormValue, cleanedEditedValues);

    return [resetFormValue, valueToSet, touchedData];
};

const setInitialValuesTouched = (setFieldValue, setFieldTouched, currentParentKey = '', invalidData = {}) => {
    const touched = {};
    Object.keys(invalidData).forEach((key) => {
        const currentValue = invalidData[key];
        const currentKey = currentParentKey ? `${currentParentKey}.${key}` : key;
        if (isValidObject(currentValue)) {
            touched[key] = setInitialValuesTouched(setFieldValue, setFieldTouched, currentKey, currentValue);
        } else {
            touched[key] = true;
            setFieldValue(currentKey, currentValue);
            setFieldTouched(currentKey, true);
        }
    });
    return touched;
};

const resetInvalidRows = (setFieldValue, setFieldTouched, currentParentKey = '', values, indexes = []) => {
    if (indexes.length) {
        Object.keys(values).forEach((key) => {
            if (isNumber(key) ? indexes.includes(key) : true) {
                const currentValue = values[key];
                const currentKey = currentParentKey ? `${currentParentKey}.${key}` : key;
                if (isValidObject(currentValue)) {
                    resetInvalidRows(setFieldValue, setFieldTouched, currentKey, currentValue, indexes);
                } else {
                    setFieldValue(currentKey, currentValue);
                    setFieldTouched(currentKey, true);
                }
            }
        });
    }
};

export { geUpdatedTouchedOnAdd, getValuesAndTouchedUponDelete, setInitialValuesTouched, resetInvalidRows };
