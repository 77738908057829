
import { useRef, useEffect } from 'react';
import {
    makeStyles, Paper, ClickAwayListener,
    Popper, Grid,
} from 'Generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import { TextField } from '../../textfield/components/TextField';
import popdownStyle from '../styles/PopdownStyle';

const useStyles = makeStyles(popdownStyle, { name: 'Popdown' });

const Popdown = (props) => {
    const selectRef = useRef();
    const selectContainerRef = useRef();
    const classes = useStyles(props);
    const inputRef = useRef({});
    const { shouldAutoFocus } = props;

    const getPaperStyle = () => {
        const { useContainerRef, PaperProps = {} } = props;
        const { width: anchorWidth } = useContainerRef
            ? selectContainerRef.current.getBoundingClientRect()
            : selectRef.current.getBoundingClientRect();
        const { width = anchorWidth } = PaperProps;
        return { width };
    };

    const onRef = (ref, containerRef) => {
        if (ref) {
            selectRef.current = ref;
            selectContainerRef.current = containerRef;
        }
    };

    useEffect(() => {
        const { inputRef: propInputRef = inputRef } = props;
        if (shouldAutoFocus) {
            propInputRef.current.focus();
        }
    }, [shouldAutoFocus]);


    const {
        open,
        children,
        maxHeight,
        PaperProps: { className: paperClassName = '', ...PaperProps },
        onClickAway,
        TextFieldClasses,
        disablePortal,
        classes: ignore,
        useContainerRef,
        containerClassName,
        PopperProps: { className: popperClassName, parentRef, ...PopperProps },
        ...others
    } = props;

    return (
        <Grid
            className={clsx(classes.propdown, containerClassName)}
            role="presentation"
        >
            <TextField
                onRef={onRef}
                classes={TextFieldClasses}
                inputRef={inputRef}
                {...others}
            />
            {
                (open && children)
                && (
                    <Popper
                        open={open}
                        anchorEl={useContainerRef ? selectContainerRef.current : selectRef.current}
                        placement="bottom-start"
                        className={clsx(classes.popper, popperClassName)}
                        disablePortal={disablePortal}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: parentRef || 'scrollParent',
                            },
                            hide: { enabled: true },
                        }}
                        {...PopperProps}
                    >
                        <ClickAwayListener mouseEvent={open && 'onClick'} onClickAway={onClickAway}>
                            <Paper
                                className={clsx(classes.paper, classes.paperHeight, paperClassName)}
                                style={{ ...getPaperStyle() }}
                                {...PaperProps}
                            >
                                {children}
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                )
            }
        </Grid>
    );
};

Popdown.propTypes = {
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    classes: PropTypes.object,
    children: PropTypes.node,
    open: PropTypes.bool,
    PaperProps: PropTypes.object,
    TextFieldClasses: PropTypes.object,
    onClickAway: () => { },
    PopperProps: PropTypes.object,
    // set this to false to achieve modal like behavior
    disablePortal: PropTypes.bool,
    // By enabling this, popdown will use the text field container's width
    useContainerRef: PropTypes.bool,
    containerClassName: PropTypes.string,
    GridProps: PropTypes.object,
    shouldAutoFocus: PropTypes.bool,
};

Popdown.defaultProps = {
    maxHeight: 240,
    open: false,
    PaperProps: {},
    onClickAway: () => { },
    PopperProps: {},
    disablePortal: true,
    useContainerRef: false,
};

export default Popdown;
