import { memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import { FORM_COMPONENTS, INPUT_TYPE } from 'Generic/form/config/FormComponentsConfig';
import { restrictInputToNumericKeys } from 'Commons/helpers/utils/Utils';
import round from 'Commons/helpers/utils/Round';
import Form from '../../form/components/Form';
import textFieldStyle from '../styles/TextFieldStyle';
import {
    Grid,
    InputAdornment,
    makeStyles,
    TextField as MaterialUiTextField,
} from '../../componentlibrary/components/Components';

const useStyles = makeStyles(textFieldStyle, { name: 'TextField' });

const TextField = React.forwardRef(({ trackValue, value, onChange, onRef, readOnly, ...props }, ref) => {
    const classes = useStyles(props);
    const [stateValue, setStateValue] = useState(trackValue ? value : '');
    const textFieldRef = useRef();
    const textFieldContainerRef = useRef();

    useEffect(() => {
        onRef(textFieldRef.current, textFieldContainerRef.current);
    }, []);

    useImperativeHandle(ref, () => textFieldRef.current);

    const onChangeValue = (event) => {
        const { target: { value: targetValue } } = event;
        const { rateField } = props;
        let shouldUpdateValue = true;
        if (rateField) {
            const splitValue = targetValue.toString().split('.');
            // Checking if targetValue has more than 2 decimal places
            if (splitValue.length > 1 && splitValue[1].length > 2) {
                shouldUpdateValue = false;
            }
        }
        if (shouldUpdateValue) {
            if (trackValue) {
                setStateValue(targetValue);
            }
            onChange(event, targetValue);
        }
    };

    const getInputProps = () => {
        const { addon, InputProps, addonClassName, label, disableUnderline } = props;
        const { className } = InputProps;
        const addOnProps = {};

        Object.keys(addon).forEach((position) => {
            addOnProps[`${position}Adornment`] = (
                addon[position]
                && (
                    <InputAdornment
                        className={clsx(
                            classes.inputAdornment,
                            classes[`${position}Adornment`],
                            position === 'start' && classes.defaultCursor,
                            addonClassName,
                        )}
                    >
                        {addon[position]}
                    </InputAdornment>
                )
            );
        });

        return {
            ...InputProps,
            ...addOnProps,
            readOnly,
            disableUnderline: readOnly || disableUnderline,
            className: clsx(classes.underline, classes.inputRoot, { [classes.marginTop]: !label }, className),
        };
    };

    const handleRateFields = (event) => {
        const { rateField } = props;
        if (rateField) {
            if (trackValue && stateValue) {
                const fixedValue = round(stateValue, 2);
                if (stateValue !== round(stateValue, 2, true)) {
                    setStateValue(fixedValue);
                    onChange(event, fixedValue);
                }
            } else if (value && value !== round(value, 2, true)) {
                onChange(event, round(value, 2));
            }
        }
    };

    const handleOnBlur = (event) => {
        const { rateField, onBlur: onBlurCbProp } = props;
        if (rateField) handleRateFields(event);
        if (onBlurCbProp) onBlurCbProp(event);
    };

    const {
        error, label, addon, form, preferred, required, multiline, dynamicMultiline = false,
        minWidth, width, typographyVariant,
        inputProps: { className: inputClassName, ...otherInputProps }, light, className,
        InputProps: textFieldInputProps, type, additionalIcon: { left, right } = {}, classes: overriddenClasses,
        InputLabelProps: { classes: inputLabelClasses, ...InputLabelProps }, rateField, addonClassName,
        disableUnderline, alwaysShowError, onBlur: _ignore, ...others // We have already handled calling onBlur in handleOnBlur func, if we do not remove it, onBlur gets triggered twice
    } = props;
    const {
        inputProps: { className: inputInputClassName, ...otherInputInputProps } = {},
        ...InputProps
    } = getInputProps();
    const isNumberField = rateField || type === 'number' || type === INPUT_TYPE.INTEGER;

    return (
        <Grid container wrap="nowrap" className={classes.alignment} ref={textFieldContainerRef}>
            {
                left
                && (
                    <div className={classes.rightMarginIcon}>
                        {left}
                    </div>
                )
            }
            <MaterialUiTextField
                ref={textFieldRef}
                className={clsx(classes.textField, {
                    [classes.textFieldWidth]: width,
                    [classes.lightTextField]: light,
                    [classes.multiline]: !readOnly && !dynamicMultiline && multiline,
                }, className)}
                error={error}
                type={rateField || type === INPUT_TYPE.INTEGER ? INPUT_TYPE.NUMBER : type}
                value={trackValue ? stateValue : value}
                onChange={onChangeValue}
                label={label}
                multiline={multiline}
                InputProps={InputProps}
                {...type === INPUT_TYPE.INTEGER && { onKeyDown: restrictInputToNumericKeys }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                    className: clsx(classes.input, { [classes.number]: isNumberField },
                        { [classes[`${typographyVariant}Input`]]: typographyVariant },
                        inputClassName,
                        inputInputClassName),
                    onBlur: handleOnBlur,
                    ...otherInputInputProps,
                    ...otherInputProps,
                }}
                InputLabelProps={{
                    classes: { root: classes.labelRoot, ...inputLabelClasses },
                    shrink: true,
                    ...InputLabelProps,
                }}
                {...others}
            />
            {
                right
                && (
                    <div className={classes.leftMarginIcon}>
                        {right}
                    </div>
                )
            }
        </Grid>
    );
});

TextField.propTypes = {
    addon: PropTypes.shape({
        start: PropTypes.node,
        end: PropTypes.node,
    }),
    InputLabelProps: PropTypes.shape(),
    autoComplete: PropTypes.string,
    InputProps: PropTypes.shape(),
    label: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    trackValue: PropTypes.bool,
    onChange: PropTypes.func,
    preferred: PropTypes.bool,
    required: PropTypes.bool,
    form: PropTypes.object,
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    error: PropTypes.bool,
    type: PropTypes.string,
    addonClassName: PropTypes.string,
    light: PropTypes.bool,
    className: PropTypes.string,
    additionalIcon: PropTypes.shape({
        left: PropTypes.node,
        right: PropTypes.node,
    }),
    multiline: PropTypes.bool,
    onRef: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    typographyVariant: PropTypes.oneOf(['h5', 'h6', 'subtitle1']),
    readOnly: PropTypes.bool,
    rateField: PropTypes.bool,
    disableUnderline: PropTypes.bool,
    onBlur: PropTypes.func,
    dynamicMultiline: PropTypes.bool,
    alwaysShowError: PropTypes.bool,
};

TextField.defaultProps = {
    addon: {},
    InputLabelProps: { shrink: true },
    autoComplete: 'off',
    InputProps: { className: '' },
    label: '',
    value: '',
    trackValue: true,
    disabled: false,
    onChange: () => { },
    onBlur: () => { },
    onRef: () => { },
    preferred: false,
    required: false,
    type: INPUT_TYPE.SEARCH,
    inputProps: {},
    className: '',
    multiline: false,
    rateField: false,
};

const StyledTextField = memo(TextField);
StyledTextField.displayName = FORM_COMPONENTS.TEXT_FIELD;
const FormTextField = Form(StyledTextField, {}, { error: true });

export {
    StyledTextField as TextField,
    FormTextField,
};
