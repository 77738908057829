import { PureComponent } from 'react';
import { capitalize } from 'Commons/helpers/utils/DataHelpers';
import { clsx } from 'Commons/helpers/utils/clsx';
import { Icon as MaterialUiIcon, SvgIcon, withStyles } from 'Generic/componentlibrary/components/Components';
import iconStyle from '../styles/IconStyle';

class Icon extends PureComponent {
    renderIcon(type) {
        return this[`render${capitalize(type)}Icon`]();
    }

    renderCustomIcon() {
        const {
            className, icon, classes, style, path, display, disabled, color, size, fullWidth, noCursorPointer,
            ...iconProps
        } = this.props;
        return (
            <span
                className={clsx(
                    classes.cpIcon,
                    classes[display],
                    classes[icon],
                    classes.icon,
                    classes[size],
                    {
                        [classes.disabled]: disabled,
                        [classes[`${color}icon`]]: color,
                        [classes.fullWidth]: fullWidth,
                        [classes.noCursorPointer]: noCursorPointer,
                    },
                    className,
                )
                }
                style={style}
                cy-id={icon}
                {...iconProps}
            />
        );
    }

    renderFontIcon() {
        const {
            classes, children, icon, path, className, display, size, disabled, fullWidth, noCursorPointer,
            ...iconProps
        } = this.props;
        return (
            <MaterialUiIcon
                className={clsx(
                    classes.icon,
                    classes[display],
                    classes[size],
                    {
                        [classes.disabled]: disabled,
                        [classes.fullWidth]: fullWidth,
                    },
                    className,
                )}
                {...iconProps}
            >
                { icon || children }
            </MaterialUiIcon>
        );
    }

    renderSvgIcon() {
        const { classes, children, path, className, size, disabled, fullWidth, ...iconProps } = this.props;
        return (
            <SvgIcon
                className={clsx(
                    classes.icon,
                    classes[size],
                    {
                        [classes.disabled]: disabled,
                        [classes.fullWidth]: fullWidth,
                    },
                    className,
                )}
                {...iconProps}
            >
                {<path d={path || children} /> }
            </SvgIcon>
        );
    }

    /* renderThemedIcon() {
        const { classes, children, icon, className, variant, path, ...iconProps } = this.props;
        const getIcon = () => {
            const camelizedIcon = camelize(`${icon}_${variant}`);
            return `${camelizedIcon.charAt(0).toUpperCase()}${camelizedIcon.slice(1)}`;
        };
        const ThemedIcon = MaterialUiIcons[getIcon()];
        return (
            <ThemedIcon className={`${classes.icon} ${className}`} {...iconProps} />
        );
    } */

    render() {
        const { type } = this.props;
        return this.renderIcon(type);
    }
}
Icon.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['font', 'svg', 'custom', 'themed']),
    variant: PropTypes.oneOf(['outlined', 'rounded', 'two_tone', 'sharp']),
    children: PropTypes.node,
    path: PropTypes.string,
    icon: PropTypes.string,
    classes: PropTypes.object,
    style: PropTypes.object,
    display: PropTypes.oneOf(['inline', 'block', 'inline-block']),
    disabled: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'default', 'large']),
    fullWidth: PropTypes.bool,
    noCursorPointer: PropTypes.bool,
};
Icon.defaultProps = {
    className: '',
    type: 'font',
    children: null,
    path: '',
    icon: '',
    display: 'inline',
    disabled: false,
    size: 'default',
    noCursorPointer: false,
};
export default withStyles(iconStyle)(Icon);
