import media from 'Commons/theme/MediaQueries';

const MAIN_PADDING = {
    DESKTOP_UP: 24,
    TABLET_UP: 16,
    DEFAULT: 8,
};

const dashboardStyle = theme => ({
    root: {
        height: '100vh',
        flexGrow: 1, /* drawer height can be defined here */
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    alert: {
        whiteSpace: 'pre',
        paddingBottom: theme.spacing(2),
    },
    checkbox: { paddingBottom: theme.spacing(2) },
    content: {
        overflow: 'auto',
        marginTop: theme.spacing(15),
        [media.tabletUp]: {
            padding: MAIN_PADDING.TABLET_UP,
            paddingTop: 0,
        },
        [media.laptopUp]: { marginTop: theme.spacing(10) },
        [media.desktop]: {
            marginTop: theme.spacing(11),
            padding: MAIN_PADDING.DESKTOP_UP,
            paddingTop: 0,
        },
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: MAIN_PADDING.DEFAULT,
        paddingTop: 0,
    },
    mainContent: {
        '&$onboarding': {
            marginTop: theme.spacing(8),
            [media.laptopUp]: { marginTop: theme.spacing(7) },
            [media.desktop]: { marginTop: theme.spacing(8) },
            padding: 0,
            display: 'flex',
        },
    },
    onboarding: {},
    alertMinWidth: { minWidth: 350 },
});

export default dashboardStyle;
