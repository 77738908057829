import { FormTextField } from 'Generic/textfield/components/TextField';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { SM_11, SM_3_XS_4, SM_4_XS_6, SM_4_XS_8, SM_6_XS_6 } from 'Commons/config/constants/GridSpacingConstants';
import { IS_MAX_LENGTH_25, IS_MAX_LENGTH_50, ZIP_CODE_VALIDATOR } from 'Commons/config/constants/Validators';
import { memo, useRef, useState } from 'react';
import { INPUT_TYPE, VARIANT } from 'Generic/form/config/FormComponentsConfig';
import localisable from 'Commons/config/strings/localisable';
import { fetchZipCodeData } from 'Commons/components/business/formcomponents/address/config/ApiRequests';
import ThreeDotProgress from 'Generic/threedotprogress/components/ThreeDotProgress';
import AddressStyles from 'Commons/components/business/formcomponents/address/styles/AddressStyles';
import { EMPTY_FUNC, EMPTY_OBJECT } from 'Commons/config/constants/Constants';
import { getValue } from 'Commons/helpers/utils/DataHelpers';
import Label from '../../Label/components/Label';

const useStyles = makeStyles(AddressStyles, { name: 'Address' });

const Address = (props) => {
    const {
        mapping: {
            line1: {
                visibility: addressLine1Visibility = true,
                ...addressLine1Props
            } = {},
            line2: {
                visibility: addressLine2Visibility = true,
                ...addressLine2Props
            } = {},
            state: {
                name: stateFieldName,
                visibility: stateVisibility = true,
                disabled: isStateDisabled = false,
                ...stateProps
            } = {},
            country: {
                name: countryFieldName,
                visibility: countryVisibility = true,
                disabled: isCountryDisabled = false,
                ...countryProps
            } = {},
            city: {
                name: cityFieldName,
                visibility: cityVisibility = true,
                disabled: isCityDisabled = false,
                ...cityProps
            } = {},
            zipCode: {
                name: zipCodeFieldName,
                visibility: zipVisibility = true,
                ...zipProps
            } = {},
        } = {},
        disabled,
        addressLabelVisibility,
        values = {},
        setFieldValue,
        setFieldTouched,
        onAction,
        status,
        setStatus,
        handleSubmit,
        disableFormSubmit,
        getWasFormSubmissionCancelled,
    } = props;
    const classes = useStyles();

    const zipCodeFromValues = getValue(values, zipCodeFieldName);
    const cityFromValues = getValue(values, cityFieldName);
    const stateFromValues = getValue(values, stateFieldName);
    const countryFromValues = getValue(values, countryFieldName);

    const { current: threeDotProgressClasses = '' } = useRef({ spinner: classes.threeDotProgressSpinner });
    const { current: zipRelatedFieldsAddon } = useRef({ end: (<ThreeDotProgress classes={threeDotProgressClasses} />) });

    const prevZipCode = useRef(zipCodeFromValues || '');

    const { current: defaultZipRelatedFieldsObject = {} } = useRef({
        [cityFieldName]: false,
        [stateFieldName]: false,
        [countryFieldName]: false,
    });

    const { current: allZipRelatedFieldNames = [] } = useRef(Object.keys(defaultZipRelatedFieldsObject));

    const zipRelatedFieldsNotFilled = useRef([]);
    const zipRelatedFieldsNotEditedByUser = useRef([]);

    const zipRelatedFieldsEditedByUser = useRef({ ...defaultZipRelatedFieldsObject });

    const [zipRelatedFieldsDisabledAndLoadingStatus, setZipRelatedFieldsDisabledAndLoadingStatus] = useState({ ...defaultZipRelatedFieldsObject });

    const isZipRelatedFieldDisabledAndLoading = (fieldName) => {
        const { [fieldName]: isDisabledAndLoading = false } = zipRelatedFieldsDisabledAndLoadingStatus;
        return isDisabledAndLoading;
    };

    const isCurrentZipCodeDifferentFromPrev = () => {
        const zipCode = zipCodeFromValues || '';
        return zipCode !== prevZipCode.current;
    };

    const getZipRelatedFieldsNotFilled = () => allZipRelatedFieldNames.filter(fieldName => !getValue(values, fieldName));

    const getZipRelatedFieldsNotEditedByUser = () => allZipRelatedFieldNames.filter((fieldName) => {
        const { current: { [fieldName]: isEditedByUser = false } = {} } = zipRelatedFieldsEditedByUser;
        return !isEditedByUser;
    });

    const shouldFetchZipCodeData = () => {
        zipRelatedFieldsNotFilled.current = getZipRelatedFieldsNotFilled();
        const isAnyFieldNotFilled = !!zipRelatedFieldsNotFilled.current.length;

        zipRelatedFieldsNotEditedByUser.current = getZipRelatedFieldsNotEditedByUser();
        const isAnyFieldNotEditedByUser = !!zipRelatedFieldsNotEditedByUser.current.length;

        return isCurrentZipCodeDifferentFromPrev() && (isAnyFieldNotFilled || isAnyFieldNotEditedByUser);
    };

    const setCityField = (value) => {
        setFieldValue(cityFieldName, value);
        setFieldTouched(cityFieldName, true, false);
    };

    const setStateField = (value) => {
        setFieldValue(stateFieldName, value);
        setFieldTouched(stateFieldName, true, false);
    };

    const setCountryField = (value) => {
        setFieldValue(countryFieldName, value);
        setFieldTouched(countryFieldName, true, false);
    };

    const setOrUnsetAddressFields = ({ city, state, country } = {}, isReset = false) => {
        const {
            current: {
                [cityFieldName]: isCityEditedByUser = false,
                [stateFieldName]: isStateEditedByUser = false,
                [countryFieldName]: isCountryEditedByUser = false,
            } = {},
        } = zipRelatedFieldsEditedByUser;
        if (isReset) {
            if (cityFromValues && !isCityEditedByUser) setCityField('');
            if (stateFromValues && !isStateEditedByUser) setStateField('');
            if (countryFromValues && !isCountryEditedByUser) setCountryField('');
        } else {
            if (!!city && !isCityEditedByUser) {
                setCityField(city);
            }
            if (!!state && !isStateEditedByUser) {
                setStateField(state);
            }
            if (!!country && !isCountryEditedByUser) {
                setCountryField(country);
            }
        }
    };

    const setRelevantZipRelatedFieldsDisabledAndLoadingStatus = () => {
        const uniqueFieldNames = new Set([...zipRelatedFieldsNotFilled.current, ...zipRelatedFieldsNotEditedByUser.current]);
        const newState = [...uniqueFieldNames].reduce((acc, fieldName) => ({
            ...acc,
            [fieldName]: true,
        }), {});

        // Reset the fields being populated
        zipRelatedFieldsNotEditedByUser.current = [];
        zipRelatedFieldsNotFilled.current = [];

        setZipRelatedFieldsDisabledAndLoadingStatus(prevState => ({ ...prevState, ...newState }));
    };

    const setIsZipCodeDataBeingFetched = (value = true) => {
        setStatus({
            ...status,
            isZipCodeDataBeingFetched: value,
        });
    };

    const onZipCodeDataFetch = (apiError, response) => {
        setIsZipCodeDataBeingFetched(false);
        if (response) {
            const { data: [data = {}] = [] } = response;
            setOrUnsetAddressFields(data);
        }
        if (getWasFormSubmissionCancelled()) { // Since we disabled form submission before fetch zip code data, we need to reset the form submission cancellation flag
            disableFormSubmit(false);
            handleSubmit(); // Call the form submit handler now that we're done fetching the zip code data
        }
        setZipRelatedFieldsDisabledAndLoadingStatus({ ...defaultZipRelatedFieldsObject });
    };

    const zipCodeFieldOnBlur = (event) => {
        const { relatedTarget } = event;
        const { innerText, type } = relatedTarget || {};
        const zipCode = zipCodeFromValues || '';

        if (zipCode.length < 3) { // If zip code is less than 3 chars, then unset the fields
            setOrUnsetAddressFields(undefined, true);
            return;
        }

        if (onAction && shouldFetchZipCodeData()) {
            setRelevantZipRelatedFieldsDisabledAndLoadingStatus();
            setIsZipCodeDataBeingFetched();
            if (relatedTarget !== null && [INPUT_TYPE.BUTTON, INPUT_TYPE.SUBMIT].includes(type)
                && [localisable.save, localisable.next].includes(innerText)) { // To identify if the footer's Save/Next(In case of Move-In and related flows) button was clicked
                disableFormSubmit(); // This will prevent the form from submitting so that the zip code data can be fetched and set in the form
            }
            fetchZipCodeData(onAction, zipCode, onZipCodeDataFetch);
        }

        prevZipCode.current = zipCodeFromValues;
    };

    const zipRelatedFieldsOnChange = fieldName => ({ target: { value } = {} } = {}) => {
        zipRelatedFieldsEditedByUser.current[fieldName] = !!value;
    };

    const getZipRelatedFieldAddon = fieldName => (isZipRelatedFieldDisabledAndLoading(fieldName) ? zipRelatedFieldsAddon : EMPTY_OBJECT);

    return (
        <Grid container>
            {
                addressLabelVisibility
                && (
                    <Label disabled={disabled}>
                        {localisable.address}
                    </Label>
                )
            }

            {
                addressLine1Visibility
                && (
                    <FormTextField
                        name="address.line1"
                        placeholder={localisable.addressLine1}
                        fullWidth
                        formVariant={VARIANT.INLINE}
                        GridProps={SM_11}
                        validate={IS_MAX_LENGTH_50}
                        {...addressLine1Props}
                    />
                )
            }

            {
                addressLine2Visibility
                && (
                    <FormTextField
                        name="address.line2"
                        placeholder={localisable.addressLine2}
                        fullWidth
                        formVariant={VARIANT.INLINE}
                        GridProps={SM_11}
                        validate={IS_MAX_LENGTH_50}
                        {...addressLine2Props}
                    />
                )
            }


            {
                cityVisibility
                && ( // TODO: Make the field as Dropdown
                    <FormTextField
                        name={cityFieldName}
                        placeholder={localisable.city}
                        fullWidth
                        formVariant={VARIANT.INLINE}
                        validate={IS_MAX_LENGTH_25}
                        GridProps={SM_4_XS_8}
                        disabled={isCityDisabled || isZipRelatedFieldDisabledAndLoading(cityFieldName)}
                        {...cityProps}
                        addon={getZipRelatedFieldAddon(cityFieldName)}
                        onChange={zipRelatedFieldsOnChange(cityFieldName)}
                        useField
                    />
                )
            }
            {
                stateVisibility
                && ( // TODO: Make the field as Dropdown
                    <FormTextField
                        name={stateFieldName}
                        placeholder={localisable.state}
                        fullWidth
                        formVariant={VARIANT.INLINE}
                        validate={IS_MAX_LENGTH_25}
                        GridProps={SM_3_XS_4}
                        disabled={isStateDisabled || isZipRelatedFieldDisabledAndLoading(stateFieldName)}
                        {...stateProps}
                        addon={getZipRelatedFieldAddon(stateFieldName)}
                        onChange={zipRelatedFieldsOnChange(stateFieldName)}
                        useField
                    />
                )
            }
            {
                zipVisibility
                && (
                    <FormTextField
                        name={zipCodeFieldName}
                        placeholder={localisable.zipCode}
                        validate={ZIP_CODE_VALIDATOR}
                        fullWidth
                        formVariant={VARIANT.INLINE}
                        GridProps={SM_4_XS_6}
                        {...zipProps}
                        onBlur={zipCodeFieldOnBlur}
                    />
                )
            }

            {
                countryVisibility
                && (
                    <FormTextField
                        name={countryFieldName}
                        placeholder={localisable.country}
                        fullWidth
                        formVariant={VARIANT.LAST_INLINE}
                        validate={IS_MAX_LENGTH_25}
                        GridProps={SM_6_XS_6}
                        disabled={isCountryDisabled || isZipRelatedFieldDisabledAndLoading(countryFieldName)}
                        {...countryProps}
                        addon={getZipRelatedFieldAddon(countryFieldName)}
                        onChange={zipRelatedFieldsOnChange(countryFieldName)}
                        useField
                    />
                )
            }
        </Grid>
    );
};

Address.propTypes = {
    config: PropTypes.object,
    disabled: PropTypes.bool,
    mapping: PropTypes.object,
    setConfig: PropTypes.func,
    withoutSection: PropTypes.bool,
    addressLabelVisibility: PropTypes.bool,
    onAction: PropTypes.func,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    status: PropTypes.object,
    setStatus: PropTypes.func,
    handleSubmit: PropTypes.func,
    disableFormSubmit: PropTypes.func,
    getWasFormSubmissionCancelled: PropTypes.func,
};

Address.defaultProps = {
    setConfig: EMPTY_FUNC,
    withoutSection: false,
    config: {},
    addressLabelVisibility: true,
    setFieldValue: EMPTY_FUNC,
    setFieldTouched: EMPTY_FUNC,
    setStatus: EMPTY_FUNC,
    handleSubmit: EMPTY_FUNC,
    disableFormSubmit: EMPTY_FUNC,
    getWasFormSubmissionCancelled: EMPTY_FUNC,
};

export default memo(Address);
