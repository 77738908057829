import { Typography, Grid, withStyles }
from 'Commons/components/generic/componentlibrary/components/Components';
import Link from 'Generic/link/components/Link';
import { AUTO_SUGGEST_RESOURCE, DASHBOARD_TYPE } from 'Commons/config/constants/Constants';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import Icon from 'Commons/components/generic/icon/components/Icon';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import Card from '../../../../generic/card/components/Card';
import localisable from '../../../../../config/strings/localisable';
import unitCardStyles from '../styles/unitCardStyles';

class UnitCard extends React.Component {
    render() {
        const {
            name,
            unitType, addon, comments, disabled, classes, resource, onSelectCard,
            currentAccountId: { data: { id: accountId } = {} } = {},
            currentFacility: { data: { id: fid } = {} },
            id, onClickAway, ...props
        } = this.props;
        const disabledClassName = disabled ? classes.disabled : '';
        if (resource === AUTO_SUGGEST_RESOURCE.MOVE_OUT) {
            return (
                <Card {...props}>
                    <LabelWithIcon addon={{ start: <Icon type="custom" icon="cp-unit" /> }}>
                        <Typography variant="body2" className={classes.name}>
                            {name}
                        </Typography>
                    </LabelWithIcon>
                </Card>
            );
        }
        const getCardLink = () => buildUrl('unitEdit', { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId);

        const renderCard = extraProps => (
            <Card {...props} {...extraProps}>
                <Grid container wrap="nowrap" className={classes.card}>
                    <Grid item xs={12} container className={classes.unitDetails}>
                        {
                            addon
                            && (
                                <Grid container className={classes.icon} alignItems="center">
                                    {addon}
                                </Grid>
                            )
                        }
                        {
                            name
                            && (
                                <Grid item xs={12}>
                                    <LabelWithIcon addon={{ start: <Icon type="custom" icon="cp-unit" /> }}>
                                        <Typography variant="h6">{name}</Typography>
                                    </LabelWithIcon>
                                </Grid>
                            )
                        }
                        {
                            unitType
                            && (
                                <Grid container alignItems="center" wrap="nowrap">
                                    <Typography variant="h6" className={classes.fonts}>
                                        {`${localisable.unitCardType}:`}
                                        {localisable.nbsp}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        className={disabledClassName}
                                    >
                                        {unitType}
                                    </Typography>
                                </Grid>
                            )
                        }

                        {
                            comments
                            && (
                                <Grid container alignItems="center" item xs={12}>
                                    <Typography variant="h6" className={classes.fonts}>
                                        {`${localisable.unitCardComments}:`}
                                        {localisable.nbsp}
                                    </Typography>
                                    <Typography noWrap className={classes.singleLineEllipsis}>{comments}</Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Card>
        );

        return (
            onSelectCard
                ? renderCard()
                : (
                    <Link
                        to={getCardLink()}
                        classes={{ link: classes.fullWidth }}
                        LabelWithIconProps={{ classes: { label: classes.fullWidth } }}
                    >
                        {renderCard({ onClick: () => onClickAway() })}
                    </Link>
                )
        );
    }
}

UnitCard.propTypes = {
    name: PropTypes.string,
    comments: PropTypes.string,
    disabled: PropTypes.bool,
    CardProps: PropTypes.object,
    classes: PropTypes.object.isRequired,
    unitType: PropTypes.string,
    addon: PropTypes.object,
    onSelectCard: PropTypes.func,
    resource: PropTypes.number,
    id: PropTypes.string,
    currentFacility: PropTypes.object,
    currentAccountId: PropTypes.object,
    onClickAway: PropTypes.func,
};

UnitCard.defaultProps = {
    disabled: false,
    name: '',
    unitType: '',
};

export default withStyles(unitCardStyles)(UnitCard);
