import Theme from 'Commons/theme/Theme';
import Modal from '../../../generic/modal/components/Modal';
import overlayStyle from '../styles/OverlayStyle';
import { Grid, withStyles, Divider, Button, RootRef, Route } from '../../../generic/componentlibrary/components/Components';
import LabelWithIcon from '../../../generic/labelwithicon/components/LabelWithIcon';
import Icon from '../../../generic/icon/components/Icon';
import localisable from '../../../../config/strings/localisable';

class Overlay extends React.Component {
    constructor(props) {
        super(props);
        this.parentRef = React.createRef();
        this.state = { selectedActionItem: -1 };
    }


    getHeader = () => {
        const { header, classes } = this.props;
        return (
            <Grid container alignItems="center" className={classes.overlayHeader}>
                {header}
            </Grid>
        );
    }

    getChildren = () => {
        const { children } = this.props;
        if (typeof children === 'function') {
            return children();
        }
        return children;
    }

    getKey = (index, label) => `${label}${index}`;

    onClose = (routeProps) => {
        const { toggleForm, path } = this.props;
        const { history } = routeProps;
        if (toggleForm) {
            toggleForm();
        } else if (history && path) {
            history.goBack();
        }
    }

    onActionItemClick = (event, index, onClick) => {
        this.setState({ selectedActionItem: index });
        if (onClick) onClick(event);
    }

    getDesktopActionHeader() {
        const { classes, actionItems } = this.props;
        const { selectedActionItem } = this.state;
        return (
            actionItems.length > 0
            && (
                <>
                    <Grid item className={classes.actionItems}>
                        <Grid container alignItems="center" spacing={4}>
                            {
                                actionItems.map(({ addon, label, onClick }, index) => (
                                    <Grid
                                        item
                                        key={this.getKey(label, index)}
                                        onClick={e => this.onActionItemClick(e, index, onClick)}
                                        className={selectedActionItem === index
                                            ? classes.activeItem : ''}
                                    >
                                        <LabelWithIcon
                                            addon={addon}
                                            classes={{ labelWithIcon: classes.labelWithIcon }}
                                        >
                                            {label}
                                        </LabelWithIcon>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item><Divider light /></Grid>
                </>
            )
        );
    }

    getSectionScroller() {
        const { sectionScroller } = this.props;
        return (sectionScroller
            && (
                <Grid item xs={12}>
                    {React.cloneElement(sectionScroller, { parentRef: this.parentRef })}
                </Grid>
            )
        );
    }

    getMobileActionHeader() {
        const { classes } = this.props;
        return (
            <Grid container spacing={0} className={classes.sectionBar}>
                <Grid item xs={6}>
                    <Button
                        className={`${classes.sectionTitle}`}
                        fullWidth
                        justify="space-between"
                        disabled
                    >
                        {localisable.actions}
                        {/* TODO */}
                        <Icon className={classes.icon}>arrow_drop_down</Icon>
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    {this.getSectionScroller()}
                </Grid>
            </Grid>
        );
    }

    renderActionHeader = () => {
        const { hideActionHeader } = this.props;
        const { deviceInfo: { isDesktop = false } = {} } = window;
        if (!hideActionHeader) {
            return (
                (!isDesktop) ? this.getMobileActionHeader() : this.getDesktopActionHeader()
            );
        }
        return (<></>);
    }

    renderModal = (routeProps = {}) => {
        const { showHeader, classes, footer, path, ModalProps: { modalClasses, ...ModalProps } = {}, sectionScroller, disableOverlay } = this.props;
        const { deviceInfo: { isDesktop = false } = {} } = window;
        const {
            globalClasses: {
                deactivatedOverlay: { className: deactivatedOverlay },
                deactivateConfirmationFooter: { className: deactivateConfirmationFooter },
            },
        } = Theme;
        return (
            <Modal
                header={showHeader && this.getHeader()}
                className={classes.overlay}
                onClose={() => this.onClose(routeProps)}
                BackdropProps={{ classes: { root: classes.backdropShadow }, transitionDuration: 0 }}
                open={!!path}
                classes={{ modalGrid: classes.modalGrid, modalHeader: classes.modalHeader, ...modalClasses }}
                {...ModalProps}
            >
                <Grid container wrap="nowrap">
                    <Grid container item xs={12} lg={sectionScroller ? 10 : 12}>
                        <Grid container direction="column" wrap="nowrap">
                            {this.renderActionHeader()}
                            <RootRef rootRef={this.parentRef}>
                                <Grid container item className={classes.overlayBody}>{this.getChildren()}</Grid>
                            </RootRef>
                            {
                                footer
                                && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        className={`${classes.overlayFooter} ${disableOverlay ? deactivateConfirmationFooter : ''}`}
                                    >
                                        {footer}
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                    {
                        isDesktop && sectionScroller && (
                            <Grid item container lg={2} alignItems="stretch">
                                {this.getSectionScroller()}
                            </Grid>
                        )
                    }
                </Grid>
                {
                    disableOverlay && <div className={deactivatedOverlay} />
                }
            </Modal>
        );
    }

    render() {
        const { path } = this.props;
        return (
            path
                ? (
                    <Route
                        path={path}
                        render={props => this.renderModal(props)}
                    />
                ) : this.renderModal()
        );
    }
}

Overlay.propTypes = {
    toggleForm: PropTypes.func,
    showHeader: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    header: PropTypes.node,
    footer: PropTypes.node,
    hideActionHeader: PropTypes.bool,
    ModalProps: (props, propName, componentName) => {
        const { ModalProps: { [propName]: open } = {} } = props;
        const { path } = props;
        if (!path && !open) {
            return new Error(`The prop 'ModalProps.open' is marked required when 'path' is not defined in '${componentName}'`);
        }
        return null;
    },
    actionItems: PropTypes.arrayOf(PropTypes.shape({
        addon: PropTypes.shape({
            start: PropTypes.node,
            end: PropTypes.node,
        }),
        label: PropTypes.node,
        onClick: PropTypes.func,
    })),
    path: PropTypes.string,
    sectionScroller: PropTypes.node,
    disableOverlay: PropTypes.bool,
};

Overlay.defaultProps = {
    showHeader: true,
    actionItems: [],
    disableOverlay: false,
    hideActionHeader: false,
};

export default withStyles(overlayStyle)(Overlay);
