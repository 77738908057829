import { AUTH_ENDPOINTS, FACILITY_ENDPOINTS } from 'Commons/config/constants/Endpoints';
import { FACILITY_STORE_KEY } from 'External/containers/dashboard/widgets/header/configs/Constants';

const updateFacilityStore = (onAction, payload = {}) => {
    const method = 'update';
    onAction({
        sync: true,
        config: [
            {
                store: {
                    key: FACILITY_STORE_KEY,
                    actionKey: `${FACILITY_STORE_KEY}_${method}`,
                    payload: { data: payload },
                },
                method,
            },
        ],

    });
};

const callApi = (onAction, endPoint, body, callback = () => {}, shouldSendCustomHeader = true, method = 'POST',
    storeMethod = 'read') => {
    if (onAction) {
        onAction({
            config: [{
                api: {
                    method: storeMethod,
                    action: {
                        methodType: `${method}`,
                        endPoint,
                        body,
                        ...shouldSendCustomHeader && {
                            customHeaderOptions: {
                                type: 'store',
                                key: 'facility',
                            },
                        },
                    },
                },
                callback,
            }],
        });
    }
};

const updateSessionTimeout = (onAction, callback) => {
    callApi(onAction, AUTH_ENDPOINTS.UPDATE_SESSION_TIMEOUT, {}, callback, false);
};

const updateFacility = (onAction, fid, body, callback) => {
    callApi(onAction, FACILITY_ENDPOINTS.update(fid), body, callback, false, 'PUT');
};

const acceptLegalAgreement = (onAction, fid, callback) => {
    callApi(onAction, FACILITY_ENDPOINTS.acceptLegalAgreement(fid), {}, callback, false);
};

export { updateSessionTimeout, updateFacility, acceptLegalAgreement, updateFacilityStore };
