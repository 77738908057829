const screenMaxWidth = {
    smallMobile: 360,
    mobile: 480,
    phablet: 767,
    tablet: 1279,
    laptop: 1439,
    desktop: 1920,
};

const screenMinWidth = {
    smallMobile: 240,
    mobile: 360,
    phablet: 480,
    tablet: 768,
    laptop: 1280,
    desktop: 1440,
};


export {
    screenMinWidth,
    screenMaxWidth,
};
