/* eslint-disable import/prefer-default-export */
const belongsToAnyOfGivenSources = (source, irrelevantSources = []) => irrelevantSources.includes(source);

const getTruncatedTitle = (
    title,
    shouldTruncateTitle = false,
    truncateTitleLength = 10,
) => (shouldTruncateTitle
    ? `${title.substring(0, truncateTitleLength)}${title.substring(truncateTitleLength) && '...'}`
    : title);

export { belongsToAnyOfGivenSources, getTruncatedTitle };
