import { mapEnum } from 'Commons/helpers/utils/Formatter';
import localisable from 'Commons/config/strings/localisable';
import enums from './Enums';

const GATE_ACCESS_TYPE = mapEnum(enums.gateAccessType);

const { unitOverlock, gateLockout } = localisable;

const EVENTS_TYPE_LIST = [
    {
        label: gateLockout,
        value: GATE_ACCESS_TYPE.Ledger_Gate_Access.value,
    },
    {
        label: unitOverlock,
        value: GATE_ACCESS_TYPE.Unit_Overlock_Status.value,
    },
];
export { GATE_ACCESS_TYPE, EVENTS_TYPE_LIST };
