
import { HEADER_VARIANT } from 'Commons/components/business/page/config/Constants';

const PageProps = {
    header: PropTypes.node,
    footer: PropTypes.node,
    title: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    fullHeight: PropTypes.bool,
    getBodyRef: PropTypes.func,
    getFooterRef: PropTypes.func,
    className: PropTypes.string,
    getHeaderRef: PropTypes.func,
    bodyClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    loaderClassName: PropTypes.string,
    headerContainerProps: PropTypes.object,
    headerVariant: PropTypes.oneOf(Object.values(HEADER_VARIANT)),
};

const PageDefaultProps = {
    headerVariant: HEADER_VARIANT.PLAIN,
    headerContainerProps: {},
};

export { PageProps, PageDefaultProps };
