import { COMPARISON_OPERATION, EMPTY_STRING } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import { ACCESS_CODE_REUSE, ACCESS_CODE_TYPE } from 'External/containers/facilityConfiguration/config/GateConfig';
import { getWhiteSpaceTrimmedString } from 'Commons/helpers/utils/StringHelpers';
import { convertToNumber } from 'Commons/helpers/utils/Utils';
import { hasValue, isValidValue } from 'Commons/helpers/utils/DataHelpers';
import countryData from 'Commons/components/business/phoneNumber/config/CountryData';
import { isUrl } from 'External/containers/lettereditor/utils/Helper';
import { getCleanPhoneNumber, getPlainPhoneNumber } from 'External/containers/tenant/form/utils/Utils';
import COMMON_PASSWORDS from '../../../config/CommonPasswords';
import { isPrimitiveType } from '../../api/Formatter';
import {
    compareDatesOrDatetime,
    dateDiff,
    dateFormatter,
    getCurrentDate,
    getMonth,
    getYear,
    isValidDate,
} from '../DateTime';
import { validateCardNumber } from './CardNumberValidator';
import { deepCopy } from '../DeepCopy';

/**
 * isEmail
 * isPhone
 * regex for name,...
 */

const isValidEmail = value => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value);

const isRequired = value => (!(value !== undefined && value !== null && value !== '' && (!isPrimitiveType(value)
    ? Object.keys(value).length : true)) && localisable.isRequired);

const isEmail = value => ((value && !isValidEmail(value)) ? localisable.isEmail : undefined);

const isNumeric = value => ((value && (!/^-?\d+(\.\d+)?$/i.test(value))) ? localisable.isNumeric : undefined);

const isAlphanumeric = value => ((value && (!/^[0-9a-zA-Z]+$/i.test(value))) ? localisable.isAlphanumeric : undefined);

const isValidAccessCode = value => ((value && (!(value.length === 6))) ? localisable.isValidAccessCode : undefined);

const isValidPasswords = (value = '') => ((value
    && (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,20}$/g.test(value))))
    ? localisable.isValidPasswords
    : undefined);

const isCommonPassword = (value = '') => ((value && COMMON_PASSWORDS.has(value.toLowerCase()))
    ? localisable.noCommonPassword
    : undefined);

const isValidUrl = (value = '', supportWildChard) => (value && !isUrl(value, supportWildChard)
    ? localisable.validUrlError
    : undefined);

const isContextualString = (value = '', username = '') => {
    if (value && username) {
        const nameInEmail = username.split('@')[0] || '';
        const contextualStrings = ['syrasoft', 'codeparva'];
        if (nameInEmail) {
            nameInEmail.split(/[.\-_]/).forEach((subName) => {
                if (subName.length >= 3) {
                    contextualStrings.push(subName);
                }
            });
        }
        if (contextualStrings.some(contextualString => value.includes(contextualString))) {
            return localisable.noContextualString;
        }
    }
    return undefined;
};

const isValidFacilityAbbreviation = value => (((value && value.length !== 3))
    ? localisable.isValidFacilityAbbreviation
    : undefined);

const isMaxLength = (value, maxLength = '20') => ((value && value.length > maxLength)
    ? `${localisable.isMaxLength} ${maxLength}` : undefined);

const isMinLength = (value, minLength = '0') => ((value && value.length < minLength)
    ? `${localisable.minLength} ${minLength}` : undefined);

const isPositiveNumeric = value => ((value && !(value >= 0))
    ? localisable.isValidAmount
    : undefined
);

const isNonZeroPositiveNumeric = value => ((value && !(value > 0))
    ? localisable.nonZeroPositiveAmount
    : undefined
);

const isValidCardNumber = value => ((!value || validateCardNumber(value))
    ? undefined : localisable.enterValidCardNumber
);

const isExpiryLessThanToday = (_, { month = '', year = '' }) => {
    const today = getCurrentDate();
    const dateToCompare = dateFormatter(`${year || getYear(today)}-${((`0${month}`).slice(-2) || getMonth(today))}-01`, 'YYYY-MM-DD');
    return compareDatesOrDatetime(dateToCompare, today, COMPARISON_OPERATION.LT) || !dateToCompare ? localisable.invalidDate : '';
};


const isDate = (value = {}) => {
    const date = deepCopy(value);
    if (value instanceof Object) {
        if (hasValue(value, 'month')) {
            date.month -= 1;
        }
    }
    return ((value && !isValidDate(date)) ? localisable.invalidDate : undefined);
};

const isTwoDecimalPlaces = value => ((value && !(/^-?\d*(\.\d{0,2})?$/.test(value)))
    ? localisable.twoDecimalPlaces : undefined);

const isInteger = value => ((value && !(/^[-+]?\d+$/.test(value))) ? localisable.enterInteger : undefined);

const isGreaterThan = (value, maxValue = 0) => {
    if (!isValidValue(value)) return undefined;
    return (convertToNumber(maxValue) - convertToNumber(value)) < 0 ? `${localisable.cannotIncrease}` : undefined;
};

const isLessThan = (value, minValue = 0) => {
    if (!isValidValue(value)) return undefined;
    return (convertToNumber(value) - convertToNumber(minValue)) < 0 ? `${localisable.cannotDecrease}` : undefined;
};

const isGreaterAndEquals = (value, maxValue = 0) => ((convertToNumber(value) && convertToNumber(maxValue) - convertToNumber(value) <= 0)
    ? `${localisable.notInRange}` : undefined);

const isLesserAndEquals = (value, minValue = 0) => ((convertToNumber(value) && convertToNumber(value) - convertToNumber(minValue) <= 0)
    ? `${localisable.notInRange}` : undefined);

const isNotEmptyString = value => (value && (/^\s+$/.test(value)) ? localisable.isRequired : undefined);

const isLevelLabelDuplicate = (value, existingValues) => {
    if (!value) return localisable.isRequired;
    const { levelLabelToLevelKeyMap, levelKey: thisLevelKey } = existingValues;
    const levelKeyOfCurrentLabel = levelLabelToLevelKeyMap[getWhiteSpaceTrimmedString(value)];
    return (levelKeyOfCurrentLabel && levelKeyOfCurrentLabel !== thisLevelKey
        ? localisable.chooseADifferentName : undefined);
};

const isPhoneNumberDuplicate = (value, samePhoneNumbersCount) => {
    const cleanedPhone = getCleanPhoneNumber(getPlainPhoneNumber(value));
    if ((samePhoneNumbersCount[cleanedPhone] || 0) > 1) {
        return localisable.duplicateNumber;
    }
    return undefined;
};

const isUnitLabelDuplicate = (value, { selectedUnitId, labelToIdMap }) => {
    const idOfAlreadyExistingUnit = labelToIdMap[value];
    return idOfAlreadyExistingUnit && idOfAlreadyExistingUnit !== selectedUnitId
        ? localisable.unitAlreadyExists : undefined;
};

const validateRangeAndLength = (value, validRange = {}, length = {}) => {
    const { start = 0, end = 1 } = validRange;
    const { minimum = 0, maximum = 0 } = length;
    if (convertToNumber(value) < convertToNumber(start) || convertToNumber(value) > convertToNumber(end)) return localisable.outOfRange;
    if (String(value).length < minimum || String(value).length > maximum) return localisable.invalidCodeLength;
    return EMPTY_STRING;
};

const validateAccessCodeType = (code, accessCodeType) => {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    const numericRegex = /^[0-9]+$/;
    if (accessCodeType === ACCESS_CODE_TYPE.Alphanumeric.value && !alphanumericRegex.test(code)) return localisable.invalidCodeType;
    if (accessCodeType === ACCESS_CODE_TYPE.Numeric.value && !numericRegex.test(code)) return localisable.invalidCodeType;
    return EMPTY_STRING;
};

const isValidGateAccessCode = (value, {
    reusePolicy, accessCodesMap, tenantId, validRange, length, unitsMapOfSameTenant,
    isLedgerBasedGate = true, unitId, accessCodeType,
}) => {
    const errorMessage = validateRangeAndLength(value, validRange, length) || validateAccessCodeType(value, accessCodeType);
    if (errorMessage) return errorMessage;

    if (reusePolicy === ACCESS_CODE_REUSE.Always.value) return null;

    const resourceIdListWithSameAccessCode = accessCodesMap[value] || []; // This will either be list of tenant ids or unit ids
    const doesAccessCodeBelongToADifferentTenant = resourceIdListWithSameAccessCode.some(id => (isLedgerBasedGate
        ? (id !== tenantId) : !unitsMapOfSameTenant[id]));

    if (reusePolicy === ACCESS_CODE_REUSE.Same_Tenant_only.value && !doesAccessCodeBelongToADifferentTenant) {
        return null;
    }

    const accessCodeMapWithOutCurrentResource = { ...accessCodesMap };
    if (!isLedgerBasedGate) {
        if (accessCodesMap[value] && accessCodesMap[value].length === 1 && accessCodesMap[value][0] === unitId) {
            delete accessCodeMapWithOutCurrentResource[value];
        } else if (accessCodesMap[value] && accessCodesMap[value].length > 1 && accessCodesMap[value].includes(unitId)) {
            accessCodeMapWithOutCurrentResource[value] = accessCodeMapWithOutCurrentResource[value].filter(item => item !== unitId);
        }
    }

    if (reusePolicy === ACCESS_CODE_REUSE.Never.value) {
        // If ledger based, then allow same access code to be used for the same tenant in `Never` case
        if (isLedgerBasedGate && !doesAccessCodeBelongToADifferentTenant) return null;

        // If unit based, then do not allow same access code to be used for any unit in `Never` case
        if (!isLedgerBasedGate) return !accessCodeMapWithOutCurrentResource[value] ? null : localisable.duplicateAccessCode;
    }

    return localisable.duplicateAccessCode;
};

const isDaysToDeliverBeforeValidForEffectiveDate = (value, { effectiveDate }) => {
    const daysBetweenTodayAndEffDate = dateDiff(effectiveDate, getCurrentDate());
    return value && !(daysBetweenTodayAndEffDate - value > 0) ? localisable.invalidDaysToDeliverBefore : undefined;
};

const isPastDate = date => (compareDatesOrDatetime(date, getCurrentDate(), COMPARISON_OPERATION.LT)
    ? localisable.invalidPastDate : '');

const isFutureDate = date => ((compareDatesOrDatetime(date, getCurrentDate(), COMPARISON_OPERATION.GT))
    ? localisable.invalidFutureDate : '');

const minDate = (date, value) => (compareDatesOrDatetime(date, value, COMPARISON_OPERATION.LT)
    ? localisable.invalidDate : '');

const maxDate = (date, value) => (compareDatesOrDatetime(date, value, COMPARISON_OPERATION.GT)
    ? localisable.invalidDate : '');

const isValidPhone = ({ country, countryCode, areaCode, number } = {},
    { required = false } = {}) => {
    const phone = getPlainPhoneNumber({ countryCode, areaCode, number });
    const bestGuess = countryData.allCountries.find(({ iso2 }) => iso2 === country) || {};
    let error = '';
    if (countryCode && required) {
        if (areaCode && areaCode.trim() === '') return localisable.invalidAreaCode;
        const { format } = bestGuess;
        const numberLength = format ? (format.match(/\./g) || []).length : 0;
        const enteredNumberLength = phone.length;
        if (enteredNumberLength < numberLength) {
            error = `${localisable.totalNumberCheck} ${numberLength}`;
        } else {
            error = '';
        }
    }
    return error;
};

const validateCommaSeparatedEmail = (value, { additionalEmailsToCheck = [], errorMsg }) => {
    if (value) {
        const emailList = value.split(',');
        const emailSet = new Set();
        if (additionalEmailsToCheck.length) {
            emailList.concat(additionalEmailsToCheck);
            emailSet.add(...new Set(additionalEmailsToCheck));
        }

        for (let i = 0; i < emailList.length; i += 1) {
            const email = emailList[i].trim();

            // Check for consecutive commas with no email address in between
            if (email === EMPTY_STRING && i > 0 && emailList[i - 1].trim() === EMPTY_STRING) {
                return localisable.invalidValue;
            }

            if (email && !isValidEmail(email)) {
                return localisable.invalidEmailAddress.replace('{}', email);
            }

            // Check for duplicate email addresses
            if (email !== EMPTY_STRING && emailSet.has(email)) {
                if (additionalEmailsToCheck.includes(email) && errorMsg) return errorMsg;
                return localisable.duplicateEntry.replace('{}', email);
            }

            emailSet.add(email);
        }
    }
    return undefined;
};

export {
    minDate,
    maxDate,
    isValidPhone,
    isPastDate,
    isRequired,
    isEmail,
    isNumeric,
    isAlphanumeric,
    isValidAccessCode,
    isValidPasswords,
    isValidFacilityAbbreviation,
    isMaxLength,
    isPositiveNumeric,
    isDate,
    isTwoDecimalPlaces,
    isGreaterThan,
    isLessThan,
    isInteger,
    isNotEmptyString,
    isLevelLabelDuplicate,
    isUnitLabelDuplicate,
    isNonZeroPositiveNumeric,
    isValidCardNumber,
    isGreaterAndEquals,
    isLesserAndEquals,
    isValidGateAccessCode,
    isExpiryLessThanToday,
    isMinLength,
    isCommonPassword,
    isContextualString,
    isDaysToDeliverBeforeValidForEffectiveDate,
    isFutureDate,
    isValidUrl,
    isPhoneNumberDuplicate,
    validateCommaSeparatedEmail,
    isValidEmail,
};
