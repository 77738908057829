import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { FormTextField } from 'Generic/textfield/components/TextField';
import localisable from 'Commons/config/strings/localisable';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import Button from 'Generic/button/components/Button';
import customTitleBarStyles from '../styles/CustomTitleBarStyles';

const useStyles = makeStyles(customTitleBarStyles);

const CustomTitleBar = ({
    readOnly,
    configId,
    isFormActive,
    showAlertDialog,
    headerActions,
    className,
    statusButtonProps,
    configFormClassRef,
    ...props
}) => {
    const classes = useStyles();

    const renderRegularStatusButton = () => (
        <Button
            disableRipple
            variant="outlined"
            color={isFormActive ? 'error' : 'primary'}
            onClick={showAlertDialog}
            className={classes.statusButton}
            disabled={readOnly}
            {...statusButtonProps}
        >
            {isFormActive ? localisable.deactivate : localisable.activate}
        </Button>
    );

    const renderStatusButton = () => (configFormClassRef
        ? configFormClassRef.renderStatusButton({ buttonProps: statusButtonProps })
        : renderRegularStatusButton());

    return (
        <>
            <Grid container alignItems="center" sm={6} item wrap="nowrap" className={className}>
                <Grid className={classes.auto}>
                    <FormTextField
                        name="description"
                        typographyVariant="h6"
                        placeholder={localisable.enterDescription}
                        noGrid
                        required
                        inputProps={{ className: classes.nameTextField }}
                        FormControlProps={{ fullWidth: false }}
                        readOnly={readOnly}
                        {...props}
                    />
                </Grid>
                {configId && renderStatusButton()}
            </Grid>
            <Grid item sm={6} className={classes.action}>
                {
                    headerActions.map(({ id, action }) => (
                        <React.Fragment key={id}>
                            {action}
                        </React.Fragment>
                    ))
                }
            </Grid>
        </>
    );
};

CustomTitleBar.propTypes = {
    readOnly: PropTypes.bool,
    configId: PropTypes.string,
    className: PropTypes.string,
    isFormActive: PropTypes.bool,
    showAlertDialog: PropTypes.func,
    headerActions: PropTypes.array,
    statusButtonProps: PropTypes.object,
    configFormClassRef: PropTypes.object,
};

CustomTitleBar.defaultProps = {
    readOnly: false,
    showAlertDialog: EMPTY_FUNC,
    headerActions: [],
    statusButtonProps: {},
};

export default React.memo(CustomTitleBar);
