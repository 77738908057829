import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { LOCKOUT_TYPE } from 'Commons/config/constants/Constants';
import DelinquencyTrackingWrongEntriesFilterStyle from '../styles/DelinquencyTrackingWrongEntriesFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';


const useStyles = makeStyles(DelinquencyTrackingWrongEntriesFilterStyle, { name: 'DelinquencyTrackingWrongEntriesFilterStyle' });

const DelinquencyTrackingWrongEntriesFilter = ({ formProps: { setFieldValue } = {} }) => {
    const classes = useStyles();

    return (
        <>
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.events}
            </Typography>
            <FormCheckboxGroup
                name="terms.events"
                list={Object.values(LOCKOUT_TYPE)}
                row
                noGrid
                noIndicatorWrapper
            />
            <RangeFilter
                name="totalBalance"
                setFieldValue={setFieldValue}
                label={localisable.ledgerBalance}
            />
        </>
    );
};

DelinquencyTrackingWrongEntriesFilter.propTypes = { formProps: PropTypes.object };


export default memo(DelinquencyTrackingWrongEntriesFilter);
