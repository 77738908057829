import PropTypes from 'prop-types';
import { clsx } from 'Commons/helpers/utils/clsx';
import Typography from '../../typography/components/Typography';

export default function HeaderOrFooterCell(props) {
    const { text, style, className, classes } = props;
    return (
        <Typography
            className={clsx(className, classes.headerFooterCell)}
            style={style}
            component="div"
            variant="body2"
            fontFamily="Open Sans"
        >
            {text}
        </Typography>
    );
}

HeaderOrFooterCell.propTypes = {
    className: PropTypes.string,
    style: PropTypes.shape(),
    text: PropTypes.node,
    classes: PropTypes.object.isRequired,
};

HeaderOrFooterCell.defaultProps = {
    className: '',
    style: {},
    text: '',
};
