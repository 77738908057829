import media from 'Commons/theme/MediaQueries';
import { VARIANT } from '../config/Constants';


const { innerWidth } = window;

const snackbarStyles = theme => ({
    messageContainer: {
        padding: theme.spacing(2),
        [media.tabletUp]: { width: `${innerWidth - (innerWidth * 0.75)}px` },
        color: theme.palette.common.white,
        borderRadius: 4,
    },
    message: { marginLeft: theme.spacing(2) },
    icon: { marginLeft: -5 },
    errorColorIcon: { color: theme.palette.error.main },
    error: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.icon,
    },
    warning: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.warning.icon,
    },
    success: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.icon,
    },
    info: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.information.icon,
    },
    rtns: {
        background: theme.palette.background.rtns,
        color: theme.palette.text.rtns,
        padding: theme.spacing(2),
    },
    titleContainer: {
        marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        wordBreak: 'break-word',
        alignItems: 'center',

    },
    subtitle: { marginLeft: theme.spacing(2) },
    rtnsContainer: { marginTop: theme.spacing(2) },
    actionButton: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
    },
    refreshButton: { marginLeft: theme.spacing(1) },
    titleContainerItem: ({ variant }) => ({
        maxWidth: variant === VARIANT.error.value ? '90%' : 'auto',
        overflow: 'auto',
        maxHeight: 70,
        // TODO: remove snackbar styles once error framework is decided
        '&::-webkit-scrollbar-track-piece': {
            borderRadius: 5,
            '-webkit-box-shadow': 'inset 0 0 4px rgba(67,160,71,0.3)',
        },

        '&::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            background: theme.palette[variant] && theme.palette[variant].main,
        },
    }),


    // TODO: Kept it just in case we need body again

    // expanded: {
    //     maxHeight: 106,
    //     overflow: 'auto',
    //     paddingRight: 10,

    //     '&::-webkit-scrollbar': { width: 3 },

    //     '&::-webkit-scrollbar-track': { borderRadius: 5 },
    // },
    // errorExpanded: {

    //     '&::-webkit-scrollbar-track-piece': {
    //         width: 1,
    //         borderRadius: 5,
    //         '-webkit-box-shadow': 'inset 0 0 4px rgba(244,0,0,0.3)',
    //     },

    //     '&::-webkit-scrollbar-thumb': {
    //         borderRadius: 5,
    //         background: theme.palette.common.red,
    //     },
    // },
    // successExpanded: {
    //     '&::-webkit-scrollbar-track-piece': {
    //         borderRadius: 5,
    //         '-webkit-box-shadow': 'inset 0 0 4px rgba(67,160,71,0.3)',
    //     },

    //     '&::-webkit-scrollbar-thumb': {
    //         borderRadius: 5,
    //         background: theme.palette.success.main,
    //     },
    // },
    // warningExpanded: {

    //     '&::-webkit-scrollbar-track-piece': {
    //         borderRadius: 5,
    //         '-webkit-box-shadow': 'inset 0 0 4px rgba(197,138,38,0.3)',
    //     },

    //     '&::-webkit-scrollbar-thumb': {
    //         borderRadius: 5,
    //         background: theme.palette.warning.main,
    //     },
    // },
    // showMore: {
    //     marginRight: 'auto',
    //     right: 0,
    //     fontWeight: 600,
    //     paddingTop: 10,
    //     display: 'block',
    //     cursor: 'pointer',
    // },
    // errorShowMore: { color: theme.palette.common.red },
    // warningShowMore: { color: theme.palette.warning.main },
    // successShowMore: { color: theme.palette.success.main },
});

export default snackbarStyles;
