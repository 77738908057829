import media from 'Commons/theme/MediaQueries';

const multiSelectStyle = theme => ({
    chipWrapper: {
        maxWidth: theme.spacing(36),
        marginTop: theme.spacing(2),
        overflow: 'auto',
        maxHeight: 96,
    },
    ellipsis: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    chip: {
        height: theme.spacing(4),
        background: theme.palette.chip.reserved,
        borderColor: theme.palette.chip.reserved,
        borderRadius: theme.spacing(2),
        '&:hover, &:focus, &:active': { backgroundColor: theme.palette.chip.reserved },
    },
    chipLabel: {
        color: theme.palette.common.white,
        fontSize: theme.elements.input.label.fontSize,
    },
    container: { width: 'auto' },
    componentContainerLabel: { margin: theme.spacing(2, 0, 3, 0) },
    tooltipContainer: {},
    chipContainerLabel: {
        margin: theme.spacing(3, 0, 1, 0),
        [media.desktop]: { margin: theme.spacing(4, 0, 1, 0) },
    },
    emptyValueMessage: { margin: theme.spacing(1) },
    tooltipLabel: {},
    tooltipDisabled: { cursor: 'default' },
    indicator: {
        fontSize: 8,
        [media.laptop]: { fontSize: 6 },
        [media.desktop]: { fontSize: 8 },
        cursor: 'default',
        lineHeight: 2,
        color: theme.palette.error.main,
    },
    indicatorWrapper: {
        minWidth: theme.spacing(2),
        minHeight: 0,
        maxWidth: theme.spacing(2),
    },
    InputContainer: { width: 'auto' },
});

export default multiSelectStyle;
