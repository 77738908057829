import { encryptWithPublicKey } from 'Commons/helpers/utils/Cryptography';
import { maskCharacters } from 'Commons/helpers/utils/Utils';
import localisable from 'Commons/config/strings/localisable';
import { PROCESSOR_TYPE } from 'External/containers/facilityConfiguration/config/Constants';
import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import { isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import { HOSTED_FORM_AUTH_TYPE, PAYMENT_ACTION } from '../config/Constants';

const creditCardPreProcessor = async (values, payUsing, publicKey, cardProcessorData = {}, isRefund,
    { transactionId, token, response } = {}) => {
    const {
        extra,
        cardInfo: cardDetails = {},
        useCardOnFile = false,
        ...paymentDetails
    } = values;
    const { cardProcessorId, amount, doNotProcessCard } = paymentDetails;
    const { [cardProcessorId]: { data: { processorType } = {} } = {} } = cardProcessorData;
    const updatedPaymentDetails = deepCopy(paymentDetails);
    const { saveCardForFutureTransactions, autoPayEnabled, ...cardInfo } = cardDetails;

    let billingInfo = {};
    if (response) {
        try {
            const { requestDetails: { zip, address1, nameOnAccount, country } = {} } = JSON.parse(response);
            billingInfo = { name: nameOnAccount, address: { line1: address1, zipCode: zip, country } };
        } catch {
            billingInfo = {};
        }
    }

    if (payUsing === localisable.useCardOnFile) {
        return { ...updatedPaymentDetails, cardInfo, useCardOnFile };
    } if (payUsing === localisable.enterCardDetails) {
        if (processorType === PROCESSOR_TYPE.Syrapay.value) {
            if (transactionId || token || doNotProcessCard) {
                return {
                    ...updatedPaymentDetails,
                    transactionId,
                    tokenToSave: token,
                    cardInfo: {
                        saveCardForFutureTransactions,
                        autoPayEnabled,
                        ...isObjWithKeys(billingInfo) ? { billingInfo } : {},
                    },
                    ...!isRefund && { action: PAYMENT_ACTION.PAY_VALIDATED_AMOUNT },
                };
            }
            if (isRefund) return { cardProcessorId, authType: HOSTED_FORM_AUTH_TYPE.TOKEN };
            return { cardProcessorId, authType: HOSTED_FORM_AUTH_TYPE.PAYMENT, amount };
        }
    }
    return { ...updatedPaymentDetails, cardInfo: { saveCardForFutureTransactions } };
};

const achPreProcessor = async (values, payUsing, publicKey) => {
    const {
        extra,
        achInfo: achDetails = {},
        useCardOnFile,
        cardInfo,
        ...paymentDetails
    } = values;
    if (payUsing === localisable.enterCardDetails) {
        const { displayAccountNumber, ...achInfo } = achDetails;
        const encryptedAccountNumber = await encryptWithPublicKey(displayAccountNumber, publicKey, true);
        const accountNumber = maskCharacters(displayAccountNumber);
        paymentDetails.achInfo = {
            ...achInfo,
            displayAccountNumber: accountNumber,
            encryptedAccountNumber,
        };
    } else if (payUsing === localisable.useAchOnFile) {
        paymentDetails.achInfo = achDetails;
    }
    return paymentDetails;
};

export { creditCardPreProcessor, achPreProcessor };
