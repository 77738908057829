import handleError, { handleStatusError } from '../error/ErrorHandler';
import STATUS from '../../config/constants/StoreKeyStatus';
import { curQueue } from '../../helpers/api/Queue';

/**
 * @description Based on the HTTP response codes, set the redux store keys status appropriately.
 * @param {Object} apiResult The API Result returned from server
 * @param {String} action The redux action key
 * @param {*} callback A Callback to component, to handle errors.
 */
const postProcess = (apiResult, callback, dispatch, localReqOptions) => {
    const { error: err, response = {}, timestamp } = apiResult;
    const { skipCustomPostProcessor, storeData } = localReqOptions;

    const { status } = response || {};

    const isQueued = status === STATUS.QUEUED;

    if (!isQueued) {
        // Removing request via timestamp
        curQueue.remove(timestamp, storeData);
    }

    if (!skipCustomPostProcessor) {
        if (err) {
            console.log('Error:', err);
            handleError(err, callback);
            return handleStatusError(err, dispatch, localReqOptions);
        }
        if (!isQueued && callback) {
            callback(err, response);
        }
        // No Error, Successful response
        if (isQueued) {
            return {
                data: {},
                status: STATUS.LOADING,
            };
        }
        return {
            data: response,
            status: STATUS.LOADED,
        };
    }

    return {};
};

export default postProcess;
