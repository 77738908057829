const popdownStyle = {
    popdown: { position: 'relative' },
    paperHeight: ({ maxHeight }) => ({ maxHeight }),
    paper: {
        overflowY: 'auto',
        wordWrap: 'break-word',
    },
    popper: { zIndex: 1, '&[x-out-of-boundaries]': { visibility: 'hidden' } },
};

export default popdownStyle;
