import React, { memo } from 'react';
import { Grid, Typography, makeStyles } from 'Generic/componentlibrary/components/Components';
import { Dropdown } from 'Commons/components/generic/dropdown/components/Dropdown';
import { DatePicker } from 'Generic/datepicker/components/DatePicker';
import Button from 'Commons/components/generic/button/components/Button';
import { getCurrentDate } from 'Commons/helpers/utils/DateTime';
import { CALENDAR_VIEW_TYPE, DATE_FORMAT, EMPTY_FUNC } from 'Commons/config/constants/Constants';
import { DATE_SEEK_DIRECTION } from 'External/containers/pendingTasks/components/reminders/config/Constants';
import calendarHeaderStyle from '../styles/CalendarHeaderStyle';

const useStyles = makeStyles(calendarHeaderStyle, { name: 'CalendarHeader' });

const Header = ({
    displayedDate,
    selectedView,
    viewTypeList,
    handleViewChange,
    onDateSeek,
    datePickerDateFormat,
    onDateChange,
    createButtonText,
    onCreateButtonClick,
    getDisplayText,
}) => {
    const classes = useStyles();

    const renderDateSeekButton = seekDirection => (
        <Button
            variant="icon"
            iconType="custom"
            color="primary"
            icon={`cp-chevron-${seekDirection === DATE_SEEK_DIRECTION.BACKWARD ? 'left' : 'right'}`} // TODO: Change to double arrow icon when uploaded
            onClick={() => onDateSeek(seekDirection)}
        />
    );

    return (
        <>
            <Grid container wrap="nowrap" justify="flex-start" alignItems="center" className={classes.headerContainer}>
                <Grid item xs={2} justify="flex-start" alignItems="center">
                    <DatePicker
                        value={displayedDate}
                        onChange={(_, value) => onDateChange(value)}
                        trackValue={false}
                        dataType="date"
                        format={datePickerDateFormat}
                        width={100}
                        classes={{ datePickerContainer: classes.datePickerContainer }}
                        textFieldClasses={{
                            marginTop: classes.dateTextFieldInputMarginTop,
                            input: classes.dateTextFieldInput,
                        }}
                        InputProps={{
                            classes: { disabled: classes.disabledDatePickerOverride },
                            ...(getDisplayText && { inputComponent: () => getDisplayText(displayedDate, selectedView) }),
                        }}
                        disableUnderline
                        openPickerOnInputFieldFocus
                        addon={{ end: null }}
                        additionalIcon={{
                            left: renderDateSeekButton(DATE_SEEK_DIRECTION.BACKWARD),
                            right: renderDateSeekButton(DATE_SEEK_DIRECTION.FORWARD),
                        }}
                        PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                        {...(selectedView === CALENDAR_VIEW_TYPE.Month.value && { views: ['month', 'year'] })}
                    />
                </Grid>
                <Grid item xs={1} alignItems="center" className={classes.titleSpacing}>
                    <Dropdown
                        placeholder="Select View Type"
                        value={selectedView}
                        list={viewTypeList}
                        trackValue={false}
                        onChange={(_, value) => handleViewChange(value)}
                        skipSort
                    />
                </Grid>
                { createButtonText
                && (
                    <Grid
                        container
                        item
                        xs={2}
                        justify="flex-end"
                        alignItems="center"
                        className={classes.createButtonGrid}
                        onClick={onCreateButtonClick}
                    >
                        <Typography color="primary" variant="body1">
                            {createButtonText}
                        </Typography>
                    </Grid>
                )
                }
            </Grid>
        </>
    );
};


Header.propTypes = {
    displayedDate: PropTypes.string,
    selectedView: PropTypes.string,
    viewTypeList: PropTypes.array,
    handleViewChange: PropTypes.func,
    onDateSeek: PropTypes.func,
    datePickerDateFormat: PropTypes.string,
    onDateChange: PropTypes.func,
    createButtonText: PropTypes.string,
    onCreateButtonClick: PropTypes.func,
    getDisplayText: PropTypes.func,
};

Header.defaultProps = {
    displayedDate: getCurrentDate(false),
    viewTypeList: [],
    handleViewChange: EMPTY_FUNC,
    onDateSeek: EMPTY_FUNC,
    datePickerDateFormat: DATE_FORMAT,
    onDateChange: EMPTY_FUNC,
    onCreateButtonClick: EMPTY_FUNC,
};

export default memo(Header);
