
const dragAndDropStyles = theme => ({
    localDriveIcon: { size: '3rem' },
    dragOrCreate: { border: `1px dashed ${theme.palette.status.inactive}` },
    dragOrCreateContainer: {
        maxWidth: 'none',
        flex: 1,
    },
    autoWidth: { width: 'auto' },
    acceptedFormatText: { marginTop: theme.spacing(1) },
    closeButton: {},
    chooseAFile: { cursor: 'pointer' },
});

export default dragAndDropStyles;
