import moment from 'moment';
import { Form } from 'formik';
import FilterComponent from '../../FilterComponent';
import { FormDatePicker } from '../../../../../generic/datepicker/components/DatePicker';
import { SM_6 } from '../../../../../../config/constants/GridSpacingConstants';
import { Grid, withStyles } from '../../../../../generic/componentlibrary/components/Components';
import DateRangeStyle from '../styles/DateRangeStyle';

class DateRange extends FilterComponent {
    render() {
        const {
            section: { filters: [filter = ''] },
            classes,
            appliedFilters,
        } = this.props;
        if (appliedFilters[filter]) {
            this.initialValue = appliedFilters[filter].value;
        }
        return (
            <div className={classes.dateContainer}>
                {this.renderComponentTitle()}
                <Form>
                    <Grid container>
                        <FormDatePicker
                            name={`${filter}.from`}
                            value={moment(this.initialValue.from) || moment()}
                            label="From"
                            fullWidth
                            GridProps={SM_6}
                        />
                        <FormDatePicker
                            name={`${filter}.to`}
                            value={moment(this.initialValue.to) || moment()}
                            label="To"
                            fullWidth
                            GridProps={SM_6}
                        />
                    </Grid>
                </Form>
            </div>
        );
    }
}

DateRange.propTypes = {
    section: PropTypes.any.isRequired, // The current section
    componentLabel: PropTypes.string.isRequired, // The label of component
    classes: PropTypes.any,
    appliedFilters: PropTypes.any, // The current applied filters, to bind initial value
};

export default withStyles(DateRangeStyle)(DateRange);
