const colorPalette = {
    pieColorRange: ['#22C55E', '#D1D5DB', '#F87171'],
    horizontalBarColorRange: ['#16A34A', '#EF4444', '#EAB308'],
    verticalBarColorRange: ['#0095FF', '#00E096', '#F3DE8A'],
    bar: '#D1D5DB',
    graphLoading: {
        light: '#D8DDE4',
        dark: '#EEF1F5',
    },
    common: {
        black: '#000000',
        white: '#ffffff',
        secondary: '#B5763F',
    },
    primary: {
        main: '#3369ce',
        dark: '#64668C',
        light: '#CBA0C8',
    },
    secondary: {
        main: '#2e4470',
        dark: '#2e4470',
        light: '#E0D4E0',
    },
    tertiary: { main: '#F3DE8A' },
    section: { main: '#9A7197' },
    liveData: { main: '#DB7C24' },
    pulledData: { main: '#2980B9' },
    error: {
        main: '#F44336',
        dark: '#CC0000',
        light: '#FEECEB',
        icon: '#F8695E',
        100: 'rgb(244, 67, 54, 0.1)',
    },
    success: {
        main: '#43A047',
        light: '#D0E7D1',
        icon: '#59D1B0',
        onboarding: '#B3F6CF',
    },
    warning: {
        main: '#C58A26',
        light: '#FFE7BF',
        icon: '#F8A85E',
    },
    information: {
        main: '#FFE7BF',
        icon: '#62BFFD',
    },
    query: { icon: '#6E62FD' },
    text: {
        primary: 'rgba(0, 0, 0, 0.7)',
        secondary: '#2e4470',
        disabled: 'rgba(45, 27, 56, 0.3)',
        hint: '#B4B4B4',
        rtns: '#4D4D4D',
    },
    slider: {
        main: '#C8C8C8',
        light: '#BDBDBD',
    },
    background: {
        default: '#E4E8F2',
        secondary: '#B5763F33',
        letter: '#F5F5F5',
        rtns: '#FFE99B',
    },
    button: {
        hover: '#C1DEF4',
        error: '#ED0F0F',
    },
    disabled: 'rgba(46, 68, 112, 0.5)',
    hover: '#DBECFB',
    highlight: '#D8ECFB',
    preferred: 'rgba(85, 193, 255, 0.5)',
    chip: {
        tenants: '#52ADC8',
        leads: '#A073FF',
        reserved: '#387CA3',
        estimate: '#6776FF',
        config: '#2980B9',
        expired: '#D83320',
        reservation: '#2BACF5',
    },
    border: {
        main: '#796A90',
        highlight: '#CF8FD0',
    },
    status: {
        inactive: '#CBCBCB',
        created: '#2980B9',
        locked: '#FB8B24',
        active: '#2ECC71',
        cancelled: '#ED0F0F',
        upcoming: '#187554',
        leadDuplicate: '#36C7F4',
        leadLost: '#4C4C4C',
        expired: '#2D1B384D',
    },
    rentalStatus: {
        vacant: '#2ECC71',
        rented: '#FB8B24',
        unavailable: '#797979',
    },
    occupancy: { moveout: '#F04E23' },
    roles: {
        locked: 'FB8B24',
        active: '2ECC71',
    },
    rolesGroup: {
        primary: '#55C1FF',
        secondary: '#FF8A80',
        tertiary: '#8C9EFF',
        section: '#9A7197',
    },
    gradient: {
        primary: (
            direction = '180deg',
            color1 = '#9A7197',
            colorStop1 = '4.68%',
            color2 = '#13547A',
            colorStop2 = '141.7%',
        ) => `linear-gradient(${direction}, ${color1} ${colorStop1}, ${color2} ${colorStop2})`,
    },
    black: {
        100: 'rgba(0, 0, 0, 0.13)',
        200: 'rgba(0, 0, 0, 0.2)',
        300: 'rgba(0, 0, 0, 0.3)',
        400: 'rgba(0, 0, 0, 0.4)',
        500: 'rgba(0, 0, 0, 0.5)',
        600: 'rgba(0, 0, 0, 0.6)',
        700: 'rgba(0, 0, 0, 0.7)',
        800: 'rgba(0, 0, 0, 0.8)',
    },
    bev: { defaultUnitBackground: '#FFFFFF', erredUnit: '#CA1F1D' },
    textHighlight: '#D32F2F33',
    disabledTextHighlight: '#D32F2F1A',
    red: '#FF0000',
    inheritedConfig: {
        main: '#FFE4C3',
        highlight: '#FFD9AB',
    },
    reminderCard: {
        reminder: '#FFD88E',
        reservation: '#FF9A6F',
        expectedMoveOut: '#6DCF77',
    },
    setUp: { pending: '#FB8B24' },
    offWhite: '#EDEDF2',
    migration: {
        notStarted: '#cbcbcb',
        dumpGenerationSuccess: '#2980B9',
        inProgress: '#FB8B24',
        completed: '#2ECC71',
        errored: '#F44336',
        deleted: '#4F4F4F',
        serverActive: '#2ecc71',
        serverInactive: '#cbcbcb',
        transparent: '#00000000',
    },
    ribbon: { active: '#59D1B0' },
    emailAudit: {
        yes: '#2ECC71',
        no: '#FB8B24',
    },
};

export default colorPalette;
