import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { RENT_ASSESSMENT_MODE } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';


const RentAssessmentHistoryFilter = () => (
        <>
            <Typography variant="subtitle1">
                {localisable.mode}
            </Typography>
            <FormCheckboxGroup
                name="terms.mode"
                list={[RENT_ASSESSMENT_MODE.Automatic, RENT_ASSESSMENT_MODE.Manual]}
                row
                noGrid
                noIndicatorWrapper
            />
        </>
);

export default memo(RentAssessmentHistoryFilter);
