import { UNIT_ENDPOINTS, CONFIG_ENDPOINTS } from 'Commons/config/constants/Endpoints';
import { VIEW } from 'Commons/config/constants/Constants';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import { ENTITY_TYPE } from 'External/containers/configuration/config/Constants';
import { ADVANCE_OPTIONS_ID } from 'External/containers/facilityConfiguration/config/Constants';

const callApi = (onAction, endPoint, body,
    callback = () => { }, method = 'POST', shouldSendCustomHeader = true) => {
    const api = {
        method: 'read',
        action: {
            methodType: `${method}`,
            endPoint,
            body,
            ...shouldSendCustomHeader && {
                customHeaderOptions: {
                    type: 'store',
                    key: 'facility',
                },
            },
        },
    };
    if (onAction) {
        onAction({
            config: [{
                api,
                callback,
            }],
        });
    }
};

const fetchInsuranceConfiguration = (onAction, facilityId, callback) => {
    const body = {
        view: VIEW.detail.value,
        filter: [{
            terms: {
                configType: [CONFIG_TYPE.INSURANCE],
                entityType: [ENTITY_TYPE.FACILITY],
                entityId: [facilityId],
            },
        }],
    };
    callApi(onAction, CONFIG_ENDPOINTS.search, body, callback, 'POST', false);
};

const fetchInsuranceCoverage = (onAction, facilityId, planId, coverageId, provider, callback) => {
    const body = {
        view: VIEW.detail.value,
        filter: [...planId !== ADVANCE_OPTIONS_ID && planId
            ? [{
                terms: {
                    configType: [CONFIG_TYPE.INSURANCE_COVERAGE],
                    entityType: [ENTITY_TYPE.SYSTEM_FACILITY],
                    value: { planId: [planId] },
                },
            },
            ] : [{
                terms: {
                    configType: [CONFIG_TYPE.INSURANCE_COVERAGE],
                    entityType: [ENTITY_TYPE.FACILITY],
                    entityId: [facilityId],
                },
            }], ...provider
            ? [{
                terms: {
                    configType: [CONFIG_TYPE.INSURANCE_PROVIDER],
                    entityType: [ENTITY_TYPE.SYSTEM_FACILITY],
                    id: [provider],
                },
            }] : [],
        ...coverageId
            ? [{
                terms: {
                    configType: [CONFIG_TYPE.INSURANCE_COVERAGE],
                    id: [coverageId],
                    entityType: [ENTITY_TYPE.FACILITY],
                    entityId: [facilityId],
                },
            },
            {
                terms: {
                    configType: [CONFIG_TYPE.INSURANCE_COVERAGE],
                    id: [coverageId],
                    entityType: [ENTITY_TYPE.SYSTEM_FACILITY],
                },
            },
            ] : [],
        ],
    };
    callApi(onAction, CONFIG_ENDPOINTS.search, body, callback, 'POST', false);
};

const fetchInsuranceInfo = (onAction, unitId, body, callback) => {
    callApi(onAction, UNIT_ENDPOINTS.insuranceInfo(unitId), body, callback);
};

const saveInsuranceInfo = (onAction, unitId, body, callback) => {
    callApi(onAction, UNIT_ENDPOINTS.saveInsurance(unitId), body, callback);
};

const cancelInsurance = (onAction, unitId, callback) => {
    callApi(onAction, UNIT_ENDPOINTS.cancelInsurance(unitId), {}, callback);
};

export { fetchInsuranceConfiguration, fetchInsuranceCoverage, fetchInsuranceInfo, saveInsuranceInfo, cancelInsurance };
