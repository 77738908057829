import {
    calcTriangleDimension,
    getAppropriateTextColor,
    getConvertedWH,
    getFontSize,
} from 'Commons/components/business/bev/utils/Utils';
import { BOARD } from 'External/containers/facilityConfiguration/components/bevColorSetup/components/config/Constants';
import { UNIT_CONFIG } from 'Commons/components/business/bev/config/Config';
import { TRIANGLE_POSITIONS } from 'Commons/components/business/bev/config/Constants';

const getTriangleStyle = ({ entityBevData = {}, isFacilityMigrated = false }, trianglePosition) => {
    const {
        TOP_LEFT,
        TOP_RIGHT,
        BOTTOM_RIGHT,
    } = TRIANGLE_POSITIONS;
    const {
        width: bevWidth,
        depth: bevHeight,
        color: {
            [BOARD.LOCK_OVERLOCK_STATUS]: lockOverlockBoard,
            [BOARD.MAINTENANCE_ACTIVITY]: maintenanceActivityBoard,
            [BOARD.DELINQUENCY_STATUS]: delinquencyStatusBoard,
        } = {},

    } = entityBevData;

    const { color: bottomRightColor } = lockOverlockBoard || {};
    const { color: topRightColor } = maintenanceActivityBoard || {};
    const { color: topLeftColor } = delinquencyStatusBoard || {};

    const { width: unitWidth, height: unitHeight } = getConvertedWH(bevWidth, bevHeight, isFacilityMigrated);
    const triangleSideDimension = calcTriangleDimension(unitWidth, unitHeight);
    if (bottomRightColor && trianglePosition === BOTTOM_RIGHT) {
        return {
            right: 0,
            bottom: 0,
            borderWidth: `0 0 ${triangleSideDimension}px ${triangleSideDimension}px`,
            borderColor: `transparent transparent ${bottomRightColor} transparent`,
        };
    }
    if (topRightColor && trianglePosition === TOP_RIGHT) {
        return {
            right: 0,
            top: 0,
            borderWidth: `0 ${triangleSideDimension}px ${triangleSideDimension}px 0`,
            borderColor: `transparent ${topRightColor} transparent transparent`,
        };
    }
    if (topLeftColor && trianglePosition === TOP_LEFT) {
        return {
            left: 0,
            top: 0,
            borderWidth: `${triangleSideDimension}px ${triangleSideDimension}px 0 0`,
            borderColor: `${topLeftColor} transparent transparent transparent`,
        };
    }
    return {};
};

const bevViewStyles = theme => ({
    collection: { outline: 'none' },
    entity: ({ entityBevData = {}, zoomValue, isFacilityMigrated = false }) => {
        const {
            x: bevX,
            y: bevY,
            width: bevWidth,
            depth: bevHeight,
            angle = 0,
            color: { [BOARD.RENTAL_AVAILABILITY_STATUS]: { color: mainColor = UNIT_CONFIG.DEFAULT_BACKGROUND } = {} } = {},
        } = entityBevData;
        const { width, height } = getConvertedWH(bevWidth, bevHeight, isFacilityMigrated);
        return (
            {
                backgroundColor: mainColor,
                color: getAppropriateTextColor(mainColor),
                width,
                height,
                position: 'absolute',
                left: bevX * zoomValue,
                top: bevY * zoomValue,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                WebkitTransform: `scale(${zoomValue}) rotate(${angle}deg)`,
                WebkitTransformOrigin: 'left top',
                border: `0.2px solid ${mainColor.slice(0, 7).toLowerCase()
                === UNIT_CONFIG.DEFAULT_BACKGROUND.toLowerCase()
                    ? theme.palette.black[500] : 'white'}`,
                overflow: 'hidden',
            }
        );
    },
    entityLabelContainer: {
        flex: 1,
        minWidth: 0,
        textAlign: 'center',
    },
    unitDoubleStrokeEffect: {
        border: '2px solid white',
        outline: '1px solid black',
    },
    entityLabel: {
        WebkitTransform: ({ entityBevData: { angle = 0 } = {} }) => (`rotate(${-angle}deg)`),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: ({ zoomValue } = {}) => (getFontSize(zoomValue)),
        direction: 'rtl',
    },
    triangle: {
        position: 'absolute',
        width: 0,
        height: 0,
        borderStyle: 'solid',
    },
    tooltipChipRoot: ({ entityBevData = {} }) => {
        const { color: { [BOARD.RENTAL_AVAILABILITY_STATUS]: { color: mainColor = UNIT_CONFIG.DEFAULT_BACKGROUND } = {} } = {} } = entityBevData;
        return { backgroundColor: mainColor, color: getAppropriateTextColor(mainColor) };
    },
    bottomRightTriangle: arg => getTriangleStyle(arg, TRIANGLE_POSITIONS.BOTTOM_RIGHT),
    topRightTriangle: arg => getTriangleStyle(arg, TRIANGLE_POSITIONS.TOP_RIGHT),
    topLeftTriangle: arg => getTriangleStyle(arg, TRIANGLE_POSITIONS.TOP_LEFT),
});

export default bevViewStyles;
