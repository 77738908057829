import media from 'Commons/theme/MediaQueries';

const pageStyle = theme => ({
    page: {
        flex: 1,
        overflow: 'auto',
        position: 'relative',
    },
    fullHeight: { height: '100%' },
    pageHeader: {
        minHeight: theme.spacing(5),
        borderRadius: 4,
        padding: theme.spacing(0.5, 2),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.common.white,
        [media.desktop]: {
            marginBottom: theme.spacing(1),
            minHeight: theme.spacing(6),
            padding: theme.spacing(1, 4),
        },
    },
    withContent: { [media.desktop]: { padding: theme.spacing(1, 4) } },
    pageBody: {
        flex: 1,
        overflow: 'auto',
        borderRadius: 4,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        [media.laptopUp]: { padding: theme.spacing(1, 2) },
        [media.desktop]: { padding: theme.spacing(2, 4) },
    },
    pageFooter: {
        marginTop: theme.spacing(0.5),
        [media.desktop]: { marginTop: theme.spacing(1) },
    },
    loader: {
        ...theme.globalClasses.deactivatedOverlay.style,
        position: 'absolute',
        backgroundColor: `${theme.palette.common.white}D9`,
        opacity: 1,
    },
});

export default pageStyle;
