/* eslint-disable no-nested-ternary */
import { Dropdown, FormDropdown } from 'Generic/dropdown/components/Dropdown';
import AlertDialog from 'Commons/components/generic/alertdialog/components/AlertDialog';
import Button from 'Generic/button/components/Button';
import Snackbar from 'Commons/components/generic/snackbar/components/Snackbar';
import { useEffect, useMemo, useRef, useState } from 'react';
import { formatErrors } from 'Commons/redux/error/ErrorHandler';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import ThreeDotProgress from 'Generic/threedotprogress/components/ThreeDotProgress';
import { FormTextField, TextField } from 'Generic/textfield/components/TextField';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { convertToPdfUrl } from 'Commons/components/business/report/utils/Utils';
import { clsx } from 'Commons/helpers/utils/clsx';
import { IS_NON_ZERO_POSITIVE_NUMERIC } from 'Commons/config/constants/Validators';
import useDidUpdateEffect from 'Commons/helpers/hooks/useDidUpdateEffect';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import { MOVE_IN_DOCUMENTS } from 'External/containers/rentalConfiguration/config/RentalFormConfigs';
import { ENTITY_TYPE } from 'External/containers/configuration/config/Constants';
import REQUEST_TYPES from 'Commons/config/constants/RequestTypes';
import { fetchPrinter } from 'Commons/helpers/utils/PrinterHelper';
import { fetchDocument, getTxtToken, previewLetter } from 'External/containers/operations/components/letterUtil/config/ApiRequests';
import InheritedConfigLabel from 'Commons/components/generic/InheritedConfigLabel/components/InheritedConfigLabel';
import { fetchConfiguration } from 'External/containers/configuration/config/ApiRequests';
import Footer from 'Commons/components/business/footer/components/Footer';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import Modal from 'Generic/modal/components/Modal';
import { getValue, isNonEmptyArray, isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import PrinterSection from 'External/containers/dashboard/widgets/header/components/PrinterSection';
import { ADDITIONAL_DELIVERY_METHOD, DELIVERY_METHOD, PRINT_COPIES_TEXTFIELD_WIDTH, DROPDOWN_PRINTER_SELECTION_TYPES } from '../config/Constants';
import { fetchLetter, fetchStandaloneApp } from '../config/ApiRequests';
import DeliveryMethodDropdownStyles from '../styles/DeliveryMethodDropdownStyles';


const useStyles = makeStyles(DeliveryMethodDropdownStyles, { name: 'DeliveryMethodDropdown' });


const DeliveryMethodDropdown = ({
    onAction, letterId, recipientEmailEnabled, recipientTextEnabled, shouldShowDropdown,
    currentFacility: { data: { id: facilityId } = {} } = {}, allowEsign, isWebsiteAccess = false,
    name, useForm, dropdownProps, fid, showPrintCopies, inlinePrintComponents, containerProps,
    documentType = '',
    previewProps = {},
    printOrPreviewProps: {
        name: printCopiesFieldName,
        useForm: printCopiesFieldUseForm,
        initialValue: printOrPreviewInitialValue = '',
        containerClassName: printContainerClassName,
        setFieldValue,
        onChangePrintCopies,
        ...otherPrintProps
    },
    onChange,
    showPreview,
    standaloneApps = [],
    letterConfigs = [],
    facilityGeneralConfig = {},
    isFormActive,
    printer,
    currentPrinter,
    printerSelectionProps: { show, type, containerProps: printerSelectionContainerProps, gridProps: printerSelectionGridProps },
    exclude = [],
    ...props
}) => {
    const classes = useStyles();
    // eslint-disable-next-line react/prop-types
    const { clearStoreKeys, dispatch, routeList, onMountConfig, unauthorised, fetchLazy, hasMailingLabel, ...rest } = props;
    const deliveryMethodValues = Object.values(DELIVERY_METHOD);
    const filteredMethodForMailingLabel = deliveryMethodValues.filter(method => method.value === 'Print');
    const allDeliveryMethodList = useConstructor(() => (!hasMailingLabel ? deliveryMethodValues : filteredMethodForMailingLabel));
    const [deliveryMethodList, setDeliveryMethodList] = useState(allDeliveryMethodList);
    const [error, setError] = useState('');
    const [noOfCopies, setNoOfCopies] = useState(1);
    const [deliveryMethod, setDeliveryMethod] = useState(printOrPreviewInitialValue || '');
    const { innerWidth, innerHeight } = window;

    const Component = useMemo(() => (useForm ? FormDropdown : Dropdown), [useForm]);
    const PrintCopies = useMemo(() => (printCopiesFieldName ? FormTextField : TextField), [printCopiesFieldUseForm]);

    const componentProps = useMemo(() => (useForm ? { name } : {}), [useForm]);
    const printOrPreviewProps = useMemo(() => (printCopiesFieldUseForm ? { name: printCopiesFieldName } : {}), [printCopiesFieldUseForm]);
    const modeEnabled = useRef({ email: false, phone: false });
    const isTextFriendly = useRef({});
    const isLetterFetched = useRef(false);
    const { deviceInfo: { isDesktop } = {} } = window;
    const [printerInstalled, setIsPrinterInstalled] = useState();
    const [txToken, setTxToken] = useState();
    const [{ isModalOpen = false, previewLetterLink }, setLetter] = useState({});
    const [previewTemplateLink, setPreviewTemplateLink] = useState();
    const [previewLetterId, setPreviewLetterId] = useState();
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [letterIdTemplate, setLetterIdTemplate] = useState({});
    const [showPrinterSelectionDialog, setShowPrinterSelectionDialog] = useState(false);
    const [printerSelectedStatus, setPrinterSelectedStatus] = useState(false);
    const filterDeliveryMethodList = () => {
        const { email: isEmailEnabled, phone: isPhoneEnabled } = modeEnabled.current || {};
        let applicableDeliveryMethodList = [...allDeliveryMethodList];
        const keys = [];
        if (!isEmailEnabled || !recipientEmailEnabled) {
            keys.push('Email', 'Mail');
        }
        if (!isPhoneEnabled || !recipientTextEnabled) {
            keys.push('Text');
        } else if (!isWebsiteAccess && !isTextFriendly.current[letterId]) {
            keys.push('Text');
        }
        if (isWebsiteAccess) {
            keys.push('Print');
            keys.push('Preview');
        } else if (!printerInstalled) {
            keys.push('Print');
            if (setFieldValue && !printOrPreviewInitialValue) {
                setFieldValue(name, 'Preview');
                setDeliveryMethod('Preview');
            }
        } else {
            keys.push('Preview');
            if (setFieldValue && !printOrPreviewInitialValue) {
                setFieldValue(name, 'Print');
                setFieldValue(printCopiesFieldName, 1);
                setDeliveryMethod('Print');
            }
        }
        keys.forEach((key) => {
            applicableDeliveryMethodList = applicableDeliveryMethodList.filter(
                ({ value }) => !value.includes(key),
            );
            applicableDeliveryMethodList = applicableDeliveryMethodList.filter(
                ({ value }) => !exclude.includes(value),
            );
        });

        if (documentType === MOVE_IN_DOCUMENTS.Lease_Agreement.value) {
            applicableDeliveryMethodList = applicableDeliveryMethodList.filter(({ value }) => value === DELIVERY_METHOD.Print.value || value === DELIVERY_METHOD.Save_Document.value);
        }
        if (isEmailEnabled && allowEsign) {
            applicableDeliveryMethodList = [
                ...applicableDeliveryMethodList,
                ADDITIONAL_DELIVERY_METHOD.Email_Lease_For_Esign,
            ];
        }
        isLetterFetched.current = true;
        setDeliveryMethodList(applicableDeliveryMethodList);
    };

    const getPrinterAvailabilityStatus = () => {
        if (currentPrinter) {
            const currentPrinterId = getValue(currentPrinter, 'data.id');
            const allPrinters = getValue(printer, 'data.data');
            const curPrinter = allPrinters.find(({ id }) => id === currentPrinterId);
            if (curPrinter) {
                const { isOnline = false } = curPrinter;
                return isOnline;
            }
        }
        return false;
    };

    useEffect(() => {
        if (setFieldValue && (printOrPreviewInitialValue || '') !== deliveryMethod) {
            if (deliveryMethod && deliveryMethod.includes('Print')) {
                setFieldValue(printCopiesFieldName, 1);
            } else {
                setFieldValue(printCopiesFieldName, undefined);
            }
        }
        if (deliveryMethod && deliveryMethod.includes('Print')) {
            const selectedEntityAndLevel = {
                selectedEntity: facilityId || fid,
                selectedLevel: ENTITY_TYPE.FACILITY.toLowerCase(),
            };
            if (!printer) {
                fetchPrinter(onAction, selectedEntityAndLevel, EMPTY_FUNC, REQUEST_TYPES.REFRESH);
            }
        }
    }, [deliveryMethod]);

    useEffect(() => {
        if (type === DROPDOWN_PRINTER_SELECTION_TYPES.Popover.value) {
            setPrinterSelectedStatus(getPrinterAvailabilityStatus());
        }
    }, [currentPrinter]);

    const onDeliveryMethodChange = (_, value) => {
        onChange(_, value);
        setDeliveryMethod(value);
    };

    const onStandaloneAppFetch = (apiError, response) => {
        if (apiError) {
            const formattedErrors = formatErrors(apiError);
            if (formattedErrors.errorMessage) {
                setError(formattedErrors.errorMessage);
            }
        } else if (response) {
            const { data: installedStandaloneApps = [] } = response;
            setIsPrinterInstalled(!!installedStandaloneApps.length);
        }
    };

    const onLetterFetch = (apiError, response) => {
        if (apiError) {
            const formattedErrors = formatErrors(apiError);
            if (formattedErrors.errorMessage) {
                setError(formattedErrors.errorMessage);
            }
        } else if (response) {
            const {
                data: {
                    Letter: { data: letters = [] } = {},
                    Facility_General: {
                        data: [{
                            value: {
                                emailAddress: { isEnabled: isEmailEnabled = false } = {},
                                isPhoneEnabled = false,
                            } = {},
                        } = {}] = [],
                    } = {},
                } = {},
            } = response;
            const isTextFriendlyContent = {};
            if (isPhoneEnabled) {
                letters.forEach(({ id, value: { textFriendly: { isEnabled = false } = {} } = {} }) => {
                    isTextFriendlyContent[id] = isEnabled;
                });
                isTextFriendly.current = isTextFriendlyContent;
            }
            modeEnabled.current = { email: isEmailEnabled, phone: isPhoneEnabled };
            if (!printerInstalled) {
                if (standaloneApps.length) setIsPrinterInstalled(true);
                else fetchStandaloneApp(onAction, facilityId || fid, onStandaloneAppFetch);
            }
            const mapOfLetterIdTemplate = {};
            letters.forEach(({ id, value: { templateLink } }) => {
                mapOfLetterIdTemplate[id] = templateLink;
            });

            setLetterIdTemplate({ ...mapOfLetterIdTemplate });
        }
    };

    const PrintCopiesComponent = (extraProps) => {
        const { required } = otherPrintProps;
        const { readOnly } = rest;
        return (
            <PrintCopies
                type="number"
                additionalIcon={{
                    left: (
                        <InheritedConfigLabel
                            label={localisable.printCopies}
                            readOnly={readOnly}
                            disabled={!isFormActive}
                        />
                    ),
                }}
                inputProps={{ className: classes.printCopiesinput }}
                width={PRINT_COPIES_TEXTFIELD_WIDTH}
                validate={IS_NON_ZERO_POSITIVE_NUMERIC}
                disabled={!isFormActive}
                {...(printCopiesFieldUseForm && !required) && { noIndicatorWrapper: true }}
                {...printOrPreviewProps}
                {...otherPrintProps}
                {...extraProps}
            />
        );
    };
    const onTxtTokenFetch = (apiError, response) => {
        if (apiError) {
            const formattedErrors = formatErrors(apiError);
            if (formattedErrors.errorMessage) {
                setError(formattedErrors.errorMessage);
            }
        }
        if (response) {
            const { accessToken } = response;
            setTxToken(accessToken);
        }
    };

    const onFetchRentalGeneral = (apiError, response) => {
        if (apiError) {
            const formattedErrors = formatErrors(apiError);
            if (formattedErrors.errorMessage) {
                setError(formattedErrors.errorMessage);
            }
        }
        if (response) {
            const { data: { Lease_Style: { data: allLeaseStyles = [] } = {} } = {} } = response;
            if (!previewTemplateLink) {
                allLeaseStyles.forEach((leaseStyle = {}) => {
                    const intLeaseId = parseInt(leaseStyle.id, 10);
                    if (intLeaseId === letterId) {
                        const { value: { leaseDocument } } = leaseStyle;
                        if (leaseDocument && Object.keys(letterIdTemplate).length > 0) {
                            setPreviewTemplateLink(letterIdTemplate[parseInt(leaseDocument, 10)]);
                        }
                        setPreviewLetterId(leaseDocument);
                    }
                });
            }
        }
    };
    useEffect(() => {
        if (isNonEmptyArray(letterConfigs) && isObjWithKeys(facilityGeneralConfig)) {
            onLetterFetch(null, {
                data: {
                    Letter: { data: letterConfigs },
                    Facility_General: { data: facilityGeneralConfig },
                },
            });
        } else {
            fetchLetter(onAction, facilityId || fid, onLetterFetch);
        }
    }, []);

    useDidUpdateEffect(() => {
        if (showPreview && letterIdTemplate) {
            getTxtToken(onAction, onTxtTokenFetch);
            fetchConfiguration(
                onAction,
                {
                    configType: [CONFIG_TYPE.LEASE_STYLE],
                    selectedEntity: '1',
                    selectedLevel: 'facility',
                },
                onFetchRentalGeneral,
            );
        }
    }, [letterIdTemplate]);

    useDidUpdateEffect(() => {
        filterDeliveryMethodList();
    }, [letterId, printerInstalled]);

    const onPreviewLetterFetch = (apiError, response) => {
        if (apiError) {
            const formattedErrors = formatErrors(apiError);
            if (formattedErrors.errorMessage) {
                setError(formattedErrors.errorMessage);
            }
            setIsPreviewLoading(false);
        }
        if (response) {
            const [letterBody] = response;
            const pdfUrl = convertToPdfUrl(letterBody);
            setLetter({ isModalOpen: true, previewLetterLink: pdfUrl });
            setIsPreviewLoading(false);
        }
    };

    const onFetchDocument = (apiError, response) => {
        if (apiError) {
            const formattedErrors = formatErrors(apiError);
            if (formattedErrors.errorMessage) {
                setError(formattedErrors.errorMessage);
            }
            setIsPreviewLoading(false);
        }
        if (response) {
            const { data: [{ mergeFields: previewMergeFields = {} }] = [] } = response;
            if (previewTemplateLink) {
                fetch(previewTemplateLink).then((previewTemplateResponse) => {
                    if (previewTemplateResponse.ok) {
                        previewTemplateResponse.text().then((data) => {
                            previewLetter(
                                onAction,
                                txToken,
                                previewMergeFields,
                                data,
                                onPreviewLetterFetch,
                            );
                        });
                    }
                });
            } else {
                setIsPreviewLoading(false);
            }
        }
    };

    const getModalFooter = () => (
        <Footer
            negativeButtonProps={{
                label: localisable.exit,
                onClick: () => setLetter({ isModalOpen: false, previewLetter }),
            }}
            positiveButtonProps={{}}
        />
    );

    const onPreviewLetter = () => {
        if (Object.keys(previewProps).length > 0) {
            const { tenantId, unitId, ledgerId, period, billToDate } = previewProps;
            if ((tenantId || unitId) && previewLetterId) {
                setIsPreviewLoading(true);
                fetchDocument(
                    onAction,
                    {
                        letterId: previewLetterId,
                        generateDocument: false,
                        info: [
                            {
                                tenantId,
                                unitId,
                                ledgerId,
                                period,
                                billToDate,
                            },
                        ],
                    },
                    onFetchDocument,
                );
            }
        }
    };

    const deliveryMethodListContainsPrint = () => {
        const filteredDeliveryMethodList = deliveryMethodList.filter(({ value }) => value.includes('Print'));
        return filteredDeliveryMethodList.length > 0;
    };

    const onShowPrinterSelectionAlert = () => {
        setShowPrinterSelectionDialog(!showPrinterSelectionDialog);
    };

    const onCloseAlert = () => {
        setShowPrinterSelectionDialog(false);
    };

    const onPrinterSelected = () => {
        setShowPrinterSelectionDialog(false);
    };


    const getPrinterMessage = () => (printerSelectedStatus ? localisable.printerSelectionNoteAvailable : localisable.printerSelectionNoteUnavailable);

    const renderAlertActions = () => (
        <>
            <Button
                variant="text"
                color="error"
                className={clsx(classes.action, classes.transparent)}
                onClick={onCloseAlert}
            >
                <Typography variant="body2">{(localisable.exit).toUpperCase()}</Typography>
            </Button>
            <Button
                variant="text"
                color="primary"
                disabled={!printerSelectedStatus}
                onClick={onPrinterSelected}
            >
                <Typography variant="body2">{(localisable.save).toUpperCase()}</Typography>
            </Button>
        </>
    );

    const renderComponent = () => {
        const { match: { path = '' } = {}, currentFacility: { data: { id: currentFacilityId } = {} } = {} } = props;
        const extraContainerProps = inlinePrintComponents
            ? {
                container: true,
                alignItems: 'flex-end',
                item: true,
                xs: 12,
                className: classes.container,
                ...containerProps,
            }
            : {
                flexDirection: 'column',
                alignItems: 'flexStart',
                xs: 12,
            };
        const showPrinterSection = () => {
            const printDeliveryMethods = [DELIVERY_METHOD.Print.value, DELIVERY_METHOD.Print_And_Email.value];
            const showPrinter = printDeliveryMethods.includes(deliveryMethod) && show;
            if (showPrinter) {
                if (type !== DROPDOWN_PRINTER_SELECTION_TYPES.Popover.value) {
                    return (
                        <Grid
                            item
                            xs={12}
                            md={4}
                            lg={4}
                            {...(!isDesktop && { className: classes.mobileContainer })}
                            {...printerSelectionGridProps}
                        >
                            <PrinterSection
                                path={path}
                                classes={classes}
                                printer={printer}
                                onAction={onAction}
                                currentPrinter={currentPrinter}
                                currentFacilityId={currentFacilityId}
                                containerProps={printerSelectionContainerProps}
                            />
                        </Grid>

                    );
                }
                return (
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography
                            variant="caption"
                            color="primary"
                            className={classes.changePrinterLabel}
                            onClick={onShowPrinterSelectionAlert}
                        >
                            {localisable.changePrinter}
                        </Typography>
                    </Grid>

                );
            }
            return <></>;
        };
        return shouldShowDropdown && deliveryMethodList.length ? (
            <Grid xs={12}>
                <Grid {...extraContainerProps}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        {...(!inlinePrintComponents && {
                            ...(!isDesktop && { className: classes.mobileContainer }),
                            ...containerProps,
                        })}
                    >
                        <Component
                            key={`${deliveryMethodList.length}`}
                            list={deliveryMethodList}
                            onChange={onDeliveryMethodChange}
                            initialValue={deliveryMethod}
                            {...componentProps}
                            {...rest}
                        />
                    </Grid>
                    {showPrinterSection()}
                    {showPrintCopies
              && (deliveryMethod && deliveryMethod.includes('Print'))
              && deliveryMethodListContainsPrint() && (
                        <Grid
                            {...(inlinePrintComponents && { justify: 'flex-end' })}
                            item
                            xs={12}
                            md={4}
                            className={clsx(
                                {
                                    [classes.lastInline]:
                        !isDesktop || !inlinePrintComponents,
                                },
                                classes.printCopiesContainer,
                                printContainerClassName,
                            )}
                        >
                            <PrintCopiesComponent
                                {...(printCopiesFieldUseForm
                                    ? { noGrid: true, noIndicatorWrapper: false }
                                    : {
                                        value: noOfCopies,
                                        onChange: (_, value) => {
                                            setNoOfCopies(value);
                                            onChangePrintCopies(value);
                                        },
                                    })}
                                isFormActive={isFormActive}
                            />
                        </Grid>
                    )}
                </Grid>

                <>
                        {documentType === MOVE_IN_DOCUMENTS.Lease_Agreement.value && showPreview
                        && (!isPreviewLoading ? (
                            <Typography
                                variant="body1"
                                color="primary"
                                className={classes.previewLabel}
                                onClick={onPreviewLetter}
                            >
                                {localisable.preview}
                            </Typography>
                        ) : (
                            <ThreeDotProgress className={classes.previewLabel} />
                        ))}
                </>
                {isModalOpen && (
                    <Modal
                        open
                        footer={getModalFooter()}
                        hideCloseButton
                        classes={{
                            modalGrid: classes.modalGrid,
                            modalBody: classes.modalBody,
                        }}
                    >
                        <object
                            data={`${previewLetterLink}#toolbar=0`}
                            type="application/pdf"
                            width={innerWidth * 0.8}
                            height={innerHeight * 0.7}
                        >
                            <a href={previewLetter}>{localisable.agreement}</a>
                        </object>
                    </Modal>
                )}
                {
                    showPrinterSelectionDialog && (
                        <AlertDialog
                            open={showPrinterSelectionDialog}
                            onClose={onCloseAlert}
                            title={localisable.printerDetails}
                            dialogClasses={{ paperWidthSm: classes.printAlertDialogContainerSm }}
                            actions={renderAlertActions()}
                        >
                            <Grid container direction="column">
                                <Grid
                                    item
                                    xs={12}
                                    {...(!isDesktop && { className: classes.mobileContainer })}
                                >
                                    <Typography variant="caption">
                                        {localisable.printerLabel}
                                    </Typography>
                                    <PrinterSection
                                        path={path}
                                        classes={classes}
                                        printer={printer}
                                        onAction={onAction}
                                        currentPrinter={currentPrinter}
                                        currentFacilityId={currentFacilityId}
                                        containerProps={printerSelectionContainerProps}
                                    />
                                    <Typography variant="body2" className={classes.printerAlertNote}>
                                        {getPrinterMessage()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AlertDialog>
                    )
                }

                {error && <Snackbar variant="error" title={error} />}
            </Grid>
        ) : null;
    };
    return isLetterFetched.current ? renderComponent() : <ThreeDotProgress />;
};

DeliveryMethodDropdown.propTypes = {
    name: PropTypes.string,
    letterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    useForm: PropTypes.bool,
    dropdownProps: PropTypes.any,
    onAction: PropTypes.func,
    currentFacility: PropTypes.object,
    fid: PropTypes.string,
    recipientEmailEnabled: PropTypes.bool,
    recipientTextEnabled: PropTypes.bool,
    allowEsign: PropTypes.bool,
    shouldShowDropdown: PropTypes.bool,
    printOrPreviewProps: PropTypes.object,
    showPrintCopies: PropTypes.bool,
    inlinePrintComponents: PropTypes.bool,
    containerProps: PropTypes.object,
    onChange: PropTypes.func,
    isWebsiteAccess: PropTypes.bool,
    documentType: PropTypes.string,
    showPreview: PropTypes.bool,
    previewProps: PropTypes.object,
    standaloneApps: PropTypes.object,
    leaseStyleConfigs: PropTypes.array,
    letterConfigs: PropTypes.array,
    facilityGeneralConfig: PropTypes.object,
    isFormActive: PropTypes.object,
    printer: PropTypes.object,
    currentFacilityId: PropTypes.object,
    currentPrinter: PropTypes.object,
    printerSelectionProps: PropTypes.object,
    match: PropTypes.object,
    exclude: PropTypes.array,
    onChangePrintCopies: PropTypes.func,
};

DeliveryMethodDropdown.defaultProps = {
    recipientEmailEnabled: true,
    recipientTextEnabled: true,
    allowEsign: false,
    shouldShowDropdown: true,
    printOrPreviewProps: {},
    showPrintCopies: true,
    inlinePrintComponents: false,
    containerProps: {},
    onChange: EMPTY_FUNC,
    showPreview: false,
    previewProps: {},
    standaloneApps: [],
    leaseStyleConfigs: [],
    letterConfigs: [],
    facilityGeneralConfig: {},
    isFormActive: true,
    currentPrinter: null,
    printerSelectionProps: {
        show: false,
        type: DROPDOWN_PRINTER_SELECTION_TYPES.Inline.value,
        containerProps: {},
        gridProps: {},
    },
    onChangePrintCopies: EMPTY_FUNC,
};
export default DeliveryMethodDropdown;

const SmartDeliveryMethodDropdown = window.withProvider('DeliveryMethodDropdown');

export { SmartDeliveryMethodDropdown as DeliveryMethodDropdown };
