import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import Link from '../../link/components/Link';
import { Popper, Paper, MenuList, MenuItem, ClickAwayListener, withStyles }
from '../../componentlibrary/components/Components';
import menuStyle from '../styles/MenuStyle';


const timeoutDuration = 100;

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.anchorEl = React.createRef();
        this.state = {
            mouseOverAnchor: false,
            mouseOverMenu: false,
            open: false,
            arrowRef: null,
        };
    }

    onClick = () => {
        const { props: { onOpenOrClose } } = this;
        this.setState((prevState) => {
            const open = !prevState.open;
            onOpenOrClose(open);
            return { open };
        });
    };

    onClickAway = (e) => {
        this.setState(() => {
            if (!this.anchorEl.current.contains(e.target)) {
                return { open: false };
            }
            return null;
        });
        const { onOpenOrClose } = this.props;
        onOpenOrClose(false);
    };

    leaveMenu = () => {
        setTimeout(() => {
            this.setState({ mouseOverMenu: false });
        }, timeoutDuration);
    }

    enterMenu = () => {
        this.setState({ mouseOverMenu: true });
    }

    leaveAnchor = () => {
        setTimeout(() => {
            this.setState({ mouseOverAnchor: false });
        }, timeoutDuration);
    }

    enterAnchor = () => {
        this.setState({ mouseOverAnchor: true });
    }

    handleArrowRef = (node) => {
        this.setState({ arrowRef: node });
    };

    render() {
        const {
            children, list = [], event, classes,
            anchorElProps: { className: anchorElClassName = '', ...otherAnchorElProps },
            PopperProps: { className: popperClassName = '', modifiers, ...otherPopperProps },
            PaperProps, MenuListProps, MenuItemProps, LinkProps,
        } = this.props;
        const { open: onClickOpen, mouseOverAnchor, mouseOverMenu, arrowRef } = this.state;
        const anchorEl = this.anchorEl.current;
        const eventProp = event === 'onHover'
            ? { onMouseEnter: this.enterAnchor, onMouseLeave: this.leaveAnchor }
            : { onClick: this.onClick };
        const open = event === 'onHover' ? (mouseOverAnchor || mouseOverMenu) : onClickOpen;
        const paperHoverProps = event === 'onHover'
            ? { onMouseEnter: this.enterMenu, onMouseLeave: this.leaveMenu }
            : {};


        return (
            <React.Fragment>
                <div
                    {...otherAnchorElProps}
                    className={`${classes.anchorElDiv} ${anchorElClassName}`}
                    ref={this.anchorEl}
                >
                    <div
                        className={classes.anchorEl}
                        role="presentation"
                        {...eventProp}
                    >
                        {children}
                    </div>
                </div>
                <Popper
                    className={`${classes.popper} ${classes.arrowShadow} ${popperClassName}`}
                    open={open}
                    anchorEl={anchorEl}
                    disablePortal
                    modifiers={{
                        arrow: {
                            enabled: open,
                            element: arrowRef,
                        },
                        ...modifiers,
                    }}
                    {...otherPopperProps}
                >
                    <span className={classes.arrow} ref={this.handleArrowRef} />
                    <ClickAwayListener mouseEvent={open && 'onClick'} onClickAway={this.onClickAway}>
                        <Paper
                            {...paperHoverProps}
                            {...PaperProps}
                        >
                            <MenuList {...MenuListProps}>
                                {
                                    list.map((item, index) => {
                                        const { label, url, addon, node, divider, target } = item;
                                        const key = index;
                                        return (
                                            <MenuItem
                                                disableRipple
                                                key={key}
                                                onClick={this.onClickAway}
                                                divider={divider}
                                                {...MenuItemProps}
                                            >
                                                {
                                                    node
                                                    || (
                                                        <Link
                                                            className={classes.link}
                                                            to={url}
                                                            addon={addon}
                                                            target={target}
                                                            classes={{ reactlink: classes.reactlink }}
                                                            {...LinkProps}
                                                        >
                                                            {label}
                                                        </Link>
                                                    )
                                                }
                                            </MenuItem>
                                        );
                                    })
                                }
                            </MenuList>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </React.Fragment>
        );
    }
}

Menu.propTypes = {
    children: PropTypes.node.isRequired,
    list: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            addon: PropTypes.shape({ start: PropTypes.node, end: PropTypes.node }),
            url: PropTypes.string,
            label: PropTypes.node,
            divider: PropTypes.bool,
            target: PropTypes.any,
        })), PropTypes.node]).isRequired,
    event: PropTypes.string,
    PopperProps: PropTypes.object,
    PaperProps: PropTypes.object,
    MenuListProps: PropTypes.object,
    MenuItemProps: PropTypes.object,
    anchorElProps: PropTypes.object,
    LinkProps: PropTypes.object,
    classes: PropTypes.object.isRequired,
    onOpenOrClose: PropTypes.func,
};

Menu.defaultProps = {
    event: 'onClick',
    PopperProps: {},
    PaperProps: {},
    MenuListProps: {},
    MenuItemProps: {},
    anchorElProps: {},
    LinkProps: {},
    onOpenOrClose: EMPTY_FUNC,
};

export const MenuComponent = Menu;
export default withStyles(menuStyle)(Menu);
