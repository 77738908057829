import getName from 'Commons/helpers/utils/NameHelper';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { useMemo } from 'react';
import { USER_ACTION_ITEM } from 'Commons/components/business/user/form/config/Constants';
import Icon from 'Generic/icon/components/Icon';
import hasPermission from 'Commons/helpers/utils/PermissionChecker';
import { USER_MANAGEMENT_PERMISSION_KEY } from 'Commons/components/business/user/config/Constants';
import LabelWithAddons from 'Generic/labelwithaddons/components/LabelWithAddons';
import Button from 'Generic/button/components/Button';
import headerStyle from '../styles/HeaderStyle';

const useStyles = makeStyles(headerStyle, { name: 'UserFormHeaderStyle' });

const Header = (props) => {
    const {
        permission,
        isFormActive,
        isModeCreate,
        isUserLocked,
        showAlertDialog,
        formProps: {
            initialValues: {
                id: userId,
                status: userStatus,
                firstName, lastName,
            } = {},
        } = {},
        userProfile: { data: { data: [{ id: userProfileId } = {}] = [] } = {} } = {},
    } = props;
    const classes = useStyles();

    const isUserSelf = useMemo(() => userProfileId === userId, [userId, userProfileId]);

    const hasUserManagementPermission = useMemo(() => hasPermission(permission, USER_MANAGEMENT_PERMISSION_KEY), [userId]);

    const getIsVisible = (source) => {
        const baseChecks = hasUserManagementPermission && !isUserSelf;
        switch (source) {
            case USER_ACTION_ITEM.ACTIVATE:
            case USER_ACTION_ITEM.DEACTIVATE:
                return baseChecks;

            case USER_ACTION_ITEM.UNLOCK:
                return baseChecks && isUserLocked;

            case USER_ACTION_ITEM.FORCE_PASSWORD_RESET:
                return baseChecks;

            default:
                return false;
        }
    };

    const actionItems = useMemo(() => ([
        {
            key: 1,
            icon: 'cp-unlock',
            label: localisable.unlockUser,
            onClick: () => showAlertDialog(USER_ACTION_ITEM.UNLOCK),
            isVisible: getIsVisible(USER_ACTION_ITEM.UNLOCK),
        },
        {
            key: 2,
            icon: 'cp-reset',
            label: localisable.forcePasswordReset,
            onClick: () => showAlertDialog(USER_ACTION_ITEM.FORCE_PASSWORD_RESET),
            isVisible: getIsVisible(USER_ACTION_ITEM.FORCE_PASSWORD_RESET),
        },
    ]), [userStatus, isUserLocked]);

    const renderActionItem = ({ key, label, icon, onClick } = {}) => (
        <LabelWithAddons
            key={key}
            onClick={onClick}
            start={<Icon type="custom" icon={icon} className={classes.actionItemIcon} />}
            className={classes.actionItemButton}
            labelClassName={classes.actionItemLabel}
        >
            <Typography
                variant="body2"
                color="primary"
            >
                {label}
            </Typography>
        </LabelWithAddons>
    );

    const renderHeaderTitle = () => {
        const statusButtonSource = isFormActive ? USER_ACTION_ITEM.DEACTIVATE : USER_ACTION_ITEM.ACTIVATE;
        return (
            <Grid container>
                <Typography variant="h5">
                    {isModeCreate ? localisable.createUser : getName({
                        firstName,
                        lastName,
                    })}
                </Typography>
                {
                    !isModeCreate
                    && getIsVisible(statusButtonSource) && (
                        <Grid item className={classes.statusChangeButton}>
                            <Button
                                disableRipple
                                variant="outlined"
                                color={isFormActive ? 'error' : 'primary'}
                                onClick={() => showAlertDialog(statusButtonSource)}
                            >
                                {isFormActive ? localisable.deactivate : localisable.activate}
                            </Button>
                        </Grid>
                    )
                }
            </Grid>
        );
    };

    return isModeCreate ? renderHeaderTitle() : (
        <Grid container justify="space-between">
            <Grid item>
                {renderHeaderTitle()}
            </Grid>
            <Grid item>
                <Grid container wrap="nowrap">
                    {isFormActive && actionItems.map(({ isVisible, ...actionItemConfig } = {}) => (isVisible ? renderActionItem(actionItemConfig) : null))}
                </Grid>
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    formProps: PropTypes.object,
    permission: PropTypes.object,
    isModeCreate: PropTypes.bool,
    isFormActive: PropTypes.bool,
    isUserLocked: PropTypes.bool,
    userProfile: PropTypes.object,
    showAlertDialog: PropTypes.func,
};

export default Header;
