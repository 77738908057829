import { FormDatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import { SM_12, SM_4_XS_6 } from 'Commons/config/constants/GridSpacingConstants';
import Comments from 'Commons/components/business/comments/components/Comments';
import { memo } from 'react';
import { Grid } from 'Generic/componentlibrary/components/Components';
// import { FormDropdown } from 'Generic/dropdown/components/Dropdown';
import localisable from 'Commons/config/strings/localisable';

const OtherInformation = ({
    mapping: {
        comments: {
            visibility: commentsVisibility = true,
            ...commentsProps
        } = {},
        dateOfBirth: {
            visibility: dateOfBirthVisibility = true,
            ...dateOfBirthProps
        } = {},
    } = {},
}) => (
        <>
            <Grid container>
                {
                    /* TODO: Backend Support
                        <FormDropdown
                            name="language"
                            label={localisable.tenantLanguage}
                            fullWidth
                            GridProps={SM_4_XS_6}
                        />
                    */
                }
                {
                    dateOfBirthVisibility
                    && (
                        <FormDatePicker
                            name="dob"
                            label={localisable.tenantDob}
                            fullWidth
                            GridProps={SM_4_XS_6}
                            disableFuture
                            {...dateOfBirthProps}
                        />
                    )
                }
            </Grid>
            {
                commentsVisibility
                && (
                    <Comments
                        name="comments"
                        GridProps={SM_12}
                        {...commentsProps}
                    />
                )
            }
        </>
);


OtherInformation.propTypes = { mapping: PropTypes.object };


export default memo(OtherInformation);
