import media from 'Commons/theme/MediaQueries';

const smartListActionItemsPanelStyle = theme => ({
    actionItemsContainer: { padding: theme.spacing(2, 0, 2, 4) },
    actionItem: { paddingRight: theme.spacing(4) },
    backToDashboardActionItem: {
        paddingRight: theme.spacing(4),
        [media.mobile]: { paddingRight: theme.spacing(1) },
    },
});

export default smartListActionItemsPanelStyle;
