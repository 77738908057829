import { deepCopy } from 'Commons/helpers/utils/DeepCopy';

const getAppliedAutoSuggest = (autoSuggestKeys, autoSuggestKey, allFilters) => {
    let appliedAutoSuggest = null;
    let appliedFilters = {};
    autoSuggestKeys.unshift(autoSuggestKey);
    appliedFilters = deepCopy(allFilters);
    for (let i = 0; i < autoSuggestKeys.length; i += 1) {
        const key = autoSuggestKeys[i];
        const keyParts = key.split('.');
        let curr = appliedFilters;
        for (let j = 0; j < keyParts.length - 1; j += 1) {
            curr = curr[keyParts[j]] || {};
        }
        const currentVal = curr[keyParts[keyParts.length - 1]];
        if (currentVal && !appliedAutoSuggest) {
            appliedAutoSuggest = currentVal;
            delete curr[keyParts[keyParts.length - 1]];
            break;
        }
    }
    return { appliedAutoSuggest, appliedFilters };
};

export { getAppliedAutoSuggest };
