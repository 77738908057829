import media from '../../../../theme/MediaQueries';

const overlayStyle = theme => ({
    overlay: { '& $modalGrid, & $overlayHeader': { boxShadow: theme.boxShadows[0], flexWrap: 'nowrap' } },
    modalGrid: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: 'auto',
        transform: 'none',
        margin: '24px 16px',
        [media.tabletUp]: { margin: 16 },
        [media.laptopUp]: { margin: '36px' },
    },
    modalHeader: { minHeight: 'auto', zIndex: 2 },
    backdropShadow: { backgroundColor: 'rgba(255,255,255,0.8)' },
    actionItems: {
        [media.laptopUp]: { padding: '20px 48px', height: '64px' },
        '& $activeItem': { '& $labelWithIcon': { color: theme.palette.common.black } },
    },
    activeItem: {},
    gridItem: { marginRight: 32 },
    overlayBody: {
        height: 0,
        padding: '0px 16px',
        [media.laptopUp]: { padding: '0px 48px' },
        flex: '1 1 auto',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
        },
    },
    overlayHeader: {
        minHeight: theme.components.overlay.header.minHeight,
        padding: '14px 16px',
        [media.laptopUp]: { padding: '14px 48px' },
    },
    overlayFooter: {},
    sectionBar: {
        borderBottom: `1px solid ${theme.palette.border}`,
        boxShadow: theme.boxShadows[1],
        height: '48px',
    },
    sectionTitle: {
        fontWeight: 600,
        color: theme.palette.text.secondary,
        borderLeft: `1px solid ${theme.palette.border}`,
        borderRight: `1px solid ${theme.palette.border}`,
        borderRadius: '0',
        padding: '10px',
        height: '48px',
        justifyContent: 'flex-start',
        [media.tabletUp]: { padding: 16 },
        [media.laptopUp]: {
            border: 'none',
            justifyContent: 'center',
        },
    },
    icon: {
        position: 'absolute',
        right: 0,
        paddingRight: '5px',
    },
    sectionScroller: {
        top: 0,
        bottom: 0,
        position: 'absolute',
    },
    labelWithIcon: {
        color: theme.palette.text.secondary,
        '&:hover': { color: theme.palette.common.black },
    },
});

export default overlayStyle;
