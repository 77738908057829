import { makeStyles, Grid, Button, useLocation } from 'Commons/components/generic/componentlibrary/components/Components';
import { memo, useMemo, useState, useRef } from 'react';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import { WALK_IN_CUSTOMER_ID } from 'External/containers/pointOfSale/config/Constants';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import Icon from 'Generic/icon/components/Icon';
import { DASHBOARD_TYPE } from 'Commons/config/constants/Constants';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import { ROUTE } from 'External/redux/config/RouteNames';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import { isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import headerStyle from '../styles/HeaderStyle';
import { getPaymentHeaderActionItems } from '../config/HeaderConfig';
import { clsx } from '../../../../helpers/utils/clsx';
import PromoPlan from './PromoPlan';

const useStyles = makeStyles(headerStyle, { name: 'PaymentHeader' });

const Header = ({
    header, toggleBillAhead, showModal, togglePromoPlan, submitPromoPlansData,
    isBillAheadOpen, tenantId, ledgerId, fid, isPromoPlanOpen,
    formProps: {
        values: {
            extra: {
                unitList = [],
                unitList: [{ ledger: { id: ledgerOfUnit } = {} } = {}] = [],
            } = {},
        },
    },
    promoPlanData,
}) => {
    const classes = useStyles();
    const { pathname } = useLocation();
    const unitsByLedger = useMemo(() => (
        unitList.reduce((acc, value) => {
            const { ledger: { id: ledger } = {} } = value;
            const { [`${ledger}`]: units = [] } = acc;
            units.push(value);
            return { ...acc, [`${ledger}`]: units };
        }, {})
    ), [ledgerOfUnit]);

    const isPromoPlanValid = (promoPlan, validChargeCategories) => {
        const {
            disabled = false, expired = false,
            value: { bills = [], accessibility: { features: { Payment } = {} } = {} } = {},
        } = promoPlan;
        return (
            Payment
            && !disabled
            && !expired
            && bills.some(({ chargeCategory }) => validChargeCategories.includes(Number(chargeCategory)))
        );
    };

    const selectedPromoPlansRef = useRef({});

    const [canApplyPromoPlans, setApplyPromoPlanState] = useState(false);

    const allPromoPlansForUnits = useMemo(() => {
        const allPromoPlans = {};
        const validChargeCategories = [];
        unitList.forEach(({ rentChargeCategoryId }) => {
            validChargeCategories.push(Number(rentChargeCategoryId));
        });
        Object.values(promoPlanData).forEach((promoPlan = {}) => {
            const { id, value = {} } = promoPlan;
            if (isPromoPlanValid(promoPlan, validChargeCategories)) {
                allPromoPlans[id] = { ...promoPlan, ...value };
            }
        });
        setApplyPromoPlanState(false);
        return allPromoPlans;
    }, [ledgerOfUnit]);

    const { deviceInfo: { isDesktop } } = window;
    // TODO: Remove Bill Ahead disable during Unit Transfer when Case 29288 is resolved
    const isCurrentPathUnitTransfer = pathname === buildUrl('facilityFeatures',
        { fid, feature: ROUTE.TRANSFER }, DASHBOARD_TYPE.EXTERNAL);
    const isBillAheadDisabled = !ledgerId || !(unitsByLedger[`${ledgerId}`] || []).length || isCurrentPathUnitTransfer;
    const isPromoPlanDisabled = !ledgerOfUnit || !isObjWithKeys(promoPlanData);

    const headerActionItems = useMemo(() => getPaymentHeaderActionItems({
        isPromoPlanDisabled,
        isBillAheadDisabled,
        isBillAheadOpen,
        toggleBillAhead,
        togglePromoPlan,
        showModal,
    }),
    [isBillAheadOpen, isBillAheadDisabled, isPromoPlanDisabled]);

    const { current: selectedPromoPlans } = selectedPromoPlansRef;

    const setSelectedPromoPlans = (updatedPromoPlans = {}) => {
        selectedPromoPlansRef.current = updatedPromoPlans;
        setApplyPromoPlanState(true);
    };

    const onSubmitPromoPlans = () => {
        const selectedPromoPlansCopy = deepCopy(selectedPromoPlans);
        togglePromoPlan();
        submitPromoPlansData({ ledgerId: ledgerOfUnit, addList: Object.keys(selectedPromoPlansCopy) });
    };

    if (header) return header;

    return (
        <Grid container justify="space-between" wrap="nowrap">
            <Typography variant="h6" noWrap className={classes.paymentHeader}>{localisable.makePayment}</Typography>
            {
                tenantId !== WALK_IN_CUSTOMER_ID && (
                    <Grid container wrap="nowrap" spacing={1} justify="flex-end" className={classes.autoWidth}>
                        {headerActionItems.map(({ shouldShow, onClick, icon, isDisabled, label }) => (
                            <React.Fragment key={label}>
                                {
                                    shouldShow
                                    && (
                                        <LabelWithIcon
                                            item
                                            addon={{
                                                start: <Icon
                                                    type="custom"
                                                    icon={icon}
                                                    disabled={isDisabled}
                                                    color="primary"
                                                />,
                                            }}
                                            {...!isDisabled && { onClick }}
                                        >
                                            {isDesktop && (
                                                <Typography color="primary" noWrap disabled={isDisabled}>
                                                    {label}
                                                </Typography>
                                            )}
                                        </LabelWithIcon>
                                    )
                                }
                            </React.Fragment>
                        ))}
                    </Grid>
                )
            }
            <AlertDialog
                open={isPromoPlanOpen}
                onClose={togglePromoPlan}
                title={<Typography variant="subtitle1">{localisable.promoPlans}</Typography>}
                actions={(
                        <>
                            <Button
                                onClick={togglePromoPlan}
                                variant="outlined"
                                className={clsx(classes.alertAction, classes.cancelButton)}
                            >
                                {localisable.cancel}
                            </Button>
                            <Button
                                onClick={onSubmitPromoPlans}
                                variant="contained"
                                color="secondary"
                                disabled={!canApplyPromoPlans}
                                className={classes.alertAction}
                            >
                                {localisable.apply}
                            </Button>
                        </>
                )}
            >
                <PromoPlan
                    key={ledgerOfUnit}
                    promoPlanData={allPromoPlansForUnits}
                    selectedPromoPlans={selectedPromoPlans}
                    setSelectedPromoPlans={setSelectedPromoPlans}
                />
            </AlertDialog>
        </Grid>
    );
};

Header.propTypes = {
    header: PropTypes.any,
    showModal: PropTypes.bool,
    formProps: PropTypes.object,
    isPromoPlanOpen: PropTypes.bool,
    togglePromoPlan: PropTypes.func,
    toggleBillAhead: PropTypes.func,
    promoPlanData: PropTypes.object,
    isBillAheadOpen: PropTypes.bool,
    submitPromoPlansData: PropTypes.func,
    tenantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ledgerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(Header);
