// import { createEnumMap } from '../../../../../helpers/utils/Formatter';

// const FILTER_STAGES = createEnumMap(['Filter Types List', 'Filter Section', 'Applied Filters']);
const FILTER_STAGES = {
    FILTER_TYPES_LIST: 1,
    FILTER_SECTION: 2,
    APPLIED_FILTERS: 3,
};

export default FILTER_STAGES;
