import { CONFIG_ENDPOINTS } from 'Commons/config/constants/Endpoints';
import {
    getActionConfig,
    getDynamicConfigFromOnMount,
    getCustomHeaderConfigByStoreKey,
} from './getConfig';
import STATUS from '../../../config/constants/StoreKeyStatus';
import REQUEST_TYPES from '../../../config/constants/RequestTypes';
import calculatePriority from './calculatePriority';
import { action } from '../../../redux/helper/Store';
import configTypes from '../../../redux/config/Config';
import accountContextRequiredConfig from '../../../redux/config/AccountContextConfig';
import { deepMerge } from '../../../helpers/utils/DataHelpers';
import { deepCopy } from '../../../helpers/utils/DeepCopy';

const emptyCallback = () => { };

/**
* @description Dispatch actions to store helper
* @param {Array} apiQueue Current batch that needs to be dispatched
*/
const dispatchAPIQueueActions = (apiQueue = [], dispatch, storeData) => (apiQueue.length ? apiQueue.forEach((api) => {
    action(api, dispatch, storeData);
}) : null);
export default class BatchProcessor {
    constructor() {
        this.nextBatchToProcess = [];
        this.status = {
            loading: false,
            error: false,
        };
        this.curStoreKeys = {};
        this.statusToKeyMap = {};
    }

    init(componentConfig = {}, dispatch, routeProps = {}) {
        this.componentRouteProps = { ...routeProps };
        this.componentConfig = componentConfig;
        this.dispatch = dispatch;
        this.batchedCriticalKeys = calculatePriority(componentConfig.dependencyConfig || {}) || [];
        return this.batchedCriticalKeys;
    }

    resolveCustomHeaderFromStore(key = '') {
        const { id, type } = getCustomHeaderConfigByStoreKey(key);
        if (typeof id === 'function') {
            const contextId = id(this.curStoreKeys);
            return {
                id: contextId,
                type,
            };
        }
        if (id) {
            return {
                id,
                type,
            };
        }
        return null;
    }

    getCustomHeader({ type, key, context }) {
        if (type === 'store' && key) {
            return this.resolveCustomHeaderFromStore(key);
        }
        if (type === 'default' && context) {
            return context;
        }
        return null;
    }

    getAccountContext = () => {
        const { currentAccountId: { data: { id } = {} } = {} } = this.curStoreKeys;
        const { match: { params: { accountId } = {} } = {} } = this.componentRouteProps;
        if (id || accountId) {
            return { id: id || accountId, type: 'Account' };
        }
        return null;
    }

    processCustomHeaders(apiOptions = {}, storeKey) {
        const { api: { customHeaderOptions, headers = {}, ...apiConfig } = {}, store, ...rest } = apiOptions;
        const { endPoint } = apiConfig;
        let contextHeaders;
        // TODO: Find better approach to identify config searches
        if (accountContextRequiredConfig.includes(storeKey) || endPoint === CONFIG_ENDPOINTS.search) {
            contextHeaders = this.getAccountContext();
        }
        if (customHeaderOptions) {
            contextHeaders = this.getCustomHeader(customHeaderOptions);
        }
        if (contextHeaders) {
            const { id = '', type = '' } = contextHeaders;
            if (id && type) {
                return {
                    api: {
                        ...apiConfig,
                        headers: {
                            ...headers, // Merging other headers users might have sent
                            'context-id': id,
                            'context-type': type,
                        },
                    },
                    store,
                    ...rest,
                };
            }
        }
        return apiOptions;
    }

    getRequestOptions(actionKey, options) {
        const { api } = options;
        const { storeKey, actionType } = this.getStoreKeyFromAction(actionKey);
        if (api) {
            const apiFetchConfig = api.action
                ? {
                    api: { ...deepMerge(api.action, api.dynamic || {}) },
                    store: this.getStoreConfig(storeKey, actionType),
                }
                : this.getRequestConfigForKey(actionKey, api.dynamic || {});
            return this.processCustomHeaders(apiFetchConfig, storeKey);
        }
        return undefined;
    }

    /**
     * @description Getting the storeKeys to dispatch
     * @param {*} criticalKeysBatches
    */
    groupStoreKeysToDispatch(criticalKeysBatches) {
        return criticalKeysBatches.map(batch => batch.map(keys => keys.key));
    }

    clearStore = () => {
        this.dispatch({
            type: 'clear_store',
            payload: {},
            storeKey: {},
        });
    }

    getGroupedBatch = (actionType, batch) => {
        let regularBatch = [];
        const configBatch = [];

        if (actionType === 'read') {
            batch.forEach((key) => {
                if (configTypes.includes(key)) {
                    configBatch.push(key);
                } else {
                    regularBatch.push(key);
                }
            });
        } else {
            regularBatch = batch;
        }

        return {
            regularBatch,
            configBatch,
        };
    }

    getStoreConfig = (key, actionType, storeDetail = {}) => ({
        ...storeDetail,
        key,
        actionKey: `${key}_${actionType}`,
        payload: {},
        dispatch: this.dispatch,
    })

    /**
     * Get the request options
     * @param {*} actionType
     * @param {*} batch
     * @param {Object} options Any dynamic config that needs to be appended
     */
    calculateRequestQ(actionType, batch = [], options = {}) {
        const { regularBatch, configBatch } = this.getGroupedBatch(actionType, batch);
        let configBatchApiRequests = 0;
        let configRequest;

        let requestAPIQueue = regularBatch.map((key) => {
            const { api, store } = this.getRequestOptions(`${key}_${actionType}`, options);
            if (store) {
                store.dispatch = this.dispatch;
            }
            return {
                ...options,
                api,
                store,
            };
        });

        if (configBatch.length) {
            configBatchApiRequests = 1;
            const storeKey = 'config';
            // Calling getRequestConfigForKey to avoid dual merging of dynamic config
            const { api: configRequestApi } = this.getRequestConfigForKey(`${storeKey}_${actionType}`, {});
            const { body: { filter: initialFilter } } = configRequestApi;

            const configFilter = [];
            const includeRestricted = [];
            let configHeaders;

            configBatch.forEach((key) => {
                const { api: { body: { filter = [], __includeRestricted = [] } = {}, headers } = {} } = this.getRequestOptions(`${key}_${actionType}`, options) || {};
                configHeaders = headers;
                configFilter.push(...filter);
                includeRestricted.push(...__includeRestricted);
            }, initialFilter);

            configRequestApi.body.filter = configFilter;
            // eslint-disable-next-line no-underscore-dangle
            configRequestApi.body.__includeRestricted = includeRestricted;
            configRequestApi.headers = configHeaders;

            const { api: { dynamic = {} } = {}, ...restOptions } = options;
            const mergedApi = deepCopy(deepMerge(dynamic, configRequestApi));

            // Set only if all requested configurations has action config
            if (configFilter.length === configBatch.length) {
                configRequest = {
                    ...restOptions,
                    api: mergedApi,
                    store: {
                        key: 'config',
                        keys: configBatch,
                        actionKey: 'config_read',
                        payload: {},
                        dispatch: this.dispatch,
                    },
                };
            }
        }
        // Adding config batch to requestApiQueue
        if (configRequest) {
            requestAPIQueue.push(configRequest);
        }

        // Remove keys that do not have a request body
        requestAPIQueue = requestAPIQueue.filter(singleRequest => ((singleRequest.api || singleRequest.store)
            ? singleRequest : false));
        return {
            requestAPIQueue,
            error: (regularBatch.length + configBatchApiRequests) !== requestAPIQueue.length,
        };
    }

    /**
     * Process a batch of store keys, make API calls and update Redux Store
     * @param {Array} batchedCriticalKeys All the store keys that needs to be batched and fetched
     * @param {*} actionType The action type
     * @param {Object} options Additional options
     */
    nextBatch(
        batchedCriticalKeys = [],
        actionType = 'read',
        options = {
            requestType: REQUEST_TYPES.INIT,
            api: {},
            callback: emptyCallback,
        },
    ) {
        // Dereferencing params
        const storeOptions = { ...options, processCustomHeaders: this.processCustomHeaders.bind(this) };
        const localBatchedCriticalKeys = [...batchedCriticalKeys];
        const {
            requestType = REQUEST_TYPES.INIT,
            api,
            store,
        } = storeOptions;
        const batchToProcess = batchedCriticalKeys.length > 0
            ? Object.assign(batchedCriticalKeys) : this.nextBatchToProcess;
        if (requestType === REQUEST_TYPES.INIT) {
            Object.keys(this.curStoreKeys).forEach((key) => {
                if (this.curStoreKeys[key].status > STATUS.LOADING && batchToProcess.indexOf(key) > -1) {
                    batchToProcess.splice(batchToProcess.indexOf(key), 1);
                }
            });
        }

        if (batchToProcess.length > 0) {
            // Transform the store keys to action keys
            // Append Action Type
            let { requestAPIQueue, error } = this.calculateRequestQ(actionType, batchToProcess, storeOptions); // eslint-disable-line prefer-const
            // If there are any keys that do not have request body, then the component cannot be loaded
            if (!error) {
                // Remove Keys whose state are invalid
                requestAPIQueue = this.getRequestQ(requestAPIQueue, requestType);
                dispatchAPIQueueActions(requestAPIQueue, this.dispatch, this.curStoreKeys);
                this.status.error = false;
            } else {
                this.status.error = true;
            }
        } else if (localBatchedCriticalKeys.length === 0) {
            // Developer had not passed a batch, only API or only Store call
            if (api) {
                // If action config already passed
                // By passing store
                const {
                    action: actionApiConfig = {},
                    dynamic = {},
                } = api;
                storeOptions.api = { ...actionApiConfig, ...dynamic };
                const batchOptions = this.processCustomHeaders(storeOptions);
                action(batchOptions, this.dispatch, this.curStoreKeys);
            } else if (store) {
                // If payload passed then dispatch it
                if (store.payload) {
                    store.dispatch = this.dispatch;
                    action(storeOptions, undefined, this.curStoreKeys);
                }
            }
        } else {
            // All store keys are resolved
        }
        // this.status.error = false;
    }

    getPropsForState(state, componentConfig = {}) {
        let localState = Object.assign(state);
        // Iterate through state keys component is interested in
        const { all = [], critical = [] } = componentConfig;
        if (all.length) {
            localState = Object.keys(localState)
                .filter(key => all.includes(key))
                .reduce((obj, key) => ({
                    ...obj,
                    [key]: localState[key],
                }), {});
            this.curStoreKeys = localState;
            // For processing batch
            this.statusToKeyMap = this.indexKeyByStatus(localState, critical);

            // Check if any processed key got unloaded
            this.checkAndResetBatch(this.statusToKeyMap);

            if (!this.statusToKeyMap[STATUS.INVALID] && !this.statusToKeyMap[STATUS.ERROR]) {
                const resolvedKeys = this.statusToKeyMap[STATUS.RESOLVED] || [];
                if (resolvedKeys.length) {
                    this.processResolvedKeys(localState, resolvedKeys);
                    // Need to set error to false, if it was resolved later
                    this.status.error = false;
                }
            } else {
                this.status.error = true;
            }
        }
        return localState;
    }

    getRequestConfigForKey(key = '', dynamicConfig = {}) {
        const localDynamicConfig = Object.assign({}, dynamicConfig);

        // Get the store key
        const { storeKey, actionType } = this.getStoreKeyFromAction(key);
        const { store: storeDetail = {}, ...actionConfigRequest } = getActionConfig(storeKey, actionType) || {};
        const storeConfig = this.getStoreConfig(storeKey, actionType, storeDetail);

        // Adding timestamp to dynamic config
        if (Object.keys(localDynamicConfig).length > 0) {
            localDynamicConfig.timestamp = Date.now();
        } else {
            const { componentConfig: { onMount } = {} } = this;
            const dynamicConfigForMount = onMount && onMount[storeKey] && getDynamicConfigFromOnMount(onMount, storeKey, this.curStoreKeys,
                actionConfigRequest, this.componentRouteProps, storeConfig);
            if (dynamicConfigForMount) {
                return dynamicConfigForMount;
            }
        }
        // Check if we have required API request config
        // const api = { ...actionConfigRequest, ...localDynamicConfig };
        const api = JSON.parse(JSON.stringify(deepMerge(actionConfigRequest, localDynamicConfig)));
        return Object.keys(api).length
            ? {
                api,
                store: storeConfig,
            }
            : undefined;
    }

    getStoreKeyFromAction(actionKey = '') {
        const [storeKey = '', actionType = ''] = actionKey.split('_');
        // return storeKeyToReturn;
        return {
            storeKey,
            actionType,
        };
    }

    getRequestQ(requestAPIQueue, requestType = REQUEST_TYPES.INIT) {
        const newRequestAPIQueue = requestAPIQueue.filter((singleRequest) => {
            const localSingleRequest = Object.assign(singleRequest);
            const { storeKey } = this.getStoreKeyFromAction(localSingleRequest.key);
            const { all = {} } = this.componentConfig;
            // Checking if the storeKey is part of props or has already been subscribed to redux store
            if ((all.indexOf(storeKey) > -1)) {
                // Redux Store Subscribed
                const { storeKey: { status = STATUS.UNLOADED } = {} } = this.curStoreKeys;
                if ((status > STATUS.INVALID)) {
                    localSingleRequest.storeKey = storeKey;
                    localSingleRequest.requestType = requestType;
                    return localSingleRequest;
                }
            }
            return true;
        });
        return newRequestAPIQueue;
    }

    indexKeyByStatus(currentState = {}, criticals = []) {
        const statusToKeyMap = {};
        criticals.forEach((criticalKey) => {
            const { status = STATUS.UNLOADED } = currentState[criticalKey] || {};
            statusToKeyMap[status] = (statusToKeyMap[status] || []).concat(criticalKey);
            if (status > STATUS.LOADING && status !== STATUS.ERROR) {
                statusToKeyMap[STATUS.RESOLVED] = (statusToKeyMap[STATUS.RESOLVED] || []).concat(criticalKey);
            }
        });
        return statusToKeyMap;
    }

    checkAndResetBatch(statusToKeyMap = {}) {
        const currentUnloadedKeys = statusToKeyMap[STATUS.UNLOADED] || [];
        if (!currentUnloadedKeys.length) {
            return false;
        }

        for (let batchIndex = 0; batchIndex <= this.currentBatchIndex; batchIndex += 1) {
            const matchedKeys = this.batchedCriticalKeys[batchIndex] ? currentUnloadedKeys.filter(
                unloadedKey => this.batchedCriticalKeys[batchIndex].includes(unloadedKey),
            ) : [];
            if (matchedKeys.length) {
                this.resetBatch(batchIndex);
                return true;
            }
        }

        return false;
    }

    clearKeys = (storeKeys = []) => {
        this.dispatch({
            type: 'multikeys_clear',
            payload: {},
            keys: storeKeys,
        });
    }

    resetBatch(batchIndex) {
        this.nextBatchToProcess = [...(this.batchedCriticalKeys[batchIndex] || [])];
        this.currentBatchIndex = batchIndex;
        this.nextBatch(this.nextBatchToProcess, 'read');
    }

    areCriticalsResolved() {
        const { componentConfig: { critical = [] } } = this;
        const resolvedKeys = this.statusToKeyMap[STATUS.RESOLVED] || [];
        return resolvedKeys.length === critical.length;
    }

    areCriticalKeysValid() {
        const invalidKeys = [
            ...(this.statusToKeyMap[STATUS.ERROR] || []),
            ...(this.statusToKeyMap[STATUS.INVALID] || []),
        ];
        return invalidKeys.length;
    }

    isError() {
        return this.status.error;
    }

    isLoading() {
        return (!this.areCriticalsResolved());
    }

    start() {
        this.currentBatchIndex = 0;
        if (this.batchedCriticalKeys[this.currentBatchIndex]) {
            this.nextBatchToProcess = [...this.batchedCriticalKeys[this.currentBatchIndex]];
            this.nextBatch(this.nextBatchToProcess, 'read');
        }
    }

    updateBatch(method = 'read') {
        if (this.nextBatchToProcess.length === 0) {
            this.currentBatchIndex += 1;
            if (this.batchedCriticalKeys && this.batchedCriticalKeys[this.currentBatchIndex]) {
                // console.log('Process Next batch');
                this.nextBatchToProcess = [...this.batchedCriticalKeys[this.currentBatchIndex]];
                this.nextBatch(this.nextBatchToProcess, method);
            } else {
                // console.log('Finished Processing Batch');
                this.status.loading = false;
            }
        }
    }

    processResolvedKeys(state, resolvedKeys = []) {
        let indexOfResolvedKey = -1;
        resolvedKeys.forEach((resolvedKey) => {
            indexOfResolvedKey = this.nextBatchToProcess.indexOf(resolvedKey);
            if (indexOfResolvedKey > -1) {
                this.nextBatchToProcess.splice(indexOfResolvedKey, 1);
            }
        });
        this.updateBatch();
    }
}
