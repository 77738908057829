const AddressStyles = () => ({
    threeDotProgressSpinner: {
        '& div': {
            width: 10,
            height: 10,
        },
    },
});

export default AddressStyles;
