import { VIEW } from 'Commons/config/constants/Constants';
import { USER_ENDPOINTS } from 'Commons/config/constants/Endpoints';

const callApi = (
    onAction, endPoint, body,
    callback = () => { }, methodType = 'POST', method = 'read', customHeaderOptions = {
        type: 'store',
        key: 'account',
    },
) => {
    const api = {
        method: `${method}`,
        action: {
            methodType: `${methodType}`,
            endPoint,
            body,
            customHeaderOptions,
        },
    };
    if (onAction) {
        onAction({
            config: [{
                api,
                callback,
            }],
        });
    }
};

const getCustomHeaderOptions = accountId => ({
    type: 'default',
    context: { type: 'Account', id: accountId },
});


export const saveNewUserAction = ({ onAction, user, accountId, callback }) => {
    const customHeaderOptions = getCustomHeaderOptions(accountId);
    callApi(onAction, 'user', user, callback, 'POST', 'create', customHeaderOptions);
};

export const verifyEmail = ({ onAction, body, accountId, callback }) => {
    const endPoint = USER_ENDPOINTS.checkIfEmailExists;
    const customHeaderOptions = getCustomHeaderOptions(accountId);
    callApi(onAction, endPoint, body, callback, 'POST', 'read', customHeaderOptions);
};

export const saveEditedUserAction = ({ onAction, userId, accountId, editFormData, callback }) => {
    const endPoint = `user/${userId}`;
    const customHeaderOptions = getCustomHeaderOptions(accountId);
    callApi(onAction, endPoint, editFormData, callback, 'PUT', 'update', customHeaderOptions);
};

export const userAssociation = ({ onAction, id, accountId, permissions, callback }) => {
    const endPoint = `user/${id}/permission`;
    const body = { permissions };
    const customHeaderOptions = getCustomHeaderOptions(accountId);
    callApi(onAction, endPoint, body, callback, 'POST', 'create', customHeaderOptions);
};

export const userSearch = ({ onAction, filter, callback }) => {
    const endPoint = 'user/search';
    const body = { view: 'detail', filter: [...filter] };
    callApi(onAction, endPoint, body, callback);
};

export const userPermissionSearch = ({ onAction, userId, callback }) => {
    const endPoint = 'user/permission/search';
    callApi(onAction, endPoint, { userId }, callback);
};

export const unlockUser = (onAction, userId, callback) => {
    const endPoint = `auth/unlock-user/${userId}`;
    callApi(onAction, endPoint, undefined, callback);
};

export const forcePasswordReset = (onAction, userId, callback) => {
    const endPoint = `auth/force-password-reset/${userId}`;
    callApi(onAction, endPoint, undefined, callback);
};

export const getUserData = ({ onAction, accountId, callback, userId }) => {
    if (userId) {
        onAction({
            sync: true,
            config: [{
                api: {
                    method: 'read',
                    action: {
                        methodType: 'POST',
                        endPoint: 'user/search',
                        body: {
                            view: VIEW.detail.value,
                            filter: [{ terms: { id: [userId] } }],
                            include_relations: { User_Auth_Details: {} },
                        },
                        ...accountId ? {
                            headers: {
                                'context-id': accountId,
                                'context-type': 'Account',
                            },
                        } : {
                            customHeaderOptions: {
                                type: 'store',
                                key: 'account',
                            },
                        },
                    },
                },
                callback: (apiError, response) => callback(apiError, response, true),
            },
            {
                api: {
                    method: 'read',
                    action: {
                        methodType: 'POST',
                        endPoint: 'user/permission/search',
                        body: { userId },
                        ...accountId ? {
                            headers: {
                                'context-id': accountId,
                                'context-type': 'Account',
                            },
                        } : {
                            customHeaderOptions: {
                                type: 'store',
                                key: 'account',
                            },
                        },
                    },
                },
                callback: (apiError, response) => callback(apiError, response, false),
            }],
        });
    }
};
