import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import { FormTextField } from 'Generic/textfield/components/TextField';
import LabelWithIcon from 'Generic/labelwithicon/components/LabelWithIcon';
import { FormDatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { clsx } from 'Commons/helpers/utils/clsx';
import { getIn as getValue, isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import { EMPTY_OBJECT } from 'Commons/config/constants/Constants';
import RangeFilterStyle from '../styles/RangeFilterStyle';
import { DATE_RANGE_WIDTH } from '../config/Constants';

const useStyles = makeStyles(RangeFilterStyle, { name: 'TenantFilterStyle' });

const RangeFilter = ({
    setFieldValue, isDateRange, name, label, fromAndToPlaceholder,
    disableFuture, disablePast, classes: classesProp, formProps,
}) => {
    const classes = useStyles({ classes: classesProp });
    const { Component, componentProps, clearLabel } = useConstructor(() => ({
        Component: isDateRange ? FormDatePicker : FormTextField,
        componentProps: !isDateRange
            ? { rateField: true, inputProps: { className: classes.input } }
            : { width: DATE_RANGE_WIDTH, disableFuture, disablePast },
        clearLabel: isDateRange ? localisable.clearDate : localisable.clearBalance,
    }));

    const onClear = () => {
        const { values = {} } = formProps;
        const fromRange = getValue(values, `range.${name}.from`);
        const toRange = getValue(values, `range.${name}.to`);
        if (fromRange || toRange || !isObjWithKeys(formProps)) setFieldValue(`range.${name}`, {});
    };

    return (
        <>
            <Typography variant="subtitle1" className={`${classes.dateRangeLabel} ${classes.restLabel}`}>
                {label}
            </Typography>
            <Grid container wrap="nowrap" justify="space-between" spacing={2}>
                <Grid item>
                    <Component
                        name={`range.${name}.from`}
                        label={localisable.from}
                        noGrid
                        noIndicatorWrapper
                        placeholder={fromAndToPlaceholder}
                        {...componentProps}
                    />
                </Grid>
                <Grid item>
                    <Component
                        name={`range.${name}.to`}
                        label={localisable.to}
                        noGrid
                        noIndicatorWrapper
                        placeholder={fromAndToPlaceholder}
                        {...componentProps}
                    />
                </Grid>
            </Grid>
            <LabelWithIcon
                classes={{ labelWithIcon: classes.autoWidth }}
                className={clsx(classes.clearBalance)}
                onClick={onClear}
            >
                <Typography color="primary" variant="body2">
                    {clearLabel}
                </Typography>
            </LabelWithIcon>
        </>
    );
};

RangeFilter.propTypes = {
    setFieldValue: PropTypes.func,
    isDateRange: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    classes: PropTypes.string,
    fromAndToPlaceholder: PropTypes.string,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    formProps: PropTypes.object,
};

RangeFilter.defaultProps = {
    isDateRange: false,
    formProps: EMPTY_OBJECT,
};

export default RangeFilter;
