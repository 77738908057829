import media from '../../../../theme/MediaQueries';

const modalStyle = theme => ({
    closeButton: {
        position: 'absolute',
        borderRadius: 0,
        outline: 'none',
        border: 'none',
        top: 0,
        right: 0,
        margin: '16px 16px 0px 0px',
        zIndex: 100,
        width: 'auto',
        height: 'auto',
        '&:hover': { backgroundColor: 'transparent' },
    },
    expandButton: {
        position: 'absolute',
        borderRadius: 0,
        outline: 'none',
        border: 'none',
        top: 0,
        right: 32,
        margin: '16px 16px 0px 0px',
        zIndex: 100,
        width: 'auto',
        height: 'auto',
        '&:hover': { backgroundColor: 'transparent' },
    },
    closeIcon: {},
    expandIcon: {},
    modalGrid: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        flexDirection: 'column',
        width: '21vw',
        minHeight: '30vh',
        backgroundColor: theme.palette.common.white,
        border: 'none',
        outline: 'none',
        [media.mobile]: { width: '95vw' },
        [media.tablet]: { width: '35vw' },
        [media.laptop]: { width: '25vw' },
        [media.desktop]: { width: '25vw' },
    },
    modal: {},
    modalBody: { position: 'relative', flex: '1 1 auto' },
    modalHeader: {
        minHeight: 50,
        position: 'relative',
        '& $closeButton': {
            bottom: 0,
            margin: 'auto 16px auto auto',
        },
    },
    modalFooter: {},
    modalDivider: { width: '100%' },
});
export default modalStyle;
