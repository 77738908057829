import { makeStyles, Grid, Typography } from 'Generic/componentlibrary/components/Components';
import { shortcuts } from '../../../../config/Config';
import infoCardStyles from '../../styles/InfoCardStyles';

const useStyles = makeStyles(infoCardStyles, { name: 'KeyboardShortcutsCard' });

const KeyboardShortcut = ({ label, description }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.cardTopMargin}>
            <Grid item lg={5}>
                <Typography variant="body1">
                    {label}
                </Typography>
            </Grid>
            <Grid item lg={7}>
                <Typography variant="body1">
                    {description}
                </Typography>
            </Grid>
        </Grid>
    );
};

const KeyboardShortcuts = () => shortcuts.map(({ label, description }) => (
    <KeyboardShortcut
        key={label}
        label={label}
        description={description}
    />
));

KeyboardShortcut.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default KeyboardShortcuts;
