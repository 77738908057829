const reportStyle = () => ({
    noCloseButton: { display: 'none' },
    reportProgressModal: {},
    dialogContentRoot: {
        // padding: 0,
        /* Temporary Styles - For Loader */
        minHeight: '18vh',
        display: 'flex',
        alignItems: 'center',
    },
});

export default reportStyle;
