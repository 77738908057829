const ConversationListFilterStyle = theme => ({
    autoWidth: { width: 'auto' },
    clearDate: {
        alignSelf: 'flex-end',
        paddingTop: theme.spacing(1),
    },
    firstLabel: { paddingBottom: '16px' },
    restLabel: {
        paddingTop: '32px',
        paddingBottom: '16px',
    },
});

export default ConversationListFilterStyle;
