import { Grid, Loader, Typography, withStyles } from 'Generic/componentlibrary/components/Components';
import BaseForm from 'Commons/components/business/baseform/components/BaseForm';
import { Form, Formik } from 'formik';
import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import { getDiffProperties, isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import localisable from 'Commons/config/strings/localisable';
import Page from 'Commons/components/business/page/components/Page';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import Button from 'Generic/button/components/Button';
import getName from 'Commons/helpers/utils/NameHelper';
import { isUserLocked } from 'Commons/components/business/user/form/utils/Helpers';
import Header from 'Commons/components/business/user/form/components/Header';
import { EMPTY_FUNC, SOURCE, STATUS } from 'Commons/config/constants/Constants';
import { VARIANT } from 'Generic/snackbar/config/Constants';
import { createRef } from 'react';
import INTERNAL_ROUTES from 'Internal/redux/config/RouteNames';
import { getUserActionBreadCrumb } from 'Internal/containers/accountManagement/components/userSetup/config/BreadCrumbItem';
import { convertToNumber, isLiveData } from 'Commons/helpers/utils/Utils';
import {
    forcePasswordReset,
    getUserData,
    saveEditedUserAction,
    saveNewUserAction,
    unlockUser,
    userAssociation,
    userPermissionSearch,
    userSearch,
} from './config/ApiRequests';
import BasicInformation from './components/BasicInformation';
import Preview from './components/Preview';
import RoleSelection from './components/RoleSelection';
import userFormStyle from './styles/UserFormStyle';
import { ENTITY_TYPE } from './config/RoleSelectionConfig';
import preProcessPreviewData from './utils/previewDataPreProcessor';
import { storeKeyToEntityTypeMapping, USER_ACTION_ITEM } from './config/Constants';

class UserForm extends BaseForm {
    constructor(props) {
        super(props);
        const {
            fetchLazy,
            match: { url } = {},
            facility: { data: { data: facility = [] } = {} } = {},
        } = props;
        fetchLazy('read', { api: { dynamic: { body: isLiveData(url) ? { size: 1000 } : {} } } });
        this.defaultAlertDialogConfig = {
            title: localisable.warning,
            buttonText: localisable.okInCapitals,
            alertMessage: localisable.discardConfirmation,
        };
        this.state = {
            userList: [],
            loading: false,
            previewData: [],
            isDataUpdated: false,
            shouldShowAlert: false,
            initialFormStatus: {},
            alertDialogConfig: this.defaultAlertDialogConfig,
            ...this.isModeCreate() && {
                userInfo: {},
                initialFormStatus: { formStatus: STATUS.Active.value },
            },
            roleSelectionContainerRefSet: false,
        };
        this.alertDialogConfig = this.defaultAlertDialogConfig;
        this.userPermissions = [];
        this.initialPermissions = [];
        this.facilityObject = this.convertArrayToObject(facility);
        this.defaultFacilityList = [];
        this.roleSelectionContainerRef = createRef();
    }

    setFacilityObject = () => {
        if (!this.facilityObject) {
            const { facility: { data: { data: facility = [] } = {} } = {} } = this.props;
            this.facilityObject = this.convertArrayToObject(facility);
        }
    }

    componentDidMount = () => {
        const { getBreadCrumbList } = this.props;
        this.setFacilityObject();
        this.unblock = this.allowNavigation(this.shouldLeave, ['create', 'edit']);
        if (!this.isModeCreate()) {
            this.fetchUser();
        }
        if (getBreadCrumbList) {
            const { match: { params: { accountId } = {} } = {}, location: { state: { fid } } } = this.props;
            const { facilityObject: { [fid]: { name } = {} } } = this;
            const mode = this.isModeCreate() ? 'create' : 'edit';
            getBreadCrumbList(getUserActionBreadCrumb(mode, name, accountId, fid));
        }
    }

    componentWillUnmount() {
        this.setFacilityObject();
        const { unblock = () => { } } = this;
        unblock();
    }

    shouldLeave = () => {
        const { dirty, isValid } = this.formProps;
        const { isDataUpdated } = this.state;
        const { clearStoreKeys = EMPTY_FUNC } = this.props;
        if (dirty || isValid || isDataUpdated) {
            this.setState({ discard: true });
            return false;
        }
        if (this.isFormUpdated) {
            clearStoreKeys(['user']);
        }
        return true;
    }

    getRoleSelectionContainerRef = (ref) => {
        if (ref) {
            this.roleSelectionContainerRef = ref;
            this.setState({ roleSelectionContainerRefSet: true });
        }
    };

    isFormActive = () => {
        const { status: { formStatus } = {} } = this.formProps;
        return [STATUS.Active.value, STATUS.Created.value].includes(formStatus);
    }

    setLoading = (value = true) => this.setState({ loading: value })

    convertArrayToObject = (data = []) => data.reduce((obj, element) => ({
        ...obj,
        [element.id]: element,
    }), {})

    getRoleList = () => {
        const { userRole: { data: { data: rolesData = [] } = {} } = {} } = this.props;
        return rolesData.reduce((obj, role) => ({
            ...obj,
            [role.id]: role,
        }), {});
    }

    fetchUser = () => {
        const { onAction, match: { params: { userId, accountId } } } = this.props;
        getUserData({
            onAction,
            accountId,
            userId,
            callback: this.onUserFetch,
        });
    }

    preProcessPermissions = (permissions) => {
        const [{ permission: { roleId = '', ...remainingRoleMappings } = {}, ...otherValues }] = permissions;
        const [accountId] = Object.keys(this.accountObject);
        return [{
            ...otherValues,
            permission: {
                ...roleId && { account: { [accountId]: { roleId } } },
                ...remainingRoleMappings,
            },
        }];
    }

    onUserFetch = (apiError, response, isUserFetch) => {
        if (apiError) {
            this.handleErrors(apiError);
        } else if (response) {
            if (isUserFetch) {
                const {
                    data: [userInfo] = [],
                    relationalData: { userAuthDetails: [{ lockedUntil } = {}] = [] } = {},
                } = response;
                const { status } = userInfo || {};
                this.setState({
                    userInfo,
                    isUserLockedValue: isUserLocked(lockedUntil),
                    initialFormStatus: { formStatus: status },
                });
            } else {
                let { data: { permissions } } = response;
                permissions = permissions && this.preProcessPermissions(permissions);
                this.initialPreviewData(permissions);
            }
        }
    }

    initialPreviewData = (permissions = []) => {
        this.initialPermissions = deepCopy(permissions);
        this.getPreviewData(permissions);
    }

    getPreviewData = (permissions = [], dataUpdated) => {
        const { facilityObject, accountObject, businessGroupObject } = this;
        const { userRole: { data: { data: rolesData = [] } = {} } = {} } = this.props;
        this.userPermissions = [];
        permissions.forEach((permissionData) => {
            const facilityPermissions = {};
            const {
                permission: { facility = {}, ...restNestedPermissions } = {},
                ...restPermissionData
            } = permissionData || {};
            Object.keys(facility).forEach((id) => {
                const { [id]: facilityData = {} } = facilityObject;
                if (isObjWithKeys(facilityData)) {
                    facilityPermissions[id] = facility[id];
                }
            });
            this.userPermissions.push({
                ...restPermissionData,
                permission: { ...restNestedPermissions, facility: facilityPermissions },
            });
        });
        const initialUserRoleData = preProcessPreviewData({
            permissions: this.userPermissions,
            roles: rolesData,
            facilityObject,
            accountObject,
            businessGroupObject,
        });
        this.getDefaultFacilitiesList();
        this.setState({ previewData: initialUserRoleData, isDataUpdated: dataUpdated });
    }

    onApplyRole = ({ selectedRole, selectedEntity }) => {
        const { previewData = [] } = this.state;
        const { id: selectedRoleId, name: selectedRoleLabel } = selectedRole;
        const updatedPreviewData = deepCopy(previewData);
        const { onRowSelect, type: entityType, ...roleData } = selectedEntity;
        // eslint-disable-next-line no-nested-ternary
        const color = (entityType === ENTITY_TYPE.FACILITY)
            ? 'rolesTertiary'
            : (entityType === ENTITY_TYPE.BUSINESSGROUP)
                ? 'rolesSecondary'
                : 'rolesPrimary';
        this.updateUserPermissions({ selectedRole, selectedEntity });
        const indexOfUpdatedRole = updatedPreviewData.findIndex(roles => roles.id === selectedRoleId);
        if (indexOfUpdatedRole >= 0) {
            const roleToUpdate = updatedPreviewData[indexOfUpdatedRole];
            const { permissions } = roleToUpdate;
            permissions.push({ color, type: entityType, ...roleData });
        } else {
            const permissionsData = [];
            permissionsData.push({ color, type: entityType, ...roleData });
            updatedPreviewData.push({ id: selectedRoleId, name: selectedRoleLabel, permissions: permissionsData });
        }
        this.defaultFacilityList.push({ label: selectedEntity.name, value: selectedEntity.id });
        this.setState({ previewData: updatedPreviewData, isDataUpdated: true });
    }

    getRespectiveData = (tabName) => {
        switch (tabName) {
            case ENTITY_TYPE.BUSINESSGROUP: return this.businessGroupObject;
            case ENTITY_TYPE.ACCOUNT: return this.accountObject;
            default: return this.facilityObject;
        }
    }

    selectAllPreviewData = ({ tabName, selectedRoleData }) => {
        const { id: roleId } = selectedRoleData;
        const [{ permission = {} } = {}] = this.userPermissions;
        const getRespectedData = this.getRespectiveData(tabName);
        permission[tabName] = Object.keys(getRespectedData).reduce((obj, tabKey) => ({
            ...obj,
            [tabKey]: { roleId },
        }), {});
        this.getPreviewData([{ permission: { ...permission } }], true);
    }

    updateUserPermissions = ({ selectedRole, selectedEntity }) => {
        const { type: entityType, id: entityId } = selectedEntity;
        const { id: selectedRoleId } = selectedRole;
        const [{ permission = {} } = {}] = this.userPermissions;
        if (permission && permission[entityType]) {
            permission[entityType][entityId] = { roleId: convertToNumber(selectedRoleId) };
        } else {
            permission[entityType] = { [entityId]: { roleId: convertToNumber(selectedRoleId) } };
            this.userPermissions.push({ permission: { ...permission } });
        }
    }

    getUserFooter = () => {
        const { isValid } = this.formProps;
        const { isDataUpdated, isButtonLoading } = this.state;
        const positiveButtonProps = { disabled: !((isValid || isDataUpdated) && !isButtonLoading) };
        return this.getFooter({ positiveButtonProps });
    }

    onDeleteRole = (parentIndex, index, data) => {
        const { previewData } = this.state;
        const { type: typeOfDeleteData, id: deletedId } = data;
        const [{ permission = {} } = {}] = this.userPermissions;
        const updatedPreviewData = deepCopy(previewData);
        const { id, name, permissions } = updatedPreviewData[parentIndex];
        permissions.splice(index, 1);
        updatedPreviewData[parentIndex] = { id, name, permissions };
        delete permission[typeOfDeleteData][deletedId];
        this.defaultFacilityList.splice(this.defaultFacilityList.findIndex(
            facility => facility.value === deletedId,
        ), 1);
        this.setState({ previewData: updatedPreviewData, isDataUpdated: true });
    }

    deleteAllRoles = () => {
        const { setValues, values: { defaultFacility, ...otherValues } } = this.formProps;
        this.userPermissions = [];
        this.defaultFacilityList = [];
        setValues({ ...otherValues });
        this.setState({ previewData: [], isDataUpdated: true });
    }

    setUserListData = (apiError, response) => {
        if (apiError) {
            this.handleErrors(apiError);
        }
        if (response) {
            const { success, data } = response;
            const { match: { params: { userId } = {} } = {} } = this.props;
            if (success) {
                if (!this.userList) {
                    this.userList = deepCopy(data);
                    if (userId) {
                        this.userList = this.userList.filter(({ id }) => id !== userId);
                    }
                }
                this.setState({ userList: this.userList });
            }
        }
        this.setState({ isButtonLoading: false });
    }

    onCopyClick = () => {
        const { userList } = this.state;
        if (!this.userList) {
            this.onUserSearch();
        } else if (this.userList.length !== userList.length) {
            this.setState({ userList: this.userList });
        }
    }

    fetchSelectedUserPermission = (user) => {
        const { onAction } = this.props;
        const { id } = user;
        userPermissionSearch({ onAction, userId: id, callback: this.copyUserList });
    }

    copyUserList = (apiError, response) => {
        if (apiError) {
            this.handleErrors(apiError);
        }
        if (response) {
            const { success, data } = response;
            if (success) {
                this.copiedList = data;
            }
        }
        this.setState({ isButtonLoading: false });
    }

    onUserSearch = (data) => {
        const { onAction } = this.props;
        let filter = [];
        if (data) {
            filter = [{ match: { firstName: data } }];
        }
        userSearch({ onAction, filter, callback: this.setUserListData });
    }

    onCopy = (entityList) => {
        const { permissions: [{ permission }] } = this.copiedList;
        const data = Object.keys(entityList).reduce((obj, entity) => {
            const tempObj = obj;
            if (entityList[entity]) {
                if (permission[entity]) {
                    tempObj[entity] = deepCopy(permission[entity]);
                }
            }
            return tempObj;
        }, {});
        this.getPreviewData([{ permission: { ...data } }], true);
    }

    renderMask = () => {
        const { classes } = this.props;
        return (<Grid container className={classes.mask} />);
    }

    getComponents = () => {
        const {
            classes,
            businessGroup: { data: { data: businessGroup, data: [{ account: accountId } = {}] = [] } = {} } = {},
            account: { data: { data: account } = {} } = {},
            facility: { data: { data: facility = [] } = {} } = {},
            onAction,
        } = this.props;
        const {
            userInfo: { userType } = {}, previewData, userList,
            roleSelectionContainerRefSet,
        } = this.state;
        const { userPermissions = {}, formProps } = this;
        if (businessGroup && !this.businessGroupObject) {
            this.businessGroupObject = this.convertArrayToObject(businessGroup);
            if (this.accountObject) {
                const { [accountId]: accountBelongsToUser } = this.accountObject;
                this.accountObject = { [accountId]: accountBelongsToUser };
            }
        }
        if (account && !this.accountObject) {
            this.accountObject = this.convertArrayToObject(
                accountId ? account.filter(({ id }) => id === accountId) : account,
            );
        }
        if (facility && !this.facilityObject) {
            this.facilityObject = this.convertArrayToObject(facility);
        }
        return (
            <>
                <Grid
                    container
                    item
                    direction="column"
                    wrap="nowrap"
                    className={classes.leftContainer}
                >
                    <Grid
                        item
                        direction="column"
                        wrap="nowrap"
                        className={`${classes.componentContainer} ${classes.basicInfoContainer}`}
                    >
                        <BasicInformation
                            userType={userType}
                            userPermissions={userPermissions}
                            isFormActive={this.isFormActive()}
                            isModeCreate={this.isModeCreate()}
                            defaultFacilityList={this.defaultFacilityList}
                            formProps={formProps}
                            onAction={onAction}
                            accountId={accountId}
                        />
                    </Grid>

                    <Grid
                        ref={this.getRoleSelectionContainerRef}
                        item
                        direction="column"
                        wrap="nowrap"
                        className={`${classes.componentContainer} ${classes.roleSelectionContainer}`}
                    >
                        {(!!this.businessGroupObject || !!this.accountObject) && roleSelectionContainerRefSet ? (
                            <RoleSelection
                                facilityData={this.facilityObject}
                                businessGroupData={this.businessGroupObject}
                                accountData={this.accountObject}
                                userPermissions={userPermissions}
                                userRole={this.getRoleList()}
                                userList={userList}
                                onApplyRole={this.onApplyRole}
                                getRoleSelectionData={this.getRoleSelectionData}
                                selectAllPreviewData={this.selectAllPreviewData}
                                onCopyClick={this.onCopyClick}
                                userSearch={this.onUserSearch}
                                fetchSelectedUserPermission={this.fetchSelectedUserPermission}
                                onCopyUser={this.onCopy}
                                formProps={formProps}
                                isModeCreate={this.isModeCreate()}
                                roleSelectionContainerRef={this.roleSelectionContainerRef}
                            />
                        ) : this.renderLoader()}
                        {!this.isFormActive() && this.renderMask()}
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction="column"
                    wrap="nowrap"
                    className={classes.componentContainer}
                >
                    {
                        (!!this.businessGroupObject || !!this.accountObject) && (
                            <Preview
                                key={previewData}
                                previewData={previewData}
                                onDeleteRole={this.onDeleteRole}
                                deleteAll={this.deleteAllRoles}
                            />
                        )
                    }
                    {!this.isFormActive() && this.renderMask()}
                </Grid>
            </>
        );
    }

    saveNewUser = (user) => {
        this.shouldAssignRoles = !!this.userPermissions.length;
        const { defaultFacility, ...otherUserData } = user;
        this.setState({ isButtonLoading: true });
        const { onAction, match: { params: { accountId }, path } } = this.props;
        const internalRoute = path.split('/')[1];
        const userData = internalRoute === INTERNAL_ROUTES.INTERNAL_MAIN ? { ...otherUserData, account_id_list: [Number(accountId)] } : { ...otherUserData };
        saveNewUserAction({ onAction, user: { ...userData }, accountId, callback: this.onNewUserSave });
    }

    onNewUserSave = (apiError, response) => {
        const { userInfo } = this.state;
        if (apiError) {
            this.handleErrors(apiError);
            this.setState({ isButtonLoading: false });
        }
        if (response) {
            const { success, data } = response;
            if (success) {
                const [userData] = data;
                const { id } = userData;
                this.setSnackbarProps(true, localisable.userFormCreated);
                if (this.shouldAssignRoles) {
                    this.onUserAssociate(id);
                } else {
                    this.setState({
                        discard: false,
                        isDataUpdated: false,
                        isButtonLoading: false,
                        userInfo: { ...userInfo, ...this.editFormData },
                    });
                    this.goBack();
                }
            }
        }
    }

    onEditedUserSave = (apiError, response) => {
        const { userInfo } = this.state;
        if (apiError) {
            this.handleErrors(apiError);
            this.setState({ isButtonLoading: false });
        }
        if (response) {
            const { success } = response;
            if (success) {
                this.setSnackbarProps(true, localisable.userFormUpdated);
                this.setState({
                    discard: false,
                    isDataUpdated: false,
                    isButtonLoading: false,
                    userInfo: { ...userInfo, ...this.editFormData },
                });
                this.goBack();
            }
        }
    }

    getPermission = (roleList = [], permission = {}, role = '') => {
        Object.keys(permission).forEach((entity) => {
            Object.keys(permission[entity]).forEach((entityId) => {
                roleList.push({
                    entityType: storeKeyToEntityTypeMapping[entity],
                    entityId,
                    actionType: role,
                    roleId: permission[entity][entityId].roleId,
                });
            });
        });
    }

    getAssignedRoles = () => {
        const roleList = [];
        if (this.isModeCreate()) {
            if (this.userPermissions && this.userPermissions.length) {
                const [{ permission = {} } = {}] = this.userPermissions;
                this.getPermission(roleList, permission, 'Assign');
            }
        } else {
            if (Object.keys(this.updatedRoles).length) {
                const { 0: { permission = {} } } = this.updatedRoles;
                this.getPermission(roleList, permission, 'Assign');
            }
            if (Object.keys(this.deletedRoles).length) {
                const { 0: { permission = {} } } = this.deletedRoles;
                this.getPermission(roleList, permission, 'Delete');
            }
        }
        return roleList;
    }

    onUserAssociate = (id) => {
        const { onAction, match: { params: { accountId } } } = this.props;
        const permissions = this.getAssignedRoles();
        userAssociation({ onAction, id, accountId, permissions, callback: this.onAssociateUser });
    }

    onAssociateUser = (apiError, response) => {
        const { userInfo } = this.state;
        const { onAction, match: { params: { userId } } } = this.props;
        const { defaultFacility } = this.editFormData;
        if (apiError) {
            this.handleErrors(apiError);
            this.setState({ isButtonLoading: false });
        }
        if (response) {
            const { success, data } = response;
            if (success) {
                if (!this.isModeCreate()) {
                    if (Object.keys(this.editFormData).length) {
                        const { match: { params: { accountId } } } = this.props;
                        saveEditedUserAction({
                            onAction,
                            userId,
                            accountId,
                            editFormData: this.editFormData,
                            callback: this.onEditedUserSave,
                        });
                    } else {
                        this.setSnackbarProps(true, localisable.userFormUpdated);
                        this.setState({
                            discard: false,
                            isDataUpdated: false,
                            isButtonLoading: false,
                            userInfo: { ...userInfo, ...this.editFormData },
                        });
                        this.goBack();
                    }
                } else if (defaultFacility) {
                    const [{ user: createdUserId } = {}] = data;
                    const { match: { params: { accountId } } } = this.props;
                    saveEditedUserAction({
                        onAction,
                        userId: createdUserId,
                        accountId,
                        editFormData: { defaultFacility },
                        callback: this.onEditedUserSave,
                    });
                } else {
                    this.setSnackbarProps(true, localisable.userFormCreated);
                    this.setState({
                        discard: false,
                        isDataUpdated: false,
                        isButtonLoading: false,
                        userInfo: { ...userInfo, ...this.editFormData },
                    });
                    this.goBack();
                }
            }
        }
    }

    goBack = () => {
        const { history, clearStoreKeys } = this.props;
        this.shouldChangeRoute = true;
        clearStoreKeys(['user']);
        history.goBack();
    }

    getDefaultFacilitiesList = () => {
        const { userPermissions } = this;
        if (userPermissions.length) {
            const { permission: { facility: facilityPermissions = {} } = {} } = userPermissions[0];
            if (facilityPermissions) {
                this.defaultFacilityList = Object.keys(facilityPermissions).map(
                    facility => ({ label: this.facilityObject[facility].name, value: facility }),
                );
            }
        }
    }

    saveEditedUser = () => {
        this.setState({ isButtonLoading: true });
        const { onAction, match: { params: { userId } } } = this.props;
        this.updatedRoles = getDiffProperties(this.initialPermissions, this.userPermissions);
        this.deletedRoles = getDiffProperties(this.userPermissions, this.initialPermissions);
        this.shouldAssignRoles = (Object.keys(this.updatedRoles).length || Object.keys(this.deletedRoles).length);
        if (this.shouldAssignRoles) {
            this.onUserAssociate(userId);
        } else {
            const { match: { params: { accountId } } } = this.props;
            saveEditedUserAction({
                onAction,
                userId,
                accountId,
                editFormData: this.editFormData,
                callback: this.onEditedUserSave,
            });
        }
    }

    handleUnlockUserOrForcePasswordResetResponse = (apiError, response, actionItemSource) => {
        let isSuccess = false;
        let message = localisable.somethingWentWrong;
        let variant = VARIANT.error.value;
        if (apiError) {
            message = actionItemSource === USER_ACTION_ITEM.UNLOCK
                ? localisable.failedToUnlockUser
                : localisable.failedToForcePasswordReset;
        } else if (response) {
            const { success = false } = response;
            if (success) {
                message = localisable.mailSentToUserSuccessfully;
                variant = VARIANT.success.value;
                isSuccess = true;
            }
        }
        this.setSnackbarProps(true, message, variant);
        this.setState({
            loading: false,
            ...(isSuccess && actionItemSource === USER_ACTION_ITEM.UNLOCK && { isUserLockedValue: false }),
        });
    }

    handlePositiveAlertButtonClick = (userId, actionItemSource) => {
        const { onAction } = this.props;
        if ([USER_ACTION_ITEM.ACTIVATE, USER_ACTION_ITEM.DEACTIVATE].includes(actionItemSource)) {
            this.handleStatusChangeYes(userId, SOURCE.user.value, {
                customHeaderOptions: {
                    type: 'store',
                    key: 'account',
                },
            });
            return;
        }
        this.setLoading();
        switch (actionItemSource) {
            case USER_ACTION_ITEM.UNLOCK:
                unlockUser(onAction, userId,
                    (apiError, response) => this.handleUnlockUserOrForcePasswordResetResponse(apiError, response, actionItemSource));
                break;
            case USER_ACTION_ITEM.FORCE_PASSWORD_RESET:
                forcePasswordReset(onAction, userId,
                    (apiError, response) => this.handleUnlockUserOrForcePasswordResetResponse(apiError, response, actionItemSource));
                break;
            default:
                break;
        }
    }

    onAlertYesAction = (userId, actionItemSource) => {
        const { state: { discard } = {}, formProps: { dirty } = {}, defaultAlertDialogConfig } = this;
        this.setState({ discard: false, shouldShowAlert: false });
        if (discard && dirty && !actionItemSource) {
            this.handleYes();
        } else if (actionItemSource) {
            this.handlePositiveAlertButtonClick(userId, actionItemSource);
        }
        this.alertDialogConfig = defaultAlertDialogConfig;
    }

    renderHeader = () => {
        const {
            formProps,
            setLoading,
            isModeCreate,
            isFormActive,
            showAlertDialog,
            props: { permission, userProfile = {} },
            state: { isUserLockedValue } = {},
        } = this;
        return (
            <Header
                formProps={formProps}
                permission={permission}
                setLoading={setLoading}
                userProfile={userProfile}
                isUserLocked={isUserLockedValue}
                isModeCreate={isModeCreate()}
                isFormActive={isFormActive()}
                showAlertDialog={showAlertDialog}
            />
        );
    }

    showAlertDialog = (source) => {
        const { userInfo: { firstName, lastName } = {} } = this.state;
        const userName = getName({ firstName, lastName });
        let alertDialogConfig = this.defaultAlertDialogConfig;
        if (source === USER_ACTION_ITEM.ACTIVATE) {
            alertDialogConfig = {
                ...alertDialogConfig,
                source,
                buttonText: `${localisable.yes}, ${localisable.activate}`.toUpperCase(),
                alertMessage: localisable.userActivateConfirmation,
            };
        } else if (source === USER_ACTION_ITEM.DEACTIVATE) {
            alertDialogConfig = {
                ...alertDialogConfig,
                source,
                buttonColor: 'error',
                buttonText: `${localisable.yes}, ${localisable.deactivate}`.toUpperCase(),
                alertMessage: localisable.userDeactivateConfirmation,
            };
        } else if (source === USER_ACTION_ITEM.UNLOCK) {
            alertDialogConfig = {
                ...alertDialogConfig,
                source,
                title: localisable.unlockUser,
                buttonText: localisable.unlock.toUpperCase(),
                alertMessage: `${localisable.anEmailWillBeSentTo} ${userName} ${localisable.forUnlock}`,
            };
        } else if (source === USER_ACTION_ITEM.FORCE_PASSWORD_RESET) {
            alertDialogConfig = {
                ...alertDialogConfig,
                source,
                title: localisable.forcePasswordReset,
                buttonText: localisable.resetPassword.toUpperCase(),
                alertMessage: localisable.forcePasswordResetConfirmation,
            };
        }
        this.alertDialogConfig = alertDialogConfig;
        this.setState({ shouldShowAlert: true });
    }

    onAlertClose = () => {
        const { state: { discard } = {}, formProps: { dirty } = {}, defaultAlertDialogConfig } = this;
        if (discard && dirty) {
            this.setState({ discard: false });
            this.targetLocation = '';
        } else {
            this.alertDialogConfig = defaultAlertDialogConfig;
            this.setState({ shouldShowAlert: false });
        }
    }

    renderAlertDialog = () => {
        const {
            formProps: { dirty = false }, onAlertClose,
            state: {
                shouldShowAlert,
                discard = false,
            },
            props: { match: { params: { userId } = {} } = {} } = {},
            alertDialogConfig: { title, source: actionItemSource, buttonColor, buttonText, alertMessage } = {},
        } = this;
        return (
            <AlertDialog
                open={shouldShowAlert || (discard && dirty)}
                onClose={onAlertClose}
                title={title}
                actions={(
                    <>
                        <Button
                            onClick={onAlertClose}
                            variant="text"
                        >
                            {localisable.cancelLabel}
                        </Button>
                        <Button
                            onClick={() => this.onAlertYesAction(userId, actionItemSource)}
                            variant="text"
                            color={buttonColor}
                        >
                            {buttonText}
                        </Button>
                    </>
                )}
            >
                <Typography>{alertMessage}</Typography>
            </AlertDialog>
        );
    }

    renderLoader = () => {
        const { classes } = this.props;
        return (
            <Grid container justify="center" alignItems="center" className={classes.loader}>
                <Loader disableShrink />
            </Grid>
        );
    }

    renderComponent() {
        const {
            props: { classes, showBreadCrumb, breadCrumbList } = {},
            state: {
                loading,
                userInfo,
                initialFormStatus,
                userInfo: { firstName, lastName } = {},
            } = {},
        } = this;
        return (
            userInfo
                ? (
                    <>
                        <Formik
                            onSubmit={this.isModeCreate()
                                ? this.saveNewUser
                                : this.saveEditedUser
                            }
                            initialValues={userInfo}
                            initialStatus={initialFormStatus}
                            enableReinitialize
                            render={(formProps) => {
                                this.formProps = formProps;
                                return (
                                    <Form className={classes.form}>
                                        <Page
                                            fullHeight
                                            header={this.renderHeader()}
                                            footer={this.getUserFooter()}
                                            bodyClassName={classes.pageBody}
                                            title={this.isModeCreate() ? localisable.createUser
                                                : getName({ firstName, lastName })}
                                            loading={loading}
                                            breadCrumbList={breadCrumbList}
                                            showBreadCrumb={showBreadCrumb}
                                        >
                                            {this.getComponents()}
                                        </Page>
                                    </Form>
                                );
                            }}
                        />
                        {this.renderAlertDialog()}
                    </>
                )
                : this.renderLoader()

        );
    }
}

UserForm.propTypes = {
    mode: PropTypes.oneOf(['edit', 'create']),
    onAction: PropTypes.func,
    fetchLazy: PropTypes.func,
};

UserForm.defaultProps = {
    mode: 'create',
    onAction: () => { },
    fetchLazy: () => { },
};

export default withStyles(userFormStyle)(UserForm);
