import { useEffect, useRef } from 'react';

/**
 * @description useDidMountEffect Hook which simulates componentDidUpdate. It does not get called on initial render.
 * @param {function} func Function to execute
 * @param {array} deps List of dependencies
 */
const useDidUpdateEffect = (func, deps) => {
    const isInitialRender = useRef(true);

    useEffect(() => {
        // If initial render, do not re-initialize state
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        // Execute following only when config change, but not on component mount
        func();
    }, deps);
};

export default useDidUpdateEffect;
