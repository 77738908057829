/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */

import { Navigate, createBrowserRouter } from 'Commons/components/generic/componentlibrary/components/Components';
import GeneralPages from 'Generic/generalPages/components/GeneralPages';
import ProtectedRoute from 'Commons/components/generic/protectedroute/component/ProtectedRoute';
import useRouter from 'Commons/components/business/provider/useRouter';

const MAX_URL_LENGTH = 1024;

const getRegexForRouteParams = (regex) => {
    let regexToUpdate = regex;
    regexToUpdate += '((?:[^\\/]+?))';
    return regexToUpdate;
};


/**
 * @description The matchPath() method checks whether a pathname belongs to a path or not
 * @param {String} pathname similar to route pathname
 * @param {Object} '' object containing raw path
 */
const matchPath = (pathname = '', { path = '' }) => {
    const subPaths = path.split('/') || [];
    let regex = path ? '^' : '';
    subPaths.forEach((subPath) => {
        let suffix = '';
        if (subPath.charAt(subPath.length - 1) === '?') {
            regex += '(?:\\/';
            suffix += ')?';
        } else if (subPath) {
            regex += '\\/';
        }
        if (subPath.charAt(0) === ':') {
            regex = getRegexForRouteParams(regex);
        } else if (subPath) { regex += subPath; }
        regex += suffix;
    });
    regex += '(?:\\/(?=$))?|$/i';
    regex = RegExp(regex);
    return regex.exec(pathname);
};

/**
 * @description The getMinIndexOfCharsFromString() method returns the min index of chars in a given string,
 * returns undefined if chars are not present in the string.
 * @param {String} stringToSearchIn  string to find min index in
 * @param {Array} charsToFind characters whose min index we need to find
 */
const getMinIndexOfCharsFromString = (stringToSearchIn = '', charsToFind = []) => {
    let minIndex = MAX_URL_LENGTH + 1;
    charsToFind.forEach((char) => {
        const indexOfCharInString = stringToSearchIn.indexOf(char);
        if (indexOfCharInString >= 0 && indexOfCharInString < minIndex) {
            minIndex = indexOfCharInString;
        }
    });
    return minIndex === (MAX_URL_LENGTH + 1) ? undefined : minIndex;
};

/**
 * @description The getRouteParams() method returns the route params as an object..
 * @param {String} pathname  similar to pathname of route location (it has param values)
 * @param {String} path raw path, similar to path of route match (it has param keys)
 */
const getRouteParams = (pathname, path) => {
    const routeParams = {};
    if (pathname && path) {
        const splittedPaths = path.split(':');
        const textBeforeParam = splittedPaths.shift(); // Since everything before first colon is useless
        pathname = pathname.replace(textBeforeParam, ''); // Removing everything before first param from pathname
        while (splittedPaths.length) {
            const pathToProcess = splittedPaths.shift();

            // Before ?, / or end of string will be the key of route param
            const paramEndIndexInPath = getMinIndexOfCharsFromString(pathToProcess, ['?', '/']) || pathToProcess.length;
            // Before ? or end of string will be the value of route param
            const paramEndIndexInPathname = getMinIndexOfCharsFromString(pathname, ['/']) || pathname.length;

            const routeKey = pathToProcess.slice(0, paramEndIndexInPath);
            const routeValue = pathname.slice(0, paramEndIndexInPathname);
            routeParams[routeKey] = routeValue;

            const remainingWasteInPath = pathToProcess.slice(paramEndIndexInPath, pathToProcess.length - 1);
            pathname = pathname.replace(`${routeValue}${remainingWasteInPath}`, '');

            // Since route value can be optional and it may or may not have a / at the start after trimming
            if (pathname.startsWith('/')) {
                pathname = pathname.slice(1);
            }
        }
    }
    return routeParams;
};

const isBaseUrlAlpha = () => window.location.origin === process.env.ALPHA_FE_URL;

const HomePageWithLocation = ({ Component, ...props }) => {
    const { location, match, history } = useRouter();
    return <Component {...props} location={location} match={match} history={history} />;
};

HomePageWithLocation.propTypes = { Component: PropTypes.any };

const getRouter = (isUserLoggedIn, newProps, redirectTo, HomePage, setUserLoginState) => createBrowserRouter([
    {
        path: '/',
        element: !isUserLoggedIn
            ? (
                <HomePageWithLocation
                    {...newProps}
                    setUserLoginState={setUserLoginState}
                    userLoggedIn={isUserLoggedIn}
                    Component={HomePage}
                />
            )
            : <Navigate to={redirectTo} />,
    },
    {
        path: '/blog',
        element: <GeneralPages />,
    },
    {
        path: '/contactus',
        element: <GeneralPages />,
    },
    {
        path: '/faq',
        element: <GeneralPages />,
    },
    {
        path: '/logout',
        element: (
            <ProtectedRoute
                path="/logout"
                key="/logout"
                componentName="Logout"
                componentProps={newProps}
                loggedIn={isUserLoggedIn}
                redirectPathName="/"
            />
        ),
    },
    {
        path: '/a/facility/:fid?/*',
        element: (
            <ProtectedRoute
                path="/a/facility/:fid?"
                key="/a/facility/:fid?"
                componentName="Dashboard"
                componentProps={
                    {
                        setUserLoginState,
                        ...newProps,
                    }
                }
                loggedIn={isUserLoggedIn}
            />
        ),
    },
    {
        path: '/l/:accountId?/facility/:fid?/*',
        element: (
            <ProtectedRoute
                path="/l/:accountId?/facility/:fid?"
                key="/l/:accountId?/facility/:fid?"
                componentName="Dashboard"
                componentProps={
                    {
                        setUserLoginState,
                        ...newProps,
                    }
                }
                loggedIn={isUserLoggedIn}
            />
        ),
    },
    {
        path: '/p/:sandboxId?/:accountId?/facility/:fid?/*',
        element: (
            <ProtectedRoute
                path="/p/:sandboxId?/:accountId?/facility/:fid?"
                key="/p/:sandboxId?/:accountId?/facility/:fid?"
                componentName="Dashboard"
                componentProps={
                    {
                        setUserLoginState,
                        ...newProps,
                    }
                }
                loggedIn={isUserLoggedIn}
            />
        ),
    },
    {
        path: '/a/s/*',
        element: (
            <ProtectedRoute
                path="/a/s"
                key="/a/s"
                componentName="Dashboard"
                componentProps={newProps}
                loggedIn={isUserLoggedIn}
            />
        ),
    },
    {
        path: '/l/s/:accountId?/*',
        element: (
            <ProtectedRoute
                path="/l/s/:accountId?"
                key="/l/s/:accountId?"
                componentName="Dashboard"
                componentProps={newProps}
                loggedIn={isUserLoggedIn}
            />
        ),
    },
    {
        path: '/p/s/:sandboxId?/:accountId?/*',
        element: (
            <ProtectedRoute
                path="/p/s/:sandboxId?/:accountId?"
                key="/p/s/:sandboxId?/:accountId?"
                componentName="Dashboard"
                componentProps={newProps}
                loggedIn={isUserLoggedIn}
            />
        ),
    },
    {
        path: '/i/*',
        element: (
            <ProtectedRoute
                path="/i"
                key="/i"
                componentName="Dashboard"
                componentProps={newProps}
                loggedIn={isUserLoggedIn}
            />
        ),
    },
    {
        path: '*',
        element: <Navigate to={redirectTo} />,
    },
]);

export { matchPath, getRouteParams, isBaseUrlAlpha, getRouter };
