const FACILITY_TYPE = [
    {
        label: 'Anniversary',
        value: 'true',
    },
    {
        label: 'Non-Anniversary',
        value: 'false',
    },
];


export { FACILITY_TYPE };
