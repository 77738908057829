import BaseForm from 'Commons/components/business/baseform/components/BaseForm';
import { Form, Formik } from 'formik';
import { Grid, Loader, withStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import {
    CURRENCY_MAP,
    DASHBOARD_TYPE,
    EMPTY_FUNC,
    SLIDE_DIRECTION,
    SOURCE,
    STATUS,
    TOKEN_TYPE,
} from 'Commons/config/constants/Constants';
import { getCurrentDate } from 'Commons/helpers/utils/DateTime';
import { clsx } from 'Commons/helpers/utils/clsx';
import Modal from 'Commons/components/generic/modal/components/Modal';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import Button from 'Commons/components/generic/button/components/Button';
import Transition from 'Generic/transition/components/Transition';
import { VARIANT } from 'Generic/snackbar/config/Constants';
import WSUpdatePropsContext from 'Commons/contexts/WSUpdatePropsContext';
import { RESOURCE_NAME_TO_CONFIG_MAPPING } from 'External/containers/configuration/config/Constants';
import { ROUTE } from 'External/redux/config/RouteNames';
import { UnpaidBills } from 'External/containers/viewtenant/components/crmcomponents/UnpaidBills';
import Icon from 'Generic/icon/components/Icon';
import Link from 'Generic/link/components/Link';
import { CARD_PROCESSOR_SHOW_ON } from 'External/containers/facilityConfiguration/config/Constants';
import Typography from 'Generic/typography/components/Typography';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import { round } from 'Commons/helpers/utils';
import { convertToNumber, isLiveData, isPrintDeliveryMethod } from 'Commons/helpers/utils/Utils';
import { DELIVERY_METHOD } from 'Commons/components/generic/deliveryMethodDropdown/config/Constants';
import { WALK_IN_CUSTOMER_ID } from 'External/containers/pointOfSale/config/Constants';
import { getValue, isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import Login from 'Public/containers/login/widgets/Login';
import { deleteCookie, getCookie } from 'Commons/redux/helper/CookieManager';
import COOKIE_KEY from 'Commons/redux/config/CookieKey';
import DocumentFetcher from 'Commons/components/generic/deliveryMethodDropdown/components/DocumentFetcher';
import HostedFormContext from 'Commons/contexts/HostedFormContext';
import CONFIG_TYPE from 'Commons/../external/containers/configuration/config/ConfigRequestType';
import STORE_KEY from 'Commons/redux/config/StoreKey';
import { mergeChildConfigWithParent } from 'External/redux/postProcessors/configSDPostProcessor';
import { getFooterProps } from './utils/Footer';
import BillAhead from './components/BillAhead';
import PaymentStyle from './styles/PaymentStyle';
import TenantInformation from './components/TenantInformation';
import PaymentType from './components/PaymentType';
import PaymentInformation from './components/PaymentInformation';
import {
    authenticateCardProcessorIntegrator,
    fetchPaymentMethod,
    fetchTenant,
    pay,
    updatedPromoPlans,
} from './config/ApiRequests';
import Page from '../page/components/Page';
import { calculateNextAmountPayable } from './utils/BalanceCalculator';
import Header from './components/Header';
import {
    FAILED_AVS_CODES,
    FAILED_CVV_CODES,
    HOSTED_FORM_ID,
    PAYMENT_ACTION,
    PAYMENT_METHOD,
} from './config/Constants';
import { achPreProcessor, creditCardPreProcessor } from './utils/SubmitPreProcessors';
import shouldModifyNegativeAmount from './utils/CheckAmount';
import { getErrorActionList, getErrorType } from './utils/ErrorAction';

class Payment extends BaseForm {
    constructor(props) {
        // TODO: Divide constructor into smaller readable functions
        super(props);
        const {
            currentFacility: { data: { currency: currencyName = '' } = {} } = {},
            financialPayment: {
                data: {
                    data: [{
                        value: {
                            documentation: { Receipt: { deliveryMethod = DELIVERY_METHOD.Print.value } = {} } = {},
                            requireCredentials = false,
                        } = {},
                    } = {}] = [],
                } = {},
            } = {},
            isRoute = false, initialValues, reFetchTenantBalance, paymentMethod,
            ledgerInfo, isExternalSubmit = false, source,
        } = props;
        const { id: ledgerId } = ledgerInfo || {}; // Since ledgerInfo can be null as well
        const prefilled = this.initializeData();
        this.currency = CURRENCY_MAP[currencyName];
        this.initialStatus = { payUsing: localisable.enterCardDetails };
        this.currentStatus = this.initialStatus;
        this.state = {
            isButtonLoading: false,
            loading: false,
            showAlert: false,
            prefilled,
            shouldGoBack: false,
            isBillAheadOpen: false,
            refreshed: false,
            paymentMethod: this.handleInheritanceForPaymentMethod(paymentMethod),
            isUnpaidBillsOpen: false,
            prePayError: false,
            isLoginRequired: requireCredentials,
            isPaymentTokenAvailable: !!getCookie(COOKIE_KEY.PAYMENT_TOKEN),
            documentation: {},
            delinquentTenantError: false,
            fetchingAmountField: null,
        };
        this.paymentRef = React.createRef();
        this.unpaidBillsContainerRef = React.createRef();
        this.reFetchTenantBalance = reFetchTenantBalance;
        if (isRoute) {
            this.unblock = this.allowNavigation(this.shouldLeave);
        }
        this.mapCardProcessorData();
        this.initialValues = {
            receiptDeliveryMethod: ledgerId === WALK_IN_CUSTOMER_ID ? DELIVERY_METHOD.Print.value : deliveryMethod,
            effectiveDate: getCurrentDate(),
            waiveSurchargeOrDiscount: false,
            extra: {
                tenantAlerts: {},
                payUsing: localisable.enterCardDetails,
            },
            ...initialValues,
        };
        this.mapPromoPlanData();
        this.ignoreWsMessage = false;
        this.isExternalSubmit = isExternalSubmit;
        this.isAmountWithoutModifierTouched = false;
        this.prevSelectedPaymentMethodId = '';
        this.tenantInformationComponentKey = Date.now();
        this.storesToNotClearSilently = [
            CONFIG_TYPE.PROMO_PLAN,
            CONFIG_TYPE.PAYMENT_METHOD,
            ...(source === SOURCE.ledger.value ? [STORE_KEY.AR] : []),
        ];
    }

    setFetchingAmountField = (fetchingField) => {
        this.setState({ fetchingAmountField: fetchingField });
    }

    handleInheritanceForPaymentMethod = (paymentMethod) => {
        const paymentMethods = getValue(paymentMethod, 'data.Payment_Method.data') || getValue(paymentMethod, 'data.data') || [];
        return { data: { data: mergeChildConfigWithParent(paymentMethods) } };
    };

    onSnackbarMount = ({ key, closeSnackbar }) => {
        this.closeSnackbar = closeSnackbar;
        this.snackbarId = key;
    }

    mapCardProcessorData = () => {
        const { cardProcessor: { data: { data = [] } = {} } = {}, isRefund } = this.props;
        const mappedCardProcessorData = {};
        data.forEach((cardProcessorDetail = {}) => {
            const { id, value: { description, showOn = [], status } = {}, value = {} } = cardProcessorDetail;
            if (((isRefund && showOn.includes(CARD_PROCESSOR_SHOW_ON.Refund.value))
                || (!isRefund && showOn.includes(CARD_PROCESSOR_SHOW_ON.Payment.value)))
                && status === STATUS.Active.value) {
                mappedCardProcessorData[id] = { ...cardProcessorDetail, label: description, value: id, data: value };
            }
        });
        this.cardProcessorData = mappedCardProcessorData;
    }

    mapPromoPlanData = () => {
        const { promoPlan: { data: { data: allPromoPlans = [] } = {} } = {} } = this.props || {};
        this.promoPlanData = allPromoPlans.reduce((acc, promoPlan = {}) => {
            const { id, value } = promoPlan;
            return { ...acc, [id]: { ...promoPlan, ...value } };
        }, {});
    }

    getFuncToOpenPaymentMethod = (funcToOpenPaymentMethod = EMPTY_FUNC) => {
        this.openPaymentMethod = funcToOpenPaymentMethod;
    }

    updateIgnoreWsMessage = (ignore) => {
        this.ignoreWsMessage = ignore;
    }

    isLedgerChanged = (wsMessage) => {
        const { resourceName, fields: { tenant, balance } = {} } = wsMessage;
        const {
            state: { isButtonLoading } = {},
            formProps: { status: { tenantInfo: { id: tenantId } = {} } = {} },
        } = this;
        if (tenantId && resourceName === SOURCE.ledger.value && tenant === tenantId && !isButtonLoading) {
            return {
                isLedgerInfoChanged: true,
                isLedgerBalanceChanged: !!(balance),
            };
        }
        return {
            isLedgerInfoChanged: false,
            isLedgerBalanceChanged: false,
        };
    };

    isPaymentMethodChanged = (wsMessage) => {
        const { resourceName } = wsMessage;
        const { state: { isButtonLoading } = {} } = this;
        if (RESOURCE_NAME_TO_CONFIG_MAPPING[ROUTE.PAYMENT_METHOD_LIST] === resourceName && !isButtonLoading) {
            return true;
        }
        return false;
    };

    isTenantChanged = (wsMessage) => {
        const { resourceName, resourceId } = wsMessage;
        const { showTenantInformation = true } = this.props;
        const {
            state: { isButtonLoading } = {},
            formProps: { status: { tenantInfo: { id: tenantId } = {} } = {} },
        } = this;
        return !!(showTenantInformation
            && tenantId
            && resourceName === SOURCE.tenant.value
            && resourceId === convertToNumber(tenantId) && !isButtonLoading);
    };

    arePromoPlansUpdated = (wsMessage) => {
        const { resourceName } = wsMessage;
        return RESOURCE_NAME_TO_CONFIG_MAPPING[ROUTE.PROMO_PLAN_LIST] === resourceName;
    }

    getWsMessageDetails = (wsMessage) => {
        const { isLedgerChanged, isPaymentMethodChanged, isTenantChanged, arePromoPlansUpdated } = this;
        const { isLedgerInfoChanged, isLedgerBalanceChanged } = isLedgerChanged(wsMessage);
        if (isLedgerInfoChanged) {
            return {
                isLedgerInfoChanged,
                isLedgerBalanceChanged,
                message: isLedgerBalanceChanged
                    ? localisable.wsPaymentUpdateMessage
                    : localisable.wsTenantInfoUpdateMessage,
            };
        }
        if (isPaymentMethodChanged(wsMessage)) {
            return {
                paymentMethodChanged: true,
                message: localisable.wsPaymentMethodUpdateMessage,
            };
        }
        if (arePromoPlansUpdated(wsMessage)) {
            return {
                promoPlansChanged: true,
                message: localisable.wsPromoPlanUpdateMessage,
            };
        }
        if (isTenantChanged(wsMessage)) return { tenantChanged: true, message: localisable.wsTenantInfoUpdateMessage };
        return {};
    }

    onWebSocketMessage = (wsMessage = {}) => {
        const { onSnackbarMount, getWsMessageDetails, handleTenantInfoChange, ignoreWsMessage, props: showRTNS } = this;
        if (!ignoreWsMessage && showRTNS) {
            const {
                isLedgerInfoChanged, isLedgerBalanceChanged, tenantChanged,
                message = '',
            } = getWsMessageDetails(wsMessage);
            if (message) {
                this.updateSnackbarActionsFor([],
                    wsMessage, () => true,
                    tenantChanged || (isLedgerInfoChanged && !isLedgerBalanceChanged));
                this.setSnackbarProps(true, message, VARIANT.rtns.value, '', onSnackbarMount);
                this.setState({ receivedWSMessage: true }, () => {
                    this.setState({ receivedWSMessage: false });
                });
                if (tenantChanged || (isLedgerInfoChanged && !isLedgerBalanceChanged)) {
                    handleTenantInfoChange();
                }
            }
        }
    };

    handleTenantInfoChange = () => {
        const {
            formProps: { status: { tenantInfo: { id: tenantId } = {} } = {} } = {},
            props: { onAction }, onTenantFetch,
        } = this;
        fetchTenant(onAction, tenantId, onTenantFetch);
    };

    onTenantFetch = (apiError, response, actions) => {
        const {
            autoSuggestRef, closeSnackbar = EMPTY_FUNC, snackbarId,
            formProps: { status = {}, setStatus, status: { ledgerInfo: { id: ledgerId } = {} } = {} } = {},
        } = this;
        if (apiError) {
            this.handleErrors(apiError, actions);
        } else if (response) {
            const { data: [tenant] = [] } = response;
            const { ledger: ledgers = [] } = tenant;
            if (this.tenantInfo) {
                this.tenantInfo = tenant;
            }
            let ledgerListWithLabelValue = ledgers.map((ledger) => {
                const { id } = ledger;
                return {
                    ...ledger,
                    value: id,
                };
            });
            if (this.ledgerInfo || this.ledgerId) {
                ledgerListWithLabelValue = ledgerListWithLabelValue.filter(({ id }) => ledgerId === id);
                [this.ledgerInfo] = ledgerListWithLabelValue;
            }
            const updatedStatus = {
                ...status,
                tenantInfo: {
                    ...tenant,
                    ledger: ledgerListWithLabelValue,
                },
                ledgerInfo: ledgerListWithLabelValue.find(({ id }) => ledgerId === id),
            };
            setStatus(updatedStatus);
            if (autoSuggestRef) {
                autoSuggestRef.selectCard(tenant);
            }
        }
        this.setState(prevState => ({ refreshed: !prevState.refreshed }));
        closeSnackbar(snackbarId);
    };

    onRefresh = (message = {}) => {
        const {
            props: { onAction, currentFacility: { data: { id: facilityId } = {} } = {} },
            isLedgerChanged, isPaymentMethodChanged, onPaymentMethodFetch,
        } = this;
        this.positiveButtonToolTipMessage = '';
        const { isLedgerBalanceChanged } = isLedgerChanged(message);
        if (isLedgerBalanceChanged) {
            this.handleTenantInfoChange();
        }
        if (isPaymentMethodChanged(message)) {
            fetchPaymentMethod(onAction, facilityId, onPaymentMethodFetch);
        }
    };

    onPaymentMethodFetch = (apiError, response, actions) => {
        if (apiError) {
            this.handleErrors(apiError, actions);
        } else if (response) {
            this.setState({ paymentMethod: this.handleInheritanceForPaymentMethod(response) });
        }
    };

    initializeData = () => {
        const { isRoute } = this.props;
        let isPrefilled;
        let tenantOrUnitDetail;
        if (isRoute) {
            const { location: { state = {} } = {} } = this.props;
            const {
                paymentProps: {
                    tenantId, tenantInfo, ledgerInfo,
                    ledgerId, prefilled = false,
                } = {},
                detail,
            } = state || {};
            Object.assign(this, {
                tenantInfo,
                ledgerInfo,
                ledgerId,
                tenantId,
            });
            isPrefilled = prefilled;
            tenantOrUnitDetail = detail;
        } else {
            const { tenantInfo, ledgerInfo, ledgerId, tenantId, prefilled = false, detail } = this.props;
            Object.assign(this, {
                tenantInfo,
                ledgerInfo,
                ledgerId,
                tenantId,
            });
            isPrefilled = prefilled;
            tenantOrUnitDetail = detail;
        }
        if (tenantOrUnitDetail) {
            const { id, ledger } = tenantOrUnitDetail;
            if (Array.isArray(ledger)) {
                this.tenantId = id;
            } else {
                const { id: ledgerId } = ledger || {};
                this.ledgerId = ledgerId;
            }
            isPrefilled = true;
        }
        return isPrefilled;
    };

    shouldLeave = () => {
        const { formProps: { dirty } = {} } = this;
        const { prefilled } = this.state;
        if (dirty && !prefilled) {
            this.toggleAlertState({ discard: true });
            return false;
        }
        return true;
    };

    onExternalSubmit = () => {
        this.isExternalSubmit = true;
        const { setStatus, status: { errors, values, ...status } = {} } = this.formProps;
        setStatus(status);
        this.submitForm();
    }

    componentDidMount() {
        const { getSubmit } = this.props;
        if (getSubmit) {
            getSubmit(this.onExternalSubmit);
        }
        const updateWsProps = this.context;
        updateWsProps({ messageCallback: this.handleWsMessage });
    }

    componentWillUnmount() {
        const { unblock = () => { }, props: { clearStoreKeys } = {} } = this;
        const updateWsProps = this.context;
        clearStoreKeys(['unpaidAr']);
        deleteCookie(COOKIE_KEY.PAYMENT_TOKEN);
        updateWsProps({ shouldClearCallbacks: false });
        unblock();
    }

    componentDidUpdate(prevProps) {
        const { refundAmount: prevRefundAmount } = prevProps;
        const {
            props: {
                source,
                refundAmount,
                ledgerInfo: { id: ledgerId } = {},
            } = {},
            formProps: {
                setFieldValue,
                status: { selectedPaymentMethod: { id: paymentMethodId } = {} } = {},
            },
        } = this;


        if (source === SOURCE.retailSaleReturns.value && refundAmount > 0
            && (prevRefundAmount !== refundAmount
                || this.prevSelectedPaymentMethodId !== paymentMethodId
                || this.prevLedgerId !== ledgerId)) {
            this.prevLedgerId = ledgerId;
            this.prevSelectedPaymentMethodId = paymentMethodId;
            setFieldValue('extra.amountWithoutModifier', round(refundAmount, 2, true));
        }
        if (refundAmount === 0 && prevRefundAmount > 0) {
            setFieldValue('amount', round(0, 2, true));
        }
    }

    setPrefilledFalse = () => {
        this.setState({ prefilled: false });
    };

    toggleBillAhead = () => {
        this.setState(prevState => ({
            isBillAheadOpen: !prevState.isBillAheadOpen,
            ...(prevState.isUnpaidBillsOpen && { isUnpaidBillsOpen: false }),
        }));
    };

    toggleUnpaidBills = () => {
        const { onShowUnpaidBills } = this.props;
        if (onShowUnpaidBills) {
            onShowUnpaidBills();
        } else {
            this.setState(prevState => ({
                isUnpaidBillsOpen: !prevState.isUnpaidBillsOpen,
                ...(prevState.isBillAheadOpen && { isBillAheadOpen: false }),
            }));
        }
    };

    closeBillAheadOrUnpaidBillsModal = () => {
        this.setState(prevState => ({
            ...(prevState.isUnpaidBillsOpen && { isUnpaidBillsOpen: false }),
            ...(prevState.isBillAheadOpen && { isBillAheadOpen: false }),
        }));
    }

    toggleLoadingState = () => {
        this.setState(prevState => ({ loading: !prevState.loading }));
    };

    toggleAlertState = (extraStates) => {
        this.setState(prevState => ({ showAlert: !prevState.showAlert, ...extraStates }));
    };

    getPaymentDetails = async (responseCallback, actionType, isRefund, hostedFormData = {}) => {
        const {
            props: { currentFacility: { data: { publicKey } = {} } = {} } = {}, cardProcessorData,
            formProps: {
                values, values: { extra: { payUsing } = {} } = {},
                status: { modifierValue, amountWithoutModifier, selectedPaymentMethod: { value: { type: selectedPaymentMethod } = {} } = {} } = {},
            } = {},
        } = this;
        let paymentDetails = {};

        if (selectedPaymentMethod === PAYMENT_METHOD.CREDIT_CARD
            || selectedPaymentMethod === PAYMENT_METHOD.DEBIT_CARD) {
            paymentDetails = await creditCardPreProcessor(values, payUsing, publicKey, cardProcessorData,
                isRefund, hostedFormData);
        } else if (selectedPaymentMethod === PAYMENT_METHOD.ACH) {
            paymentDetails = await achPreProcessor(values, payUsing, publicKey);
        } else {
            const { extra, achInfo, cardInfo, useCardOnFile, ...restPaymentDetails } = values;
            paymentDetails = restPaymentDetails;
        }

        if (actionType) {
            paymentDetails.referenceNumber = this.referenceNumber;
            paymentDetails.action = actionType;
            this.referenceNumber = '';
            if (actionType === PAYMENT_ACTION.CANCEL_TRANSACTION) {
                this.setSnackbarProps(true, localisable.paymentIsCancelled);
                // eslint-disable-next-line no-param-reassign
                responseCallback = () => { };
            }
            this.setState({ timeOutMessage: '' });
        }
        paymentDetails.surchargeOrDiscount = modifierValue;
        paymentDetails.amountWithoutModifier = amountWithoutModifier;
        return paymentDetails;
    }

    isValidPayment = () => {
        const {
            props: {
                source,
                isRefund,
                financialPayment: {
                    data: {
                        data: [{
                            value:
                            {
                                paymentValidation: { isEnabled: validatePayment, validatePaymentsOver } = {},
                                prepayment: { allowPrepay: { isEnabled: prePayIsEnabled = false } = {} } = {},
                            } = {},
                        } = {}] = [],
                    } = {},
                } = {},
            } = {},
            state: { showPaymentOverAlert } = {},
            formProps: {
                values: { amount, extra: { ledgerBalance = null } = {} } = {},
                status: { amountWithoutModifier = 0, posCartTotal = 0, ledgerInfo: { balance: statusLedgerBalance = 0 } = {} } = {},
            } = {},
        } = this;
        const balance = ledgerBalance || statusLedgerBalance;
        if ((!isRefund && !prePayIsEnabled)
            && (source === SOURCE.retailSale.value ? round(amountWithoutModifier - posCartTotal) > balance : amountWithoutModifier > balance)) {
            this.setState({ prePayError: true });
            this.toggleAlertState();
            return false;
        }
        if (validatePayment && Number(amount) && Number(amount) > validatePaymentsOver
            && !showPaymentOverAlert) {
            this.setState({ showPaymentOverAlert: true });
            return false;
        }
        return true;
    }

    onAuthenticationResponse = (apiError, response) => {
        const { formProps: { setTouched, setErrors, setStatus } = {} } = this;
        const { success, data: { errorMessage: error } = {} } = response || {};
        if (apiError || !success) {
            let errorMessage = error || localisable.somethingWentWrong;
            if (apiError) errorMessage = this.handleErrors(apiError, { setTouched, setErrors, setStatus }, false);
            this.errorDetails = { errorMessage };
            this.setState({ isButtonLoading: false, showAlert: true });
        } else if (response) {
            const { data: { authenticationKey } = {} } = response;
            this.setState({ authenticationKey }); // Not stopping button loading until hosted form is up.
        }
    }

    onSubmit = async (_, formActions = {}, actionType, hostedFormData = {}) => {
        const {
            props: { onAction, isRefund, externalSubmit, currentPrinter: { data: { id: printerId } = {} } = {} } = {}, getPaymentDetails, isValidPayment,
            onAuthenticationResponse, isSubmitDisabled, formProps: { setFieldValue, setErrors, setTouched },
            state: { isPaymentTokenAvailable = false } = {},
        } = this;

        let actions = formActions;

        if (!isObjWithKeys(actions)) actions = { setTouched, setErrors, setFieldValue };

        if (isSubmitDisabled) this.isSubmitDisabled = false;
        else if (isObjWithKeys(hostedFormData) || isValidPayment()) {
            const tokenType = isPaymentTokenAvailable ? TOKEN_TYPE.PAYMENT : TOKEN_TYPE.STANDARD;
            let stateToUpdate = { showPaymentOverAlert: false, authenticationKey: '' };
            if (actionType !== PAYMENT_ACTION.CANCEL_TRANSACTION) {
                stateToUpdate = { ...stateToUpdate, isButtonLoading: true };
            }
            this.setState(stateToUpdate);
            const responseCallback = (error, response) => this.onPaymentResponse(error, response, actions);

            const apiBody = await getPaymentDetails(responseCallback, actionType, isRefund, hostedFormData);
            const { authType, amount: amountToPay, receiptDeliveryMethod } = apiBody;
            if (amountToPay) apiBody.amount = amountToPay.toString();

            if (isPrintDeliveryMethod(receiptDeliveryMethod)) {
                apiBody.printerId = printerId;
            }

            if (authType) {
                delete apiBody.surchargeOrDiscount;
                delete apiBody.amountWithoutModifier;
                authenticateCardProcessorIntegrator(onAction, apiBody, onAuthenticationResponse);
            } else if (this.isExternalSubmit && externalSubmit) {
                externalSubmit(apiBody, actions, responseCallback);
            } else pay(onAction, apiBody, responseCallback, isRefund, tokenType);
        }
    };

    onPaymentResponse = (apiError, response, actions) => {
        const { isRefund } = this.props;
        if (apiError) {
            const errorMessage = this.handleErrors(apiError, actions, false) || '';
            this.errorDetails = { errorMessage };
            this.setState({
                isButtonLoading: false,
                showAlert: true,
                ...errorMessage === localisable.prePayNotAllowed && { prePayError: true },
                ...errorMessage === localisable.partialPaymentNotAllowedForDelinquentTenant && { delinquentTenantError: true },
            });
        } else if (response) {
            const {
                data: {
                    message = '', ledgerBalance: updatedBalance = 0, referenceNumber, cvvMessage = '',
                    card: { cvvResponseCode = '', avsResponseCode = '' } = {}, avsMessage = '',
                    expressResponseCode = '',
                } = {},
                success,
                relationalData: { documentation = {} } = {},
            } = response;
            if (!success) {
                this.errorDetails = { errorMessage: message, responseCode: expressResponseCode };
                const updatedState = { isButtonLoading: false, showAlert: true };
                if (message.includes(localisable.takingMoreTime)) {
                    this.referenceNumber = referenceNumber;
                    updatedState.timeOutMessage = message;
                } else if (FAILED_CVV_CODES.includes(cvvResponseCode)) {
                    this.errorDetails = { errorMessage: cvvMessage, responseCode: cvvResponseCode };
                } else if (FAILED_AVS_CODES.includes(avsResponseCode)) {
                    this.errorDetails = { errorMessage: avsMessage, responseCode: avsResponseCode };
                }
                this.setState(updatedState);
            } else {
                this.setSnackbarProps(true, isRefund ? localisable.successRefund : localisable.successPayment);
                this.onSuccessfulPayment(updatedBalance, documentation);
            }
        }
    };

    getUpdatedBalanceAfterPayment = (updatedBalance) => {
        const { props: { isRefund } = {} } = this;
        if (isRefund) {
            return updatedBalance < 0 ? -updatedBalance : 0;
        }
        return updatedBalance > 0 ? updatedBalance : 0;
    }

    updateForm = (updatedBalance) => {
        const {
            formProps: {
                values: { extra: { unitList = [] } = {} },
                status: { ledgerInfo = {}, ledgerInfo: { id = '', balance: statusLedgerBalance = 0 } = {} } = {},
                status, setStatus, setFieldValue,
                initialValues: {
                    cardInfo: initialCardInfo, achInfo: initialAchInfo,
                    paymentNumber: initialPaymentNumber,
                } = {},
            },
            props: { source, isRefund, setFetchedBalance = EMPTY_FUNC } = {},
            resetIsAmountWithoutModifierTouched, getUpdatedBalanceAfterPayment,
        } = this;

        const amount = getUpdatedBalanceAfterPayment(updatedBalance);

        ledgerInfo.balance = updatedBalance;
        resetIsAmountWithoutModifierTouched();
        this.currentStatus = this.initialStatus;
        setStatus({ ...status, ledgerInfo });
        if (!shouldModifyNegativeAmount(isRefund, unitList, setFieldValue, id, source, updatedBalance || statusLedgerBalance)) {
            setFieldValue('extra.amountWithoutModifier', round(amount, 2, true));
        }
        setFetchedBalance(updatedBalance);
        setFieldValue('extra.ledgerBalance', convertToNumber(amount));
        setFieldValue('comments', undefined);
        setFieldValue('cardInfo', initialCardInfo);
        setFieldValue('achInfo', initialAchInfo);
        setFieldValue('paymentNumber', initialPaymentNumber);
    }

    clearStoreData = () => {
        const { props: { clearStoreKeys, clearArStoreKey } = {} } = this;
        const storeKeysToClear = [SOURCE.tenant.value, ...clearArStoreKey ? []
            : [SOURCE.ar.value, SOURCE.unpaidAr.value]];
        if (clearArStoreKey) {
            clearArStoreKey(true);
        }
        clearStoreKeys(storeKeysToClear);
    }

    onDocumentFetch = () => {
        const {
            formProps: { resetForm }, initialValues,
            clearStoreData, props: { onPaymentSuccess, onClose = EMPTY_FUNC },
        } = this;
        clearStoreData();
        onClose();
        resetForm(initialValues);
        onPaymentSuccess();
    }

    onSuccessfulPayment = (updatedBalance, documentation) => {
        const {
            formProps: { resetForm },
            props: { onPaymentSuccess, onClose = EMPTY_FUNC, prefilled = false } = {},
            initialValues, updateForm, clearStoreData,
        } = this;
        const stateToUpdate = { isButtonLoading: false };

        updateForm(updatedBalance);

        const documentsWithPreview = {};
        Object.keys(documentation).forEach((document) => {
            const { deliveryMethod } = documentation[document];
            if (deliveryMethod === 'Preview') {
                documentsWithPreview[document] = documentation[document];
            }
        });

        if (Object.keys(documentsWithPreview).length) {
            stateToUpdate.documentation = documentation;
        } else {
            onClose();
            clearStoreData();
            resetForm(initialValues);
            onPaymentSuccess();
        }
        this.setState({ prefilled, ...stateToUpdate });
    };

    getIsAmountWithoutModifierTouched = (isAmountWithoutModifierTouched) => {
        this.isAmountWithoutModifierTouched = isAmountWithoutModifierTouched;
    };

    getResetIsAmountWithoutModifierTouched = (resetIsAmountWithoutModifierTouchedFunc) => {
        this.resetIsAmountWithoutModifierTouched = resetIsAmountWithoutModifierTouchedFunc;
    };

    onUpdateLedgerResponse = (apiError, response, actions) => {
        this.setState({ isButtonLoading: false });
        if (apiError) {
            this.handleErrors(apiError, actions);
        } else if (response) {
            // TODO: Update Success response logic
        }
        this.toggleLoadingState();
    };

    getBillAhead = (ledgerId) => {
        const {
            props: { onAction, clearStoreKeys }, onLedgerBalance, currency,
            setReFetchTenantBalance, setPaymentSnackbarProps,
            formProps: { status: { tenantInfo: { unitList = [] } = {} } = {} },
        } = this;
        return (
            <BillAhead
                paymentRef={this.paymentRef}
                onClose={this.toggleBillAhead}
                ledgerId={ledgerId}
                onAction={onAction}
                onLedgerBalance={onLedgerBalance}
                setReFetchTenantBalance={setReFetchTenantBalance}
                setPaymentSnackbarProps={setPaymentSnackbarProps}
                currency={currency}
                clearStoreKeys={clearStoreKeys}
                unitList={unitList}
            />
        );
    };

    getUnpaidBillsList = (ledgerId, { label, balance } = {}) => {
        const {
            classes, currentFacility: { data: { id: fid } = {} } = {},
            currentAccountId: { data: { id: accountId } = {} } = {},
        } = this.props;
        const { transitionComplete } = this.state;
        const { deviceInfo: { isDesktop } = {} } = window;
        const selectedLedger = {
            id: ledgerId,
            label,
            balance,
        };
        return (
            <>
                <Icon
                    type="custom"
                    icon="cp-close"
                    color="primary"
                    onClick={() => this.toggleUnpaidBills()}
                    className={classes.unpaidBillsCloseButton}
                />
                {(transitionComplete || !isDesktop) && (
                    <Grid container className={classes.unpaidBillsListContainer}>
                        <UnpaidBills
                            containerRef={this.unpaidBillsContainerRef}
                            currency={this.currency}
                            selectedLedger={selectedLedger}
                            source={SOURCE.payment.value}
                            key={`${ledgerId} - ${balance}`}
                        />
                    </Grid>
                )}
                <Grid container justify="flex-end" className={classes.clickHereForMoreButtonContainer}>
                    <Link
                        to={buildUrl('ledgerReview', { fid, id: ledgerId }, DASHBOARD_TYPE.EXTERNAL, accountId)}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.clickHereForMoreButton}
                        >
                            {localisable.clickHereForMoreDetails}
                        </Button>
                    </Link>
                </Grid>
            </>
        );
    };

    renderBillAheadOrUnpaidBills = () => {
        const { isBillAheadOpen, isUnpaidBillsOpen } = this.state;
        const { classes } = this.props;
        const {
            status: { ledgerInfo: { label = '', balance } = {} } = {},
            values: { ledgerId: selectedLedgerId } = {},
        } = this.formProps;
        return (
            (isBillAheadOpen || isUnpaidBillsOpen)
                ? (
                    <Grid className={clsx(classes.sideContainer, isBillAheadOpen
                        ? classes.billAheadContainer : classes.unpaidBillsContainer)}
                    >
                        {
                            isBillAheadOpen ? this.getBillAhead(selectedLedgerId)
                                : this.getUnpaidBillsList(selectedLedgerId, { label, balance })
                        }
                    </Grid>
                ) : null
        );
    };

    onClosePayment = () => {
        const { onClose = EMPTY_FUNC, prefilled: prefilledProp = false } = this.props;
        const { resetForm } = this.formProps;
        const { initialValues } = this;
        onClose();
        if (resetForm) {
            resetForm(initialValues);
        }
        this.setState({
            prefilled: prefilledProp,
            isBillAheadOpen: false,
            isUnpaidBillsOpen: false,
        });
    };

    getOnLedgerBalance = (onLedgerBalance) => {
        this.onLedgerBalance = onLedgerBalance;
    };

    getAutoSuggestRef = (autoSuggestRef) => {
        this.autoSuggestRef = autoSuggestRef;
    };

    setReFetchTenantBalance = (value) => {
        this.reFetchTenantBalance = value;
    };

    setPaymentSnackbarProps = (isSnackbarOpen, title, variant) => {
        this.setSnackbarProps(isSnackbarOpen, title, variant);
    };

    getPaymentFooter = () => {
        const { positiveButtonProps, negativeButtonProps, FooterProps } = getFooterProps(this);
        return this.getFooter({
            positiveButtonProps,
            negativeButtonProps,
            ...FooterProps,
        });
    };

    onPaymentAlertYesAction = () => {
        const { onClose = EMPTY_FUNC } = this.props;
        this.toggleAlertState({ isBillAheadOpen: false });
        if (onClose) {
            this.onClosePayment();
        } else {
            this.onAlertYesAction();
        }
    };

    getErrorMessage = () => {
        const {
            errorDetails: { errorMessage, responseCode } = {}, props: { classes, isRefund } = {},
            state: { prePayError },
        } = this;
        return (
            <Grid container direction="column" alignItems="center" className={classes.errorAlertContainer}>
                <Icon type="custom" icon="cp-info" size="large" className={classes.infoIcon} />
                <Typography variant="subtitle1">
                    {isRefund ? localisable.refundFailedTitle : localisable.paymentFailed}
                </Typography>
                {responseCode
                    && (
                        <Typography variant="subtitle1">
                            {localisable.rejectionCode}{': '}{responseCode}
                        </Typography>
                    )
                }
                <Typography align="center" variant="subtitle1">
                    {localisable.reason}{': '}{prePayError ? localisable.prePayNotAllowed : errorMessage}
                </Typography>
            </Grid>
        );
    }

    onExit = () => {
        const {
            onPaymentAlertYesAction,
            props: { setSellAndPayLoading = EMPTY_FUNC } = {},
        } = this;
        this.errorDetails = {};
        onPaymentAlertYesAction();
        setSellAndPayLoading(false);
    }

    onTryDifferentMethod = () => {
        const { openPaymentMethod } = this;
        this.errorDetails = {};
        openPaymentMethod();
        this.setState({ showAlert: false });
    }

    onEditAmount = () => {
        const {
            formProps: {
                values: { extra: { unitList = [], ledgerBalance = null } = {} } = {},
                setFieldValue,
                status: { posCartTotal = 0, ledgerInfo: { id = '', balance = 0 } = {} } = {},
            } = {},
            props: { setSellAndPayLoading = EMPTY_FUNC } = {},
            props: { source } = {},
        } = this;
        let ledgerBalanceTemp = ledgerBalance || balance;
        if (ledgerBalanceTemp <= 0) {
            ledgerBalanceTemp = source === SOURCE.retailSale.value ? 0 : calculateNextAmountPayable(unitList, id);
        }
        setFieldValue('extra.amountWithoutModifier', round(convertToNumber(ledgerBalanceTemp) + convertToNumber(posCartTotal), 2, true));
        this.setState({ showAlert: false, prePayError: false, delinquentTenantError: false });
        setSellAndPayLoading(false);
    }

    getAlertActions = () => {
        const {
            errorDetails: { errorMessage } = {}, onPaymentAlertYesAction,
            state: { prePayError, delinquentTenantError },
            onExit, onTryDifferentMethod, onEditAmount, onDelinquentTenantAlertClose,
            props: { classes },
        } = this;

        const errorType = getErrorType(prePayError, delinquentTenantError, errorMessage);
        const actionList = getErrorActionList(errorType, classes, {
            onExit,
            onTryDifferentMethod,
            onEditAmount,
            onDelinquentTenantAlertClose,
            onPaymentAlertYesAction,
        });

        return (
            <>
                {
                    actionList.map(({ label, onClick, otherProps = {} }) => (
                        <Button
                            onClick={onClick}
                            key={label}
                            variant="text"
                            color="primary"
                            {...otherProps}
                        >
                            {label}
                        </Button>
                    ))
                }
            </>
        );
    }

    disableFormSubmit = (shouldDisable = true) => { this.isSubmitDisabled = shouldDisable; }

    closeHostedForm = () => {
        this.setState({ isButtonLoading: false });
        this.disableFormSubmit(false);
    }

    onPaymentLoginSuccess = () => {
        const { isLoginRequired } = this.state;
        const { enableFooterButtons = EMPTY_FUNC } = this.props;
        const paymentToken = getCookie(COOKIE_KEY.PAYMENT_TOKEN);
        if (isLoginRequired) {
            if (paymentToken) {
                this.setState({ isLoginRequired: false, isPaymentTokenAvailable: true });
                enableFooterButtons();
            } else {
                this.setState({ isLoginRequired: true });
            }
        }
    }

    isUserLoggedIn = () => {
        const { isLoginRequired, isPaymentTokenAvailable } = this.state;
        return !isLoginRequired && isPaymentTokenAvailable;
    }

    renderLogin = () => {
        const {
            props: { source: _, ...props },
            props: {
                classes,
                userProfile: {
                    data: {
                        data: [
                            { email = '' },
                        ],
                        accountNumber: currentAccountNumber = '',
                    },
                }, shouldFocusPayment = true, isRefund,
            },
            state: { isLoginRequired, isPaymentTokenAvailable }, onPaymentLoginSuccess,
        } = this;
        return (
            <div className={clsx(
                classes.paymentLoginContainer,
                isLoginRequired && !isPaymentTokenAvailable && classes.visibleLoginContainer,
            )}
            >
                <Login
                    onPaymentLoginSuccess={onPaymentLoginSuccess}
                    email={email}
                    currentAccountNumber={currentAccountNumber}
                    source={SOURCE.payment.value}
                    isEnabled={shouldFocusPayment}
                    label={isRefund ? localisable.refundLoginLabel : localisable.paymentLoginLabel}
                    {...props}
                    classes={{ ...({ loginContainer: classes.loginContainer }) }}
                />
            </div>
        );
    }

    submitHostedForm = (hostedFormData = {}) => {
        const { onSubmit } = this;
        onSubmit(undefined, undefined, undefined, hostedFormData);
    }

    onShouldShowAlertClose = () => {
        const { props: { setSellAndPayLoading = EMPTY_FUNC } = {}, toggleAlertState } = this;
        toggleAlertState();
        setSellAndPayLoading(false);
    }

    onDelinquentTenantAlertClose = () => {
        this.onShouldShowAlertClose();
        this.errorDetails = {};
        this.setState({ delinquentTenantError: false });
    }

    onPaymentOverAlertClose = () => {
        const { props: { setSellAndPayLoading = EMPTY_FUNC } = {} } = this;
        this.setState({ showPaymentOverAlert: false });
        setSellAndPayLoading(false);
    }

    togglePromoPlan = () => {
        this.setState(prevState => ({ isPromoPlanOpen: !prevState.isPromoPlanOpen }));
    }

    onUpdatePromoPlanResponse = (apiError, response) => {
        if (apiError) {
            this.handleErrors(apiError);
        } else if (response) {
            this.tenantInformationComponentKey = Date.now();
            this.setSnackbarProps(true, localisable.promoPlansUpdated);
        }
        this.setState({ pageLoading: false });
    }

    submitPromoPlansData = (promoPlanData) => {
        const { props: { onAction } = {}, onUpdatePromoPlanResponse } = this;
        this.setState({ pageLoading: true });
        updatedPromoPlans(onAction, promoPlanData, onUpdatePromoPlanResponse);
    }

    getCurrentStatus = () => this.currentStatus

    updateCurrentStatus = (status = {}) => {
        this.currentStatus = { ...this.currentStatus, ...status };
    }

    renderComponent() {
        const {
            props: {
                classes, onAction, permission = {}, showTenantInformation, shouldShowAlert = true,
                financialPayment: { data: { data: [{ value: payment = {} } = {}] = [] } = {} } = {},
                showFooter, PageProps: { md = false, lg = false, ...PageProps } = {}, open = true,
                getFormProps, isMaskVisible = false, showOnlyTenantName = false, pageTitle, fitPage = false,
                header, showHeader = true, source, clearStoreKeys, loaderComponent, match: { url } = {},
                ...props
            },
            state: {
                paymentMethod: { data: { data: paymentMethods = [] } = {} } = {},
                isUnpaidBillsOpen, isBillAheadOpen, showAlert, prefilled,
                isButtonLoading, timeOutMessage, showPaymentOverAlert = false,
                prePayError, authenticationKey, isPromoPlanOpen = false, pageLoading = false,
                documentation = {}, fetchingAmountField,
            }, formProps: { dirty = false }, cardProcessorData, initialStatus,
            tenantInfo, ledgerId, tenantId, ledgerInfo, toggleBillAhead, currency, onSubmit, getAlertActions,
            initialValues, onClosePayment, toggleLoadingState, errorDetails: { errorMessage } = {}, submitHostedForm,
            toggleAlertState, onShouldShowAlertClose, onPaymentOverAlertClose, reFetchTenantBalance, setPrefilledFalse,
            toggleUnpaidBills, renderBillAheadOrUnpaidBills, closeBillAheadOrUnpaidBillsModal, getErrorMessage,
            getFuncToOpenPaymentMethod, getIsAmountWithoutModifierTouched, isAmountWithoutModifierTouched,
            getResetIsAmountWithoutModifierTouched, renderLogin, isUserLoggedIn, disableFormSubmit, closeHostedForm,
            togglePromoPlan, getOnLedgerBalance, getAutoSuggestRef, promoPlanData = {}, submitPromoPlansData,
            tenantInformationComponentKey, onDocumentFetch, updateCurrentStatus, getCurrentStatus, setFetchingAmountField,
        } = this;
        let { props: { showModal = true, showLoader = true } } = this;
        const { currentFacility: { data: { id: fid } = {} } = {} } = props;
        const { deviceInfo: { isDesktop } } = window;
        if (!isDesktop) {
            showModal = false;
            showLoader = prefilled;
        }
        return (
            <>
                <DocumentFetcher
                    documentation={documentation}
                    onClose={onDocumentFetch}
                />
                <Formik
                    initialValues={initialValues}
                    initialStatus={initialStatus}
                    onSubmit={this.onSubmit}
                    render={(formProps) => {
                        this.formProps = formProps;
                        if (getFormProps) {
                            getFormProps(formProps);
                        }
                        const {
                            status: { tenantInfo: { id: selectedTenantId = '' } = {} } = {},
                            values: { ledgerId: selectedLedgerId, extra: { unitUpdateTime } = {} } = {},
                        } = formProps;
                        const paymentComponent = (
                            <Grid
                                container
                                className={clsx(isBillAheadOpen || isUnpaidBillsOpen
                                    ? fitPage && classes.fitPageBillAheadOpen
                                    : fitPage && classes.fitPage,
                                classes.fullHeight)}
                                justify="flex-end"
                                wrap="nowrap"
                            >
                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justify="center"
                                    className={classes.relativePosition}
                                    ref={this.paymentRef}
                                    item
                                    xs={(isBillAheadOpen || isUnpaidBillsOpen) && isDesktop ? 4 : 12}
                                >
                                    {
                                        isMaskVisible
                                        && <Grid container className={classes.mask} />
                                    }
                                    {
                                        showLoader && !selectedLedgerId
                                        && (
                                            <Grid
                                                container
                                                justify="center"
                                                alignItems="center"
                                                className={classes.loader}
                                            >
                                                {
                                                    loaderComponent || <Loader disableShrink />
                                                }
                                            </Grid>
                                        )
                                    }
                                    <Form className={classes.formStyle} id={HOSTED_FORM_ID}>
                                        <Page
                                            item
                                            header={showHeader && (
                                                <Header
                                                    fid={fid}
                                                    key={`${unitUpdateTime} - ${selectedLedgerId}`}
                                                    header={header}
                                                    toggleBillAhead={toggleBillAhead}
                                                    isBillAheadOpen={isBillAheadOpen}
                                                    isPromoPlanOpen={isPromoPlanOpen}
                                                    togglePromoPlan={togglePromoPlan}
                                                    tenantId={selectedTenantId}
                                                    ledgerId={selectedLedgerId}
                                                    showModal={showModal}
                                                    formProps={formProps}
                                                    promoPlanData={promoPlanData}
                                                    submitPromoPlansData={submitPromoPlansData}
                                                />
                                            )}
                                            footer={showFooter && this.getPaymentFooter()}
                                            md={md}
                                            lg={lg}
                                            loading={pageLoading}
                                            bodyClassName={classes.body}
                                            className={clsx(classes.whiteBackground,
                                                { [classes.noDisplay]: showLoader && !selectedLedgerId })}
                                            title={pageTitle || localisable.paymentLabel}
                                            headerClassName={classes.pageHeader}
                                            {...PageProps}
                                        >
                                            <Grid className={classes.fullWidth}>
                                                <TenantInformation
                                                    key={tenantInformationComponentKey}
                                                    formProps={formProps}
                                                    onAction={onAction}
                                                    onApiError={this.handleErrors}
                                                    currency={currency}
                                                    ledgerDetails={ledgerInfo}
                                                    tenantDetails={tenantInfo}
                                                    updateCurrentStatus={updateCurrentStatus}
                                                    toggleLoadingState={toggleLoadingState}
                                                    showTenantInformation={showTenantInformation}
                                                    reFetchTenantBalance={reFetchTenantBalance}
                                                    tenantId={tenantId}
                                                    ledgerId={ledgerId}
                                                    source={source}
                                                    showOnlyTenantName={showOnlyTenantName}
                                                    setPrefilledFalse={setPrefilledFalse}
                                                    getOnLedgerBalance={getOnLedgerBalance}
                                                    getAutoSuggestRef={getAutoSuggestRef}
                                                    clearStoreKeys={clearStoreKeys}
                                                    shouldAutoFocus={isUserLoggedIn()}
                                                    updateIgnoreWsMessage={this.updateIgnoreWsMessage}
                                                    {...props}
                                                />
                                                <PaymentInformation
                                                    formProps={formProps}
                                                    onAction={onAction}
                                                    payment={payment}
                                                    permission={permission}
                                                    reFetchTenantBalance={reFetchTenantBalance}
                                                    setPrefilledFalse={setPrefilledFalse}
                                                    isUnpaidBillsOpen={isUnpaidBillsOpen}
                                                    onShowUnpaidBills={toggleUnpaidBills}
                                                    source={source}
                                                    getResetIsAmountWithoutModifierTouched={
                                                        getResetIsAmountWithoutModifierTouched
                                                    }
                                                    getIsAmountWithoutModifierTouched={getIsAmountWithoutModifierTouched}
                                                    fetchingAmountField={fetchingAmountField}
                                                    setFetchingAmountField={setFetchingAmountField}
                                                    {...props}
                                                />
                                                <HostedFormContext.Provider
                                                    value={{
                                                        authenticationKey,
                                                        disableFormSubmit,
                                                        closeHostedForm,
                                                    }}
                                                >
                                                    <PaymentType
                                                        formProps={formProps}
                                                        paymentMethods={paymentMethods}
                                                        payment={payment}
                                                        getCurrentStatus={getCurrentStatus}
                                                        reFetchTenantBalance={reFetchTenantBalance}
                                                        parentSize={this.paymentRef}
                                                        setPrefilledFalse={setPrefilledFalse}
                                                        cardProcessorData={cardProcessorData}
                                                        isButtonLoading={isButtonLoading}
                                                        timeOutMessage={timeOutMessage}
                                                        onSubmit={onSubmit}
                                                        source={source}
                                                        getFuncToOpenPaymentMethod={getFuncToOpenPaymentMethod}
                                                        isAmountWithoutModifierTouched={isAmountWithoutModifierTouched}
                                                        showAlerts={isUserLoggedIn()}
                                                        onSubmitHostedForm={submitHostedForm}
                                                        {...props}
                                                    />
                                                </HostedFormContext.Provider>
                                            </Grid>
                                        </Page>
                                    </Form>
                                </Grid>
                                {isDesktop
                                    ? (
                                        <Transition
                                            in={isBillAheadOpen || isUnpaidBillsOpen}
                                            mountOnEnter
                                            unmountOnExit
                                            direction={SLIDE_DIRECTION.LEFT}
                                            onEntered={() => this.setState({ transitionComplete: true })}
                                        >
                                            <Grid item xs={8} ref={this.unpaidBillsContainerRef}>
                                                {renderBillAheadOrUnpaidBills()
                                                }
                                            </Grid>
                                        </Transition>
                                    )
                                    : (
                                        <Modal
                                            open={isBillAheadOpen || isUnpaidBillsOpen}
                                            hideCloseButton
                                            classes={{ modalGrid: classes.billAheadOrUnpaidBillsModal }}
                                            onClose={closeBillAheadOrUnpaidBillsModal}
                                        >
                                            {renderBillAheadOrUnpaidBills()}
                                        </Modal>
                                    )
                                }
                            </Grid>
                        );
                        return showModal
                            ? (
                                <Modal
                                    open={open}
                                    hideCloseButton
                                    classes={{
                                        modalGrid: isBillAheadOpen || isUnpaidBillsOpen
                                            ? classes.modalGridBillAheadOpen
                                            : classes.modalGrid,
                                        modalBody: classes.overflowHidden,
                                    }}
                                    onClose={dirty && !prefilled ? toggleAlertState : onClosePayment}
                                >
                                    {!isMaskVisible && !isLiveData(url) && renderLogin()}
                                    {paymentComponent}
                                </Modal>
                            )
                            : (
                                <div className={classes.relativePosition}>
                                    {!isMaskVisible && !isLiveData(url) && renderLogin()}
                                    {paymentComponent}
                                </div>
                            );
                    }}
                />
                <AlertDialog
                    open={shouldShowAlert && showAlert}
                    onClose={onShouldShowAlertClose}
                    hideCloseButton={!!errorMessage}
                    title={errorMessage || prePayError ? '' : localisable.warning}
                    actions={getAlertActions()}
                >
                    {
                        errorMessage || prePayError
                            ? getErrorMessage()
                            : <Typography>{localisable.discardConfirmation}</Typography>
                    }
                </AlertDialog>
                <AlertDialog
                    open={showPaymentOverAlert}
                    onClose={onPaymentOverAlertClose}
                    title={localisable.warning}
                    actions={(
                        <>
                            <Button
                                onClick={onPaymentOverAlertClose}
                                variant="text"
                                color="error"
                            >
                                {localisable.noEditAmount.toUpperCase()}
                            </Button>
                            <Button
                                onClick={onSubmit}
                                variant="text"
                            >
                                {localisable.yesPay.toUpperCase()}
                            </Button>
                        </>
                    )}
                >
                    <>
                        <Typography>{localisable.amountMoreThanConfiguredAlertMessagePart1}</Typography>
                        <Typography>{localisable.amountMoreThanConfiguredAlertMessagePart2}</Typography>
                    </>
                </AlertDialog>
            </>
        );
    }
}

Payment.propTypes = {
    classes: PropTypes.object,
    ledgerInfo: PropTypes.object,
    tenantInfo: PropTypes.object,
    showFooter: PropTypes.bool,
    PageProps: PropTypes.object,
    onPaymentSuccess: PropTypes.func,
    source: PropTypes.string,
    isRefund: PropTypes.bool,
    ignoreWsMessage: PropTypes.bool,
    loaderComponent: PropTypes.node,
    shouldFocusPayment: PropTypes.bool,
};

Payment.defaultProps = {
    ignoreWsMessage: false,
    showFooter: true,
    showRTNS: true,
    onPaymentSuccess: () => { },
};
Payment.contextType = WSUpdatePropsContext;

const PaymentComp = withStyles(PaymentStyle)(Payment);
const SmartPaymentComp = window.withProvider('Payment', {}, false);

export default PaymentComp;
export { SmartPaymentComp as Payment };
