import media from 'Commons/theme/MediaQueries';

const calendarHeaderStyle = theme => ({
    headerContainer: {
        padding: theme.spacing(0, 4, 2, 0),
        [media.laptop]: { padding: theme.spacing(0, 2, 1, 2) },
        [media.tablet]: { padding: theme.spacing(0, 2, 2, 2) },
        [media.mobile]: { padding: theme.spacing(0, 1, 1, 1) },
    },
    createButtonGrid: { cursor: 'pointer' },
    datePickerContainer: { width: 'auto' },
    dateTextFieldInputMarginTop: { marginTop: 0 },
    dateTextFieldInput: { padding: 0, textAlign: 'center', cursor: 'pointer' },
    disabledDatePickerOverride: { color: theme.palette.text.primary },
    titleSpacing: { marginRight: 'auto' },
});

export default calendarHeaderStyle;
