import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo, useMemo } from 'react';
import { DROPDOWN_MAX_HEIGHT } from 'External/containers/configuration/config/Constants';
import { getOptionProps, optionsFormatter } from 'Commons/helpers/utils/SDFormatter';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { clsx } from 'Commons/helpers/utils/clsx';
import { WAITING_STATUS, CUSTOMER_TYPE, WAITING_SOURCE } from '../config/Constants';
import WaitingListFilterStyle from '../styles/WaitingListFilterStyle';
import { getUnitTypeApiConfig } from '../config/ApiRequests';
import { RESERVATION_STATUS } from '../../reservationFilter/config/Constants';

const useStyles = makeStyles(WaitingListFilterStyle, { name: 'WaitingListFilter' });

const WaitingListFilter = ({
    entityId,
    formProps: { setFieldValue, values: { terms: { unitTypeId: unitTypeIds = [] } = {} } = {} } = {},
}) => {
    const classes = useStyles();

    const unitTypeApiConfig = useMemo(() => getUnitTypeApiConfig(entityId), []);

    const onSelectOrRemoveUnitType = (selectedUnitTypes) => {
        setFieldValue('terms.unitTypeId', Object.keys(selectedUnitTypes));
    };

    const { waitingStatus, waitingSource } = useConstructor(() => ({
        waitingStatus: [
            { ...WAITING_STATUS.Active },
            {
                label: RESERVATION_STATUS.Cancelled.label,
                value: WAITING_STATUS.Rejected.value,
            },
        ],
        waitingSource: Object.values(WAITING_SOURCE),
    }));

    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.customerType}
            </Typography>
            <FormCheckboxGroup
                name="terms.customerType"
                list={Object.values(CUSTOMER_TYPE)}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography variant="subtitle1" className={clsx(classes.firstLabel, classes.topMargin)}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={waitingStatus}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography variant="subtitle1" className={clsx(classes.firstLabel, classes.topMargin)}>
                {localisable.source}
            </Typography>
            <FormCheckboxGroup
                name="terms.source"
                list={waitingSource}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography variant="subtitle1" className={classes.restLabel}>
                {localisable.unitType}
            </Typography>
            <MultiSelect
                placeholder={localisable.selectUnitType}
                onSelect={onSelectOrRemoveUnitType}
                onRemove={onSelectOrRemoveUnitType}
                getOptionProps={getOptionProps}
                apiConfig={unitTypeApiConfig}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                autoSuggestKey="matchPhrasePrefix.value.description"
                optionFormatter={optionsFormatter}
                initiallySelectedValues={unitTypeIds}
            />
        </>
    );
};

WaitingListFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
};

WaitingListFilter.defaultProps = { entityId: '' };

export default memo(WaitingListFilter);
