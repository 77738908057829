import * as Uaparser from 'ua-parser-js';

const UAParser = new Uaparser();
const getScreenInfo = () => {
    const {
        availHeight,
        availWidth,
        colorDepth,
        height,
        orientation,
        pixelDepth,
        width,
    } = window.screen;
    return {
        availHeight,
        availWidth,
        colorDepth,
        height,
        orientation,
        pixelDepth,
        width,
    };
};

const getNavigatorInfo = () => {
    const {
        userAgent,
        connection = {},
        cookieEnabled,
        doNotTrack,
        plugins,
        maxTouchPoints,
        deviceMemory = 'N/A',
    } = window.navigator;
    return {
        userAgent,
        connection,
        cookieEnabled,
        doNotTrack,
        plugins,
        maxTouchPoints,
        deviceMemory,
    };
};

const getDeviceInfo = () => {
    const navigator = getNavigatorInfo();
    if (!navigator.doNotTrack) {
        return {
            ...getScreenInfo(),
            ...navigator,
        };
    }
    return { };
};

const getDeviceOSInfo = () => ({ ...UAParser.getResult(), device: getDeviceInfo() });

export {
    getDeviceInfo,
    getDeviceOSInfo,
};
