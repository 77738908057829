import localisable from 'Commons/config/strings/localisable';
import { ERROR_TYPE } from '../config/Constants';

const getErrorActionList = (errorType, classes, {
    onExit,
    onTryDifferentMethod,
    onEditAmount,
    onDelinquentTenantAlertClose,
    onPaymentAlertYesAction,
}) => {
    const exitConfig = [
        {
            label: localisable.exitCaps,
            onClick: onExit,
            otherProps: { className: classes.exitButton },
        },
    ];

    switch (errorType) {
        case ERROR_TYPE.PREPAY:
            return [
                ...exitConfig,
                { label: localisable.resetAmount, onClick: onEditAmount, otherProps: {} },
            ];
        case ERROR_TYPE.DELINQUENT_TENANT:
            return [
                {
                    label: localisable.exitCaps,
                    onClick: onDelinquentTenantAlertClose,
                    otherProps: { className: classes.exitButton },
                },
            ];
        case ERROR_TYPE.OTHER_ERROR:
            return [
                ...exitConfig,
                {
                    label: localisable.tryDifferentMethod,
                    onClick: onTryDifferentMethod,
                    otherProps: {},
                },
            ];
        default:
            return [
                {
                    label: localisable.ok,
                    onClick: onPaymentAlertYesAction,
                    otherProps: {},
                },
            ];
    }
};

const getErrorType = (isPrepayError, isDelinquentTenantError, isOtherError) => {
    if (isPrepayError) {
        return ERROR_TYPE.PREPAY;
    }
    if (isDelinquentTenantError) {
        return ERROR_TYPE.DELINQUENT_TENANT;
    }
    if (isOtherError) {
        return ERROR_TYPE.OTHER_ERROR;
    }
    return null;
};

export { getErrorActionList, getErrorType };
