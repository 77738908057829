import { useEffect, useState } from 'react';
import { Dropdown } from 'Generic/dropdown/components/Dropdown';
import localisable from 'Commons/config/strings/localisable';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import { SCROLL_TIME, DEFAULT_SECTION_ID } from '../config/Constants';

let sectionRefMap = {};
let sectionsOffset = [];
let isScrolling = null;
let isScrollListenerDisabled = false;

const initializeList = (config) => {
    const sectionConfig = [...config];
    // Resetting Helper Variables
    sectionRefMap = {};
    sectionsOffset = [];

    // Structuring helper objects and config
    return sectionConfig.map(({ label, id, ref }) => {
        sectionRefMap[id] = ref;
        if (ref) {
            sectionsOffset.push({ id, offsetTop: ref.current.offsetTop });
        }
        return { label, value: id };
    });
};

const SectionScroller = ({
    config,
    parentRef: { current: parentRef } = {}, DropdownProps,
    ...props
}) => {
    const [list, setList] = useState(() => initializeList(config));
    const [currentSectionId, setCurrentSectionId] = useState(DEFAULT_SECTION_ID);
    /**
     * @description Functions uses binary search to find the scroll position in the sections
     * @param {array} sections Sections offset and its id
     * @param {number} left Initial index of offset array
     * @param {number} right Last index of offset array
     * @param {number} pos Position to be searched
     */
    const getActiveId = (sections, left, right, pos) => {
        if (right >= left) {
            const mid = Math.trunc(left + (right - left) / 2);
            const midExists = sections[mid] !== undefined && sections[mid] !== null;
            const midPlusOneExists = sections[mid + 1] !== undefined && sections[mid + 1] !== null;
            if (midExists && midPlusOneExists && sections[mid].offsetTop && sections[mid + 1].offsetTop) {
                if (pos >= sections[mid].offsetTop
                    && (sections[mid + 1] ? (pos < sections[mid + 1].offsetTop) : true)) {
                    return sections[mid].id;
                }
                if (sections[mid].offsetTop > pos) {
                    return getActiveId(sections, left, mid - 1, pos);
                }
            }
            return getActiveId(sections, mid + 1, right, pos);
        }
        return DEFAULT_SECTION_ID;
    };

    // Listener for scroll change
    const handleScroll = (event) => {
        let { target: { scrollTop } } = event;
        const firstIndex = 0;
        const lastIndex = sectionsOffset.length - 1;
        scrollTop += sectionsOffset[firstIndex].offsetTop;

        if ((scrollTop <= sectionsOffset[firstIndex].offsetTop) || (scrollTop > sectionsOffset[lastIndex].offsetTop)) {
            if (currentSectionId !== DEFAULT_SECTION_ID) {
                setCurrentSectionId(() => DEFAULT_SECTION_ID);
            }
            return;
        }

        if (!isScrollListenerDisabled) {
            const scrolledActiveId = getActiveId(sectionsOffset, firstIndex, lastIndex, scrollTop);
            if (currentSectionId !== scrolledActiveId) { // If scroll position is not in the current section, reset it to default section
                setCurrentSectionId(() => DEFAULT_SECTION_ID);
            }
        } else {
            if (isScrolling) clearTimeout(isScrolling);
            isScrolling = setTimeout(() => {
                isScrollListenerDisabled = false;
            }, SCROLL_TIME);
        }
    };

    useEffect(() => {
        setList(initializeList(config));
    }, [config]);

    useEffect(() => {
        if (parentRef) {
            parentRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (parentRef) {
                parentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [currentSectionId]);

    const onChange = (_, selectedSection) => {
        isScrollListenerDisabled = true;
        const ref = sectionRefMap[selectedSection];
        if (ref && ref.current) {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
        setCurrentSectionId(() => selectedSection);
    };

    const updateSectionPosition = (id) => {
        onChange(null, id);
    };

    useEffect(() => {
        const { getUpdateScrollPositionFunc } = props;
        getUpdateScrollPositionFunc(updateSectionPosition);
    }, []);

    return (
        <Dropdown
            list={list}
            value={currentSectionId}
            trackValue={false}
            fullWidth
            placeholder={localisable.scrollToSections}
            onChange={onChange}
            {...DropdownProps}
            clearable={currentSectionId !== DEFAULT_SECTION_ID}
        />
    );
};

SectionScroller.propTypes = {
    config: PropTypes.array,
    parentRef: PropTypes.object,
    DropdownProps: PropTypes.object,
    getUpdateScrollPositionFunc: PropTypes.func,
};

SectionScroller.defaultProps = { getUpdateScrollPositionFunc: EMPTY_FUNC };

export default SectionScroller;
