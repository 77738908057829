/* eslint-disable import/prefer-default-export */
import { isInternalDashboard } from 'Commons/helpers/utils/Utils';
import basePostProcessor from './BaseProcessor';

const customProcessors = () => {
    const { location: { pathname } } = window;
    return isInternalDashboard(pathname)
        // eslint-disable-next-line global-require
        ? require('../../../internal/redux/postProcessors/index')
        // eslint-disable-next-line global-require
        : require('../../../external/redux/postProcessors/index');
};

const getPostProcessor = (postProcessorName) => {
    const allPostProcessors = {
        basePostProcessor,
        ...customProcessors(),
    };
    return allPostProcessors[postProcessorName];
};

export { getPostProcessor };
