import LabelWithAddons from 'Generic/labelwithaddons/components/LabelWithAddons';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import Icon from 'Generic/icon/components/Icon';
import useConstructor from 'Commons/helpers/hooks/useConstructor';

const AddMoreFilesButton = ({ title, onClick, classes }) => {
    const startIcon = useConstructor(() => (
        <Icon
            type="custom"
            icon="cp-add"
            color="primary"
            display="block"
        />
    ));

    return (
        <LabelWithAddons
            role="button"
            className={classes.addMoreButton}
            onClick={onClick}
            start={startIcon}
        >
            <Typography color="primary"> {title || localisable.addMorePhotos}</Typography>
        </LabelWithAddons>
    );
};


AddMoreFilesButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    classes: PropTypes.object,
};

export default React.memo(AddMoreFilesButton);
