const calculateNextAmountPayable = (unitList, id, skipCalculatingNextAmountPayable = true) => {
    let nextAmountPayable = 0;
    /* TODO: Next line check is just a hacky centralized fix for not using nextAmountPayable automatically, it'll be calculated on button click,
    remove it when redesigning Payment, remove this third parameter from the function as well. */
    if (skipCalculatingNextAmountPayable) return nextAmountPayable;
    unitList.forEach((unit) => {
        const { ledger: { id: ledgerId = '' } = {} } = unit;
        if (id === ledgerId) {
            nextAmountPayable += Number(unit.nextAmountPayable);
        }
    });
    return nextAmountPayable;
};


export { calculateNextAmountPayable };
