const userFormStyle = theme => ({
    form: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    pageBody: {
        flexWrap: 'nowrap',
        backgroundColor: 'transparent',
        padding: 0,
        overflow: 'hidden',
    },
    componentContainer: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        height: '100%',
        position: 'relative',
    },
    leftContainer: {
        position: 'relative',
        marginRight: theme.spacing(1),
        width: '60%',
        minWidth: '60%',
        maxWidth: '60%',
        overflowY: 'auto',
    },
    roleSelectionContainer: { backgroundColor: theme.palette.common.white },
    basicInfoContainer: { flexBasis: 'max-content' },
    mask: {
        top: 0,
        left: 0,
        position: 'absolute',
        height: '100%',
        backgroundColor: theme.hexWithOpacity(theme.palette.common.white, 6),
        zIndex: 600,
    },
    loader: { height: '100%' },
});

export default userFormStyle;
