import media from '../../../../theme/MediaQueries';

const FilterTypesStyle = theme => ({
    divider: {
        borderBottomColor: theme.separator.color,
        paddingTop: '0px',
    },
    sectionsContainer: {
        boxShadow: '0px 1px 6px 0px rgba(0,0,0,0.16)',
        width: '100%',
    },
    sectionComponent: {
        padding: 16,
        [media.smallMobile]: { padding: '0px' },
        [media.mobile]: { padding: '0px' },
        [media.phablet]: { padding: '0px' },
        [media.tablet]: { padding: '0px' },
    },
    section: { width: '100%' },
    mobileSectionHeader: {
        fontSize: '1rem',
        fontWeight: 'bolder',
    },
    mobileSectionFilters: {
        textAlign: 'end',
        fontSize: '0.875rem',
        fontWeight: 'bolder',
        color: theme.palette.common.secondary,
    },
    list: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '-5px 0px 5px -4px rgba(0, 0, 0, 0.16)',
        width: '100%',
        height: 'auto',
        [media.smallMobile]: {
            width: '100%',
            left: 0,
            position: 'absolute',
            boxShadow: 'none',
            zIndex: '1',
            height: '100%',
            flexDirection: 'column',
        },
        [media.mobile]: {
            width: '100%',
            left: 0,
            position: 'absolute',
            boxShadow: 'none',
            zIndex: '1',
            height: '100%',
            flexDirection: 'column',
        },
        [media.phablet]: {
            width: '100%',
            left: 0,
            position: 'absolute',
            boxShadow: 'none',
            zIndex: '1',
            height: '100%',
            flexDirection: 'column',
        },
        [media.tablet]: {
            position: 'absolute',
            zIndex: '1',
            left: '0px',
            width: '100%',
            boxShadow: 'none',
            height: '100%',
            flexDirection: 'column',
        },
        [media.laptopUp]: {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
        },

        '& $listItems': {
            height: 'auto',
            padding: '5px 20px',
            fontSize: '1rem',
            lineHeight: 'normal',
            [media.laptopUp]: { borderBottom: '0.9px solid rgba(239, 239, 239, 0.5)' },
            [media.mobile]: { '&:active': { background: '#F5F5F5' } },
            cursor: 'pointer',
        },

        '& $child': {
            paddingTop: 8,
            paddingBottom: 8,
        },

        '& $sectionHeader': {},

        '& *::-webkit-scrollbar': { width: 5 },

        '& *::-webkit-scrollbar-track': { borderRadius: 5 },

        '& *::-webkit-scrollbar-track-piece': {
            borderRadius: 5,
            background: 'transparent',
        },


        '& *::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            background: '#b4b4b4',
        },
    },
    sectionHeader: {
        fontSize: '1rem',
        fontWeight: '600',
        margin: '24px',
        padding: '2px',
        marginLeft: 20,
    },
    listItems: {
        height: 'auto',
        padding: '16px 28px',
        fontSize: '1rem',
        lineHeight: 'normal',
        [media.tablet]: {
            width: 736,
            height: 51,
        },
        [media.laptopUp]: { borderBottom: '0.9px solid rgba(239, 239, 239, 0.5)' },
        cursor: 'pointer',
    },
    activeLabel: {
        '& span': {
            color: theme.palette.secondary.main,
            fontWeight: 'bolder',
        },
    },
    searchFieldContainer: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: 16,
    },
    searchField: { height: '36px' },
    filterTypesContainer: { height: '100%' },
});

export default FilterTypesStyle;
