/* eslint-disable no-use-before-define */

import STATUS from 'Commons/config/constants/StoreKeyStatus';
import getUUID from 'Commons/helpers/utils/generateUUID';
import makeApiCall from '../../helpers/api/Fetcher';
import { curQueue } from '../../helpers/api/Queue';
import { getPostProcessor } from '../postProcessor/allProcessors';

/**
 * @description It converts an async, await to a more consumable format
 * Ex: [err, data]
 * @param {*} promise Fetch Promise
 */
const to = promise => promise.then(data => [null, data])
    .catch(err => [err]);


/**
 * @description This will be dispatch an Action Creator to the reducer
 * @param {String} type The Action Type
 * @param {Object} payload The payload or data that needs to be updated in store
 * @param {*} dispatchProp The dispatch function that has been passed as a prop
 */
const dispatchAction = (type, payload = {}, storeKey = '', requestType, dispatchProp, keys, isDataReversed, api) => {
    // this.props.dispatch(action(payload));
    if (type) {
        dispatchProp({
            type,
            payload,
            storeKey,
            requestType,
            keys,
            isDataReversed,
            api,
        });
    }
};

/**
 * @description Dispatch the loading action
 * @param {*} key Store Key
 * @param {*} dispatch The dispatch function that generates action and calls reducer
 */
const updateStoreKeyToLoading = (key, storeKey = '', requestType, dispatchProp, keys, isDataReversed, api) => {
    // Dispatch the loading event
    dispatchAction(`${key}_loading`, {}, storeKey, requestType, dispatchProp, keys, isDataReversed, api);
};

/**
 * @description Backend api call
 * @param {Object} options - Fetch Config
 * @param {string} options.url - The End Point
 * @param {string} options.method - The VERB (GET, POST, PUT, DELETE)
 * @param {Object} options.headers - Any Additional headers to be sent, like pagination
 * @param {string} options.credentials - To Be sent with cors/include/same-origin options
 * @param {Object} options.data - The Request body, for non GET calls
 * @param {Object} options.timestamp - The timestamp of the request, to add to queue and remove
 * @param {number} [options.retries] - The Number Of Retries On Failure
 * @param {number} [options.retryDelay] - The delay between successive retries
 */
export const api = async (options = {}) => {
    const localReqOptions = Object.assign(options);
    const { api: apiOptions } = options;
    // Adding a timestamp to identify a request
    const timestamp = getUUID();
    localReqOptions.timestamp = timestamp;
    // Need to check queue status
    // Returns if the current request added to curQ or pendingQ

    if (apiOptions.endPoint) {
        if (curQueue.add(localReqOptions)) {
        // Make API call if it is not pending
            const [err, data] = await to(makeApiCall(apiOptions));
            return {
                error: err,
                response: data,
                timestamp,
            };
        }
    }
    if (curQueue.pendingQueue.length > 0) {
        // Added to pending queue
        return {
            error: null,
            response: { status: STATUS.QUEUED },
            timestamp,
        };
    }
    // Invalid Options Passed
    return {
        error: 'No Options Passed',
        response: null,
        timestamp,
    };
};

function updateStore(options, isStoreOnlyUpdate = false) {
    const localReqOptions = Object.assign(options);
    const {
        callback,
        store: { actionKey, key: storeKey, dispatch: dispatchProp, payload, keys },
        requestType, isDataReversed, resourceName, skipCustomPostProcessor = false, api: requestApi,
    } = localReqOptions;
    dispatchAction(actionKey, payload, storeKey, requestType, dispatchProp, keys, isDataReversed, requestApi);
    if (isStoreOnlyUpdate && !skipCustomPostProcessor) {
        const postProcessorName = `${resourceName || storeKey}PostProcessor`;
        const postProcessor = getPostProcessor(postProcessorName);
        if (postProcessor) postProcessor(payload, callback, dispatchProp, localReqOptions);
    }
}

async function updateViaAPIOnly(options, storeData) {
    const localReqOptions = Object.assign(options);
    const {
        api: requestOptions,
        callback,
        dispatch,
        store: { key = '', keys = [] } = {},
        skipCustomPostProcessor = false,
        resourceName = '',
        processCustomHeaders = () => { },
        requestType,
        subProcessor,
        isDataReversed,
    } = localReqOptions;
    const ApiResult = await api(localReqOptions);
    const postProcessorName = `${resourceName || key}PostProcessor`;
    const postProcessor = getPostProcessor(postProcessorName);
    const curPostProcessor = postProcessor || getPostProcessor('basePostProcessor');
    return curPostProcessor(
        ApiResult, callback, dispatch,
        {
            api,
            keys,
            storeData,
            requestType,
            subProcessor,
            requestOptions,
            isDataReversed,
            processCustomHeaders,
            skipCustomPostProcessor,
        },
    );
}

async function storeApiUpdate(options, storeData) {
    const localReqOptions = Object.assign(options);
    const {
        store: { actionKey, key: storeKey, dispatch: dispatchProp, keys },
        requestType, isDataReversed, api: requestApi,
    } = localReqOptions;
    if (actionKey.length > 0) {
        //  Updating status to loading
        updateStoreKeyToLoading(actionKey, storeKey, requestType, dispatchProp, keys, isDataReversed, requestApi);
        // Queue and make API call, need a callback function to be called on success
        localReqOptions.store.payload = await updateViaAPIOnly(localReqOptions, storeData);
        updateStore(localReqOptions);
    }
}

/**
 * @description Call this to make API call, and then trigger respective actions
 * @param {Object} options The fetch config to pass, can be a combination of action & dynamic config
 */
export const action = async (options, dispatch, storeData) => {
    const localReqOptions = Object.assign(options);
    const {
        api: requestOptions,
        store,
    } = localReqOptions;
    localReqOptions.dispatch = dispatch;
    if (store && requestOptions) {
        await storeApiUpdate(localReqOptions, storeData);
    } else if (store) {
        updateStore(localReqOptions, true);
    } else if (requestOptions) {
        updateViaAPIOnly(localReqOptions, storeData);
    } else {
        // console.error('No Api/Store Options Passed');
    }
};
