import media from 'Commons/theme/MediaQueries';

const ICON_CONTAINER_WIDTH = {
    desktop: 48,
    responsive: 32,
};
const ICON_CONTAINER_HEIGHT = {
    desktop: 48,
    responsive: 32,
};

const sideIconStyles = theme => ({
    iconContainer: {
        width: '100%',
        border: `1px solid ${theme.palette.common.black}`,
        minHeight: 50,
        borderRadius: 4,
        [media.laptopUp]: {
            minHeight: 36,
            width: ICON_CONTAINER_WIDTH.responsive,
            height: ICON_CONTAINER_HEIGHT.responsive,
            border: 'none',
            backgroundColor: theme.palette.primary.main,
            borderTopLeftRadius: ICON_CONTAINER_WIDTH.responsive / 2,
            borderBottomLeftRadius: ICON_CONTAINER_WIDTH.responsive / 2,
        },
        [media.desktop]: {
            minHeight: 50,
            width: ICON_CONTAINER_WIDTH.desktop,
            height: ICON_CONTAINER_HEIGHT.desktop,
            borderTopLeftRadius: ICON_CONTAINER_WIDTH.desktop / 2,
            borderBottomLeftRadius: ICON_CONTAINER_WIDTH.desktop / 2,
        },
        cursor: 'pointer',
    },
    sideIcon: { color: theme.palette.common.white, marginLeft: theme.spacing(1) },
    disabled: { [media.laptopUp]: { backgroundColor: theme.palette.disabled, pointerEvents: 'none' } },
});

export default sideIconStyles;
