import { isValidEmail } from './validator/Validator';

const isStringEmpty = str => str === '';

/**
* @description
* - Removes leading and trailing white space and
* - Replaces double spaces with single space throughout the string
* @param {String} string
* @return {String} White space trimmed string
*/
const getWhiteSpaceTrimmedString = string => (string || '').trim().replace(/\s\s+/g, ' ');

const getValidEmailsFromString = (emailsString = '') => {
    const emails = emailsString.split(',').map(email => email.trim());
    return emails.filter(email => isValidEmail(email));
};

const stringifyEmailList = emailList => (emailList || []).join(', ') || '';

export { isStringEmpty, getWhiteSpaceTrimmedString, getValidEmailsFromString, stringifyEmailList };
