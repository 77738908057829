/* eslint-disable react/jsx-one-expression-per-line */
import Footer from 'Commons/components/business/footer/components/Footer';
import {
    Grid,
    withStyles,
    Paper,
} from 'Commons/components/generic/componentlibrary/components/Components';
import { Formik } from 'formik';
import Overlay from '../../overlay/components/Overlay';
import FilterOverlayStyle from '../styles/FilterOverlayStyle';
import FilterTypes from './FilterTypes';
import { VIEW } from '../../../../config/constants/Constants';
import { convertAppliedFiltersToValues } from '../../../../helpers/utils/SmartListDataHelper';
import RequestTypes from '../../../../config/constants/RequestTypes';
import BaseForm from '../../baseform/components/BaseForm';
import localisable from '../../../../config/strings/localisable';
import STATUS from '../../../../config/constants/StoreKeyStatus';
import AppliedFilterContainer from '../../appliedFilterContainer/component/AppliedFilterContainer';
import FILTER_STAGES from './configs/FilterStages';

class FilterOverlay extends BaseForm {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            reset: false,
            stage: FILTER_STAGES.FILTER_TYPES_LIST,
            filtersApplied: props.appliedFilters || {},
            filtersUpdated: false,
            curSectionSelected: {},
        };
        this.initialAppliedFilters = this.convertAppliedFiltersToInitialValues(props.appliedFilters);
    }

    componentDidMount() {
        const { listConfig: { filterProps: { sectionsConfig: { sections } } } } = this.props;
        this.initSections(sections);
    }

    getHeader = () => {
        const { classes, listName } = this.props;
        return (
            <Grid container spacing={2} alignItems="flex-end">
                <Grid className={classes.filterHeader} item>
                    {`${listName} - Filter`}
                </Grid>
            </Grid>
        );
    }

    initSections = (sections) => {
        this.sections = { ...sections };
        const { sectionClicked } = this.props;
        this.sectionsList = this.sections ? Object.keys(this.sections) : [];
        if (sectionClicked) {
            this.curSectionSelected = sectionClicked;
        } else {
            this.curSectionSelected = this.sectionsList.length > 0 ? this.sections[this.sectionsList[0]] : null;
        }
        this.setState({ curSectionSelected: { ...this.curSectionSelected } });
    }

    changeCurSectionSelected = (section) => {
        const { device = 'isDesktop' } = this.props;
        if (device !== 'isDesktop') {
            if (this.updateFilterStage) {
                this.updateFilterStage(FILTER_STAGES.FILTER_SECTION);
            }
        }
        this.setState({ curSectionSelected: section });
    }

    onChipSelect = (filter) => {
        const { getSectionByFilter, listConfig: { filterProps: { sectionsConfig: { availableFilters, sections } } } } = this.props;
        const section = getSectionByFilter(filter, availableFilters);
        if (section) {
            this.sectionClicked = sections[section] || {};
            this.setState({ curSectionSelected: this.sectionClicked });
        }
    }

    onClearAll = () => {
        this.curFiltersApplied = {};
        this.initialAppliedFilters = {};
        const { resetForm } = this.formProps;
        if (resetForm) {
            resetForm({});
        }
        this.setState({ filtersApplied: {} }, () => {
            this.setState({ filtersUpdated: true });
        });
    }

    /**
     * @name FilterData
     * @description Callback to filter overlay, to get search request
     */
    filterData = (filters = [], filtersApplied = {}) => {
        const { onAction, listConfig: { filterSearchUrl, sortConfig } } = this.props;
        onAction({
            config: [{
                method: 'read',
                api: {
                    dynamic: {
                        methodType: 'POST',
                        endPoint: filterSearchUrl,
                        customHeaderOptions: {
                            type: 'store',
                            key: 'facility',
                        },
                        body: {
                            view: VIEW.list.value,
                            filter: filters,
                            sort: sortConfig,
                        },
                    },
                },
                callback: this.onFilterApplied(filtersApplied),
                // store: { key: store },
                requestType: RequestTypes.REFRESH,
            }],
        });
    }

    applyFilters = () => {
        const { values } = this.formProps;
        const { listConfig: { filterProps: { sectionsConfig: { availableFilters } } }, createFilterRequest } = this.props;
        // Merge currently applied filters and new filters
        const newValues = {
            ...convertAppliedFiltersToValues(this.curFiltersApplied, availableFilters),
            ...values,
        };
        const { searchRequest: filters, filtersApplied } = createFilterRequest(availableFilters, newValues);
        this.filterData(filters, filtersApplied);
    }

    updateFilters = () => {
        const { values } = this.formProps;
        const { listConfig: { filterProps: { sectionsConfig: { availableFilters } } }, createFilterRequest } = this.props;
        const { filtersApplied: stateFiltersApplied } = this.state;
        // Merge currently applied filters and new filters
        this.curFilters = {
            ...convertAppliedFiltersToValues(stateFiltersApplied, availableFilters),
            ...values,
        };
        const { filtersApplied } = createFilterRequest(availableFilters, this.curFilters);
        this.curFiltersApplied = filtersApplied;
    }

    filterRemoved = (updatedFilters) => {
        this.curFiltersApplied = updatedFilters;
        this.initialAppliedFilters = this.convertAppliedFiltersToInitialValues(updatedFilters);
        if (Object.keys(updatedFilters).length === 0) {
            const { resetForm } = this.formProps;
            if (resetForm) {
                resetForm({});
            }
        }

        this.setState({ filtersApplied: updatedFilters }, () => {
            this.setState({ filtersUpdated: true });
        });
    }

    updateFilterStage = (stage = FILTER_STAGES.FILTER_TYPES_LIST) => {
        this.setState({ stage });
    }

    getFooter = (FooterProps) => {
        const { isValid, dirty, handleReset } = this.formProps;
        const { errorMessage, filtersUpdated } = this.state;
        const { classes } = this.props;
        const FooterErrorProps = errorMessage ? { message: errorMessage, classes: { message: classes.message } } : {};
        return (
            <Footer
                className={classes.footer}
                positiveButtonProps={{
                    label: localisable.applyFilters,
                    type: 'submit',
                    disabled: !filtersUpdated && !isValid,
                    onClick: () => {
                        this.submitForm();
                    },
                }}
                negativeButtonProps={{
                    label: localisable.discard,
                    disabled: !dirty,
                    onClick: () => {
                        // this.onClose();
                        handleReset();
                    },
                }}
                {...FooterProps}
                {...FooterErrorProps}
            />
        );
    }


    onClose = () => {
        const { setFilterOverlay } = this.props;
        setFilterOverlay(false);
    }

    handleBackButton = () => {
        const { stage } = this.props;
        switch (stage) {
            case FILTER_STAGES.APPLIED_FILTERS:
                this.updateFilterStage(FILTER_STAGES.FILTER_TYPES_LIST);
                break;
            case FILTER_STAGES.FILTER_SECTION:
                this.updateFilterStage(FILTER_STAGES.FILTER_TYPES_LIST);
                break;
            default:
                this.updateFilterStage(FILTER_STAGES.FILTER_TYPES_LIST);
        }
    }

    onFilterApplied = filtersApplied => (apiError, apiResponse) => {
        if (apiError) {
            const { setErrors, setTouched } = this.formProps;
            const actions = { setErrors, setTouched };
            this.handleErrors(apiError, actions);
        } else if (apiResponse) {
            const { onAction, listConfig: { store }, onFilterChange } = this.props;
            onAction({
                config: [{
                    method: 'read',
                    store: {
                        key: store,
                        payload: {
                            data: {},
                            status: STATUS.LOADING,
                        },
                        actionKey: `${store}_read_loading`,
                    },
                    requestType: RequestTypes.REFRESH,
                }, {
                    method: 'read',
                    store: {
                        key: store,
                        payload: {
                            data: apiResponse,
                            status: STATUS.LOADED,
                        },
                        actionKey: `${store}_read`,
                    },
                    requestType: RequestTypes.REFRESH,
                }],
            });
            onFilterChange(filtersApplied, { apiCall: false });
            this.onClose();
        }
    }

    convertAppliedFiltersToInitialValues = (activeFilters = {}) => {
        const initialValues = {};
        Object.keys(activeFilters).forEach((filter) => {
            initialValues[filter] = activeFilters[filter].value;
        });
        return initialValues;
    }

    resetFilters = (values, actions) => {
        const { appliedFilters } = this.props;
        const { resetForm, setSubmitting } = actions;
        if (resetForm) {
            const initialValues = this.convertAppliedFiltersToInitialValues(appliedFilters);
            setSubmitting(false);
            resetForm(initialValues);
        }
    }

    renderMobile() {
        const { classes } = this.props;
        const { stage } = this.state;
        return (
            <Grid container className={classes.filterContainer}>
                { (stage === FILTER_STAGES.FILTER_TYPES_LIST || stage === FILTER_STAGES.FILTER_SECTION) && this.renderFilterTypes()}
                { (stage === FILTER_STAGES.APPLIED_FILTERS) && this.renderAppliedFilters()}
            </Grid>
        );
    }

    renderDesktop() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.filterContainer} spacing={2}>
                { this.renderFilterTypes()}
                { this.renderAppliedFilters()}
            </Grid>
        );
    }

    renderFilterTypes() {
        const {
            classes,
            listConfig: { filterProps: { sectionsConfig: { sections, availableFilters } } },
            appliedFilters,
            device,
        } = this.props;
        const { stage, curSectionSelected } = this.state;
        return (
            <Grid item container xs={12} lg={8}>
                <Paper className={classes.filterTypesPaper}>
                    <FilterTypes
                        sections={sections}
                        appliedFilters={appliedFilters}
                        applyFilters={this.applyFilters}
                        availableFilters={availableFilters}
                        formProps={this.formProps}
                        device={device}
                        stage={stage}
                        changeStage={this.updateFilterStage}
                        onBack={this.handleBackButton}
                        curSection={curSectionSelected}
                        onSectionClicked={this.changeCurSectionSelected}
                    />
                </Paper>
            </Grid>
        );
    }

    renderAppliedFilters() {
        const {
            classes,
            device,
        } = this.props;
        const { filtersApplied: stateFiltersApplied } = this.state;
        this.updateFilters();
        return (
            <Grid item container className={classes.filterContainer} xs={12} lg={4}>
                <Paper className={classes.filterTypesPaper}>
                    <AppliedFilterContainer
                        data={this.curFiltersApplied || stateFiltersApplied}
                        width={500}
                        isInModal
                        onFilterChange={this.filterRemoved}
                        changeStage={this.updateFilterStage}
                        device={device}
                        onBack={this.handleBackButton}
                        onSelectChip={this.onChipSelect}
                        onClearAll={this.onClearAll}
                    />
                </Paper>
            </Grid>
        );
    }

    renderStage() {
        const { device } = this.props;
        switch (device) {
            case 'isMobile':
            case 'isPhablet':
            case 'isTablet':
                return this.renderMobile();
            // return this.renderTablet();
            default:
                return this.renderDesktop();
        }
    }

    render() {
        const {
            match: { path } = {},
            showFilterOverlay = false,
            classes,
        } = this.props;
        return (
            <Formik
                onSubmit={this.applyFilters}
                onReset={this.resetFilters}
                enableReinitialize
                initialValues={this.initialAppliedFilters}
                render={(formProps) => {
                    this.formProps = formProps;
                    return (
                        <Overlay
                            header={this.getHeader()}
                            footer={this.getFooter()}
                            classes={{ overlay: classes.overlay, overlayHeader: classes.overlayHeader }}
                            path={`${path}`}
                            hideActionHeader
                            ModalProps={{ onClose: this.onClose, open: showFilterOverlay, modalClasses: { modalGrid: classes.modalGrid } }}
                        >
                            {
                                this.renderStage()
                            }
                        </Overlay>
                    );
                }}
            />
        );
    }
}

FilterOverlay.propTypes = {
    match: PropTypes.any, // The match from router
    showFilterOverlay: PropTypes.bool, // The method from parent container which maintains a state variable, to toggle overlay visibility
    createFilterRequest: PropTypes.func.isRequired, // The method from parent container which has access to createFilterRequest, which creates search request
    listConfig: PropTypes.object.isRequired, // Contains the initial config for the list
    listName: PropTypes.string.isRequired,
    appliedFilters: PropTypes.any, // The currently applied filters
    classes: PropTypes.any,
    device: PropTypes.string, // The type of device filter overlay is rendered on
    setFilterOverlay: PropTypes.func.isRequired, // Callback to parent container to set visibility value
    onFilterChange: PropTypes.func.isRequired, // Callback to parent container, to let it know when filter is added/removed
    onAction: PropTypes.any.isRequired, // SmartComponent prop to dispatch actions to Parent Containers HOC
    sectionClicked: PropTypes.any, // The default section to be selected on open
    getSectionByFilter: PropTypes.func.isRequired, // When you want to get section for a filter that was clicked
};

FilterOverlay.defaultProps = {
    showFilterOverlay: false,
    device: 'isDesktop',
};

export default withStyles(FilterOverlayStyle)(FilterOverlay);
