import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { STATUS } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import ConfigurationFilterStyle from '../styles/ConfigurationFilterStyle';

const useStyles = makeStyles(ConfigurationFilterStyle, { name: 'ConfigurationFilterStyle' });


const ConfigurationFilter = (props) => {
    const {
        formProps: { setFieldValue } = {},
        includeInactiveForFutureUseStatus = false,
    } = props;

    const classes = useStyles();

    const handleStatusCheckboxChange = (_, value) => {
        if (includeInactiveForFutureUseStatus && value.includes(STATUS.Inactive.value)) {
            setFieldValue('terms.value.status', [...value, STATUS.Inactive_For_Future_Use.value]);
        } else {
            setFieldValue('terms.value.status', value.filter(item => item !== STATUS.Inactive_For_Future_Use.value));
        }
    };

    return (
    <>
        <Typography variant="subtitle1" className={classes.firstLabel}>
            {localisable.status}
        </Typography>
        <FormCheckboxGroup
            name="terms.value.status"
            list={[STATUS.Active, STATUS.Inactive]}
            row
            noGrid
            noIndicatorWrapper
            onChange={handleStatusCheckboxChange}
        />
    </>
    );
};

ConfigurationFilter.propTypes = {
    formProps: PropTypes.object,
    includeInactiveForFutureUseStatus: PropTypes.bool,
};

export default memo(ConfigurationFilter);
