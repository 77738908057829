import * as unflatten from 'unflatten';
import { FilterTypes } from '../../components/business/filterOverlay/components/configs/FilterConfigs';
import { extractValueFromObject, isValidValue } from './DataHelpers';
/**
 * @name convertAppliedFiltersToValues
 * @description Normalizes the filterApplied to a value
 * @param {*} appliedFilters The current applied filters
 */
const convertAppliedFiltersToValues = (appliedFilters = {}, availableFilters) => {
    const filtersActiveKeys = Object.keys(appliedFilters);
    if (appliedFilters && filtersActiveKeys.length > 0) {
        const newFilterValues = {};
        filtersActiveKeys.forEach((key) => {
            if (availableFilters[key] && availableFilters[key].type === FilterTypes.MULTI_SELECT) {
                if (Array.isArray(appliedFilters[key].value)) {
                    newFilterValues[key] = appliedFilters[key].value;
                } else {
                    newFilterValues[key] = [appliedFilters[key].value];
                }
            } else {
                newFilterValues[key] = appliedFilters[key].value || {};
            }
        });
        return newFilterValues;
    }
    return {};
};

const cleanseTheFilters = (filters = {}) => {
    const filterKeys = Object.keys(filters);
    const cleansedFilters = {};
    if (filterKeys.length > 0) {
        filterKeys.forEach((key) => {
            if (isValidValue(filters[key])) {
                const filterValue = filters[key];
                if (Array.isArray(filterValue) && filterValue.length > 0) {
                    cleansedFilters[key] = filters[key];
                } else if (typeof filterValue === 'object' && Object.keys(filterValue).length > 0) {
                    const cleanedObject = cleanseTheFilters(filterValue);
                    if (Object.keys(cleanedObject).length > 0) {
                        cleansedFilters[key] = cleanseTheFilters(filterValue);
                    }
                } else if (typeof filterValue === 'string' && filterValue.length > 0) {
                    cleansedFilters[key] = filters[key];
                } else if (typeof filterValue === 'boolean') {
                    cleansedFilters[key] = filters[key];
                }
            }
        });
    }
    return cleansedFilters;
};

/**
 * @name constructFilterRequest
 * @description Given all filters and current filters applied, it will return filters request
 * @param {Object} allFilters All Available Filters for work center
 * @param {Object} currentFilters Current Filters Applied
 */
const constructFilterRequest = (allFilters = {}, currentFilters = {}) => {
    const currentFiltersKeys = Object.keys(currentFilters);
    const newSearchRequest = {
        terms: {},
        range: {},
    };
    const newFiltersApplied = {};

    currentFiltersKeys.forEach((filter) => {
        if (allFilters[filter]) {
            const { type, apiMapping, label, hidden = false } = allFilters[filter]; // Filter from all available filter - The current filter
            switch (type) {
                case FilterTypes.NUMBER_RANGE:
                case FilterTypes.DATE_RANGE:
                    newSearchRequest.range[apiMapping] = currentFilters[filter];
                    newFiltersApplied[filter] = {
                        label,
                        value: currentFilters[filter],
                        hidden,
                    };
                    break;
                case FilterTypes.SINGLE_SELECT:
                    newSearchRequest.terms[apiMapping] = [extractValueFromObject(currentFilters[filter])];
                    newFiltersApplied[filter] = {
                        label,
                        value: extractValueFromObject(currentFilters[filter]),
                        hidden,
                    };
                    break;
                case FilterTypes.MULTI_SELECT:
                    if (currentFilters[filter] && Array.isArray(currentFilters[filter]) && currentFilters[filter].length > 0) {
                        newSearchRequest.terms[apiMapping] = [...currentFilters[filter]];
                    }
                    newFiltersApplied[filter] = {
                        label,
                        value: Array.isArray(currentFilters[filter]) ? [...currentFilters[filter]] : [currentFilters[filter]],
                        hidden,
                    };
                    break;
                default:
                    newSearchRequest.terms[apiMapping] = [currentFilters[filter]];
                    newFiltersApplied[filter] = {
                        label,
                        value: [currentFilters[filter]],
                        hidden,
                    };
            }
        }
    });
    return {
        searchRequest: [unflatten(newSearchRequest)],
        filtersApplied: newFiltersApplied,
    };
};

export {
    constructFilterRequest,
    convertAppliedFiltersToValues,
    cleanseTheFilters,
};
