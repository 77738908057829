import { useState } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import Icon from 'Generic/icon/components/Icon';
import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import Form from '../../form/components/Form';
import {
    Checkbox as MaterialUiCheckbox,
    FormControlLabel,
    makeStyles,
} from '../../componentlibrary/components/Components';
import checkBoxStyle from '../styles/CheckBoxStyle';

const useStyles = makeStyles(checkBoxStyle, { name: 'Checkbox' });

const Checkbox = (props) => {
    const {
        trackValue, value, label, form, classes: overriddenClasses, formLabelClassName, size,
        FormControlLabelProps: { className: formLabelClass, ...otherLabelProps } = {},
        checkboxClasses, onChange: propOnChange, className, preferred, readOnly, ...others
    } = props;
    const [stateValue, setStateValue] = useState(() => (trackValue ? value : null));
    const classes = useStyles(props);

    const onChange = (event, currentValue) => {
        if (trackValue) {
            setStateValue(currentValue);
        }
        propOnChange(event, currentValue);
    };

    const renderCheckbox = () => (
        <MaterialUiCheckbox
            onChange={onChange}
            color={readOnly ? 'default' : 'primary'}
            className={clsx(classes.root, className, readOnly && classes.readOnly)}
            classes={checkboxClasses}
            checked={trackValue ? stateValue : value}
            value={trackValue ? stateValue : value}
            checkedIcon={<Icon icon="cp-check-box" type="custom" size={size} />}
            icon={<Icon icon="cp-check-box-outlined" type="custom" size={size} />}
            indeterminateIcon={<Icon icon="cp-indeterminate-check-box" type="custom" size={size} />}
            {...others}
        />
    );


    return (
        label
            ? (
                <FormControlLabel
                    className={clsx(classes.formLabel,
                        formLabelClass,
                        formLabelClassName,
                        readOnly && classes.InheritedConfigLabel)}
                    control={renderCheckbox()}
                    label={label}
                    {...otherLabelProps}
                />
            )
            : renderCheckbox()
    );
};

Checkbox.propTypes = {
    label: PropTypes.node,
    form: PropTypes.object,
    name: PropTypes.string,
    size: PropTypes.string,
    onChange: PropTypes.func,
    preferred: PropTypes.bool,
    classes: PropTypes.object,
    trackValue: PropTypes.bool,
    readOnly: PropTypes.bool,
    className: PropTypes.string,
    checkboxClasses: PropTypes.object,
    formLabelClassName: PropTypes.string,
    FormControlLabelProps: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

Checkbox.defaultProps = {
    label: '',
    value: false,
    trackValue: true,
    onChange: () => { },
    form: { setFieldValue: () => { } },
    readOnly: false,
};

Checkbox.displayName = FORM_COMPONENTS.CHECKBOX;
const FormCheckbox = Form(Checkbox);

export {
    Checkbox,
    FormCheckbox,
};
