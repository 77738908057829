/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import {
    BOARD,
    RENTAL_AVAILABILITY_STATUS_BOARD_RESERVED_ITEM_ID,
} from 'External/containers/facilityConfiguration/components/bevColorSetup/components/config/Constants';
import { getConvertedWH, getUnitDetails } from 'Commons/components/business/bev/utils/Utils';
import { Collection } from 'react-virtualized';
import Typography from 'Generic/typography/components/Typography';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import bevViewStyles from 'Commons/components/business/bev/components/view/styles/BevViewStyles';
import { memo, useEffect, useRef } from 'react';
import {
    DASHBOARD_TYPE,
    EMPTY_FIELD_TEXT,
    EMPTY_FUNC,
    RESERVATION_STATUS,
    SOURCE,
} from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import { VACANT_AVAILABILITY_STATUS } from 'External/containers/unit/form/config/Constants';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import { clsx } from 'Commons/helpers/utils/clsx';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import Chip from 'Commons/components/generic/chip/components/Chip';
import { AVAILABILITY_STATUS } from 'Commons/components/business/filtersAndSorts/components/unitFilter/config/Constants';
import { GATE_STATUS } from 'External/containers/facilityConfiguration/config/GateConfig';
import { RENTAL_STATUS, TRIANGLE_POSITIONS, TRIANGLE_TYPES, TYPE_OF_ENTITY } from '../../config/Constants';
import { fetchReservation, fetchTenant } from '../../config/ApiRequests';

const useStyles = makeStyles(bevViewStyles);

const Triangle = ({
    corner,
    unitId,
    entityBevData,
    zoomValue,
    isFacilityMigrated = false,
}) => {
    const classes = useStyles({ entityBevData, zoomValue, isFacilityMigrated });
    const {
        BOTTOM_RIGHT,
        TOP_LEFT,
        TOP_RIGHT,
    } = TRIANGLE_POSITIONS;
    const {
        BOTTOMRIGHT,
        TOPLEFT,
        TOPRIGHT,
    } = TRIANGLE_TYPES;

    let triangleType;

    switch (corner) {
        case BOTTOM_RIGHT:
            triangleType = BOTTOMRIGHT;
            break;
        case TOP_RIGHT:
            triangleType = TOPRIGHT;
            break;
        case TOP_LEFT:
            triangleType = TOPLEFT;
            break;
        default:
            break;
    }

    return (
        <span
            key={unitId}
            className={`${classes.triangle} ${classes[`${triangleType}Triangle`]}`}
        />
    );
};

Triangle.defaultProps = { isFacilityMigrated: false };

Triangle.propTypes = {
    corner: PropTypes.string,
    unitId: PropTypes.string,
    entityBevData: PropTypes.object,
    zoomValue: PropTypes.number,
    isFacilityMigrated: PropTypes.bool,
};

const TriangleMemoized = memo(Triangle);

const Entity = (props) => {
    const {
        data,
        handleUnitClick,
        zoomValue,
        isFacilityMigrated = false,
        isSearchedUnit,
        unitTypeData,
        unitBevData = [],
        unitIdTenantMapper = {},
        unitIdReservationMapper = {},
    } = props;
    const {
        id,
        label,
        entityType,
        bev,
        bev: {
            x: bevX,
            y: bevY,
            width: bevWidth,
            depth: bevHeight,
            color: {
                [BOARD.LOCK_OVERLOCK_STATUS]: lockOverlockBoard,
                [BOARD.MAINTENANCE_ACTIVITY]: maintenanceActivityBoard,
                [BOARD.DELINQUENCY_STATUS]: delinquencyStatusBoard,
            } = {},
        } = {},
        unitType,
        rentalStatus,
    } = data || {};

    const {
        width,
        depth,
        standardMonthlyRate,
        currentRate,
        nextRentAssessmentDate,
        unitGateAccessStatus,
        unitAvailabilityStatus,
        categoryResult,
        unitComments,
        tenantBalance,
        firstName,
        lastName,
        lastPaidDate,
        lastPaidAmount,
        reservationCode,
        reservationStatus,
        reservedFirstName,
        reservedLastName,
    } = getUnitDetails(id, unitType, unitTypeData, unitBevData, unitIdTenantMapper, unitIdReservationMapper);

    const { color: bottomRightColor } = lockOverlockBoard || {};
    const { color: topRightColor } = maintenanceActivityBoard || {};
    const { color: topLeftColor } = delinquencyStatusBoard || {};
    const classes = useStyles({ entityBevData: bev, zoomValue, isFacilityMigrated });

    const isEntityUnit = entityType === TYPE_OF_ENTITY.Unit.value;

    if (!(bevX && bevY && bevWidth && bevHeight)) return null;

    const renderTriangles = () => {
        const {
            BOTTOM_RIGHT,
            TOP_LEFT,
            TOP_RIGHT,
        } = TRIANGLE_POSITIONS;

        const triangles = [];

        const commonProps = {
            unitId: id,
            entityBevData: bev,
            zoomValue,
            isFacilityMigrated,
        };

        if (bottomRightColor) {
            triangles.push(
                <TriangleMemoized
                    key={`${id}-${BOTTOM_RIGHT}`}
                    corner={BOTTOM_RIGHT}
                    {...commonProps}
                />,
            );
        }
        if (topRightColor) {
            triangles.push(
                <TriangleMemoized
                    key={`${id}-${TOP_RIGHT}`}
                    corner={TOP_RIGHT}
                    {...commonProps}
                />,
            );
        }
        if (topLeftColor) {
            triangles.push(
                <TriangleMemoized
                    key={`${id}-${TOP_LEFT}`}
                    corner={TOP_LEFT}
                    {...commonProps}
                />,
            );
        }
        return triangles;
    };

    const getChipData = () => {
        const chipLabel = (reservationStatus === RESERVATION_STATUS.Reserved) ? reservationStatus
            : ((unitGateAccessStatus === GATE_STATUS.Locked.value) ? (localisable.gateLocked)
                : ((unitAvailabilityStatus === AVAILABILITY_STATUS.Unavailable.value) ? unitAvailabilityStatus : rentalStatus));

        const chipValue = (reservationStatus === RESERVATION_STATUS.Reserved) ? reservationStatus
            : ((unitGateAccessStatus === GATE_STATUS.Locked.value) ? (localisable.gateLocked)
                : ((unitAvailabilityStatus === AVAILABILITY_STATUS.Unavailable.value) ? unitAvailabilityStatus : rentalStatus));

        return {
            size: 'small',
            label: chipLabel,
            value: chipValue,
        };
    };

    const tooltipContent = () => (
        <Grid container direction="column" spacing={1}>
            <Grid item container justify="space-between">
                <Grid item>
                    <Typography variant="subtitle1">
                        {label}
                    </Typography>
                </Grid>
                <Grid item>
                    <Chip data={getChipData()} classes={{ root: classes.tooltipChipRoot }} />
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="subtitle2">
                    {localisable.unitType}
                </Typography>
            </Grid>
            <Grid item>
                {width} X {depth} ({categoryResult})
            </Grid>
            <Grid item container direction="row" spacing={5}>
                <Grid item direction="column">
                    <Grid item>
                        {
                            rentalStatus === RENTAL_STATUS.RENTED ? (
                                <Typography variant="subtitle2">
                                    {localisable.currentRate}
                                </Typography>
                            ) : (
                                <Typography variant="subtitle2">
                                    {localisable.standardRate}
                                </Typography>
                            )
                        }
                    </Grid>
                    <Grid item>
                        {
                            rentalStatus === RENTAL_STATUS.RENTED ? (
                                `$${currentRate}`
                            ) : (
                                `$${standardMonthlyRate}`
                            )
                        }
                    </Grid>
                </Grid>
                {
                    rentalStatus === RENTAL_STATUS.RENTED && (
                        <Grid item direction="column">
                            <Grid item>
                                <Typography variant="subtitle2">
                                    {localisable.nextBillingDate}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {nextRentAssessmentDate}
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
            {reservationStatus === RESERVATION_STATUS.Reserved && rentalStatus === RENTAL_STATUS.VACANT
            && (
                <>
                <Grid item>
                    <Typography variant="subtitle2">
                        {localisable.reservedBy}
                    </Typography>
                </Grid>
                <Grid item>
                    {reservedFirstName} {reservedLastName}
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        {localisable.reservationCode}
                    </Typography>
                </Grid>
                <Grid item>
                    {reservationCode}
                </Grid>
                </>
            )
            }
            {rentalStatus === RENTAL_STATUS.RENTED
            && (
                <Grid item container direction="row" spacing={5}>
                    <Grid item direction="column">
                        <Grid item>
                            <Typography variant="subtitle2">
                                {localisable.tenantName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {firstName}{lastName}
                        </Grid>
                    </Grid>
                    <Grid item direction="column">
                        <Grid item>
                            <Typography variant="subtitle2">
                                {localisable.tenantBalance}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {`$${tenantBalance}`}
                        </Grid>
                    </Grid>
                </Grid>
            )
            }
            {rentalStatus === RENTAL_STATUS.RENTED
            && (
<>
            <Grid item>
                <Typography variant="subtitle2">
                    {localisable.lastPaid}
                </Typography>
            </Grid>
            <Grid item>
                {(lastPaidDate && lastPaidAmount) ? (`${lastPaidDate} / $${lastPaidAmount}`) : '-'}
            </Grid>
</>
            )
            }
            <Grid item>
                <Typography variant="subtitle2">
                    {localisable.unitComments}
                </Typography>
            </Grid>
            <Grid item>
                {unitComments || EMPTY_FIELD_TEXT}
            </Grid>
        </Grid>
    );


    const renderEntityLabel = () => (
        <Grid
            className={classes.entityLabelContainer}
        >
            <Tooltip
                key={id}
                title={isEntityUnit ? tooltipContent() : label}
                open={isSearchedUnit}
                trackValue={!isSearchedUnit}
            >
                <Typography
                    variant="subtitle2"
                    className={classes.entityLabel}
                >
                    {label}
                </Typography>
            </Tooltip>
        </Grid>
    );

    return (
        <Grid
            key={id}
            className={clsx(classes.entity, { [classes.unitDoubleStrokeEffect]: isSearchedUnit && isEntityUnit })}
            {...isEntityUnit && { onClick: () => handleUnitClick(data) }}
        >
            {renderEntityLabel()}
            {isEntityUnit && renderTriangles()}
        </Grid>
    );
};

Entity.defaultProps = {
    isFacilityMigrated: false,
    handleUnitClick: EMPTY_FUNC,
    isSearchedUnit: false,
};

Entity.propTypes = {
    data: PropTypes.object,
    handleUnitClick: PropTypes.func,
    zoomValue: PropTypes.number,
    isFacilityMigrated: PropTypes.bool,
    isSearchedUnit: PropTypes.bool,
    unitTypeData: PropTypes.object,
    unitBevData: PropTypes.array,
    unitIdTenantMapper: PropTypes.object,
    unitIdReservationMapper: PropTypes.object,
};


const BevView = (props) => {
    const classes = useStyles();
    const {
        history,
        onAction,
        zoomValue,
        entities = [],
        canvasGridRef,
        setShowLoader,
        setSnackbarProps,
        isFacilityMigrated = false,
        currentFacility: { data: { id: fid } = {} } = {},
        currentAccountId: { data: { id: accountId } = {} } = {},
        unitBev: {
            data: {
                data: unitBevData = [],
                relationalData: { unitType: unitTypeData = {} } = {},
            } = {},
        } = {},
        scrollToIndex,
        unitIdTenantMapper = {},
        unitIdReservationMapper = {},
    } = props;

    const {
        clientWidth: containerWidth = 0,
        clientHeight: containerHeight = 0,
    } = canvasGridRef || {};

    const collectionRef = useRef(null);
    const EntityComponent = useConstructor(() => memo(Entity));

    useEffect(() => {
        const { current: collectionRefObj } = collectionRef;
        if (collectionRefObj) {
            collectionRefObj.recomputeCellSizesAndPositions();
        }
    }, [zoomValue]);

    const handleUnitClick = (unit) => {
        let state;

        const {
            id: unitId,
            label,
            rentalStatus,
            unitType: unitTypeId,
            availabilityStatus,
            bev: { color: { [BOARD.RENTAL_AVAILABILITY_STATUS]: { itemId: reservedColorItemId } = {} } = {} } = {},
        } = unit;

        if (availabilityStatus === VACANT_AVAILABILITY_STATUS.Unavailable.value) {
            history.push(buildUrl('unitEdit', { fid, id: unitId }, DASHBOARD_TYPE.EXTERNAL, accountId));
        } else if (rentalStatus === RENTAL_STATUS.VACANT) {
            const { value: { description } = {} } = unitTypeData[unitTypeId];
            const isUnitReserved = reservedColorItemId === RENTAL_AVAILABILITY_STATUS_BOARD_RESERVED_ITEM_ID;

            const url = buildUrl('moveIn', { fid }, DASHBOARD_TYPE.EXTERNAL, accountId);

            let detail = {
                id: unitId,
                label,
                rentalStatus,
                unitType: { id: unitTypeId, value: { description } },
            };

            if (isUnitReserved) {
                setShowLoader(true);
                fetchReservation(onAction, unitId, (_, response) => {
                    setShowLoader(false);
                    const { data: [{ id: reservationId, ...restReservationData } = {}] = [] } = response || {};
                    if (reservationId) {
                        detail = {
                            id: reservationId,
                            ...restReservationData,
                            unitType: detail.unitType,
                            unitId,
                            unit: {
                                id: unitId,
                                label,
                            },
                        };
                        state = {
                            activeStep: 0,
                            detail,
                            source: SOURCE.reservation.value,
                        };
                        history.push(url, state);
                    } else {
                        setSnackbarProps(true, localisable.reservationListEmpty, 'error');
                    }
                }, 1);
            } else {
                state = {
                    activeStep: 0,
                    detail,
                    source: SOURCE.unit.value,
                };
                history.push(url, state);
            }
        } else {
            setShowLoader(true);
            fetchTenant(onAction, unitId, (_, response) => {
                setShowLoader(false);
                const { data: [{ id } = {}] = [] } = response || {};
                if (id) {
                    history.push(buildUrl('tenantView', { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId));
                } else {
                    setSnackbarProps(true, localisable.tenantNotFound, 'error');
                }
            });
        }
    };

    // eslint-disable-next-line react/prop-types
    const addEntity = ({ index, key }) => {
        const { [index]: entityData = {} } = entities;
        const isSearchedUnit = index === scrollToIndex;

        return (
            <EntityComponent
                key={key}
                data={entityData}
                handleUnitClick={handleUnitClick}
                zoomValue={zoomValue}
                isFacilityMigrated={isFacilityMigrated}
                isSearchedUnit={isSearchedUnit}
                unitTypeData={unitTypeData}
                unitBevData={unitBevData}
                unitIdTenantMapper={unitIdTenantMapper}
                unitIdReservationMapper={unitIdReservationMapper}
            />
        );
    };

    const cellSizeAndPositionGetter = ({ index }) => {
        const { [index]: entity = {} } = entities;
        const {
            bev: {
                x = 0,
                y = 0,
                width: bevWidth = 0,
                depth: bevHeight = 0,
            } = {},
        } = entity;

        const {
            width,
            height,
        } = getConvertedWH(bevWidth, bevHeight, isFacilityMigrated);
        return {
            height: height * zoomValue,
            width: width * zoomValue,
            x: x * zoomValue,
            y: y * zoomValue,
        };
    };

    return (
        <Collection
            ref={collectionRef}
            cellCount={entities.length}
            cellRenderer={addEntity}
            scrollToCell={scrollToIndex}
            cellSizeAndPositionGetter={cellSizeAndPositionGetter}
            width={containerWidth}
            height={containerHeight}
            className={classes.collection}
        />
    );
};

BevView.propTypes = {
    entities: PropTypes.array,
    zoomValue: PropTypes.number,
    history: PropTypes.object.isRequired,
    onAction: PropTypes.func.isRequired,
    setSnackbarProps: PropTypes.func.isRequired,
    setShowLoader: PropTypes.func.isRequired,
    currentFacility: PropTypes.object.isRequired,
    currentAccountId: PropTypes.object,
    canvasGridRef: PropTypes.object,
    unitBev: PropTypes.object,
    isFacilityMigrated: PropTypes.bool,
    scrollToIndex: PropTypes.number,
    unitIdTenantMapper: PropTypes.object,
    unitIdReservationMapper: PropTypes.object,
};

BevView.defaultProps = {
    entities: [],
    unitBev: {},
    isFacilityMigrated: false,
};

export default BevView;
