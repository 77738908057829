import {
    FILTER_LABEL_LEFT_MARGIN,
    SHOW_MORE_BUTTON_RIGHT_MARGIN,
    SHOW_MORE_BUTTON_LEFT_MARGIN,
} from '../config/Constants';
import media from '../../../../theme/MediaQueries';

const AppliedFilterContainerStyle = theme => ({
    rootContainer: { background: theme.palette.common.white },
    normalContainer: {
        alignItems: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxHeight: 75,
        padding: '0 0 0 32px',
        flex: 1,
    },
    normalContainerInModal: {
        [media.mobile]: { padding: '0 8px' },
        [media.phablet]: { padding: '0 8px' },
        [media.tablet]: { padding: '0 8px' },
        [media.laptopUp]: { padding: '0 32px 10px 32px' },
    },
    noFiltersApplied: { margin: 0 },
    label: {
        fontSize: 16,
        fontWeight: 600,
        margin: FILTER_LABEL_LEFT_MARGIN,
        marginRight: 0,
        marginLeft: 0,
    },
    showMoreButton: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.secondary.main,
        alignSelf: 'center',
        marginRight: SHOW_MORE_BUTTON_RIGHT_MARGIN,
        marginLeft: SHOW_MORE_BUTTON_LEFT_MARGIN,
        cursor: 'pointer',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    container: {
        [media.mobile]: {
            marginLeft: '2px',
            marginTop: '2px',
        },
        [media.phablet]: {
            marginLeft: '2px',
            marginTop: '2px',
        },
        [media.tablet]: {
            marginLeft: '2px',
            marginTop: '2px',
        },
        [media.laptopUp]: {
            marginLeft: 16,
            marginTop: 16,
        },
    },
    mobileSectionHeader: { paddingBottom: 15, marginBottom: 16, borderBottom: `1px solid ${theme.palette.border}` },
    title: { '& span': { fontWeight: 'bolder' } },
    listItemContainer: { padding: 0 },
    noOfFiltersContainer: {
        width: '3px',
        height: '3px',
        display: 'inline',
        backgroundColor: theme.palette.common.secondary,
    },
    noOfFiltersText: {
        fontSize: '0.625rem',
        color: 'white',
        display: 'inline',
    },
    clearAllButton: { margin: 0 },
});

export default AppliedFilterContainerStyle;
