/* eslint-disable eqeqeq */
import { ENTITY_TYPE } from '../config/RoleSelectionConfig';

const roleUpdater = ({ data, color, type, currentRoleId, customRoleData, entityData }) => {
    Object.keys(data).forEach((id) => {
        if (data[id].roleId == currentRoleId) {
            customRoleData.permissions.push({
                ...(entityData[id]),
                color,
                type,
                roleId: currentRoleId,
            });
        }
    });
};

const preProcessPreviewData = ({ permissions, roles, facilityObject, accountObject, businessGroupObject }) => {
    const [{ permission: { account = {}, facility = {}, businessGroup = {} } = {} } = {}] = permissions;
    return roles.reduce((initialUserRoleData, role) => {
        const { id: currentRoleId, name } = role;
        const customRoleData = {
            id: currentRoleId,
            name,
            permissions: [],
        };
        roleUpdater({ data: account, color: 'rolesPrimary', type: ENTITY_TYPE.ACCOUNT, currentRoleId, customRoleData, entityData: accountObject });
        roleUpdater({ data: facility, color: 'rolesTertiary', type: ENTITY_TYPE.FACILITY, currentRoleId, customRoleData, entityData: facilityObject });
        roleUpdater({ data: businessGroup, color: 'rolesSecondary', type: ENTITY_TYPE.BUSINESSGROUP, currentRoleId, customRoleData, entityData: businessGroupObject });
        initialUserRoleData.push(customRoleData);
        return initialUserRoleData;
    }, []);
};

export default preProcessPreviewData;
