import { Grid, Loader, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { SOURCE, TABLE_ROW_HEIGHT_VARIANT, TIME_WINDOW } from 'Commons/config/constants/Constants';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import { DatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import SmartList from 'Commons/components/business/smartlist/components/SmartList';
import { memo, useEffect, useReducer, useState } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import Button from 'Generic/button/components/Button';
import Icon from 'Commons/components/generic/icon/components/Icon';
import ButtonText from 'Generic/buttontext/components/ButtonText';
import { VARIANT } from 'Generic/snackbar/config/Constants';
import { formatErrors } from 'Commons/redux/error/ErrorHandler';
import { addToDate, getCurrentDate } from 'Commons/helpers/utils/DateTime';
import { screenMaxWidth } from 'Commons/config/constants/ScreenWidths';
import { convertToNumber } from 'Commons/helpers/utils/Utils';
import { TextField } from 'Generic/textfield/components/TextField';
import { EXTRA_SMALL_TEXTFIELD_WIDTH, SMALL_TEXTFIELD_WIDTH } from 'External/containers/configuration/config/Constants';
import { Radio } from 'Generic/radio/components/Radio';
import Chip from 'Generic/chip/components/Chip';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import BillAheadStyle from '../styles/BillAheadStyle';
import getBillAheadColumns from '../config/BillAheadColumns';
import { billAhead, fetchLedgerBalance } from '../config/ApiRequests';
import {
    BILLS_TABLE_LEFT_AND_RIGHT_PADDING,
    BILLS_TABLE_MIN_HEIGHT,
    BILLS_TABLE_RESPONSIVE_HEIGHT,
    BILLS_TABLE_RESPONSIVE_WIDTH,
    BILLS_TABLE_TOP_AND_BOTTOM_PADDING,
    REDUCER_ACTION,
} from '../config/Constants';

const useStyles = makeStyles(BillAheadStyle, { name: 'BillAhead' });

const BillAhead = (props) => {
    const {
        paymentRef: { current: paymentRef } = {}, ledgerId, onAction, currency, clearStoreKeys,
        onClose, onLedgerBalance, setReFetchTenantBalance, setPaymentSnackbarProps, unitList,
    } = props;
    const { clientHeight = 500, clientWidth = 500 } = paymentRef || {};

    const classes = useStyles();

    const { inputClasses, chipClasses } = useConstructor(() => ({
        inputClasses: { className: classes.input },
        chipClasses: {
            root: classes.chip,
            label: classes.chipLabel,
        },
    }));

    const initialState = {
        arList: [],
        totalAmount: '--',
        rentAssessmentDate: undefined,
        gettingBillsSuccess: false,
        period: undefined,
        useEffectiveDate: false,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case REDUCER_ACTION.ON_GENERATE_BILLS: return { initialState };
            case REDUCER_ACTION.ON_GET_BILLS_SUCCESS: return {
                ...state,
                arList: action.charges,
                totalAmount: action.amount,
                gettingBillsSuccess: action.success,
            };
            case REDUCER_ACTION.PRE_GET_BILLS: return {
                ...state,
                arList: action.charges,
                totalAmount: action.amount,
                ...(state.useEffectiveDate
                    ? { rentAssessmentDate: action.effectiveDateOrPeriod }
                    : { period: action.effectiveDateOrPeriod }),
            };
            case REDUCER_ACTION.ON_GET_BILLS_ERROR: return {
                ...state,
                gettingBillsSuccess: action.success,
            };
            case REDUCER_ACTION.ON_PERIOD_TOGGLE: return {
                ...initialState,
                ...(state.useEffectiveDate ? { period: undefined } : { rentAssessmentDate: undefined }),
                useEffectiveDate: !state.useEffectiveDate,
            };
            default: return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const [isListLoading, setIsListLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const getMaxDate = () => addToDate(getCurrentDate(), 12, TIME_WINDOW.MONTHS);

    const onBillFetch = (apiError, response) => {
        if (apiError) {
            dispatch({ type: REDUCER_ACTION.ON_GET_BILLS_ERROR, success: false });
        } else if (response) {
            const { success = false, data: { charges = [], totalAmount: amount } = {} } = response;
            dispatch({
                type: REDUCER_ACTION.ON_GET_BILLS_SUCCESS,
                charges,
                amount,
                success,
            });
        }
        setIsListLoading(false);
    };

    const onBillGenerate = (apiError, response) => {
        if (apiError) {
            const formattedErrors = formatErrors(apiError);
            setPaymentSnackbarProps(true, formattedErrors.errorMessage, VARIANT.error.value);
        } else if (response) {
            setPaymentSnackbarProps(true, localisable.successfulBillGeneration, VARIANT.success.value);
            dispatch({ type: REDUCER_ACTION.ON_GENERATE_BILLS });
            setReFetchTenantBalance(true);
            fetchLedgerBalance(onAction, ledgerId, onLedgerBalance);
            clearStoreKeys([SOURCE.tenant.value]);
        }
        setIsButtonLoading(false);
    };

    const generateBills = () => {
        if (convertToNumber(state.totalAmount) > 0) {
            const body = {
                ...(state.useEffectiveDate ? { effectiveDate: state.rentAssessmentDate } : { period: state.period }),
                ledgerId,
                generate: true,
                totalAmount: state.totalAmount,
            };
            setIsButtonLoading(true);
            billAhead(onAction, body, onBillGenerate);
        }
    };

    const getBills = (effectiveDateOrPeriod, ledgerChange) => {
        if (state.rentAssessmentDate !== effectiveDateOrPeriod || (ledgerChange && state.rentAssessmentDate)) {
            dispatch({
                type: REDUCER_ACTION.PRE_GET_BILLS,
                charges: [],
                amount: '--',
                effectiveDateOrPeriod,
            });
            if (effectiveDateOrPeriod) {
                setIsListLoading(true);
                const body = {
                    ...(state.useEffectiveDate
                        ? { effectiveDate: effectiveDateOrPeriod }
                        : { period: effectiveDateOrPeriod }),
                    ledgerId,
                    generate: false,
                };
                billAhead(onAction, body, onBillFetch);
            }
        }
    };

    useEffect(() => {
        getBills(state.rentAssessmentDate, true);
    }, [ledgerId]);

    const getMinHeight = (height, heightToCompare) => (height < heightToCompare ? height : heightToCompare);

    const calculateTableWidth = () => {
        const { innerWidth, deviceInfo: { isDesktop } } = window;
        if (isDesktop) {
            const tableWidth = (clientWidth * 2) - BILLS_TABLE_LEFT_AND_RIGHT_PADDING;
            let tableHeight = BILLS_TABLE_MIN_HEIGHT;
            if (state.arList.length > 0) {
                const dataTableHeight = (state.arList.length + 1) * TABLE_ROW_HEIGHT_VARIANT.ONE_LINER;
                const modalHeight = clientHeight - (innerWidth <= screenMaxWidth.laptop ? BILLS_TABLE_TOP_AND_BOTTOM_PADDING.laptop : BILLS_TABLE_TOP_AND_BOTTOM_PADDING.desktop);
                tableHeight = getMinHeight(dataTableHeight, modalHeight);
            }
            return { tableWidth, tableHeight };
        }
        return { tableWidth: BILLS_TABLE_RESPONSIVE_WIDTH, tableHeight: BILLS_TABLE_RESPONSIVE_HEIGHT };
    };

    const getListConfig = () => {
        const { tableWidth, tableHeight } = calculateTableWidth();
        return {
            store: 'ar',
            columns: getBillAheadColumns(classes, currency),
            name: localisable.billAheadHeader,
            emptyBodyText: localisable.noBills,
            tableProps: {
                headerRowHeight: TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER,
                tableWidth,
                tableHeight,
                classes: {
                    firstColumn: classes.firstColumn,
                    lastColumn: classes.lastColumn,
                },
                rowDivider: false,
                leftAndRightPadding: 0,
            },
            getCustomData: () => state.arList,
        };
    };

    const onChangeOfEffectiveDate = (dateObj, formattedDate) => {
        // When date is not valid we send the same object in the formattedDate as well. If date and formattedDate are same, we can assume that the date is invalid.
        if (dateObj !== formattedDate) getBills(formattedDate, false);
    };

    const getLoader = () => (
        state.rentAssessmentDate
        && (
            <Grid container justify="center" alignItems="center" className={classes.loader}>
                <Loader disableShrink />
            </Grid>
        )
    );

    const getHeader = () => (
        <Grid container justify="space-between">
            <Typography variant="h6" noWrap>{localisable.billAhead}</Typography>
            <Button
                onClick={onClose}
                className={`${classes.closeButton}`}
                variant="icon"
                disableRipple
                icon="cp-close"
                type="button"
                iconType="custom"
                IconProps={{ className: classes.closeIcon }}
            />
        </Grid>
    );

    const getDateAndAmountSection = () => (
        <Grid container item className={clsx(classes.autoWidth, classes.unitsSection)} xs={6} wrap="nowrap">
            <Radio
                value={state.useEffectiveDate}
                trackValue={false}
                label={localisable.payTill}
                onChange={() => dispatch({ type: REDUCER_ACTION.ON_PERIOD_TOGGLE })}
            />
            <Grid item xs={6}>
                <DatePicker
                    value={state.rentAssessmentDate}
                    trackValue={false}
                    onChange={onChangeOfEffectiveDate}
                    maxDate={getMaxDate()}
                    minDate={getCurrentDate()}
                    width={SMALL_TEXTFIELD_WIDTH}
                    disabled={!state.useEffectiveDate}
                />
            </Grid>
        </Grid>
    );

    const getBillsTable = () => (
        <SmartList
            listConfig={getListConfig}
            {...props}
        />
    );

    const getErrorNessage = () => (
        <Grid container justify="center" alignItems="center" className={classes.info}>
            <Icon type="custom" icon="cp-info" />
            <Typography>{localisable.billAheadError}</Typography>
        </Grid>
    );

    const getFooter = () => (
        <Grid container justify="flex-end" className={classes.submit}>
            <Button
                variant="contained"
                disabled={!state.gettingBillsSuccess}
                color="secondary"
                onClick={generateBills}
            >
                <ButtonText
                    isButtonLoading={isButtonLoading}
                    loadingText={localisable.generating}
                    label={localisable.generateBills}
                />
            </Button>
        </Grid>
    );

    const getPeriodSection = () => (
        <Grid container item xs={6} className={classes.periodsSection} wrap="nowrap">
            <Radio
                value={!state.useEffectiveDate}
                trackValue={false}
                onChange={() => dispatch({ type: REDUCER_ACTION.ON_PERIOD_TOGGLE })}
            />
            <TextField
                type="number"
                width={EXTRA_SMALL_TEXTFIELD_WIDTH}
                inputProps={inputClasses}
                additionalIcon={{
                    left: (
                        <Typography variant="body1">
                            {localisable.payAheadFor}
                        </Typography>
                    ),
                    right: (
                        <Typography variant="body1">
                            {localisable.periods.toLowerCase()}
                        </Typography>
                    ),
                }}
                disabled={state.useEffectiveDate}
                onChange={(_, value) => getBills(value, false)}
                value={state.period}
                trackValue={false}
            />
        </Grid>
    );

    const getUnits = () => (
        <Grid container justify="space-between" className={classes.dateAndAmountSection}>
            <Grid container item xs={6}>
                <Typography>
                    {localisable.units}
                </Typography>
                <Grid container className={classes.units} spacing={2}>
                    {
                        unitList.map(({ label }) => (
                            <Grid item>
                                <Chip
                                    key={label}
                                    classes={chipClasses}
                                    data={{
                                        color: 'secondary',
                                        size: 'medium',
                                        label,
                                        value: label,
                                    }}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid
                container
                item
                justify="flex-end"
                className={clsx(classes.autoWidth, classes.totalAmountSection)}
                spacing={2}
                xs={6}
            >
                <Grid item>
                    <Typography variant="h6" noWrap>{localisable.amountToBePaid}</Typography>
                </Grid>
                <Grid container item className={classes.autoWidth}>
                    <Icon type="custom" icon="cp-dollar" className={classes.dollarSymbol} />
                    <Typography variant="h6" noWrap>{state.totalAmount}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid className={classes.billAhead}>
            {getHeader()}
            {getUnits()}
            {getPeriodSection()}
            {getDateAndAmountSection()}
            {!isListLoading && (state.rentAssessmentDate || state.period)
                ? getBillsTable()
                : getLoader()
            }
            {!state.gettingBillsSuccess && (state.rentAssessmentDate || state.period) && !isListLoading
                && getErrorNessage()
            }
            {(state.rentAssessmentDate || state.period) && state.arList.length > 0
                && getFooter()
            }
        </Grid>
    );
};

BillAhead.propTypes = {
    paymentRef: PropTypes.object,
    parentSize: PropTypes.shape({ current: PropTypes.object }),
    ledgerId: PropTypes.string,
    onAction: PropTypes.func,
    onClose: PropTypes.func,
    onLedgerBalance: PropTypes.func,
    setReFetchTenantBalance: PropTypes.func,
    setPaymentSnackbarProps: PropTypes.func,
    currency: PropTypes.string,
    clearStoreKeys: PropTypes.func.isRequired,
    unitList: PropTypes.array,
};

export default memo(BillAhead);
