import { deepCopy } from 'Commons/helpers/utils/DeepCopy';

const doesValueExists = (initialValues, key) => {
    let currentTarget = deepCopy(initialValues);
    const nameArray = key.split('.');
    for (let i = 0; i < nameArray.length; i += 1) {
        if (nameArray[i] in currentTarget) {
            currentTarget = currentTarget[nameArray[i]];
        } else {
            return false;
        }
    }
    return true;
};

const isFalsyValue = value => ['', undefined, null].includes(value);

export const getFieldValue = (initialValues, name, value) => {
    if (isFalsyValue(value) && !doesValueExists(initialValues, name)) {
        return undefined;
    }

    const nameArray = name.split('.');
    let current = deepCopy(initialValues);
    for (let i = 0; i < nameArray.length; i += 1) {
        if (nameArray[i] in current) {
            current = current[nameArray[i]];
        } else {
            return value;
        }
    }
    // Using == as we don't need strict comparison. Works in case BE sends integer and we store string in the form values
    // eslint-disable-next-line eqeqeq
    if (current == value) {
        return current;
    }
    return value;
};
