import { memo, useEffect, useMemo, useRef, useState } from 'react';
import Typography from 'Generic/typography/components/Typography';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Chip from 'Generic/chip/components/Chip';
import Tooltip from 'Commons/components/generic/tooltip/components/Tooltip';
import {
    SearchableDropdown,
    SmartSearchableDropdown,
} from 'Commons/components/generic/dropdown/components/SearchableDropdown';
import { Dropdown } from 'Generic/dropdown/components/Dropdown';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import multiSelectStyle from '../styles/MultiSelectStyle';
import Icon from '../../icon/components/Icon';

const useStyles = makeStyles(multiSelectStyle, { name: 'MultiSelect' });

const MultiSelect = (props) => {
    const {
        component: Component, onSelect, onRemove, initiallySelectedValues = [], selectedValuesInitialState = {},
        componentContainerLabel, chipContainerLabel, classes: ignore, showComponent, emptyValueMessage, containerProps,
        allowDelete, chipProps, tooltipProps, disabled, showRequiredIcon, ...componentProps
    } = props;
    const { list, apiConfig = {} } = props;
    const [selectedValues, setSelectedValues] = useState(selectedValuesInitialState);
    const [selectedValue, setSelectedValue] = useState();
    const classes = useStyles(props);
    const mappedListData = useRef({});
    const isOfflineSearchableDropdown = useMemo(() => !isObjWithKeys(apiConfig),
        [JSON.stringify(apiConfig)]);
    const searchableDropdownProps = useConstructor(() => ({
        inputValueFormatter: () => '',
        keyToMatch: 'id',
        options: isOfflineSearchableDropdown ? list : [],
    }));

    useEffect(() => {
        if (list.length) {
            const updatedData = {};
            list.forEach(({ value, ...data }) => {
                updatedData[value] = { value, ...data };
            });
            mappedListData.current = updatedData;
        }
        searchableDropdownProps.options = isOfflineSearchableDropdown ? list : [];
    }, [list]);

    useEffect(() => {
        if (initiallySelectedValues.length && list.length) {
            const { current: listData } = mappedListData;
            const mappedInitiallySelectedValues = {};
            initiallySelectedValues.forEach((value) => {
                mappedInitiallySelectedValues[value] = listData[value];
            });
            setSelectedValues(mappedInitiallySelectedValues);
        }
        if (!initiallySelectedValues.length) { // To clear the existing chips
            setSelectedValues({});
        }
    }, [initiallySelectedValues]);

    const onSelectValue = (_, value = '', data) => {
        if (value !== '' && value !== null) { // So that it doesn't fail for 0 or false (If in case)
            const updatedSelectedValues = { ...selectedValues, [value]: data || mappedListData.current[value] };
            setSelectedValues(updatedSelectedValues);
            setSelectedValue(data ? value : '');
            if (onSelect) {
                onSelect(updatedSelectedValues);
            }
        }
    };

    const onRemoveChip = (_, __, { value: idToRemove }) => {
        const updatedSelectedValues = { ...selectedValues };
        delete updatedSelectedValues[idToRemove];
        setSelectedValues(updatedSelectedValues);
        setSelectedValue('');
        if (onRemove) {
            onRemove(updatedSelectedValues);
        }
    };

    const renderInputComponent = () => (
        <Component
            {...componentProps}
            value={selectedValue}
            onChange={onSelectValue}
            {...Component !== Dropdown && searchableDropdownProps}
            trackValue={false}
            disabled={disabled}
        />
    );

    return (
        <Grid container item direction="column" className={classes.container} {...containerProps}>
            {componentContainerLabel && showComponent
                && (
                    <Typography variant="subtitle1" className={classes.componentContainerLabel}>
                        {componentContainerLabel}
                    </Typography>
                )
            }
            {showComponent
                && (
                    showRequiredIcon
                        ? (
                            <Grid container direction="row" className={classes.InputContainer}>
                                <Grid
                                    container
                                    className={classes.indicatorWrapper}
                                    wrap="nowrap"
                                >
                                    <Icon
                                        type="custom"
                                        display="inline-block"
                                        className={classes.indicator}
                                        icon="cp-asterisk"
                                    />
                                </Grid>
                                {renderInputComponent()}
                            </Grid>
                        ) : renderInputComponent()
                )
            }
            {chipContainerLabel
                && (
                    <Typography variant="subtitle2" className={classes.chipContainerLabel}>
                        {chipContainerLabel}
                    </Typography>
                )
            }
            <Grid container item className={classes.chipWrapper} spacing={2}>
                {isObjWithKeys(selectedValues)
                    && Object.values(selectedValues).map(({ id, label = '', value } = {}) => (
                        <Grid item key={id || value} className={classes.tooltipContainer}>
                            <Tooltip
                                placement="bottom"
                                event="onHover"
                                interactive={false}
                                className={`${classes.ellipsis} ${disabled ? classes.tooltipDisabled : ''}`}
                                title={(
                                    <Typography
                                        noWrap
                                        variant="body1"
                                        className={classes.tooltipLabel}
                                    >
                                        {label.trim()}
                                    </Typography>
                                )}
                                {...tooltipProps}
                            >
                                <Chip
                                    key={id || value}
                                    onDelete={allowDelete ? onRemoveChip : undefined}
                                    classes={{
                                        root: classes.chip,
                                        label: classes.chipLabel,
                                    }}
                                    data={{
                                        color: 'light',
                                        size: 'medium',
                                        label,
                                        value: id || value,
                                    }}
                                    disabled={disabled}
                                    {...chipProps}
                                />
                            </Tooltip>
                        </Grid>
                    ))
                }
                {!isObjWithKeys(selectedValues) && emptyValueMessage
                    && (
                        <Typography
                            variant="body1"
                            fontFamily="Open Sans"
                            fontStyle="italic"
                            disabled
                            className={classes.emptyValueMessage}
                        >
                            {emptyValueMessage}
                        </Typography>
                    )
                }
            </Grid>
        </Grid>
    );
};

MultiSelect.propTypes = {
    component: PropTypes.oneOf([SmartSearchableDropdown, SearchableDropdown, Dropdown]),
    list: PropTypes.array,
    options: PropTypes.array,
    onSelect: PropTypes.func,
    onRemove: PropTypes.func,
    initiallySelectedValues: PropTypes.array.isRequired,
    componentContainerLabel: PropTypes.string,
    chipContainerLabel: PropTypes.string,
    classes: PropTypes.object,
    emptyValueMessage: PropTypes.string,
    containerProps: PropTypes.object,
    showComponent: PropTypes.bool,
    allowDelete: PropTypes.bool,
    chipProps: PropTypes.object,
    tooltipProps: PropTypes.object,
    disabled: PropTypes.bool,
    showRequiredIcon: PropTypes.bool,
    selectedValuesInitialState: PropTypes.object,
    // Props below this line and all other props will be passed to the Component
    apiConfig: PropTypes.object,
};

MultiSelect.defaultProps = {
    list: [],
    options: [],
    component: SmartSearchableDropdown,
    componentContainerLabel: null,
    chipContainerLabel: null,
    classes: {},
    containerProps: {},
    showComponent: true,
    allowDelete: true,
    chipProps: {},
    tooltipProps: {},
    showRequiredIcon: false,
};

export default memo(MultiSelect);
