import { createMuiTheme } from '../components/generic/componentlibrary/components/Components';
import palette from './ColorPalette';
import media from './MediaQueries';
import HEX_OPACITY from './styles/HexOpacity';
import { screenMinWidth } from '../config/constants/ScreenWidths';

const Theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: screenMinWidth.phablet,
            md: screenMinWidth.tablet,
            lg: screenMinWidth.laptop,
            xl: screenMinWidth.desktop,
        },
    },
    palette: { ...palette },
    typography: {
        fontFamily: 'Roboto',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        h2: {
            letterSpacing: 0.15,
            fontFamily: 'Open Sans',
            lineHeight: 1.5,
            fontSize: '1.5rem',
            [media.desktop]: { fontSize: '2rem' },
        },
        h3: {
            letterSpacing: '0.75px',
            lineHeight: 1.5,
            fontSize: '1.25rem',
            [media.desktop]: { fontSize: '1.5rem' },
        },
        h4: {
            letterSpacing: 0.15,
            lineHeight: 1.5,
            fontSize: '1rem',
            [media.tabletUp]: { fontSize: '1.25rem' },
        },
        h5: {
            letterSpacing: '0.5px',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            lineHeight: 1.5,
            fontSize: '1.125rem',
            [media.laptopUp]: {
                fontSize: '1.125rem',
                letterSpacing: '0.75px',
            },
            [media.desktopUp]: { fontSize: '1.5rem' },
        },
        h6: {
            letterSpacing: '0.5px',
            fontFamily: 'Open Sans',
            lineHeight: 1.5,
            fontSize: '1rem',
            [media.desktop]: {
                fontSize: '1.125rem',
                letterSpacing: '0.75px',
            },
            [media.desktopUp]: { fontSize: '1.25rem' },
        },
        caption: {
            fontFamily: 'Open Sans',
            lineHeight: 1.5,
            fontSize: '0.625rem',
            [media.desktop]: { fontSize: '0.75rem' },
        },
        subtitle1: {
            letterSpacing: '0.5px',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            lineHeight: 1.5,
            fontSize: '0.875rem',
            [media.desktopUp]: {
                fontSize: '1rem',
                letterSpacing: '0.75px',
            },
        },
        subtitle2: {
            letterSpacing: '0.5px',
            lineHeight: 1.5,
            fontSize: '0.75rem',
            [media.desktopUp]: {
                fontSize: '0.875rem',
                letterSpacing: '0.75px',
            },
        },
        body1: {
            lineHeight: 1.25,
            fontSize: '0.75rem',
            [media.desktop]: { fontSize: '0.875rem' },
            [media.desktopUp]: { fontSize: '1rem' },
        },
        body2: {
            lineHeight: 1.5,
            fontSize: '0.75rem',
            [media.desktop]: { fontSize: '0.875rem' },
        },
    },
    elements: {
        input: {
            fontSize: '1rem',
            label: {
                fontSize: '0.875rem',
                color: palette.text.primary,
            },
            helperText: '0.75rem',
            placeholder: palette.text.hint,
            color: palette.text.secondary,
            activeOrSelected: palette.primary.main,
        },
        button: {
            fontSize: '0.75rem',
            [media.desktop]: { fontSize: '0.875rem' },
            [media.desktopUp]: { fontSize: '1rem' },
        },
        buttonOutlined: {
            fontSize: '0.75rem',
            [media.desktop]: { fontSize: '0.875rem' },
            [media.desktopUp]: { fontSize: '1rem' },
            height: '42px',
            border: '1px',
        },
        tooltip: { fontSize: '0.875rem' },
        link: {
            color: palette.common.black,
            fontWeight: 600,
            hover: palette.primary.main,
            disabled: palette.disabled,
        },
    },
    components: {
        globalSearch: {
            general: {
                color: palette.text.secondary,
                fontSize: '0.875rem',
            },
            tenantCard: {
                name: {
                    fontSize: '1rem',
                    fontWeight: 600,
                },
                rightLabel: {
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    textAlign: 'right',
                },
                badge: {
                    height: '20px',
                    backgroundColor: '#52ADC8',
                    color: palette.common.white,
                    fontSize: '0.75rem',
                    textAlign: 'center',
                },
            },
            leadCard: {
                Name: {
                    fontSize: '1rem',
                    fontWeight: 600,
                },
                rightLabel: {
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    textAlign: 'right',
                },
                badge: {
                    height: '20px',
                    backgroundColor: '#387CA3',
                    color: palette.common.white,
                    fontSize: '0.75rem',
                    textAlign: 'center',
                },
            },
            unitCard: {
                name: {
                    fontSize: '1rem',
                    fontWeight: 600,
                },
                leftLabel: {
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    textAlign: 'left',
                },
                badge: {
                    height: '20px',
                    backgroundColor: '#55C39E',
                    color: palette.common.white,
                    fontSize: '0.75rem',
                    textAlign: 'center',
                },
            },
            helperText: {
                color: palette.text.secondary,
                fontSize: '0.875rem',
                textAlign: 'center',
            },
        },
        topNavAction: {
            disabled: palette.disabled,
            activeLink: palette.text.secondary,
            hover: palette.common.black,
        },
        sideNav: {
            backgroundColor: palette.secondary.main,
            color: palette.common.white,
        },
        dashboard: {
            sideBar: {
                contentColor: palette.common.white,
                contentColoronHover: palette.secondary.main,
                sidePanel: '#293949',
                backgroundColor: palette.secondary.main,
                highlight: palette.highlight,
                lineSeperator: 'rgba(204,216,226,0.1)',
                footerContent: '#525354',
            },
        },
        overlay: {
            height: '90vh',
            width: '96vw',
            header: { minHeight: '72px' },
            actionItems: { height: '64px' },
        },
    },
    globalClasses: {
        childrenPadding: {
            className: 'Section-childrenPadding',
            style: {
                '& > :nth-child(1)': { paddingRight: 120 },
                '& > :nth-child(2)': { paddingLeft: 120 },
            },
        },
        overflow: { className: 'overflow', style: {} },
        visible: { className: 'visible', style: {} },
        overflowVisible: {
            className: 'overflow.visible',
            style: { overflow: 'visible' },
        },
        inner: {
            className: 'inner',
            style: {},
        },
        subsectionPadding: {
            className: 'subsectionPadding',
            style: {},
        },
        innerSubsectionPadding: {
            className: 'inner.subsectionPadding',
            style: {
                padding: 0,
                [media.laptopUp]: { paddingLeft: 132 },
            },
        },
        subsectionChildrenPadding: {
            className: 'subsectionChildrenPadding',
            style: {
                '& > :nth-child(1)': { paddingRight: 50 },
                '& > :nth-child(2)': { paddingLeft: 50 },
            },
        },
        multilineEllipsis: {
            className: 'multilineEllipsis',
            style: {
                overflow: 'hidden',
                display: 'inline-box',
                boxOrient: 'vertical',
                lineClamp: 2,
                '@supports not (-webkit-line-clamp: 2)': {
                    display: 'block',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
            },
        },
        deactivatedOverlay: {
            className: 'deactivateOverlay',
            style: {
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: palette.common.white,
                opacity: 0.8,
                zIndex: 100,
            },
        },
        deactivateConfirmationFooter: {
            className: 'deactivateConfirmationFooter',
            style: { zIndex: 101 },
        },
    },
    spacing: value => value * 8,
    hexWithOpacity: (hexValue, opacity) => hexValue + HEX_OPACITY[opacity],
    boxShadows: [
        '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
        '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
        '0 -2px 6px 0 rgba(0, 0, 0, 0.16)',
        '-3px 10px 10px -10px rgba(0, 0, 0, 0.16)',
        '0px 4px 8px rgba(0, 0, 0, 0.25)',
        '0px 4px 6px rgba(0, 0, 0, 0.3)',
        '0px 1px 8px rgba(0, 0, 0, 0.1)',
        '0px 2px 4px rgba(0, 0, 0, 0.12)',
        '0px 4px 4px rgba(0, 0, 0, 0.25)',
        '0px 2px 2px rgba(0, 0, 0, 0.3)',
        '1px 1px 8px 0px rgba(0, 0, 0, 0.25)',
    ],
    separator: { color: 'rgba(240,240,240,1)' },
    overrides: {
        MuiFormLabel: {
            root: {
                color: palette.text.primary,
                '&$focused': { color: palette.primary.main },
                fontFamily: 'Open Sans',
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: -8,
                marginRight: 24,
                width: 'fit-content',
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '0.625rem',
                [media.desktopUp]: { fontSize: '0.75rem' },
            },
        },
        PrivateSwitchBase: { root: { padding: 4, [media.desktop]: { padding: 8 } } },
        MuiIconButton: { root: { padding: 0 } },
        MuiButton: {
            root: {
                minWidth: 80,
                minHeight: 30,
                [media.desktop]: {
                    minWidth: 88,
                    minHeight: 32,
                },
                [media.desktopUp]: {
                    minWidth: 96,
                    minHeight: 36,
                },
                textTransform: 'none',
                fontWeight: 'normal',
                padding: '7px 9px', // Considering 1px for the border
                lineHeight: 1,
            },
            outlined: {
                padding: '7px 9px', // Considering 1px for the border, effective padding = '8px 10px'
                marginRight: '10px',
                [media.tabletUp]: { marginRight: '20px' },
            },
            outlinedPrimary: {
                border: `1px solid ${palette.primary.main}`,
                '&:hover': {
                    color: palette.common.white,
                    backgroundColor: palette.primary.main,
                },
            },
            outlinedSecondary: {
                border: `1px solid ${palette.secondary.main}`,
                '&:hover': {
                    color: palette.common.white,
                    backgroundColor: palette.secondary.dark,
                },
            },
            contained: {
                '&$disabled': {
                    backgroundColor: palette.disabled,
                    color: palette.common.white,
                },
            },
            textPrimary: {
                backgroundColor: `${palette.highlight}80`,
                '&:hover': {
                    backgroundColor: palette.button.hover,
                    '@media (hover: none)': { backgroundColor: `${palette.highlight}80` },
                },
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: '54.7px',
            },
        },
        MuiPickersModal: { dialog: { minHeight: 'auto' } },
        MuiPickersToolbarText: {
            toolbarTxt: {
                fontSize: 19,
                fontWeight: 600,
            },
        },
        MuiPickersYear: {
            root: { height: 40 },
            yearSelected: {
                fontSize: '1.5rem',
                fontWeight: 600,
            },
        },
        MuiPickersDay: { day: { fontWeight: 'normal' } },
        MuiInputAdornment: { root: { height: 'auto' } },
        MuiInput: {
            underline: {
                '&$disabled:before': {
                    borderBottomStyle: 'solid',
                    borderBottomColor: palette.text.disabled,
                },
                '&:before': { borderBottomColor: palette.black[600] },
                '&:after': { borderBottom: `2px solid ${palette.primary.main}` },
            },
        },
        MuiInputBase: {
            input: {
                overflow: 'visible',
                textOverflow: 'initial',
                padding: '6px 0px 4px 0px',
                height: 20,
                fontSize: '0.75rem',
                [media.desktop]: { fontSize: '0.875rem', padding: '12px 0px 8px 0px' },
                [media.desktopUp]: { fontSize: '1rem' },
            },
            multiline: { padding: '12px 0 0' },
        },
        MuiAvatar: {
            root: {
                width: 36,
                height: 36,
            },
        },
        MuiTreeItem: {
            root: { '&:focus > $content': { backgroundColor: palette.highlight } },
            content: { '&:hover': { backgroundColor: palette.hover } },
        },
    },
    props: { MuiButtonBase: { disableRipple: true } },
});

export default Theme;
