import media from 'Commons/theme/MediaQueries';

const SummaryTableStyle = theme => ({
    header: { padding: theme.spacing(0, 4) },
    singleTableHeader: { [media.laptopUp]: { padding: theme.spacing(0), width: '50%' } },
    doubleTableHeader: { [media.laptopUp]: { padding: theme.spacing(0), width: '70%' } },
    headerText: {},
    autoWidth: { width: 'auto' },
    table: {
        width: 'auto',
        borderWidth: 2,
        borderColor: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        [media.mobileUp]: { marginTop: theme.spacing(0) },
        [media.tabletUp]: { marginTop: theme.spacing(3) },
        [media.laptop]: { marginTop: theme.spacing(1.5) },
    },
    columnHeader: {
        cursor: 'text',
        '&$rightAlign': { paddingRight: 0 },
        '&$headerText': { fontWeight: '1rem' },
        '&$leftCell': { alignItems: 'center', paddingLeft: theme.spacing(1) },
        '&$date': { alignItems: 'center' },
        '&$totalBalance': { paddingRight: theme.spacing(1) },
    },
    totalBalance: {},
    rightAlign: {},
    date: {},
    leftCell: {},
    tableGrid: { '&$noPadding': { padding: '0px !important' } },
    noPadding: {},
});

export default SummaryTableStyle;
