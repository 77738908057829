import { Typography, Grid, makeStyles }
    from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import localisable from 'Commons/config/strings/localisable';
import { DELETE_OVERLAY_ACTION, DELETE_OVERLAY_DELETION_STATUS } from 'Commons/config/constants/Constants';
import deleteOverlayStyle from '../styles/DeleteOverlayStyle';

const useStyles = makeStyles(deleteOverlayStyle, { name: 'DeleteOverlay' });

const DeleteOverlay = ({ classes: classesProp, onDelete, currentKey, deleteStatus }) => {
    const isNew = deleteStatus === DELETE_OVERLAY_DELETION_STATUS.NEW;
    const classes = useStyles({ classes: classesProp });
    return (
        <div className={classes.deletionOverlay}>
            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                justify="center"
                direction="column"
                className={classes.overlayMessage}
            >
                <Grid item>
                    <Typography
                        variant="body1"
                        noWrap
                    >
                        {
                            isNew
                                ? localisable.markedForDeletionNew
                                : localisable.markedForDeletionExisting
                        }
                    </Typography>
                </Grid>
                <Grid container justify="center">
                    { isNew && (
                        <Grid item>
                            <Button onClick={() => onDelete(currentKey, DELETE_OVERLAY_ACTION.OK)}>
                                <Typography variant="subtitle2" className={classes.overlayMessageOk}>
                                    {localisable.ok}
                                </Typography>
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <Button onClick={() => onDelete(currentKey, DELETE_OVERLAY_ACTION.UNDO)}>
                            <Typography variant="subtitle2" className={classes.overlayMessageCancelStart}>
                                {isNew ? localisable.exit : localisable.undo}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    );
};

export default React.memo(DeleteOverlay);

DeleteOverlay.propTypes = {
    classes: PropTypes.object,
    onDelete: PropTypes.func,
    currentKey: PropTypes.any,
    deleteStatus: PropTypes.string,
};

DeleteOverlay.defaultProps = { deleteStatus: DELETE_OVERLAY_DELETION_STATUS.EXISTING };
