const TenantFilterStyle = theme => ({
    firstLabel: { paddingBottom: '16px' },
    restLabel: {
        paddingTop: '32px',
        paddingBottom: '16px',
    },
    withoutTokenCheckbox: { marginLeft: theme.spacing(4) },
});

export default TenantFilterStyle;
