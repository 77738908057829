import { getValue } from 'Commons/helpers/utils/DataHelpers';
import { TABLE_ROW_HEIGHT_VARIANT } from 'Commons/config/constants/Constants';


const getColumnKeys = columns => columns.map(column => column.key);

const dataConverter = (columns, rowData) => {
    const columnKeys = getColumnKeys(columns);
    return rowData.map(row => columnKeys.map(key => getValue(row, key)));
};

const getGridHeightValues = (gridContainerHeight, gridContentHeight, horizontalScrollSize) => {
    let newGridContainerHeight = gridContainerHeight;
    if (newGridContainerHeight > gridContentHeight + horizontalScrollSize && gridContentHeight !== 0) {
        newGridContainerHeight = gridContentHeight + horizontalScrollSize;
    }
    return newGridContainerHeight;
};

const getHeaderVariantMapping = isResponsive => ({
    [TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER]: isResponsive ? TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER_RESPONSIVE
        : TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER,
    [TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER]: isResponsive ? TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER_RESPONSIVE
        : TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER,
});

export { dataConverter, getGridHeightValues, getHeaderVariantMapping };
