const buttonStyle = theme => ({
    button: { ...theme.elements.button },
    displayNone: { display: 'none' },
    error: {
        color: theme.palette.button.error,
        borderColor: theme.palette.button.error,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.button.error,
        },
    },
    backgroundForErrorText: { backgroundColor: theme.palette.error.light },
    light: {
        backgroundColor: 'transparent',
        '&:hover': { backgroundColor: theme.hexWithOpacity(theme.palette.highlight, 3) },
    },
    textPrimary: { color: theme.palette.text.primary },
    disabled: {},
});

export default buttonStyle;
