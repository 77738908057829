import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const PHONE_FIELDS = createEnumMap([
    'countryCode',
    'areaCode',
    'country',
    'number'], key => key.replace(/[A-Z]/g, match => `_${match}`).toUpperCase());

const PREFERRED_COUNTRIES_LIST = ['us', 'ca'];

export { PHONE_FIELDS, PREFERRED_COUNTRIES_LIST };
