const baseUserRoleStyle = theme => ({
    header: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: '16px 32px',
    },
    flexOne: { flex: '1 1 1px' },
    sideBar: {
        marginTop: theme.spacing(0),
        padding: theme.spacing(2),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        borderRadius: 4,
        size: 'small',
    },
    sideBarContainer: {
        backgroundColor: theme.palette.common.white,
        flexWrap: 'nowrap',
        overflow: 'auto',
    },
    mainTab: {
        marginTop: theme.spacing(0),
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        padding: '16px 32px',
        paddingRight: theme.spacing(1),
        borderRadius: 4,
        overflow: 'auto',
        flexWrap: 'nowrap',
    },
    pageBody: { flexWrap: 'nowrap', backgroundColor: 'transparent', padding: 0 },
    form: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    buttons: {
        padding: '18px 16px',
        justifyContent: 'left',
        lineHeight: '0.1px',
        letterSpacing: '0.25px',
        fontStyle: 'normal',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 'medium',

    },
    sideBarIcons: {
        display: 'grid-row',
        flexFlow: 'row-reverse wrap',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        flexWrap: 'nowrap',
        overflow: 'hidden',
    },
    searchBar: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        top: -10,
        flexWrap: 'nowrap',
        overflow: 'hidden',
    },
    tabIndicator: { backgroundColor: theme.palette.primary.main },
    statusButton: { marginLeft: theme.spacing(4) },
    tabPanel: {
        paddingLeft: theme.spacing(0),
        overflow: 'auto',
        flexWrap: 'nowrap',
        marginTop: theme.spacing(4),
        overflowY: 'overlay',
    },
    nameTextField: { fontFamily: 'Open Sans', fontSize: 24, fontWeight: 600 },
    createIcon: { paddingLeft: theme.spacing(1), color: theme.palette.primary.main },
    tabsContainer: { paddingTop: theme.spacing(4) },
    indicatorWrapper: { display: 'none' },
    selected: { backgroundColor: theme.hexWithOpacity(theme.palette.primary.main, 2) },
    loader: { height: '100%' },
});

export default baseUserRoleStyle;
