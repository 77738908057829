import { mapEnum } from 'Commons/helpers/utils/Formatter';
import enums from './Enums';

const RENTAL_STATUS = mapEnum(enums.rentalStatus);
const AVAILABILITY_STATUS = mapEnum(enums.availabilityStatus);
const UNIT_TYPES = mapEnum(enums.categories);
const INVENTORY_REPORT_STATUS = [
    {
        label: 'Show',
        value: 'true',
    },
    {
        label: 'Hide',
        value: 'false',
    },
];


export { RENTAL_STATUS, AVAILABILITY_STATUS, UNIT_TYPES, INVENTORY_REPORT_STATUS };
