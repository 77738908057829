
import media from 'Commons/theme/MediaQueries';

const nameStyles = {
    spacingFormGrid: {
        '&$salutation': {
            [media.phabletUp]: {
                flexGrow: 0,
                maxWidth: '18%',
                flexBasis: '18%',
            },
        },
        '&$name': {
            [media.phabletUp]: {
                flexGrow: 0,
                maxWidth: '32.6%',
                flexBasis: '32.6%',
            },
        },
    },
    salutation: {},
    name: {},
    order: { order: 1 },
};

export default nameStyles;
