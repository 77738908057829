import media from 'Commons/theme/MediaQueries';

const formStyle = theme => ({
    formControl: {},
    error: {},
    errorTextPadding: { paddingLeft: theme.spacing(2) },
    noPadding: { '&$item': { padding: 0 } },
    noLeftRightPadding: {
        '&$item': {
            padding: theme.spacing(2, 0),
            [media.laptopUp]: { padding: theme.spacing(1.5, 0) },
            [media.desktop]: { padding: theme.spacing(2, 0) },
        },
    },
    firstInlineField: {
        '&$item': {
            padding: theme.spacing(2, 2, 0, 2),
            [media.laptopUp]: { padding: theme.spacing(1.5, 0, 0, 2) },
            [media.desktop]: { padding: theme.spacing(2, 2, 0, 2) },
        },
    },
    lastInlineField: {
        '&$item': {
            padding: theme.spacing(0, 2, 2, 2),
            [media.laptopUp]: { padding: theme.spacing(0, 0, 1.5, 2) },
            [media.desktop]: { padding: theme.spacing(0, 2, 2, 2) },
        },
    },
    inlineField: {
        '&$item': {
            padding: theme.spacing(0, 2),
            [media.laptopUp]: { padding: theme.spacing(0, 0, 0, 2) },
            [media.desktop]: { padding: theme.spacing(0, 2) },
        },
    },
    spacingFormGrid: {
        '&$item': {
            padding: theme.spacing(2),
            [media.laptopUp]: { padding: theme.spacing(1.5, 0, 1.5, 2) },
            [media.desktop]: { padding: theme.spacing(2) },
        },
    },
    helperText: {},
    item: {},
    formComponent: {},

    indicatorWrapper: {
        minWidth: theme.spacing(2),
        minHeight: 0,
        maxWidth: theme.spacing(2),
    },
    padding: { paddingTop: 3 },
    indicator: {
        fontSize: 8,
        [media.laptop]: { fontSize: 6 },
        [media.desktop]: { fontSize: 8 },
        cursor: 'default',
        lineHeight: 2,
    },
    required: { color: theme.palette.error.main },
    preferred: { color: theme.palette.preferred },
    hidden: { display: 'none' },
});

export default formStyle;
