import { FormTextField } from 'Generic/textfield/components/TextField';
import { useState } from 'react';
import { IS_VALID_PASSWORDS } from 'Commons/config/constants/Validators';
import { Grid, ExpansionPanel, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { ExpansionPanelSummary } from '@material-ui/core';
import Icon from 'Generic/icon/components/Icon';
import Typography from 'Generic/typography/components/Typography';
import userProfileStyles from 'Commons/components/business/userProfile/styles/UserProfileStyles';
import localisable from '../../../../config/strings/localisable';

const useStyles = makeStyles(userProfileStyles, { name: 'ResetPassword' });

const ResetPassword = () => {
    const classes = useStyles();
    const [oldPassIconChange, setOldPassVisibility] = useState(false);
    const [newPassIconChange, setNewPassVisibility] = useState(false);
    const [confirmPassIconChange, setConfirmPassVisibility] = useState(false);

    const renderViewHidePasswordIcon = (shouldShow, setStateFunc) => (
        <Icon
            type="custom"
            color="primary"
            icon={shouldShow ? 'cp-view' : 'cp-hide'}
            className={classes.icons}
            onClick={() => setStateFunc(!shouldShow)}
        />
    );

    return (
        <>
            <Grid item xs={8}>
                <ExpansionPanel className={classes.shadows}>
                    <ExpansionPanelSummary
                        className={classes.expansionPanelSummary}
                        classes={{ content: classes.expansionPanelSummaryContent }}
                        expandIcon={(
                            <Icon
                                icon="cp-expand"
                                type="custom"
                                color="primary"
                                className={classes.expandIcon}
                            />
                        )}
                    >
                        <Typography variant="h6">
                            {localisable.passwordUpdate}
                        </Typography>
                    </ExpansionPanelSummary>
                    <FormTextField
                        name="extra.oldPassword"
                        autoComplete="new-Password"
                        key={`extra.oldPassword${oldPassIconChange}`}
                        type={oldPassIconChange ? 'text' : 'password'}
                        addon={{ end: renderViewHidePasswordIcon(oldPassIconChange, setOldPassVisibility) }}
                        label={localisable.enterOldPassword}
                        validate={IS_VALID_PASSWORDS}
                        noIndicatorWrapper
                    />
                    <FormTextField
                        name="extra.newPassword"
                        autoComplete="new-Password"
                        key={`extra.newPassword${newPassIconChange}`}
                        type={newPassIconChange ? 'text' : 'password'}
                        addon={{ end: renderViewHidePasswordIcon(newPassIconChange, setNewPassVisibility) }}
                        label={localisable.enterNewPassword}
                        validate={IS_VALID_PASSWORDS}
                        noIndicatorWrapper
                    />
                    <FormTextField
                        name="extra.confirmPassword"
                        autoComplete="new-Password"
                        key={`extra.confirmPassword${confirmPassIconChange}`}
                        type={confirmPassIconChange ? 'text' : 'password'}
                        addon={{ end: renderViewHidePasswordIcon(confirmPassIconChange, setConfirmPassVisibility) }}
                        label={localisable.reEnterNewPassword}
                        validate={IS_VALID_PASSWORDS}
                        noIndicatorWrapper
                    />
                </ExpansionPanel>
            </Grid>
        </>
    );
};

export default ResetPassword;
