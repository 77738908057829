import media from 'Commons/theme/MediaQueries';

const bevLeftSidebarStyles = theme => ({
    leftPanelContainer: {
        padding: theme.spacing(2, 2, 2, 4),
        backgroundColor: theme.palette.common.white,
        flexWrap: 'nowrap',
        overflow: 'auto',
        borderRadius: 4,
        width: '18%',
        [media.laptop]: { padding: theme.spacing(1, 1, 1, 2) },
    },
    sideBarTopMargin: {
        marginTop: theme.spacing(2),
        [media.laptop]: { marginTop: theme.spacing(1) },
    },
    sideBarTopMarginForDirections: {
        marginTop: theme.spacing(4),
        [media.laptop]: { marginTop: theme.spacing(2) },
    },
    sectionContainer: {
        marginTop: theme.spacing(4),
        [media.laptop]: { marginTop: theme.spacing(2) },
    },
    addButtonsContainer: { [media.laptop]: { paddingTop: theme.spacing(1) } },
    radioGroupMarginRight: { '&>:last-child': { marginRight: 0 } },
    number: {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            appearance: 'none',
            background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASCAYAAABit09LAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACLSURBVHgB5ZIhDoAwDEVHkFMY5vFo8FwFj8fDgfB45jnFFAZPm/ySrYKApslPl+ytTftnTBolaUJOIlfQTKpILWkjnRoUSCpZDecKCqQBYB3DDI5oF3Dm7PFQ4OVxAFTvzK/j1Xpk4YkLCmIzVgZ3UkNyEdwDEreOTLV3qGhNaun9e/jSo3KhoU+DXWxIJFoIWlIqAAAAAElFTkSuQmCC")no-repeat center center',
            top: 0,
            right: 0,
            bottom: 0,
            width: '1em',
        },
    },
    button: {
        margin: theme.spacing(0.25),
        padding: 0,
        maxWidth: 32,
        maxHeight: 32,
        minWidth: 32,
        minHeight: 32,
        color: theme.palette.black[600],
        borderColor: theme.palette.black[600],
        '&:hover': {
            color: theme.palette.black[600],
            backgroundColor: theme.palette.button.hover,
            borderColor: theme.palette.button.hover,
        },
    },
    addButton: { '&, &:hover': { color: theme.palette.text.primary } },
    keepButtonActive: {
        color: theme.palette.black[600],
        backgroundColor: theme.palette.button.hover,
        borderColor: theme.palette.button.hover,
    },
    alertDialogButton: { fontSize: 14 },
    inputField: { textAlign: 'left' },
});

export default bevLeftSidebarStyles;
