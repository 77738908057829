const formattersStyle = theme => ({
    labelWrapper: {
        flexWrap: 'nowrap',
        overflow: 'hidden',
    },
    labelWithIcon: { maxWidth: '100%' },
    disabledIcon: { cursor: 'default' },
    balance: {
        textAlign: 'right',
        width: '100%',
    },
    contentList: { overflow: 'hidden' },
    fullHeight: { height: '100%' },
    moreItems: {
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.secondary.main,
        },
    },
    cursorDefault: { cursor: 'default' },
    faPadding: { paddingLeft: 8 },
    iconColor: { color: '#D83320' },
    maxWidth: { maxWidth: '100%' },
    inline: { flexWrap: 'nowrap', maxWidth: '90%' },
    chipFixedWidth: { width: 96 },
    overflowHidden: { overflow: 'hidden' },
    disabledText: { color: theme.palette.text.disabled },
});

export default formattersStyle;
