
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { FormDatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import LabelWithIcon from 'Generic/labelwithicon/components/LabelWithIcon';
import AllAchBatchesFilterStyle from '../styles/AllAchBatchesFilterStyle';

const useStyles = makeStyles(AllAchBatchesFilterStyle, { name: 'AllAchBatchesFilter' });

const AllAchBatchesFilter = ({ formProps: { setFieldValue } }) => {
    const classes = useStyles();
    const onClearDate = () => { setFieldValue('range', {}); };

    return (
        <>
            <Typography variant="h6" className={classes.dateRangeLabel}>
                {localisable.batchBetween}
            </Typography>
            <Grid container wrap="nowrap" justify="space-between" spacing={2}>
                <Grid item>
                    <FormDatePicker
                        name="range.createdDate.from"
                        label={localisable.fromDate}
                        noGrid
                        noIndicatorWrapper
                    />
                </Grid>
                <Grid item>
                    <FormDatePicker
                        name="range.createdDate.to"
                        label={localisable.toDate}
                        noGrid
                        noIndicatorWrapper
                    />
                </Grid>
            </Grid>
            <LabelWithIcon
                classes={{ labelWithIcon: classes.autoWidth }}
                className={classes.clearDate}
                onClick={onClearDate}
            >
                <Typography color="primary" variant="body2">
                    {localisable.clearDate}
                </Typography>
            </LabelWithIcon>
        </>
    );
};

AllAchBatchesFilter.propTypes = { formProps: PropTypes.object };

export default memo(AllAchBatchesFilter);
