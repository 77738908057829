
const treeViewStyles = theme => ({
    root: {
        overflow: 'auto',
        '&:focus > $content': { backgroundColor: 'transparent' },
    },
    content: {
        '&:hover': { backgroundColor: 'transparent' },
        minHeight: theme.spacing(2),
        margin: theme.spacing(1, 0),
    },
    checkboxRoot: {
        padding: 0,
        marginRight: theme.spacing(1),
    },
    formLabel: {
        marginRight: theme.spacing(1),
        marginLeft: 0,
    },
    toggleIcon: { marginLeft: theme.spacing(1) },
    indicatorWrapper: { flexBasis: '0%' },
    checked: {},
    group: {},

    selectAllPadding: {
        paddingLeft: theme.spacing(29.25),
        fontWeight: 700,
        paddingBottom: theme.spacing(1),
    },

    fontClass: { fontWeight: 700 },
    childClass: { paddingLeft: theme.spacing(3), fontWeight: 700 },
    gridPadding: { paddingLeft: theme.spacing(3), marginBottom: theme.spacing(3) },
    gridContainer: { paddingLeft: theme.spacing(3) },
    gridCheckBox: { paddingLeft: theme.spacing(9) },
    parentCheckBox: { paddingLeft: theme.spacing(10) },
    permissionStyle: { paddingBottom: theme.spacing(1), fontWeight: 700, whiteSpace: 'nowrap' },
    sublabel: { paddingTop: theme.spacing(1) },
    sublabelChild: { paddingLeft: theme.spacing(3), paddingTop: theme.spacing(1) },
    gridCheckBoxChildren: { paddingLeft: theme.spacing(10), paddingTop: theme.spacing(1) },
    innerBox: { display: 'flex', alignItems: 'center' },
    noCheckbox: { paddingLeft: theme.spacing(11), paddingTop: theme.spacing(0), color: theme.palette.status.inactive },
    mainContainer: { position: 'relative' },
    checkBoxContainer: { display: 'flex', alignItems: 'center' },
    permissionHeader: { paddingLeft: theme.spacing(3) },
    selectionHeader: {
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(1),
    },
    headerWrapper: {
        position: 'sticky',
        top: theme.spacing(0),
        background: theme.palette.common.white,
        zIndex: 1,
    },
});

export default treeViewStyles;
