const dropDownStyle = theme => ({
    dropdown: { width: 'auto' },
    listItem: {
        whiteSpace: 'normal',
        height: 'auto',
        minHeight: theme.spacing(6),
        wordWrap: 'break-word',
        padding: '8px 16px',
        lineHeight: 1.3,
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.palette.highlight },
    },
    message: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        boxSizing: 'border-box',
    },
    formControlLabel: {
        margin: 0,
        width: '100%',
        padding: 5,
    },
    textFieldInput: {
        overflow: 'visible',
        textOverflow: 'initial',
        whiteSpace: 'normal',
        textAlign: 'left',
    },
    iconArrowDown: {},
    paper: { padding: '8px 0px', zIndex: 1 },
    disabled: {},
    selected: { backgroundColor: theme.palette.highlight },
    inputRoot: { '&$disabled': { pointerEvents: 'none' } },
    searchIcon: { padding: '12px 8px 8px 0' },
    fullWidth: { width: '100%' },
});

export default dropDownStyle;
