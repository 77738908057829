const colorPickerStyle = theme => ({
    coloredSquare: {
        position: 'relative',
        outline: 'none',
        border: 'none',
        borderRadius: 0,
        maxWidth: 24,
        maxHeight: 24,
        minWidth: 24,
        minHeight: 24,
        backgroundColor: ({ color: { hex } = {} } = {}) => hex,
        '&:hover': { backgroundColor: ({ color: { hex } = {} } = {}) => hex },
        margin: theme.spacing(2),
    },
    popper: { zIndex: 1 },
    readOnly: { pointerEvents: 'none' },
});

export default colorPickerStyle;
