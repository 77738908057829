import { VIEW } from 'Commons/config/constants/Constants';
import { TENANT_ENDPOINTS } from 'Commons/config/constants/Endpoints';

const getApiConfig = (selectedFacility, isAutoSuggest = false) => ({
    api: {
        dynamic: {
            endPoint: isAutoSuggest ? TENANT_ENDPOINTS.autoSuggest : TENANT_ENDPOINTS.search,
            body: { view: VIEW.detail.value },
            headers: {
                'context-id': selectedFacility,
                'context-type': 'Facility',
            },
        },
    },
});

const getTenantApiConfig = selectedFacility => ({
    search: getApiConfig(selectedFacility),
    autoSuggest: getApiConfig(selectedFacility, true),
    sendCustomHeader: false,
});

export { getTenantApiConfig };
