const formItemStyle = theme => ({
    formItem: {
        minWidth: theme.spacing(2),
        maxWidth: theme.spacing(2),
    },
    formPadding: ({ padding = theme.spacing(2) }) => ({ padding }),
    indicator: {
        fontSize: 8,
        cursor: 'default',
        lineHeight: 2,
    },
    required: { color: theme.palette.error.main },
    preferred: { color: theme.palette.preferred },
    label: {
        ...theme.typography.caption,
        '&$disabled': { color: theme.palette.text.disabled },
    },
    disabled: {},
    item: {},
});


export default formItemStyle;
