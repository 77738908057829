import { isExternalDashboard, isLiveData, isInternalDashboard } from 'Commons/helpers/utils/Utils';
import GLOBAL_STORE_KEYS from './config/GlobalStoreKeys';

let Dependency = require('../../../../external/redux/config/Dependency').default;

const TYPE = { ROUTE_BASED: 0, COMPONENT_BASED: 1 };

export default class ConfigLoader {
    constructor() {
        this.initialized = false;
    }

    init(route, componentName) {
        this.componentName = componentName;
        this.route = route || {};
        this.initializeDependency();
        this.initializeType();
        this.initializeConfig();
        return this.config;
    }

    initializeType() {
        this.type = Dependency[this.componentName] ? TYPE.COMPONENT_BASED : TYPE.ROUTE_BASED;
    }

    initializeConfig() {
        if (this.type === TYPE.COMPONENT_BASED) {
            this.initializeConfigByComponentName();
        } else {
            this.initializeConfigByRoute();
        }
    }

    initializeDependency() {
        const { url = '' } = this.route;
        if (isInternalDashboard(url)) {
            // eslint-disable-next-line global-require
            Dependency = require('../../../../internal/redux/config/Dependency').default;
        } else if (isExternalDashboard(url) || isLiveData(url)) {
            // eslint-disable-next-line global-require
            Dependency = require('../../../../external/redux/config/Dependency').default;
        }
    }

    initializeConfigByComponentName() {
        this.dependencyConfig = Dependency[this.componentName] || {};
        this.config = this.flattenStoreKeys(Dependency[this.componentName]);
        this.initialized = true;
    }

    initializeConfigByRoute() {
        const { pathList = [] } = this.route;
        let dependency = Dependency;
        const depth = pathList.length;
        this.currentRoute = this.getCurrentPath();
        pathList.map((route) => {
            dependency = dependency[route] || {};
            return true;
        });
        this.dependencyConfig = dependency[this.currentRoute] || {};
        this.config = this.flattenStoreKeys({ ...dependency[this.currentRoute], depth });
        this.initialized = true;
    }

    flattenStoreKeys({ critical = {}, lazy = {}, onDemand = {}, depth = -1, onMount = null, permissionKey = '' } = {}) {
        const keys = {
            critical: Object.keys(critical),
            lazy: Object.keys(lazy),
            onDemand: Object.keys(onDemand),
            onMount,
            permissionKey,
            dependencyConfig: this.dependencyConfig,
        };
        return {
            ...keys,
            depth,
            all: [...GLOBAL_STORE_KEYS, ...keys.critical, ...keys.lazy, ...keys.onDemand],
        };
    }

    getCurrentPath() {
        const { path = '', pathList = [] } = this.route;
        const parentPath = pathList.length ? `/${pathList.join('/')}` : '';
        return path.slice(parentPath.length + 1, path.length);
    }

    getRouteList() {
        return {
            pathList: this.getPathList(),
            urlList: this.route.urlList.concat(this.route.url),
        };
    }

    getPathList() {
        return this.currentRoute ? this.route.pathList.concat(this.currentRoute) : this.route.pathList;
    }

    get() {
        return this.config;
    }
}
