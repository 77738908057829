import moment from 'moment';
import { TIME_WINDOW, SEARCH_DATE_FORMAT, COMPARISON_OPERATION, NUMBER_OF_DAYS_IN_WEEK } from 'Commons/config/constants/Constants';

/**
*  Formatting of date and time using moment.js
* */

/**
 *
 * @param {string} timezone Standard Timezone String Literal
 */
const setDefaultTimezone = timezone => moment.tz.setDefault(timezone);

/**
 * @name isValidDate
 * @description Given a string, checks whether proper date or not
 * @param {string} date
 */
const isValidDate = (date) => {
    const dateObj = moment(date);
    return dateObj.isValid();
};

/**
 * @name dateFormatter
 * @description Given a date, converts the date into mentioned format. moment returns undefined date as today's date
 * @param {Date | string} date Valid Date Object or String
 * @param format
 * @param removeTimeZoneAwareness
 */
const dateFormatter = (date, format = 'MM-DD-YYYY', removeTimeZoneAwareness = false) => {
    const dateObj = removeTimeZoneAwareness ? moment(date).utc() : moment(date);
    return dateObj.isValid() ? dateObj.format(format) : null;
};

/**
 * @description Returns day name for a given date. Will return full name if noOfChars is undefined
 * @param {string} date
 * @param {number} noOfChars Number of characters required in the returned day's name.
                            Ex - Passing 3, will return only 'Mon' for 'Monday'.
 * @returns {string}
 */
const getDayName = (date, noOfChars = undefined) => moment(date).format('dddd').slice(0, noOfChars);

const getDay = date => moment(date).date();

const getMonth = date => moment(date).month();

const getYear = date => moment(date).year();

const getMinOrMaxDate = (dates, needsMin) => {
    const filteredDates = [];
    dates.forEach((date) => {
        if (date) filteredDates.push(moment(date));
    });
    return needsMin ? moment.min(filteredDates) : moment.max(filteredDates);
};

const getMinDate = dates => getMinOrMaxDate(dates, true);

const getMaxDate = dates => getMinOrMaxDate(dates);

const handleRequiredDateDataType = (dateObj, convertToString, format) => {
    if (convertToString) {
        return dateFormatter(dateObj, format);
    }
    return dateObj;
};

/**
 * @description Adds the number to the given date.
 * @param {(moment.Moment | string)} date A moment/Date object or date string. If undefined, it takes the current date/time
 * @param {number} numberOfDeltas Number to add to date
 * @param {string} timeDelta One of the value of TIME_WINDOW. Eg. days/months/years etc.
 * @param {boolean} convertToString Will return string if true else moment object
 * @param {string} format The date format required if converting to string
 * @returns {(moment.Moment | string)} - Returns the added date in either string format or as a Moment obj based on `convertToString` param
 */
const addToDate = (date, numberOfDeltas = 1, timeDelta = TIME_WINDOW.DAYS,
    convertToString = false, format = SEARCH_DATE_FORMAT) => handleRequiredDateDataType(moment(date).add(numberOfDeltas, timeDelta), convertToString, format);


/**
 * @description Subtracts the number from the given date.
 * @param {(moment.Moment | string)} date A moment/Date object or date string. If undefined, it takes the current date/time
 * @param {number} numberOfDeltas Number to subtract from date
 * @param {string} timeDelta One of the value of TIME_WINDOW. Eg. days/months/years etc.
 * @param {boolean} convertToString Will return string if true else moment object
 * @param {string} format The date format required if converting to string
 * @returns {(moment.Moment | string)} - Returns the subtracted date in either string format or as a Moment obj based on `convertToString` param
 */
const subtractFromDate = (date = '', numberOfDeltas = 1, timeDelta = TIME_WINDOW.DAYS,
    convertToString = false, format = SEARCH_DATE_FORMAT) => handleRequiredDateDataType(moment(date).subtract(numberOfDeltas, timeDelta), convertToString, format);

const dateDiff = (date2, date1, timeDelta = TIME_WINDOW.DAYS) => moment(date2).diff(moment(date1), timeDelta);

const getCurrentDate = (convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment().format(format);
    }
    return moment();
};

const getStartOfMonth = (convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment().startOf('month').format(format);
    }
    return moment().startOf('month');
};

const getStartOfYear = (convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment().startOf('year').format(format);
    }
    return moment().startOf('year');
};

const getStartOfLastMonth = (convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment().subtract(1, 'months').startOf('month').format(format);
    }
    return moment().subtract(1, 'months').startOf('month');
};

const getEndOfLastMonth = (convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment().subtract(1, 'months').endOf('month').format(format);
    }
    return moment().subtract(1, 'months').endOf('month');
};

/**
 * @description Convert to Moment obj
 * @param date {(string | (number | string)[])}
 * @return {moment.Moment} Moment object of given date
 */
const convertToMomentObj = date => moment(date);

/**
 * @description Compare dates or datetime
 * @param {( moment.Moment | Date | string | number | (number | string)[])} date1 - If `undefined`, it takes the current date/time
 * @param {( moment.Moment | Date | string | number | (number | string)[])} date2 - If `undefined`, it takes the current date/time
 * @param {('lt' | 'lte' | 'gt' | 'gte' | 'eq' | 'ne')} compareOperation
 * @param {('years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds')} granularity
 * @returns {boolean} Comparison result
 */
const compareDatesOrDatetime = (date1, date2, compareOperation = COMPARISON_OPERATION.EQ, granularity = TIME_WINDOW.DAYS) => {
    const dateObj1 = moment(date1);
    const dateObj2 = moment(date2);
    let result;
    switch (compareOperation) {
        case COMPARISON_OPERATION.EQ:
            result = dateObj1.isSame(dateObj2, granularity);
            break;
        case COMPARISON_OPERATION.NE:
            result = !dateObj1.isSame(dateObj2, granularity);
            break;
        case COMPARISON_OPERATION.LT:
            result = dateObj1.isBefore(dateObj2, granularity);
            break;
        case COMPARISON_OPERATION.LTE:
            result = dateObj1.isSameOrBefore(dateObj2, granularity);
            break;
        case COMPARISON_OPERATION.GT:
            result = dateObj1.isAfter(dateObj2, granularity);
            break;
        case COMPARISON_OPERATION.GTE:
            result = dateObj1.isSameOrAfter(dateObj2, granularity);
            break;
        default: break;
    }
    return result;
};

const getFirstDateOfMonth = (year = undefined, month = undefined, convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment({ year, month, day: 1 }).startOf('month').format(format);
    }
    return moment({ year, month, day: 1 }).startOf('month');
};

const getLastDateOfMonth = (year = undefined, month = undefined, convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment({ year, month }).endOf('month').format(format);
    }
    return moment({ year, month }).endOf('month');
};

const getLastOfMonthFromDate = (year = undefined, month = undefined, convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment({ year, month, day: 1 }).endOf('month').format(format);
    }
    return moment({ year, month, day: 1 }).endOf('month');
};

const createFormattedDate = (year = undefined, month = undefined, day = undefined, convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment({ year, month, day }).format(format);
    }
    return moment({ year, month, day });
};


/**
 * Get the starting day index of the month, considering the first day of the week.
 *
 * @param {Number} year - The year.
 * @param {Number} month - The month (0-11).
 * @returns {Number} - The starting day index (0: Sunday, 1: Monday, ...).
 */
const getMonthStartingDayIndex = (year, month) => moment({ year, month, day: 1 }).day();

const getStartOfWeek = (date, convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment(date).clone().startOf('week').format(format);
    }
    return moment(date).clone().startOf('week');
};

const getEndOfWeek = (date, convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment(date).clone().endOf('week').format(format);
    }
    return moment(date).clone().endOf('week');
};

const getDate = (date, convertToString = true, format = SEARCH_DATE_FORMAT) => {
    if (convertToString) {
        return moment(date).format(format);
    }
    return moment(date);
};

/**
 * Get the number of weeks in a month, considering the starting day of the week.
 *
 * @param {Number} year - The year.
 * @param {Number} monthIndex - The month (0-11).
 * @param {Number} [startDayOfWeek=0] - The starting day of the week (0: Sunday, 1: Monday, ...). Default: 0 (Sunday).
 * @returns {Number} - The number of weeks in the month.
 */
const calculateWeeksInMonth = (year, monthIndex, startDayOfWeek = 0) => {
    const firstDayOfWeek = startDayOfWeek;
    const firstOfMonth = getFirstDateOfMonth(year, monthIndex, false).day();
    const numberOfDaysInMonth = getLastDateOfMonth(year, monthIndex, false).date();
    const firstWeekDay = (firstOfMonth - firstDayOfWeek + NUMBER_OF_DAYS_IN_WEEK) % NUMBER_OF_DAYS_IN_WEEK;

    const used = firstWeekDay + numberOfDaysInMonth;
    return Math.ceil(used / NUMBER_OF_DAYS_IN_WEEK);
};

export {
    getDayName,
    getDay,
    getYear,
    getMonth,
    dateDiff,
    addToDate,
    getMinDate,
    getMaxDate,
    isValidDate,
    dateFormatter,
    getCurrentDate,
    getStartOfYear,
    getStartOfMonth,
    subtractFromDate,
    convertToMomentObj,
    setDefaultTimezone,
    compareDatesOrDatetime,
    getStartOfLastMonth,
    getEndOfLastMonth,
    getFirstDateOfMonth,
    getLastDateOfMonth,
    getLastOfMonthFromDate,
    createFormattedDate,
    getStartOfWeek,
    getEndOfWeek,
    getMonthStartingDayIndex,
    calculateWeeksInMonth,
    getDate,
};
