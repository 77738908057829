import localisable from 'Commons/config/strings/localisable';
import { memo } from 'react';
import RangeFilter from '../../rangeFilter/components/RangeFilter';


const EmailAuditFilter = (props) => {
    const { formProps: { setFieldValue } = {} } = props;
    return (
        <>
            <RangeFilter
                name="createdDate"
                setFieldValue={setFieldValue}
                label={localisable.dateRange}
                isDateRange
            />
        </>
    );
};

EmailAuditFilter.propTypes = { formProps: PropTypes.object };

export default memo(EmailAuditFilter);
