import React, { PureComponent } from 'react';
import Button from '../../button/components/Button';
import { Grid, Divider, withStyles, Modal as MuiModal, Backdrop } from '../../componentlibrary/components/Components';
import modalStyle from '../styles/ModalStyle';

class Modal extends PureComponent {
    renderCloseButton = () => {
        const { classes, onClose } = this.props;
        return (
            <Button
                onClick={onClose}
                className={`${classes.closeButton}`}
                variant="icon"
                disableRipple
                icon="cp-close"
                type="button"
                iconType="custom"
                color="primary"
                IconProps={{ className: classes.closeIcon }}
            />
        );
    }

    renderExpandButton = () => {
        const { classes, onExpand, isExpanded } = this.props;
        return (
            <Button
                onClick={onExpand}
                className={`${classes.expandButton}`}
                variant="icon"
                disableRipple
                icon={isExpanded ? 'cp-arrow-collapse' : 'cp-arrow-expand'}
                type="button"
                iconType="custom"
                color="primary"
                IconProps={{ className: classes.expandIcon }}
            />
        );
    }

    render = () => {
        const {
            header, footer, open, children, className = '', classes, onClose, divider,
            hideCloseButton, bodyContainerProps, showExpandButton, onExpand, isExpanded, ...props
        } = this.props;
        return (
            <MuiModal
                BackdropComponent={Backdrop} // Overriding SimpleBackdrop component since it uses inline styles
                open={open}
                onClose={onClose}
                className={`${classes.modal} ${className}`}
                {...props}
            >
                <Grid container className={`${classes.modalGrid}`} direction="column">
                    {
                        header
                        && (
                            <Grid item className={`${classes.modalHeader}`}>
                                {!hideCloseButton && this.renderCloseButton()}
                                {showExpandButton && this.renderExpandButton()}
                                {header}

                                {
                                    (divider === 'header' || divider === 'both')
                                    && <Divider light className={`${classes.modalDivider}`} />
                                }
                            </Grid>
                        )
                    }
                    {
                        children
                        && (
                            <Grid
                                container
                                item
                                className={`${classes.modalBody}`}
                                wrap="nowrap"
                                {...bodyContainerProps}
                            >
                                {!header && !hideCloseButton && this.renderCloseButton()}
                                {!header && showExpandButton && this.renderExpandButton()}
                                {children}
                            </Grid>
                        )
                    }
                    {
                        footer
                        && (
                            <Grid item className={`${classes.modalFooter}`}>
                                {
                                    (divider === 'footer' || divider === 'both') && <Divider />
                                }
                                {footer}
                            </Grid>
                        )
                    }
                </Grid>
            </MuiModal>
        );
    }
}

Modal.propTypes = {
    header: PropTypes.node,
    footer: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    onClose: PropTypes.func,
    classes: PropTypes.object.isRequired,
    divider: PropTypes.oneOf(['header', 'footer', 'both', 'none']),
    open: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    bodyContainerProps: PropTypes.object,
    showExpandButton: PropTypes.bool,
    onExpand: PropTypes.func,
    isExpanded: PropTypes.bool,
};
Modal.defaultProps = {
    header: '',
    footer: '',
    onClose: () => {},
    onExpand: () => {},
    divider: 'none',
    open: true,
    hideCloseButton: false,
    bodyContainerProps: {},
    showExpandButton: false,
};
export default withStyles(modalStyle)(Modal);
