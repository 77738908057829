import { VIEW } from 'Commons/config/constants/Constants';

const callApi = (onAction, endPoint, body, callback = () => {}, method = 'POST') => {
    if (onAction) {
        onAction({
            config: [{
                api: {
                    method: 'read',
                    action: {
                        methodType: `${method}`,
                        endPoint,
                        body,
                    },
                },
                callback,

            }],
        });
    }
};

const changePassword = (onAction, { confirmPassword, ...resetPassword }, callback) => {
    callApi(onAction, 'auth/change-password', { ...resetPassword }, callback);
};
const changeUserDetails = (onAction, userId, userDetails, callback) => {
    callApi(onAction, `user/${userId}`, { ...userDetails }, callback, 'PUT');
};
const getUserDetails = (onAction, userId, callback, includeRelations) => {
    const body = {
        view: VIEW.detail.value,
        filter: [{ terms: { id: [userId] } }],
        ...(includeRelations && { includeRelations }),
    };
    callApi(onAction, 'user/search', body, callback);
};

export { changePassword, changeUserDetails, getUserDetails };
