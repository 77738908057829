import media from 'Commons/theme/MediaQueries';

const canvasHeaderStyles = theme => ({
    canvasHeaderContainer: {
        position: 'relative',
        padding: ({ isSetupMode }) => (theme.spacing(3, isSetupMode ? 5 : 4, 1, 4)),
    },
    zoomButtonContainer: {
        position: 'relative',
        borderRadius: 8,
        border: `1px ${theme.palette.black[600]} solid`,
    },
    zoomButton: {
        margin: 0,
        padding: 0,
        maxWidth: 40,
        maxHeight: 30,
        minWidth: 40,
        minHeight: 30,
        borderRadius: 10,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
            border: 'none',
        },
    },
    zoomButtonDisabled: { '&.Mui-disabled': { border: 'none' } },
    zoomInButton: {
        border: 'none',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    zoomOutButton: {
        border: 'none',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    zoomPercentage: {
        display: 'inline',
        padding: theme.spacing(0, 0.5),
        fontSize: 14,
    },
    textButton: {
        '&:hover': { backgroundColor: theme.palette.common.white },
        backgroundColor: theme.palette.common.white,
    },
    resetButton: { margin: theme.spacing(0, 2) },
    setAsDefaultContainer: { minHeight: 32, paddingTop: theme.spacing(1) },
    setDefaultButton: { margin: theme.spacing(0, 0, 0, 1), minHeight: 21 },
    textFontSize: { fontSize: 14 },
    divider: {
        position: 'absolute',
        right: 70,
        top: 3,
        width: 1,
        height: 25,
        backgroundColor: theme.palette.black[300],
    },
    infoButton: {
        maxWidth: theme.spacing(5),
        maxHeight: theme.spacing(5),
        minWidth: theme.spacing(5),
        minHeight: theme.spacing(5),
        [media.desktop]: {
            maxWidth: theme.spacing(7),
            maxHeight: theme.spacing(7),
            minWidth: theme.spacing(7),
            minHeight: theme.spacing(7),
        },
        borderRadius: '50%',
        boxShadow: theme.boxShadows[5],
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    refreshButton: { marginRight: theme.spacing(0) },
    searchableUnitDropdownGrid: { paddingLeft: theme.spacing(2) },
    searchableUnitDropdown: {
        width: '200px',
        [media.tablet]: { width: '120px' },
    },
    scrollActionItems: {
        alignSelf: 'start',
        borderRadius: theme.spacing(1),
        border: `1px ${theme.palette.black[600]} solid`,
        marginLeft: theme.spacing(2),
    },
    directionBtn: { height: 30 },
});

export default canvasHeaderStyles;
