import ButtonText from 'Generic/buttontext/components/ButtonText';
import { clsx } from 'Commons/helpers/utils/clsx';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import footerStyle from '../styles/FooterStyle';

const useStyles = makeStyles(footerStyle, { name: 'Footer' });

const FooterButton = ({
    index,
    buttonProps: {
        label,
        disabled,
        loadingText,
        isButtonLoading,
        className: buttonClassName,
        classes: { button: buttonClasses, ...otherButtonClasses } = {},
        buttonToolTipMessage,
        buttonTextProps,
        ...otherButtonProps
    } = {},
    classes: classesProp,
    className,
    ...props
}) => {
    const classes = useStyles({ classes: classesProp });
    const { variant = 'contained' } = otherButtonProps;

    const renderButton = () => (label
        ? (
            <Grid item key={index}>
                <Button
                    variant={variant}
                    disabled={disabled}
                    color={!disabled ? 'secondary' : undefined}
                    classes={{ button: buttonClasses, ...otherButtonClasses }}
                    className={clsx(className, classes.button, buttonClassName,
                        variant !== 'icon' && classes.buttonWidth)}
                    {...props}
                    {...otherButtonProps}
                >
                    <ButtonText
                        isButtonLoading={isButtonLoading}
                        loadingText={loadingText}
                        label={label}
                        {...buttonTextProps}
                    />
                </Button>
            </Grid>
        ) : null);

    return (
        buttonToolTipMessage
            ? (
                <Tooltip title={buttonToolTipMessage}>
                    {renderButton()}
                </Tooltip>
            )
            : renderButton()

    );
};

FooterButton.propTypes = {
    index: PropTypes.number,
    buttonProps: PropTypes.object,
    classes: PropTypes.object,
    className: PropTypes.string,
};

const { deviceInfo: { isMobile, isPhablet } } = window;
const isMobileOrPhablet = isMobile || isPhablet;

const Footer = ({
    className,
    message,
    backButtonProps,
    backButtonProps: { label: backButtonLabel },
    classes: classesProp,
    positiveButtonProps,
    negativeButtonProps,
    spacing,
    ...props
}) => {
    const buttonCount = [backButtonProps, positiveButtonProps, negativeButtonProps]
        .filter(({ label }) => !!label).length;

    const classes = useStyles({ classes: classesProp });

    const justify = (backButtonLabel || message || (isMobileOrPhablet && buttonCount > 1))
        ? 'space-between' : 'flex-end';

    let negativeButton = null;
    let positiveButton = null;
    if (Object.keys(negativeButtonProps).length) {
        negativeButton = (
            <FooterButton
                variant="outlined"
                classes={classes}
                className={classes.buttonNegative}
                buttonProps={negativeButtonProps}
            />
        );
    }
    if (Object.keys(positiveButtonProps).length) {
        positiveButton = Array.isArray(positiveButtonProps) ? (
            positiveButtonProps.map((buttonProps, index) => (
                <FooterButton
                    index={index}
                    classes={classes}
                    buttonProps={buttonProps}
                    className={classes.buttonPositive}
                />
            ))
        ) : (
            <FooterButton
                classes={classes}
                buttonProps={positiveButtonProps}
                className={classes.buttonPositive}
            />
        );
    }

    const renderNegativePositiveButton = () => (
        negativeButton || positiveButton
            ? (
            <>
                {negativeButton}
                {positiveButton}
            </>
            )
            : null
    );
    return (
        <Grid container alignItems="center" justify={justify} className={clsx(classes.footer, className)} {...props}>
            {
                message
                || (
                    <FooterButton
                        variant="outlined"
                        classes={classes}
                        buttonProps={backButtonProps}
                        className={classes.buttonBack}
                    />
                )
            }

            {
                isMobileOrPhablet
                    ? renderNegativePositiveButton()
                    : (
                        <Grid
                            container
                            item
                            justify="flex-end"
                            className={classes.buttonWrapper}
                            spacing={spacing}
                        >
                            {renderNegativePositiveButton()}
                        </Grid>
                    )
            }
        </Grid>
    );
};

Footer.propTypes = {
    backButtonProps: PropTypes.object,
    positiveButtonProps: PropTypes.any,
    negativeButtonProps: PropTypes.object,
    className: PropTypes.string,
    classes: PropTypes.object,
    message: PropTypes.node,
    spacing: PropTypes.number,
};

Footer.defaultProps = {
    backButtonProps: {},
    negativeButtonProps: {},
    positiveButtonProps: { label: localisable.save, isButtonLoading: false, loadingText: localisable.saving },
};


export default Footer;

export { FooterButton };
