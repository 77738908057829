import media from '../../../../theme/MediaQueries';

const FilterOverlayStyle = theme => ({
    filterHeader: {
        fontSize: 16,
        color: theme.palette.text.primary,
        fontWeight: 'bolder',
    },
    overlay: {
        '& $overlayHeader': {
            flexWrap: 'nowrap',
            [media.smallMobile]: {
                boxShadow: 'none !important',
                padding: '0px 16px',
                minHeight: 50,
            },
            [media.mobile]: {
                boxShadow: 'none !important',
                padding: '0px 16px',
                minHeight: 50,
            },
            [media.phablet]: {
                boxShadow: 'none !important',
                padding: '0px 16px',
                minHeight: 50,
            },
            [media.tablet]: {
                boxShadow: 'none !important',
                padding: '0px 16px',
            },
        },
    },
    overlayHeader: {},
    filterContainer: {
        [media.mobile]: {
            position: 'absolute',
            left: '0px',
            width: '100%',
            height: '100%',
        },
        [media.phablet]: {
            padding: '2px',
            position: 'absolute',
            left: '0px',
            width: '100%',
            height: '100%',
        },
        [media.tablet]: {
            padding: '2px',
            position: 'absolute',
            left: '0px',
            width: '100%',
            height: '100%',
        },
        [media.laptopUp]: { padding: '36px 0px' },
    },
    filterTypesPaper: {
        height: '100%',
        width: '100%',
        [media.mobile]: { boxShadow: 'none !important' },
        [media.smallMobile]: { boxShadow: 'none !important' },
        [media.phablet]: { boxShadow: 'none !important' },
        [media.tablet]: { boxShadow: 'none !important' },
    },
    footer: {
        zIndex: 50,
        boxShadow: theme.boxShadows[0],
    },
    message: { color: theme.palette.error.main },
    modalGrid: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: 'auto',
        transform: 'none',
        margin: '24px 16px',
        boxShadow: theme.boxShadows[0],
        [media.tabletUp]: { margin: '2vw' },
        [media.laptopUp]: { margin: '8vw' },
    },
});

export default FilterOverlayStyle;
