import { deCapitalize, camelCase } from 'Commons/helpers/utils/DataHelpers';
import { RESOURCE_NAME_TO_STORE_KEY } from 'Commons/components/business/basecomponent/config/Constants';

const CONFIGURATION_KEY_POSTFIX = '_configuration';

const getStoreKeyToClear = (message, storesToNotClearSilently = []) => {
    const { resourceName = '' } = message;

    if (resourceName.includes(CONFIGURATION_KEY_POSTFIX)) {
        const { fields: { configType } = {} } = message;
        return storesToNotClearSilently.includes(configType) ? '' : deCapitalize(camelCase(configType));
    }

    return storesToNotClearSilently.includes(resourceName)
        || storesToNotClearSilently.includes(RESOURCE_NAME_TO_STORE_KEY[resourceName])
        ? '' : RESOURCE_NAME_TO_STORE_KEY[resourceName];
};

export { getStoreKeyToClear };
