import { FormTextField } from 'Generic/textfield/components/TextField';
import { SM_6 } from 'Commons/config/constants/GridSpacingConstants';
import { IS_EMAIL, IS_MAX_LENGTH_15, IS_MAX_LENGTH_20, IS_MAX_LENGTH_254 } from 'Commons/config/constants/Validators';
import localisable from 'Commons/config/strings/localisable';
import { memo, useState } from 'react';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { FormCheckbox } from 'Generic/checkbox/components/CheckBox';
import { FormDropdown } from 'Generic/dropdown/components/Dropdown';
import StandardUserAccessible from 'Commons/components/generic/usertypeaccessible/components/StandardUserAccessible';
import { isMigrationUser, isSyrasoftAdminOrDevLead, isSyrasoftSales } from 'Commons/helpers/utils/Utils';
import Modal from 'Commons/components/generic/modal/components/Modal';
import Button from 'Commons/components/generic/button/components/Button';
import { SUPPORT_EMAIL, TECH_SUPPORT_PHONE_NUMBER } from 'External/containers/dashboard/widgets/header/configs/Constants';
import basicInformationStyle from '../styles/BasicInformationStyle';
import { verifyEmail } from '../config/ApiRequests';
import { BAD_REQUEST_STATUS_CODE, ERROR_CODE } from '../config/Constants';

const useStyles = makeStyles(basicInformationStyle);


const BasicInformation = ({ defaultFacilityList, isFormActive, isModeCreate, formProps, onAction, accountId }) => {
    const classes = useStyles();
    const [openAlertModal, setAlertModalStatus] = useState(false);
    const { errors: { email: emailErrors = '' } = {}, setFieldValue } = formProps || {};
    const getTitle = () => (
        <Grid container item xs={12} alignItems="center" className={classes.title}>
            <Typography variant="h6" disabled={!isFormActive}>
                {localisable.basicInformation}
            </Typography>
        </Grid>
    );

    const shouldEnableEmailField = () => isModeCreate || isSyrasoftAdminOrDevLead() || isSyrasoftSales() || isMigrationUser();

    const onEmailVerification = (apiError) => {
        if (apiError) {
            const { body: { errors: [{ errorCode = '' } = {}] = [] } = {}, status } = apiError;
            if (status === BAD_REQUEST_STATUS_CODE && errorCode === ERROR_CODE.not_unique) {
                setAlertModalStatus(true);
            }
        }
    };

    const handleEmailValidation = (_, value) => {
        if (!emailErrors) {
            const { initialValues: { email: initialEmailValue = '' } = {} } = formProps;
            const body = { email: value };
            if (`${initialEmailValue}` !== `${value || ''}`) {
                verifyEmail({ onAction, body, accountId, callback: onEmailVerification });
            }
        }
    };

    const getFormElement = () => (
        <Grid container>
            <FormTextField
                name="firstName"
                useField
                label={localisable.firstName}
                fullWidth
                validate={IS_MAX_LENGTH_20}
                GridProps={SM_6}
                required
            />

            <FormTextField
                name="lastName"
                useField
                label={localisable.lastName}
                fullWidth
                validate={IS_MAX_LENGTH_20}
                GridProps={SM_6}
                required
            />

            <FormTextField
                name="email"
                useField
                label={localisable.emailId}
                fullWidth
                validate={{ ...IS_EMAIL, ...IS_MAX_LENGTH_254 }}
                GridProps={SM_6}
                required
                disabled={!shouldEnableEmailField()}
                onBlur={handleEmailValidation}
            />

            <FormTextField
                name="phoneNumber"
                useField
                label={localisable.phoneNumber}
                fullWidth
                validate={IS_MAX_LENGTH_15}
                GridProps={SM_6}
            />

            <StandardUserAccessible>
                <FormDropdown
                    name="defaultFacility"
                    label={localisable.defaultFacility}
                    fullWidth
                    useField
                    GridProps={SM_6}
                    list={defaultFacilityList}
                />
            </StandardUserAccessible>

            <Grid container item xs={12} sm={6} className={classes.checkbox}>
                <FormCheckbox
                    name="hasTwoFactor"
                    label={localisable.enableTwoFactorAuthentication}
                    color="primary"
                    disableRipple
                    useField
                    noGrid
                />
            </Grid>
        </Grid>
    );

    const handleModalClose = () => {
        setFieldValue('email', '');
        setAlertModalStatus(false);
    };

    const getModalHeader = () => (
        <Typography variant="h3" className={classes.headerTitle}>
            {localisable.userEmailConflict}
        </Typography>
    );

    const getModalBody = () => (
        <Grid className={classes.bodyEnclosure}>
            <Grid>
                <Typography variant="h4" className={classes.bodyText} width="100%">
                    {localisable.emailConflictMessage}
                </Typography>
            </Grid>
            <Grid className={classes.customerSupportEnclosure}>
                <Typography variant="h4" className={classes.customerSupport}>
                    <span className={classes.customerSupportBold}>{localisable.customerSupportNumber}: </span>
                    <span>{TECH_SUPPORT_PHONE_NUMBER}</span>
                </Typography>
                <Typography variant="h4" className={classes.customerSupport}>
                    <span className={classes.customerSupportBold}>{localisable.customerSupportMail}: </span>
                    <span>{SUPPORT_EMAIL}</span>
                </Typography>
            </Grid>
        </Grid>
    );

    const getModalFooter = () => (
        <Grid className={classes.footerEnclosure}>
            <Grid>
                <Button className={classes.buttonProps} color="error" onClick={handleModalClose}>
                    <Typography variant="h4" className={classes.buttonText}>
                        {localisable.noExitCaps}
                    </Typography>
                </Button>
            </Grid>
            <Grid>
                <a href={`tel:${TECH_SUPPORT_PHONE_NUMBER}`}>
                    <Button className={classes.buttonProps} color="primary">
                        <Typography variant="h4" className={classes.buttonText}>
                            {localisable.callSupport.toUpperCase()}
                        </Typography>
                    </Button>
                </a>
            </Grid>
            <Grid>
                <a href={`mailto:${SUPPORT_EMAIL}`}>
                    <Button className={classes.buttonProps} color="primary">
                        <Typography variant="h4" className={classes.buttonText}>
                            {localisable.emailSupport.toUpperCase()}
                        </Typography>
                    </Button>
                </a>
            </Grid>
        </Grid>
    );

    const renderModal = () => (
        <Modal
            open={!!openAlertModal}
            header={getModalHeader()}
            footer={getModalFooter()}
            classes={{
                modalGrid: classes.modalGrid,
                modalHeader: classes.modalHeader,
                modalBody: classes.modalBody,
                closeButton: classes.closeButton,
            }}
            onClose={handleModalClose}
            disableBackdropClick
        >
            {getModalBody()}
        </Modal>
    );

    return (
        <Grid className={classes.block}>
            {
                getTitle()
            }
            {
                getFormElement()
            }
            {
                renderModal()
            }
        </Grid>
    );
};

BasicInformation.propTypes = {
    isFormActive: PropTypes.bool,
    isModeCreate: PropTypes.bool,
    defaultFacilityList: PropTypes.array,
    formProps: PropTypes.object,
    onAction: PropTypes.func,
    accountId: PropTypes.string,
};

BasicInformation.defaultProps = { defaultFacilityList: [] };

export default memo(BasicInformation);
