const SavedDocumentsListFilterStyle = theme => ({
    tenantMultiSelect: { paddingTop: theme.spacing(5) },
    firstLabel: { paddingBottom: theme.spacing(2) },
    label: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(2),
    },
    chipWrapper: { minHeight: theme.spacing(16) },
});

export default SavedDocumentsListFilterStyle;
