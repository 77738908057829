import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const storeKey = [
    'ar', 'unpaidAr', 'tenant', 'savedDocument', 'unitBev',
    'unitBevMini', 'placeholder', 'level',
];

const formatter = key => key.replace(/ /g, '_').split(/(?=[A-Z])/).join('_').toUpperCase();

const STORE_KEY = createEnumMap(storeKey, formatter);

export default STORE_KEY;
