import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { FormDropdown } from 'Commons/components/generic/dropdown/components/Dropdown';
import { dateFormatter } from 'Commons/helpers/utils/DateTime';
import { FormDatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import { SEARCH_DATE_FORMAT } from 'Commons/config/constants/Constants';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { FormSmartSearchableDropdown } from 'Generic/dropdown/components/SearchableDropdown';
import { MEDIUM_SD_HEIGHT } from 'Commons/components/generic/dropdown/config/Constants';
import { getOptionProps, labelFormatter } from 'Commons/helpers/utils/SDFormatter';
import { isLiveData } from 'Commons/helpers/utils/Utils';
import { XS_6_MD_6 } from 'Commons/config/constants/GridSpacingConstants';
import { getApiConfig, getPaymentMethodApiConfig } from 'External/containers/manualForm/config/ApiRequests';
import { optionsChargeCategoryFormatter, optionsPaymentMethodFormatter } from 'External/containers/inventory/form/utils/Formatters';
import RangeFilter from '../../rangeFilter/components/RangeFilter';
import LedgerReviewFilterStyle from '../styles/LedgerReviewFilterStyle';

const useStyles = makeStyles(LedgerReviewFilterStyle, { name: 'LedgerReviewFilter' });

const LedgerReviewFilter = ({ formProps: { setFieldValue } = {}, unitList, entityId }) => {
    const classes = useStyles();
    const {
        rangeFilterClasses,
        paymentMethodApiConfig,
        chargeCategoryApiConfig,
    } = useConstructor(() => ({
        rangeFilterClasses: { clearBalance: classes.justifyRight },
        paymentMethodApiConfig: getPaymentMethodApiConfig(entityId),
        chargeCategoryApiConfig: getApiConfig(entityId),
    }));

    const onChargeCategoryDropdownChange = (_, value) => {
        if (!value) {
            setFieldValue('terms.chargeCategoryId', null);
        }
    };

    const onPaymentMethodDropdownChange = (_, value) => {
        if (!value) {
            setFieldValue('terms.paymentMethodId', null);
        }
    };

    return (
        <>
            <Typography variant="subtitle1">
                {localisable.unit}
            </Typography>
            <FormDropdown
                name="terms.unit.id.0"
                list={unitList}
                placeholder={localisable.selectUnit}
                useField
                noGrid
                noIndicatorWrapper
            />
            <Typography variant="subtitle1" className={classes.paddingTop}>
                {localisable.createdDate}
            </Typography>
            <FormDatePicker
                name="range.createdDate.from"
                placeholder={localisable.selectDate}
                noGrid
                noIndicatorWrapper
                onChange={date => setFieldValue('range.createdDate.to', dateFormatter(date, SEARCH_DATE_FORMAT))}
            />
            {
                isLiveData()
                && (
                    <>
                        <Typography variant="subtitle1" className={classes.paddingTop}>
                            {localisable.chargeCategory}
                        </Typography>
                        <FormSmartSearchableDropdown
                            placeholder={localisable.selectChargeCategory}
                            name="terms.chargeCategoryId.0"
                            autoSuggestKey="matchPhrasePrefix.label"
                            fetchOnce
                            keyToMatch="id"
                            maxHeight={MEDIUM_SD_HEIGHT}
                            inputValueFormatter={labelFormatter}
                            getOptionProps={getOptionProps}
                            GridProps={XS_6_MD_6}
                            apiConfig={chargeCategoryApiConfig}
                            optionFormatter={optionsChargeCategoryFormatter}
                            noIndicatorWrapper
                            noGrid
                            onChange={onChargeCategoryDropdownChange}
                        />
                        <Typography variant="subtitle1" className={classes.paddingTop}>
                            {localisable.paymentMethod}
                        </Typography>
                        <FormSmartSearchableDropdown
                            placeholder={localisable.selectPaymentMethod}
                            name="terms.paymentMethodId.0"
                            autoSuggestKey="matchPhrasePrefix.label"
                            fetchOnce
                            keyToMatch="id"
                            maxHeight={MEDIUM_SD_HEIGHT}
                            inputValueFormatter={labelFormatter}
                            getOptionProps={getOptionProps}
                            GridProps={XS_6_MD_6}
                            apiConfig={paymentMethodApiConfig}
                            optionFormatter={optionsPaymentMethodFormatter}
                            noIndicatorWrapper
                            noGrid
                            onChange={onPaymentMethodDropdownChange}
                        />
                    </>
                )
            }
            <RangeFilter
                name="effectiveDate"
                setFieldValue={setFieldValue}
                label={localisable.dateRange}
                isDateRange
                classes={rangeFilterClasses}
                fromAndToPlaceholder={localisable.selectDate}
            />
        </>
    );
};

LedgerReviewFilter.propTypes = {
    formProps: PropTypes.object,
    unitList: PropTypes.array,
    entityId: PropTypes.string,
};

export default memo(LedgerReviewFilter);
