/* eslint-disable import/prefer-default-export */
const ERROR = 2;

const LARGE_SD_HEIGHT = 300;
const NORMAL_SD_HEIGHT = 250;
const MEDIUM_SD_HEIGHT = 200;
const SMALL_SD_HEIGHT = 150;
const EXTRA_SMALL_SD_HEIGHT = 100;

export { ERROR, LARGE_SD_HEIGHT, MEDIUM_SD_HEIGHT, NORMAL_SD_HEIGHT, SMALL_SD_HEIGHT, EXTRA_SMALL_SD_HEIGHT };
