import { capitalize } from 'Commons/helpers/utils/DataHelpers';
import { isInternalDashboard } from 'Commons/helpers/utils/Utils';
/* eslint-disable global-require */


/**
* @description Search Action config and return the HTTP request, if key and method exists
* @param {*} storeKey The store key to search in action config
* @param {*} method the method type to get
*/
export const getActionConfig = (storeKey = '', method = '') => {
    const ActionConfig = isInternalDashboard()
        ? require('../../../../internal/redux/config/Action').default
        : require('../../../../external/redux/config/Action').default;
    return ActionConfig && ActionConfig[storeKey] && ActionConfig[storeKey][method]
        ? { timestamp: Date.now(), ...ActionConfig[storeKey][method] }
        : null;
};

/**
* @description Search for dynamic config in onMountConfigs
* @param {String} storeKey The key to search for in config
* @param {Object} store The store keys component has access to
* @param {String} actionConfig The static config of the store key
*/
export const getDynamicConfigFromOnMount = (onMount = {}, storeKey, store = {}, actionConfig = {}, routeProps = {}, storeConfig) => {
    const onMountConfigs = isInternalDashboard()
        ? require('../../../../internal/redux/config/onMountConfigs').default
        : require('../../../../external/redux/config/onMountConfigs').default;
    const key = capitalize(storeKey);
    const { [storeKey]: { funcName = `resolve${key}` } = {} } = onMount;
    // If store key exists return config
    const getOnMountConfig = onMountConfigs && onMountConfigs[funcName];
    const onMountConfig = getOnMountConfig && getOnMountConfig(store, actionConfig, routeProps, storeConfig);
    if (onMountConfig) {
        const { api, ...rest } = onMountConfig;
        return {
            ...(api && {
                api: {
                    ...api,
                    timestamp: Date.now(),
                },
            }),
            ...rest,
        };
    }
    return undefined;
};

export const getCustomHeaderConfigByStoreKey = (storeKey) => {
    const CustomHeaderStoreConfig = isInternalDashboard()
        ? require('../../../../internal/redux/config/CustomHeadersConfigs').StoreCustomHeaders
        : require('../../../../external/redux/config/CustomHeadersConfigs').StoreCustomHeaders;
    // If store key exists return config
    if (CustomHeaderStoreConfig && storeKey) {
        return CustomHeaderStoreConfig[storeKey];
    }
    return null;
};
