import localisable from 'Commons/config/strings/localisable';
import { memo, useMemo } from 'react';
import MultiSelect from 'Generic/multiselect/components/MultiSelect';
import { getOptionProps, labelFormatter } from 'Commons/helpers/utils/SDFormatter';
import { getUnitApiConfig } from 'Commons/components/business/filtersAndSorts/components/rentAdjustmentSummaryFilter/config/ApiRequests';
import { getValue } from 'Commons/helpers/utils/DataHelpers';
import RentAdjustmentSummaryFilterStyle
from 'Commons/components/business/filtersAndSorts/components/rentAdjustmentSummaryFilter/styles/RentAdjustmentSummaryFilterStyle';
import { makeStyles } from 'Generic/componentlibrary/components/Components';

const useStyles = makeStyles(RentAdjustmentSummaryFilterStyle, { name: 'RentAdjustmentSummaryFilterStyle' });

const RentAdjustmentSummaryFilter = ({ formProps: { setFieldValue, values = {} } = {}, selectedFacility }) => {
    const formField = 'terms.summary__unit_id';
    const selectedUnits = getValue(values, formField) || [];

    const unitApiConfig = useMemo(() => getUnitApiConfig(selectedFacility), [selectedFacility]);

    const onSelectOrRemoveUnitType = (selectedUnitIds) => {
        setFieldValue(formField, Object.keys(selectedUnitIds));
    };

    const classes = useStyles();

    return (
        <MultiSelect
            placeholder={localisable.selectUnit}
            onSelect={onSelectOrRemoveUnitType}
            onRemove={onSelectOrRemoveUnitType}
            getOptionProps={getOptionProps}
            keyToMatch="id"
            apiConfig={unitApiConfig}
            maxHeight={150}
            initiallySelectedValues={selectedUnits}
            inputValueFormatter={labelFormatter}
            componentContainerLabel={localisable.units}
            containerProps={{ className: classes.unitMultiSelect }}
            PopdownProps={{ PopperProps: { modifiers: { preventOverflow: { enabled: false } } } }}
        />
    );
};

RentAdjustmentSummaryFilter.propTypes = {
    formProps: PropTypes.object,
    selectedFacility: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default memo(RentAdjustmentSummaryFilter);
