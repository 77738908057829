import moment from 'moment-timezone';

const DEPRECATED_TIME_ZONES = ['UCT', 'PST8PDT', 'GB', 'MST7MDT', 'EST5EDT', 'W-SU', 'CST6CDT', 'HST', 'MST',
    'Universal', 'EET', 'WET', 'EST', 'CET', 'MET', 'GMT', 'Etc'];

const sortByGmt = timezone => timezone.sort((timezoneA, timezoneB) => {
    const { label: labelA } = timezoneA;
    const { label: labelB } = timezoneB;
    const [ahh, amm] = labelA.split('GMT')[1].split(')')[0].split(':');
    const [bhh, bmm] = labelB.split('GMT')[1].split(')')[0].split(':');
    return (+ahh * 60 + amm) - (+bhh * 60 + bmm);
});

const getTimezoneLabel = timezone => `(GMT${moment.tz(timezone).format('Z')}) ${timezone}`;

const getTimezoneList = ({ start = 0, size = 20, autoSuggest } = {}) => {
    const deprecatedTimeZonesRegex = `^${DEPRECATED_TIME_ZONES.join('|^')}`;
    const timezones = moment.tz.names();
    let offsetTmz = timezones
        .filter(timezone => timezone.startsWith('A') || !new RegExp(deprecatedTimeZonesRegex).test(timezone))
        .map(timezone => ({
            label: getTimezoneLabel(timezone),
            value: timezone,
        }));
    if (autoSuggest) {
        offsetTmz = offsetTmz.filter((row) => {
            const pattern = new RegExp(autoSuggest, 'i');
            const labelWithOffset = row.label;
            const [, region] = labelWithOffset.split(' ');
            return region.match(pattern);
        });
    }

    const sortedTimeZones = sortByGmt(offsetTmz);
    const data = size ? sortedTimeZones.slice(start, start + size) : sortedTimeZones;

    return { data, totalCount: sortedTimeZones.length };
};

export { getTimezoneList, getTimezoneLabel };
