import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import styles from '../styles/LinkStyle';
import { Grid, makeStyles, NavLink as ReactLink } from '../../componentlibrary/components/Components';
import LabelWithIcon from '../../labelwithicon/components/LabelWithIcon';

const useStyles = makeStyles(styles, { index: 1, name: 'linkStyle' });

const Link = ({ className, children, addon, LabelWithIconProps, activeClassName, isActive, ...linkProps }) => {
    const classes = useStyles(linkProps);
    return (
        <Grid className={`link ${classes.link}`} item>
            <ReactLink
                className={({ isActive: isRouteActive }) => (isActive() || isRouteActive
                    ? `${classes.reactlink} ${className} ${activeClassName}`
                    : `${classes.reactlink} ${className}`)}
                {...linkProps}
            >
                <LabelWithIcon addon={addon} {...LabelWithIconProps}>{children}</LabelWithIcon>
            </ReactLink>
        </Grid>
    );
};

Link.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    addon: PropTypes.shape({
        start: PropTypes.node,
        end: PropTypes.node,
    }),
    classes: PropTypes.object,
    LabelWithIconProps: PropTypes.object,
    activeClassName: PropTypes.string,
    isActive: PropTypes.func,
};

Link.defaultProps = {
    className: '',
    addon: {},
    LabelWithIconProps: {},
    isActive: EMPTY_FUNC,
};

export default Link;
