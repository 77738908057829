import localisable from 'Commons/config/strings/localisable';
import { memo } from 'react';
import RangeFilter from '../../rangeFilter/components/RangeFilter';


const SavedDocumentFilter = ({ formProps: { setFieldValue } = {} }) => (
        <>
            <RangeFilter
                name="createdDate"
                setFieldValue={setFieldValue}
                label={localisable.documentsIssuedBetween}
                isDateRange
            />
        </>
);

SavedDocumentFilter.propTypes = { formProps: PropTypes.object };


export default memo(SavedDocumentFilter);
