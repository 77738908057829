/**
 * Example Queue
 * [
 *  {
 *      endPoint: 'user/',
 *      methodType: 'GET',
 *      timestamp: 1531819442177,
 *      queryParams: {}
 *  }
 * ]
 */

import { action } from 'Commons/redux/helper/Store';

export class Queue {
    constructor() {
        this.queue = [];
        this.pendingQueue = [];
    }

    add(reqAction) {
        // console.log('Adding to Queue:', action, this.queue, this.pendingQueue);
        if (this.queue.length < parseInt(process.env.API_QUEUE_LIMIT, 10)) {
            this.queue.push(reqAction);
            return true;
        } if (!(this.queue.findIndex(({ timestamp } = {}) => timestamp === reqAction.timestamp) > -1)) {
            // this.pendingQueue.unshift(action);
            this.pendingQueue.push(reqAction);
            return false;
        }
        return false;
    }

    /**
     * @description Removing existing request from the queue
     * @param {*} timestamp The timestamp of the request to be removed
     */
    remove(timestamp, storeData) {
        // let removedElement;
        if (this.queue.length > 0) {
            const elementIndex = this.queue.findIndex(
                ({ timestamp: currentTimeStamp } = {}) => timestamp === currentTimeStamp,
            );
            // removedElement = elementIndex && (elementIndex.index > -1) ? this.queue.splice(elementIndex, 1) : this.queue;
            if (elementIndex > -1) {
                this.queue.splice(elementIndex, 1);
            }
            if (this.pendingQueue.length > 0) {
                const reqOptions = this.pendingQueue.shift();
                action(reqOptions, reqOptions.dispatch, storeData);
            }
        }
    }

    /**
     * @description Get the current queue
     */
    get() {
        return this.queue;
    }
}

export const curQueue = new Queue();
