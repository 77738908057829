import media from 'Commons/theme/MediaQueries';

const PhoneNumberStyles = theme => ({
    flagButton: {
        minWidth: 16,
        [media.laptopUp]: { minWidth: 16 },
        [media.desktopUp]: { minWidth: 20 },
        padding: 0,
        height: theme.spacing(2),
    },
    flagIcon: {
        width: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    native: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        padding: theme.spacing(1),
    },
    nativeRoot: {
        padding: 0,

        '& + svg': { display: 'none' },
    },
    nativeSelect: {
        padding: 0,
        lineHeight: 0,
        height: 11,
    },
    positionStart: { position: 'relative' },
    leftMarginIcon: {},
    showAllCountries: {
        padding: theme.spacing(2, 2, 2, 2),
        '&:hover': { background: theme.palette.highlight },
    },
});

export default PhoneNumberStyles;
