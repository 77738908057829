const linkStyles = theme => ({
    link: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    reactlink: {
        display: 'flex',
        flex: '1 1 auto',
        height: '100%',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        maxWidth: '100%',
        '&:hover': { color: theme.palette.secondary.main },
    },
});

export default linkStyles;
