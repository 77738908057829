import { memo } from 'react';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { clsx } from 'Commons/helpers/utils/clsx';
import { STATUS } from 'Commons/config/constants/Constants';
import enums from '../config/Enums';
import triangleStyle from '../styles/TriangleStyle';

const useStyles = makeStyles(triangleStyle);
const { deviceInfo: { isMobile } } = window;

const Triangle = ({
    children, triangleClassName, titleClassName, variant,
    classes: classesProp, typographyVariant,
}) => {
    const classes = useStyles({ classes: classesProp });
    return (
        <div className={classes.triangleContainer}>
            <div className={clsx(classes.triangle, classes[`status${variant}`], triangleClassName)} />
            <Typography
                variant={typographyVariant || isMobile ? 'subtitle2' : 'subtitle1'}
                fontFamily="Open Sans"
                className={clsx(classes.title, titleClassName)}
            >
                {children}
            </Typography>
        </div>
    );
};

Triangle.propTypes = {
    children: PropTypes.node,
    triangleClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    classes: PropTypes.object,
    variant: PropTypes.oneOf(enums.variants),
    typographyVariant: PropTypes.string,
};

Triangle.defaultProps = {
    variant: STATUS.Active.value,
    triangleClassName: '',
    titleClassName: '',
};

export default memo(Triangle);
