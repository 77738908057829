import Typography from 'Generic/typography/components/Typography';
import { clsx } from 'Commons/helpers/utils/clsx';
import Page from 'Commons/components/business/page/components/Page';
import Icon from '../../../generic/icon/components/Icon';
import {
    Popper,
    Paper,
    Grid,
    withStyles,
    ClickAwayListener,
    RootRef,
    Loader,
} from '../../../generic/componentlibrary/components/Components';
import tablePaperStyle from '../styles/TablePaperStyle';
import { TextField } from '../../../generic/textfield/components/TextField';

class TablePaper extends React.Component {
    constructor(props) {
        super(props);
        const { classes } = props;
        this.state = {
            searchButtonOpen: false,
            isInputEmpty: true,
            open: false,
        };
        this.tablePaperHeaderRef = React.createRef();
        this.moreIconRef = React.createRef();
        this.inputProps = { autoFocus: false };
        this.addon = {
            start:
                <Icon
                    icon="cp-search"
                    type="custom"
                    className={classes.icon}
                />,
        };
    }

    showClearButton = (shouldShow) => {
        const { classes } = this.props;
        if (shouldShow) {
            this.addon.end = (
                <Icon
                    icon="cp-close"
                    type="custom"
                    className={classes.icon}
                    onClick={() => this.onChange('')}
                    color="primary"
                    size="medium"
                />
            );
        } else {
            delete this.addon.end;
        }
    }

    onClick = () => {
        this.setState({ searchButtonOpen: true });
    };

    onChange = (event = {}) => {
        const { target: { value = '' } = {} } = event;
        const inputLength = value.length;
        const { onSearchChange } = this.props;
        const { isInputEmpty } = this.state;
        if (!inputLength) {
            this.showClearButton(false);
            this.setState({ isInputEmpty: true });
        } else if (isInputEmpty) {
            this.showClearButton(true);
            this.setState({ isInputEmpty: false });
        }
        this.setState(prevState => (prevState.value !== value ? { value } : null));
        onSearchChange(event);
    }

    onClickAway = (e) => {
        e.stopPropagation();
        const { isInputEmpty } = this.state;
        if (isInputEmpty) {
            this.setState({ searchButtonOpen: false });
        }
    }

    onClickAwayPaper = (e) => {
        this.isMoreIconClickedOnClickAway = e.target === this.moreIconRef.current;
        const { onSearchChange } = this.props;
        this.setState({ open: false });
        onSearchChange();
    }

    showOptions = () => {
        this.setState({
            open: !this.isMoreIconClickedOnClickAway,
            searchButtonOpen: false,
        }, () => {
            this.isMoreIconClickedOnClickAway = false;
        });
    }

    componentOnClickHandler = (action) => {
        const { history } = this.props;
        if (typeof action === 'function') {
            action();
        } else {
            history.push(action);
        }
    }

    renderActionItems = () => {
        const { classes, tablePaperComponents = [] } = this.props;
        const { deviceInfo: { isDesktop } } = window;
        return (
            tablePaperComponents.length > 0
            && (
                <Grid container wrap="nowrap" direction={isDesktop ? 'row' : 'column'}>
                    {tablePaperComponents.map(({
                        isLoading,
                        isVisible,
                        name,
                        icon,
                        open,
                        color = 'primary',
                        component = 'div',
                        disabled = false,
                    }) => isVisible && (

                        <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            className={classes.componentLink}
                            key={name}
                            onClick={disabled ? null : () => this.componentOnClickHandler(open)}
                            wrap="nowrap"
                        >
                            {
                                isLoading ? <Loader size={20} />
                                    : (
                                        <>
                                            <Grid item>
                                                <Icon icon={icon} type="custom" color={color} disabled={disabled} />
                                            </Grid>
                                            <Grid item className={classes.actionItemLabel}>
                                                <Typography
                                                    color={color}
                                                    component={component}
                                                    disabled={disabled}
                                                >
                                                    {name}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )
                            }
                        </Grid>
                    ))}
                </Grid>
            )
        );
    }

    renderSearch = () => {
        const {
            props: { classes, showFilter, openSearchTextField, hideSearch },
            state: { searchButtonOpen },
        } = this;

        if (hideSearch) {
            return null;
        }

        if (openSearchTextField === 'always') {
            return this.getSearchTextField();
        }

        // For openSearchTextField == 'on-click
        return (
            searchButtonOpen
                ? (
                    <ClickAwayListener
                        mouseEvent={searchButtonOpen && 'onClick'}
                        onClickAway={this.onClickAway}
                    >
                        {this.getSearchTextField()}
                    </ClickAwayListener>
                )
                : (
                    <>
                        <Icon
                            icon="cp-search"
                            type="custom"
                            className={`${classes.icon} ${classes.hoverIcon} `}
                            onClick={this.onClick}
                        />
                        {showFilter && (
                            <Icon
                                className={`${classes.icon} ${classes.hoverIcon}`}
                                type="custom"
                                icon="cp-filter"
                                onClick={() => {
                                    const { onFilterClick = () => { } } = this.props;
                                    onFilterClick();
                                }}
                            />
                        )}
                    </>
                )
        );
    }

    getSearchTextField = () => {
        const {
            props: { classes, searchPlaceholder, searchTextFieldPosition },
            state: { value } = {},
            addon,
        } = this;
        const { deviceInfo: { isDesktop } } = window;
        return (searchTextFieldPosition === 'bottom' || isDesktop)
            && (
                <TextField
                    value={value}
                    onChange={this.onChange}
                    className={classes.searchTextField}
                    placeholder={searchPlaceholder}
                    addon={addon}
                    fullWidth
                    InputProps={{ className: classes.searchInputField }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={this.inputProps}
                    trackValue={false}
                />
            );
    }

    getMoreOptions = () => {
        const { classes, tablePaperComponents = [] } = this.props;
        return tablePaperComponents.length > 0
            && (
                <RootRef rootRef={this.moreIconRef}>
                    <Icon
                        className={`${classes.icon}`}
                        type="font"
                        icon="more_vert"
                        onClick={this.showOptions}
                    />
                </RootRef>
            );
    }

    getHeader = () => {
        const {
            title, classes, renderHeader, rowCount, searchTextFieldPosition, showMoreOption,
            headerProps: { className: headerClassName = '', ...headerProps } = {},
        } = this.props;
        const { deviceInfo: { isDesktop } } = window;

        return (
            title
            && (
                <Grid container direction="column" wrap="nowrap" className={classes.fullHeight}>
                    {renderHeader(rowCount)
                        || (
                            <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                className={clsx(headerClassName)}
                                wrap={searchTextFieldPosition === 'bottom' ? 'wrap' : 'nowrap'}
                                {...headerProps}
                            >
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    className={classes.title}
                                >
                                    {title}
                                    {
                                        searchTextFieldPosition === 'bottom'
                                        && (
                                            <Grid item className={classes.openSearchTextField}>
                                                {this.renderSearch()}
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justify="flex-end"
                                    className={classes.actionIcons}
                                    wrap="nowrap"
                                >
                                    {isDesktop || !showMoreOption ? this.renderActionItems() : this.getMoreOptions()}
                                    {
                                        searchTextFieldPosition === 'right'
                                        && (this.renderSearch())
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            ));
    }

    renderPopper() {
        const { open = false } = this.state;
        const { classes } = this.props;
        const anchorEl = this.tablePaperHeaderRef.current;
        return (
            <Popper
                open={open}
                anchorEl={anchorEl}
                className={`${classes.optionsStyle} ${classes.popper}`}
                transition
                placement="bottom-end"
                style={{ top: anchorEl ? anchorEl.clientHeight : null }}
                disablePortal
            >
                <ClickAwayListener
                    mouseEvent="onClick"
                    onClickAway={this.onClickAwayPaper}
                >
                    <Paper square>
                        {this.renderActionItems()}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        );
    }

    render() {
        const { open } = this.state;
        const {
            children, classes, getTablePaperHeaderRef,
            fullHeight, pageTitle, isLoading = false, PageProps,
        } = this.props;
        return (
            <Page
                className={classes.page}
                header={this.getHeader()}
                headerClassName={classes.pageHeader}
                bodyClassName={classes.pageBody}
                headerVariant="With_Content"
                getHeaderRef={getTablePaperHeaderRef}
                fullHeight={fullHeight}
                title={pageTitle}
                loading={isLoading}
                {...PageProps}
            >
                <Grid container direction="column" wrap="nowrap" className={classes.tableContainer}>
                    {children}
                </Grid>
                {open && this.renderPopper()}
            </Page>

        );
    }
}

TablePaper.propTypes = {
    PageProps: PropTypes.object,
    title: PropTypes.node.isRequired,
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    searchPlaceholder: PropTypes.node,
    onSearchChange: PropTypes.func,
    getTablePaperHeaderRef: PropTypes.func,
    onFilterClick: PropTypes.func,
    showFilter: PropTypes.bool,
    hideSearch: PropTypes.bool,
    history: PropTypes.any,
    tablePaperComponents: PropTypes.array,
    renderHeader: PropTypes.func,
    rowCount: PropTypes.number,
    headerProps: PropTypes.object,
    fullHeight: PropTypes.bool,
    pageTitle: PropTypes.string,
    keepSearchOpen: PropTypes.bool,
    SearchTextFieldProps: PropTypes.object,
    openSearchTextField: PropTypes.oneOf(['always', 'on-click']),
    // NOTE: Position is relative to the table paper title title
    searchTextFieldPosition: PropTypes.oneOf(['right', 'bottom']),
    isLoading: PropTypes.bool,
    showMoreOption: PropTypes.bool,
};

TablePaper.defaultProps = {
    PageProps: {},
    searchPlaceholder: 'Search',
    onSearchChange: () => { },
    getTablePaperHeaderRef: () => { },
    showFilter: false,
    hideSearch: true,
    headerProps: {},
    renderHeader: () => { },
    fullHeight: false,
    openSearchTextField: 'on-click',
    searchTextFieldPosition: 'right',
    showMoreOption: true,
};

export default withStyles(tablePaperStyle)(TablePaper);
