import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const OPTIONS = {
    RETRIES: 0,
    RETRY_DELAY: 1000,
    RETRY_ON: [501, 503],
};

const REPORT_OPTIONS = {
    RETRIES: 3,
    RETRY_DELAY: 1000,
    RETRY_ON: [500, 20], // 20 is for error code i.e. when request gets timed out by fetchApiWithTimeout
};

const HTTP_HEADER_SOURCE = createEnumMap(['Sandbox']);

export { REPORT_OPTIONS, OPTIONS, HTTP_HEADER_SOURCE };
