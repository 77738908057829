import arrowTheme from '../../../../theme/ArrowTheme';
import media from '../../../../theme/MediaQueries';

const tooltipStyle = theme => ({
    arrow: { color: theme.palette.common.white },
    lightTooltip: {
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[2],
        ...theme.typography.body2,
        padding: theme.spacing(1),
        [media.laptopUp]: { padding: theme.spacing(1, 2) },
    },
    arrowShadow: { ...arrowTheme.arrowShadow },
    tooltipTarget: {
        display: 'inline-flex',
        position: 'relative',
        cursor: 'pointer',
        maxWidth: '100%',
        width: 'fit-content',
    },
    root: { maxWidth: '100%' },
});

export default tooltipStyle;
