import media from '../../../../theme/MediaQueries';

const sideBarStyle = theme => ({
    drawerPaper: {
        position: 'absolute',
        width: '60%',
        top: 64,
        whiteSpace: 'nowrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background: theme.components.dashboard.sideBar.backgroundColor,
        overflowX: 'hidden',
        border: 'none',
        [media.tablet]: { width: '30%' },
        [media.laptopUp]: {
            position: 'relative',
            width: 220,
        },
        height: `calc(100% - ${64}px)`,
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [media.laptopUp]: { width: theme.spacing(8) },
        border: 'none',
        height: `calc(100% - ${64}px)`,
    },
    firstItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        height: 64,
    },
});

export default sideBarStyle;
