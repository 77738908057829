const timezoneApiConfig = {
    search: {
        store: {
            key: 'timezone',
            actionKey: 'timezone_read',
            payload: {},
        },
        method: 'read',
    },
    autoSuggest: {
        store: {
            key: 'timezone',
            actionKey: 'timezone_read',
        },
        method: 'read',
    },
};

export { timezoneApiConfig };
