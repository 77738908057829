const colorLegendStyles = theme => ({
    coloredSquareAndDescriptionContainer: {
        flexWrap: 'nowrap',
        marginTop: theme.spacing(1),
    },
    coloredSquare: {
        marginRight: theme.spacing(2),
        width: 12,
        height: 12,
        outline: 'none',
        backgroundColor: ({ color = theme.palette.black[200] }) => color,
        flex: 'None',
    },
    coloredSquareBorder: { border: '1px solid lightgrey' },
    legendBoardEntry: { marginTop: theme.spacing(4) },
});

export default colorLegendStyles;
