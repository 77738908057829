const labelStyle = theme => ({
    label: {
        ...theme.typography.caption,
        '&$disabled': { color: theme.palette.text.disabled },
    },
    labelItem: { '&.MuiGrid-item': { paddingBottom: 0 } },
    disabled: {},
});

export default labelStyle;
