import media from 'Commons/theme/MediaQueries';

const calendarStyle = theme => ({
    mainContainer: {
        padding: theme.spacing(1, 2),
        height: '100%',
        width: '100%',
        [media.tablet]: { padding: theme.spacing(0, 2, 2, 2) },
        [media.mobile]: { padding: theme.spacing(0, 1, 1, 1) },
        overflow: 'auto',
    },
});

export default calendarStyle;
