const FOCUS_EVENT_LISTENER = 'focus';
const BLUR_EVENT_LISTENER = 'blur';

const getEventListeners = ({ onFocus, onBlur }) => (
    [
        {
            listener: FOCUS_EVENT_LISTENER,
            action: onFocus,
        },
        {
            listener: BLUR_EVENT_LISTENER,
            action: onBlur,
        },
    ]
);

export { getEventListeners, FOCUS_EVENT_LISTENER, BLUR_EVENT_LISTENER };
