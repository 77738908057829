const labelWithIconStyle = theme => ({

    labelWithIcon: { cursor: 'pointer' },
    noCursorPointer: { cursor: 'default' },
    spacing: { marginLeft: theme.spacing(1) },
    label: { ...theme.typography.body1 },
    addonEnd: {},
    addonStart: {},
    noPointerEvent: { pointerEvents: 'none' },
});
export default labelWithIconStyle;
