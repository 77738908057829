import media from 'Commons/theme/MediaQueries';

const PaymentTypeStyle = theme => ({
    paymentTypeContainer: {
        [media.mobileUp]: { padding: '8px 16px' },
        [media.tabletUp]: { padding: '16px 32px' },
        borderRadius: 4,
    },
    secondaryColor: { color: theme.palette.secondary.main },
    actionIcons: { marginLeft: 8, marginRight: 8 },
    noWrap: { flexWrap: 'nowrap' },
    actionsContainer: { width: 'auto' },
    inputClasses: { '$&noBorder': { border: '0px' } },
    noBorder: { },
    dropDown: { '&$disabled': { '&$noBorderBottomDisabled': { borderBottom: 'none' } } },
    disabled: {},
    noBorderBottom: { borderBottom: 'none' },
    receiptDropDown: { alignSelf: 'flex-end' },
    payTypeContainer: {
        [media.mobile]: { justifyContent: 'space-around' },
        [media.tabletUp]: { justifyContent: 'flex-start' },
    },
    payTypeContainerwithCard: { [media.mobile]: { justifyContent: 'flex-start' } },
    noRightMargin: { marginRight: 0 },
    noBorderBottomDisabled: { },
    dropDownWidth: { maxWidth: 150 },
    defaultCursor: { cursor: 'default' },
    cardProcessorBottomMargin: { marginBottom: theme.spacing(4) },
    smallFont: { fontSize: 12 },
    defaultTopMargin: { marginTop: theme.spacing(2) },
    error: { color: theme.palette.error.main },
    warning: { color: theme.palette.warning.main },
    alignCenter: { alignSelf: 'center' },
    alert: ({ width = 0, height, left, top } = {}) => ({
        height,
        width,
        position: 'absolute',
        left,
        top,
    }),
    paper: {
        width: '70%',
        minHeight: '25%',
    },
    radioGroup: { flexDirection: 'row' },
    infoIcon: { fontSize: '3rem' },
    shortTopMargin: { marginTop: theme.spacing(1) },
    errorButton: {
        color: theme.hexWithOpacity(theme.palette.error.main, 7),
        '&:hover': { backgroundColor: theme.hexWithOpacity(theme.palette.error.main, 1) },
    },
    waitButton: {
        color: theme.palette.primary.main,
        '&:hover': { backgroundColor: theme.hexWithOpacity(theme.palette.primary.main, 1) },
    },
    useAchOnFile: { width: 'auto', paddingTop: theme.spacing(2) },
    printCopy: {
        padding: theme.spacing(0, 2, 0, 4),
        marginBottom: theme.spacing(-1),
        [media.laptopUp]: { padding: theme.spacing(0, 0, 0, 4) },
        [media.desktop]: { padding: theme.spacing(0, 2, 0, 4) },
    },
    autoWidth: { width: 'auto' },
    receiptTopMargin: { marginTop: theme.spacing(2) },
    formControlLabel: { marginBottom: theme.spacing(2) },
    receiptDropDownContainer: { display: 'flex', justifyContent: 'flex-start', [media.mobile]: { paddingLeft: theme.spacing(1) } },
});

export default PaymentTypeStyle;
