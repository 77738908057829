import { FormTextField } from 'Generic/textfield/components/TextField';
import { FormDropdown } from 'Commons/components/generic/dropdown/components/Dropdown';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { FormCheckbox } from 'Generic/checkbox/components/CheckBox';
import { useMemo } from 'react';
import Typography from 'Generic/typography/components/Typography';
import userProfileStyles from 'Commons/components/business/userProfile/styles/UserProfileStyles';
import { isSyrasoftUser } from 'Commons/helpers/utils/Utils';

const useStyles = makeStyles(userProfileStyles, { name: 'UserDetails' });

const UserDetails = (props) => {
    const {
        facility,
        userData: {
            firstName,
            lastName,
            email,
        } = {},
    } = props;
    const classes = useStyles();

    const facilityList = useMemo(() => {
        const { data: { data = [] } = {} } = facility;
        return data.map(({ id: value, name: label }) => ({ label, value }));
    }, [facility]);

    const renderInfo = (label, value, component) => {
        const componentToRender = component || (
            <Typography variant="body1">{value}</Typography>
        );
        return (
            <Grid
                container
                className={classes.userInfoFieldContainer}
                alignItems="baseline"
            >
                <Grid item xs={3}><Typography variant="caption">{label}:</Typography></Grid>
                <Grid
                    item
                    xs={9}
                >
                    {componentToRender}
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Grid container className={classes.userDetailsContainer}>
                {renderInfo(localisable.firstName, firstName)}
                {renderInfo(localisable.lastName, lastName)}
                {renderInfo(localisable.email, email)}
                {renderInfo(localisable.phone, undefined, (
                    <FormTextField
                        name="phoneNumber"
                        noGrid
                        noIndicatorWrapper
                    />
                ))}
                {
                    !isSyrasoftUser()
                    && renderInfo(localisable.defaultFacility, undefined, (<FormDropdown
                        name="defaultFacility"
                        useField
                        list={facilityList}
                        noGrid
                        noIndicatorWrapper
                    />))
                }
            </Grid>
            <FormCheckbox
                name="hasTwoFactor"
                label={localisable.enableTwoFactorAuthentication}
                className={classes.checkBoxRoot}
                FormControlLabelProps={{ className: classes.checkboxLabel }}
                noIndicatorWrapper
                noGrid
            />
        </>
    );
};

UserDetails.propTypes = {
    facility: PropTypes.object,
    userData: PropTypes.object.isRequired,
};

UserDetails.defaultProps = { facility: {} };

export default UserDetails;
