import localisable from 'Commons/config/strings/localisable';
import { FAVICON_LOC, WINDOW_SCALE_FACTOR } from '../config/Constants';

const convertToPdfUrl = (data) => {
    const buf = Buffer.from(data, 'base64');
    const blob = new Blob([buf], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
};

const renderInPdf = (pdfUrl, title, openInNewWindow = true) => {
    const windowDimension = openInNewWindow
        ? `height=${WINDOW_SCALE_FACTOR * window.innerHeight},width=${WINDOW_SCALE_FACTOR * window.innerWidth}`
        : undefined;
    const pdfWindow = window.open(pdfUrl, '_blank', windowDimension);

    if (!pdfWindow) {
        // TODO: Show a model to user showing how to allow popups
        return;
    }
    pdfWindow.onload = () => {
        setTimeout(() => {
            pdfWindow.document.title = title || localisable.report;
            let link = pdfWindow.document.querySelector("link[rel~='icon']");
            if (!link) {
                link = pdfWindow.document.createElement('link');
                link.rel = 'icon';
                pdfWindow.document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = FAVICON_LOC;
        }, 600);
    };
};

export { renderInPdf, convertToPdfUrl };
