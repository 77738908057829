import { useState } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import Typography from 'Commons/components/generic/typography/components/Typography';
import Icon from 'Commons/components/generic/icon/components/Icon';
import { makeStyles, Grid, ExpansionPanel as MuiExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from
    'Generic/componentlibrary/components/Components';
import ExpansionPanelStyle from '../styles/ExpansionPanelStyle';

const useStyles = makeStyles(ExpansionPanelStyle, { name: 'Insurance' });

/**
 * Component for showing information in a dotted-bordered-panel that can be expanded or collapsed whenever required.
 */
const ExpansionPanel = ({ header, open, children, onCloseOrOpen, ...props }) => {
    const classes = useStyles();
    const [isPanelOpen, openPanel] = useState(open);

    const handleOpenOrClose = () => {
        openPanel(!isPanelOpen);
        onCloseOrOpen();
    };

    const onPanelClick = () => {
        if (!isPanelOpen) {
            openPanel(true);
        }
    };

    return (
        <MuiExpansionPanel
            className={clsx(classes.panel, isPanelOpen ? classes.panelOpen : classes.panelClose)}
            expanded={isPanelOpen}
            {...props}
        >
            <ExpansionPanelSummary
                onClick={onPanelClick}
                className={classes.panelSummary}
                classes={{ expanded: classes.expanded, root: classes.root, content: classes.content }}
            >
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.panelSummaryAdjustment}
                >
                    <Typography variant="subtitle1">
                        {header}
                    </Typography>
                    <Icon
                        icon={isPanelOpen ? 'cp-close' : 'cp-plus'}
                        type="custom"
                        onClick={handleOpenOrClose}
                        className={classes.panelSummaryIcon}
                    />
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.panelDetails}>
                {children}
            </ExpansionPanelDetails>
        </MuiExpansionPanel>
    );
};

ExpansionPanel.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.any.isRequired,
    onCloseOrOpen: PropTypes.func,
    open: PropTypes.bool,
};

export default ExpansionPanel;
