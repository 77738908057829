import { clsx } from 'Commons/helpers/utils/clsx';
import Flags from 'country-flag-icons/react/3x2';
import {
    InputAdornment, IconButton,
    Menu, NativeSelect, withStyles, Divider,
} from 'Generic/componentlibrary/components/Components';
import { debounce } from 'Commons/helpers/utils/Utils';
import { memoize } from 'Commons/helpers/Memoize';
import { TextField } from 'Commons/components/generic/textfield/components/TextField';
import Form from 'Commons/components/generic/form/components/Form';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import Typography from 'Commons/components/generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import { getIn } from 'Commons/helpers/utils/DataHelpers';
import { getPlainPhoneNumber } from 'External/containers/tenant/form/utils/Utils';
import countryData from '../config/CountryData';
import Item from './Item';
import PhoneNumberStyles from '../styles/PhoneNumberStyles';
import { PHONE_FIELDS } from '../config/Constants';
import { TEXT_SUPPORTED_COUNTRIES } from '../../formcomponents/contact/config/Constants';

// Reference - https://github.com/alexplumb/material-ui-phone-number/blob/master/src/components/index.jsx

// TODO: Convert to Functional Component
class MaterialUiPhoneNumber extends React.Component {
    flags = {};

    guessSelectedCountry = memoize((inputNumber, onlyCountries, defaultCountry, isInitial = false) => {
        const secondBestGuess = onlyCountries.find(({ iso2 }) => iso2 === defaultCountry) || {};
        if (inputNumber.trim() === '') return secondBestGuess;
        let bestGuess = onlyCountries.reduce((selectedCountry, country) => {
            if (inputNumber.startsWith(country.dialCode)) {
                if (country.dialCode.length > selectedCountry.dialCode.length) {
                    return country;
                }
                if (country.dialCode.length === selectedCountry.dialCode.length
                    && country.priority < selectedCountry.priority) {
                    return country;
                }
            }
            return selectedCountry;
        }, { dialCode: '', priority: 10001 }, this);
        if (isInitial) {
            bestGuess = secondBestGuess;
        }
        if (!bestGuess.name) return secondBestGuess;
        return bestGuess;
    });

    constructor(props) {
        super(props);
        let filteredCountries = countryData.allCountries;

        if (props.disableAreaCodes) filteredCountries = this.deleteAreaCodes(filteredCountries);
        if (props.regions) filteredCountries = this.filterRegions(props.regions, filteredCountries);

        const onlyCountries = this.excludeCountries(
            this.getOnlyCountries(props.onlyCountries, filteredCountries), props.excludeCountries,
        );

        const preferredCountries = filteredCountries.filter(country => props.preferredCountries.some(preferredCountry => preferredCountry === country.iso2));

        const { countryCode, areaCode, number } = props.value || {};

        const inputNumber = props.value ? getPlainPhoneNumber({ countryCode, areaCode, number }) : '';

        let countryGuess;
        if (inputNumber.length > 1) {
            // Country detect by value field
            countryGuess = this.guessSelectedCountry(inputNumber.replace(/\D/g, ''), onlyCountries, props.defaultCountry, true) || 0;
        } else if (props.defaultCountry) {
            // Default country
            countryGuess = onlyCountries.find(({ iso2 }) => props.defaultCountry === iso2) || 0;
        } else {
            // Empty params
            countryGuess = 0;
        }

        const countryGuessIndex = countryData.allCountries.findIndex(({ iso2 }) => countryGuess.iso2 === iso2);
        const dialCode = (
            inputNumber.length < 2
            && countryGuess
            && !inputNumber.replace(/\D/g, '').startsWith(countryGuess.dialCode)
        ) ? countryGuess.dialCode : '';

        const formattedNumber = (inputNumber === '' && countryGuess === 0) ? ''
            : this.formatNumber(
                (props.disableCountryCode ? '' : dialCode) + inputNumber.replace(/\D/g, ''),
                countryGuess.name ? countryGuess.format : undefined,
            );


        this.state = {
            formattedNumber,
            placeholder: props.placeholder,
            onlyCountries,
            preferredCountries,
            defaultCountry: props.defaultCountry,
            selectedCountry: countryGuess,
            highlightCountryIndex: countryGuessIndex,
            queryString: '',
            freezeSelection: false,
            debouncedQueryStingSearcher: debounce(this.searchCountry, 100),
            anchorEl: null,
            showAllCountries: false,
        };
    }

    componentDidMount() {
        if (document.addEventListener) {
            document.addEventListener('keydown', this.handleKeydown);
        }
    }

    componentDidUpdate(prevProps) {
        const { value: currentValue = {}, clearValuesOnEmpty } = this.props;
        const { value: prevValue = {} } = prevProps;
        if (clearValuesOnEmpty && Object.keys(prevValue).length && !Object.keys(currentValue).length) { // To check if the value is cleared
            this.handleInput(null, '');
        }
    }

    componentWillUnmount() {
        if (document.removeEventListener) {
            document.removeEventListener('keydown', this.handleKeydown);
        }
    }

    getProbableCandidate = memoize((queryString) => {
        if (!queryString || queryString.length === 0) {
            return null;
        }

        const { onlyCountries } = this.state;

        // don't include the preferred countries in search
        const probableCountries = onlyCountries.filter(country => country.name.toLowerCase().startsWith(queryString.toLowerCase()), this);
        return probableCountries[0];
    });

    getOnlyCountries = (onlyCountriesArray, filteredCountries) => {
        if (onlyCountriesArray.length === 0) return filteredCountries;

        return filteredCountries.filter(country => onlyCountriesArray.some(element => element === country.iso2));
    }

    excludeCountries = (selectedCountries, excludedCountries) => {
        if (excludedCountries.length === 0) {
            return selectedCountries;
        }
        return selectedCountries.filter(selCountry => !excludedCountries.includes(selCountry.iso2));
    }

    filterRegions = (regions, filteredCountries) => {
        if (typeof regions === 'string') {
            const region = regions;
            return filteredCountries.filter(country => country.regions.some(element => element === region));
        }

        return filteredCountries.filter((country) => {
            const matches = regions.map(region => country.regions.some(element => element === region));
            return matches.some(el => el);
        });
    }

    // Countries array methods
    deleteAreaCodes = filteredCountries => filteredCountries.filter(country => country.isAreaCode !== true);

    // Hooks for updated props
    updateDefaultCountry = (country) => {
        const { onlyCountries } = this.state;
        const { disableCountryCode } = this.props;

        const newSelectedCountry = onlyCountries.find(({ iso2 }) => country === iso2);

        this.setState({
            defaultCountry: country,
            selectedCountry: newSelectedCountry,
            formattedNumber: disableCountryCode ? '' : `+${newSelectedCountry.dialCode}`,
        });
    }

    // View methods
    scrollTo = (country) => {
        if (!country) { return; }

        const container = this.dropdownContainerRef;

        if (!container || !document.body) { return; }
        container.scrollTop = country.offsetTop;
    }

    formatNumber = (text, patternArg) => {
        const { disableCountryCode, enableLongNumbers, autoFormat } = this.props;

        let pattern;
        if (disableCountryCode && patternArg) {
            pattern = patternArg.split(' ');
            pattern.shift();
            pattern = pattern.join(' ');
        } else {
            pattern = patternArg;
        }

        if (!text || text.length === 0) {
            return disableCountryCode ? '' : '+';
        }

        // for all strings with length less than 3, just return it (1, 2 etc.)
        // also return the same text if the selected country has no fixed format
        if ((text && text.length < 2) || !pattern || !autoFormat) {
            return disableCountryCode ? text : `+${text}`;
        }

        const formattedObject = pattern.split('').reduce((acc, character) => {
            if (acc.remainingText.length === 0) {
                return acc;
            }
            const { remainingText, formattedText } = acc;

            if (character !== '.') {
                return {
                    formattedText: formattedText + character,
                    remainingText,
                };
            }
            const formattedChar = remainingText[0];
            remainingText.shift();
            return {
                formattedText: formattedText + formattedChar,
                remainingText,
            };
        }, {
            formattedText: '',
            remainingText: text.split(''),
        });

        let formattedNumber;
        if (enableLongNumbers) {
            formattedNumber = formattedObject.formattedText + formattedObject.remainingText.join('');
        } else {
            formattedNumber = formattedObject.formattedText;
        }

        // Always close brackets
        if (formattedNumber.includes('(') && !formattedNumber.includes(')')) formattedNumber += ')';
        return formattedNumber;
    }

    // Put the cursor to the end of the input (usually after a focus event)
    cursorToEnd = () => {
        const { isModernBrowser } = this.props;

        const input = this.inputRef;
        if (input) {
            input.focus();
            if (isModernBrowser) {
                const len = input.value.length;
                input.setSelectionRange(len, len);
            }
        }
    }

    getElement = index => this.flags[`flag_no_${index}`]

    // return country data from state
    getCountryData = (receivedSelectedCountry) => {
        let { selectedCountry } = this.state || {};

        if (receivedSelectedCountry) {
            selectedCountry = receivedSelectedCountry;
        }

        if (!selectedCountry) return {};

        return {
            name: selectedCountry.name || '',
            dialCode: selectedCountry.dialCode || '',
            countryCode: selectedCountry.iso2 || '',
            areaCode: selectedCountry.areaCode || '',
            countryCodeWithoutAreaCode: selectedCountry.countryCode || '',
            areaCodeLength: selectedCountry.hasAreaCodes || 0,
            format: selectedCountry.format || '',
        };
    }

    handleInput = (e, inputValue) => {
        let { selectedCountry: newSelectedCountry, freezeSelection } = this.state;
        const { selectedCountry, formattedNumber: oldFormattedText, onlyCountries, defaultCountry } = this.state;
        const { disableCountryCode, countryCodeEditable, isModernBrowser, onChange } = this.props;

        let formattedNumber = disableCountryCode ? '' : '+';

        if (!countryCodeEditable) {
            const updatedInput = `+${newSelectedCountry.dialCode}`;
            if (inputValue.length < updatedInput.length) {
                return;
            }
        }

        // Does not exceed 15 digit phone number limit
        if (inputValue.replace(/\D/g, '').length > 15) {
            return;
        }

        // if the input is the same as before, must be some special key like enter etc.
        if (inputValue === oldFormattedText) {
            return;
        }

        // ie hack
        if (e) {
            if (e.preventDefault) {
                e.preventDefault();
            } else {
                e.returnValue = false;
            }
        }

        if (inputValue.length > 0) {
            // before entering the number in new format, lets check if the dial code now matches some other country
            const inputNumber = inputValue.replace(/\D/g, '');

            // we don't need to send the whole number to guess the country... only the first 6 characters are enough
            // the guess country function can then use memoization much more effectively since the set of input it
            // gets has drastically reduced
            if (!freezeSelection || selectedCountry.dialCode.length > inputNumber.length) {
                const guessedCountry = this.guessSelectedCountry(inputNumber, onlyCountries, defaultCountry);
                newSelectedCountry = guessedCountry;
                freezeSelection = false;
            }
            // let us remove all non numerals from the input
            formattedNumber = this.formatNumber(inputNumber, newSelectedCountry.format);
        }

        let caretPosition = e ? e.target.selectionStart : 0;
        const diff = formattedNumber.length - oldFormattedText.length;
        this.handleFormFields(formattedNumber, this.getCountryData(newSelectedCountry.dialCode
            ? newSelectedCountry
            : selectedCountry));

        this.setState({
            formattedNumber,
            freezeSelection,
            selectedCountry: newSelectedCountry.dialCode ? newSelectedCountry : selectedCountry,
        }, () => {
            if (isModernBrowser) {
                if (diff > 0) {
                    caretPosition -= diff;
                }

                const lastChar = formattedNumber.charAt(formattedNumber.length - 1);

                if (lastChar === ')') {
                    this.inputRef.setSelectionRange(formattedNumber.length - 1, formattedNumber.length - 1);
                } else if (caretPosition > 0 && oldFormattedText.length >= formattedNumber.length) {
                    this.inputRef.setSelectionRange(caretPosition, caretPosition);
                }
            }

            if (onChange) {
                onChange(e, formattedNumber);
            }
        });
    }

    getAreaCode = (formattedNumber, countryAndAreaData = {}) => {
        const { countryCodeWithoutAreaCode: countryCode, areaCodeLength } = countryAndAreaData;
        const countryCodeLength = countryCode.length;
        const digits = formattedNumber.replace(/\D/g, '');
        return areaCodeLength ? digits.substring(countryCodeLength, areaCodeLength + 1) : null;
    }

    handleFormFields = (formattedNumber, countryAndAreaData = {}) => {
        const { setFieldValue, name, onValidCallback } = this.props;
        const { countryCodeWithoutAreaCode, countryCode, format } = countryAndAreaData;
        const areaCode = this.getAreaCode(formattedNumber, countryAndAreaData);
        const unformattedNumber = formattedNumber.replace(/\D/g, '');
        const number = unformattedNumber.slice(`${areaCode || ''}${countryCodeWithoutAreaCode}`.length);
        const enteredNumberLength = unformattedNumber.length;
        const numberLength = (format.match(/\./g) || []).length;
        if (setFieldValue) {
            setFieldValue(`${name}.${PHONE_FIELDS.COUNTRY_CODE}`, countryCodeWithoutAreaCode);
            setFieldValue(`${name}.${PHONE_FIELDS.AREA_CODE}`, areaCode);
            setFieldValue(`${name}.${PHONE_FIELDS.COUNTRY}`, countryCode);
            setFieldValue(`${name}.${PHONE_FIELDS.NUMBER}`, number);
        }
        if (enteredNumberLength === numberLength) {
            onValidCallback(unformattedNumber, name);
        }
    }

    handleRefInput = (ref) => {
        const { inputRef, InputProps } = this.props;
        this.inputRef = ref;

        let refProp;

        if (inputRef) {
            refProp = inputRef;
        } else if (InputProps && InputProps.ref) {
            refProp = InputProps.ref;
        }

        if (refProp) {
            if (typeof refProp === 'function') {
                refProp(ref);
            } else {
                refProp.current = ref;
            }
        }
    };

    handleInputClick = (e) => {
        const { onClick } = this.props;

        if (onClick) {
            onClick(e, this.getCountryData());
        }
    }

    handleFlagItemClick = (e, country) => {
        const { error, formattedNumber, selectedCountry } = this.state;
        const { onChange } = this.props;

        const currentSelectedCountry = selectedCountry;
        const nextSelectedCountry = country;

        const unformattedNumber = formattedNumber.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
        const newNumber = !error && unformattedNumber.length > 1 ? unformattedNumber.replace(currentSelectedCountry.dialCode, nextSelectedCountry.dialCode) : nextSelectedCountry.dialCode;

        const newFormattedNumber = this.formatNumber(newNumber.replace(/\D/g, ''), nextSelectedCountry.format);
        this.handleFormFields(newFormattedNumber, this.getCountryData(nextSelectedCountry));

        const numberLength = nextSelectedCountry.format ? (nextSelectedCountry.format.match(/\./g) || []).length : 0;
        const enteredNumberLength = newNumber.length;

        this.setState({
            anchorEl: null,
            selectedCountry: nextSelectedCountry,
            formattedNumber: newFormattedNumber,
            freezeSelection: true, // Freezing Country Selection upon selection of dropdown
            error: enteredNumberLength < numberLength ? `Total number of digits should be ${numberLength}` : '',
        }, () => {
            this.cursorToEnd();
            if (onChange) {
                onChange(e, newFormattedNumber);
            }
        });
    }

    handleInputFocus = (e) => {
        const { selectedCountry } = this.state;
        const { disableCountryCode, onFocus } = this.props;

        // if the input is blank, insert dial code of the selected country
        if (this.inputRef) {
            if (this.inputRef.value === '+' && selectedCountry && !disableCountryCode) {
                this.setState({ formattedNumber: `+${selectedCountry.dialCode}` }, () => setTimeout(this.cursorToEnd, 10));
            }
        }

        this.setState({ placeholder: '' });

        if (onFocus) {
            onFocus(e, this.getCountryData());
        }

        setTimeout(this.cursorToEnd, 10);
    }

    handleInputBlur = (e) => {
        const { placeholder, setFieldTouched, touched, name } = this.props;

        if (!e.target.value) {
            this.setState({ placeholder });
        }

        const phoneTouched = getIn(touched, name) || {};
        const phoneFields = Object.values(PHONE_FIELDS);

        const isPhoneTouched = phoneFields.some(fieldName => phoneTouched[fieldName]);
        if (!isPhoneTouched) {
            phoneFields.forEach((fieldName) => {
                setFieldTouched(`${name}.${fieldName}`, true, false);
            });
        }
    }

    getHighlightCountryIndex = (direction) => {
        const { highlightCountryIndex: oldHighlightCountryIndex, onlyCountries, preferredCountries } = this.state;

        // had to write own function because underscore does not have findIndex. lodash has it
        const highlightCountryIndex = oldHighlightCountryIndex + direction;

        if (highlightCountryIndex < 0 || highlightCountryIndex >= (onlyCountries.length + preferredCountries.length)) {
            return highlightCountryIndex - direction;
        }

        return highlightCountryIndex;
    }

    searchCountry = () => {
        const { queryString, onlyCountries, preferredCountries } = this.state;

        const probableCandidate = this.getProbableCandidate(queryString) || onlyCountries[0];
        const probableCandidateIndex = onlyCountries.findIndex(({ iso2 }) => probableCandidate.iso2 === iso2) + preferredCountries.length;

        this.scrollTo(this.getElement(probableCandidateIndex), true);

        this.setState({ queryString: '', highlightCountryIndex: probableCandidateIndex });
    }

    handleKeydown = (e) => {
        const {
            anchorEl, highlightCountryIndex, preferredCountries, onlyCountries,
            queryString, debouncedQueryStingSearcher,
        } = this.state;
        const { keys, disabled } = this.props;

        if (!anchorEl || disabled) return;

        // ie hack
        if (e.preventDefault) {
            e.preventDefault();
        } else {
            e.returnValue = false;
        }

        const moveHighlight = (direction) => {
            this.setState({ highlightCountryIndex: this.getHighlightCountryIndex(direction) }, () => {
                this.scrollTo(this.getElement(
                    highlightCountryIndex + preferredCountries.length,
                ), true);
            });
        };

        switch (e.which) {
            case keys.DOWN:
                moveHighlight(1);
                break;
            case keys.UP:
                moveHighlight(-1);
                break;
            case keys.ENTER:
                this.handleFlagItemClick(e, onlyCountries[highlightCountryIndex]);
                break;
            case keys.ESC:
                this.setState({ anchorEl: null }, this.cursorToEnd);
                break;
            default:
                if ((e.which >= keys.A && e.which <= keys.Z) || e.which === keys.SPACE) {
                    this.setState({ queryString: queryString + String.fromCharCode(e.which) }, debouncedQueryStingSearcher);
                }
        }
    }

    handleInputKeyDown = (e) => {
        const { keys, onEnterKeyPress, onKeyDown } = this.props;
        if (e.which === keys.ENTER && onEnterKeyPress) {
            onEnterKeyPress(e);
        }

        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    checkIfValid = () => {
        const { formattedNumber } = this.state;
        const { isValid } = this.props;

        return isValid(formattedNumber.replace(/\D/g, ''));
    };

    updateFormattedNumber = (number) => {
        const { onlyCountries, defaultCountry } = this.state;
        const { disableCountryCode } = this.props;

        let countryGuess;
        let inputNumber = number;
        let formattedNumber = number;

        // if inputNumber does not start with '+', then use default country's dialing prefix,
        // otherwise use logic for finding country based on country prefix.
        if (!inputNumber.startsWith('+')) {
            countryGuess = onlyCountries.find(({ iso2 }) => defaultCountry === iso2);
            const dialCode = countryGuess && !inputNumber.replace(/\D/g, '').startsWith(countryGuess.dialCode) ? countryGuess.dialCode : '';
            formattedNumber = this.formatNumber(
                (disableCountryCode ? '' : dialCode) + inputNumber.replace(/\D/g, ''),
                countryGuess ? countryGuess.format : undefined,
            );
        } else {
            inputNumber = inputNumber.replace(/\D/g, '');
            const guessedCountry = this.guessSelectedCountry(inputNumber, onlyCountries, defaultCountry);
            countryGuess = guessedCountry;

            formattedNumber = this.formatNumber(inputNumber, countryGuess.format);
        }

        this.setState({ selectedCountry: countryGuess, formattedNumber });
    };

    getDropdownProps = () => {
        const { selectedCountry, anchorEl, preferredCountries, onlyCountries, showAllCountries } = this.state;

        const {
            classes, dropdownClass, localization, disabled,
            native, keepCountries,
        } = this.props;

        onlyCountries.sort((a, b) => {
            const localizedA = localization[a.name] || a.name;
            const localizedB = localization[b.name] || b.name;
            return localizedA.localeCompare(localizedB);
        });

        const isSelected = country => Boolean(selectedCountry && selectedCountry.iso2 === country.iso2);

        const FlagComponent = Flags[selectedCountry.iso2.toUpperCase()];

        const dropdownProps = disabled ? {} : {
            startAdornment: (
                <InputAdornment
                    className={classes.positionStart}
                    position="start"
                >
                    {native ? (
                        <>
                            <NativeSelect
                                id="country-menu"
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({ anchorEl: null })}
                                className={classes.native}
                                classes={{
                                    root: clsx(classes.nativeRoot, 'native'),
                                    select: classes.nativeSelect,
                                }}
                                onChange={e => this.handleFlagItemClick(e, e.target.value)}
                                IconComponent={Boolean(FlagComponent) && FlagComponent}
                                disableUnderline
                            >
                                {!!preferredCountries.length && preferredCountries.map((country, index) => (
                                    <Item
                                        key={`preferred_${country.iso2}_${index}`}
                                        itemRef={(node) => {
                                            this.flags[`flag_no_${index}`] = node;
                                        }}
                                        name={country.name}
                                        iso2={country.iso2}
                                        dialCode={country.dialCode}
                                        localization={localization && localization[country.name]}
                                        native
                                    />
                                ))}

                                {onlyCountries.map((country, index) => (
                                    <Item
                                        key={`preferred_${country.iso2}_${index}`}
                                        itemRef={(node) => {
                                            this.flags[`flag_no_${index}`] = node;
                                        }}
                                        name={country.name}
                                        iso2={country.iso2}
                                        dialCode={country.dialCode}
                                        localization={localization && localization[country.name]}
                                        native
                                    />
                                ))}
                            </NativeSelect>
                        </>
                    )
                        : (
                            <>
                                <IconButton
                                    className={classes.flagButton}
                                    aria-owns={anchorEl ? 'country-menu' : null}
                                    aria-label="Select country"
                                    onClick={e => this.setState({ anchorEl: e.currentTarget })}
                                    aria-haspopup
                                >
                                    {Boolean(FlagComponent) && <FlagComponent className="margin" />}
                                </IconButton>

                                <Menu
                                    className={dropdownClass}
                                    id="country-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => this.setState({ anchorEl: null })}
                                >
                                    {/* TODO: Uncomment this when area code needs to be shown */}
                                    {/* {!!preferredCountries.length && preferredCountries.map((country, index) => (
                                        <Item
                                            key={`preferred_${country.iso2}_${index}`}
                                            itemRef={(node) => {
                                                this.flags[`flag_no_${index}`] = node;
                                            }}
                                            selected={isSelected(country)}
                                            onClick={e => this.handleFlagItemClick(e, country)}
                                            name={country.name}
                                            iso2={country.iso2}
                                            dialCode={country.dialCode}
                                            localization={localization && localization[country.name]}
                                            className={classes.flagIcon}
                                        />
                                    ))}

                                    {!!preferredCountries.length && <Divider />} */}

                                    {onlyCountries.filter(({ iso2 }) => showAllCountries || keepCountries.includes(iso2)).map((country, index) => (
                                        <Item
                                            key={`preferred_${country.iso2}_${index}`}
                                            itemRef={(node) => {
                                                this.flags[`flag_no_${index}`] = node;
                                            }}
                                            selected={isSelected(country)}
                                            onClick={e => this.handleFlagItemClick(e, country)}
                                            name={country.name}
                                            iso2={country.iso2}
                                            dialCode={country.dialCode}
                                            localization={localization && localization[country.name]}
                                            className={classes.flagIcon}
                                        />
                                    ))}
                                    <Divider />
                                    <LabelWithIcon
                                        onClick={() => { this.setState({ showAllCountries: !showAllCountries }); }}
                                        className={classes.showAllCountries}
                                    >
                                        <Typography>
                                            {showAllCountries ? localisable.showLess : localisable.showMore}
                                        </Typography>
                                    </LabelWithIcon>
                                </Menu>
                            </>
                        )}
                </InputAdornment>
            ),
        };

        return dropdownProps;
    };

    render() {
        const { placeholder: statePlaceholder, formattedNumber } = this.state;

        const {
            // start placeholder props
            native, defaultCountry, excludeCountries, onlyCountries, preferredCountries,
            dropdownClass, autoFormat, disableAreaCodes, isValid, disableCountryCode,
            disableDropdown, enableLongNumbers, countryCodeEditable, onEnterKeyPress,
            isModernBrowser, classes, keys, localization, placeholder, regions, onChange,
            setFieldValue, setFieldTouched, validate, value, onBlur,
            // end placeholder props
            className, error, InputProps, baseFieldName, initialValue, onValidCallback, label,
            ...restProps
        } = this.props;

        const dropdownProps = this.getDropdownProps();

        return (
            // Using FormTextField instead of TextField to send dynamic data to the validator
            <TextField
                placeholder={statePlaceholder}
                className={className}
                inputRef={this.handleRefInput}
                onChange={this.handleInput}
                onClick={this.handleInputClick}
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputBlur}
                onKeyDown={this.handleInputKeyDown}
                type="tel"
                alwaysShowError // To show error even if field is not touched
                value={formattedNumber}
                InputProps={{
                    ...dropdownProps,
                    ...InputProps,
                }}
                label={label}
                {...restProps}
            />
        );
    }
}

MaterialUiPhoneNumber.propTypes = {
    validate: PropTypes.object,
    classes: PropTypes.object,

    keepCountries: PropTypes.arrayOf(PropTypes.string),
    excludeCountries: PropTypes.arrayOf(PropTypes.string),
    onlyCountries: PropTypes.arrayOf(PropTypes.string),
    preferredCountries: PropTypes.arrayOf(PropTypes.string),
    defaultCountry: PropTypes.string,

    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    variant: PropTypes.string,
    native: PropTypes.bool,

    label: PropTypes.string,
    className: PropTypes.string,
    dropdownClass: PropTypes.string,
    InputProps: PropTypes.object,
    inputProps: PropTypes.object,
    inputRef: PropTypes.func,

    autoFormat: PropTypes.bool,
    disableAreaCodes: PropTypes.bool,
    disableCountryCode: PropTypes.bool,
    disableDropdown: PropTypes.bool,
    enableLongNumbers: PropTypes.bool,
    countryCodeEditable: PropTypes.bool,

    regions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),

    localization: PropTypes.object,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,

    isValid: PropTypes.func,
    isModernBrowser: PropTypes.func,
    onEnterKeyPress: PropTypes.func,
    keys: PropTypes.object,
    form: PropTypes.object,
    baseFieldName: PropTypes.string, // path to the field to store the formatted phone number in the broke down format i.e. { area_code, country_code, number, country }.
    name: PropTypes.string,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    touched: PropTypes.object,
    value: PropTypes.object,
    onValidCallback: PropTypes.func,
    clearValuesOnEmpty: PropTypes.bool,
};

MaterialUiPhoneNumber.defaultProps = {
    keepCountries: TEXT_SUPPORTED_COUNTRIES,
    excludeCountries: [],
    onlyCountries: [],
    preferredCountries: [],
    defaultCountry: 'us',

    placeholder: '+1 (702) 123-4567',
    disabled: false,
    error: false,
    variant: 'standard',
    native: false,

    label: '',
    className: '',
    dropdownClass: '',

    autoFormat: true,
    disableAreaCodes: false,
    isValid: inputNumber => countryData.allCountries.some(country => inputNumber.startsWith(country.dialCode) || country.dialCode.startsWith(inputNumber)),
    disableCountryCode: false,
    disableDropdown: false,
    enableLongNumbers: false,
    countryCodeEditable: true,

    regions: '',

    localization: {},

    onEnterKeyPress: () => { },
    onChange: () => { },
    onValidCallback: () => { },

    isModernBrowser: () => (document.createElement ? Boolean(document.createElement('input').setSelectionRange) : false),

    keys: {
        UP: 38,
        DOWN: 40,
        RIGHT: 39,
        LEFT: 37,
        ENTER: 13,
        ESC: 27,
        PLUS: 43,
        A: 65,
        Z: 90,
        SPACE: 32,
    },
};

const StyledPhoneNumber = withStyles(PhoneNumberStyles)(MaterialUiPhoneNumber);
const PhoneNumberForm = Form(StyledPhoneNumber, {}, { error: true });
const FormPhoneNumberField = ({ validate, ...props }) => (
    <PhoneNumberForm
        validate={{
            isValidPhone: true,
            ...validate,
        }}
        skipValueSet
        {...props}
    />
);

FormPhoneNumberField.propTypes = { validate: PropTypes.object };

export { FormPhoneNumberField };
