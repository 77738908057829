import { clsx } from 'Commons/helpers/utils/clsx';
import { dateFormatter } from 'Commons/helpers/utils/DateTime';
import Typography from 'Generic/typography/components/Typography';

const DateFormatter = (props) => {
    const { argument: [, , , { effectiveDate = '', toDate = '' }] } = props;
    const startDate = effectiveDate.substr(0, effectiveDate.indexOf('T'));
    const endDate = toDate.substr(0, toDate.indexOf('T'));
    return (
        <Typography>
            {clsx(startDate && dateFormatter(startDate),
                startDate && endDate && ' to ', endDate && dateFormatter(endDate))}
        </Typography>
    );
};

DateFormatter.propTypes = { argument: PropTypes.array };

const BillAheadDateFormatter = (...data) => <DateFormatter argument={data} />;

export default BillAheadDateFormatter;
