/* eslint-disable no-underscore-dangle */
import { PureComponent } from 'react';
import { fabric } from 'fabric';
import { Grid, withStyles } from 'Generic/componentlibrary/components/Components';
import getUUID from 'Commons/helpers/utils/generateUUID';
import {
    getActualAngle,
    getConvertedWH,
    getEntitiesDataFromCanvas,
    getFontSize,
    getTooltip,
    reconvertWH,
    setTextAngleToZero,
} from 'Commons/components/business/bev/utils/Utils';
import theme from 'Commons/theme/Theme';
import { convertToNumber } from 'Commons/helpers/utils/Utils';
import { isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import {
    CANVAS_BOUNDARY_CONFIG,
    CANVAS_CONFIG,
    CANVAS_DIMENSIONS,
    defaultNewObjXY,
    OBJECT_CONFIG,
    UNIT_CONFIG,
} from '../../config/Config';
import {
    ACTIONS,
    DEFAULT_RENTAL_STATUS,
    DIRECTIONS,
    EVENT_TRIGGERED_BY_TYPE,
    POSITIONS,
    TYPE_OF_ENTITY,
} from '../../config/Constants';
import rotateIcon from '../../../../../assets/images/RotateIcon.svg';
import bevStyles from '../../styles/BevStyles';

class BevSetup extends PureComponent {
    constructor(props) {
        super(props);
        this.canvasWrapperRef = React.createRef();
        this.state = { shouldMaskCanvas: false };
    }

    componentDidMount() {
        const { setupModeDimensions } = CANVAS_DIMENSIONS;
        const {
            props: {
                entities,
                formProps: {
                    resetForm, initialValues,
                    values: { bev: { level: currentLevel } = {} } = {},
                } = {},
            },
            canvasWrapperRef: { current: canvasWrapperRef },
        } = this;

        resetForm({
            ...initialValues,
            bev: {
                ...initialValues.bev,
                level: currentLevel,
            },
        });

        this.modifyFabricCanvasAndObjectPrototype();

        const canvasInstance = new fabric.Canvas('bevSetupCanvas', CANVAS_CONFIG);

        this.attachListenersToCanvasWrapper();

        this.attachEventsToCanvas(canvasInstance);

        const Group = fabric.util.createClass(fabric.Group);

        this.attachEventsToGroup(Group);

        canvasInstance.setDimensions(setupModeDimensions);

        this.labelTooltip = getTooltip(fabric);

        this.fabricCanvas = canvasInstance;
        this.Group = Group;
        this.entities = entities;
        this.drawCanvas();
        canvasWrapperRef.focus();
    }

    componentDidUpdate(prevProps) {
        const { formProps: { errors: { bev: prevErrorsInBev, label: prevLabelError } = {} } = {} } = prevProps;
        const {
            props: {
                formProps: { errors: { bev: errorsInBev, label: labelError } = {} } = {},
                setShouldDisableToolBox,
            } = {},
            state: { shouldMaskCanvas } = {},
        } = this;
        if (prevErrorsInBev !== errorsInBev || prevLabelError !== labelError) {
            const activeObject = this.fabricCanvas.getActiveObject();
            const { type } = activeObject || {};
            if ((errorsInBev || labelError) && !shouldMaskCanvas && activeObject && type === 'group') {
                this.setOrUnsetRedStrokeOnObject(activeObject, false, true);
                setShouldDisableToolBox(true);
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ shouldMaskCanvas: true });
            } else if (!errorsInBev && !labelError && shouldMaskCanvas) {
                this.setOrUnsetRedStrokeOnObject(activeObject, true, true);
                setShouldDisableToolBox(false);
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ shouldMaskCanvas: false });
            }
        }
    }

    componentWillUnmount() {
        this.fabricCanvas.clear();
        this.fabricCanvas.dispose();
    }

    modifyFabricCanvasAndObjectPrototype = () => {
        fabric.Object.prototype.objectCaching = false;
        // overrides top right corner icon to custom icon
        fabric.util.object.extend(fabric.Object.prototype, {
            ...OBJECT_CONFIG,
            _drawControl(control, ctx, _, left, top) {
                if (!this.isControlVisible(control)) {
                    return;
                }
                const { cornerSize } = this;

                const customIconImage = new Image();
                if (control === 'tr') {
                    customIconImage.src = rotateIcon;
                    ctx.drawImage(customIconImage, left, top, cornerSize, cornerSize);
                }
            },
        });

        // overrides top right corner action to rotate and cursor to rotation cursor
        fabric.util.object.extend(fabric.Canvas.prototype, {
            _getActionFromCorner(target, corner) {
                if (!corner) {
                    return ACTIONS.DRAG;
                }
                return ACTIONS.ROTATE;
            },
            getCornerCursor(corner, target, e) {
                if (this.actionIsDisabled(corner, target, e)) {
                    return this.notAllowedCursor;
                }
                if (corner) {
                    return this.rotationCursor;
                }
                return this.defaultCursor;
            },
        });
    }

    attachListenersToCanvasWrapper = () => {
        const {
            props: { handleKeyDown, handleKeyUp },
            canvasWrapperRef: { current: canvasWrapperRef },
        } = this;
        fabric.util.addListener(canvasWrapperRef, 'keydown', target => handleKeyDown(target, this.fabricCanvas));
        fabric.util.addListener(canvasWrapperRef, 'keyup', target => handleKeyUp(target, this.fabricCanvas));
    }

    clearFormValues = (additionalBevValues = {}) => {
        const {
            formProps: {
                values,
                setValues,
                initialValues: { bev: bevInitialValues } = {},
                values: { bev: { level: currentLevel } = {} } = {},
            } = {},
        } = this.props;
        setValues({
            ...values,
            id: undefined,
            label: '',
            width: undefined,
            depth: undefined,
            unitType: undefined,
            placeholderType: undefined,
            entityType: TYPE_OF_ENTITY.Unit.value,
            bev: { ...bevInitialValues, level: currentLevel, ...additionalBevValues },
        });
    }

    handleActiveSelectionType = (event) => {
        const { setRequiredFieldsConfig } = this.props;
        const { target: { angle } = {} } = event;
        setRequiredFieldsConfig({});
        this.clearFormValues({ angle });
    }

    attachEventsToCanvas = (canvasInstance) => {
        const {
            handlePanning,
            handleMouseMove, handleCanvasMouseUp,
            setRequiredFieldsConfig, addToLabelToIdMap,
            setUnitTypeList,
            isFacilityMigrated,
        } = this.props;

        canvasInstance.on('selection:created', (event = {}) => {
            const { target, target: { type } = {} } = event;
            this.controlObjectShadow(event);
            if (type === 'group') {
                this.loadEntityDataIntoForm(target);
            } else if (type === 'activeSelection') {
                this.handleActiveSelectionType(event);
            }
        });

        canvasInstance.on('selection:updated', (event) => {
            const { target, target: { type } = {} } = event;
            this.controlObjectShadow(event);
            if (type === 'group') {
                setUnitTypeList(undefined, true);
                this.loadEntityDataIntoForm(target);
            } else if (type === 'activeSelection') {
                this.handleActiveSelectionType(event);
            }
        });

        canvasInstance.on('object:rotating', ({ target } = {}) => this.handleRotation(target));

        canvasInstance.on('object:modified', (event) => {
            const { target: { type } = {}, transform: { action: eventActionType } = {} } = event;
            const eventToSend = type === 'activeSelection' && eventActionType === 'rotate' ? undefined : event;
            this.handleObjectModification(eventToSend, eventActionType);
        });

        canvasInstance.on('before:selection:cleared', (event) => {
            const { e: { type: triggeredBy } = {} } = event;

            if (triggeredBy === EVENT_TRIGGERED_BY_TYPE.MANUAL) return;

            const selection = this.fabricCanvas.getActiveObject();
            const { type } = selection;

            this.controlObjectShadow(null, true);
            setUnitTypeList(undefined, true, false);
            setRequiredFieldsConfig(undefined, true);
            this.clearFormValues();
            if (type === 'group') {
                const [{
                    label: currentlyActObjLabel,
                    tempId: currentActObjTempId,
                    entityType,
                }] = getEntitiesDataFromCanvas(selection, undefined, isFacilityMigrated);
                if (currentActObjTempId && triggeredBy === 'mousedown'
                && entityType === TYPE_OF_ENTITY.Unit.value) { // 'e' would be undefined when this event (before:selection:cleared) is not fired as a result of a mousedown event. Because this event (before:selection:cleared) is also internally fired by fabric when canvas is being cleared ( i.e. calls discardActiveObject() in canvas.clear() which in-turn fires this event ) in which case 'e' would be 'undefined'
                    addToLabelToIdMap(currentlyActObjLabel, currentActObjTempId);
                }
            }
        });

        canvasInstance.on('mouse:down', event => handlePanning(event, this.fabricCanvas));
        canvasInstance.on('mouse:move', event => handleMouseMove(event, this.fabricCanvas, fabric, this.labelTooltip));
        canvasInstance.on('mouse:up', () => handleCanvasMouseUp(this.fabricCanvas));
    }

    attachEventsToGroup = (Group) => {
        const { props: { handleEntityMouseOver, handleEntityMouseOut, moveTooltipWithPointer } } = this;

        Group.prototype.on('mouseup', ({ e } = {}) => {
            moveTooltipWithPointer({ e }, this.fabricCanvas, fabric, this.labelTooltip);
            // Loading of entity data into form on selection is handled in canvas's 'selection:created/updated' event listener
        });

        Group.prototype.on('mouseover', event => handleEntityMouseOver(event, this.fabricCanvas, this.labelTooltip));

        Group.prototype.on('mousedown', () => { this.labelTooltip.visible = false; });

        Group.prototype.on('mouseout', () => handleEntityMouseOut(this.fabricCanvas, this.labelTooltip));
    }

    getFabricCanvasInstance = () => (this.fabricCanvas);

    transformCoordsAndUpdateEntityData = (action = '', selectionAngle = 0) => {
        const { addToNewAndUpdatedEntitiesList } = this.props;
        const activeSelection = this.fabricCanvas.getActiveObject();
        const matrix = activeSelection.calcTransformMatrix();
        const activeObjects = this.fabricCanvas.getActiveObjects();
        activeObjects.forEach((activeObject) => {
            const objectPosition = new fabric.Point(activeObject.left, activeObject.top);
            const { x, y } = fabric.util.transformPoint(objectPosition, matrix);
            addToNewAndUpdatedEntitiesList(activeObject, true, {
                bev: {
                    x,
                    y,
                    ...(action === 'rotate' && { angle: getActualAngle(activeObject.angle + selectionAngle) }),
                },
            });
        });
        this.fabricCanvas.renderAll();
    };

    handleObjectModification = (event, action = '') => {
        let objectWasRestricted = false;
        if (event) {
            objectWasRestricted = this.restrictObjToCanvas(event);
        }
        if (!objectWasRestricted) {
            const {
                addToNewAndUpdatedEntitiesList, checkAndHandleLabelOverflow,
                formProps: { setFieldValue } = {},
            } = this.props;
            let actionType = action;
            let entity;
            let objectsInActiveSelection;
            let selectionType;
            let selectionAngle;
            let x;
            let y;
            if (event) {
                const {
                    transform: { action: eventActionType } = {},
                    target,
                    target: { type, angle, left: xFromEvent, top: yFromEvent } = {},
                } = event;
                actionType = eventActionType;
                entity = target;
                selectionType = type;
                selectionAngle = angle;
                x = xFromEvent;
                y = yFromEvent;
            } else {
                const activeObj = this.fabricCanvas.getActiveObject();
                const {
                    type, angle = 0, left: xFromObject, top: yFromObject,
                    _objects: objectsInSelection,
                } = activeObj || {};
                selectionType = type;
                selectionAngle = angle;
                x = xFromObject;
                y = yFromObject;
                entity = activeObj;
                objectsInActiveSelection = objectsInSelection;
            }
            if (actionType === 'rotate') {
                const propertyModified = 'bev.angle';
                if (selectionType === 'group') {
                    this.modifyEntityData(propertyModified, selectionAngle);
                } else if (selectionType === 'activeSelection') {
                    this.transformCoordsAndUpdateEntityData(actionType, selectionAngle);
                    this.fabricCanvas.discardActiveObject({ type: EVENT_TRIGGERED_BY_TYPE.MANUAL });
                    objectsInActiveSelection.forEach((object) => {
                        checkAndHandleLabelOverflow(fabric, this.fabricCanvas, object, false);
                    });
                    const selection = new fabric.ActiveSelection(objectsInActiveSelection,
                        { canvas: this.fabricCanvas });
                    this.fabricCanvas.setActiveObject(selection);
                    this.fabricCanvas.renderAll();
                }
                setFieldValue(propertyModified, getActualAngle(selectionAngle));
            } else if (event && actionType === 'drag') {
                const { target: { unitType } = {} } = event;
                const { setUnitTypeList } = this.props;
                if (selectionType === 'group') {
                    addToNewAndUpdatedEntitiesList(entity, true, {
                        bev: {
                            x,
                            y,
                        },
                    });
                } else if (selectionType === 'activeSelection') {
                    this.transformCoordsAndUpdateEntityData(actionType);
                    setFieldValue('ignore.multipleModified', true, false);
                }
                setUnitTypeList(unitType, false, false); // To trigger a render cycle so that the footer is re-rendered and the save button disable condition works as intended
            }
        }
    };

    loadEntityDataIntoForm = (activeObject) => {
        const { type, ...entity } = activeObject || {};
        if (type === 'group') {
            const {
                setUnitLabelBeforeDuplicateError, setUnitTypeList,
                isFacilityMigrated,
                formProps: { values, setValues },
            } = this.props;
            const [{
                id, tempId, label, unitType, entityType,
                placeholderType, bev,
            } = {}] = getEntitiesDataFromCanvas(entity, undefined, isFacilityMigrated);
            const isEntityUnit = entityType === TYPE_OF_ENTITY.Unit.value;
            const entityId = id || tempId;
            if (isEntityUnit) setUnitTypeList(unitType);
            setValues({
                ...values,
                id: entityId,
                tempId,
                label,
                unitType,
                bev,
                entityType,
                placeholderType,
            });
            if (isEntityUnit) setUnitLabelBeforeDuplicateError(entityId, label);
        }
    };


    /**
     * @description Updates the specified property of the entity on change in the form
     * @param {string} property The property to update
     * @param {*} value The property's new value
     * @param {fabric.Group} activeObject the active object on the canvas
     * @param {boolean} isSourceDuplicateUnitNumberAlertDialog Will convert Width/Height if true
     */
    modifyEntityData = (property, value, activeObject = null, isSourceDuplicateUnitNumberAlertDialog = false) => {
        const actObject = activeObject || this.fabricCanvas.getActiveObject();
        const { type } = actObject || {};
        if (actObject && type === 'group') {
            const {
                props: {
                    addToNewAndUpdatedEntitiesList, checkAndHandleLabelOverflow,
                    isFacilityMigrated,
                } = {},
            } = this;
            let propertyAndValueObject = {};
            const { _objects: [rectObj, textObj] } = actObject;
            switch (property) {
                case 'label':
                    textObj.set('text', value);
                    propertyAndValueObject = { label: value };
                    break;
                case 'bev.angle': {
                    const angle = getActualAngle(value);
                    actObject.rotate(angle);
                    textObj.set('angle', -angle);
                    actObject.setCoords();
                    const { left: x, top: y } = actObject;
                    propertyAndValueObject = {
                        bev: {
                            angle,
                            x,
                            y,
                        },
                    };
                    break;
                }
                case 'bev.width':
                case 'bev.depth': {
                    const numericValue = convertToNumber(value);
                    if (numericValue > 0) {
                        const { width, height } = isSourceDuplicateUnitNumberAlertDialog
                            ? {
                                width: numericValue,
                                height: numericValue,
                            } : getConvertedWH(numericValue, numericValue, isFacilityMigrated);
                        const { key, newValue } = (property === 'bev.width')
                            ? {
                                key: 'width',
                                newValue: width,
                            } : {
                                key: 'height',
                                newValue: height,
                            };
                        rectObj.set(key, newValue);
                        rectObj.setCoords();
                        actObject.set(key, newValue);
                        actObject.setCoords();
                        propertyAndValueObject = {
                            bev: {
                                [key === 'height' ? 'depth' : key]: isSourceDuplicateUnitNumberAlertDialog
                                    ? reconvertWH(numericValue, isFacilityMigrated) : numericValue,
                            },
                        };
                    }
                    break;
                }
                case 'unitType':
                    actObject.set('unitType', value);
                    propertyAndValueObject = { unitType: value };
                    break;
                case 'placeholderType':
                    actObject.set('placeholderType', value);
                    propertyAndValueObject = { placeholderType: value };
                    break;
                case 'bev.level':
                    actObject.set('level', value);
                    propertyAndValueObject = { bev: { level: value } };
                    break;
                default:
                    break;
            }
            checkAndHandleLabelOverflow(fabric, this.fabricCanvas, actObject, false);
            this.fabricCanvas.renderAll();
            addToNewAndUpdatedEntitiesList(actObject, true, propertyAndValueObject);
        }
    };

    /**
     * @description Applies/Removes shadow on canvas objects when selecting/deselecting them
     * @param {Object} e event object from the fired event
     * @param {boolean} clearAll If 'true', will remove shadow on all selected objects. Default value is 'false'.
     */
    controlObjectShadow = (e, clearAll = false) => {
        if (clearAll) {
            this.fabricCanvas.getActiveObjects().forEach((obj) => {
                obj.setShadow(null);
                obj.saveState();
            });
        } else if (e) {
            const { selected = [], deselected = [] } = e;
            if (selected.length) {
                selected.forEach((obj) => {
                    obj.setShadow(theme.boxShadows[4]);
                    obj.saveState();
                });
            }
            if (deselected.length) {
                deselected.forEach((obj) => {
                    obj.setShadow(null);
                    obj.saveState();
                });
            }
        }
        if (e) e.target.saveState();
        this.fabricCanvas.renderAll();
    };

    setOrUnsetRedStrokeOnObject = (object, shouldUnset = false, shouldCallRenderAll = false) => {
        const { type, ...actObject } = object || this.fabricCanvas.getActiveObject() || {};
        if (type === 'group') {
            const { _objects: [rectObj, textObj] } = actObject;
            const { RECT_CONFIG: { stroke: defaultStrokeColor } = {}, ERRED_COLOR } = UNIT_CONFIG;
            const strokeColorToSet = shouldUnset ? defaultStrokeColor : ERRED_COLOR;
            rectObj.set('stroke', strokeColorToSet);
            textObj.set('fill', strokeColorToSet);
        }
        if (shouldCallRenderAll) this.fabricCanvas.renderAll();
    };

    controlEntityHighlighting = (erredEntities, entityTypeToHighlight) => {
        if (!isObjWithKeys(erredEntities)) return;
        if (!entityTypeToHighlight) return;

        const canvasObjects = this.fabricCanvas.getObjects('group');
        const { ERRED_COLOR } = UNIT_CONFIG;
        canvasObjects.forEach((obj) => {
            const { id, tempId, entityType, _objects: [{ stroke } = {}] = [] } = obj;

            if (entityType !== entityTypeToHighlight) return;

            if (erredEntities[id || tempId]) {
                this.setOrUnsetRedStrokeOnObject(obj);
            } else if (stroke === ERRED_COLOR) {
                this.setOrUnsetRedStrokeOnObject(obj, true);
            }
        });
        this.fabricCanvas.renderAll();
    };

    handleRotation = (selectedObject) => {
        const { type, angle: selectionAngle, _objects: childObjects } = selectedObject;
        if (type === 'activeSelection') {
            childObjects.forEach((obj) => {
                setTextAngleToZero(this.fabricCanvas, obj, selectionAngle);
            });
        } else if (type === 'group') {
            setTextAngleToZero(this.fabricCanvas, selectedObject);
        }
    };

    /**
     * @description Restricts objects from being dragged and placed outside the canvas area
     */
    restrictObjToCanvas = (event) => {
        let restricted = false;
        const obj = event ? event.target : this.fabricCanvas.getActiveObject();
        const { left: objLeft, top: objTop, width: objWidth, height: objHeight } = obj.getBoundingRect();
        const {
            left: leftBoundary,
            top: topBoundary,
            width: boundaryWidth,
            height: boundaryHeight,
        } = this.canvasBoundary.getBoundingRect();
        const rightBoundary = leftBoundary + boundaryWidth;
        const bottomBoundary = topBoundary + boundaryHeight;
        if (objLeft < leftBoundary
            || objTop < topBoundary
            || objLeft + objWidth > rightBoundary
            || objTop + objHeight > bottomBoundary) {
            obj.set('angle', obj._stateProperties.angle);
            this.handleRotation(obj);
            obj.set('top', obj._stateProperties.top);
            obj.set('left', obj._stateProperties.left);
            restricted = true;
        }
        obj.setCoords();
        obj.saveState();
        return restricted;
    };

    rotate = (degree) => {
        const { formProps: { setFieldValue } } = this.props;
        const activeObj = this.fabricCanvas.getActiveObject();
        if (isObjWithKeys(activeObj)) {
            const { angle: currentAngle, type, _objects: objectInSelection } = activeObj;
            const newAngle = getActualAngle(currentAngle + degree);
            activeObj.rotate(newAngle);
            this.handleRotation(activeObj);
            activeObj.setCoords();
            const objectWasRestricted = this.restrictObjToCanvas();
            this.fabricCanvas.renderAll();
            if (!objectWasRestricted) {
                this.fabricCanvas.fire('object:modified', {
                    target: type === 'group' ? activeObj : {
                        type,
                        _objects: objectInSelection,
                    },
                    transform: { action: 'rotate' },
                });
                setFieldValue('bev.angle', newAngle);
            }
        }
    };

    delete = () => {
        const {
            props: { handleDeletionOfEntityFromCanvas },
            state: { shouldMaskCanvas } = {},
        } = this;
        if (!shouldMaskCanvas) {
            const actObject = this.fabricCanvas.getActiveObject();
            const { type } = actObject || {};
            if (type !== 'group') return; // If a group of entities is selected, do not delete

            const activeObjects = this.fabricCanvas.getActiveObjects();
            this.fabricCanvas.remove(...activeObjects);
            this.fabricCanvas.discardActiveObject(undefined);
            this.fabricCanvas.renderAll();

            handleDeletionOfEntityFromCanvas(activeObjects);
        }
    };

    /**
     * @description Returns the new coordinates for the object as a fabric.Point class instance after calculating the coordinates by taking into consideration the object's bounding box's width and height
     * @param {fabric.Object} obj Canvas object
     * @param {string} position The alignment position: 'left', 'right', 'top', 'bottom', 'vCenter', 'hCenter'
     * @param {Object} selectionProperties Object with half of selection's width and height as keys
     * @return {fabric.Point} fabric.Point instance with new coordinates of the object
     */
    getNewShiftedCoordinates = (obj, position, selectionProperties) => {
        const { LEFT, RIGHT, TOP, BOTTOM, HCENTER, CENTER } = POSITIONS;
        const {
            selectionWidthHalf = 0,
            selectionHeightHalf = 0,
        } = selectionProperties;
        const { x: objInitialX, y: objInitialY } = obj.getPointByOrigin(CENTER, CENTER);
        const { width: objBoundingBoxWidth, height: objBoundingBoxHeight } = obj.getBoundingRect();
        const coords = {};
        if (position === LEFT) {
            coords.x = -selectionWidthHalf + objBoundingBoxWidth / 2;
            coords.y = objInitialY;
        } else if (position === RIGHT) {
            coords.x = selectionWidthHalf - objBoundingBoxWidth / 2;
            coords.y = objInitialY;
        } else if (position === TOP) {
            coords.x = objInitialX;
            coords.y = -selectionHeightHalf + objBoundingBoxHeight / 2;
        } else if (position === BOTTOM) {
            coords.x = objInitialX;
            coords.y = selectionHeightHalf - objBoundingBoxHeight / 2;
        } else if (position === HCENTER) {
            coords.x = 0;
            coords.y = objInitialY;
        } else {
            coords.x = objInitialX;
            coords.y = 0;
        }
        const { x, y } = coords;
        return new fabric.Point(x, y);
    };

    /**
     * @description Aligns objects within the selection to the position specified w.r.t  the selection's center[0,0]
     * @param {string} position The alignment position: 'left', 'right', 'top', 'bottom', 'vCenter', 'hCenter'
     */
    align = (position) => {
        const { CENTER } = POSITIONS;
        const activeObject = this.fabricCanvas.getActiveObject();
        if (isObjWithKeys(activeObject)) {
            const { type, angle } = activeObject;
            if (type === 'activeSelection' && angle === 0) {
                const { width: selectionWidth, height: selectionHeight } = activeObject.getBoundingRect();

                const selectionWidthHalf = (selectionWidth / 2);
                const selectionHeightHalf = (selectionHeight / 2);

                activeObject.forEachObject((obj) => {
                    const newCoords = this.getNewShiftedCoordinates(obj, position,
                        {
                            selectionWidthHalf,
                            selectionHeightHalf,
                        });
                    obj.setPositionByOrigin(newCoords, CENTER, CENTER);
                    obj.setCoords();
                });
                this.fabricCanvas.renderAll();
                this.transformCoordsAndUpdateEntityData();
            }
        }
    };

    zoom = (increment, shouldReset = false) => {
        const { canvasWrapperRef: { current: canvasWrapperRef }, fabricCanvas, props: { zoomCanvas } = {} } = this;
        zoomCanvas(increment, canvasWrapperRef, fabricCanvas, shouldReset, fabric);
    };

    getNewObjectXY = () => {
        const { getActiveDirection } = this.props;

        const activeObj = this.fabricCanvas.getActiveObject();

        const direction = getActiveDirection();

        if (activeObj && direction) {
            const { UP, RIGHT, DOWN } = DIRECTIONS;

            const zoom = this.fabricCanvas.getZoom();

            const {
                width: selectedObjWidth = 0,
                height: selectedObjHeight = 0,
            } = activeObj.getBoundingRect();

            const { x, y } = activeObj.getPointByOrigin('center', 'center');

            const positiveOrNegative = (direction === RIGHT || direction === DOWN) ? 1 : -1;

            let newX = x;
            let newY = y;

            switch (direction) {
                case UP:
                case DOWN: {
                    newY = y + positiveOrNegative * (selectedObjHeight / zoom + UNIT_CONFIG.PADDING);
                    // Divide by zoom so that the calculation is consistent when zoomed in/out
                    break;
                }
                default: {
                    newX = x + positiveOrNegative * (selectedObjWidth / zoom + UNIT_CONFIG.PADDING);
                    break;
                }
            }
            return new fabric.Point(newX, newY);
        }
        const { x, y } = defaultNewObjXY;
        return new fabric.Point(x, y);
    };

    /**
     * @description Adds a entity to the canvas
     * @param {object} entity The entity object to be added
     * @param {boolean} isAddByClick Default false. Used to determine if the add was from existing data or from the click of an add button. If from an add button, the added entity is made active on the canvas
     */
    addEntity(entity, isAddByClick = false) {
        if (entity) {
            const { CENTER, LEFT, TOP } = POSITIONS;
            let tempId;
            let originX = CENTER;
            let originY = CENTER;

            const {
                Group,
                props: {
                    addToLabelToIdMap,
                    isFacilityMigrated,
                    addToNewAndUpdatedEntitiesList,
                    checkAndHandleLabelOverflow,
                    zoomValue,
                },
            } = this;
            const {
                id,
                label,
                unitType,
                placeholderType,
                entityType,
                rentalStatus = DEFAULT_RENTAL_STATUS,
                bev: {
                    x = defaultNewObjXY.x,
                    y = defaultNewObjXY.y,
                    width: bevWidth,
                    depth: bevHeight,
                    angle = 0,
                    level,
                } = {},
            } = entity;

            if (!(x && y && bevWidth && bevHeight)) return; // If x, y, width or height are 0, do not add to canvas

            const { width, height } = getConvertedWH(bevWidth, bevHeight, isFacilityMigrated);

            const isEntityUnit = entityType === TYPE_OF_ENTITY.Unit.value;
            const isEntityPlaceholder = entityType === TYPE_OF_ENTITY.Placeholder.value;

            let newCoords;

            const activeObj = this.fabricCanvas.getActiveObject();

            if (isAddByClick) {
                newCoords = this.getNewObjectXY();
                const { getActiveDirection } = this.props;
                const direction = getActiveDirection();
                const cHeight = this.fabricCanvas.getHeight();
                const cWidth = this.fabricCanvas.getWidth();

                tempId = getUUID();

                if (direction && activeObj) { // Restricts adding to canvas area
                    const { x: newX, y: newY } = newCoords;
                    if ((newX - width / 2) < 0
                        || (newX + width / 2) > cWidth
                        || (newY + height / 2) > cHeight
                        || (newY - height / 2) < 0) {
                        return;
                    }
                } else { // To add in the top left corner with default X,Y when no direction and entity is selected
                    originX = LEFT;
                    originY = TOP;
                }
            }

            const rect = new fabric.Rect({
                fill: UNIT_CONFIG.DEFAULT_BACKGROUND,
                width,
                height,
                strokeWidth: 0.7,
                ...UNIT_CONFIG.RECT_CONFIG,
            });

            const entityLabel = new fabric.Text(label, {
                ...UNIT_CONFIG.LABEL_CONFIG,
                fill: theme.palette.black[700],
                fontSize: getFontSize(zoomValue),
            });
            const entityObj = new Group([rect], {
                ...(id && { id }),
                ...(tempId && { tempId }),
                entityType,
                left: convertToNumber(isAddByClick ? newCoords.x : x),
                top: convertToNumber(isAddByClick ? newCoords.y : y),
                width,
                height,
                angle: isAddByClick && !activeObj ? 0 : convertToNumber(angle),
                stateProperties: ['angle', 'top', 'left'],
                level,
                ...(isEntityUnit && {
                    unitType,
                    rentalStatus,
                }),
                ...(isEntityPlaceholder && { placeholderType }),
                originX: LEFT,
                originY: TOP,
            });

            entityObj.add(entityLabel);

            entityObj.saveState();

            if (isAddByClick) {
                entityObj.setPositionByOrigin(newCoords, originX, originY);

                this.fabricCanvas.setActiveObject(entityObj); // This fires selection:updated event, which is handled above in the constructor. The loading of this entity's data into form is taken care of in the event handler. Since this entity is set as active object, the fired event's target will be this entity itself

                if (isEntityUnit) addToLabelToIdMap(label, tempId);

                addToNewAndUpdatedEntitiesList(entityObj);
                const { canvasWrapperRef: { current: canvasWrapperRef } } = this;
                canvasWrapperRef.focus();
            }

            setTextAngleToZero(this.fabricCanvas, entityObj);

            this.fabricCanvas.add(entityObj);
            checkAndHandleLabelOverflow(fabric, this.fabricCanvas, entityObj, isAddByClick, zoomValue);
        }
    }

    drawCanvas() {
        const { entities, props: { zoomValue } } = this;
        const boundaryRect = new fabric.Rect(CANVAS_BOUNDARY_CONFIG);
        this.fabricCanvas.add(boundaryRect);
        this.canvasBoundary = boundaryRect;
        this.fabricCanvas.add(this.labelTooltip);
        if (entities) {
            entities.forEach((entity) => {
                this.addEntity(entity);
            });
        }
        this.fabricCanvas.bringToFront(this.labelTooltip);
        this.fabricCanvas.setZoom(zoomValue);
        this.fabricCanvas.renderAll();
    }

    render() {
        const { props: { classes } = {}, state: { shouldMaskCanvas } = {} } = this;
        return (
            <>
                {
                    shouldMaskCanvas && (
                        <Grid container className={classes.canvasMask} />
                    )
                }
                <div ref={this.canvasWrapperRef} tabIndex="0" role="button" className={classes.canvasWrapper}>
                    <canvas id="bevSetupCanvas" />
                </div>
            </>
        );
    }
}

BevSetup.propTypes = {
    formProps: PropTypes.object,
    entities: PropTypes.array,
    zoomValue: PropTypes.number,
    getActiveDirection: PropTypes.func,
    addToNewAndUpdatedEntitiesList: PropTypes.func,
    handleDeletionOfEntityFromCanvas: PropTypes.func,
    handlePanning: PropTypes.func,
    handleKeyDown: PropTypes.func,
    handleKeyUp: PropTypes.func,
    handleMouseMove: PropTypes.func,
    handleCanvasMouseUp: PropTypes.func,
    addToLabelToIdMap: PropTypes.func.isRequired,
    setUnitLabelBeforeDuplicateError: PropTypes.func.isRequired,
    setUnitTypeList: PropTypes.func.isRequired,
    setRequiredFieldsConfig: PropTypes.func.isRequired,
    handleEntityMouseOver: PropTypes.func,
    handleEntityMouseOut: PropTypes.func,
    checkAndHandleLabelOverflow: PropTypes.func.isRequired,
    moveTooltipWithPointer: PropTypes.func.isRequired,
    isFacilityMigrated: PropTypes.bool,
};

BevSetup.defaultProps = {
    formProps: {},
    entities: [],
    getActiveDirection: () => {
    },
    addToNewAndUpdatedEntitiesList: () => {
    },
    handleDeletionOfEntityFromCanvas: () => {
    },
    isFacilityMigrated: false,
};

export default withStyles(bevStyles)(BevSetup);
