import Footer from 'Commons/components/business/footer/components/Footer';
import { getDiffProperties } from 'Commons/helpers/utils/DataHelpers';
import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import { getEditEndpoints } from 'Commons/config/constants/Endpoints';
import localisable from 'Commons/config/strings/localisable';
import { ACTIVATE, DEACTIVATE, EMPTY_FUNC, STATUS, WS_EVENT_TYPE } from 'Commons/config/constants/Constants';
import { VARIANT } from 'Generic/snackbar/config/Constants';
import { RESOURCE_NAME_TO_STORE_KEY } from 'Commons/components/business/basecomponent/config/Constants';
import { getRouteParams } from 'Commons/helpers/utils/RouteHelpers';
import { Typography } from 'Commons/components/generic/componentlibrary/components/Components';
import { convertToNumber } from 'Commons/helpers/utils/Utils';
import BaseComponent from '../../basecomponent/components/BaseComponent';
import { formatErrors } from '../../../../redux/error/ErrorHandler';
import ALERT_MESSAGE from '../config/AlertMessage';

export default class BaseForm extends BaseComponent {
    /** Always Set this touch keys */
    touchedKeys = {};

    /** Formik Form Props, In render method please set this  */
    formProps = {};

    editFormData = {};

    /** Tooltip message to show when positive footer button is disabled because of rtns message. */
    positiveButtonToolTipMessage = ''

    componentWillUnmount() { }

    allowNavigation = (callback, unblockedPaths = []) => {
        const { history, unauthorised, match: { params: { fid } = {} } = {} } = this.props;
        return history.block(({ nextLocation: targetLocation }) => {
            const { pathname, state } = targetLocation;
            this.targetLocation = pathname;
            this.routeState = state;
            const { fid: targetFacilityId } = getRouteParams(pathname, '/a/facility/:fid');
            if (this.shouldChangeRoute || unauthorised) {
                this.shouldChangeRoute = false;
                return true;
            } if (this.isPathUnblocked(unblockedPaths) && fid === targetFacilityId) {
                return true;
            } if (callback) {
                return callback();
            }
            return false;
        });
    }

    isPathUnblocked = (unblockedPaths = []) => unblockedPaths.some(path => this.targetLocation.includes(path))

    handleErrors = (formError, actions, shouldShowSnackbar = true) => {
        const { values, status } = this.formProps;
        const formattedErrors = formatErrors(formError);
        if (formattedErrors) {
            if (shouldShowSnackbar) {
                this.setSnackbarProps(true, formattedErrors.errorMessage || localisable.somethingWentWrong,
                    VARIANT.error.value);
            }
        } else if (actions) {
            actions.setStatus({ ...status, errors: formattedErrors, values });
            actions.setErrors(formattedErrors);
        }
        this.resetTouchedFields(actions);
        return formattedErrors.errorMessage;
    }

    resetTouchedFields = (actions) => {
        if (actions && actions.setTouched) {
            actions.setTouched(this.touchedKeys || {});
        }
    }

    // TODO: Remove this when Page is implemented in each form
    handleNo = () => {
        this.targetLocation = '';
        this.setState({ discard: false });
    }

    handleYes = () => {
        const { history, closeModal } = this.props;
        const { targetLocation, processBeforeUnmount = EMPTY_FUNC } = this;
        this.shouldChangeRoute = true;
        processBeforeUnmount();
        if (closeModal) {
            closeModal(false);
        } else if (targetLocation) {
            history.push(targetLocation);
        } else {
            history.goBack();
        }
    }

    handleFormSubmit = () => {
        this.beforeSubmit();
    }

    onCancel = () => {
        const { formProps: { dirty } = {}, props: { history } = {} } = this;
        if (dirty) {
            this.targetLocation = '';
            this.setState({ discard: true });
        } else {
            history.goBack();
        }
    }

    // TODO: Remove this when Page is implemented in each form
    discardConfirmation = FooterProps => (
        <Footer
            positiveButtonProps={{
                label: localisable.yes,
                onClick: this.handleYes,
            }}
            negativeButtonProps={{
                label: localisable.no,
                onClick: this.handleNo,
            }}
            {...FooterProps}
        />
    )

    getFooter = ({
        positiveButtonProps = {}, negativeButtonProps = {}, hidePositiveButton = false,
        hideNegativeButton = false, ...FooterProps
    } = {}) => {
        const { isValid, setStatus, dirty, status: { errors, values, ...status } = {} } = this.formProps;
        const { state: { isButtonLoading } = {}, positiveButtonToolTipMessage } = this;

        return (
            <Footer
                positiveButtonProps={hidePositiveButton ? {} : {
                    label: localisable.save,
                    type: 'submit',
                    disabled: !(isValid && dirty && !isButtonLoading && !positiveButtonToolTipMessage),
                    buttonToolTipMessage: positiveButtonToolTipMessage,
                    onClick: () => {
                        setStatus(status);
                        this.submitForm();
                    },
                    loadingText: localisable.saving,
                    isButtonLoading,
                    ...positiveButtonProps,
                }}
                negativeButtonProps={hideNegativeButton ? {} : {
                    label: localisable.exit,
                    onClick: this.onCancel,
                    ...negativeButtonProps,
                }}
                {...FooterProps}
            />
        );
    }

    transformToReserved = (originalKeyName, newKeyName, data) => {
        const originalData = { ...data };
        if (data[originalKeyName]) {
            originalData[newKeyName] = originalData[originalKeyName];
            delete originalData[originalKeyName];
        }
        return originalData;
    }

    isFormActive = () => {
        const { status: { formStatus } = {} } = this.formProps;
        return formStatus === STATUS.Active.value;
    }

    getCustomHeaderForStatusChange = () => ({
        type: 'store',
        key: 'facility',
    })

    handleStatusChangeYes = (id, source, apiActionOptions) => {
        const { isFormActive, getCustomHeaderForStatusChange } = this;
        const endPoint = getEditEndpoints(id, source, isFormActive() ? DEACTIVATE : ACTIVATE);
        const { setErrors, setTouched, setStatus } = this.formProps;
        const actions = { setErrors, setTouched, setStatus };
        const { onAction } = this.props;

        this.setState({ loading: true });
        if (onAction && endPoint) {
            onAction({
                config: [{
                    api: {
                        dynamic: {
                            methodType: 'POST',
                            endPoint,
                            customHeaderOptions: getCustomHeaderForStatusChange(),
                            ...apiActionOptions,
                        },
                    },
                    callback: this.onStatusChange(actions),
                }],
            });
        }
    }

    onStatusChange = actions => (apiError, response) => {
        if (apiError) {
            this.handleErrors(apiError, actions);
        } else {
            const { success } = response;
            const { formProps: { status, setStatus } = {}, isFormActive } = this;
            if (success) {
                setStatus({
                    ...status,
                    formStatus: !isFormActive() ? STATUS.Active.value : STATUS.Inactive.value,
                });
                this.isFormUpdated = true;
            }
        }
        this.setState({ loading: false });
    }

    showAlertDialog = () => {
        this.setState({ shouldShowAlert: true });
    }

    getAlertMessage = (source) => {
        const { state: { discard } = {}, formProps: { dirty } = {}, isFormActive } = this;
        if (discard && dirty) {
            return <Typography>{localisable.discardConfirmation}</Typography>;
        }

        if (source) {
            return (
                <Typography>
                    {isFormActive() ? ALERT_MESSAGE[source].deactivate : ALERT_MESSAGE[source].activate}
                </Typography>
            );
        }

        return null;
    }

    onAlertYesAction = (id = '', source = '', apiActionOptions) => {
        const { state: { discard } = {}, formProps: { dirty } = {} } = this;
        this.setState({ discard: false, shouldShowAlert: false });
        if (discard && dirty) {
            this.handleYes();
        } else if (source) {
            this.handleStatusChangeYes(id, source, apiActionOptions);
        }
    }

    onAlertClose = () => {
        const { state: { discard } = {}, formProps: { dirty } = {} } = this;
        if (discard && dirty) {
            this.setState({ discard: false });
            this.targetLocation = '';
        } else {
            this.setState({ shouldShowAlert: false });
        }
    }

    // Checks if the current form's data has been updated by some other client or created based on shouldConsiderCreateEvent
    receivedWsMessageForCurrentForm = (wsMessage, source, shouldConsiderCreateEvent = false) => {
        const { resourceName, eventType, resourceId } = wsMessage;
        const { formProps: { values: { id } = {} } = {}, state: { isButtonLoading } = {} } = this;
        const isCurrentResourceUpdated = eventType === WS_EVENT_TYPE.UPDATED && resourceId === convertToNumber(id);
        const isCreated = eventType === WS_EVENT_TYPE.CREATED;

        if (id && RESOURCE_NAME_TO_STORE_KEY[resourceName] === source
            && (isCurrentResourceUpdated || (shouldConsiderCreateEvent && isCreated))
            && !isButtonLoading) {
            return true;
        }
        return false;
    }

    /**
     * Override this function to add the config to update the desired store on activate/deactivate success
     * @param {Object} actions Formik actions object
     * @param {Boolean} reset Should overlay close after store update
     */
    updateStoreConfig = () => { }

    /**
     * @name preProcessValues
     * @description Called before submitting form for diff
     * @param values All the form values
     * @param touchedKeys All the form's touched keys
     */
    preProcessValues = (values, touchedKeys) => ({
        processedValues: values,
        propertiesToCheck: touchedKeys,
    });

    createEditFormData = () => {
        const { values, initialValues, touched = {} } = this.formProps;
        this.touchedKeys = deepCopy(touched);
        const { processedValues, propertiesToCheck } = this.preProcessValues(values, touched);
        // Perform form diff only if Form is in edit mode
        this.editFormData = !this.isModeCreate() ? getDiffProperties(initialValues, processedValues, {
            properties: propertiesToCheck,
            excludeProperties: this.excludeProperties || undefined,
        }) : processedValues;
    }

    submitForm = () => {
        const { formProps: { handleSubmit } = {}, createEditFormData } = this;
        createEditFormData();
        handleSubmit();
    }

    onClose = () => {
        const { dirty } = this.formProps;
        if (!dirty) {
            this.onReset();
        } else {
            this.setState({ discard: true });
        }
    }

    onReset = (data, source, clearStore) => {
        const { history, location: { state } = {} } = this.props;
        const { prevPath } = state || {};
        if (prevPath) {
            history.replace({
                pathname: `${prevPath}`,
                state: { ...state, detail: data, source },
            });
        } else {
            this.goBack(clearStore);
        }
    }

    goBack = () => {
        const { history } = this.props;
        if (history) {
            history.goBack();
        }
    }

    isModeCreate = () => {
        const { mode } = this.props;
        return mode === 'create';
    }

    handleIsSubmitDisabled = () => {
        this.disableFormSubmit(false);
        this.setWasFormSubmissionCancelled(true);
    }

    disableFormSubmit = (shouldDisable = true) => {
        this.isSubmitDisabled = shouldDisable;
    }

    setWasFormSubmissionCancelled = (wasCancelled = true) => {
        this.wasFormSubmissionCancelled = wasCancelled;
    }

    getWasFormSubmissionCancelled = () => this.wasFormSubmissionCancelled;

    reEvaluateEditFormDataIfRequired = () => {
        if (this.shouldReEvaluateEditFormData) {
            this.shouldReEvaluateEditFormData = false;
            this.createEditFormData();
        }
    }
}
