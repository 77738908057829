const leadFilterStyle = theme => ({
    overflow: {
        overflow: 'auto',
        marginTop: theme.spacing(3),
        maxHeight: 500,
        flexWrap: 'nowrap',
    },
    toDateField: {
        marginLeft: theme.spacing(5),
        marginRight: ({ isMobile }) => theme.spacing(isMobile ? 2 : 0),
    },
    marginForLeadStatus: { marginLeft: theme.spacing(12), marginTop: theme.spacing(2) },
    autoWidth: { width: 'auto' },
    dateRangeLabel: { padding: theme.spacing(2, 0) },
    clearDate: {
        alignSelf: 'flex-end',
        paddingTop: theme.spacing(1),
        marginRight: ({ isMobile }) => theme.spacing(isMobile ? 2 : 0),
    },
    unitTypeTopMargin: { marginTop: theme.spacing(2) },
    firstLabel: { paddingBottom: '16px' },
    restLabel: {
        paddingTop: '32px',
        paddingBottom: '16px',
    },
});

export default leadFilterStyle;
