const threeDotProgressStyle = theme => ({
    spinner: {
        display: 'inline',

        '& div': {
            width: 12,
            height: 12,
            backgroundColor: theme.palette.text.secondary,
            borderRadius: '100%',
            display: 'inline-block',
            '-webkit-animation': '$sk-bouncedelay 1.4s infinite ease-in-out both',
            animation: '$sk-bouncedelay 1.4s infinite ease-in-out both',
        },

        '& $bounce1': {
            '-webkit-animation-delay': '-0.32s',
            animationDelay: '-0.32s',
        },

        '& $bounce2': {
            '-webkit-animation-delay': '-0.16s',
            animationDelay: '-0.16s',
        },


        '@global': {
            '@keyframes sk-bouncedelay': {
                '0%, 80%, 100%': {
                    '-webkit-transform': 'scale(0)',
                    transform: 'scale(0)',
                },
                '40%': {
                    '-webkit-transform': 'scale(1.0)',
                    transform: 'scale(1.0)',
                },
            },
        },
    },
    bounce1: {},
    bounce2: {},
    bounce3: {},
});

export default threeDotProgressStyle;
