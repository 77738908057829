import {
    balanceFormatter, dateFormatter,
    descriptionFormatter,
} from 'Commons/components/business/summaryTable/components/SummaryFormatter';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import getFormattedAmount from 'Commons/helpers/utils/AmountHelper';

// TODO: Remove after Move-Out Changes
const getFooterData = (classes, totalBalance, isResponsiveScreen = false) => {
    const columns = [
        {
            key: 'total',
            width: isResponsiveScreen ? 45 : 23,
            label:
                <Typography variant={isResponsiveScreen ? 'subtitle1' : 'h6'} fontFamily="Roboto">
                    {localisable.total}
                </Typography>,
            headerClassName: `${classes.totalBalance} ${classes.columnHeader} ${classes.leftCell}`,
            align: 'right',
            valign: 'center',
        },
        ...!isResponsiveScreen ? [
            {
                key: 'date',
                width: 41,
                label: '',
                headerClassName: `${classes.totalBalance} ${classes.columnHeader}`,
                align: 'right',
                valign: 'center',
            },
        ] : [],
        {
            key: 'debit',
            width: isResponsiveScreen ? 25 : 18,
            label:
                <Typography variant={isResponsiveScreen ? 'subtitle1' : 'h6'} fontFamily="Open Sans">
                    {totalBalance[0]}
                </Typography>,
            formatter: balanceFormatter,
            headerClassName: `${classes.totalBalance} ${classes.rightAlign} ${classes.columnHeader}`,
            align: 'right',
        },
        {
            key: 'credit',
            width: isResponsiveScreen ? 30 : 18,
            label:
                <Typography variant={isResponsiveScreen ? 'subtitle1' : 'h6'} fontFamily="Open Sans">
                    {totalBalance[1]}
                </Typography>,
            formatter: balanceFormatter,
            headerClassName: `${classes.totalBalance} ${classes.rightAlign} ${classes.columnHeader} `,
            align: 'right',
        },
    ];
    return columns;
};

const getColumns = (classes, currencySign, totalBalance, isResponsiveScreen = false) => {
    const columns = [
        {
            key: 'particulars',
            width: isResponsiveScreen ? 45 : 23,
            formatter: descriptionFormatter,
            label:
                <Typography variant={isResponsiveScreen ? 'body1' : 'subtitle1'}>
                    {localisable.chargeCategoryCaps}
                </Typography>,
            footer:
                <Typography variant={isResponsiveScreen ? 'subtitle1' : 'h6'} fontFamily="Roboto">
                    {localisable.total}
                </Typography>,
        },
        ...!isResponsiveScreen ? [
            {
                key: 'date',
                width: 40,
                label: <Typography variant="subtitle1">{localisable.dateCaps}</Typography>,
                formatter: dateFormatter,
                align: 'center',
            },
        ] : [],
        {
            key: 'amount',
            width: isResponsiveScreen ? 30 : 19,
            label:
                <Typography variant={isResponsiveScreen ? 'body1' : 'subtitle1'}>
                    {`${localisable.amountCaps}  (${currencySign})`}
                </Typography>,
            footer:
                <Typography
                    variant={isResponsiveScreen ? 'subtitle1' : 'h6'}
                    fontFamily="Open Sans"
                    align="right"
                >
                    {getFormattedAmount(totalBalance)}
                </Typography>,
            headerClassName: `${classes.rightAlign} ${classes.columnHeader}`,
            footerClassName: `${classes.totalBalance} ${classes.rightAlign} ${classes.columnHeader} `,
            formatter: balanceFormatter,
            align: 'right',
        },
    ];
    return columns;
};

export { getFooterData, getColumns };
