import { VIEW } from 'Commons/config/constants/Constants';
import { CONFIG_ENDPOINTS } from 'Commons/config/constants/Endpoints';

const getLateEventApiConfig = (entityId, accountId) => {
    const apiConfig = {
        api: {
            dynamic: {
                endPoint: CONFIG_ENDPOINTS.search,
                body: {
                    view: VIEW.detail.value,
                    filter: [{
                        terms: {
                            value: { status: ['Active'] },
                            entityId: [entityId],
                            entityType: ['Facility'],
                            configType: ['Late_Event'],
                        },
                    }],
                },
                ...accountId
                && {
                    headers: {
                        'context-type': 'Account',
                        'context-id': accountId,
                    },
                },
            },
        },
    };
    return {
        search: apiConfig,
        autoSuggest: apiConfig,
        sendCustomHeader: false,
    };
};

export { getLateEventApiConfig };
