import arrowTheme from '../../../../theme/ArrowTheme';

const menuStyle = theme => ({
    link: { padding: '0 16px' },
    anchorElDiv: { display: 'inline-block' },
    anchorEl: { display: 'inline' },
    popper: {
        ...arrowTheme.popper,
        '& $reactlink, & $reactlink:hover': { color: theme.palette.text.primary },
    },
    arrow: { ...arrowTheme.arrow },
    arrowShadow: { ...arrowTheme.arrowShadow },
    reactlink: {},
});

export default menuStyle;
