import { clsx } from 'Commons/helpers/utils/clsx';
import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import Form from '../../form/components/Form';
import { FormControlLabel, Radio, RadioGroup as MaterialUiRadioGroup, withStyles, FormLabel }
    from '../../componentlibrary/components/Components';
import Icon from '../../icon/components/Icon';
import radioGroupStyle from '../styles/RadioGroupStyle';
import { processValue } from '../../../../helpers/api/Formatter';


class RadioGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        const { trackValue } = this.props;
        if (trackValue) {
            const { value } = props;
            this.state = { value };
        }
    }

    onChange = (event) => {
        const { onChange, dataType, trackValue } = this.props;
        if (trackValue) {
            this.setState({ value: event.target.value });
        }
        const value = processValue(event.target.value, dataType);
        onChange(event, value);
    }

    getValue = (value) => {
        const { defaultValueWhenNull } = this.props;
        return ((value != null) ? `${value}` : defaultValueWhenNull);
    }

    render() {
        const {
            form, onChange, value, list, trackValue, RadioProps, classes, label, dataType, defaultValueWhenNull,
            FormLabelProps, preferred, FormControlLabelProps, required, disabled, className, readOnly, ...props
        } = this.props;
        const { value: stateValue } = this.state;
        return (
            <>
                {label && <FormLabel disabled={disabled} classes={{ root: classes.root }} {...FormLabelProps}>{label}</FormLabel>}
                <MaterialUiRadioGroup
                    onChange={this.onChange}
                    className={clsx(
                        classes.radioButtonGroup,
                        { [classes.paddingTop]: label },
                        className,
                    )}
                    value={trackValue ? this.getValue(stateValue) : this.getValue(value)}
                    {...props}
                >
                    {
                        list.map(({ label: radioLabel, value: radioValue, disabled: disabledOption, ...radioProps }) => (
                            <FormControlLabel
                                key={radioValue}
                                label={radioLabel}
                                value={this.getValue(radioValue)}
                                disabled={disabled || disabledOption}
                                classes={{ root: classes.formControlLabelRoot }}
                                control={(
                                    <Radio
                                        disableRipple
                                        color={readOnly ? 'default' : 'primary'}
                                        className={clsx(readOnly && classes.readOnly)}
                                        icon={<Icon className={classes.sizeIcon} icon="cp-radio-off" type="custom" />}
                                        checkedIcon={<Icon className={classes.sizeIcon} icon="cp-radio-on" type="custom" />}
                                        required={required}
                                        {...radioProps}
                                    />
                                )}
                                className={clsx(readOnly && classes.InheritedConfigLabel)}
                                {...FormControlLabelProps}
                            />
                        ))
                    }
                </MaterialUiRadioGroup>
            </>
        );
    }
}

RadioGroup.propTypes = {
    form: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    trackValue: PropTypes.bool,
    list: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.node,
    preferred: PropTypes.bool,
    dataType: PropTypes.oneOf(['string', 'number', 'boolean']),
    name: PropTypes.string,
    required: PropTypes.bool,
    RadioProps: PropTypes.object,
    FormLabelProps: PropTypes.object,
    FormControlLabelProps: PropTypes.object,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    defaultValueWhenNull: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

RadioGroup.defaultProps = {
    form: { setFieldValue: () => { } },
    onChange: () => { },
    trackValue: true,
    value: null,
    preferred: false,
    dataType: 'boolean',
    RadioProps: {},
    FormLabelProps: {},
    FormControlLabelProps: {},
    disabled: false,
    readOnly: false,
    defaultValueWhenNull: null,
};

const withStyleRadioGroup = withStyles(radioGroupStyle)(RadioGroup);
withStyleRadioGroup.displayName = FORM_COMPONENTS.RADIO_GROUP;
const FormRadioGroup = Form(withStyleRadioGroup);

export {
    withStyleRadioGroup as RadioGroup,
    FormRadioGroup,
};
