import { REPORT_WC_ENDPOINTS } from 'Commons/config/constants/Endpoints';

const generateReport = (onAction, body, reportIdOrType, entityType, entityId, accountId, callback) => {
    onAction({
        config: [{
            api: {
                action: {
                    methodType: 'POST',
                    baseUrl: process.env.REPORT_URL,
                    endPoint: REPORT_WC_ENDPOINTS.report.generate(reportIdOrType),
                    body,
                    headers: {
                        'context-type': entityType,
                        'context-id': entityId,
                        ...accountId ? { 'account-id': accountId } : {},
                    },
                },
            },
            callback,
        }],
    });
};

const createCategory = (onAction, body, accountId, callback) => {
    onAction({
        config: [{
            api: {
                action: {
                    methodType: 'POST',
                    baseUrl: process.env.REPORT_URL,
                    endPoint: REPORT_WC_ENDPOINTS.category.create,
                    body,
                    headers: { ...accountId ? { 'account-id': accountId } : {} },
                },
            },
            callback,
        }],
    });
};

const updateCategory = (onAction, id, body, accountId, callback) => {
    onAction({
        config: [{
            api: {
                action: {
                    methodType: 'PUT',
                    baseUrl: process.env.REPORT_URL,
                    endPoint: REPORT_WC_ENDPOINTS.category.update(id),
                    body,
                    headers: { ...accountId ? { 'account-id': accountId } : {} },
                },
            },
            callback,
        }],
    });
};

const deleteCategory = (onAction, id, callback) => {
    onAction({
        config: [{
            api: {
                action: {
                    methodType: 'DELETE',
                    baseUrl: process.env.REPORT_URL,
                    endPoint: REPORT_WC_ENDPOINTS.category.delete(id),
                },
            },
            callback,
        }],
    });
};

export { generateReport, createCategory, updateCategory, deleteCategory };
