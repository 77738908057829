import useConstructor from 'Commons/helpers/hooks/useConstructor';
import localisable from 'Commons/config/strings/localisable';
import { FormSmartSearchableDropdown } from 'Generic/dropdown/components/SearchableDropdown';
import { labelFormatter, getOptionProps } from 'Commons/helpers/utils/SDFormatter';
import { MEDIUM_SD_HEIGHT } from 'Commons/components/generic/dropdown/config/Constants';
import { timezoneApiConfig } from '../config/ApiRequests';
import { getTimezoneLabel } from '../utils/TimezoneHelpers';

const TimezoneDropdown = ({ value, ...props }) => {
    const timezoneList = useConstructor(() => (value ? [{ value, label: getTimezoneLabel(value) }] : []));

    return (
        <FormSmartSearchableDropdown
            fullWidth
            isStoreUpdate
            maxHeight={MEDIUM_SD_HEIGHT}
            keyToMatch="value"
            options={timezoneList}
            autoSuggestKey="autoSuggest"
            label={localisable.timezone}
            apiConfig={timezoneApiConfig}
            getOptionProps={getOptionProps}
            inputValueFormatter={labelFormatter}
            placeholder={localisable.selectTimezone}
            {...props}
        />
    );
};

TimezoneDropdown.propTypes = { value: PropTypes.string };

export default TimezoneDropdown;
