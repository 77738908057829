import React, { memo } from 'react';
import { Grid, Typography, makeStyles } from 'Generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import { getDate } from 'Commons/helpers/utils/DateTime';
import { GridListWithShowMore } from 'Commons/components/business/formatters/components/Formatters';
import dayStyle from '../styles/DayStyle';

const useStyles = makeStyles(dayStyle, { name: 'Day' });

const Day = ({
    displayDay,
    completeDate,
    dataForDay,
    currentDate,
    disabled, shouldTruncateTitle, truncateTitleLength,
    renderInRows,
    maxVisibleContent,
    onClickMoreItems,
    ...restProps
}) => {
    const { deviceInfo: { isDesktop, isLaptop, isTablet } } = window;
    const currentDateStr = getDate(currentDate);
    const classes = useStyles({ isDateToday: completeDate === currentDateStr, renderInRows, isDesktop, isLaptop, isTablet });

    const renderSingleDayData = (singleDayData = []) => (
        <GridListWithShowMore
            fullHeight={false}
            maxVisibleContent={maxVisibleContent}
            requireTooltip
            GridProps={{ container: false }}
            // eslint-disable-next-line no-sparse-arrays
            argument={[singleDayData,,, {}, {}]}
            classes={classes}
            showStatusIcon
            onClickMoreItems={onClickMoreItems}
            onClickMoreItemsParams={{ completeDate }}
            shouldTruncateTitle={shouldTruncateTitle}
            truncateTitleLength={truncateTitleLength}
            {...restProps}
        />
    );
    return (
        <div
            className={clsx({ [classes.disabled]: disabled }, classes.dayContainer)}
            key={completeDate}
        >
            <Grid item xs={2}>
                <Typography variant="h6" className={clsx({ [classes.dateBadge]: completeDate === currentDateStr })}>
                    {displayDay}
                </Typography>
            </Grid>
            {renderSingleDayData(dataForDay)}
        </div>
    );
};

Day.propTypes = {
    displayDay: PropTypes.string,
    dataForDay: PropTypes.array,
    disabled: PropTypes.bool,
    shouldTruncateTitle: PropTypes.bool,
    truncateTitleLength: PropTypes.number,
    currentDate: PropTypes.object,
    completeDate: PropTypes.string,
    renderInRows: PropTypes.bool,
    maxVisibleContent: PropTypes.number,
    onClickMoreItems: PropTypes.func,
};

Day.defaultProps = {
    displayDay: '',
    dataForDay: [],
    disabled: false,
    shouldTruncateTitle: false,
    truncateTitleLength: 10,
    maxVisibleContent: 1,
};

export default memo(Day);
