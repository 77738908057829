import media from 'Commons/theme/MediaQueries';

const levelManagementStyles = theme => ({
    modalHeader: { padding: theme.spacing(2, 4) },
    modalBody: { padding: theme.spacing(0, 4) },
    modalFooter: { padding: theme.spacing(2, 4) },
    modalGrid: {
        [media.laptopUp]: { width: '35vw' },
        [media.tablet]: { height: '80vh', width: '80vw' },
        [media.mobile]: { height: '70vh' },
        borderRadius: 4,
        flexWrap: 'noWrap',
    },
    addButton: { marginLeft: theme.spacing(1) },
    listContainer: { width: '100%' },
    listHeader: { borderBottom: `1px ${theme.palette.black[600]} solid` },
    listColumnHeading: { paddingBottom: theme.spacing(1) },
    lastColumnPadding: { paddingRight: theme.spacing(6) },
    lastColumnValuePadding: { paddingRight: theme.spacing(4) },
    levelsContainer: {
        marginTop: theme.spacing(1),
        height: '30vh',
        [media.laptop]: { height: '25vh' },
        [media.tablet]: { height: '64vh' },
        [media.mobile]: { height: '40vh' },
        overflow: 'auto',
    },
    deleteButton: { color: theme.palette.disabled },
    unitsOnLevel: {
        fontWeight: 400,
        fontSize: 14,
    },
    rightPadding: { paddingRight: theme.spacing(2) },
    error: { color: theme.palette.error.main },
});

export default levelManagementStyles;
