import { FormTextField } from 'Generic/textfield/components/TextField';
import { Grid, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { SM_2_XS_4, SM_6, XS_8, XS_4 } from 'Commons/config/constants/GridSpacingConstants';
import { SUFFIX, SALUTATIONS } from 'Commons/config/constants/Constants';
import { FormDropdown } from 'Commons/components/generic/dropdown/components/Dropdown';
import { IS_MAX_LENGTH_50, IS_MAX_LENGTH_100 } from 'Commons/config/constants/Validators';
import { memo } from 'react';
import localisable from 'Commons/config/strings/localisable';
import { clsx } from 'Commons/helpers/utils/clsx';
import nameStyles from '../styles/NameStyles';

const useStyles = makeStyles(nameStyles, { name: 'Name' });

const { deviceInfo: { isMobile } } = window;

const NameFields = ({
    mapping: {
        firstName: { visibility: firstNameVisibility = true, ...firstNameProps } = {},
        lastName: { visibility: lastNameVisibility = true, ...lastNameProps } = {},
        salutation: { visibility: salutationVisibility = true, ...salutationProps } = {},
        suffix: { visibility: suffixVisibility = true, ...suffixProps } = {},
        preferredName: { visibility: preferredNameVisibility = true, ...preferredNameProps } = {},
        businessName: { visibility: businessNameVisibility = true, ...businessNameProps } = {},
        reverseLastNameAndSuffix = isMobile,
    } = {},
    touched = {},
}) => {
    const classes = useStyles();
    const { name: { salutation: isSalutationTouched = false, suffix: isSuffixTouched = false } = {} } = touched || {};

    const renderLastNameAndSuffix = () => (
        <>
            {
                lastNameVisibility
                && (
                    <FormTextField
                        name="name.lastName"
                        label={localisable.lastName}
                        placeholder={localisable.exampleLastName}
                        fullWidth
                        validate={IS_MAX_LENGTH_100}
                        GridProps={XS_8}
                        classes={{
                            spacingFormGrid: classes.spacingFormGrid,
                            item: clsx(classes.name, reverseLastNameAndSuffix ? classes.order : undefined),
                        }}
                        {...lastNameProps}
                    />
                )
            }

            {
                suffixVisibility
                && (
                    <FormDropdown
                        name="name.suffix"
                        clearable
                        label={localisable.tenantNameSuffix}
                        GridProps={SM_2_XS_4}
                        fullWidth
                        list={Object.values(SUFFIX)}
                        {...suffixProps}
                        alwaysShowError={isSuffixTouched} // To do: Make it centralized in future
                    />
                )
            }
        </>
    );
    return (
        <>
            {
                salutationVisibility
                && (
                    <FormDropdown
                        name="name.salutation"
                        clearable
                        label={localisable.tenantNameSalutation}
                        fullWidth
                        classes={{
                            spacingFormGrid: classes.spacingFormGrid,
                            item: classes.salutation,
                        }}
                        GridProps={XS_4}
                        list={Object.values(SALUTATIONS)}
                        {...salutationProps}
                        alwaysShowError={isSalutationTouched} // To do: Make it centralized in future
                    />
                )
            }

            {
                firstNameVisibility
                && (
                    <FormTextField
                        name="name.firstName"
                        label={localisable.firstName}
                        placeholder={localisable.exampleFirstName}
                        fullWidth
                        validate={IS_MAX_LENGTH_100}
                        GridProps={XS_8}
                        classes={{
                            spacingFormGrid: classes.spacingFormGrid,
                            item: classes.name,
                        }}
                        {...firstNameProps}
                    />
                )
            }

            {
                reverseLastNameAndSuffix
                    ? (
                        <Grid container>
                            {renderLastNameAndSuffix()}
                        </Grid>
                    )
                    : renderLastNameAndSuffix()
            }

            {
                preferredNameVisibility
                && (
                    <FormTextField
                        name="name.preferredName"
                        label={localisable.tenantNamePreferredName}
                        placeholder={localisable.exampleFirstName}
                        fullWidth
                        validate={IS_MAX_LENGTH_100}
                        GridProps={SM_6}
                        {...preferredNameProps}
                    />
                )
            }

            {
                businessNameVisibility
                && (
                    <FormTextField
                        name="name.businessName"
                        label={localisable.tenantNameBusinessName}
                        placeholder={localisable.exampleBusinessName}
                        fullWidth
                        validate={IS_MAX_LENGTH_50}
                        GridProps={SM_6}
                        {...businessNameProps}
                    />
                )
            }

        </>
    );
};

NameFields.propTypes = { mapping: PropTypes.object, touched: PropTypes.object };

// TODO: Remove setConfig after Lead is refactored to new form standards
const Name = ({ noGrid, mapping, setConfig, touched, ...props }) => (noGrid
    ? <NameFields mapping={mapping} touched={touched} />
    : (
        <Grid container {...props}>
            <NameFields mapping={mapping} touched={touched} />
        </Grid>
    )
);

Name.propTypes = {
    setConfig: PropTypes.func,
    noGrid: PropTypes.bool,
    mapping: PropTypes.object,
    touched: PropTypes.object,
};

export default memo(Name);
