import media from '../../../../theme/MediaQueries';

const cardStyle = theme => ({
    addon: {
        width: 'auto',
        position: 'absolute',
        cursor: 'pointer',
    },
    topLeft: { left: 0 },
    topRight: { right: 0 },
    bottomLeft: { bottom: 0, left: 0 },
    bottomRight: { bottom: 0, right: 0 },
    card: {
        boxShadow: theme.boxShadows[0],
        width: '100%',
        borderSizing: 'border-block',
    },
    cardContent: {
        position: 'relative',
        [media.smallMobile]: { padding: 16 },
        [media.mobile]: { padding: 16 },
        padding: 20,
    },
});

export default cardStyle;
