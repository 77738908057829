/* eslint-disable eqeqeq */
import { useState, useRef, useEffect } from 'react';
import { TIMEOUT } from 'Commons/config/constants/Constants';
import { getValue as getIn, getValue, isObjWithKeys, sortArray } from 'Commons/helpers/utils/DataHelpers';
import { List, ListItem, withStyles, Typography, Loader } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import RequestTypes from 'Commons/config/constants/RequestTypes';
import useDidUpdateEffect from 'Commons/helpers/hooks/useDidUpdateEffect';
import ThreeDotProgress from 'Generic/threedotprogress/components/ThreeDotProgress';
import Icon from 'Generic/icon/components/Icon';
import Button from 'Generic/button/components/Button';
import Form from 'Generic/form/components/Form';
import Popdown from 'Generic/popdown/components/Popdown';
import { clsx } from 'Commons/helpers/utils/clsx';
import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import { isBetween } from 'Commons/helpers/utils/Utils';
import dropdownStyle from '../styles/DropdownStyle';
import getConfig from '../config/ActionConfig';
import { ERROR } from '../config/Constants';

let timeout;

const SearchableDropdown = ({
    addon,
    sortBy,
    classes,
    onAction,
    readOnly,
    onChange,
    maxHeight,
    apiConfig,
    keyToMatch,
    getInputRef,
    fetchInitial,
    PopdownProps,
    isStoreUpdate,
    getOptionProps,
    autoSuggestKey,
    optionFormatter,
    offlineSearchKey,
    textFieldClasses,
    noOptionsMessage,
    getAutoSuggestKey,
    inputValueFormatter,
    autoSuggestKeySetter,
    resetValueOnConfigChange,
    value: propValue,
    options: initialPropOptions,
    disabled,
    fetchOnce,
    apiConfig: {
        sendCustomHeader,
        search: searchConfig,
        autoSuggest: autoSuggestConfig,
    },
    InputProps: {
        className = '',
        classes: { root: inputRootClasses, disabled: inputDisabledClasses, ...inputClasses } = {},
        ...InputProps
    } = {},
    form, // eslint-disable-line react/prop-types
    clear, // eslint-disable-line react/prop-types
    dispatch, // eslint-disable-line react/prop-types
    routeList, // eslint-disable-line react/prop-types
    timezone, // eslint-disable-line react/prop-types
    fetchLazy, // eslint-disable-line react/prop-types
    unauthorised, // eslint-disable-line react/prop-types
    onMountConfig, // eslint-disable-line react/prop-types
    clearStoreKeys, // eslint-disable-line react/prop-types
    currentFacility, // eslint-disable-line react/prop-types
    currentAccountId, // eslint-disable-line react/prop-types
    supportTextField,
    getOpenFunc,
    ...props
}) => {
    const propOptionsRef = useRef(initialPropOptions);
    const { current: propOptions } = propOptionsRef;
    const [options, setOptions] = useState(propOptions);
    const [initialFetchedData, setInitialFetchedData] = useState();
    const [optionsCount, setOptionsCount] = useState(propOptions.length);
    const inputRef = useRef({});

    useEffect(() => {
        if (getInputRef) {
            getInputRef(inputRef);
        }
        /*
            When it has the value already set, clicking on cross shows the options and it changes once the data is fetched.
            To avoid this, once the options are consumed to show the value in text field, we are setting the options to null.
            This way clicking on cross will show the loader and the options will be shown once fetched.
        */
        if (isObjWithKeys(apiConfig) && fetchOnce) { setOptions(null); }
    }, []);

    const isOfflineFilterEnabled = () => !Object.keys(apiConfig).length || (fetchOnce && options);
    const hasData = optionsList => optionsList && optionsList.length;
    const getCurrentOption = (valueToFind, optionsList = []) => optionsList.find(option => getIn(option, keyToMatch) == valueToFind);
    const getInputValue = (currentValue, optionsList) => {
        if (!currentValue) {
            return '';
        }

        let newInputValue = currentValue;
        const currentOption = getCurrentOption(currentValue, optionsList);
        if (currentOption) {
            if (inputValueFormatter) {
                newInputValue = inputValueFormatter(currentOption);
                if (typeof newInputValue !== 'string') {
                    console.error('inputValue expected to be string but got ', typeof newInputValue);
                }
            }
        } else {
            console.error(`SearchableDropdown : ${currentValue} is not present in the given options`);
            return '';
        }
        return newInputValue;
    };
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(hasData(options) ? propValue : '');
    const [inputValue, setInputValue] = useState(() => (hasData(options) ? getInputValue(propValue, options) : ''));
    const [isOnline] = useState(() => !!Object.keys(apiConfig).length);

    const openDropdown = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (open) {
            inputRef.current.focus();
        }
    }, [open]);

    useEffect(() => {
        if (getOpenFunc) {
            getOpenFunc(openDropdown);
        }
    }, []);

    const setOptionsData = (inputData, totalCount, requestType, onClear) => {
        let data = inputData;
        if (requestType === RequestTypes.INIT) {
            setInitialFetchedData(data);
        } else if (isOnline && requestType === RequestTypes.PAGINATE) {
            data = [...options, ...data];
        }
        setOptionsCount(totalCount);

        if (requestType !== RequestTypes.INIT || onClear
            || (requestType === RequestTypes.INIT && !hasData(options))) {
            setOptions(data);
        }
    };

    useEffect(() => {
        if (inputValue && document.activeElement === inputRef.current) {
            setOpen(true);
        }
    }, [inputValue]);

    const onFetchData = (requestType, onClear, userInput) => (apiError, response) => {
        setLoading(false);
        if (apiError) {
            setError(localisable.somethingWentWrong);
        } else if (response) {
            const { totalCount } = response;
            let { data } = response;
            if (optionFormatter) {
                data = optionFormatter(response, userInput);
            }
            if (fetchOnce) propOptionsRef.current = data;
            setOptionsData(data, totalCount, requestType, onClear);
        }
    };

    const getFieldName = (name) => {
        const delimiter = '.';
        return name.includes(delimiter) ? name.split(delimiter) : name;
    };

    const setIn = (filter, userInput) => {
        let autoSuggestKeyToSend = autoSuggestKey;
        if (getAutoSuggestKey) {
            autoSuggestKeyToSend = getAutoSuggestKey(userInput);
        }
        let autoSuggest = filter;
        const fieldNames = getFieldName(autoSuggestKeyToSend);
        let lastField = autoSuggestKeyToSend;

        if (Array.isArray(fieldNames)) {
            lastField = fieldNames.pop();
            fieldNames.forEach((fieldName) => {
                autoSuggest[fieldName] = {};
                autoSuggest = autoSuggest[fieldName];
            });
        }
        autoSuggest[lastField] = userInput;
    };

    const fetchData = ({ userInput, requestType, onClear = false }) => {
        setLoading(true);
        if (isOnline) {
            let apiOptions = {
                isStoreUpdate,
                requestType,
                callback: onFetchData(requestType, onClear, userInput),
                sendCustomHeader,
            };

            if (userInput) {
                if (!isStoreUpdate) {
                    const filter = {};
                    if (autoSuggestKeySetter) {
                        autoSuggestKeySetter(filter, userInput);
                    } else {
                        setIn(filter, userInput);
                    }
                    apiOptions.filter = [filter];
                } else {
                    let autoSuggestKeyToSend = autoSuggestKey;
                    if (getAutoSuggestKey) {
                        autoSuggestKeyToSend = getAutoSuggestKey(userInput);
                    }
                    apiOptions.payload = { [autoSuggestKeyToSend]: userInput };
                }
            }

            if (requestType === RequestTypes.PAGINATE) {
                apiOptions = {
                    ...apiOptions,
                    ...(isStoreUpdate
                        ? {
                            payload: {
                                ...apiOptions.payload,
                                start: options.length,
                            },
                        }
                        : { start: options.length }),
                };
            }

            const passedConfig = userInput ? autoSuggestConfig : searchConfig;
            const config = getConfig(apiOptions, passedConfig);
            onAction({ config });
        } else {
            let data = propOptions;
            if (userInput) {
                const pattern = new RegExp(userInput, 'i');
                data = data.filter(row => row.label.match(pattern));
            }
            const totalCount = data.length;

            if (optionFormatter) {
                data = optionFormatter(data, userInput);
            }
            setOptionsData(data, totalCount, requestType, onClear);
            setLoading(false);
        }
    };

    useDidUpdateEffect(() => {
        setInitialFetchedData(null);
        setOptions(null);
        if (resetValueOnConfigChange) {
            setValue(null);
            setInputValue('');
        }
    }, [apiConfig]);

    useDidUpdateEffect(() => {
        setValue(propValue);
        setInputValue(getInputValue(propValue, options));
    }, [propValue]);

    const onListScroll = ({
        target: {
            scrollHeight,
            scrollTop,
            clientHeight,
        },
    } = {}) => {
        const scrollPosition = scrollHeight - scrollTop;
        const hasReachedBottom = isBetween(scrollPosition, clientHeight - ERROR, clientHeight + ERROR);
        if (hasReachedBottom && !fetchOnce && optionsCount > options.length) {
            fetchData({ userInput: inputValue, requestType: RequestTypes.PAGINATE });
        }
    };

    const filterOnline = (currentInputValue) => {
        if (!currentInputValue) {
            if (!initialFetchedData && fetchInitial) {
                fetchData({ requestType: RequestTypes.INIT, onClear: true });
            } else {
                setOptions(initialFetchedData);
            }
        }
        if (currentInputValue !== inputValue) {
            if (currentInputValue) {
                if (timeout) clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fetchData({ userInput: currentInputValue });
                }, TIMEOUT);
            }
            setInputValue(currentInputValue);
        } else if (timeout) {
            clearTimeout(timeout);
        }
    };

    const filterOffline = (currentInputValue) => {
        if (currentInputValue) {
            const lowerCaseInputValue = currentInputValue.toLowerCase();
            let autoSuggestKeyToSend = autoSuggestKey;
            if (getAutoSuggestKey) {
                autoSuggestKeyToSend = getAutoSuggestKey(currentInputValue);
            }
            const searchKey = offlineSearchKey || autoSuggestKeyToSend.substring(autoSuggestKeyToSend.indexOf('.') + 1); // To remove matchPhasePrefix from it
            const filteredOptions = propOptions.filter((option) => {
                const lowerCaseOptionValue = (getValue(option, searchKey) || '').toLowerCase();
                return lowerCaseOptionValue.startsWith(lowerCaseInputValue);
            });
            setOptions(filteredOptions);
        } else {
            setOptions(propOptions);
        }
        setInputValue(currentInputValue);
    };

    const fetch = (currentInputValue) => {
        if (isOfflineFilterEnabled()) {
            filterOffline(currentInputValue);
        } else {
            filterOnline(currentInputValue);
        }
    };

    const onInputChange = (_, currentInputValue) => {
        if (supportTextField) {
            setValue(currentInputValue);
        }
        if (!fetchInitial) {
            setOpen(!open);
            setLoading(!open);
        }
        fetch(currentInputValue);
    };

    const handleValue = (event) => {
        const currentValue = getInputValue(value, options);
        setInputValue(currentValue);

        // If value selected is not found in the option,
        // setting empty value in the Formik by calling onChange manually
        if (!currentValue) {
            const currentOption = getCurrentOption(value, options);
            onChange(event, currentValue, currentOption);
            onInputChange(event, currentValue);
        }
    };

    const onClickAway = (event) => {
        if (event.target !== inputRef.current && !supportTextField) {
            handleValue(event);
            setOpen(false);
        } else {
            onChange(event, value);
            setInputValue(value);
            setOpen(false);
        }
    };

    const onClick = () => {
        const isOpen = !open ? true : null;
        if (fetchInitial) {
            if (isOpen) {
                if (!initialFetchedData && !hasData(options)) {
                    fetchData({ requestType: RequestTypes.INIT });
                }
            }
            if (isOpen || !inputValue) {
                setOpen(isOpen);
            }
        }
    };

    const onOptionClick = (event, item) => {
        const currentValue = getIn(item, keyToMatch);
        if (currentValue !== value) {
            setValue(currentValue);
            onChange(event, currentValue, item);
        }
        setInputValue(getInputValue(currentValue, options));
        setOpen(false);
    };

    const renderText = children => (
        <Typography
            component="li"
            variant="body2"
            align="center"
            className={`${classes.listItem} ${classes.message}`}
        >
            {children}

        </Typography>
    );

    const getSortedList = () => (isOfflineFilterEnabled() ? sortArray(options, sortBy) : options);


    const renderListItem = () => {
        if (loading && !hasData(options)) return renderText(<ThreeDotProgress />);
        if (error) return renderText(error);
        if (!hasData(options)) return renderText(noOptionsMessage);
        return getSortedList().map((item, index) => {
            const { id, label, className: itemClassName, ...listItemProps } = getOptionProps(item) || {};
            return (
                <ListItem
                    cy-id={`option_${index}`}
                    key={index}
                    className={clsx(
                        classes.listItem,
                        { [classes.selected]: getIn(item, keyToMatch) === value },
                        itemClassName,
                    )}
                    onClick={e => onOptionClick(e, item)}
                    {...listItemProps}
                >
                    <Typography component="div" className={classes.fullWidth}>
                        {label || id}
                    </Typography>
                </ListItem>
            );
        });
    };

    // TODO: Add readonly support
    return (
        <div className={classes.dropdown}>
            <Popdown
                value={inputValue}
                trackValue={false}
                InputProps={{
                    ...!supportTextField && { inputProps: { readOnly } },
                    onClick,
                    className,
                    classes: {
                        root: `${classes.inputRoot} ${inputRootClasses}`,
                        disabled: `${disabled ? classes.disabled : ''} ${inputDisabledClasses}`,
                        ...inputClasses,
                    },
                    ...InputProps,
                }}
                readOnly={readOnly}
                addon={{
                    start: !readOnly && (
                        <Icon
                            type="custom"
                            icon="cp-search-e2e"
                            size="small"
                            disabled={disabled}
                            className={classes.searchIcon}
                        />
                    ),
                    end: !readOnly && (
                        // eslint-disable-next-line no-nested-ternary
                        (loading && hasData(options)) ? renderText(<Loader disableShrink size={16} />)
                            : inputValue && !supportTextField
                                ? (
                                    <Button
                                        variant="icon"
                                        icon="cp-close"
                                        color="primary"
                                        disabled={disabled}
                                        iconType="custom"
                                        onClick={(event) => {
                                            inputRef.current.focus();
                                            event.stopPropagation();
                                            onInputChange(event, '');
                                            onChange();
                                            if (fetchInitial) {
                                                setOpen(true);
                                            }
                                        }}
                                        IconProps={{ size: 'medium' }}
                                    />
                                )
                                : (
                                    <Button
                                        variant="icon"
                                        iconType="custom"
                                        icon="cp-arrow-down"
                                        disabled={disabled}
                                        color="primary"
                                    />
                                )),
                    ...addon,
                }}
                open={open}
                disabled={disabled}
                onClickAway={onClickAway}
                classes={{ paper: classes.paper }}
                maxHeight={maxHeight}
                TextFieldClasses={textFieldClasses}
                PaperProps={{ square: true, onScroll: onListScroll }}
                onChange={onInputChange}
                inputRef={inputRef}
                {...props}
                {...PopdownProps}
            >
                <List disablePadding>
                    {renderListItem()}
                </List>
            </Popdown>
        </div>
    );
};

SearchableDropdown.propTypes = {
    getInputRef: PropTypes.func,
    apiConfig: PropTypes.shape({
        search: PropTypes.object,
        pagination: PropTypes.object,
        sendCustomHeader: PropTypes.bool,
    }),
    readOnly: PropTypes.bool,
    fetchInitial: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.array,
    onAction: PropTypes.func,
    keyToMatch: PropTypes.string,
    getOptionProps: PropTypes.func,
    initialValue: PropTypes.string,
    optionFormatter: PropTypes.func,
    inputValueFormatter: PropTypes.func,
    addon: PropTypes.shape({
        start: PropTypes.node,
        end: PropTypes.node,
    }),
    onChange: PropTypes.func,
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    InputProps: PropTypes.object,
    isStoreUpdate: PropTypes.bool,
    PopdownProps: PropTypes.object,
    autoSuggestKey: PropTypes.string,
    getAutoSuggestKey: PropTypes.func,
    noOptionsMessage: PropTypes.string,
    textFieldClasses: PropTypes.object,
    offlineSearchKey: PropTypes.string,
    autoSuggestKeySetter: PropTypes.func,
    resetValueOnConfigChange: PropTypes.bool,
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    fetchOnce: PropTypes.bool, /* If true, sd will fetch the data once and filter it offline.
    It has to have apiConfig to work this way. */
    supportTextField: PropTypes.bool, // To get the value we type in the searchable dropdown, this field will give you value on clickaway.
    sortBy: PropTypes.arrayOf(PropTypes.string), // Only applicable for offline searches,
    getOpenFunc: PropTypes.func,
};

SearchableDropdown.defaultProps = {
    addon: {},
    options: [],
    apiConfig: {},
    readOnly: false,
    maxHeight: 240,
    disabled: false,
    PopdownProps: {},
    onChange: () => { },
    isStoreUpdate: false,
    textFieldClasses: {},
    autoSuggestKey: 'autoSuggest',
    resetValueOnConfigChange: true,
    getOptionProps: x => ({ label: x }),
    noOptionsMessage: localisable.noOptions,
    fetchOnce: false,
    fetchInitial: true,
    supportTextField: false,
    sortBy: ['label'],
};

const SearchableDropdownComponent = withStyles(dropdownStyle)(SearchableDropdown);
const SmartSearchableDropdown = window.withProvider('SearchableDropdown');

SearchableDropdownComponent.displayName = FORM_COMPONENTS.SEARCHABLE_DROPDOWN;
SmartSearchableDropdown.displayName = FORM_COMPONENTS.SMART_SEARCHABLE_DROPDOWN;

const FormSearchableDropdown = Form(SearchableDropdownComponent);
const FormSmartSearchableDropdown = Form(SmartSearchableDropdown);


export default SearchableDropdownComponent;

export {
    SearchableDropdownComponent as SearchableDropdown,
    SmartSearchableDropdown,
    FormSearchableDropdown,
    FormSmartSearchableDropdown,
};
