
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import { EMPTY_OBJECT } from 'Commons/config/constants/Constants';
import confirmationAlertStyles from '../styles/ConfirmationAlertStyles';


const useStyles = makeStyles(confirmationAlertStyles, { name: 'ConfirmationAlert' });

const ConfirmationAlert = ({
    open,
    title,
    onClose,
    message,
    onClickNo,
    onClickYes,
    noButtonLabel,
    noButtonProps,
    yesButtonProps,
    yesButtonLabel,
    ...props
}) => {
    const classes = useStyles(props);
    return (
        <AlertDialog
            open={open}
            onClose={onClose}
            title={title || localisable.warning}
            actions={(
                <>
                    {
                        noButtonLabel
                        && (
                            <Button
                                variant="text"
                                color="error"
                                onClick={onClickNo || onClose}
                                className={classes.noBtn}
                                {...noButtonProps}
                            >
                                <Typography>{noButtonLabel}</Typography>
                            </Button>
                        )
                    }
                    {
                        yesButtonLabel && (
                            <Button
                                onClick={onClickYes}
                                variant="text"
                                color="primary"
                                {...yesButtonProps}
                            >
                                <Typography>{yesButtonLabel}</Typography>
                            </Button>
                        )}
                </>
            )}
            dialogClasses={{ paperWidthSm: classes.invoiceAlert }}
        >
            {message}
        </AlertDialog>
    );
};

ConfirmationAlert.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.node,
    message: PropTypes.node,
    onClose: PropTypes.func,
    onClickNo: PropTypes.func,
    onClickYes: PropTypes.func,
    noButtonLabel: PropTypes.node,
    yesButtonLabel: PropTypes.node,
    noButtonProps: PropTypes.object,
    yesButtonProps: PropTypes.object,
};

ConfirmationAlert.defaultProps = {
    open: false,
    noButtonProps: EMPTY_OBJECT,
    yesButtonProps: EMPTY_OBJECT,
    message: `${localisable.invoiceAlreadyScheduled} ${localisable.wantToCancelScheduled}`,
};

export default React.memo(ConfirmationAlert);
