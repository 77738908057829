import media from 'Commons/theme/MediaQueries';

const monthStyle = (theme) => {
    const minHeight = 57.5;
    return {
        paper: {
            padding: theme.spacing(1),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            border: '1px solid #E6E7EB',
            minHeight: `${minHeight}px`,
        },
        weekDayContainer: ({ renderInRows }) => ({ padding: theme.spacing(1), minHeight: `${minHeight / 10 * 12}px`, minWidth: renderInRows ? '70px' : '56px' }),
        weekDays: ({ renderInRows }) => ({ ...(!renderInRows && { width: '55px', minWidth: '50px', [media.desktopUp]: { width: '100px', minWidth: '100px' } }) }),
    };
};

export default monthStyle;
