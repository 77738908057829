import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import BillAheadDateFormatter from './Formatters';

const getLabel = label => (
    <Typography variant="subtitle2">
        {label}
    </Typography>
);

const getBillAheadColumns = (classes, currency) => [
    {
        label: getLabel(localisable.unit),
        key: 'unitLabel',
        width: 15,
    },
    {
        label: getLabel(localisable.chargeCategory),
        key: 'chargeCategoryDescription',
        width: 15,
    },
    {
        label: getLabel(localisable.date),
        key: 'toDate',
        formatter: BillAheadDateFormatter,
        align: 'center',
        width: 30,
    },
    {
        label: getLabel(`${localisable.totalAmount} ${currency ? `(${currency})` : ''}`),
        key: 'amount',
        align: 'right',
        width: 15,
    },
    {
        label: getLabel(localisable.description),
        key: 'description',
        width: 25,
        headerClassName: classes.lastColumnList,
        className: classes.lastColumnList,
    },
];

export default getBillAheadColumns;
