import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { VARIANT, SNACKBAR_POSITION, AUTO_HIDE_DURATION } from '../config/Constants';
import SnackMessage from './SnackMessage';


// TODO for new-base-style: Incoming transition is too fast
const Snackbar = ({
    variant, title, subtitle, actions,
    onSnackbarMount, classes, rtnsWithAutoClose = false,
    ...props
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { current: isRealTimeNotification } = useRef(variant === VARIANT.rtns.value);
    // At present, we are not supporting the auto-closing feature for rtns as we also use it for error notifications.
    // For some rtns notification where we want auto-closing we will be passing duration of Warning variant.
    const getAutoHideDuration = () => {
        if (variant === VARIANT.rtns.value && rtnsWithAutoClose) {
            return AUTO_HIDE_DURATION[VARIANT.warning.value];
        }
        return AUTO_HIDE_DURATION[variant];
    };

    const snackbarKey = useRef({});

    useEffect(() => {
        enqueueSnackbar(title, {
            variant: isRealTimeNotification ? VARIANT.default.value : variant,
            preventDuplicate: true,
            autoHideDuration: getAutoHideDuration(),
            content: (key) => {
                snackbarKey.current = key;
                return (
                    <SnackMessage
                        subtitle={subtitle}
                        variant={variant}
                        id={key}
                        title={title}
                        isRealTimeNotification={isRealTimeNotification}
                        actions={actions}
                        closeSnackbar={closeSnackbar}
                        classes={classes}
                        onSnackbarMount={onSnackbarMount}
                    />
                );
            },
            ...(isRealTimeNotification && { anchorOrigin: SNACKBAR_POSITION.TOP_CENTER }),
            ...props,
        });
        return () => {
            const { current: key } = snackbarKey;
            if (variant === VARIANT.error.value) {
                closeSnackbar(key);
            }
        };
    }, []);

    return null;
};
export default Snackbar;
