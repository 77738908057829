import { clsx } from 'Commons/helpers/utils/clsx';
import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import { screenMaxWidth } from 'Commons/config/constants/ScreenWidths';
import Form from '../../form/components/Form';
import { Switch as MaterialUiSwitch, FormControlLabel, withStyles } from '../../componentlibrary/components/Components';
import switchStyle from '../styles/SwitchStyle';

class Switch extends React.PureComponent {
    constructor(props) {
        super(props);
        const { trackValue } = props;
        this.state = {};
        if (trackValue) {
            const { checked } = props;
            this.state = { checked };
        }
    }

    onChange = (event) => {
        const { onChange, trackValue } = this.props;
        const { target: { checked } } = event;
        if (trackValue) {
            this.setState({ checked });
        }
        onChange(event, checked);
    }

    renderSwitch = (props) => {
        const { checked: stateChecked } = this.state;
        const { trackValue, value, readOnly, classes } = this.props;
        const { innerWidth } = window;
        return (
            <MaterialUiSwitch
                onChange={this.onChange}
                checked={trackValue ? stateChecked : value}
                color={readOnly ? 'default' : 'primary'}
                classes={{ input: clsx(readOnly && classes.readOnly) }}
                disableRipple={readOnly}
                size={innerWidth <= screenMaxWidth.laptop ? 'small' : 'medium'}
                {...props}
            />
        );
    }

    render() {
        const {
            onChange, checked, trackValue, form, label, value,
            FormControlLabelProps, readOnly, classes, ...props
        } = this.props;
        return label ? (
            <FormControlLabel
                className={clsx(readOnly && classes.readOnly)}
                control={this.renderSwitch(props)}
                label={label}
                {...FormControlLabelProps}
            />
        )
            : this.renderSwitch(props);
    }
}


Switch.propTypes = {
    form: PropTypes.object,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    trackValue: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.bool,
    FormControlLabelProps: PropTypes.object,
    label: PropTypes.node,
    readOnly: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};

Switch.defaultProps = {
    form: { setFieldValue: () => { } },
    onChange: () => { },
    checked: false,
    trackValue: true,
    readOnly: false,
};

const SwitchComponent = withStyles(switchStyle)(Switch);
SwitchComponent.displayName = FORM_COMPONENTS.SWITCH;
const FormSwitch = Form(SwitchComponent);

export {
    SwitchComponent as Switch,
    FormSwitch,
};
