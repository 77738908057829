/* This piece of code has been taken form https://github.com/clauderic/react-sortable-hoc */

import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { provideDisplayName } from '../utils';

export default function sortableHandle(
    WrappedComponent,
    config = { withRef: false },
) {
    return class WithSortableHandle extends React.Component {
    static displayName = provideDisplayName('sortableHandle', WrappedComponent);

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        const node = findDOMNode(this);
        node.sortableHandle = true;
    }

    render() {
        const ref = config.withRef ? 'wrappedInstance' : null;

        return <WrappedComponent ref={ref} {...this.props} />;
    }
    };
}

export function isSortableHandle(node) {
    return node.sortableHandle != null;
}
