import Collapse from '@material-ui/core/Collapse';
import { Grid, List, ListItem, withStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import Link from 'Commons/components/generic/link/components/Link';
import CompanyLogo from 'Commons/components/generic/logo/components/CompanyLogo';
import { DASHBOARD_TYPE, FACILITY_LICENSE_STATUS } from 'Commons/config/constants/Constants';
import enums from 'Commons/config/constants/Enums';
import { isNonEmptyArray, isValidValue } from 'Commons/helpers/utils/DataHelpers';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import { isSyrasoftUser, isLiveData, isInternalDashboard } from 'Commons/helpers/utils/Utils';
import { clsx } from 'Commons/helpers/utils/clsx';
import Button from 'Generic/button/components/Button';
import listItemsStyle from '../styles/ListItemsStyle';
import doesUrlBelongsToAnyGivenPath from '../utils/Helper';
import {
    externalRouteEntityMapping,
    externalSettingsRouteEntityMapping,
    findMatchingParentEntityIndex,
} from '../utils/ListItemsUtil';
import Copyright from './Copyright';

class SideBarListItems extends React.Component {
    constructor(props) {
        super(props);
        const { dashboardType } = props;
        this.state = { currentParentEntityIndex: 0, parentEntitiesIndex: [] };
        this.updateConfig(dashboardType);
    }

    componentDidMount() {
        const { location: { pathname: locationUrl }, dashboardType } = this.props;
        if (dashboardType === DASHBOARD_TYPE.EXTERNAL) {
            this.setState({ parentEntitiesIndex: Object.keys(externalRouteEntityMapping).map(Number) });
        } else if (dashboardType === DASHBOARD_TYPE.SETTINGS) {
            this.setState({ parentEntitiesIndex: Object.keys(externalSettingsRouteEntityMapping).map(Number) });
        }
        if (dashboardType !== DASHBOARD_TYPE.INTERNAL) {
            const initialExpandedIndex = findMatchingParentEntityIndex(dashboardType, locationUrl);
            if (initialExpandedIndex !== null) {
                this.setState({ currentParentEntityIndex: initialExpandedIndex });
            }
        }
    }

    updateConfig = (dashboardType) => {
        if (dashboardType === DASHBOARD_TYPE.INTERNAL) {
            // eslint-disable-next-line global-require
            this.config = require('Internal/containers/dashboard/widgets/sidebar/configs/Config').default;
        } else if (dashboardType === DASHBOARD_TYPE.SETTINGS) {
            // eslint-disable-next-line global-require
            this.config = require('External/containers/dashboardSettings/widgets/sidebar/configs/Config').default;
        } else if (dashboardType === DASHBOARD_TYPE.EXTERNAL) {
            // eslint-disable-next-line global-require
            this.config = require('External/containers/dashboard/widgets/sidebar/configs/Config').default;
        }
    }

    getHomeUrlDetails = () => {
        const { defaultFacilityId, dashboardType, match: { params: { fid, accountId } = {} } } = this.props;
        const facilityId = fid || defaultFacilityId;
        return dashboardType === DASHBOARD_TYPE.EXTERNAL
            ? { url: buildUrl('facilitySelect', { fid }, DASHBOARD_TYPE.EXTERNAL, accountId) }
            : {
                url: buildUrl('facilitySelect', { fid: facilityId }, DASHBOARD_TYPE.EXTERNAL, accountId),
                target: '_blank',
            };
    }

    handleCompanyLogoOnClick = ({ url, target }) => {
        const { props: { history } = {}, state: { currentParentEntityIndex } } = this;
        if (currentParentEntityIndex !== null) {
            this.setState({ currentParentEntityIndex: 0 });
        }
        if (target) window.open(url, target);
        else history.push(url);
    }

    render() {
        const {
            classes, open, match: { url, params: { fid, accountId } = {} }, location: { pathname: locationUrl },
            dashboardType, permission = {}, history: { match: { params: { '*': currentUrl } } }, // '*' contains the matched part after base url
            currentFacility: {
                data: {
                    isPhoneEnabled = false, emailAddress: { isEnabled: isEmailEnabled = false } = {},
                    metaInfo: { legalAgreement: { isAccepted = false } = {} } = {},
                    licenseStatus,
                } = {},
            } = {},
        } = this.props;
        const { config, getHomeUrlDetails } = this;
        this.updateConfig(dashboardType);
        const homeUrlDetails = getHomeUrlDetails();
        const { currentParentEntityIndex, parentEntitiesIndex } = this.state;
        const configArgs = {
            currentUrl: locationUrl,
            matchUrl: url,
            fid,
            accountId,
            permission,
            homeUrlDetails,
            isAccepted,
            isConversationEnabled: isEmailEnabled || isPhoneEnabled || isSyrasoftUser(),
        };

        const renderSideNavItems = () => (
            <div>
                {
                    config(configArgs).map((element, idx) => {
                        const {
                            label, children, url: parentUrl, addon: { end, ...addon } = {}, target,
                            onClick, visible = true, workCenterPaths: parentWorkCenterPaths,
                        } = element;
                        if (open && addon) { addon.end = end; }
                        const isCurrentWCPath = currentUrl === parentUrl
                        || doesUrlBelongsToAnyGivenPath(locationUrl, parentWorkCenterPaths, url.replace(`/${currentUrl}`, ''));
                        const handleEntityExpandOrCollapse = (index) => {
                            if (index === currentParentEntityIndex) this.setState({ currentParentEntityIndex: null });
                            else this.setState({ currentParentEntityIndex: index });
                        };
                        const isInternalDashBoard = (dashboardType === DASHBOARD_TYPE.INTERNAL);
                        const getListItemClasses = () => {
                            const parentEntityIndex = !isInternalDashBoard
                                && findMatchingParentEntityIndex(dashboardType, locationUrl, url.replace(`/${currentUrl}`, ''));
                            const isChildEntitySelected = idx === parentEntityIndex;
                            const classNames = [];
                            if (!isInternalDashBoard) {
                                if (isValidValue(parentUrl)) {
                                    classNames.push(
                                        classes.childListItems,
                                        classes.labelWithIconMargin,
                                        classes.stickyItems,
                                        open && classes.childEntityPadding,
                                    );
                                } else {
                                    classNames.push(classes.entityRoot);
                                    if (!isChildEntitySelected) classNames.push(classes.parentEntityPadding);
                                    if (isChildEntitySelected) classNames.push(classes.selectedEntityIndicator);
                                }
                            } else if (isInternalDashBoard) classNames.push(classes.listItems);
                            return clsx(...classNames);
                        };
                        return visible && (
                            <div key={`${label}-${parentUrl}`}>
                                {(open || isValidValue(parentUrl)) && (
                                    <>
                                        <ListItem
                                            key={`${label}-${parentUrl}`}
                                            divider={isInternalDashBoard}
                                            classes={{
                                                root: isInternalDashBoard && classes.root,
                                                divider: isInternalDashBoard && classes.divider,
                                            }}
                                            className={getListItemClasses()}
                                            onClick={() => isNonEmptyArray(children)
                                                && handleEntityExpandOrCollapse(idx)}
                                            button
                                            disableGutters={!isInternalDashBoard}
                                        >
                                            {isNonEmptyArray(children)
                                                ? (
                                                    <LabelWithIcon
                                                        addon={{
                                                            end:
                                                                <Button
                                                                    variant="icon"
                                                                    iconType="custom"
                                                                    icon={idx === currentParentEntityIndex
                                                                        ? 'cp-expand' : 'cp-chevron-right'}
                                                                    IconProps={{
                                                                        size: 'large',
                                                                        className: classes.iconColorWhite,
                                                                    }}
                                                                />,
                                                        }}
                                                        classes={{
                                                            labelWithIcon: classes.labelWithIconSpacing,
                                                            label: classes.textEllipsis,
                                                        }}
                                                    >
                                                        {open && label.toUpperCase()}
                                                    </LabelWithIcon>
                                                )
                                                : (
                                                    <Link
                                                        className={clsx(classes.link,
                                                            !isInternalDashBoard && classes.childEntityItem)}
                                                        to={parentUrl}
                                                        addon={addon}
                                                        end
                                                        isActive={isInternalDashBoard ? (() => isCurrentWCPath) : undefined}
                                                        activeClassName={isInternalDashBoard
                                                            ? classes.activeLink : null}
                                                        target={target}
                                                        onClick={onClick}
                                                        classes={{ link: classes.link, reactlink: classes.reactlink }}
                                                        LabelWithIconProps={{
                                                            classes: {
                                                                labelWithIcon: clsx(classes.labelWithIcon,
                                                                    !open && classes.iconCenter),
                                                                label: classes.label,
                                                                addonEnd: classes.addonEnd,
                                                            },
                                                        }}
                                                    >
                                                        {open && label}
                                                    </Link>
                                                )}
                                        </ListItem>
                                    </>
                                )}
                                {isNonEmptyArray(children) && (
                                    <Collapse
                                        in={open
                                            ? idx === currentParentEntityIndex
                                            : parentEntitiesIndex.includes(idx)}
                                        timeout="auto"
                                    >
                                        <List component="div" className={classes.noPadding}>
                                            {isNonEmptyArray(children) && (
                                                children.map((item) => {
                                                    const {
                                                        childAddon: { end: childAddonEnd, ...childAddon },
                                                        url: elementUrl, label: childLabel, workCenterPaths = [], visible: isChildVisible = true,
                                                    } = item;
                                                    if (open) { childAddon.end = childAddonEnd; }
                                                    const isCurrentChildWCPath = currentUrl === elementUrl
                                                        || doesUrlBelongsToAnyGivenPath(locationUrl, workCenterPaths, url.replace(`/${currentUrl}`, ''));
                                                    return isChildVisible && (
                                                        <ListItem
                                                            key={`${childLabel}-${elementUrl}`}
                                                            button
                                                            classes={{
                                                                root: clsx(classes.entityRoot,
                                                                    open ? classes.childEntityPadding
                                                                        : classes.collapsePadding),
                                                                divider: classes.noDivider,
                                                            }}
                                                            className={classes.childListItems}
                                                            divider
                                                            disableGutters
                                                        >
                                                            <Link
                                                                className={clsx(classes.link, classes.childEntityItem)}
                                                                to={elementUrl}
                                                                addon={childAddon}
                                                                isActive={() => isCurrentChildWCPath}
                                                                activeClassName={classes.activeLink}
                                                                end
                                                                target={target}
                                                                onClick={onClick}
                                                                classes={{
                                                                    link: classes.link,
                                                                    reactlink: classes.reactlink,
                                                                }}
                                                                LabelWithIconProps={{
                                                                    classes: {
                                                                        labelWithIcon: !open && classes.iconCenter,
                                                                        label: clsx(
                                                                            classes.label, classes.textEllipsis,
                                                                        ),
                                                                        addonEnd: classes.addonEnd,
                                                                    },
                                                                }}
                                                            >
                                                                {open && childLabel}
                                                            </Link>
                                                        </ListItem>
                                                    );
                                                })
                                            )}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        );
                    })
                }
            </div>
        );

        return (
            <>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    className={`${classes.listItems} ${classes.companyLogo}
                    ${dashboardType === DASHBOARD_TYPE.INTERNAL && classes.borderBottom}`}
                >
                    <LabelWithIcon
                        addon={{
                            start:
                                !open && (
                                    <CompanyLogo
                                        mini
                                        height={24}
                                        width={24}
                                    />
                                ),
                        }}
                        classes={{
                            labelWithIcon: classes.labelWithIcon,
                            label: classes.label,
                            addonEnd: classes.addonEnd,
                        }}
                        onClick={() => this.handleCompanyLogoOnClick(homeUrlDetails)}
                    >
                        {
                            open && (
                                <CompanyLogo
                                    height="auto"
                                    width={140}
                                    white
                                />
                            )
                        }
                    </LabelWithIcon>
                </Grid>
                <List disablePadding className={classes.topSection}>
                    {licenseStatus === FACILITY_LICENSE_STATUS.Active.value || isInternalDashboard(url) || isLiveData(url) ? renderSideNavItems() : null}
                </List>

                <Copyright open={open} />
            </>
        );
    }
}

SideBarListItems.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    match: PropTypes.object,
    dashboardType: PropTypes.oneOf(enums.dashboardType),
    location: PropTypes.object,
    defaultFacilityId: PropTypes.string,
    permission: PropTypes.object,
    currentFacility: PropTypes.object,
    history: PropTypes.object,
};

export default withStyles(listItemsStyle)(SideBarListItems);
