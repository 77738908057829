import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { memo } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import labelWithAddonsStyle from '../styles/LabelWithAddonsStyle';

const useStyles = makeStyles(labelWithAddonsStyle, { name: 'LabelWithAddons' });

const LabelWithAddons = ({
    children, start, end, className, startClassName, endClassName, classes: classesProps,
    labelClassName, stopLabelInteraction, ...props
}) => {
    const classes = useStyles({ classes: classesProps });
    return (
        <Grid
            container
            alignItems="center"
            wrap="nowrap"
            className={clsx(classes.container, { [classes.clickable]: !stopLabelInteraction }, className)}
            {...props}
        >
            {start && <div className={clsx(startClassName)}>{start}</div>}
            {children
            && (
                <div
                    className={clsx(classes.label, { [classes.spacing]: start }, labelClassName)}
                >
                    {children}
                </div>
            )}
            {end && <div className={clsx({ [classes.spacing]: children }, endClassName)}>{end}</div>}
        </Grid>
    );
};

LabelWithAddons.propTypes = {
    end: PropTypes.node,
    start: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    endClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    stopLabelInteraction: PropTypes.bool,
    startClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
};

LabelWithAddons.defaultProps = { stopLabelInteraction: false };

export default memo(LabelWithAddons);
