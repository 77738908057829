const enums = {
    formComponents: ['Checkbox', 'Checkox_Group', 'Color_Picker', 'Date_Picker', 'Dropdown', 'Searchable_Dropdown',
        'Smart_Searchable_Dropdown', 'Radio', 'Radio_Group', 'Switch', 'Text_Field'],
    variant: ['Default', 'Inline', 'Last_Inline', 'First_Inline', 'No_Padding', 'No_Left_Right_Padding'],
    inputType: ['button', 'checkbox', 'color', 'date', 'datetime-local', 'email', 'file', 'hidden', 'image',
        'month', 'number', 'password', 'radio', 'range', 'reset', 'search', 'submit', 'tel', 'text', 'time', 'url',
        'week', 'integer'],
};

export default enums;
