import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import smartListActionItemsPanelStyle from 'Commons/components/business/smartlistActionItemsPanel/styles/SmartListActionItemsPanelStyle';
import localisable from 'Commons/config/strings/localisable';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import ActionItem from 'Commons/components/business/smartlistActionItemsPanel/components/ActionItem';
import { memo } from 'react';

const useStyles = makeStyles(smartListActionItemsPanelStyle, { name: 'SmartListActionItemsPanel' });

const SmartListActionItemsPanel = (props) => {
    const {
        showBackToDashBoardActionItem = false,
        onBackToDashboardClick = EMPTY_FUNC,
        actionItemsConfig = [],
        containerProps,
        actionItemProps,
        backToDashboardActionItemProps,
    } = props;
    const classes = useStyles();
    return (
        <Grid container justify="flex-end" className={classes.actionItemsContainer} {...containerProps}>
            {!!actionItemsConfig.length && actionItemsConfig.map((config) => {
                const {
                    key,
                    isVisible = true,
                    ...remainingConfig
                } = config;
                return isVisible
                    ? (
                        <Grid key={key} item className={classes.actionItem} {...actionItemProps}>
                            <ActionItem
                                config={remainingConfig}
                            />
                        </Grid>
                    ) : null;
            })
            }
            {showBackToDashBoardActionItem && (
                <Grid item className={classes.backToDashboardActionItem} {...backToDashboardActionItemProps}>
                    <ActionItem
                        config={{
                            label: localisable.backToDashboard,
                            icon: 'cp-chevron-left',
                            onClick: onBackToDashboardClick,
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

SmartListActionItemsPanel.propTypes = {
    showBackToDashBoardActionItem: PropTypes.bool,
    actionItemsConfig: PropTypes.array,
    onBackToDashboardClick: PropTypes.func,
    containerProps: PropTypes.object,
    actionItemProps: PropTypes.object,
    backToDashboardActionItemProps: PropTypes.object,
};

SmartListActionItemsPanel.defaultProps = {
    showBackToDashBoardActionItem: false,
    actionItemsConfig: [],
    onBackToDashboardClick: () => {},
};

export default memo(SmartListActionItemsPanel);
