import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { STATUS } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import InventoryListFilterStyle from '../styles/InventoryListFilterStyle';

const useStyles = makeStyles(InventoryListFilterStyle, { name: 'InventoryListFilterStyle' });


const InventoryListFilter = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={[STATUS.Active, STATUS.Inactive]}
                row
                noGrid
                noIndicatorWrapper
            />
        </>
    );
};
export default memo(InventoryListFilter);
