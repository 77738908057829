/* eslint-disable prefer-promise-reject-errors */
import COOKIE_KEY from 'Commons/redux/config/CookieKey';
import WebSocketInstance from 'Commons/components/business/socket/WebSocket';
import { ONE_MINUTE, TOKEN_TYPE } from 'Commons/config/constants/Constants';
import { isPulledData } from 'Commons/helpers/utils/Utils';
import { OPTIONS, REPORT_OPTIONS, HTTP_HEADER_SOURCE } from './Config';
import { getCookie } from '../../redux/helper/CookieManager';
import {
    deepCamelize,
    deepDecamelize,
} from './Formatter';
import { getApiBuildVersion } from '../utils/ApiBuildVersionManager';
import { isBaseUrlAlpha } from '../utils/RouteHelpers';
import fetchWithTimeout from './FetchWithTimeout';
// to be updated wih the api resource version

// const apiVersion = process.env.API_VERSION;
/**
 * @description Construct the query string from the params passed
 * @param {Object} queryParams Key Value pair of query params
 */
const getQueryString = (queryParams) => {
    let queryString = '';
    Object.entries(queryParams).forEach(([key, value]) => {
        queryString = `${queryString}${key}=${value}&`;
    });
    return queryString.slice(0, -1);
};

/**
 * @description Appends the base url to the request end point and optionally the version
 * @param {*} path
 * @param {*} queryParams
 */
const buildUrl = (path, queryParams = {}, baseUrl = process.env.API_URL) => {
    let url = isBaseUrlAlpha() && ![process.env.REPORT_URL, process.env.TX_SERVER_URL].includes(baseUrl) ? process.env.ALPHA_API_URL : baseUrl;
    url = url.replace(':apiVersion', process.env.API_VERSION);
    url = `${url.endsWith('/') ? (`${url}${path}`) : (`${url}/${path}`)}`;
    if (Object.keys(queryParams).length > 0) {
        url = `${url}?${getQueryString(queryParams)}`;
    }
    return url;
};
const isUserAuthorized = (tokenType = TOKEN_TYPE.STANDARD) => {
    let paymentToken;
    const token = getCookie(COOKIE_KEY.TOKEN);
    if (tokenType === TOKEN_TYPE.PAYMENT) {
        paymentToken = getCookie(COOKIE_KEY.PAYMENT_TOKEN);
    }
    if (token) {
        if (paymentToken) {
            return { status: true, paymentToken, token };
        }
        return { status: true, token };
    }
    return {
        status: false,
        token: null,
    };
};


/**
 * @description Generates the headers and combines with any headers passed as dependency config
 * Also if user authorized will append the token
 * @param {Object} headers Headers passed from Dependency Config
 */
const getHeaders = (headers = {}, isFormData = false, tokenType = TOKEN_TYPE.STANDARD) => {
    const httpHeaders = new Headers();
    const { location: { pathname } } = window;
    const socketId = WebSocketInstance.getSocketId();
    if (Object.keys(headers).length > 0) {
        Object.keys(headers).forEach((key) => {
            httpHeaders.append(key, headers[key]);
        });
    }
    const { status, token, paymentToken } = isUserAuthorized(tokenType);
    if (status && !('Authorization' in headers)) {
        httpHeaders.append('Authorization', `Token ${token}`);
        if (tokenType === TOKEN_TYPE.PAYMENT) {
            httpHeaders.append('Payment-Token', paymentToken);
        }
    }
    if (isPulledData(pathname)) httpHeaders.append('Source', HTTP_HEADER_SOURCE.SANDBOX);
    if (socketId) {
        httpHeaders.append('Socket-Id', socketId);
    }
    // Append Auth & Application wide headers here if required
    if (!isFormData) {
        httpHeaders.append('Content-Type', 'application/json');
    }
    const apiBuildVersion = getApiBuildVersion();
    if (apiBuildVersion) {
        httpHeaders.append('Build-Version', apiBuildVersion);
    }
    return httpHeaders;
};

const getBody = (isFormData, body, decamalize) => {
    if (isFormData) {
        return body;
    }
    if (Object.keys(body).length > 0) {
        if (decamalize) {
            return JSON.stringify(deepDecamelize(body));
        }
        return JSON.stringify(body);
    }
    return undefined;
};

/**
 * @description Get the header options based on authorized or no
 * @param {Object} options Fetch API Options from Dependency Config
 */
const getOptions = ({
    methodType: method = 'GET', headers = {}, body = {},
    tokenType = TOKEN_TYPE.STANDARD, decamalize = true,
}) => {
    const isFormData = body instanceof FormData;
    const requestOptions = {
        method,
        headers: getHeaders(headers, isFormData, tokenType) || {},
        body: getBody(isFormData, body, decamalize),
        mode: 'cors',
    };
    const { status } = isUserAuthorized();
    if (status && !('Authorization' in headers)) {
        return {
            credentials: 'include',
            ...requestOptions,
        };
    }
    return requestOptions;
};

/**
 * @description Parses the API response and filters the errors and response.
 * Categorizes into Network/Server/Application error/Invalid JSON(If we get html)
 * @param {*} response HTTP Response
 */
const parseAPIResponse = response => new Promise(resolve => resolve(response.text()))
    .catch(err => Promise.reject({
        type: 'NetworkError',
        status: response.status,
        message: err,
    }))
    .then((responseBody) => {
        // Attempt to parse JSON
        try {
            const parsedJSON = deepCamelize(JSON.parse(responseBody));
            if (response.ok) return parsedJSON;
            if (response.status >= 500) {
                return Promise.reject({
                    type: 'ServerError',
                    status: response.status,
                    body: parsedJSON,
                });
            }
            if (response.status <= 501) {
                return Promise.reject({
                    type: 'ApplicationError',
                    status: response.status,
                    body: parsedJSON,
                });
            }
        } catch (e) {
            // We should never get these unless response is mangled
            // Or API is not properly implemented
            return Promise.reject({
                type: 'InvalidJSON',
                status: response.status,
                body: responseBody,
            });
        }
        return Promise.resolve({
            type: 'Invalid',
            status: '',
            body: {},
        });
    });

/**
 * @description Make An API Call
 * @param {*} options Fetch Options along with retry
 */
const makeApiCall = async (options = {}) => {
    const { baseUrl } = options;
    let defaultOptions = OPTIONS;
    if (baseUrl === process.env.REPORT_URL) {
        defaultOptions = REPORT_OPTIONS;
    }
    const { RETRIES, RETRY_DELAY, RETRY_ON } = defaultOptions;
    const { retries = RETRIES, retryDelay = RETRY_DELAY, retryOn = RETRY_ON, timeout = ONE_MINUTE } = options;
    return new Promise(((resolve, reject) => {
        const wrappedFetch = function wrappedFetch(n) {
            /**
             * @description No Of times to retry at a set delay
             * @param {*} n No Of Retries
             */
            function retry(noOfRetries) {
                setTimeout(() => {
                    wrappedFetch(noOfRetries - 1);
                }, retryDelay);
            }

            const url = buildUrl(options.endPoint, options.queryParams, baseUrl);
            fetchWithTimeout(url, getOptions(options), timeout)
                .then(parseAPIResponse)
                .then((response) => {
                    if (retryOn.indexOf(response.status) === -1) {
                        resolve(response);
                    } else if (n > 1 && retryOn.indexOf(response.status) > -1) {
                        retry(n);
                    } else {
                        reject(response);
                    }
                })
                .catch((error) => {
                    const { status, code } = error;
                    if (n > 1 && retryOn.indexOf(status || code) > -1) {
                        retry(n);
                    } else {
                        reject(error);
                    }
                });
        };
        wrappedFetch(retries);
    }));
};

export default makeApiCall;
