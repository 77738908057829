import { FormControlLabel, Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { FormDropdown } from 'Commons/components/generic/dropdown/components/Dropdown';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormTextField } from 'Generic/textfield/components/TextField';
import Button from 'Commons/components/generic/button/components/Button';
import Icon from 'Generic/icon/components/Icon';
import { DeliveryMethodDropdown } from 'Generic/deliveryMethodDropdown/components/DeliveryMethodDropdown';
import { getCurrentDate } from 'Commons/helpers/utils/DateTime';
import Typography from 'Generic/typography/components/Typography';
import TenantAlertDialog from 'Commons/components/business/tenantAlertDialog/components/TenantAlertDialog';
import { EMPTY_FUNC, SOURCE, STATUS } from 'Commons/config/constants/Constants';
import { Checkbox, FormCheckbox } from 'Generic/checkbox/components/CheckBox';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { clsx } from 'Commons/helpers/utils/clsx';
import { FormRadioGroup } from 'Generic/radiogroup/components/RadioGroup';
import { XS_12_MD_5, XS_12_MD_7 } from 'Commons/config/constants/GridSpacingConstants';
import SecondsCountdownTimer from 'Generic/secondscountdowntimer/SecondsCountdownTimer';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import { screenMaxWidth } from 'Commons/config/constants/ScreenWidths';
import { SMALL_DROPDOWN_MAX_HEIGHT } from 'External/containers/configuration/config/Constants';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import { isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import { round } from 'Commons/helpers/utils';
import { WALK_IN_CUSTOMER_ID } from 'External/containers/pointOfSale/config/Constants';
import { PROCESSOR_TYPE } from 'External/containers/facilityConfiguration/config/Constants';
import HostedFormContext from 'Commons/contexts/HostedFormContext';
import { DROPDOWN_PRINTER_SELECTION_TYPES } from 'Commons/components/generic/deliveryMethodDropdown/config/Constants';
import PaymentTypeStyle from '../styles/PaymentTypeStyle';
import { calculateNextAmountPayable } from '../utils/BalanceCalculator';
import { PAYMENT_ACTION, PAYMENT_METHOD } from '../config/Constants';
import CreditCard from './CreditCard';
import localisable from '../../../../config/strings/localisable';
import ACH from './ACH';
import Check from './Check';
import MoneyOrder from './MoneyOrder';
import { setFormValuesForUseCardOnFile } from '../utils/DataHandler';
import SyrapayHostedForm from './SyrapayHostedForm';
import shouldModifyNegativeAmount from '../utils/CheckAmount';

const useStyles = makeStyles(PaymentTypeStyle);

const PaymentType = ({
    formProps: {
        values: {
            ledgerId: ledger, amount, cardProcessorId, cardInfo: { autoPayEnabled, saveCardForFutureTransactions } = {},
            extra: { tenantAlerts = {}, ledgerBalance = null, payUsing, unitList = [] } = {},
            doNotProcessCard = false, receiptDeliveryMethod = [], paymentNumber, useAchOnFile, achInfo = {},
            achInfo: { autoPayEnabled: autoPayEnabledForAch } = {},
        } = {},
        status,
        setStatus,
        setFieldValue,
        setFieldTouched,
        touched = {},
        setTouched,
        status: {
            selectedPaymentMethod: { id: paymentMethodId, value: { type } = {} } = {},
            ledgerInfo: {
                id: selectedLedgerId,
                balance = 0,
                financials: {
                    cardInfo = {}, cardInfo: { token = '' } = {},
                    preferredPaymentMethodId, achPpd: { displayAccountNumber } = {}, isAutoPayEnabled,
                } = {},
            } = {},
            tenantInfo: { id: tenantId = '' } = {},
            tenantInfo = {},
            preferredMethod,
            amountWithoutModifier,
            onAlertYes = false,
        } = {},
    } = {}, formProps,
    payment: {
        requireComment,
        refuseCheckFromFlaggedTenants: refuseCheck = false,
        defaultCardProcessor,
        documentation: { Receipt: { generate: shouldGenerateReceipt = false } = {} } = {},
    } = {},
    paymentMethods, getCurrentStatus,
    currentFacility: { data: { id: fid } = {} } = {},
    reFetchTenantBalance, cardProcessorData, source,
    parentSize, isRefund, timeOutMessage, isButtonLoading, onSubmitHostedForm,
    setPrefilledFalse, onSubmit, getFuncToOpenPaymentMethod, isAmountWithoutModifierTouched, showAlerts,
}) => {
    const classes = useStyles(parentSize && parentSize.current ? parentSize.current.getBoundingClientRect() : {});
    const isFlagged = !!(tenantAlerts.paymentRelatedAlert && tenantAlerts.paymentRelatedAlert[type]);
    const previousPaymentId = useRef();
    const paymentMethodDropdownRef = useRef(null);
    const [refundPeriodExpiredDisabled, setRefundPeriodExpiredDisabled] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [paymentMethodsList, setPaymentMethodsList] = useState([]);
    const [paymentMethodsObject, setPaymentMethods] = useState({});
    const [generalAlertShown, setGeneralAlertShown] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [shouldUseTenantAddress, makeUseOfTenantAddress] = useState(false);
    const { authenticationKey, disableFormSubmit, closeHostedForm } = useContext(HostedFormContext);
    let balanceUpdated = reFetchTenantBalance
        && ledgerBalance !== null
        && !isAmountWithoutModifierTouched
        ? ledgerBalance
        : balance;
    let amountWithoutModifierUpdated = reFetchTenantBalance
        && ledgerBalance !== null
        && !isAmountWithoutModifierTouched
        ? ledgerBalance
        : amountWithoutModifier;
    if (isRefund) {
        balanceUpdated = balanceUpdated < 0 ? -balanceUpdated : 0;
        amountWithoutModifierUpdated = amountWithoutModifierUpdated < 0 ? -amountWithoutModifierUpdated : amountWithoutModifierUpdated;
    } else if (amountWithoutModifierUpdated <= 0 && !isAmountWithoutModifierTouched) {
        amountWithoutModifierUpdated = (source === SOURCE.retailSale.value)
            ? 0 : calculateNextAmountPayable(unitList, selectedLedgerId);
    }
    const { innerWidth, deviceInfo: { isMobile } = {} } = window;
    const { formControlClasses, deliveryMethodContainerProps } = useConstructor(() => ({
        formControlClasses: {
            classes: {
                label: classes.smallFont,
                root: classes.noRightMargin,
            },
        },
        deliveryMethodContainerProps: { xs: 12, md: 12, lg: 12, className: classes.receiptDropDownContainer },
    }));
    const initialCardInfo = useMemo(() => cardInfo, [token]);
    useEffect(() => {
        setFieldValue('extra.cardInfo', initialCardInfo);
    }, [token]);
    const shouldEnterCardDetails = useMemo(() => payUsing === localisable.enterCardDetails,
        [payUsing]);

    const shouldShowCardComponent = useMemo(() => {
        const { [cardProcessorId]: { data: { processorType, refundPeriodExpired = false } = {} } = {} } = cardProcessorData;
        if (isRefund) {
            setFieldValue('doNotProcessCard', refundPeriodExpired);
            setFieldTouched('doNotProcessCard', true);
            setRefundPeriodExpiredDisabled(refundPeriodExpired);
        }
        if (processorType === PROCESSOR_TYPE.Syrapay.value) return payUsing !== localisable.enterCardDetails;
        return true;
    }, [payUsing, cardProcessorId]);

    const convertPaymentMethodsListToMap = methods => methods.reduce((obj, item) => ({
        ...obj,
        [item.id]: item,
    }), {});

    const doesPaymentMethodUseCard = useMemo(() => type === PAYMENT_METHOD.CREDIT_CARD
        || type === PAYMENT_METHOD.DEBIT_CARD,
    [type]);

    const doesPaymentMethodSavesInfo = useMemo(() => type === PAYMENT_METHOD.CREDIT_CARD
        || type === PAYMENT_METHOD.DEBIT_CARD || type === PAYMENT_METHOD.ACH,
    [type]);

    const doesPaymentMethodUsePaymentNumber = useMemo(() => type === PAYMENT_METHOD.CHECK
        || type === PAYMENT_METHOD.MONEY_ORDER, [type]);

    const { receiptGridProps, shouldShowPrintCopies, payTypeAndCardProcessorContainer } = useMemo(() => {
        if (receiptDeliveryMethod && receiptDeliveryMethod.includes('Print')) {
            // return {
            //     receiptGridProps: { xs: doesPaymentMethodUseCard ? 12 : 7 },
            //     payTypeAndCardProcessorContainer: { xs: doesPaymentMethodUseCard ? 12 : 5 },
            //     shouldShowPrintCopies: true,
            // };
        }
        return {
            receiptGridProps: {
                xs: doesPaymentMethodUseCard ? 12 : 6,
                md: doesPaymentMethodUseCard ? 4 : 6,
                xl: doesPaymentMethodUseCard && !source ? 4 : 5,
            },
            payTypeAndCardProcessorContainer: {
                xs: 12,
                // eslint-disable-next-line no-nested-ternary
                lg: 12,
            },
            shouldShowPrintCopies: false,
        };
    }, [receiptDeliveryMethod, doesPaymentMethodUseCard, paymentMethodId]);

    const isInEditMode = useMemo(() => payUsing === localisable.enterCardDetails,
        [payUsing]);

    const payUsingOptions = useMemo(() => {
        const { [cardProcessorId]: { data: { lanes: [laneId] = [] } = {} } = {} } = cardProcessorData;
        const payOptions = [{ label: localisable.enterCardDetails, value: localisable.enterCardDetails }];
        if (laneId) {
            payOptions.unshift({ label: localisable.swipeCard, value: localisable.swipeCard });
        }
        if (ledger && token && preferredPaymentMethodId === paymentMethodId) {
            payOptions.unshift({ label: localisable.useCardOnFile, value: localisable.useCardOnFile });
        }
        return payOptions;
    }, [ledger, cardProcessorId, paymentMethodId, token]);

    const setTodaysDate = () => {
        setFieldValue('effectiveDate', getCurrentDate());
    };

    useEffect(() => {
        const methodListWithLabelValue = paymentMethods.reduce((acc, {
            id, value: {
                description,
                status: paymentMethodStatus = STATUS.Active.value,
            } = {},
        }) => {
            if (paymentMethodStatus === STATUS.Active.value) {
                return [...acc, { label: description, value: id }];
            }
            return acc;
        }, []) || [];
        const mappedPaymentMethods = convertPaymentMethodsListToMap(paymentMethods);
        const selectedMethod = paymentMethods.find(({ id }) => `${id}` === `${preferredMethod || preferredPaymentMethodId}`) || paymentMethods[0] || {};

        let updatedStatus = { ...status };
        if (!mounted) updatedStatus = { ...getCurrentStatus(), ...status };
        setStatus({ ...updatedStatus, selectedPaymentMethod: selectedMethod });
        setPaymentMethods(mappedPaymentMethods);
        setPaymentMethodsList(methodListWithLabelValue);
        if (tenantId) {
            setFieldValue('paymentMethodId', selectedMethod.id);
            if (!shouldModifyNegativeAmount(isRefund, unitList, setFieldValue, selectedLedgerId, source, ledgerBalance || balance)) {
                setFieldValue('extra.amountWithoutModifier', round(balanceUpdated, 2, true));
            }
        }
    }, [tenantId, preferredMethod, JSON.stringify(paymentMethods)]);

    useEffect(() => {
        const date = getCurrentDate();
        const alertArray = [
            ...(tenantAlerts.general ? tenantAlerts.general : []),
            ...(isFlagged ? tenantAlerts.paymentRelatedAlert[type] : []),
        ];
        const processedAlerts = [];
        alertArray.forEach(({ comments, severity, fromDate = date, toDate = date, id, visibleAt }) => {
            if ((fromDate <= date) && (!toDate || toDate >= date) && (visibleAt.includes('Payment') || visibleAt.includes('All'))) {
                processedAlerts.push({ comments, severity, id });
            }
        });
        setAlerts(processedAlerts);
    }, [JSON.stringify(tenantAlerts), paymentMethodId]);

    useEffect(() => {
        const cardProcessorList = Object.values(cardProcessorData);
        let cardProcessorIdToSet = cardProcessorId;
        if (doesPaymentMethodUseCard && cardProcessorList.length) {
            if (!cardProcessorIdToSet) {
                const [{ id: processorId } = {}] = cardProcessorList;
                cardProcessorIdToSet = defaultCardProcessor || processorId;
                setFieldValue('cardProcessorId', cardProcessorIdToSet);
            }
        } else {
            cardProcessorIdToSet = undefined;
            setFieldValue('cardProcessorId', undefined);
            setFieldValue('cardData', undefined);
        }
        if (doesPaymentMethodUseCard && cardProcessorIdToSet) {
            setTodaysDate();
            if (token) {
                setFormValuesForUseCardOnFile(setFieldValue, isAutoPayEnabled);
            } else {
                setFieldValue('cardInfo', undefined);
                setFieldValue('extra.payUsing', localisable.enterCardDetails);
            }
        }
        if (!doesPaymentMethodUsePaymentNumber && paymentNumber) {
            setFieldValue('paymentNumber', undefined);
        }
        if (type !== PAYMENT_METHOD.ACH) {
            if (useAchOnFile) {
                setFieldValue('useAchOnFile', undefined);
            }
            if (Object.keys(achInfo).length) {
                setFieldValue('achInfo', undefined);
            }
        }
    }, [type]);

    const onSelectPayType = (_, selectedPaymentMethodId, onAlertYesArgument = false) => {
        previousPaymentId.current = paymentMethodId;
        if (selectedPaymentMethodId !== paymentMethodId) {
            const selectedPaymentMethod = paymentMethodsObject[selectedPaymentMethodId] || {};
            setStatus({ ...status, selectedPaymentMethod, onAlertYes: onAlertYesArgument });
            setFieldValue('extra.amountWithoutModifier', round(amountWithoutModifierUpdated || amount, 2, true));
            if (preferredPaymentMethodId === selectedPaymentMethodId && isObjWithKeys(initialCardInfo)) {
                setFieldValue('extra.cardInfo', initialCardInfo);
                setFormValuesForUseCardOnFile(setFieldValue, isAutoPayEnabled);
            } else {
                setFieldValue('cardInfo', {});
                setFieldValue('extra.cardInfo', {});
                setTouched({ ...touched, cardInfo: { cvvOrPin: false, billingInfo: { month: false, year: false } } });
                if (payUsing === localisable.useCardOnFile) {
                    setFieldValue('cardInfo', undefined);
                    setFieldValue('extra.payUsing', localisable.enterCardDetails);
                }
            }
            setPrefilledFalse();
        }
    };

    const onChangePayUsing = (_, payUsingValue, changeAutoPayValue = true) => {
        if (payUsingValue === localisable.useCardOnFile) {
            setFieldValue('useCardOnFile', true, false);
            setFieldValue('cardInfo', {
                saveCardForFutureTransactions: true,
                autoPayEnabled: isAutoPayEnabled && changeAutoPayValue,
            }, false);
        } else {
            setFieldValue('useCardOnFile', undefined, false);
            let saveCard = true;
            if (changeAutoPayValue) {
                saveCard = undefined;
                setFieldValue('cardInfo.autoPayEnabled', undefined, false);
            }
            setFieldValue('cardInfo.saveCardForFutureTransactions', saveCard, false);
        }
    };

    const onChangeUseAchOnFile = (payUsingAchOnFile, changeAutoPayValue = true) => {
        if (payUsingAchOnFile) {
            setFieldValue('extra.payUsing', localisable.useAchOnFile, false);
            setFieldValue('useAchOnFile', true, false);
            setFieldValue('achInfo', {
                saveAchForFutureTransactions: true,
                autoPayEnabled: isAutoPayEnabled && changeAutoPayValue,
            }, false);
        } else {
            setFieldValue('useAchOnFile', undefined, false);
            let saveAch = true;
            if (changeAutoPayValue) {
                saveAch = undefined;
                setFieldValue('achInfo.autoPayEnabled', undefined, false);
            }
            setFieldValue('achInfo.saveAchForFutureTransactions', saveAch, false);
        }
    };

    const onEnableOrDisableAutoPay = (autoPayValue, baseFieldName) => {
        if (autoPayValue) {
            setFieldValue('extra.payUsing', localisable.enterCardDetails, false);
            if (baseFieldName === 'cardInfo') {
                onChangePayUsing('', localisable.enterCardDetails, false);
            } else {
                onChangeUseAchOnFile(false, false);
            }
        }
    };

    const onEnableOrDisableCardProcessing = (_, enableProcessing) => {
        if (!enableProcessing) {
            setTodaysDate();
        }
    };

    const renderPayTypeDropDown = () => (
        <Grid
            container
            direction="column"
            alignItems="center"
            wrap="nowrap"
            item
            xs={6}
            md={doesPaymentMethodUseCard ? 4 : 6}
        >
            <FormDropdown
                name="paymentMethodId"
                placeholder="Pay Type"
                list={paymentMethodsList}
                noGrid
                noIndicatorWrapper
                onChange={onSelectPayType}
                disabled={!ledger}
                key={`${ledger} - ${type}`}
                FormControlProps={{ fullWidth: false }}
                label={localisable.paymentMethod}
                InputProps={{
                    className: `${classes.noBorderBottom}  ${classes.noBorderBottomDisabled}`,
                    classes: { root: classes.dropDown, disabled: classes.disabled },
                }}
                onRef={(ref) => { paymentMethodDropdownRef.current = ref; }}
                maxHeight={SMALL_DROPDOWN_MAX_HEIGHT}
            />
            {
                doesPaymentMethodUseCard
                && (
                    <FormCheckbox
                        name="doNotProcessCard"
                        noGrid
                        trackValue
                        label={localisable.doNotProcessCard}
                        size="small"
                        key={`${ledger} - ${localisable.doNotProcessCard} - ${doNotProcessCard}`}
                        FormControlProps={{ fullWidth: false }}
                        FormControlLabelProps={formControlClasses}
                        noIndicatorWrapper
                        disabled={!ledger || refundPeriodExpiredDisabled}
                        onChange={onEnableOrDisableCardProcessing}
                    />
                )
            }
        </Grid>
    );

    const renderReceiptDropDown = () => (
        <Grid
            container
            alignItems={isMobile ? 'flex-start' : 'center'}
            justify="flex-end"
            wrap="nowrap"
            item
            xs={doesPaymentMethodUseCard ? 12 : 6}
            md={doesPaymentMethodUseCard ? 4 : 6}
            className={clsx(
                {
                    [classes.receiptDropDown]: shouldShowPrintCopies || source || (doesPaymentMethodUseCard
                        && (isMobile || (innerWidth > screenMaxWidth.tablet && innerWidth <= screenMaxWidth.laptop))),
                    [classes.cardProcessorBottomMargin]: !shouldShowPrintCopies && !source && (doesPaymentMethodUseCard
                        && !(isMobile || (innerWidth > screenMaxWidth.tablet && innerWidth <= screenMaxWidth.laptop))),
                },
                shouldShowPrintCopies && doesPaymentMethodUseCard && classes.receiptTopMargin,
            )}
            {...receiptGridProps}
        >
            <DeliveryMethodDropdown
                useForm
                key={tenantId}
                name="receiptDeliveryMethod"
                fid={fid}
                noGrid
                label={localisable.receiptDeliverBy}
                placeholder={localisable.selectDeliveryMethod}
                FormControlProps={{ fullWidth: false }}
                formVariant={VARIANT.INLINE}
                showPrintCopies={false}
                containerClassName={classes.autoWidth}
                recipientEmailEnabled={selectedLedgerId !== WALK_IN_CUSTOMER_ID}
                recipientTextEnabled={selectedLedgerId !== WALK_IN_CUSTOMER_ID}
                noIndicatorWrapper
                containerProps={deliveryMethodContainerProps}
                printOrPreviewProps={{ initialValue: receiptDeliveryMethod, useForm: true }}
                printerSelectionProps={{
                    show: true,
                    containerProps: { desktop: { xs: 12 } },
                    type: DROPDOWN_PRINTER_SELECTION_TYPES.Popover.value,
                }}
            />
        </Grid>
    );

    const renderCardProcessorDropDown = () => (
        <Grid
            container
            alignItems="center"
            wrap="nowrap"
            item
            xs={5}
            md={4}
            className={clsx({ [classes.cardProcessorBottomMargin]: doesPaymentMethodUseCard })}
        >
            <FormDropdown
                name="cardProcessorId"
                list={Object.values(cardProcessorData)}
                noGrid
                required={!doNotProcessCard}
                noIndicatorWrapper
                key={`${type} - ${ledger} - ${doNotProcessCard}`}
                disabled={!ledger}
                label={localisable.cardProcessor}
                FormControlProps={{ fullWidth: false }}
                maxHeight={SMALL_DROPDOWN_MAX_HEIGHT}
                containerClassName={classes.autoWidth}
            />
        </Grid>
    );

    const renderCommentsTextField = () => (
        <FormTextField
            name="comments"
            placeholder={localisable.paymentComment}
            disabled={!ledger}
            key={ledger}
            required={requireComment}
            fullWidth
            noGrid
            noIndicatorWrapper={!requireComment}
            onChange={() => setPrefilledFalse()}
        />
    );

    const renderPayUsing = () => (
        <FormRadioGroup
            name="extra.payUsing"
            className={clsx(classes.shortTopMargin, !isMobile && classes.radioGroup)}
            list={payUsingOptions}
            key={`${localisable.payUsing} - ${autoPayEnabled} - ${payUsingOptions.length} - ${ledger} - 
                ${paymentMethodId} - ${cardProcessorId}`}
            noGrid
            noIndicatorWrapper
            disabled={!ledger}
            onChange={onChangePayUsing}
            dataType="string"
        />
    );

    const renderSaveCardCheckboxes = baseFieldName => (
        <>
            <FormCheckbox
                name={`${baseFieldName}.${baseFieldName === 'achInfo'
                    ? 'saveAchForFutureTransactions'
                    : 'saveCardForFutureTransactions'}`}
                GridProps={XS_12_MD_7}
                trackValue
                key={`${payUsing}- ${localisable.saveThisForFuturePayments} - ${ledger}-${autoPayEnabled}=${autoPayEnabledForAch}`}
                label={localisable.saveThisForFuturePayments}
                FormControlProps={{ fullWidth: false }}
                noIndicatorWrapper
                disabled={!ledger || [localisable.useCardOnFile, localisable.useAchOnFile].includes(payUsing)
                    || ledger === WALK_IN_CUSTOMER_ID || autoPayEnabled || autoPayEnabledForAch}
                formVariant={VARIANT.NO_PADDING}
            />
            <FormCheckbox
                name={`${baseFieldName}.autoPayEnabled`}
                GridProps={XS_12_MD_5}
                trackValue
                key={`${payUsing}- ${localisable.signUpForAutoPay} - ${ledger}`}
                label={localisable.signUpForAutoPay}
                FormControlProps={{ fullWidth: false }}
                noIndicatorWrapper
                disabled={!ledger || ledger === WALK_IN_CUSTOMER_ID}
                formVariant={VARIANT.NO_PADDING}
                onChange={(_, autoPayValue) => onEnableOrDisableAutoPay(autoPayValue, baseFieldName)}

            />
        </>
    );

    const renderPaymentProcessingDetails = () => (
        <Grid container direction="column" alignItems="center">
            <Icon
                type="custom"
                icon="cp-pending"
                className={classes.infoIcon}
            />
            <Typography variant="h6" className={classes.shortTopMargin}>
                {isRefund ? localisable.refundInProgress : localisable.paymentInProgress}
            </Typography>
            <Typography variant="body2" className={classes.shortTopMargin}>
                {isRefund ? localisable.waitingForRefundConfirmation
                    : localisable.waitingForPaymentConfirmation}
            </Typography>
            {
                timeOutMessage
                && (
                    <Grid container direction="column" alignItems="center">
                        <Typography variant="body2" align="center" className={classes.defaultTopMargin}>
                            {timeOutMessage}
                        </Typography>
                        <Grid
                            container
                            item
                            xs={12}
                            lg={8}
                            direction="row"
                            justify="space-between"
                            className={classes.shortTopMargin}
                        >
                            <Button
                                className={classes.errorButton}
                                onClick={() => onSubmit('', {}, PAYMENT_ACTION.CANCEL_TRANSACTION)}
                            >
                                {isRefund ? localisable.cancelRefund : localisable.cancelPayment}
                            </Button>
                            <Button
                                className={classes.waitButton}
                                onClick={() => onSubmit('', {}, PAYMENT_ACTION.GET_TRANSACTION_STATUS)}
                            >
                                {localisable.noWait}
                            </Button>
                        </Grid>
                    </Grid>
                )
            }
            {
                !timeOutMessage && payUsing !== localisable.swipeCard
                && <SecondsCountdownTimer className={classes.shortTopMargin} maxSeconds={31} />
            }
        </Grid>
    );

    const openPaymentMethodDropdown = () => {
        const { current: paymentMethodDropdown } = paymentMethodDropdownRef;
        paymentMethodDropdown.setState({ open: true });
    };

    useEffect(() => {
        getFuncToOpenPaymentMethod(openPaymentMethodDropdown);
        setMounted(true);
    }, []);

    const renderPaymentMethod = () => {
        if ((isButtonLoading && type === PAYMENT_METHOD.CREDIT_CARD) || timeOutMessage) {
            return renderPaymentProcessingDetails();
        }
        switch (type) {
            case PAYMENT_METHOD.DEBIT_CARD:
            case PAYMENT_METHOD.CREDIT_CARD:
                if (payUsing === localisable.swipeCard) {
                    return (
                        <Grid container direction="column" alignItems="center">
                            <Icon
                                type="custom"
                                icon="cp-info"
                                color="primary"
                                className={classes.infoIcon}
                            />
                            <Grid container direction="row" justify="center" className={classes.shortTopMargin}>
                                <Typography>{localisable.clickOn}</Typography>&nbsp;
                                <Typography
                                    variant="subtitle1"
                                    fontStyle="italic"
                                >{isRefund ? localisable.refund : localisable.pay}
                                </Typography>&nbsp;
                                <Typography>{localisable.andSwipeTheCard}</Typography>
                            </Grid>
                        </Grid>
                    );
                }

                return (
                    <CreditCard
                        creditCardInfo={cardInfo}
                        key={`${ledger} - ${paymentMethodId} - ${payUsing} - ${doNotProcessCard} -${cardProcessorId}`}
                        disabled
                        baseFormFieldName="extra.cardInfo"
                        tenantInfo={tenantInfo}
                        skipFieldsValidation={doNotProcessCard}
                        formProps={formProps}
                    />
                );

            case PAYMENT_METHOD.ACH: return (
                <ACH
                    key={`${ledger} - ${type} - ${payUsing}`}
                    isInEditMode={isInEditMode}
                    disabled={!ledger || !shouldEnterCardDetails}
                    baseFormFieldName={shouldEnterCardDetails ? 'achInfo' : 'extra.achInfo'}
                />
            );
            case PAYMENT_METHOD.CHECK: return <Check key={ledger} disabled={!ledger} />;
            case PAYMENT_METHOD.MONEY_ORDER: return <MoneyOrder source={SOURCE.payment.value} disabled={!ledger} />;
            default: return null;
        }
    };

    const renderAlert = () => {
        let isOpen = alerts.length ? !onAlertYes : false;
        if (!(refuseCheck && isFlagged) && generalAlertShown) {
            isOpen = false;
        }
        if (!showAlerts) {
            isOpen = false;
        }

        const onAlertClose = () => {
            setAlerts([]);
            if (!(refuseCheck && isFlagged)) {
                setGeneralAlertShown(true);
            }
        };

        const onPaymentTypeChange = (e) => {
            e.stopPropagation();
            e.preventDefault();
            setFieldValue('paymentMethodId', previousPaymentId.current);
            onSelectPayType('', previousPaymentId.current, true);
            setAlerts([]);
            openPaymentMethodDropdown();
        };

        return (
            <>
                <TenantAlertDialog
                    isTenantAlertOpen={isOpen}
                    ignoreText={refuseCheck && isFlagged ? localisable.ignore : localisable.ok}
                    changeText={refuseCheck && isFlagged && localisable.changePayment}
                    changeTextFunc={onPaymentTypeChange}
                    tenantAlerts={alerts}
                    onAlertClose={onAlertClose}
                    onAlertIgnore={onAlertClose}
                    dialogClasses={{
                        scrollPaper: classes.alert,
                        paper: classes.paper,
                    }}
                />
            </>
        );
    };

    const renderUseAchOnFile = () => (
        <LabelWithIcon className={classes.useAchOnFile} onClick={() => onChangeUseAchOnFile(true)}>
            <Typography color="primary" variant="body2">
                {localisable.useAchOnFile}
            </Typography>
        </LabelWithIcon>
    );

    return (
        <Grid container className={classes.paymentTypeContainer} justify="center">
            <Grid
                container
                direction={
                    (isMobile || !!source || (innerWidth > screenMaxWidth.tablet && innerWidth <= screenMaxWidth.laptop)
                        || shouldShowPrintCopies) && doesPaymentMethodUseCard
                        ? 'column' : 'row'
                }
                justify={
                    (isMobile || !!source || (innerWidth > screenMaxWidth.tablet && innerWidth <= screenMaxWidth.laptop)
                        || shouldShowPrintCopies) && doesPaymentMethodUseCard
                        ? 'flex-end' : 'flex-start'
                }
                item
                xs={12}
                className={classes.noWrap}
                alignItems="baseline"
            >
                <Grid
                    container
                    direction="row"
                    item
                    className={doesPaymentMethodUseCard ? classes.payTypeContainer : classes.payTypeContainerwithCard}
                    alignItems="baseline"
                    spacing={isMobile ? 1 : 2}
                    {...payTypeAndCardProcessorContainer}
                >
                    {renderPayTypeDropDown()}
                    {doesPaymentMethodUseCard && renderCardProcessorDropDown()}
                    {shouldGenerateReceipt && renderReceiptDropDown()}
                </Grid>
            </Grid>
            {
                doesPaymentMethodUseCard
                && (
                    <Grid container direction="column" className={classes.defaultTopMargin}>
                        <Typography disabled={!ledger} variant="caption">{localisable.payUsing}</Typography>
                        {renderPayUsing()}
                    </Grid>
                )
            }
            {
                type === PAYMENT_METHOD.ACH && displayAccountNumber
                && (
                    <Grid container justify="flex-end">
                        {renderUseAchOnFile()}
                    </Grid>
                )
            }
            {
                shouldShowCardComponent
                    ? (
                        <Grid
                            container
                            justify="space-around"
                            item
                            xs={12}
                            lg={source ? 12 : 10}
                            className={classes.defaultTopMargin}
                        >
                            {renderPaymentMethod()}
                        </Grid>
                    )
                    : (
                        <Grid container direction="column">
                            {authenticationKey && (
                                <SyrapayHostedForm
                                    facilityId={fid}
                                    tokenOnly={!!isRefund}
                                    tenantInfo={tenantInfo}
                                    paymentAmount={amount || 0}
                                    onSubmit={onSubmitHostedForm}
                                    authenticationKey={authenticationKey}
                                    disableFormSubmit={disableFormSubmit}
                                    closeHostedForm={closeHostedForm}
                                    shouldUseTenantAddress={shouldUseTenantAddress}
                                    shouldSaveCard={saveCardForFutureTransactions || autoPayEnabled}
                                />
                            )}
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={(
                                    <Checkbox
                                        disableRipple
                                        color="primary"
                                        onChange={() => makeUseOfTenantAddress(!shouldUseTenantAddress)}
                                    />
                                )}
                                label={localisable.useTenantAddress}
                            />
                        </Grid>
                    )
            }
            {
                doesPaymentMethodSavesInfo && !isRefund && !isButtonLoading && !timeOutMessage
                && (
                    <Grid container item xs={12} direction={isMobile ? 'column' : 'row'}>
                        {renderSaveCardCheckboxes(type === PAYMENT_METHOD.ACH ? 'achInfo' : 'cardInfo')}
                    </Grid>
                )}
            <Grid container item xs={12} className={clsx({ [classes.defaultTopMargin]: !doesPaymentMethodUseCard })}>
                {renderCommentsTextField()}
            </Grid>
            {renderAlert()}
        </Grid>
    );
};

PaymentType.propTypes = {
    isRefund: PropTypes.bool,
    source: PropTypes.string,
    payment: PropTypes.object,
    showAlerts: PropTypes.bool,
    formProps: PropTypes.object,
    parentSize: PropTypes.object,
    isButtonLoading: PropTypes.bool,
    paymentMethods: PropTypes.array,
    timeOutMessage: PropTypes.string,
    getCurrentStatus: PropTypes.func,
    currentFacility: PropTypes.object,
    setPrefilledFalse: PropTypes.func,
    cardProcessorData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    reFetchTenantBalance: PropTypes.bool,
    getFuncToOpenPaymentMethod: PropTypes.func,
    onSubmitHostedForm: PropTypes.func.isRequired,
    isAmountWithoutModifierTouched: PropTypes.bool,
    refundPeriodExpiredDisabled: PropTypes.bool,
};
PaymentType.defaultProps = {
    formProps: {},
    payment: {},
    paymentMethods: [],
    getCurrentStatus: () => { },
    reFetchTenantBalance: false,
    cardProcessorData: {},
    timeOutMessage: '',
    getFuncToOpenPaymentMethod: EMPTY_FUNC,
    currentFacility: {},
    refundPeriodExpiredDisabled: false,
};

export default (memo(PaymentType));
