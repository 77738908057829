
const arrowTheme = {
    arrowShadow: {
        '&[x-placement*="bottom"] $arrow': { '&::before': { filter: 'drop-shadow(0px -1px 0px rgba(0,0,0,.1))' } },
        '&[x-placement*="top"] $arrow': { '&::before': { filter: 'drop-shadow(0px 1px 0px rgba(0,0,0,.1))' } },
        '&[x-placement*="left"] $arrow': { '&::before': { filter: 'drop-shadow(1px 0px 0px rgba(0,0,0,.1))' } },
        '&[x-placement*="right"] $arrow': { '&::before': { filter: 'drop-shadow(-1px 0px 0px rgba(0,0,0,.1))' } },
    },
};


export default arrowTheme;
