import { Grid } from 'Commons/components/generic/componentlibrary/components/Components';
import { useState } from 'react';
import localisable from 'Commons/config/strings/localisable';
import { Checkbox } from 'Generic/checkbox/components/CheckBox';
import Button from 'Commons/components/generic/button/components/Button';
import Typography from 'Generic/typography/components/Typography';
import ButtonText from 'Generic/buttontext/components/ButtonText';
import { acceptLegalAgreement } from './config/ApiRequests';

const LegalAgreement = ({ agreementLink, classes, onAction, fid, clearStoreKeys }) => {
    const { innerWidth, innerHeight } = window;
    const [checked, setChecked] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const onAcceptAgreement = (_, response) => {
        if (response) {
            clearStoreKeys(['userProfile', 'facility', 'permissionRoles', 'currentFacility']);
        }
        setIsButtonLoading(false);
    };

    const onProceed = () => {
        setIsButtonLoading(true);
        acceptLegalAgreement(onAction, fid, onAcceptAgreement);
    };

    return (
        <Grid container className={classes.content}>
            <object
                data={`${agreementLink}#toolbar=0`}
                type="application/pdf"
                width={innerWidth}
                height={innerHeight * 0.75}
            >
                <a href={agreementLink}>{localisable.agreement}</a>
            </object>
            <Grid container alignItems="center" direction="column" justify="flex-end">
                <Checkbox
                    value={checked}
                    trackValue={false}
                    label={<Typography variant="subtitle1">{localisable.termsAndConditions}</Typography>}
                    onChange={(_, value) => setChecked(value)}
                />
                <Button
                    variant="contained"
                    onClick={onProceed}
                    disabled={!checked || !fid}
                >
                    <ButtonText
                        isButtonLoading={isButtonLoading}
                        label={localisable.proceed}
                    />
                </Button>
            </Grid>
        </Grid>
    );
};

LegalAgreement.propTypes = {
    agreementLink: PropTypes.string,
    classes: PropTypes.object,
    onAction: PropTypes.func,
    fid: PropTypes.string,
    clearStoreKeys: PropTypes.func,
};

export default LegalAgreement;
