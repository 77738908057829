import { Typography as MuiTypography, makeStyles } from 'Generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import typographyStyle from '../styles/TypographyStyle';

const useStyles = makeStyles(typographyStyle, { name: 'Typography' });

const Typography = (props) => {
    const { fontStyle, fontFamily, className, disabled, ...others } = props;
    const classes = useStyles(props);

    return (
        <MuiTypography
            className={clsx(classes.fontFamily, classes.fontStyle,
                disabled && classes.disabled, className)}
            {...others}
        />
    );
};

Typography.propTypes = {
    fontStyle: PropTypes.oneOf(['normal', 'italic', 'oblique']),
    fontFamily: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

Typography.defaultProps = {
    className: '',
    fontStyle: 'normal',
};

export default Typography;
