import { TABLE_ROW_HEIGHT_VARIANT } from 'Commons/config/constants/Constants';
import media from '../../../../theme/MediaQueries';

const tableStyle = theme => ({
    table: {
        display: 'flex',
        height: '100%',
        color: theme.palette.text.primary,
        flexDirection: 'column',
    },
    tableGrid: {
        flexGrow: 1,
        display: 'flex',
        position: 'relative',
        overflow: 'auto',
        width: '1024px',
        [media.tabletUp]: { width: 'auto' },
    },
    gridColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: '1 1 auto',
    },
    fixedGridContainer: {
        flex: '0 0 75px',
        zIndex: 10,
        position: 'absolute',
        boxSizing: 'border-box',
        '& $fixedGrid': { overflow: 'hidden !important' },
    },
    fixedGrid: {},
    marginalsGrid: {
        overflow: 'hidden !important',
        outline: 'none',
    },
    bodyGrid: {
        width: 'auto',
        overflow: 'auto',
        scrollBehavior: 'smooth',
        willChange: 'inherit !important',
        outline: 'none',
    },
    tableFooter: {
        position: 'absolute',
        width: '100%',
        margin: 0,
        backgroundColor: `${theme.palette.common.white}CC`,
    },
    [`atTop${TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER}`]: { top: TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER },
    [`atTop${TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER}`]: { top: TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER },
    [`atTop${TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER_RESPONSIVE}`]: { top: TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER_RESPONSIVE },
    [`atTop${TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER_RESPONSIVE}`]: { top: TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER_RESPONSIVE },
    atBottom: { bottom: 0 },
    headerFooterCell: {
        display: 'flex',
        textAlign: 'left',
        width: '100%',
        height: '100%',
        cursor: 'default',
        overflow: 'hidden',
        padding: 8,
        boxSizing: 'border-box',
        fontWeight: 600,
    },
    cell: {
        padding: 8,
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    row: {
        display: 'flex',
        boxSizing: 'border-box',
        padding: theme.spacing(0.2, 0),
    },
    bodyRow: {
        '&:hover': {
            backgroundColor: theme.palette.hover,
            '& $hiddenColumn': { '& > div': { visibility: 'visible' } },
        },
    },
    firstColumn: { paddingLeft: 32 },
    firstColumnDefaultPadding: { paddingLeft: 8 },
    firstColumnDefaultHeader: { paddingLeft: 12 },
    activeRibbon: { borderLeft: `4px solid ${theme.palette.ribbon.active}` },
    inactiveRibbon: { borderLeft: `4px solid ${theme.palette.status.inactive}` },
    noLeftPadding: { paddingLeft: 0 },
    lastColumn: {
        paddingRight: 16,
        '&$hiddenColumn': { '& > div': { visibility: 'hidden' } },
    },
    rowBorder: { borderBottom: `1px solid ${theme.palette.black[300]}` },
    marginalsBorder: { borderBottom: `1px solid ${theme.palette.black[600]}` },
    marginalsBorderTop: { borderTop: `1px solid ${theme.palette.black[600]}` },
    hiddenColumn: {},

    alignLeft: { justifyContent: 'flex-start', textAlign: 'left' },
    alignRight: { justifyContent: 'flex-end', textAlign: 'right' },
    alignCenter: { justifyContent: 'center', textAlign: 'center' },

    vAlignTop: { alignItems: 'flex-start' },
    vAlignBottom: { alignItems: 'flex-end' },
    vAlignCenter: { alignItems: 'center' },
    disabledCell: {
        position: 'absolute',
        height: '100%',
        backgroundColor: theme.hexWithOpacity(theme.palette.common.white, 3),
        zIndex: 802,
    },
    coloredSquare: {
        marginRight: theme.spacing(1),
        width: 16,
        height: 16,
        outline: 'none',
        alignContent: 'center',
    },
    activeStatusColor: { background: theme.palette.ribbon.active },
    inactiveStatusColor: { background: theme.palette.status.inactive },
    activeStatusContainer: { marginRight: theme.spacing(5) },
    inactiveStatusContainer: { marginLeft: theme.spacing(3) },
    activeInactiveLabel: { padding: theme.spacing(0, 3) },
    activeInactiveListPage: {
        margin: theme.spacing(1, 4.5, 0, 4.5),
        paddingBottom: theme.spacing(2),
    },
    activeInactiveSettingsPage: { padding: theme.spacing(1, 4.5, 2, 0) },
});

export default tableStyle;
