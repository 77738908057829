import { AutoSuggest } from 'Commons/components/business/autosuggest/components/AutoSuggest';
import localisable from 'Commons/config/strings/localisable';
import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { MIGRATION_STAGES } from '../config/Constants';
import MigrationStagesStyle from '../styles/MigrationStagesStyle';

const useStyles = makeStyles(MigrationStagesStyle, { name: 'MigrationStagesStyle' });

const MigrationStagesFilter = () => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h3" className={classes.filterLabel}>
                Filter
            </Typography>
            <Grid container>
                <Typography variant="subtitle1" className={classes.firstLabel}>
                    {localisable.migrationStage}
                </Typography>
                <FormCheckboxGroup
                    name="terms.migrationStages"
                    list={Object.values(MIGRATION_STAGES)}
                />
            </Grid>
            <Grid container className={classes.accountSearch}>
                <Grid item>
                    <Typography variant="subtitle1" className={classes.accountLabel}>
                        {localisable.accountSelection}
                    </Typography>
                </Grid>
                <Grid item>
                    <AutoSuggest
                        PopdownProps={{
                            placeholder: 'Select an account to check migration',
                            label: 'Search Account',
                            name: 'accountId',
                        }}
                        fullWidth
                        textFieldClasses={{ input: classes.input }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default memo(MigrationStagesFilter);
