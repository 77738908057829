import media from 'Commons/theme/MediaQueries';

const autoSuggestStyle = theme => ({
    autoSuggest: {
        padding: 0,
        '& $listItem:first-child': { paddingTop: 4 },
        '& $listItem:last-child': { paddingBottom: 4 },
    },
    padding: { paddingLeft: 25 },
    listItem: {
        color: theme.palette.text.primary,
        whiteSpace: 'normal',
        height: 'auto',
        wordWrap: 'break-word',
        padding: '3px 4px',
        lineHeight: 1.3,
        outline: 'none',
        minHeight: 40,
        justifyContent: 'center',
    },
    autoSuggestCard: {
        boxShadow: 'none',
        border: `1px ${theme.palette.black[300]} solid`,
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.palette.hover },
        backgroundColor: theme.palette.common.white,
    },
    autoSuggestUnitCard: {
        boxShadow: 'none',
        border: `0px solid ${theme.palette.disabled}4C`,
        borderRadius: 0,
        padding: '8px 16px',
        lineHeight: 1.3,
        justifyContent: 'left',
    },
    paper: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
        },
    },
    unitListpadding: { padding: '5px 20px', width: '100%' },
    searchIcon: {
        paddingRight: 8,
        paddingBottom: 8,
        [media.phabletUp]: { padding: '12px 8px 8px 0' },
    },
    cardContent: { padding: theme.spacing(1) },
});

export default autoSuggestStyle;
