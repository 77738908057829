import round from 'Commons/helpers/utils/Round';

/**
 * @param {number | string} amount The amount to be formatted
 * @param {number} precision number of decimal places
 * @return {string}
 */
const getFormattedAmount = (amount, precision = 2) => round(amount, precision, true);

export default getFormattedAmount;
