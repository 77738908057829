import { DELINQUENCY_SCHEDULED_STATUS } from 'Commons/config/constants/Constants';

const STATUS = [
    {
        label: 'Scheduled',
        value: DELINQUENCY_SCHEDULED_STATUS.Active.value,
    },
    {
        label: 'Deleted',
        value: DELINQUENCY_SCHEDULED_STATUS.Inactive.value,
    },
];


export { STATUS };
