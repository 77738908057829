import { memo, useState, useEffect } from 'react';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import { THOUSAND_MILI_SECONDS } from './config/Constants';

const SecondsCountdownTimer = ({ maxSeconds, ...props }) => {
    const [seconds, setSeconds] = useState(maxSeconds);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(secondsValue => secondsValue - 1);
        }, THOUSAND_MILI_SECONDS);
        setTimeout(() => { clearInterval(interval); }, maxSeconds * THOUSAND_MILI_SECONDS);
    }, []);

    return (<Typography {...props}>{seconds} {localisable.sec}</Typography>);
};

SecondsCountdownTimer.propTypes = { maxSeconds: PropTypes.number };

export default memo(SecondsCountdownTimer);
