import { DASHBOARD_TYPE } from 'Commons/config/constants/Constants';
import { OPERATIONS_PATHS } from 'External/containers/dashboard/widgets/sidebar/WorkCenterPaths';
import { ROUTE } from 'External/redux/config/RouteNames';

const externalRouteEntityMapping = {
    0: [ROUTE.PENDING_TASKS],
    1: [ROUTE.TENANT_LIST, ROUTE.CREATE_TENANT, ROUTE.EDIT_TENANT, ROUTE.VIEW_TENANT, ROUTE.LEDGER_REVIEW,
        ROUTE.LEAD_LIST, ROUTE.CREATE_LEAD, ROUTE.EDIT_LEAD,
        ROUTE.CONVERSATIONS, ROUTE.DELINQUENCY_LIST],
    2: [ROUTE.UNIT_LIST, ROUTE.CREATE_UNIT, ROUTE.EDIT_UNIT,
        ROUTE.BEV_VIEW, ROUTE.BEV_SETUP,
        ROUTE.RESERVATION_LIST, ROUTE.RESERVATION, ROUTE.WAITING_LIST],
    3: [ROUTE.DOCS_GD, ROUTE.DOCS_RB, ROUTE.REPORT, ROUTE.REPORT_WC],
    4: [ROUTE.OPERATIONS, OPERATIONS_PATHS,
        ROUTE.POINT_OF_SALE, ROUTE.INVENTORY_LIST, ROUTE.VENDOR_LIST, ROUTE.RETAIL_SALE_RETURNS,
        ROUTE.VEHICLE, ROUTE.FINANCIAL_LIST, ROUTE.CREATE_AR, ROUTE.DEPOSIT_UPDATE],
};

const externalSettingsRouteEntityMapping = {
    0: [ROUTE.USER_LIST, ROUTE.USER_ROLE],
    1: ['c/facility'],
    2: [ROUTE.REPORT, ROUTE.CONSOLIDATED_REPORT],
};

const excludeEntities = ['movein', 'moveout', 'estimate'];

const findMatchingParentEntityIndex = (dashboardType, pathname) => {
    const getMatchingEntity = routeEntityMapping => Object.entries(routeEntityMapping).find(
        ([, routes]) => routes.some(route => pathname.includes(route)),
    );
    let routeEntityMapping;
    if (dashboardType === DASHBOARD_TYPE.SETTINGS) {
        routeEntityMapping = externalSettingsRouteEntityMapping;
    } else if (dashboardType === DASHBOARD_TYPE.EXTERNAL) {
        routeEntityMapping = externalRouteEntityMapping;
    }
    const matchedEntity = getMatchingEntity(routeEntityMapping);
    if (matchedEntity) {
        return parseInt(matchedEntity[0], 10);
    }
    if (excludeEntities.some(entity => pathname.includes(entity))) {
        return null;
    }
    return 0;
};

export { externalRouteEntityMapping, externalSettingsRouteEntityMapping, findMatchingParentEntityIndex };
