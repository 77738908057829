import { useState } from 'react';
import ViewReport from './ViewReport';

const ReportViewer = ({
    children,
    component: Component,
    componentProps: ComponentProps,
    ...props
}) => {
    const [viewReport, setViewReport] = useState(false);
    const eventProps = { onClick: () => setViewReport(true) };

    const onCloseLoader = () => {
        setViewReport(false);
    };

    return (
        <>
            <Component {...eventProps} {...ComponentProps}>
                {children}
            </Component>
            {
                viewReport
                && (
                    <ViewReport
                        onClose={onCloseLoader}
                        {...props}
                    />
                )
            }
        </>
    );
};

ReportViewer.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.elementType,
    componentProps: PropTypes.object,
};

ReportViewer.defaultProps = { component: 'div' };

export default React.memo(ReportViewer);
