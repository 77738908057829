import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const PERCENTAGE = 'PERCENTAGE';
const PIXEL = 'PIXEL';
const OVERSCAN_COLUMN_COUNT = 3;
const OVERSCAN_ROW_COUNT = 2;
const ALIGN = createEnumMap(['left', 'right', 'center']);
const VALIGN = createEnumMap(['top', 'bottom', 'center']);
const ERROR = 1;

export {
    ERROR,
    PERCENTAGE,
    PIXEL,
    ALIGN,
    VALIGN,
    OVERSCAN_COLUMN_COUNT,
    OVERSCAN_ROW_COUNT,
};
