import PAYMENT_PERMISSION_KEYS from 'Commons/components/business/payment/config/PermissionKeys';
import hasPermission from 'Commons/helpers/utils/PermissionChecker';

const getPaymentPermissions = (permission = {}) => {
    const paymentPermissions = {};
    Object.keys(PAYMENT_PERMISSION_KEYS).forEach((key) => {
        paymentPermissions[PAYMENT_PERMISSION_KEYS[key]] = hasPermission(permission, PAYMENT_PERMISSION_KEYS[key]);
    });
    return paymentPermissions;
};

export default getPaymentPermissions;
