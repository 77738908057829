export const labelFormatter = label => label.replace(/_/g, ' ');

export const valueFormatter = value => value;

//  TODO - Make formatter an object parameter where the structure would be something like:
//  formatter = { label: formatter1, value: formatter2 }
export const mapEnum = (enumList, formatter = labelFormatter, valueTransformer = valueFormatter) => {
    const enumMap = {};
    enumList.forEach((value) => {
        enumMap[value] = {
            label: formatter(value),
            value: valueTransformer(value),
        };
    });
    return enumMap;
};

const keyFormatter = key => key.replace(/ /g, '_').toUpperCase();

export const underScoreFormatter = val => val.replace(/ /g, '_');

/**
 * @name createEnumMap
 * @description Given a list of objects, creates a map
 * @param {Array} enums Array of enums
 */
export const createEnumMap = (enums = [], formatter = keyFormatter) => {
    const enumMapToReturn = {};
    enums.forEach((enumKey = '') => {
        enumMapToReturn[formatter(enumKey)] = enumKey;
    });
    return enumMapToReturn;
};

export const pathFormatter = (path, replacementChar = ' - ') => path.replace(/\./g, replacementChar);
