const migrationStagesStyle = theme => (
    {
        filterLabel: { marginBottom: theme.spacing(2.5) },
        migrationLabel: { paddingTop: theme.spacing(2) },
        accountLabel: { paddingTop: theme.spacing(2), marginBottom: theme.spacing(2.5) },
        accountSearch: { flexDirection: 'column' },
        input: { paddingBottom: theme.spacing(0.25), paddingTop: 0 },
    });

export default migrationStagesStyle;
