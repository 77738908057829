const cardChipStyle = theme => ({
    customChip: {
        height: 20,
        borderRadius: 13,
    },
    tenant: { backgroundColor: theme.palette.chip.tenants },
    lead: { backgroundColor: theme.palette.chip.leads },
    reserved: { backgroundColor: theme.palette.chip.reservation },
    estimate: { backgroundColor: theme.palette.chip.estimate },
    // Lead estimate card chips
    estimateStatusActive: { backgroundColor: theme.palette.status.active, color: theme.palette.common.white },
    estimateStatusMovedIn: { backgroundColor: theme.palette.status.created, color: theme.palette.common.white },
    estimateStatusExpired: { backgroundColor: theme.palette.status.expired, color: theme.palette.common.white },
    //
    expired: { backgroundColor: theme.palette.chip.expired },
    chipLabel: {
        fontSize: 12,
        fontWeight: 600,
        paddingLeft: 14,
        paddingRight: 14,
        textTransform: 'uppercase',
        color: theme.palette.common.white,
    },
});

export default cardChipStyle;
