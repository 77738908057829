import { XS_6 } from 'Commons/config/constants/GridSpacingConstants';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { FormTextField } from 'Generic/textfield/components/TextField';
import Typography from 'Generic/typography/components/Typography';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { convertToNumber, isValidNumber } from 'Commons/helpers/utils/Utils';
import { getIn } from 'Commons/helpers/utils/DataHelpers';
import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { MODE } from 'Commons/config/constants/Constants';
import Icon from 'Commons/components/generic/icon/components/Icon';
import LabelWithAddons from 'Commons/components/generic/labelwithaddons/components/LabelWithAddons';
import filterStyle from '../styles/DelinquencyFilterStyles';

const useStyles = makeStyles(filterStyle, { name: 'DelinquencyFilter', index: 1 });

const FromToFilterTextField = ({
    title,
    values,
    toName,
    classes,
    toLabel,
    fromName,
    fromLabel,
    clearText,
    toPlaceHolder,
    setFieldValue,
    fromPlaceholder,
    toTextFieldProps,
    fromTextFieldProps,
}) => {
    const clearFieldValue = () => {
        setFieldValue(fromName, undefined);
        setFieldValue(toName, undefined);
    };

    const fromValue = getIn(values, fromName);
    const toValue = getIn(values, toName);

    const getValidator = useCallback((name) => {
        const isFieldNoOfDaysLate = name.startsWith('range.noOfDaysLate');
        const isFieldLedgerBalance = name.startsWith('range.balance');
        const commonValidators = {
            ...!isFieldLedgerBalance ? { isInteger: true } : {},
            ...(isFieldNoOfDaysLate || isFieldLedgerBalance)
                ? { isNonZeroPositiveNumeric: true } : { isPositiveNumeric: true },
        };

        switch (name) {
            case 'range.balance.from':
            case 'range.noOfDaysLate.from':
            case 'range.lastContactedDays.from':
                return {
                    ...commonValidators,
                    ...isValidNumber(toValue) && convertToNumber(toValue) >= 0 ? {
                        isGreaterThan: {
                            value: toValue,
                            errorMessage: `${localisable.valueMustBeLessThan} ${toValue}`,
                        },
                    } : {},
                };
            case 'range.balance.to':
            case 'range.noOfDaysLate.to':
            case 'range.lastContactedDays.to':
                return {
                    ...commonValidators,
                    ...isValidNumber(fromValue) && convertToNumber(fromValue) >= 0 ? {
                        isLessThan: {
                            value: fromValue,
                            errorMessage: `${localisable.valueMustBeMoreThan} ${fromValue}`,
                        },
                    } : {},
                };
            default: return {};
        }
    }, [fromValue, toValue]);

    return (
        <Grid item container direction="column" spacing={1}>
            <Grid item>
                <Typography
                    variant="subtitle1"
                    className={title === localisable.balanceOwed ? classes.firstLabel : classes.restLabel}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={4}>
                    <FormTextField
                        name={fromName}
                        GridProps={XS_6}
                        label={fromLabel}
                        noIndicatorWrapper
                        placeholder={fromPlaceholder}
                        validate={getValidator(fromName)}
                        className={classes.filterTextField}
                        {...fromTextFieldProps}
                    />
                    <FormTextField
                        name={toName}
                        label={toLabel}
                        GridProps={XS_6}
                        noIndicatorWrapper
                        placeholder={toPlaceHolder}
                        validate={getValidator(toName)}
                        className={classes.filterTextField}
                        {...toTextFieldProps}
                    />
                </Grid>
            </Grid>
            <Grid item container justify="flex-end">
                <Typography
                    component="div"
                    color="primary"
                    variant="caption"
                    onClick={clearFieldValue}
                    className={classes.buttonLabel}
                >
                    {clearText}
                </Typography>
            </Grid>
        </Grid>
    );
};

FromToFilterTextField.propTypes = {
    title: PropTypes.node,
    toLabel: PropTypes.node,
    toName: PropTypes.string,
    values: PropTypes.object,
    fromLabel: PropTypes.node,
    clearText: PropTypes.node,
    classes: PropTypes.object,
    fromName: PropTypes.string,
    setFieldValue: PropTypes.func,
    toPlaceHolder: PropTypes.node,
    fromPlaceholder: PropTypes.node,
    toTextFieldProps: PropTypes.object,
    fromTextFieldProps: PropTypes.object,
};

const getModeLabel = (mode) => {
    const { icon, value } = mode;
    return (
        <LabelWithAddons
            start={<Icon icon={icon} type="custom" fullWidth />}
        >
            <Typography variant="body2" noWrap>
                {value}
            </Typography>
        </LabelWithAddons>
    );
};

const DelinquencyFilter = ({ formProps: { setFieldValue, values } = {} }) => {
    const classes = useStyles();
    const modeList = MODE.map((object) => {
        const { value } = object;
        return { label: getModeLabel(object), value };
    });
    return (
        <Grid container direction="column" spacing={3} className={classes.filterSection}>
            <FromToFilterTextField
                values={values}
                classes={classes}
                toName="range.balance.to"
                fromName="range.balance.from"
                setFieldValue={setFieldValue}
                title={localisable.balanceOwed}
                fromLabel={`${localisable.minimumAmount} ($)`}
                toLabel={`${localisable.maximumAmount} ($)`}
                fromPlaceholder={localisable.enterNumbers}
                toPlaceHolder={localisable.enterNumbers}
                clearText={localisable.clearAmount}
            />

            <FromToFilterTextField
                values={values}
                classes={classes}
                toLabel={localisable.endDay}
                setFieldValue={setFieldValue}
                toName="range.noOfDaysLate.to"
                fromName="range.noOfDaysLate.from"
                title={localisable.noOfDaysLate}
                fromLabel={localisable.startDay}
                clearText={localisable.clearDays}
                toPlaceHolder={localisable.enterEndDay}
                fromPlaceholder={localisable.enterStartDay}
            />

            <FromToFilterTextField
                values={values}
                classes={classes}
                setFieldValue={setFieldValue}
                toLabel={localisable.endDay}
                title={localisable.lastContacted}
                fromLabel={localisable.startDay}
                clearText={localisable.clearDays}
                toName="range.lastContactedDays.to"
                fromName="range.lastContactedDays.from"
                toPlaceHolder={localisable.enterEndDay}
                fromPlaceholder={localisable.enterStartDay}
            />
            <Grid item>
                <Typography variant="subtitle1" className={classes.restLabel}>
                    {localisable.contactedVia}
                </Typography>
                <FormCheckboxGroup
                    name="terms.lastContactedMode"
                    list={modeList}
                    noGrid
                    noIndicatorWrapper
                />
            </Grid>
        </Grid>
    );
};

DelinquencyFilter.propTypes = { formProps: PropTypes.object };

DelinquencyFilter.defaultProps = { formProps: {} };

export default memo(DelinquencyFilter);
