import { createEnumMap, mapEnum } from 'Commons/helpers/utils/Formatter';
import localisable from 'Commons/config/strings/localisable';
import STATUS from 'Commons/config/constants/StoreKeyStatus';
import bevEnums from './Enums';

const KEY_CODES = {
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    DELETE: 46,
    SPACE_BAR: 32,
    ENTER: 13,
};

const ROTATE_BY = {
    LEFT_RIGHT: 10,
    NINETY_DEG: 90,
};

const COUNT_TYPE = {
    INCREMENT: 1,
    DECREMENT: -1,
};

const COUNT_RADIO_LIST = [
    {
        value: COUNT_TYPE.INCREMENT,
        label: localisable.bevIncrementRadioLabel,
    },
    {
        value: COUNT_TYPE.DECREMENT,
        label: localisable.bevDecrementRadioLabel,
    },
];

const RENTAL_STATUS = createEnumMap(bevEnums.rentalStatus);

const DEFAULT_RENTAL_STATUS = RENTAL_STATUS.VACANT;

const BEV_MODES = createEnumMap(bevEnums.modes);

const POSITIONS = createEnumMap(bevEnums.positions);

const TRIANGLE_POSITIONS = createEnumMap(bevEnums.trianglePositions);

const TRIANGLE_TYPES = createEnumMap(bevEnums.triangleTypes);

const ACTIONS = createEnumMap(bevEnums.actions);

const DIRECTIONS = createEnumMap(bevEnums.directions);

const STATUS_TO_UPDATE_ON = [STATUS.LOADED, STATUS.UPDATED, STATUS.UNLOADED, STATUS.INVALID];

const EMPTY_LIST = [];

const DEFAULT_LEVEL_LABEL = createEnumMap(bevEnums.defaultLevelLabels);

const EVENT_TRIGGERED_BY_TYPE = createEnumMap(bevEnums.eventTriggerTypes);

const TYPE_OF_ENTITY = mapEnum(bevEnums.entityType);

const PLACEHOLDER_TYPE = mapEnum(bevEnums.placeholderType);

const COLORED_SQUARE_BORDER_SHOW_TOLERANCE = 228;

export {
    ACTIONS,
    KEY_CODES,
    ROTATE_BY,
    BEV_MODES,
    POSITIONS,
    COUNT_TYPE,
    DIRECTIONS,
    EMPTY_LIST,
    RENTAL_STATUS,
    TRIANGLE_TYPES,
    COUNT_RADIO_LIST,
    TRIANGLE_POSITIONS,
    EVENT_TRIGGERED_BY_TYPE,
    STATUS_TO_UPDATE_ON,
    DEFAULT_LEVEL_LABEL,
    DEFAULT_RENTAL_STATUS,
    COLORED_SQUARE_BORDER_SHOW_TOLERANCE,
    TYPE_OF_ENTITY,
    PLACEHOLDER_TYPE,
};
