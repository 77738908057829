import { FormTextField } from 'Generic/textfield/components/TextField';
import { XS_FALSE, SM_8_XS_12, XS_12 } from 'Commons/config/constants/GridSpacingConstants';
import { IS_EMAIL, IS_MAX_LENGTH_254 } from 'Commons/config/constants/Validators';
import { useEffect, memo, useState } from 'react';
import { FormCheckbox } from 'Commons/components/generic/checkbox/components/CheckBox';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import { isEmail } from 'Commons/helpers/utils/validator/Validator';
import { FORM_TYPE } from 'External/containers/createoccupancy/config/Constants';
import Button from 'Commons/components/generic/button/components/Button';
import Tooltip from 'Commons/components/generic/tooltip/components/Tooltip';
import Icon from 'Commons/components/generic/icon/components/Icon';
import Typography from 'Commons/components/generic/typography/components/Typography';
import Phone from './Phone';
import contactStyle from '../styles/ContactStyle';

const useStyles = makeStyles(contactStyle, { name: 'Contact' });

const Contact = ({
    phone,
    status,
    values,
    touched,
    resetForm,
    isFormActive,
    initialValues,
    setFieldValue,
    setFieldTouched,
    currentFacility,
    onPhoneOrEmailValid,
    email: { emailAddress, isEmailEnabled, additionalEmailAddress } = {},
    initialValues: { email: { emailAddress: initialEmailAddress } = {} } = {},
    values: { ignore: { formType } = {} } = {},
}) => {
    const classes = useStyles();

    const [isAdditionalEmailOpen, setIsAdditionalEmailOpen] = useState(() => !!additionalEmailAddress);

    useEffect(() => {
        if (!emailAddress && isEmailEnabled) {
            setFieldTouched('email.isEmailEnabled', true, false);
            setFieldValue('email.isEmailEnabled', false);
        }
        if (emailAddress
            && initialEmailAddress !== emailAddress
            && !isEmail(emailAddress)) { // The isEmail validator returns error message in case the email is invalid, hence the !isEmail
            onPhoneOrEmailValid(emailAddress, 'email.emailAddress');
        }
    }, [emailAddress, initialEmailAddress]);

    const checkEmail = () => {
        if (!isEmailEnabled) {
            setFieldTouched('email.isEmailEnabled', true, false);
            setFieldValue('email.isEmailEnabled', true);
        }
    };

    return (
        <>
            {/* To avoid the empty padding */}
            <Grid container alignItems="center">
                <FormTextField
                    name="email.emailAddress"
                    label={localisable.emailId}
                    placeholder={localisable.exampleEmail}
                    validate={{ ...IS_EMAIL, ...IS_MAX_LENGTH_254 }}
                    fullWidth
                    componentClasses={{ leftMarginIcon: classes.leftMarginIcon }}
                    GridProps={SM_8_XS_12}
                    onChange={checkEmail}
                />

                <FormCheckbox
                    className={classes.checkbox}
                    color="primary"
                    key={!emailAddress}
                    disabled={!emailAddress}
                    name="email.isEmailEnabled"
                    GridProps={XS_FALSE}
                    label={localisable.enableEmail}
                    formVariant={VARIANT.INLINE}
                />
                {formType === FORM_TYPE.TENANT ? (
                    <>
                    {isAdditionalEmailOpen ? (
                        <FormTextField
                            name="email.additionalEmailAddress"
                            label={(
                                <Grid container direction="row" alignItems="center" justify="space-between">
                                    <Typography>{localisable.additionalEmails}</Typography>
                                    <Tooltip
                                        title={(
                                            <ol>
                                                <li>{localisable.additionalEmailConfiguredMsg}</li>
                                                <li>{localisable.additionalEmailCommunicationMsg}</li>
                                                <li>{localisable.additionalEmailDuplicateCheckMsg}</li>
                                            </ol>
                                        )}
                                        classes={{ lightTooltip: classes.lightTooltip }}
                                        event="onClick"
                                    >
                                        <Icon type="custom" icon="cp-info" color="primary" size="medium" className={classes.defaultCursor} />
                                    </Tooltip>
                                </Grid>
                            )}
                            placeholder={`${localisable.enterEmail} (${localisable.commaSeparatedEmailNote})`}
                            validate={{ validateCommaSeparatedEmail: { value: { additionalEmailsToCheck: [emailAddress], errorMsg: localisable.primaryEmailInAdditionalEmailErrorMsg } } }}
                            fullWidth
                            GridProps={XS_12}
                            componentClasses={{ leftMarginIcon: classes.leftMarginIcon }}
                            addon={{
                                end: <Icon
                                    icon="cp-close"
                                    type="custom"
                                    display="block"
                                    onClick={() => {
                                        setIsAdditionalEmailOpen(false);
                                        setFieldValue('email.additionalEmailAddress', '');
                                        setFieldTouched('email.additionalEmailAddress', true);
                                    }}
                                />,
                            }}
                        />
                    ) : null }
                    {!isAdditionalEmailOpen && emailAddress && (
                        <Button
                            fullWidth
                            variant="text"
                            color="primary"
                            onClick={() => setIsAdditionalEmailOpen(true)}
                            className={classes.additionalEmailAddress}
                        >
                            {`${localisable.add} ${localisable.additionalEmails}`}
                        </Button>
                    )}
                    </>
                ) : null}
            </Grid>

            <Phone
                phone={phone}
                status={status}
                values={values}
                classes={classes}
                touched={touched}
                resetForm={resetForm}
                isFormActive={isFormActive}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
                setFieldTouched={setFieldTouched}
                currentFacility={currentFacility}
                onPhoneOrEmailValid={onPhoneOrEmailValid}
            />

        </>
    );
};

Contact.propTypes = {
    phone: PropTypes.object,
    email: PropTypes.object,
    status: PropTypes.object,
    values: PropTypes.object,
    touched: PropTypes.object,
    resetForm: PropTypes.func,
    isFormActive: PropTypes.bool,
    setFieldValue: PropTypes.func,
    initialValues: PropTypes.object,
    setFieldTouched: PropTypes.func,
    currentFacility: PropTypes.object,
    onPhoneOrEmailValid: PropTypes.func,
};

Contact.defaultProps = {
    phone: {},
    touched: {},
    initialValues: {},
    setFieldValue: () => {},
    onPhoneOrEmailValid: () => {},
};

export default memo(Contact);
