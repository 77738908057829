const checkZipCodeDataFetchStatus = (classRef, actions, formStatus) => {
    const { isZipCodeDataBeingFetched = false } = formStatus;
    if (isZipCodeDataBeingFetched) {
        classRef.setState({ isButtonLoading: true });
        // eslint-disable-next-line no-param-reassign
        classRef.shouldReEvaluateEditFormData = true;
        actions.setSubmitting(false);
    }
};

export { checkZipCodeDataFetchStatus };
