import { Grid, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { memo, useEffect, useMemo, useState } from 'react';
import { SM_2, SM_6 } from 'Commons/config/constants/GridSpacingConstants';
import { FormTextField } from 'Commons/components/generic/textfield/components/TextField';
import Tabs from 'Commons/components/generic/tabs/components/Tabs';
import localisable from 'Commons/config/strings/localisable';
import Button from 'Generic/button/components/Button';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import baseUserRoleStyle from 'Commons/components/business/baseUserRole/styles/BaseUserRoleStyle';
import userRoleTabsConfig from 'Commons/components/business/baseUserRole/config/UserRoleTabsConfig';
import { configurationData, financialsData, occupancyData, retailSaleData, unitData }
from '../config/RolePermissionsConfig';
import {
    configurationBgPermissions, configurationFacilityPermissions, financialsDeleteAccessPermissions,
    financialsManualChargePemissions, financialsPaymentPermissions, financialsReverseAccessPermissions,
    occupancyMoveInPermissions, occupancyMoveOutPermissions, occupancyReservationPermissions,
    retailSaleInventoryAuditPermissions, retailSaleSellInventoryPermissions, unitGeneralPermissions,
} from '../config/PermissionNameLists';

const useStyles = makeStyles(baseUserRoleStyle, { name: 'UserRoleMainTab' });

const UserRoleMainTab = ({
    onActivateOrDeactivate = {},
    isFormActive,
    roleId,
    formProps = {},
    formProps: {
        values: {
            permission: {
                facility: {
                    financials: {
                        payment: { access: payment = false } = {},
                        charge: {
                            manualCharge: { access: manualCharge = false } = {},
                            delete: { access: deleteAccess = false } = {},
                            reverse: { access: reverseAccess = false } = {},
                        } = {},
                    } = {},
                    unit: { edit: { general = false } = {} } = {},
                    reservation: { access: performReservation = false } = {},
                    configurations: { createEdit: { access: facilityEdit = false } = {} } = {},
                    retailSale: { sell = false, inventoryAudit = false } = {},
                    occupancy: {
                        moveIn: { access: performMoveIn = false } = {},
                        moveOut: { access: performMoveOut = false } = {},
                    } = {},
                } = {},
                businessGroup: { configurations: { createEdit: { access: businessGroupEdit = false } = {} } = {} } = {},
            } = {},
        } = {},
        setFieldValue,
        setFieldTouched,
    } = {},
    isModeCreate,
    isStandardUser = false,
    isSourceInternalDashboard,
    curUserPermRoleIdForCurFacility,
} = {}) => {
    const classes = useStyles();
    const [isMoveInDisabled, setMoveInDisable] = useState(false);
    const [isMoveOutDisabled, setMoveOutDisable] = useState(false);
    const [occupancyConfig, updateOccupancyConfig] = useState(() => occupancyData(
        isMoveInDisabled, isMoveOutDisabled, performReservation,
    ));
    const [financialsConfig, updateFinancialsConfig] = useState(() => financialsData());
    const [retailSaleConfig, updateRetailSaleConfig] = useState(() => retailSaleData());
    const [unitConfig, updateUnitConfig] = useState(() => unitData());
    const [configuration, updateConfiguration] = useState(() => configurationData());
    const isUserEditingOwnRole = useMemo(() => isStandardUser
        && Number(curUserPermRoleIdForCurFacility) === Number(roleId), [curUserPermRoleIdForCurFacility, roleId]);

    const setFieldValueAndTouched = (permissionArray) => {
        permissionArray.forEach((permission) => {
            setFieldValue(permission, false, true);
            setFieldTouched(permission);
        });
    };

    useEffect(() => {
        updateUnitConfig(unitData(general));
        if (!general) {
            setFieldValueAndTouched(unitGeneralPermissions);
        }
    }, [general]);

    useEffect(() => {
        updateConfiguration(configurationData(facilityEdit, businessGroupEdit));
        if (!facilityEdit) {
            setFieldValueAndTouched(configurationFacilityPermissions);
        }
        if (!businessGroupEdit) {
            setFieldValueAndTouched(configurationBgPermissions);
        }
    }, [facilityEdit, businessGroupEdit]);

    useEffect(() => {
        updateRetailSaleConfig(retailSaleData(sell, inventoryAudit));
        if (!sell) {
            setFieldValueAndTouched(retailSaleSellInventoryPermissions);
        }
        if (!inventoryAudit) {
            setFieldValueAndTouched(retailSaleInventoryAuditPermissions);
        }
    }, [sell, inventoryAudit]);

    useEffect(() => {
        updateFinancialsConfig(financialsData(payment, manualCharge, reverseAccess, deleteAccess));

        if (!payment) {
            setFieldValueAndTouched(financialsPaymentPermissions);
        }
        if (!manualCharge) {
            setFieldValueAndTouched(financialsManualChargePemissions);
        }
        if (!reverseAccess) {
            setFieldValueAndTouched(financialsReverseAccessPermissions);
        }
        if (!deleteAccess) {
            setFieldValueAndTouched(financialsDeleteAccessPermissions);
        }
    }, [payment, manualCharge, deleteAccess, reverseAccess]);

    useEffect(() => {
        updateOccupancyConfig(occupancyData(!performMoveIn, !performMoveOut, !performReservation));
        setMoveInDisable(!performMoveIn);
        setMoveOutDisable(!performMoveOut);
        if (!performMoveIn) {
            setFieldValueAndTouched(occupancyMoveInPermissions);
        }
        if (!performMoveOut) {
            setFieldValueAndTouched(occupancyMoveOutPermissions);
        }
        if (!performReservation) {
            setFieldValueAndTouched(occupancyReservationPermissions);
        }
    }, [performMoveOut, performMoveIn, performReservation]);

    return (
        <Grid container direction="column" item xs={12} lg={10} className={classes.mainTab}>
            {
                <Grid container className={classes.roleDisplay}>
                    <FormTextField
                        name={isSourceInternalDashboard ? 'userTypeLabel' : 'name'}
                        GridProps={isSourceInternalDashboard ? SM_6 : SM_2}
                        formVariant={VARIANT.NO_PADDING}
                        required
                        noIndicatorWrapper
                        inputProps={{ className: classes.nameTextField }}
                        readOnly={isUserEditingOwnRole || isSourceInternalDashboard}
                    />
                    {
                        roleId
                        && !isSourceInternalDashboard && !isUserEditingOwnRole && (
                            <Button
                                disableRipple
                                variant="outlined"
                                color={isFormActive ? 'error' : 'primary'}
                                className={classes.statusButton}
                                onClick={onActivateOrDeactivate}
                                disabled={isUserEditingOwnRole}
                            >
                                {isFormActive ? localisable.deactivate : localisable.activate}
                            </Button>
                        )
                    }
                </Grid>
            }
            <Tabs
                tabsData={userRoleTabsConfig({
                    formProps,
                    occupancyConfig,
                    financialsConfig,
                    retailSaleConfig,
                    unitConfig,
                    configuration,
                    isModeCreate,
                    isFormActive,
                    isStandardUser,
                    isUserEditingOwnRole,
                    isSourceInternalDashboard,
                    curUserPermRoleIdForCurFacility,
                })}
                variant="scrollable"
                classes={{ tabsRoot: classes.tabsContainer }}
                indicatorColor="primary"
                childClasses={classes.tabPanel}
                TabIndicatorProps={{ className: classes.tabIndicator }}
            />
        </Grid>
    );
};
export default memo(UserRoleMainTab);
