import Modal from 'Commons/components/generic/modal/components/Modal';
import { Formik, Form } from 'formik';
import { Grid, Loader, withStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { getDiffProperties, isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import BaseForm from 'Commons/components/business/baseform/components/BaseForm';
import Page from 'Commons/components/business/page/components/Page';
import validateForm from 'Commons/helpers/utils/VerifyUser';
import {
    changePassword, changeUserDetails,
    getUserDetails,
} from 'Commons/components/business/userProfile/config/ApiRequests';
import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import { encryptPasswords } from 'Commons/helpers/utils/FormDataHelper';
import ResetPassword from './ResetPassword';
import userProfileStyles from '../styles/UserProfileStyles';
import UserDetails from './UserDetails';

class UserProfile extends BaseForm {
    constructor(props) {
        super(props);
        this.state = { isButtonLoading: false };
        this.onAction = this.props.onAction || null;
        this.requestsInQueue = 0;
        const { facility: { data: { relationalData: { globalEncryptionKey } = {} } = {} } = {} } = props;
        this.globalEncryptionKey = globalEncryptionKey;
    }

    componentDidMount = () => {
        this.fetchUserProfile();
    };

    fetchUserProfile = () => {
        const { onAction, match: { params: { userId } } } = this.props;
        const includeRelations = !this.globalEncryptionKey && { Global_Encryption_Key: {} };
        getUserDetails(onAction, userId, (error, responseData) => this.onUserProfileFetch(error, responseData), includeRelations);
    }

    onUserProfileFetch = (err, res) => {
        if (err) {
            this.handleErrors(err);
        } else if (res) {
            const { data: [userData = {}] = [], relationalData: { globalEncryptionKey } = {} } = res;
            if (!this.globalEncryptionKey) this.globalEncryptionKey = globalEncryptionKey;
            this.setState({ initialValues: userData });
        }
    };

    saveUserDetails = (userDetails) => {
        const { onAction, match: { params: { userId } } } = this.props;
        this.requestsInQueue = this.requestsInQueue + 1;
        this.setState({ isButtonLoading: true });
        changeUserDetails(onAction, userId, userDetails,
            (error, responseData) => this.onEditUserDetails(error, responseData));
    }

    onEditUserDetails = (err, res) => {
        const {
            resetForm = {}, values = {},
            setTouched, setStatus, setErrors,
        } = this.formProps;
        this.requestsInQueue -= 1;
        if (err) {
            this.handleErrors(err, { setTouched, setStatus, setErrors });
            this.hasErrors = true;
        } else if (res) {
            const { data: [updatedData = {}] = [] } = res;
            resetForm(values);
            this.initialValues = values;
            this.setSnackbarProps(true, localisable.successPassword);
            if (!this.requestsInQueue && !this.hasErrors) {
                this.onClose();
            } else {
                this.initialValues = { ...this.initialValues, ...updatedData };
            }
        }
        this.showSnackbar();
    };

    showSnackbar = () => {
        if (!this.requestsInQueue) {
            this.setState({ isButtonLoading: false });
        } else {
            this.setState({ isButtonLoading: true });
        }
    }

    savePassword = async (values) => {
        const { globalEncryptionKey, props: { onAction } = {} } = this;
        this.setState({ isButtonLoading: true });
        if (values.newPassword === values.confirmPassword) {
            this.requestsInQueue = this.requestsInQueue + 1;
            const valuesWithEncryptedPassword = await encryptPasswords(values, globalEncryptionKey);
            changePassword(onAction, valuesWithEncryptedPassword,
                (error, responseData) => this.onChangeUserPassword(error, responseData));
        }
    }

    onChangeUserPassword = (err, res) => {
        const { setFieldValue, setTouched, setStatus, setErrors } = this.formProps;
        this.requestsInQueue -= 1;
        if (err) {
            this.handleErrors(err, { setTouched, setStatus, setErrors });
            this.hasErrors = true;
        } else if (res) {
            this.setSnackbarProps(true, localisable.passwordChanged);
            if (!this.requestsInQueue && !this.hasErrors) {
                this.onClose();
            } else {
                setFieldValue('extra', undefined);
            }
        }
        this.showSnackbar();
    }

    save = () => {
        const {
            initialValues, values: { extra = {}, ...value },
            touched: { extra: ignore, ...touched } = {},
        } = this.formProps;
        this.requestsInQueue = 0;
        this.hasErrors = false;

        this.touchedKeys = deepCopy(touched);
        const { processedValues, propertiesToCheck } = this.preProcessValues(value, touched);
        const userDetails = getDiffProperties(initialValues, processedValues,
            { properties: propertiesToCheck, excludeProperties: this.excludeProperties || undefined });

        if (isObjWithKeys(userDetails)) {
            this.saveUserDetails(userDetails);
        }

        if (isObjWithKeys(extra)) {
            this.savePassword(extra);
        }
    }

    validateFormForSamePassword = (values) => {
        const { phoneNumber, extra = {} } = values;
        const newErrors = {};
        if (phoneNumber && phoneNumber.length > 15) newErrors.phoneNumber = `${localisable.isMaxLength} 15`;
        const passwordErrors = validateForm(extra);
        if (isObjWithKeys(passwordErrors)) newErrors.extra = passwordErrors;
        return newErrors;
    }

    onCancel = () => {
        const { props: { history } = {} } = this;
        history.goBack();
    }

    renderComponent() {
        const {
            state: {
                initialValues: { firstName = '', lastName = '' } = {},
                initialValues,
            },
            props: { classes, facility, userProfile: { data: { data: [userData = {}] = [] } = {} } = {} } = {},
        } = this;
        return (
            <Modal
                onClose={this.onCancel}
                open
            >
                {
                    initialValues
                        ? (
                            <Formik
                                initialValues={initialValues}
                                validate={this.validateFormForSamePassword}
                                onSubmit={this.save}
                                render={(formProps) => {
                                    this.formProps = formProps;
                                    return (
                                        <Page
                                            header={(
                                                <Typography variant="h5">
                                                    {`${firstName} ${lastName}`}
                                                </Typography>
                                            )}
                                            footer={this.getFooter({ classes: { footer: classes.footer } })}
                                            bodyClassName={classes.pageBody}
                                            headerClassName={classes.pageHeader}
                                        >
                                            <Form>
                                                <UserDetails
                                                    facility={facility}
                                                    userData={userData}
                                                />
                                                <ResetPassword />
                                            </Form>
                                        </Page>
                                    );
                                }}
                            />
                        )
                        : (
                            <Grid container justify="center" alignItems="center">
                                <Loader disableShrink />
                            </Grid>
                        )
                }
            </Modal>
        );
    }
}

UserProfile.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};

export default withStyles(userProfileStyles)(UserProfile);
