import localisable from '../strings/localisable';

const IS_MAX_LENGTH_8 = { isMaxLength: 8 };
const IS_MAX_LENGTH_5 = { isMaxLength: 5 };
const IS_MAX_LENGTH_9 = { isMaxLength: 9 };
const IS_MAX_LENGTH_10 = { isMaxLength: 10 };
const IS_MAX_LENGTH_12 = { isMaxLength: 12 };
const IS_MAX_LENGTH_15 = { isMaxLength: 15 };
const IS_MAX_LENGTH_17 = { isMaxLength: 17 };
const IS_MAX_LENGTH_20 = { isMaxLength: 20 };
const IS_MAX_LENGTH_22 = { isMaxLength: 22 };
const IS_MAX_LENGTH_23 = { isMaxLength: 23 };
const IS_MAX_LENGTH_25 = { isMaxLength: 25 };
const IS_MAX_LENGTH_30 = { isMaxLength: 30 };
const IS_MAX_LENGTH_50 = { isMaxLength: 50 };
const IS_MAX_LENGTH_254 = { isMaxLength: 254 };
const IS_MAX_LENGTH_1024 = { isMaxLength: 1024 };
const IS_MAX_LENGTH_100 = { isMaxLength: 100 };
const IS_MIN_LENGTH_3 = { isMinLength: 3 };
const IS_EMAIL = { isEmail: true };
const IS_VALID_ACCESS_CODE = { isValidAccessCode: true };
const IS_VALID_PASSWORDS = { isValidPasswords: true };
const IS_ALPHANUMERIC = { isAlphanumeric: true };
const IS_VALID_FACILITY_ABBREVIATION = { isValidFacilityAbbreviation: true };
const IS_INTEGER = { isInteger: true };
const IS_POSITIVE_NUMERIC = { isPositiveNumeric: true };
const ZIP_CODE_VALIDATOR = { ...IS_ALPHANUMERIC, ...IS_MAX_LENGTH_10 };
const IS_NOT_EMPTY_STRING = { isNotEmptyString: true };
const IS_NON_ZERO_POSITIVE_NUMERIC = { isNonZeroPositiveNumeric: true };
const IS_VALUE_LESS_THAN_10 = { isGreaterThan: { value: 10, errorMessage: localisable.maximumLimitReached } };
const IS_COMMON_PASSWORD = { isCommonPassword: '' };
const IS_MAX_LENGTH_4 = { isMaxLength: 4 };
const IS_MAX_LENGTH_11 = { isMaxLength: 11 };
const IS_MAX_LENGTH_250 = { isMaxLength: 250 };
const IS_VALID_EMAIL_PREFIX = { isAlphanumeric: true };
const TAX_RECEIPT_CODE_VALIDATOR = { ...IS_ALPHANUMERIC, ...IS_MAX_LENGTH_20 };


export {
    IS_MAX_LENGTH_8,
    IS_MAX_LENGTH_9,
    IS_MAX_LENGTH_10,
    IS_MAX_LENGTH_12,
    IS_MAX_LENGTH_15,
    IS_MAX_LENGTH_17,
    IS_MAX_LENGTH_20,
    IS_MAX_LENGTH_22,
    IS_MAX_LENGTH_23,
    IS_MAX_LENGTH_25,
    IS_MAX_LENGTH_30,
    IS_MAX_LENGTH_50,
    IS_MAX_LENGTH_254,
    IS_MAX_LENGTH_1024,
    IS_EMAIL,
    IS_VALID_ACCESS_CODE,
    IS_VALID_PASSWORDS,
    IS_ALPHANUMERIC,
    IS_VALID_FACILITY_ABBREVIATION,
    IS_INTEGER,
    IS_POSITIVE_NUMERIC,
    ZIP_CODE_VALIDATOR,
    IS_NOT_EMPTY_STRING,
    IS_NON_ZERO_POSITIVE_NUMERIC,
    IS_MAX_LENGTH_100,
    IS_VALUE_LESS_THAN_10,
    IS_MAX_LENGTH_5,
    IS_MIN_LENGTH_3,
    IS_COMMON_PASSWORD,
    IS_MAX_LENGTH_4,
    IS_MAX_LENGTH_11,
    IS_MAX_LENGTH_250,
    IS_VALID_EMAIL_PREFIX,
    TAX_RECEIPT_CODE_VALIDATOR,
};
