import React, { memo } from 'react';
import { Breadcrumbs, Grid, makeStyles, Typography } from 'Generic/componentlibrary/components/Components';
import Link from '../../link/components/Link';
import breadCrumbStyle from '../styles/BreadCrumbStyle';

const useStyles = makeStyles(breadCrumbStyle, { name: 'BreadCrumbStyle' });
const Breadcrumb = (props) => {
    const { breadCrumbList = [] } = props;
    const classes = useStyles();
    const getHeader = () => (
        <Breadcrumbs aria-label="breadcrumb">
            {breadCrumbList.map((breadCrumb) => {
                const { name, url } = breadCrumb;
                return (
                    url ? <Link to={url} key={name}>{name}</Link> : <Typography key={name}>{name}</Typography>
                );
            })}
        </Breadcrumbs>
    );

    return (
        breadCrumbList.length ? <Grid className={classes.pageHeading}>{getHeader()}</Grid> : null
    );
};

Breadcrumb.propTypes = { breadCrumbList: PropTypes.array };

export default memo(Breadcrumb);
