import { useEffect, useState } from 'react';
import Link from 'Commons/components/generic/link/components/Link';
import { Grid } from 'Generic/componentlibrary/components/Components';
import ThreeDotProgress from 'Generic/threedotprogress/components/ThreeDotProgress';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';


const DocumentLink = (props) => {
    const {
        text, url, GridProps, intervalDuration,
        retryCount, ...linkProps
    } = props;
    const [isLinkReady, setIsLinkReady] = useState(false);
    const [retry, setRetry] = useState(retryCount);

    useEffect(() => {
        const interval = setInterval(() => {
            if (retry === 0) clearInterval(interval);
            else {
                fetch(url).then((response) => {
                    if (response.ok) {
                        setIsLinkReady(true);
                        clearInterval(interval);
                        setRetry(0);
                    }
                });
                setRetry(retry - 1);
            }
        }, intervalDuration);

        return () => clearInterval(interval);
    }, [retry]);

    return (
        <Grid container item {...GridProps}>
            {
                isLinkReady
                        && (
                            <Link
                                to={url}
                                target="_blank"
                                {...linkProps}
                            >
                                {text}
                            </Link>
                        )
            }
            { !isLinkReady
                && (retry !== 0
                    ? <ThreeDotProgress />
                    : (
                        <Typography>
                            {localisable.takingLongerThanExpected}
                        </Typography>
                    ))
            }
        </Grid>
    );
};

DocumentLink.propTypes = {
    GridProps: PropTypes.object,
    text: PropTypes.string,
    url: PropTypes.string,
    retryCount: PropTypes.number,
    intervalDuration: PropTypes.number,
};

DocumentLink.defaultProps = {
    retryCount: 5,
    intervalDuration: 5000,
};

export default DocumentLink;
