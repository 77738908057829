// Add the store key name below as used in dependency.js

const listTypeConfigs = [
    'unitType', 'chargeCategory', 'taxExemptCategory', 'taxCode', 'paymentMethod', 'violation',
    'letter', 'promoPlan', 'promoPlanTemplate', 'lateEvent', 'maintenanceEvent', 'cardProcessor', 'leaseStyle',
];

const config = [
    'basic',
    'requiredFields',
    'suggestedFields',
    'rentalGeneral',
    'customerGeneral',
    'moveIn',
    'moveOut',
    'financialGeneral',
    'facilityGeneral',
    'customerGeneral',
    'unitGeneral',
    'rentalGeneral',
    'financialPayment',
    'tenantSections',
    'level',
    'inventory',
    'bevColors',
    'leaseGeneral',
    'invoicing',
    'gate',
    'transfer',
    'insurance',
    'insuranceCoverage',
    'tenantWebsites',
    'vehicle',
    'placeholder',
    ...listTypeConfigs,
];

export default config;

export { listTypeConfigs };
