import BaseForm from 'Commons/components/business/baseform/components/BaseForm';
import SmartList from 'Commons/components/business/smartlist/components/SmartList';
import { Grid, Loader, withStyles } from 'Generic/componentlibrary/components/Components';
import { Form, Formik } from 'formik';
import Page from 'Commons/components/business/page/components/Page';
import { clsx } from 'Commons/helpers/utils/clsx';
import { EMPTY_FUNC, EMPTY_OBJECT } from 'Commons/config/constants/Constants';
import ImportData from 'External/containers/onboarding/components/importData/components/ImportData';
import { createRef } from 'react';
import { getDiffProperties, isFunction, isNumber, isObjWithKeys, isValidObject, removeFalseKeys } from 'Commons/helpers/utils/DataHelpers';
import { deepMerge } from 'Commons/helpers/utils/DeepCopy';
import localisable from 'Commons/config/strings/localisable';
import smartListWithFormStyle from '../styles/SmartListWithFormStyle';
import { AUTO_SAVE_INTERVAL, HORIZONTAL_SPACING_SMART_LIST, VERTICAL_SPACING_SMART_LIST } from '../config/Constants';
import { SmartListWithFormDefaultProps, SmartListWithFormProps } from '../config/SmartListWithFormProps';
import {
    getValuesAndTouchedUponDelete,
    geUpdatedTouchedOnAdd,
    resetInvalidRows,
    setInitialValuesTouched,
} from '../utils/Utils';

class SmartListWithForm extends BaseForm {
    constructor(props) {
        super(props);
        this.state = { bodyRef: createRef() };
        this.autoSaveIntervalId = undefined;
    }

    componentDidMount() {
        const {
            formProps: { setFieldValue, setFieldTouched } = {},
            props: { getAddRowFunc, getDeleteRowFunc, autoSave = false, invalidData } = {}, addRow, deleteRow,
        } = this;
        getAddRowFunc(addRow);
        getDeleteRowFunc(deleteRow);
        if (invalidData) {
            setInitialValuesTouched(setFieldValue, setFieldTouched, '', invalidData);
        }
        if (autoSave) this.setAutoSaveInterval();
    }

    componentDidUpdate(prevProps) {
        const { props: { autoSave = false } = {} } = this;
        const { autoSave: prevAutoSave = false } = prevProps;
        if (prevAutoSave !== autoSave) {
            if (autoSave) {
                if (this.autoSaveIntervalId) return;
                this.setAutoSaveInterval();
            } else this.unsetAutoSaveInterval();
        }
    }

    componentWillUnmount() {
        this.unsetAutoSaveInterval();
    }

    setAutoSaveInterval = () => {
        this.autoSaveIntervalId = setInterval(() => {
            this.autoSaving = true;
            this.createEditFormData();
            this.handleSubmit();
        }, AUTO_SAVE_INTERVAL);
    }

    unsetAutoSaveInterval = () => {
        clearInterval(this.autoSaveIntervalId);
        this.autoSaveIntervalId = null;
    }

    getDefaultTableProps = (bodyRef, tableProps = {}) => {
        const { props: { fullHeight } = {} } = this;
        const { deviceInfo: { isDesktop } } = window;
        const { clientHeight = 500, clientWidth = 500 } = bodyRef || {};
        const {
            customHorizontalSpacing = HORIZONTAL_SPACING_SMART_LIST,
            customVerticalSpacing = VERTICAL_SPACING_SMART_LIST,
        } = tableProps;
        return {
            ...fullHeight
            && {
                tableWidth: isDesktop ? clientWidth - customHorizontalSpacing : 1500,
                tableHeight: clientHeight - customVerticalSpacing,
            },
        };
    }

    updateListConfig = (listConfigToUpdate = {}) => {
        const {
            props: { formConfigForColumns = {}, updateDeletedRow, handleAddRowInternally, importSource } = {},
            getDefaultTableProps,
            state: { bodyRef: { current: bodyRef } = {} } = {}, formProps = {}, getCustomData, addRow, deleteRow,
        } = this;
        const {
            columns = [], formatterProps = {}, tableProps = {},
            tablePaperProps = {}, tablePaperProps: { tablePaperComponents = [] } = {},
        } = listConfigToUpdate;
        const updatedColumns = columns.map((column = {}, index) => {
            const { [index]: { formFieldInfo = {} } = {} } = formConfigForColumns;
            return { ...column, formFieldInfo };
        });
        return {
            ...listConfigToUpdate,
            columns: updatedColumns,
            tableProps: {
                ...tableProps,
                ...getDefaultTableProps(bodyRef, tableProps),
            },
            tablePaperProps: {
                ...tablePaperProps,
                tablePaperComponents: [
                    ...tablePaperComponents,
                    ...(importSource
                        ? [{
                            open: () => this.setState({ isImporting: true }),
                            name: `${localisable.import} ${importSource}`,
                            icon: 'cp-import',
                            isVisible: true,
                        }] : []),
                ],
            },
            formatterProps: {
                ...formatterProps,
                formProps,
                ...updateDeletedRow && { updateDeletedRow },
                ...handleAddRowInternally && { openForm: addRow },
                deleteRow,
            },
            getCustomData,
        };
    }

    getFormValues = (values) => {
        if (typeof values === 'object') {
            return Object.values(values).filter(({ deleted }) => !deleted);
        } return values;
    }

    addRow = (indexToInsertOn = 0) => {
        const {
            formProps: { setFieldValue, values = {}, setValues = EMPTY_FUNC, touched = {}, setTouched } = EMPTY_OBJECT,
            props: { handleEditFormData = false },
        } = this;
        const data = this.getFormValues(values);
        data.splice(indexToInsertOn, 0, {});
        let updatedValues;
        if (handleEditFormData) {
            setTouched(geUpdatedTouchedOnAdd(touched));
            updatedValues = Object.assign({}, data);
        } else {
            updatedValues = data;
        }
        setValues(updatedValues);
        if (handleEditFormData) {
            setFieldValue('0', {});
        }
    }

    deleteRow = (indexToDeleteFrom = 0) => {
        const {
            formProps: {
                resetForm, values = {}, setFieldValue, initialValues,
                setValues = EMPTY_FUNC, touched = {}, setTouched,
            } = EMPTY_OBJECT,
            props: { handleEditFormData },
        } = this;
        const data = this.getFormValues(values);
        if (handleEditFormData) {
            const initialList = isValidObject(initialValues) ? Object.values(initialValues) : initialValues;
            this.createEditFormData();
            const [
                resetFormValue,
                valueToSet,
                touchedData,
            ] = getValuesAndTouchedUponDelete(indexToDeleteFrom, data, initialList, this.editFormData, touched);
            resetForm(resetFormValue);
            setValues(valueToSet);
            setTouched(touchedData);
            setFieldValue('extra.deleted', true);
        } else {
            const updatedValues = data.filter((_, index) => indexToDeleteFrom !== index);
            setValues(updatedValues);
        }
    }

    getCustomData = () => {
        const { formProps: { values = {} } = {} } = this;
        let listValues = [];
        if (isValidObject(values)) {
            Object.keys(values).forEach((key) => {
                if (isNumber(key)) {
                    listValues.push(values[key]);
                }
            });
        } else {
            listValues = values;
        }
        return listValues;
    }

    getPageBodyRef = (ref) => {
        const { current: bodyRef } = ref || {};
        const { modifiedListConfig: { tableProps = {} } = {}, getDefaultTableProps } = this;
        this.modifiedListConfig = {
            ...this.modifiedListConfig,
            tableProps: {
                ...tableProps,
                ...getDefaultTableProps(bodyRef, tableProps),
            },
        };
        this.setState({ bodyRef: ref });
    }

    getListConfig = (currentFacility) => {
        const { props: { listConfig } = {}, modifiedListConfig = {} } = this;
        const isListConfigOfFuncType = isFunction(listConfig);
        if (
            !isObjWithKeys(modifiedListConfig) // For first time
            || (isListConfigOfFuncType) // For func type configs, only on arg value change
        ) {
            const listConfigToUpdate = isListConfigOfFuncType ? listConfig(currentFacility) : listConfig;
            this.modifiedListConfig = this.updateListConfig(listConfigToUpdate);
        }
        return this.modifiedListConfig;
    }

    toggleButtonLoadingState = (updatedValues, indexesToSet, toggleCallback = EMPTY_FUNC) => {
        if (updatedValues) {
            const { values = {}, setValues, resetForm, setFieldValue, setFieldTouched } = this.formProps;
            resetForm(updatedValues);
            setValues(deepMerge(values, updatedValues));
            resetInvalidRows(setFieldValue, setFieldTouched, '', values, indexesToSet);
            const isAutoSaving = this.autoSaving;
            this.autoSaving = false;
            toggleCallback(isAutoSaving);
        }
        this.setState(prevProps => ({ isButtonLoading: !prevProps.isButtonLoading }));
    }

    handleSubmit = () => {
        const { editFormData, props: { onSubmit }, toggleButtonLoadingState, formProps: { dirty, values } } = this;
        if (dirty) {
            onSubmit(values, editFormData, toggleButtonLoadingState);
        }
    }

    onImport = (importedData) => {
        this.setState({ isImporting: false }, () => {
            const {
                props: { invalidData = {}, onSubmit, preProcessImportedData },
                formProps: { values, setFieldValue, setFieldTouched, touched: currentTouched, initialValues },
                getFormValues, preProcessValues, toggleButtonLoadingState,
            } = this;
            let processedImportedData = importedData;
            if (preProcessImportedData) {
                processedImportedData = preProcessImportedData(importedData);
            }
            const formValues = getFormValues(values);
            const importedTouched = setInitialValuesTouched(setFieldValue, setFieldTouched, '',
                removeFalseKeys(Object.assign({}, [...formValues.map(() => (null)), ...processedImportedData])));
            const dirtyTouched = setInitialValuesTouched(setFieldValue, setFieldTouched, '', invalidData);
            const updatedValues = [
                ...Object.values(invalidData),
                ...formValues.slice(Object.keys(invalidData).length),
                ...processedImportedData,
            ];
            const updatedTouched = { ...currentTouched, ...importedTouched, ...dirtyTouched };
            const { processedValues, propertiesToCheck } = preProcessValues(updatedValues, updatedTouched);
            const editFormData = getDiffProperties(initialValues, processedValues, {
                properties: propertiesToCheck,
                excludeProperties: this.excludeProperties || undefined,
            });
            this.autoSaving = true;
            onSubmit(updatedValues, editFormData, toggleButtonLoadingState);
        });
    }

    onImpostClose = () => {
        this.setState({ isImporting: false }, () => {
            const {
                props: { invalidData = {} },
                formProps: { setFieldValue, setFieldTouched },
            } = this;
            setInitialValuesTouched(setFieldValue, setFieldTouched, '', invalidData);
        });
    }

    renderComponent = () => {
        const {
            getListConfig, getFooter, getPageBodyRef, handleSubmit, onImport, onImpostClose,
            props: {
                fullHeight, ContainerProps: { footer = getFooter, ...ContainerProps }, classes, initialValues, onSubmit,
                listConfig, title, FormikProps, getFormProps, footerProps, pageKey, isInitialValid, importSource, ...props
            } = {},
            state: { isImporting },
        } = this;
        return (
            isImporting
                ? (
                    <ImportData
                        onBack={onImpostClose}
                        source={importSource}
                        onImportSuccess={onImport}
                    />
                )
                : (
                    <>
                        {
                            initialValues ? (
                                <Formik
                                    initialValues={initialValues}
                                    {...onSubmit && { onSubmit: handleSubmit }}
                                    isInitialValid={isInitialValid}
                                    {...FormikProps}
                                    render={(formProps) => {
                                        const { values = {} } = formProps;
                                        this.formProps = formProps;
                                        if (getFormProps) {
                                            getFormProps(formProps);
                                        }
                                        return (
                                            <Form className={clsx({ [classes.fullHeight]: fullHeight }, classes.form)}>
                                                <Page
                                                    fullHeight={fullHeight}
                                                    footer={footer(footerProps)}
                                                    getBodyRef={getPageBodyRef}
                                                    getFormRef={() => { this.setState({ formRef: formProps }); }}
                                                    key={pageKey}
                                                    {...title && { header: title }}
                                                    {...ContainerProps}
                                                >
                                                    <SmartList
                                                        listConfig={getListConfig}
                                                        key={Object.keys(values).filter(key => isNumber(key)).length}
                                                        {...props}
                                                    />
                                                </Page>
                                            </Form>
                                        );
                                    }}
                                />
                            )
                                : (
                                    <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
                                        <Loader disableShrink />
                                    </Grid>
                                )
                        }
                    </>
                )
        );
    }
}

SmartListWithForm.propTypes = SmartListWithFormProps;

SmartListWithForm.defaultProps = SmartListWithFormDefaultProps;

export default withStyles(smartListWithFormStyle)(SmartListWithForm);
