import { mapEnum } from 'Commons/helpers/utils/Formatter';
import enums from 'Commons/config/constants/Enums';

const MAX_VISIBLE_CONTENT = 1;
const MAX_VISIBLE_CONTENT_IN_ROW = 2;
const VIEW = mapEnum(enums.view);


export {
    MAX_VISIBLE_CONTENT,
    VIEW,
    MAX_VISIBLE_CONTENT_IN_ROW,
};
