class EventEmitter {
    constructor() {
        this.events = {};
    }

    emit(eventName, data) {
        const eventsList = this.events[eventName];
        if (eventsList) {
            eventsList.forEach((event) => {
                event.call(null, data);
            });
        }
    }

    subscribe(eventName, fn) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }

        this.events[eventName].push(fn);
        return () => {
            this.events[eventName] = this.events[eventName].filter(eventFn => fn !== eventFn);
        };
    }
}
const eventEmitter = new EventEmitter();
export default eventEmitter;
