import { Typography } from 'Generic/componentlibrary/components/Components';
import HighlightAutoSuggestText from 'Commons/components/business/autosuggest/components/HighlightSelectedText';
import { useMemo, useCallback, memo } from 'react';
import getName from '../../../../../helpers/utils/NameHelper';

/**
 * firstName - string
 * lastName - string
 * salutation - string
 * suffix - string
 * className - string
 */
const Name = ({
    firstName,
    lastName,
    businessName,
    suffix,
    preferredName,
    salutation,
    autoSuggestText,
    disabled,
    ...others
}) => {
    const nameArray = useMemo(
        () => getName({ firstName, lastName, suffix, salutation, businessName, preferredName }).split(/( )/),
        [firstName, lastName, businessName, suffix, preferredName, salutation],
    );

    const renderNameArray = useCallback(() => nameArray.map((name, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <HighlightAutoSuggestText key={`Name-${name}-${index}`} autoSuggestText={autoSuggestText} disabled={disabled}>
            {name}
        </HighlightAutoSuggestText>
    )), [firstName, lastName, businessName, suffix, preferredName, salutation]);

    return (
        <Typography
            noWrap
            component="span"
            {...others}
        >
            {renderNameArray()}
        </Typography>
    );
};

Name.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    salutation: PropTypes.string,
    suffix: PropTypes.string,
    businessName: PropTypes.string,
    preferredName: PropTypes.string,
    autoSuggestText: PropTypes.string,
    disabled: PropTypes.bool,
};

Name.defaultProps = {
    firstName: '',
    lastName: '',
    salutation: '',
    suffix: '',
    businessName: '',
    autoSuggestText: '',
    disabled: false,
};

export default memo(Name);
