const infoCardStyles = theme => ({
    cardTopMargin: { marginTop: theme.spacing(2) },
    infoCardContainer: {
        zIndex: 50,
        position: 'absolute',
        right: 0,
        top: 0,
        width: 306,
        height: 'auto',
        background: theme.palette.common.white,
        borderRadius: 4,
        boxShadow: ({ showInfoCard }) => (showInfoCard ? theme.boxShadows[5] : ''),
        transform: 'translateY(-100%)',
        transition: 'transform .2s ease-in',
        padding: theme.spacing(1, 1, 2, 2),
    },
    infoContainer: {
        overflow: 'auto',
        maxHeight: ({ canvasGridHeight }) => (canvasGridHeight - 92),
    },
    infoCardCloseButton: {
        position: 'absolute',
        right: 8,
        top: 12,
        maxWidth: 24,
        maxHeight: 24,
        minWidth: 24,
        minHeight: 24,
    },
    infoCardShow: { transform: 'translateY(0)' },
    cardTitle: { marginBottom: theme.spacing(4) },
});

export default infoCardStyles;
