import { addToDate } from 'Commons/helpers/utils/DateTime';
import { WEEKDAYS_SUNDAY_FIRST } from 'External/containers/pendingTasks/components/reminders/config/Constants';

const addDatePadding = currentDay => (currentDay < 10 && currentDay > 0 ? `0${currentDay}` : `${currentDay}`);

/**
 * Rearrange the weekdays list based on the starting day index.
 *
 * @param {Number} [startDay=0] - The starting day of the week (0: Sunday, 1: Monday, ...). Default: 0 (Sunday).
 * @returns {Array} - The rearranged array of weekdays.
 */
const rearrangeWeekdays = (startDay = 0) => {
    // Rearrange the weekdays list based on the starting day index
    const weekdays = Object.values(WEEKDAYS_SUNDAY_FIRST);
    const reorderedWeekdays = [
        ...weekdays.slice(startDay),
        ...weekdays.slice(0, startDay),
    ];
    return reorderedWeekdays;
};

/**
 * @description returns complete date after adding/substracting from date
 * @param {Date} date date/dateObject
 * @param {Number} days days to add or subtract
 * */
const getCompleteDate = (date, days, convertToString = true) => {
    const completeDate = addToDate(date, days, undefined, convertToString);
    return completeDate;
};

const getDataForDay = (data = {}, completeDate) => data[completeDate] || [];

export { addDatePadding, rearrangeWeekdays, getCompleteDate, getDataForDay };
