import { b64Encode, b64Decode } from 'Commons/helpers/utils/DataHelpers';

const stringToArrayBuffer = (string) => {
    /**
     * Convert String to Array Buffer
     * @param {String} string String to Convert
     */
    const buf = new ArrayBuffer(string.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = string.length; i < strLen; i += 1) {
        bufView[i] = string.charCodeAt(i);
    }
    return buf;
};


/**
 * Array Buffer to String
 * @param {ArrayBuffer} arrayBuffer ArrayBuffer to Convert
 */
const arrayBufferToString = arrayBuffer => String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));


const encryptWithPublicKey = async (plainText, publicKey, encodeBase64 = false) => {
    /**
     * Util to encrypt the data with given public key
     * @param {String} plainText Text to be encrypted
     * @param {String} publicKey Facility's Public Key
     */

    const publicKeyHeader = '-----BEGIN PUBLIC KEY-----';
    const publicKeyFooter = '-----END PUBLIC KEY-----';
    const publicKeyContent = publicKey.substring(publicKeyHeader.length,
        publicKey.length - publicKeyFooter.length - 1);

    const binaryString = b64Decode(publicKeyContent); // Decode the string to get the binary data
    const publicKeyBuffer = stringToArrayBuffer(binaryString); // Convert from a binary string to an ArrayBuffer

    const dataBuffer = stringToArrayBuffer(plainText); // Convert the plain text to Array Buffer

    const encrypted = await window.crypto.subtle.importKey(
        'spki', // SubjectPublicKeyInfo
        publicKeyBuffer,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256',
        },
        false,
        ['encrypt'],
    ).then(importedKey => window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256',
        },
        importedKey,
        dataBuffer,
    ));

    const encryptedString = arrayBufferToString(encrypted);
    return encodeBase64 ? b64Encode(encryptedString) : encryptedString;
};


export { encryptWithPublicKey };
