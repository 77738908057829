import { GATE_TYPE, GATE_VENDOR } from 'External/containers/facilityConfiguration/config/GateConfig';
import { memo } from 'react';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Commons/components/generic/typography/components/Typography';
import { FormTextField } from 'Commons/components/generic/textfield/components/TextField';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import localisable from 'Commons/config/strings/localisable';
import Icon from 'Commons/components/generic/icon/components/Icon';
import { generateRandomNumber } from 'Commons/helpers/utils/RandomNumberGenerator';
import { clsx } from 'Commons/helpers/utils/clsx';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import accessCodeFieldStyle from '../styles/AccessCodeFieldStyle';

const useStyles = makeStyles(accessCodeFieldStyle, { name: 'AccessCodeField' });

const AccessCodeField = ({
    title, required, containerProps, hideAutoGenerateText, accessCodesMap: allAccessCodes,
    setFieldValue, name, currentAccessCode, unitsMapOfSameTenant, setFieldTouched, className, labelWithIconClassName,
    excludeFromValidation, tenantId, unitId, hideAutoGenerateButton = false, labelWithIconTooltipClassName,
    readOnly, gateConfig: {
        vendor = GATE_VENDOR.NONE.value, type: gateType,
        accessCode: { validRange: { start = 0, end = 1 } = {}, allowReuse, validRange, length, type: accessCodeType } = {},
    }, ...props
}) => {
    const classes = useStyles(props);

    const validator = useConstructor(() => {
        const { relationalData: relationalAccessCodeData = {}, ...accessCodesMap } = allAccessCodes;

        return {
            isValidGateAccessCode: {
                value: {
                    reusePolicy: allowReuse,
                    accessCodesMap,
                    relationalAccessCodeData,
                    unitsMapOfSameTenant,
                    validRange,
                    length,
                    tenantId,
                    unitId,
                    accessCodeType,
                    isLedgerBasedGate: gateType === GATE_TYPE.Ledger_Based.value,
                },
            },
        };
    });

    const regenerateAccessCode = () => {
        const regeneratedAccessCode = generateRandomNumber(start, end, allAccessCodes);
        setFieldValue(name, regeneratedAccessCode);
        setFieldTouched(name, true);
    };

    const renderAccessCodeField = () => (
        <>
            {title && <Typography variant="body1" className={classes.title}>{title}: </Typography>}
            <FormTextField
                noGrid
                containerClassName={classes.autoWidth}
                noIndicatorWrapper={!required}
                required={!readOnly && required}
                name={name}
                {...!readOnly && { validate: validator }}
                readOnly={readOnly}
                {...props}
            />
            {
                !hideAutoGenerateButton && (
                    <Tooltip
                        title={localisable.autoGenerate}
                        className={labelWithIconTooltipClassName}
                    >
                        <LabelWithIcon
                            className={clsx(classes.autoWidth, classes.labelWithIcon, labelWithIconClassName)}
                            addon={{ start: <Icon type="custom" icon="cp-reset" color="primary" display="block" /> }}
                            onClick={regenerateAccessCode}
                        >
                            {
                                !hideAutoGenerateText
                                && <Typography variant="body1" color="primary">{localisable.autoGenerate}</Typography>
                            }
                        </LabelWithIcon>
                    </Tooltip>
                )
            }
        </>
    );

    if (vendor === GATE_VENDOR.NONE.value) {
        return null;
    }

    return (
        <Grid container alignItems="flex-start" {...containerProps} className={className}>
            {renderAccessCodeField()}
        </Grid>
    );
};

AccessCodeField.propTypes = {
    title: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    gateConfig: PropTypes.object,
    excludeFromValidation: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]), // Access code will not be validated against this value
    containerProps: PropTypes.object,
    accessCodesMap: PropTypes.object,
    name: PropTypes.string.isRequired,
    hideAutoGenerateText: PropTypes.bool,
    hideAutoGenerateButton: PropTypes.bool,
    unitsMapOfSameTenant: PropTypes.object,
    tenantId: PropTypes.string,
    unitId: PropTypes.string,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    className: PropTypes.string,
    labelWithIconClassName: PropTypes.string,
    labelWithIconTooltipClassName: PropTypes.string,
    currentAccessCode: PropTypes.string,
};

AccessCodeField.defaultProps = {
    className: '',
    gateConfig: {},
    required: false,
    readOnly: false,
    excludeFromValidation: '',
    containerProps: {},
    accessCodesMap: {},
    unitsMapOfSameTenant: {},
    hideAutoGenerateButton: false,
};

export default memo(AccessCodeField);
