
const occupancyCardStyles = theme => ({
    cardChip: {
        color: 'primary',
        position: 'absolute',
        bottom: theme.spacing(2.5),
        right: theme.spacing(1),
        width: 'auto',
    },
    chipLabel: { textTransform: 'capitalize', fontWeight: 400 },
    card: { cursor: 'pointer', border: `1px solid ${theme.palette.black[500]}` },
    typography: { fontWeight: 600 },
});
export default occupancyCardStyles;
