import { Form, Formik } from 'formik';
import BaseForm from 'Commons/components/business/baseform/components/BaseForm';
import { Grid, Loader, Typography } from 'Commons/components/generic/componentlibrary/components/Components';
import Page from 'Commons/components/business/page/components/Page';
import { deepMerge, flattenObject, isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import localisable from 'Commons/config/strings/localisable';
import Icon from 'Commons/components/generic/icon/components/Icon';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import Button from 'Generic/button/components/Button';
import { DASHBOARD_TYPE, SOURCE, STATUS, WS_EVENT_TYPE } from 'Commons/config/constants/Constants';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import { isInternalDashboard, isSyrasoftUser } from 'Commons/helpers/utils/Utils';
import INTERNAL_ROUTES from 'Internal/redux/config/RouteNames';
import { ROUTE } from 'External/redux/config/RouteNames';
import UserRoleSideBar from 'Commons/components/business/baseUserRole/components/UserRoleSideBar';
import UserRoleMainTab from 'Commons/components/business/baseUserRole/components/UserRoleMainTab';

class BaseUserRole extends BaseForm {
    constructor(props) {
        super(props);
        const {
            match: { url },
            currentFacility: { data: { permissionRoleId: curUserPermRoleIdForCurFacility } = {} } = {},
        } = props;
        this.state = {
            userRoles: {},
            allRoles: {},
            isSearchbarOpened: false,
            shouldShowAlert: false,
            receivedWSMessage: false,
            selectedId: undefined,
            selectedUserType: undefined,
        };
        this.curUserPermRoleIdForCurFacility = curUserPermRoleIdForCurFacility;
        this.isStandardUser = !isSyrasoftUser();
        this.isSourceInternalDashboard = isInternalDashboard(url);
        this.dashboardType = this.isSourceInternalDashboard ? DASHBOARD_TYPE.INTERNAL : DASHBOARD_TYPE.SETTINGS;
        this.baseURL = this.isSourceInternalDashboard ? INTERNAL_ROUTES.SYRASOFT_USER_ROLES : ROUTE.USER_ROLE;
        this.unblock = this.allowNavigation(this.shouldLeave);
    }

    onCancel = () => {
        const { formProps: { dirty } = {}, props: { history } = {}, areChangesMade = false, isCreateRole } = this;
        if (isCreateRole() ? dirty && areChangesMade : dirty) {
            this.targetLocation = '';
            this.setState({ discard: true });
        } else {
            history.goBack();
        }
    }

    componentDidUpdate() {
        this.unblock = this.allowNavigation(this.shouldLeave);
    }

    componentWillUnmount() {
        const { unblock = () => { }, props: { clearStoreKeys = () => {} } = {} } = this;
        unblock();
        if (this.shouldClearStoreKey) {
            clearStoreKeys(['userRole']);
        }
    }

    preProcessRoles = () => {}

    handleInitialRouteChange = () => {
        const {
            history, location: { pathname } = {},
            match: { params: { userType: userTypeFromParams, roleId, accountId } = {} },
        } = this.props;
        const {
            dashboardType, urlBuilderName,
            urlBuilderNameForCreate,
            urlBuilderNameForEdit,
            state: {
                userRoles,
                selectedUserType,
                selectedId,
            },
        } = this;
        const isBasePath = pathname === buildUrl(urlBuilderName, undefined, dashboardType, accountId);

        if ((isBasePath || roleId || userTypeFromParams) && isObjWithKeys(userRoles)) {
            const { [selectedUserType || selectedId]: { status, ...rest } = {} } = userRoles;
            if (isBasePath) {
                history.replace(buildUrl(urlBuilderNameForEdit,
                    { selectedId, selectedUserType },
                    dashboardType, accountId));
            }
            this.initialValues = { ...rest };
            this.initialStatus = { formStatus: this.isSourceInternalDashboard ? STATUS.Active.value : status };
        } else if (!this.isSourceInternalDashboard) {
            history.replace(buildUrl(urlBuilderNameForCreate, undefined, dashboardType, accountId));
            this.state.selectedId = '';
            this.initialValues = { permission: { account: {} } };
            this.initialStatus = { formStatus: STATUS.Active.value };
        }
    }

    createOrUpdateUserRole = () => {}

    copyRoleName = () => {
        const { dirty } = this.formProps;
        if (dirty) {
            this.nextIdToSelect = localisable.copy;
            this.setState({ discard: true });
        } else {
            this.selectOrCopyRole(localisable.copy);
        }
    }

    resetCurrentForm = () => {
        const { initialValues, formProps: { resetForm, setStatus } = {} } = this;
        resetForm(initialValues);
        setStatus({ formStatus: STATUS.Active.value });
    }

    onCreateRole = () => {
        const {
            dashboardType,
            urlBuilderNameForCreate,
            formProps: { dirty } = {},
            props: { match: { params: { accountId } = {} } = {} } = {},
        } = this;
        if (dirty) {
            this.targetLocation = buildUrl(urlBuilderNameForCreate, undefined, dashboardType, accountId);
            this.setState({ discard: true });
        } else {
            this.createRole();
        }
    }

    createRole = () => {
        const { history, match: { params: { accountId } = {} } = {} } = this.props;
        this.initialValues = {};
        this.resetCurrentForm();
        this.setState({ selectedId: '' });
        history.replace(buildUrl(this.urlBuilderNameForCreate, undefined, this.dashboardType, accountId));
    }

    onSearch = (value) => {
        const { allRoles } = this.state;
        let filteredRoles = allRoles;
        if (value) {
            const lowerValue = value.toLowerCase();
            filteredRoles = Object.values(allRoles).reduce((updatedRoles, role) => {
                const { id, name, userType } = role;
                const roleName = this.isSourceInternalDashboard ? userType : name;
                if ((roleName).toLowerCase().includes(lowerValue)) {
                    return {
                        ...updatedRoles,
                        [userType || id]: {
                            id,
                            ...role,
                        },
                    };
                }
                return updatedRoles;
            }, {});
        }
        this.setState({ userRoles: filteredRoles });
    }

    clearSearch = () => {
        const { allRoles } = this.state;
        this.setState({ userRoles: allRoles });
    }

    onUserRoleUpdate = (error, response) => {
        let updatedState = { isButtonLoading: false };
        const { setErrors, setTouched, setStatus } = this.formProps;
        const actions = { setErrors, setTouched, setStatus };
        if (error) {
            this.handleErrors(error, actions);
        } else if (response) {
            const { data: [data = {}] = [] } = response;
            const { userRoles, selectedId, selectedUserType, allRoles } = this.state;
            const { resetForm } = this.formProps;
            const key = this.isSourceInternalDashboard ? selectedUserType : selectedId;
            const { status, ...updatedRole } = deepMerge(userRoles[key], data);
            this.initialValues = { ...updatedRole };
            this.initialStatus = { formStatus: this.isSourceInternalDashboard ? STATUS.Active.value : status };
            resetForm(this.initialValues);
            this.setSnackbarProps(true, localisable.roleUpdated);
            if (!this.shouldClearStoreKey) {
                this.shouldClearStoreKey = true;
            }
            updatedState = {
                ...updatedState,
                userRoles: {
                    ...userRoles,
                    [key]: { ...userRoles[key], ...updatedRole, status },
                },
                allRoles: {
                    ...allRoles,
                    [key]: { ...userRoles[key], ...updatedRole, status },
                },
            };
        }
        this.setState(updatedState);
    }

    onStatusChange = actions => (apiError, response) => {
        let updatedState = { loading: false };
        if (apiError) {
            this.handleErrors(apiError, actions);
        } else if (response) {
            const { success } = response;
            const {
                formProps: { status, setStatus } = {}, isFormActive,
                state: { selectedId, userRoles, allRoles } = {},
            } = this;
            const updatedStatus = !isFormActive() ? STATUS.Active.value : STATUS.Inactive.value;
            if (success) {
                setStatus({
                    ...status,
                    formStatus: updatedStatus,
                });
                updatedState = {
                    ...updatedState,
                    userRoles: { ...userRoles, [selectedId]: { ...userRoles[selectedId], status: updatedStatus } },
                    allRoles: { ...allRoles, [selectedId]: { ...allRoles[selectedId], status: updatedStatus } },
                    status: updatedStatus,
                };
            }
        }
        this.setState(updatedState);
    }

    onUserRoleCreate = (err, response) => {
        let updatedState = { isButtonLoading: false };
        if (err) {
            this.handleErrors(err);
        } else if (response) {
            const {
                state: { userRoles, allRoles },
                props: { history, match: { params: { accountId } = {} } = {} },
                formProps: { resetForm },
                urlBuilderNameForEdit,
            } = this;
            const { data: [{ id, status, ...data } = {}] = [] } = response;
            this.initialValues = { id, ...data };
            this.initialStatus = { formStatus: status };
            resetForm(this.initialValues);
            updatedState = {
                ...updatedState,
                userRoles: { ...userRoles, [id]: { id, status, ...data } },
                allRoles: { ...allRoles, [id]: { id, status, ...data } },
                selectedId: id,
            };
            this.setSnackbarProps(true, localisable.roleCreated);
            if (!this.shouldClearStoreKey) {
                this.shouldClearStoreKey = true;
            }
            history.replace(buildUrl(urlBuilderNameForEdit, { selectedId: id }, DASHBOARD_TYPE.SETTINGS, accountId));
        }
        this.setState(updatedState);
    }

    shouldLeave = () => {
        const { formProps: { dirty } = {}, targetLocation, areChangesMade } = this;
        if ((this.isCreateRole() ? areChangesMade : dirty) && !targetLocation.includes(this.baseURL)) {
            this.setState({ discard: true }, () => { });
            return false;
        }
        return true;
    }

    isModeCreate = () => {
        const { state: { selectedId } = {} } = this;
        return !selectedId;
    }

    selectOrCopyRole = (idOrUserType) => {
        const {
            state: { allRoles, selectedId } = {},
            props: { history, match: { params: { accountId } = {} } = {} } = {},
            formProps: { resetForm, setStatus, status, setFieldValue } = {},
            dashboardType,
            urlBuilderNameForCreate,
            urlBuilderNameForEdit,
        } = this;
        const isCopying = idOrUserType === localisable.copy;
        this.nextIdToSelect = '';
        const { status: currentStatus, name, ...role } = allRoles[isCopying ? selectedId : idOrUserType];
        this.initialValues = { ...role, name };
        resetForm(this.initialValues);
        const formStatusToSet = isCopying ? STATUS.Active.value : currentStatus;
        setStatus({ ...status, formStatus: this.isSourceInternalDashboard ? STATUS.Active.value : formStatusToSet });
        const selectedIdToSet = isCopying ? '' : idOrUserType;

        this.setState({
            selectedId: this.isSourceInternalDashboard ? undefined : selectedIdToSet,
            selectedUserType: this.isSourceInternalDashboard ? idOrUserType : undefined,
        });

        if (isCopying) {
            history.replace(buildUrl(urlBuilderNameForCreate, undefined, dashboardType, accountId));
            setFieldValue('name', `${name}-${localisable.copy}`);
        } else {
            history.replace(buildUrl(urlBuilderNameForEdit,
                { selectedId: idOrUserType, selectedUserType: idOrUserType },
                dashboardType, accountId));
        }
    }

    onRoleSelection = (idOrUserType) => {
        const { dirty } = this.formProps;
        const { areChangesMade, isCreateRole } = this;
        this.nextIdToSelect = idOrUserType;
        if (isCreateRole() ? areChangesMade : dirty) {
            this.setState({ discard: true });
        } else {
            this.selectOrCopyRole(idOrUserType);
        }
    }

    handleYes = () => {
        const {
            nextIdToSelect, targetLocation,
            urlBuilderNameForCreate,
            dashboardType,
            props: { history, match: { params: { accountId } = {} } = {} } = {},
        } = this;
        if (nextIdToSelect) {
            this.selectOrCopyRole(nextIdToSelect);
        } else {
            this.shouldChangeRoute = true;
            if (targetLocation === buildUrl(urlBuilderNameForCreate, undefined, dashboardType, accountId)) {
                this.createRole();
                return;
            }
            if (targetLocation) {
                history.push(targetLocation);
            } else {
                history.goBack();
            }
        }
    }

    shouldUpdateRTNSSnackbarActions = (wsMessage = {}) => {
        const { eventType } = wsMessage;
        return eventType === WS_EVENT_TYPE.CREATED;
    }

    handleWsMessage = (message = {}) => {
        this.onWebSocketMessage(message);
    }

    renderHeader = (pageTitlePrefix) => {
        const { classes, match: { params: { roleId } = {} } = {} } = this.props;
        return roleId || this.isSourceInternalDashboard
            ? (
                <Grid container direction="row">
                    <Typography variant="h5">
                        {pageTitlePrefix}
                    </Typography>
                    {!this.isSourceInternalDashboard && (
                        <Icon
                            icon="cp-add"
                            type="custom"
                            onClick={this.onCreateRole}
                            className={classes.createIcon}
                        />
                    )}
                </Grid>
            )
            : (
                <Grid container>
                    <Typography variant="h5">
                        {localisable.createRole}
                    </Typography>
                </Grid>
            );
    }

    isCreateRole = () => this.props.location.pathname.includes('create');

    renderComponent() {
        const {
            isStandardUser = false,
            initialValues,
            initialValues: { name, userType } = {},
            initialStatus, isFormActive, showAlertDialog, onCancel,
            onAlertClose,
            renderHeader,
            isModeCreate,
            formProps: { dirty },
            state: { shouldShowAlert, discard = false, loading = false, selectedId, selectedUserType },
            props: { classes, match: { params: { roleId } = {} } = {} },
            curUserPermRoleIdForCurFacility,
            isCreateRole,
            areChangesMade,
        } = this;
        /*
           -- For reference --
           userType - For non-std perm - user type
           selectedUserType - For non-std perm when a role is selected
           name - For std perm - role name
           selectedId - For std perm when a role is selected
        */
        const roleName = name || userType;
        const pageTitlePrefix = this.isSourceInternalDashboard ? localisable.syrasoftUserRoles : localisable.userRole;
        return (
            initialValues
                ? (
                    <Formik
                        initialValues={initialValues}
                        initialStatus={initialStatus}
                        onSubmit={this.createOrUpdateUserRole}
                        render={(formProps) => {
                            const { status: { formStatus } = {}, values: formValues, dirty: formDirty } = formProps;
                            if (formDirty) {
                                const flattenValues = flattenObject(formValues);
                                this.areChangesMade = Object.values(flattenValues).some(value => value);
                            }
                            this.formProps = formProps;
                            return (
                                <Form className={classes.form}>
                                    <Page
                                        header={renderHeader(pageTitlePrefix)}
                                        footer={this.getFooter()}
                                        fullHeight
                                        bodyClassName={classes.pageBody}
                                        loading={loading}
                                        title={`${pageTitlePrefix}${roleName ? ` - ${roleName}` : ''}`}
                                    >
                                        <UserRoleSideBar
                                            userRoles={this.state.userRoles}
                                            onRoleSelection={this.onRoleSelection}
                                            selectedRoleKey={this.isSourceInternalDashboard
                                                ? selectedUserType
                                                : selectedId}
                                            copyRoleName={this.copyRoleName}
                                            onSearch={this.onSearch}
                                            clearSearch={this.clearSearch}
                                            isSourceInternalDashboard={this.isSourceInternalDashboard}
                                        />
                                        <UserRoleMainTab
                                            key={`${formStatus}-${roleId}`}
                                            roleId={roleId}
                                            formProps={formProps}
                                            isFormActive={isFormActive()}
                                            isModeCreate={isModeCreate()}
                                            isStandardUser={isStandardUser}
                                            onActivateOrDeactivate={showAlertDialog}
                                            isSourceInternalDashboard={this.isSourceInternalDashboard}
                                            curUserPermRoleIdForCurFacility={curUserPermRoleIdForCurFacility}
                                        />
                                        <AlertDialog
                                            open={shouldShowAlert || (isCreateRole() ? (discard && dirty && areChangesMade) : (discard && dirty))}
                                            onClose={onAlertClose || onCancel}
                                            title={localisable.warning}
                                            actions={(
                                                <Button
                                                    onClick={() => this.onAlertYesAction(roleId, SOURCE.userRole.value)}
                                                    variant="text"
                                                >
                                                    {localisable.ok}
                                                </Button>
                                            )}
                                        >
                                            {this.getAlertMessage(SOURCE.userRole.value)}
                                        </AlertDialog>
                                    </Page>
                                </Form>
                            );
                        }
                        }
                    />
                ) : (
                    <Grid container justify="center" alignItems="center" className={classes.loader}>
                        <Loader disableShrink />
                    </Grid>
                )
        );
    }
}

BaseUserRole.propTypes = {
    onAction: PropTypes.func,
    userRole: PropTypes.object,
    history: PropTypes.object.isRequired,
};

export default BaseUserRole;
