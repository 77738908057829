import { screenMinWidth, screenMaxWidth } from '../config/constants/ScreenWidths';

const media = {
    smallMobile: `@media only screen and (min-width: ${screenMinWidth.smallMobile}px) and (max-width: ${screenMaxWidth.smallMobile}px)`,
    mobile: `@media only screen and (min-width: ${screenMinWidth.mobile}px) and (max-width: ${screenMaxWidth.mobile}px)`,
    mobileUp: `@media only screen and (min-width: ${screenMinWidth.mobile}px)`,
    phablet: `@media only screen and (min-width: ${screenMinWidth.phablet}px) and (max-width: ${screenMaxWidth.phablet}px)`,
    phabletUp: `@media only screen and (min-width: ${screenMinWidth.phablet}px)`,
    tablet: `@media only screen and (min-width: ${screenMinWidth.tablet}px) and (max-width: ${screenMaxWidth.tablet}px)`,
    tabletPortrait: `@media only screen and (min-width: ${screenMinWidth.tablet}px) and (max-width: ${screenMaxWidth.tablet}px) and (orientation: portrait)`,
    tabletLandscape: `@media only screen and (min-width: ${screenMinWidth.tablet}px) and (max-width: ${screenMaxWidth.tablet}px) and (orientation: landscape)`,
    tabletUp: `@media only screen and (min-width: ${screenMinWidth.tablet}px)`,
    laptop: `@media only screen and (min-width: ${screenMinWidth.laptop}px) and (max-width: ${screenMaxWidth.laptop}px)`,
    laptopUp: `@media only screen and (min-width: ${screenMinWidth.laptop}px)`,
    desktop: `@media only screen and (min-width: ${screenMinWidth.desktop}px)`,
    desktopUp: `@media only screen and (min-width: ${screenMaxWidth.desktop}px)`,
};
export default media;
