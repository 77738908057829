
import localisable from 'Commons/config/strings/localisable';
import { Grid, Loader, makeStyles } from 'Generic/componentlibrary/components/Components';
import buttonTextStyles from '../styles/ButtonTextStyles';

const useStyles = makeStyles(buttonTextStyles);

const ButtonText = ({ isButtonLoading, loadingText, label, ...props }) => {
    const classes = useStyles(props);
    return (
        isButtonLoading
            ? (
                <Grid container justify="center" alignItems="center">
                    <Loader
                        className={classes.loader}
                        size={16}
                        disableShrink
                    />
                    <span className={classes.loadingText}>{ loadingText }</span>
                </Grid>
            )
            : label
    );
};

ButtonText.propTypes = {
    loadingText: PropTypes.string,
    isButtonLoading: PropTypes.bool,
    label: PropTypes.string,
    classes: PropTypes.object,
};

ButtonText.defaultProps = {
    loadingText: localisable.saving,
    isButtonLoading: false,
    label: localisable.save,
};

export default React.memo(ButtonText);
