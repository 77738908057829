/* eslint-disable import/prefer-default-export */
import { mapEnum } from '../../../../../helpers/utils/Formatter';
import enums from '../../../../../config/constants/Enums';

const primaryPhone = mapEnum(enums.primary);
const LIST_ITEMS_FONT_SIZE = '14px';
const LIST_ITEMS_FONT_WEIGHT = '400';

export {
    primaryPhone,
    LIST_ITEMS_FONT_SIZE,
    LIST_ITEMS_FONT_WEIGHT,
};
