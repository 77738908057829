import useRouter from 'Commons/components/business/provider/useRouter';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';

const CustomRoute = ({
    component: Component, path,
    removeRefOfUnmountedComponent, getKey,
    ...props
}) => {
    const { location, match, history } = useRouter(path);

    const key = getKey({ location, match, history });
    const keyProp = key ? { key } : {};
    removeRefOfUnmountedComponent(Component); // updates component instance in Router

    return (
        <Component {...props} location={location} match={match} history={history} {...keyProp} />
    );
};

CustomRoute.propTypes = {
    component: PropTypes.any,
    path: PropTypes.string,
    removeRefOfUnmountedComponent: PropTypes.func,
    getKey: PropTypes.func,
};

CustomRoute.defaultProps = {
    removeRefOfUnmountedComponent: EMPTY_FUNC,
    getKey: EMPTY_FUNC,
};

export default CustomRoute;
