import Icon from 'Generic/icon/components/Icon';

const NODE_STATE = {
    UNCHECKED: 0,
    INTERMEDIATE: 1,
    CHECKED: 2,
};

const TEXTFIELD_ADDON = { start: <Icon type="custom" icon="cp-search" /> };

const CHECKBOX_PROPS = { useField: true };

export {
    NODE_STATE,
    TEXTFIELD_ADDON,
    CHECKBOX_PROPS,
};
