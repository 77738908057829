/* eslint-disable no-restricted-syntax */
import {
    DASHBOARD_TYPE, EMPTY_FUNC, FACILITY_LICENSE_STATUS,
    SESSION_TIMEOUT,
} from 'Commons/config/constants/Constants';
import { withStyles, Grid } from 'Commons/components/generic/componentlibrary/components/Components';
import BaseComponent from 'Commons/components/business/basecomponent/components/BaseComponent';
import SideBarContext from 'Commons/contexts/SideBarContext';
import PermissionContext from 'Commons/contexts/PermissionContext';
import WSChangeGroupContext from 'Commons/contexts/WSChangeGroupContext';
import WebSocketInstance from 'Commons/components/business/socket/WebSocket';
import COOKIE_KEY from 'Commons/redux/config/CookieKey';
import { getCookie, deleteCookie, setCookie } from 'Commons/redux/helper/CookieManager';
import GeneralPages from 'Generic/generalPages/components/GeneralPages';
import { VARIANT } from 'Generic/generalPages/config/Config';
import WSUpdatePropsContext from 'Commons/contexts/WSUpdatePropsContext';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import {
    isSettings, isInternalDashboard, isLiveData,
    isExternalDashboard, isLiveSettings, isPulledSettings, getJsonFromUrl, isSyrasoftUser,
} from 'Commons/helpers/utils/Utils';
import INTERNAL_ROUTES from 'Internal/redux/config/RouteNames';
import StandaloneAppDownloadContext from 'Commons/contexts/StandaloneAppDownloadContext';
import { changeFacilityRequest } from 'External/containers/dashboard/widgets/header/configs/ApiRequests';
import { CRUD_METHODS, DEFAULT_EXTERNAL_ROUTE } from 'Commons/redux/config/Constants';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import { isObjWithKeys } from 'Commons/helpers/utils/DataHelpers';
import {
    CURRENT_FACILITY_STORE_KEY,
    FACILITY_STORE_KEY,
} from 'External/containers/dashboard/widgets/header/configs/Constants';
import { VARIANT as SNACKBAR_VARIANT } from 'Generic/snackbar/config/Constants';
import Button from 'Commons/components/generic/button/components/Button';
import dashboardStyle from './styles/DashboardStyle';
import { updateFacilityStore, updateSessionTimeout } from './config/ApiRequests';
import { BLUR_EVENT_LISTENER, FOCUS_EVENT_LISTENER, getEventListeners } from './config/EventListeners';
import LegalAgreement from './LegalAgreement';
import { clsx } from '../../../helpers/utils/clsx';

const JSZip = require('jszip');

class Dashboard extends BaseComponent {
    constructor(props) {
        super(props);
        const {
            downloadStandaloneApp, setFinishedDownloadCallback, onFocus, onBlur, connectWebsocket,
            resolveCurrentFacilityDetails, resolveAppropriateRoute,
            props: { userProfile: { data: { data: [{ userType } = {}] = [{}] } = {} } } = {},
        } = this;
        const { withProvider } = window;
        window.userType = userType;
        this.SideBar = withProvider('SideBar', props, false);
        this.childrenToNotify = [];
        this.initializeDashboardComponents(props);
        this.resolveQuickbooksUrl(props);
        this.standaloneAppDownloadDetails = { downloadStandaloneApp, setFinishedDownloadCallback };
        this.eventListeners = getEventListeners({ onFocus, onBlur });
        resolveCurrentFacilityDetails();
        connectWebsocket();
        resolveAppropriateRoute();
        this.headerKey = 0;
        this.storesToNotClearSilently = FACILITY_STORE_KEY;
    }

    resolveQuickbooksUrl = () => {
        const {
            props:
            {
                history, location: { search = '' } = {},
                match: { params: { accountId } = {} } = {},
            } = {},
        } = this;
        const facilityId = getCookie(COOKIE_KEY.DEFAULT_FACILITY_ID);
        const qbInfo = getJsonFromUrl(search);
        const { error: qbError = {}, code = '', realmId = '' } = qbInfo;
        if (facilityId && ((code && realmId) || isObjWithKeys(qbError))) {
            let url = buildUrl('configType', { fid: facilityId, config: 'quickbook' },
                DASHBOARD_TYPE.SETTINGS,
                accountId);
            if (isObjWithKeys(qbError)) {
                url += `?error=${qbError}`;
            } else {
                url += `?code=${code}&realmId=${realmId}`;
            }
            if (url) {
                history.replace(url);
            }
        }
    }

    resolveCurrentFacilityDetails = () => {
        const {
            props: {
                match: { params: { fid } = {} } = {}, location: { state = {} } = {},
                facility: { data: { data = [] } = {} } = {},
            } = {}, props = {},
        } = this;
        let facilityId;
        if (state) {
            const { fid: stateFid } = state;
            facilityId = stateFid;
        }
        // TODO: Find an appropriate way to handle defaultFacilityId
        this.defaultFacilityId = getCookie(COOKIE_KEY.DEFAULT_FACILITY_ID);
        this.stateFacilityId = facilityId;
        deleteCookie(COOKIE_KEY.DEFAULT_FACILITY_ID);
        const currentFacilityId = fid || facilityId || this.defaultFacilityId;
        if (this.defaultFacilityId) {
            changeFacilityRequest(CRUD_METHODS.UPDATE, data.find(({ id }) => id === this.defaultFacilityId), props);
        }
        this.selectedEntityId = currentFacilityId;
        this.facilityMap = this.preProcessData(data);
    }

    connectWebsocket = () => {
        WebSocketInstance.connect(this.selectedEntityId);
        WebSocketInstance.addCallback(this.onWebSocketMessage);
    }

    resolveAppropriateRoute = () => {
        const { props: { match: { url } = {} } = {} } = this;
        const { deviceInfo: { isDesktop } } = window;
        let stateToSet = { open: isDesktop, showAlert: false };

        if (isLiveData(url)) {
            this.isLiveData = true;
            stateToSet = { ...stateToSet, showAlert: true };
        } else {
            this.checkAndUpdateRoute();
        }
        this.state = stateToSet;
    }

    doesFacilityBelongToCurrentAccount = () => {
        const { facilityMap = {} } = this;
        if (facilityMap[this.selectedEntityId]) {
            return true;
        }
        [this.selectedEntityId] = Object.keys(facilityMap) || [''];
        return false;
    }

    preProcessData = (facilityData) => {
        const facilityMap = {};
        facilityData.reduce((processedData, item) => {
            const { id } = item;
            facilityMap[id] = item;
            return processedData;
        }, {});
        return facilityMap;
    }

    updateCurrentRouteIfInvalid = () => {
        const {
            props = {},
            props: {
                history,
                match: { url } = {},
            } = {},
            doesFacilityBelongToCurrentAccount = EMPTY_FUNC,
            facilityMap = {},
        } = this;
        if (isExternalDashboard(url) && !isSettings(url) && !doesFacilityBelongToCurrentAccount()) {
            const dashboardUrl = buildUrl('dashboard', { fid: this.selectedEntityId });
            changeFacilityRequest('update', facilityMap[this.selectedEntityId], props);
            history.replace(dashboardUrl);
        }
    }

    checkAndUpdateRoute = () => {
        const {
            dashboardType, initializeInternalDashboardComponents,
            initializeExternalDashboardComponents,
            props: {
                history, clearStoreKeys,
                facility: {
                    data: {
                        data: facilities = [],
                        relationalData: { isSystemAccount = false } = {},
                    } = {},
                } = {},
            } = {},
        } = this;
        if (!facilities.length
            && isSystemAccount
            && dashboardType === DASHBOARD_TYPE.EXTERNAL
        ) {
            initializeInternalDashboardComponents();
            clearStoreKeys(['userProfile', 'facility', 'permissionRoles']);
            history.replace(`/${INTERNAL_ROUTES.INTERNAL_MAIN}`);
        } else if (!isSyrasoftUser() && !isSystemAccount && dashboardType === DASHBOARD_TYPE.INTERNAL) {
            initializeExternalDashboardComponents();
            history.replace(DEFAULT_EXTERNAL_ROUTE);
        }
    }

    componentDidMount = () => {
        const { setUpdateTimeoutInterval } = this;
        this.isUserActive = true;
        this.manageEventListeners({ shouldAddEventListeners: true });
        setUpdateTimeoutInterval();
    }

    componentWillUnmount = () => {
        clearInterval(this.updateTimeoutInterval);
        this.manageEventListeners({ shouldAddEventListeners: false });
    }

    manageEventListeners = ({ shouldAddEventListeners = true }) => {
        const { eventListeners } = this;
        const addOrRemoveEventListener = shouldAddEventListeners ? window.addEventListener : window.removeEventListener;
        eventListeners.forEach(({ listener, action }) => {
            addOrRemoveEventListener(listener, action);
        });
    }

    onFocusOrBlur = (listener) => {
        const { setUpdateTimeoutInterval, updateTimeoutInterval, checkAndUpdateSessionTimeout } = this;
        this.isUserActive = true;
        checkAndUpdateSessionTimeout();
        if (listener === FOCUS_EVENT_LISTENER) setUpdateTimeoutInterval();
        else {
            clearInterval(updateTimeoutInterval);
            this.isUserActive = false;
        }
    }

    onFocus = () => this.onFocusOrBlur(FOCUS_EVENT_LISTENER)

    onBlur = () => this.onFocusOrBlur(BLUR_EVENT_LISTENER)

    setUpdateTimeoutInterval = () => {
        const { checkAndUpdateSessionTimeout } = this;
        this.updateTimeoutInterval = setInterval(checkAndUpdateSessionTimeout, SESSION_TIMEOUT);
    }

    checkAndUpdateSessionTimeout = () => {
        const { props: { onAction } = {}, onSessionTimeoutUpdate, isUserActive } = this;
        if (isUserActive) {
            updateSessionTimeout(onAction, onSessionTimeoutUpdate);
        }
    }

    onSessionTimeoutUpdate = (_, response) => {
        if (response) {
            const { data: { token, tokenExpiry } = {} } = response;
            if (token && tokenExpiry) {
                setCookie(COOKIE_KEY.TOKEN, token);
            }
        }
    }

    onRefresh = () => {
        const { props: { clearStoreKeys = EMPTY_FUNC, match: { params: { fid } = {} } = {} } = {} } = this;
        WebSocketInstance.discardChannelsGroup(fid);
        clearStoreKeys([FACILITY_STORE_KEY, CURRENT_FACILITY_STORE_KEY]);
    }

    updateFacilityStoreWithLicenseStatus = (updatedFacilityId) => {
        const { facility: { data: { data: facilityData = [], ...data } = {} } = {}, onAction } = this.props;
        const updatedFacilityData = [];
        facilityData.forEach((eachFacility) => {
            const { id, licenseStatus } = eachFacility;
            let updatedFacility = eachFacility;
            if (`${id}` === `${updatedFacilityId}`) {
                updatedFacility = {
                    ...eachFacility,
                    licenseStatus: licenseStatus === FACILITY_LICENSE_STATUS.Active.value
                        ? FACILITY_LICENSE_STATUS.Expired.value : FACILITY_LICENSE_STATUS.Active.value,
                };
            }
            updatedFacilityData.push(updatedFacility);
        });
        this.headerKey += 1;
        updateFacilityStore(onAction, { data: updatedFacilityData, ...data });
    }

    handleFacilityStatusRTNSMessage = (message = {}) => {
        const { resourceId, resourceName, eventType, fields = {} } = message;
        const { match: { params: { fid } = {} } = {} } = this.props;
        if (resourceName === 'facility' && eventType === 'Updated' && (fields && 'licenseStatus' in fields)) {
            if (`${resourceId}` === `${fid}`) {
                this.setSnackbarProps(true,
                    localisable.rtnsBlockedSave,
                    SNACKBAR_VARIANT.rtns.value);
                this.setState({ receivedWSMessage: true }, () => { this.state.receivedWSMessage = false; });
            } else this.updateFacilityStoreWithLicenseStatus(resourceId);
        } else if (resourceName === 'gate_configuration' && eventType === 'updated') {
            this.setSnackbarProps(true,
                localisable.rtnsFacilityConfigMessage,
                SNACKBAR_VARIANT.rtns.value);
            this.setState({ receivedWSMessage: true }, () => { this.state.receivedWSMessage = false; });
        }
    }

    handleUnitAvailabilityForWaiting = (message = {}) => {
        const { displayMessage, resourceName = '' } = message;
        if (resourceName === 'Unit_Availability_For_Waiting') {
            const unitTypeMessage = localisable.unitTypeAvailableMessage.replace('UNIT_TYPE', displayMessage);
            this.alert = {
                title: localisable.waitingListAlert,
                message: `${unitTypeMessage}\n${localisable.customersWaitingOnUnitType}`,
            };
            this.setState({ showAlert: true });
        }
    }

    onWebSocketMessage = (wsMessage) => {
        const { childrenToNotify = [], handleFacilityStatusRTNSMessage, handleUnitAvailabilityForWaiting } = this;
        childrenToNotify.forEach((notifyChild = EMPTY_FUNC) => {
            notifyChild(wsMessage);
        });
        handleFacilityStatusRTNSMessage(wsMessage);
        handleUnitAvailabilityForWaiting(wsMessage);
    }

    // TODO: Remove children function when they unmount, Case 29287
    updateWsProps = (wsProps) => {
        const { childrenToNotify = [] } = this;
        const { messageCallback, shouldClearCallbacks = true } = wsProps;
        if (!messageCallback || messageCallback === EMPTY_FUNC) {
            if (shouldClearCallbacks) {
                this.childrenToNotify = [];
            } else {
                this.childrenToNotify.shift(); // remove 0th
            }
        } else {
            this.childrenToNotify = [messageCallback].concat(childrenToNotify);
        }
    }

    changeWebSocketGroup = (currentFacility, prevFacility) => {
        this.selectedEntityId = currentFacility;
        WebSocketInstance.updateChannelsGroup(currentFacility, prevFacility);
    }

    initializeDashboardComponents = () => {
        const {
            props: { match: { url } = {} } = {}, initializeExternalDashboardComponents,
            initializeInternalDashboardComponents, initializeSettingsDashboardComponents,
        } = this;
        initializeExternalDashboardComponents();
        if (isInternalDashboard(url)) {
            initializeInternalDashboardComponents();
        } else if (isSettings(url) || isLiveSettings(url) || isPulledSettings(url)) {
            initializeSettingsDashboardComponents();
        }
    }

    initializeExternalDashboardComponents = () => {
        const { withProvider } = window;
        this.dashboardType = DASHBOARD_TYPE.EXTERNAL;
        this.Header = withProvider('Header', this.props, false);
        this.Main = withProvider('Main', this.props, false);
    }

    initializeInternalDashboardComponents = () => {
        const { withProvider } = window;
        this.dashboardType = DASHBOARD_TYPE.INTERNAL;
        this.Header = withProvider('HeaderInt', this.props, false);
        this.Main = withProvider('MainInt', this.props, false);
    }

    initializeSettingsDashboardComponents = () => {
        const { withProvider } = window;
        this.dashboardType = DASHBOARD_TYPE.SETTINGS;
        this.Header = withProvider('Header', this.props, false);
        this.Main = withProvider('MainSettings', this.props, false);
    }

    toggleDrawer = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    };

    closeDrawer = () => {
        this.setState({ open: false });
    }

    hasAccess = () => {
        const { unauthorised } = this.props;
        const { match: { params: { fid } }, facility: { data: { data = [] } = {} } = {} } = this.props;
        const wrongFacilityId = fid ? data.some(({ id }) => id === fid) : true;
        return wrongFacilityId || unauthorised;
    }

    /* Below functions till renderComponents are used to download standalone app from the configuration page. Writing this
    code here allows the user to leave the standalone app download page and to work on other parts of the app. Status
    of download is sent using Context Provider to the relevant component */

    setFinishedDownloadCallback = (callback = EMPTY_FUNC) => {
        this.onDownloadFinish = callback;
    }

    updateStandaloneAppDownloadingStartDetails = (response) => {
        this.standaloneAppDownloadDetails.downloadStarted = true;
        this.standaloneAppDownloadDetails.contentLength = +response.headers.get('Content-Length');
        this.standaloneAppDownloadDetails.receivedLength = 0;
    }

    addFilesToFolder = (folder, files = []) => {
        files.forEach(({ name, data }) => {
            folder.file(name, data);
        });
    }

    getExeFile = (chunks, { name = 'syrasoft-cloud-assist.exe' }) => {
        const { standaloneAppDownloadDetails: { receivedLength } = {} } = this;
        const standaloneInstallerFile = new Uint8Array(receivedLength);
        let position = 0;
        for (const chunk of chunks) {
            standaloneInstallerFile.set(chunk, position); // (4.2)
            position += chunk.length;
        }
        return { name, data: standaloneInstallerFile };
    }

    getConfigFile = (configData = {}) => ({
        name: 'config.json',
        data: JSON.stringify(configData),
    })

    downloadZippedFolder = (zippedContent) => {
        const zippedData = window.URL.createObjectURL(zippedContent);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = zippedData;
        link.download = 'syrasoft-cloud-assist.zip';
        link.hidden = true;
        link.click();
        window.URL.revokeObjectURL(zippedData);
        link.remove();
    }

    postDownloadStandaloneApp = (chunks, file, additionalFiles, configData) => {
        const { addFilesToFolder, getExeFile, getConfigFile, downloadZippedFolder } = this;
        const zip = JSZip();
        const folder = zip.folder('syrasoft-cloud-assist');
        const files = [getExeFile(chunks, file), getConfigFile(configData)].concat(additionalFiles);
        addFilesToFolder(folder, files);
        zip.generateAsync({ type: 'blob' }).then((content) => {
            downloadZippedFolder(content);
        });
    }

    downloadInstaller = async (file, additionalDownloadedFiles, configData) => {
        const {
            onDownloadFinish = EMPTY_FUNC, updateStandaloneAppDownloadingStartDetails,
            postDownloadStandaloneApp,
        } = this;
        const { url } = file;
        const s3Response = await fetch(url);
        updateStandaloneAppDownloadingStartDetails(s3Response);
        const reader = s3Response.body.getReader();
        const chunks = [];
        while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader.read();
            if (done) {
                this.standaloneAppDownloadDetails.downloadStarted = false;
                onDownloadFinish();
                postDownloadStandaloneApp(chunks, file, additionalDownloadedFiles, configData);
                break;
            } else {
                chunks.push(value);
                this.standaloneAppDownloadDetails.receivedLength += value.length;
            }
        }
    }

    downloadStandaloneApp = async (file = {}, additionalFiles = [], configData = {}) => {
        const { downloadInstaller } = this;
        const additionalDownloadedFiles = [];
        additionalFiles.forEach((fileToDownload) => {
            const { url, name } = fileToDownload;
            fetch(url).then((response) => {
                if (response.ok) {
                    response.blob().then((data) => {
                        additionalDownloadedFiles.push({ data, name });
                    });
                }
            });
        });
        downloadInstaller(file, additionalDownloadedFiles, configData);
    }

    getAgreementDetails = () => {
        const {
            match: { params: { fid } = {} } = {},
            facility: { data: { data = [], relationalData: { agreementLink } = {} } = {} } = {},
        } = this.props;
        const currentFacility = data.find(({ id }) => id === (fid || this.stateFacilityId || this.defaultFacilityId));
        const { metaInfo: { legalAgreement: { isAccepted = false } = {} } = {} } = currentFacility || {};
        return [isSyrasoftUser() ? true : isAccepted, agreementLink];
    }

    renderAlertMessage = () => {
        const { alert: { message: alertMessage = '' } = {} } = this;
        return alertMessage
            ? <Typography>{alertMessage}</Typography>
            : (
                <>
                    <Typography>{localisable.working}</Typography>
                    <Typography color="error">{` ${localisable.liveData}`}</Typography>
                    <Typography>. {localisable.beCautious}</Typography>
                </>
            );
    }

    onCloseAlert = () => {
        this.alert = {};
        this.setState({ showAlert: false });
    }

    getAlertActions = () => {
        const { onCloseAlert } = this;
        return (
            <Button
                onClick={onCloseAlert}
                variant="text"
                color="primary"
            >
                <Typography variant="subtitle2">
                    {localisable.ok}
                </Typography>
            </Button>
        );
    }

    renderComponent() {
        const {
            classes, routeList, history, location, match, permission,
            match: { url, params: { fid } = {} } = {}, onAction, clearStoreKeys,
            userProfile: { data: { data: [{ userType }] = [{}] } = {} },
        } = this.props;
        const [isAccepted, agreementLink] = this.getAgreementDetails();
        const routeProps = { history, match, location, routeList };
        const { open, showAlert } = this.state;
        const {
            Header, Main, SideBar, dashboardType, changeWebSocketGroup, headerKey,
            updateWsProps, defaultFacilityId, standaloneAppDownloadDetails,
            facilityMap: { [this.selectedEntityId || fid]: { metaInfo: { isOnboarding = false } = {} } = {} } = {},
            alert: { title: alertTitle = '' } = {}, renderAlertMessage, onCloseAlert, getAlertActions,
        } = this;
        return (
            <>
                <div className={classes.root}>
                    <PermissionContext.Provider value={permission}>
                        <WSChangeGroupContext.Provider value={changeWebSocketGroup}>
                            <WSUpdatePropsContext.Provider value={updateWsProps}>
                                <Header
                                    key={`Header-${headerKey}`}
                                    toggleDrawer={this.toggleDrawer}
                                    dashboardType={dashboardType}
                                    defaultFacilityId={defaultFacilityId}
                                    {...routeProps}
                                />
                                {
                                    !isOnboarding
                                    && (
                                        <SideBar
                                            open={open}
                                            dashboardType={dashboardType}
                                            closeDrawer={this.closeDrawer}
                                            defaultFacilityId={defaultFacilityId}
                                            userType={userType}
                                            {...routeProps}
                                        />
                                    )
                                }
                                {
                                    isAccepted || (isSettings(url) || url.match(/^\/l\/s/g))
                                        ? (
                                            <SideBarContext.Provider value={open}>
                                                <StandaloneAppDownloadContext.Provider
                                                    value={standaloneAppDownloadDetails}
                                                >
                                                    <Main
                                                        key={fid}
                                                        updateWsProps={updateWsProps}
                                                        dashboardType={dashboardType}
                                                        defaultFacilityId={defaultFacilityId}
                                                        userType={userType}
                                                        classes={
                                                            {
                                                                ...isOnboarding
                                                                && {
                                                                    content: clsx(
                                                                        classes.mainContent,
                                                                        classes.onboarding,
                                                                    ),
                                                                },
                                                            }
                                                        }
                                                        {...routeProps}
                                                    />
                                                </StandaloneAppDownloadContext.Provider>
                                            </SideBarContext.Provider>
                                        )
                                        : (
                                            <LegalAgreement
                                                classes={classes}
                                                agreementLink={agreementLink}
                                                onAction={onAction}
                                                fid={fid}
                                                clearStoreKeys={clearStoreKeys}
                                            />
                                        )
                                }
                            </WSUpdatePropsContext.Provider>
                        </WSChangeGroupContext.Provider>
                    </PermissionContext.Provider>
                </div>
                <AlertDialog
                    open={showAlert}
                    onClose={onCloseAlert}
                    title={alertTitle || localisable.alert}
                    {...alertTitle
                    && {
                        DialogContentProps: { classes: { root: classes.alertMinWidth } },
                        actions: getAlertActions(),
                    }
                    }
                >
                    <Grid container className={classes.alert}>
                        {renderAlertMessage()}
                    </Grid>
                </AlertDialog>
            </>
        );
    }

    renderUnauthorise() {
        return (
            <GeneralPages useAnchorTag variant={VARIANT.UNAUTHORISED} />
        );
    }


    renderError() {
        return (
            <GeneralPages useAnchorTag variant={VARIANT.SERVER_ERROR} />
        );
    }

    renderLoading() {
        return (
            <p>
                Dashboard Loading...
            </p>
        );
    }
}

Dashboard.propTypes = { classes: PropTypes.object, history: PropTypes.any };

export default withStyles(dashboardStyle)(Dashboard);
