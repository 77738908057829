import { memo } from 'react';
import Table from 'Generic/table/components/Table';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import { TABLE_ROW_HEIGHT_VARIANT } from 'Commons/config/constants/Constants';
import getFormattedAmount from 'Commons/helpers/utils/AmountHelper';
import { clsx } from 'Commons/helpers/utils/clsx';
import Typography from 'Commons/components/generic/typography/components/Typography';
import SummaryTableStyle from '../styles/SummaryTableStyle';
import { getColumns } from './SummaryTableConfig';
import {
    MIN_TABLE_WIDTH_FOR_SINGLE_LINE_ROW,
    MIN_TABLE_WIDTH_FOR_DOUBLE_LINE_ROW,
    MIN_TABLE_WIDTH_FOR_RESPONSIVE_VERSION,
} from '../config/Constants';


const useStyles = makeStyles(SummaryTableStyle);
const { innerWidth, deviceInfo: { isMobile, isPhablet } } = window;
const tableProps = isMobile || isPhablet ? { tableWidth: innerWidth - 64 } : {};

// TODO:will change the name to Summary table and delete the other summaryTable when MoveOut is done

const SummaryTable = ({
    source, data, title,
    total, currency, isAnotherTablePresent, ...props
}) => {
    const classes = useStyles();
    const { tableWidth: tableWidthFromParent } = props;
    const { tableWidth: tableWidthFromSelf } = tableProps;
    const tableWidth = tableWidthFromParent || tableWidthFromSelf;
    let rowHeight = 60;
    if (tableWidth > MIN_TABLE_WIDTH_FOR_SINGLE_LINE_ROW) {
        rowHeight = 40;
    } else if (tableWidth > MIN_TABLE_WIDTH_FOR_DOUBLE_LINE_ROW) {
        rowHeight = 50;
    }
    const isResponsiveScreen = tableWidth < MIN_TABLE_WIDTH_FOR_RESPONSIVE_VERSION;

    return (
        <>
            <Grid
                container
                alignItems="center"
                justify="space-between"
                className={clsx(classes.header,
                    isAnotherTablePresent ? classes.doubleTableHeader : classes.singleTableHeader)}
            >
                <Typography variant="subtitle1">{title}</Typography>
                <Grid
                    container
                    className={classes.autoWidth}
                >
                    <Typography variant="subtitle1" fontFamily="Roboto">{currency}</Typography>
                    <Typography variant="subtitle1">{`${getFormattedAmount(total)}`}</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.table} wrap="nowrap" direction="column">
                <Table
                    columns={getColumns(classes, currency, total, isResponsiveScreen)}
                    data={data}
                    rowCount={data.length}
                    rowHeight={rowHeight}
                    headerRowHeight={(isResponsiveScreen) ? TABLE_ROW_HEIGHT_VARIANT.HEADER_ONE_LINER : TABLE_ROW_HEIGHT_VARIANT.HEADER_TWO_LINER}
                    tableHeight={250}
                    rowDivider={false}
                    hasFooter
                    classes={{
                        firstColumn: `${classes.columnHeader} ${classes.leftCell}`,
                        tableGrid: clsx(classes.tableGrid, classes.noPadding),
                    }}
                    formatterProps={{ isResponsive: isResponsiveScreen }}
                    {...tableProps}
                    {...props}
                />
            </Grid>
        </>
    );
};

SummaryTable.propTypes = {
    source: PropTypes.string,
    data: PropTypes.array,
    title: PropTypes.string,
    total: PropTypes.string,
    currency: PropTypes.string,
    tableWidth: PropTypes.number,
    isAnotherTablePresent: PropTypes.bool,
};

SummaryTable.defaultProps = {
    data: [],
    title: '',
    total: '',
    currency: '$',
    isAnotherTablePresent: false,
};

export default memo(SummaryTable);
