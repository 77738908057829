import BaseComponent from '../../basecomponent/components/BaseComponent';
import { ListItem, Icon, ListItemText } from '../../../generic/componentlibrary/components/Components';

export default class FilterComponent extends BaseComponent {
    renderComponentTitle() {
        const { device, classes, componentLabel, onBack } = this.props;
        switch (device) {
            case 'isMobile':
            case 'isPhablet':
            case 'isTablet':
                return (
                    <div className={classes.mobileSectionHeader}>
                        <ListItem>
                            <Icon onClick={onBack}>chevron_left</Icon>
                            <ListItemText className={classes.title}>{componentLabel}</ListItemText>
                        </ListItem>
                    </div>
                );
            default:
                return (<p className={classes.title}>{componentLabel}</p>);
        }
    }

    render() {
        return (
            <div>Default Component</div>
        );
    }
}
