import React, { memo, useEffect, useMemo, useState } from 'react';
import { makeStyles, Grid } from 'Commons/components/generic/componentlibrary/components/Components';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import {
    compareDatesOrDatetime, createFormattedDate,
    getCurrentDate, getDate, getDay,
    getFirstDateOfMonth, getLastDateOfMonth,
    getMonth, calculateWeeksInMonth, getYear, convertToMomentObj,
} from 'Commons/helpers/utils/DateTime';
import {
    CALENDAR_VIEW_TYPE, COMPARISON_OPERATION,
    DATE_FORMAT, EMPTY_FUNC, MAX_WEEKS_IN_MONTH,
    NUMBER_OF_DAYS_IN_WEEK,
} from 'Commons/config/constants/Constants';
import { DATE_SEEK_DIRECTION } from 'External/containers/pendingTasks/components/reminders/config/Constants';
import { rearrangeWeekdays } from 'Commons/helpers/utils/CalendarHelper';
import calendarStyle from '../styles/CalendarStyle';
import Month from './Month';
import Header from './Header';


const useStyles = makeStyles(calendarStyle, { name: 'Calendar' });

const getCalendarData = (calculateValue, calendarWeekStartingDay = 0, showFixedNumberOfWeeks = false) => {
    const year = getYear(calculateValue);
    const month = getMonth(calculateValue);
    const firstDayOfMonth = getFirstDateOfMonth(year, month, false);
    const lastDayOfMonth = getLastDateOfMonth(year, month, false);

    const numberOfDays = lastDayOfMonth.date();
    const startingDay = firstDayOfMonth.day(); // 0: Sunday, 1: Monday, ..., 6: Saturday
    const prevMonthLastDay = getLastDateOfMonth(year, month - 1 >= 0 ? month - 1 : 11, false).date();
    const numberOfWeeksInMonth = calculateWeeksInMonth(year, month, calendarWeekStartingDay);
    const numberOfWeeksToDisplay = showFixedNumberOfWeeks ? MAX_WEEKS_IN_MONTH : numberOfWeeksInMonth;

    const calendarData = { year, month, numberOfDays, startingDay, prevMonthLastDay, numberOfWeeksInMonth, numberOfWeeksToDisplay };
    return calendarData;
};

const Calendar = ({
    showFixedNumberOfWeeks, calendarWeekStartingDay,
    viewTypeList,
    renderInRows, maxVisibleContent, data = {},
    datePickerDateFormat, createButtonText, onCreateButtonClick,
    getDisplayText, onChangeSelectedViewType,
    onDateChange: onpropDateChange, showHeader,
    trackValue, value, onClickMoreItems,
    shouldTruncateTitle, truncateTitleLength,
    ...restProps
}) => {
    const classes = useStyles({ renderInRows });
    const WEEKDAYS_LIST = useConstructor(() => rearrangeWeekdays(calendarWeekStartingDay));
    const currentDate = useMemo(() => getCurrentDate(false), []);

    const [selectedView, setSelectedView] = useState(CALENDAR_VIEW_TYPE.Month.value);
    const [displayedDate, setDisplayedDate] = useState(trackValue ? getDate(value, false) : currentDate); // to store the date in the moment.js format, initialized with the 'currentDate'
    const [calenderDataValue, setCalenderDataValue] = useState(() => getCalendarData(trackValue ? displayedDate : value, calendarWeekStartingDay, showFixedNumberOfWeeks)); // to store the date in the moment.js format, initialized with the 'currentDate'

    useEffect(() => {
        const calculateValue = trackValue ? displayedDate : value;
        setCalenderDataValue(getCalendarData(calculateValue, calendarWeekStartingDay, showFixedNumberOfWeeks));
    }, [displayedDate, value]);

    const handleViewChange = (_, viewTypeValue) => {
        if (trackValue) {
            setSelectedView(viewTypeValue);
        }
        onChangeSelectedViewType(viewTypeValue);
        // Reset displayed date on view change for 'Week' and 'Day'
        const selectedDateValue = trackValue ? displayedDate : value;
        const newDate = createFormattedDate(getYear(selectedDateValue), getMonth(selectedDateValue), getDay(selectedDateValue), false);
        if (trackValue) {
            setDisplayedDate(newDate);
        }
        onpropDateChange(newDate);
    };

    const onDateSeek = (seekDirection) => {
        const increment = seekDirection === DATE_SEEK_DIRECTION.FORWARD ? 1 : -1;
        let nextDate = displayedDate;
        if (selectedView === CALENDAR_VIEW_TYPE.Month.value) {
            nextDate = displayedDate.clone().add(increment, 'months');
        } else if (selectedView === CALENDAR_VIEW_TYPE.Week.value) {
            nextDate = displayedDate.clone().add(increment * NUMBER_OF_DAYS_IN_WEEK, 'days');
        } else if (selectedView === CALENDAR_VIEW_TYPE.Day.value) {
            nextDate = displayedDate.clone().add(increment, 'days');
        }
        if (trackValue) {
            setDisplayedDate(nextDate);
        }
        onpropDateChange(nextDate);
    };

    const onDateChange = (date) => {
        if (compareDatesOrDatetime(displayedDate, date, COMPARISON_OPERATION.NE)) {
            if (trackValue) {
                setDisplayedDate(convertToMomentObj(date));
            }
            onpropDateChange(convertToMomentObj(date));
        }
    };

    const renderCalendar = () => {
        const {
            year, month, numberOfDays,
            startingDay, prevMonthLastDay,
            numberOfWeeksToDisplay,
        } = calenderDataValue;
        if (selectedView === CALENDAR_VIEW_TYPE.Month.value) {
            return (
                <Month
                    calendarWeekStartingDay={calendarWeekStartingDay}
                    numberOfWeeksToDisplay={numberOfWeeksToDisplay}
                    daysOfWeek={WEEKDAYS_LIST}
                    numberOfDays={numberOfDays}
                    startingDay={startingDay}
                    prevMonthLastDay={prevMonthLastDay}
                    month={month}
                    year={year}
                    renderInRows={renderInRows}
                    data={data}
                    currentDate={currentDate}
                    maxVisibleContent={maxVisibleContent}
                    shouldTruncateTitle={shouldTruncateTitle}
                    truncateTitleLength={truncateTitleLength}
                    onClickMoreItems={onClickMoreItems}
                    {...restProps}
                />
            );
        }
        return null;
    };
    return (
        <Grid container xs={12} className={classes.mainContainer}>
            {showHeader && (
                <Header
                    displayedDate={displayedDate}
                    selectedView={selectedView}
                    viewTypeList={viewTypeList}
                    handleViewChange={handleViewChange}
                    onDateSeek={onDateSeek}
                    datePickerDateFormat={datePickerDateFormat}
                    onDateChange={onDateChange}
                    createButtonText={createButtonText}
                    onCreateButtonClick={onCreateButtonClick}
                    getDisplayText={getDisplayText}
                />
            )
            }
            <Grid container xs={12}>
                {renderCalendar()}
            </Grid>
        </Grid>
    );
};

Calendar.propTypes = {
    showFixedNumberOfWeeks: PropTypes.bool,
    calendarWeekStartingDay: PropTypes.number,
    renderInRows: PropTypes.bool,
    showHeader: PropTypes.bool,
    maxVisibleContent: PropTypes.number,
    datePickerDateFormat: PropTypes.string,
    createButtonText: PropTypes.string,
    onCreateButtonClick: PropTypes.func,
    viewTypeList: PropTypes.array,
    data: PropTypes.object,
    getDisplayText: PropTypes.func,
    trackValue: PropTypes.bool,
    onClickMoreItems: PropTypes.func,
    value: PropTypes.string,
    onChangeSelectedViewType: PropTypes.func,
    onDateChange: PropTypes.func,
    shouldTruncateTitle: PropTypes.bool,
    truncateTitleLength: PropTypes.number,
};

Calendar.defaultProps = {
    showFixedNumberOfWeeks: false,
    calendarWeekStartingDay: 0, // Sunday == 0, Monday == 1
    renderInRows: false,
    maxVisibleContent: 1, // max data to render each day,
    datePickerDateFormat: DATE_FORMAT,
    onCreateButtonClick: EMPTY_FUNC,
    viewTypeList: Object.values(CALENDAR_VIEW_TYPE),
    showHeader: false,
    trackValue: true,
    onClickMoreItems: EMPTY_FUNC,
    onChangeSelectedViewType: EMPTY_FUNC,
    onDateChange: EMPTY_FUNC,
    shouldTruncateTitle: false,
    truncateTitleLength: 10,
};

export default memo(Calendar);
