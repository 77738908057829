const checkBoxStyle = theme => ({
    formLabel: {
        '-webkit-user-select': 'none', /* webkit (safari, chrome) browsers */
        '-moz-user-select': 'none', /* mozilla browsers */
        '-khtml-user-select': 'none', /* webkit (konqueror) browsers */
        '-ms-user-select': 'none', /* IE10+ */
    },
    root: { color: theme.palette.primary.main },
    readOnly: {
        color: theme.palette.text.disabled,
        pointerEvents: 'none',
    },
    InheritedConfigLabel: { pointerEvents: 'none' },
});

export default checkBoxStyle;
