import { mapEnum } from 'Commons/helpers/utils/Formatter';
import enums from './Enums';

const DOCUMENT_TYPE = mapEnum(enums.documentType);
const SUB_DOCUMENT_TYPE = mapEnum(enums.subDocumentType);

const DOCUMENT_STATUS = mapEnum(enums.documentStatus);

export {
    DOCUMENT_TYPE,
    DOCUMENT_STATUS,
    SUB_DOCUMENT_TYPE,
};
