import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo, useMemo } from 'react';
import { DROPDOWN_MAX_HEIGHT } from 'External/containers/configuration/config/Constants';
import { getOptionProps, optionsFormatter } from 'Commons/helpers/utils/SDFormatter';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import { RESERVATION_STATUS } from '../config/Constants';
import ReservationFilterStyle from '../styles/ReservationFilterStyle';
import { getUnitTypeApiConfig } from '../config/ApiRequests';
import RangeFilter from '../../rangeFilter/components/RangeFilter';

const useStyles = makeStyles(ReservationFilterStyle, { name: 'ReservationFilter' });

const ReservationFilter = ({
    entityId,
    formProps: { setFieldValue, values: { terms: { unitTypeId: unitTypeIds = [] } = {} } = {} } = {},
}) => {
    const classes = useStyles();

    const unitTypeApiConfig = useMemo(() => getUnitTypeApiConfig(entityId), []);

    const onSelectOrRemoveUnitType = (selectedUnitTypes) => {
        setFieldValue('terms.unitTypeId', Object.keys(selectedUnitTypes));
    };

    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={Object.values(RESERVATION_STATUS)}
                row
                noGrid
                noIndicatorWrapper
            />
            <RangeFilter
                name="rentalInfo.moveInDate"
                setFieldValue={setFieldValue}
                label={localisable.moveInBetween}
                isDateRange
            />
            <Typography variant="subtitle1" className={classes.restLabel}>
                {localisable.unitType}
            </Typography>
            <MultiSelect
                placeholder={localisable.selectUnitType}
                onSelect={onSelectOrRemoveUnitType}
                onRemove={onSelectOrRemoveUnitType}
                getOptionProps={getOptionProps}
                apiConfig={unitTypeApiConfig}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                autoSuggestKey="matchPhrasePrefix.value.description"
                optionFormatter={optionsFormatter}
                initiallySelectedValues={unitTypeIds}
            />
        </>
    );
};

ReservationFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
};

ReservationFilter.defaultProps = { entityId: '' };

export default memo(ReservationFilter);
