/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-cond-assign */
function toVal(mix) {
    let k; let y; let
        str = '';
    if (mix) {
        if (typeof mix === 'object') {
            if (mix.push) {
                for (k = 0; k < mix.length; k += 1) {
                    if (mix[k] && (y = toVal(mix[k]))) {
                        str && (str += ' ');
                        str += y;
                    }
                }
            } else {
                for (k in mix) {
                    if (mix[k] && (y = toVal(k))) {
                        str && (str += ' ');
                        str += y;
                    }
                }
            }
        } else if (typeof mix !== 'boolean' && !mix.call) {
            str && (str += ' ');
            str += mix;
        }
    }
    return str;
}

/**
 * @description An utility for constructing strings conditionally.
 */
const clsx = (...args) => {
    let i = 0; let x; let
        str = '';
    while (i < args.length) {
        // eslint-disable-next-line no-plusplus
        if (x = toVal(args[i++])) {
            str && (str += ' ');
            str += x;
        }
    }
    return str;
};

export default clsx;

export { clsx };
