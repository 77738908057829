import media from '../../../../theme/MediaQueries';

const tablePaperStyle = theme => ({
    actionIcons: {
        '& > div': { marginLeft: 8 },
        '& *': { boxSizing: 'border-box' },
        width: 'auto',
    },
    hoverIcon: { '&:hover': { borderBottom: `1px solid ${theme.palette.text.primary}` } },
    icon: { color: theme.palette.text.primary },
    optionsStyle: {
        zIndex: 800,
        '& > div': { padding: '14px 16px' },
    },
    searchTextField: {
        // Using vw since the tablet sizes might vary
        [media.tabletLandscape]: { width: '25vw' },
        [media.laptopUp]: {
            width: '400px',
            display: 'block',
        },
    },
    searchInputField: {
        padding: 0,
        marginTop: 0,
    },
    tableContainer: {
        flex: 1,
        backgroundColor: theme.palette.common.white,
        '& *': { outline: 'none', boxSizing: 'border-box' },
    },
    popper: {
        position: 'absolute',
        right: 0,
    },
    openSearchTextField: { padding: theme.spacing(1) },
    componentLink: {
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary.main,
        },
        paddingLeft: theme.spacing(3),
    },
    pageBody: {
        overflow: 'initial',
        padding: 0,
    },
    pageHeader: {},
    actionItemLabel: { whiteSpace: 'pre' },
    page: {},
    title: {},
    fullHeight: { height: '100%' },
});
export default tablePaperStyle;
