import { withStyles, Chip } from 'Commons/components/generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import cardChipStyle from '../styles/CardChipStyle';


class CardChip extends React.Component {
    render() {
        const { classes, label, type, labelClassName, ...props } = this.props;
        return (
            <Chip
                classes={{
                    root: `${classes.customChip} ${classes[type]}`,
                    label: clsx(classes.chipLabel, labelClassName),
                }}
                label={label || type}
                {...props}
            />

        );
    }
}

CardChip.propTypes = {
    label: PropTypes.string,
    classes: PropTypes.object,
    type: PropTypes.oneOf(['lead', 'tenant', 'reserved', 'estimate', 'estimateStatusActive',
        'estimateStatusMovedIn', 'estimateStatusExpired', 'expired']),
    labelClassName: PropTypes.string,
};

CardChip.defaultProps = { type: 'tenant' };

export default withStyles(cardChipStyle)(CardChip);
