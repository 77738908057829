import { COMPARISON_OPERATION, TIME_WINDOW } from 'Commons/config/constants/Constants';
import { compareDatesOrDatetime } from 'Commons/helpers/utils/DateTime';

const isUserLocked = (lockedUntil) => {
    if (lockedUntil) {
        return compareDatesOrDatetime(lockedUntil, undefined, COMPARISON_OPERATION.GTE, TIME_WINDOW.SECONDS);
    }
    return false;
};

export { isUserLocked };
