const previewStyle = theme => ({
    block: { alignContent: 'flex-start' },
    roleTitle: { padding: theme.spacing(1) },
    title: {
        padding: '20px 28px',
        minHeight: 48,
        maxHeight: 48,
        marginBottom: theme.spacing(1),
    },
    roleBlockPadding: { padding: '20px 28px' },
    iconPadding: { padding: 4 },
});

export default previewStyle;
