/* eslint-disable import/no-extraneous-dependencies */

import { connect } from 'react-redux';
import { Component } from 'react';
import ErrorBoundaries from 'Generic/errorboundaries/components/ErrorBoundaries';
import hasPermission from 'Commons/helpers/utils/PermissionChecker';
import Loader from 'Commons/components/generic/pageloader/components/Loader';
import * as LazyComponents from '../../../helpers/Loaders';
import ConfigLoader from './ConfigLoader';
import BatchProcessor from './BatchProcessor';


const Provider = (ComponentName, componentProps = {}, shouldWrapWithErrorBoundaries = true) => {
    const SmartComponent = LazyComponents[ComponentName];
    const configLoader = new ConfigLoader();
    const batchProcessor = new BatchProcessor();

    /**
     * @description This maps the redux state properties the component is interested to the components props
     * @param {*} state Redux state that is being mapped to this component
     */
    const mapStateToProps = (state, ownProps) => {
        let componentConfig = null;
        const { match: { path = '', url = '' } = {}, routeList: { pathList = [], urlList = [] } = {} } = ownProps;
        componentConfig = configLoader.initialized
            ? (componentConfig || configLoader.get())
            : configLoader.init({ pathList, path, url, urlList }, ComponentName);
        return batchProcessor.getPropsForState(state, componentConfig);
    };

    class ProviderHOC extends Component {
        constructor(props) {
            super(props);
            this.unauthorised = false;
            this.initialize(props, componentProps || {});
        }

        /**
         * For the smart component to initiate dispatching an action or api or both
         * @param {*} method The Action Key method to fetch Action Config.
         * @param {Object} options Api/Store Options
         * @param {Boolean} options.sync Api/Store Options
         * @param {Object[]} options.config Store Config
         * @param {Object[]} options.config[].method The method = [CRUD]
         * @param {Object} options.config[].api If an API call needs to be made. Please pass the request options and type
         * @param {Object} [options.config[].api.action] Optional Action Config, if passed we will not pick up action config
         * @param {Object} [options.config[].api.dynamic] A dynamic config that needs to be combined with action config [Overwrites the action config properties]
         * @param {Function} [options.config[].callback] An optional callback to get API/Store Update results
         * @param {Object} options.config[].store If a store needs to be updated then pass the options.
         * @param {String} options.config[].store.key The store key that needs to be updated.
         * @param {String} options.config[].store.actionKey The store key that needs to be updated.
         * @param {Object} options.config[].store.payload The store key that needs to be updated.
         * @param {Object} options.config[].skipCustomPostProcessor If you need to skip custom post processor
         * @param {Object[]} options.config[].dependentStoreKeys All dependent store keys that needs to be updated.
         * @param {String} options.config[].dependentStoreKeys[].key Dependent Store Key Name
         * @param {Object} [options.config[].dependentStoreKeys[].states] The store key status to update on failure/success
         * @param {Boolean} [options.config[].dependentStoreKeys[].states.error] The store key status to update on error
         * @param {Boolean} [options.config[].dependentStoreKeys[].states.success] The store key status to update on success
         * @param {Object} [options.config[].dependentStoreKeys[].postProcessor = 'baseProcessor'] The custom post processor to use.
         * @param {*} options.requestType The type of request, Enum: [INIT, REFRESH, PAGINATE]
         */
        onAction = (options = {}) => {
            const {
                sync = false,
                config = [],
            } = options;
            const result = sync
                ? this.syncProcessActions(config)
                : this.asyncProcessActions(config);
            return result;
        }


        /**
        * @description It constructs the batch for API Q & call BatchProcessor's nextBatch.
        * @param {Object} option option config
        */
        processAction = (option) => {
            const onActionAPIQueue = [];
            const { all: allKeys } = configLoader.get();
            const { api: apiConfig, store, method } = option;
            if (store) {
                const { key: storeKey = '' } = store;
                // Check if component has access to this storeKey
                if (allKeys) {
                    if (allKeys.indexOf(storeKey) > -1) {
                        if (store && apiConfig) { onActionAPIQueue.push(storeKey); }
                        batchProcessor.nextBatch(onActionAPIQueue, method, option);
                    } else {
                        console.error(`Component: ${ComponentName} cannot call ${storeKey}`);
                    }
                } else {
                    console.error('Component cannot access this store key');
                }
            } else if (apiConfig) {
                // Bypassing store
                batchProcessor.nextBatch([], method, option);
            }
        }

        processActionCb = (err, res) => {
            if (err) {
                console.error('Error while process options', err);
                this.pendingOptions = [];
            } else if (res) {
                if (this.pendingOptions && this.pendingOptions.length > 0) {
                    this.pendingOptions.splice(0, 1);
                    this.syncProcessActions(this.pendingOptions);
                }
            }
            if (this.componentCb) {
                this.componentCb(err, res);
            }
        }

        /**
        * @description Processes the config one after another
        * @param {Array[Object]} options options config
        */
        syncProcessActions = (options) => {
            if (options.length > 1) {
                // Need to replace callback
                this.pendingOptions = options;
                const [option] = this.pendingOptions;
                this.componentCb = option.callback;
                option.callback = this.processActionCb;
                this.processAction(option);
            } else if (options.length === 1) {
                const [option] = options;
                this.processAction(option);
            }
        }

        /**
        * @description Processes the configs at the same time
        * @param {Array[Object]} options options config
        */
        asyncProcessActions = (options = []) => {
            options.forEach((option) => {
                this.processAction(option);
            });
        }

        /**
         * @description Since MSTP initializes the config, we can start resoving the dependencies(If Any)
         * @param {*} props The props passed to the component
        */
        initialize = (props) => {
            this.resolveDependency(props);
        }

        /**
         * wait until criticals are loaded and fetch lazy dependencies
         */
        resolveDependency = (props) => {
            const componentConfig = configLoader.get();
            const {
                permission,
                match,
                location,
                history,
            } = props;
            const { permissionKey } = componentConfig;
            const routeProps = { match, location, history };
            batchProcessor.init(componentConfig, props.dispatch, routeProps);
            if (!permission || !permissionKey || hasPermission(permission, permissionKey)) {
                batchProcessor.start();
            } else {
                this.unauthorised = true;
            }
        }

        // This method bypasses onAction & directly dispatches event to store.
        // Please carefully send the store keys to clear
        clearKeys = (storeKeys = []) => {
            batchProcessor.clearKeys(storeKeys);
        }

        /**
         * @description To fetch lazy storeKeys
         * @param {*} method
         * @param {*} options For dynamic config
         */
        fetchLazy(method = 'read', options) {
            // Get the lazy keys for current component
            batchProcessor.nextBatch([...configLoader.get().lazy] || [], method, options);
        }

        clearStore() {
            batchProcessor.clearStore();
        }

        render() {
            const { onMount, lazy = [] } = configLoader.get();
            if (batchProcessor.isError()) {
                const props = {
                    ...this.props,
                    routeList: configLoader.getRouteList(),
                    isError: true,
                };
                return React.createElement(SmartComponent, props);
            }
            // Determining if criticals are loaded. Not lazy loading
            if (!batchProcessor.isLoading() || this.unauthorised) {
                const props = {
                    onMountConfig: onMount,
                    onAction: this.onAction,
                    clearStoreKeys: this.clearKeys,
                    clear: this.clearStore,
                    ...this.props,
                    unauthorised: this.unauthorised,
                    routeList: configLoader.getRouteList(),
                    fetchLazy: lazy.length > 0 ? this.fetchLazy : undefined,
                };
                const SmartElement = React.createElement(SmartComponent, props);
                return shouldWrapWithErrorBoundaries
                    ? <ErrorBoundaries>{SmartElement}</ErrorBoundaries>
                    : SmartElement;
            }
            return <Loader />;
        }
    }
    return connect(mapStateToProps)(ProviderHOC);
};
export default Provider;
