import { withRouter } from 'Commons/components/business/provider/WithRouterHoc';
import { withStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import hasPermission from 'Commons/helpers/utils/PermissionChecker';
import { SOURCE, FEATURE } from 'Commons/config/constants/Constants';
import formattersStyle from '../styles/FormattersStyles';
import { belongsToAnyOfGivenSources } from '../utils/Helper';

import * as Formatters from './Formatters';

const moveInVisible = (permission, source) => hasPermission(permission, 'moveIn')
&& !belongsToAnyOfGivenSources(source, [SOURCE.user.value, SOURCE.configuration.value]);

const moveOutVisible = (permission, source) => hasPermission(permission, 'moveOut')
&& !belongsToAnyOfGivenSources(source, [SOURCE.user.value, SOURCE.configuration.value, SOURCE.lead.value]);

const paymentVisible = (permission, source) => hasPermission(permission, FEATURE.payment.value)
&& !belongsToAnyOfGivenSources(source, [SOURCE.user.value, SOURCE.configuration.value, SOURCE.lead.value]);

const promoPlanVisible = (permission, source) => belongsToAnyOfGivenSources(source, [SOURCE.promoPlan.value]);

const useFormatter = ({ source = 'tenant', formattersRequested = [], stylesheet }) => formattersRequested
    .reduce((allRequestedFormatters, formatter) => {
        const Component = Formatters[formatter];
        if (!Component) { throw new Error(`${formatter} not found`); }

        const Formatter = withRouter(withStyles(theme => ({
            ...formattersStyle(theme),
            ...(typeof stylesheet === 'function' ? stylesheet(theme) : stylesheet),
        }))(Component, { index: 1 })); // To Prioritize Formatters Style over Table Style

        return {
            ...allRequestedFormatters,
            [formatter]: (...data) => {
                let actionsVisibility = {};
                if (formatter === 'Action') {
                    const [,,,, { permission } = {}] = data || [];
                    const isMoveInVisible = moveInVisible(permission, source);
                    const isMoveOutVisible = moveOutVisible(permission, source);
                    const isPaymentVisible = paymentVisible(permission, source);
                    const isPromoPlanVisible = promoPlanVisible(permission, source);
                    actionsVisibility = { isMoveInVisible, isMoveOutVisible, isPaymentVisible, isPromoPlanVisible };
                }
                return <Formatter argument={data} source={source} {...actionsVisibility} />;
            },
        };
    }, {});

export default useFormatter;
