const getRelationalDataMappedById = (relationalData = {}) => Object.keys(relationalData)
    .reduce((acc, key) => {
        const relationalDataPerKey = relationalData[key];
        return {
            ...acc,
            [key]: Array.isArray(relationalDataPerKey)
                ? relationalDataPerKey.reduce((nestedAcc, dataPerKey) => {
                    const { id } = dataPerKey;
                    return {
                        ...nestedAcc,
                        [id]: dataPerKey,
                    };
                }, {})
                : relationalDataPerKey,
        };
    }, {});

const mapRelationalData = (processedData = {}, keys = [], isRelationalDataAlreadyMappedById = false) => {
    let mappedData = processedData || {};
    const { data = {}, data: { data: nestedData = [], relationalData = {} } = {} } = mappedData;
    let updatedRelationalData = relationalData || {};
    if (!isRelationalDataAlreadyMappedById) {
        updatedRelationalData = getRelationalDataMappedById(updatedRelationalData);
    }
    mappedData = {
        ...mappedData,
        data: {
            ...data,
            data: nestedData.map(eachData => keys.reduce((acc, { dataKey, relationalDataKey }) => {
                // to handle nested key data
                const dataKeyArray = dataKey.split('.');
                const keyToReplace = dataKeyArray.reduce((accData, key) => accData[key] || {}, eachData);
                return { ...acc, [relationalDataKey]: updatedRelationalData[relationalDataKey][keyToReplace] };
            }, eachData)),
            relationalData: updatedRelationalData,
        },
    };
    return mappedData;
};

export { mapRelationalData, getRelationalDataMappedById };
