import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo, useMemo } from 'react';
import { DROPDOWN_MAX_HEIGHT } from 'External/containers/configuration/config/Constants';
import { getOptionProps, optionsFormatter } from 'Commons/helpers/utils/SDFormatter';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import { EMPTY_LIST } from 'Commons/config/constants/Constants';
import { STATUS } from '../config/Constants';
import { getLateEventApiConfig } from '../config/ApiRequests';
import DelinquencyTrackingSchedulerFilterStyle from '../styles/DelinquencyTrackingSchedulerFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';


const useStyles = makeStyles(
    DelinquencyTrackingSchedulerFilterStyle, { name: 'DelinquencyTrackingSchedulerFilterStyle' },
);

const DelinquencyTrackingSchedulerFilter = ({
    entityId = '', accountId = '', formProps = {},
    formProps: {
        setFieldValue,
        values: { terms: { lateEventConfigId = EMPTY_LIST } = {} } = {},
    } = {},
}) => {
    const classes = useStyles();
    const lateEventApiConfig = useMemo(
        () => getLateEventApiConfig(entityId, accountId), [entityId, accountId],
    );
    const onSelectOrRemoveGateType = (selectedUnitTypes) => {
        setFieldValue('terms.lateEventConfigId', Object.keys(selectedUnitTypes));
    };

    return (
        <>
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.facilityType}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={STATUS}
                row
                noGrid
                noIndicatorWrapper
            />
            <RangeFilter
                isDateRange
                name="scheduledDate"
                formProps={formProps}
                setFieldValue={setFieldValue}
                label={localisable.dateRange}
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.selectEvent}
            </Typography>
            <MultiSelect
                placeholder={localisable.selectEvent}
                onSelect={onSelectOrRemoveGateType}
                getOptionProps={getOptionProps}
                keyToMatch="id"
                apiConfig={lateEventApiConfig}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                autoSuggestKey="matchPhrasePrefix.value"
                optionFormatter={(options = {}) => optionsFormatter(options, null, 'Late_Event')}
                onRemove={onSelectOrRemoveGateType}
                initiallySelectedValues={lateEventConfigId}
            />
        </>
    );
};

DelinquencyTrackingSchedulerFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
    accountId: PropTypes.string,
};

DelinquencyTrackingSchedulerFilter.defaultProps = { entityId: '' };

export default memo(DelinquencyTrackingSchedulerFilter);
