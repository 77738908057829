import { VIEW } from 'Commons/config/constants/Constants';
import { UNIT_ENDPOINTS } from 'Commons/config/constants/Endpoints';

const getApiConfig = (selectedFacility, isAutoSuggest = false) => ({
    api: {
        dynamic: {
            endPoint: isAutoSuggest ? UNIT_ENDPOINTS.autoSuggest : UNIT_ENDPOINTS.search,
            body: { view: VIEW.detail.value },
            headers: {
                'context-id': selectedFacility,
                'context-type': 'Facility',
            },
        },
    },
});

const getUnitApiConfig = selectedFacility => ({
    search: getApiConfig(selectedFacility),
    autoSuggest: getApiConfig(selectedFacility, true),
    sendCustomHeader: false,
});

export { getUnitApiConfig };
