const radioStyle = theme => ({
    root: {},
    readOnly: {
        color: theme.palette.text.disabled,
        pointerEvents: 'none',
    },
    InheritedConfigLabel: { pointerEvents: 'none' },
});

export default radioStyle;
