import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo, useMemo } from 'react';
import { DROPDOWN_MAX_HEIGHT } from 'External/containers/configuration/config/Constants';
import { getOptionProps, optionsFormatter } from 'Commons/helpers/utils/SDFormatter';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import { DELINQUENCY_STATUS, EMPTY_LIST } from 'Commons/config/constants/Constants';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { getLateEventApiConfig } from '../config/ApiRequests';
import DelinquencyTrackingLateEventFilterStyle from '../styles/DelinquencyTrackingLateEventFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';

const useStyles = makeStyles(
    DelinquencyTrackingLateEventFilterStyle, { name: 'DelinquencyTrackingLateEventFilterStyle' },
);

const DelinquencyTrackingLateEventFilter = ({
    entityId, accountId, extraFilters, formProps = {},
    formProps: {
        setFieldValue,
        values: { terms: { lateEventConfigId = EMPTY_LIST } = {} } = {},
    } = {},
}) => {
    const classes = useStyles();
    const lateEventApiConfig = useMemo(
        () => getLateEventApiConfig(entityId, accountId, extraFilters), [entityId, accountId],
    );
    const ALLOWED_LATE_EVENT_STATUS = useConstructor(() => [
        {
            label: 'Pending',
            value: DELINQUENCY_STATUS.Active.value,
        },
        DELINQUENCY_STATUS.Processed,
        DELINQUENCY_STATUS.Skipped,
        DELINQUENCY_STATUS.Waived,
    ]);

    const onSelectOrRemoveGateType = (selectedUnitTypes) => {
        setFieldValue('terms.lateEventConfigId', Object.keys(selectedUnitTypes));
    };

    return (
        <>
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={ALLOWED_LATE_EVENT_STATUS}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.selectEvent}
            </Typography>
            <MultiSelect
                placeholder={localisable.selectEvent}
                onSelect={onSelectOrRemoveGateType}
                getOptionProps={getOptionProps}
                keyToMatch="id"
                apiConfig={lateEventApiConfig}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                autoSuggestKey="matchPhrasePrefix.value"
                optionFormatter={(options = {}) => optionsFormatter(options, null, 'Late_Event')}
                onRemove={onSelectOrRemoveGateType}
                initiallySelectedValues={lateEventConfigId}
            />
            <RangeFilter
                isDateRange
                disableFuture
                name="effectiveDate"
                formProps={formProps}
                setFieldValue={setFieldValue}
                label={localisable.dateRange}
            />
        </>
    );
};

DelinquencyTrackingLateEventFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
    accountId: PropTypes.string,
    extraFilters: PropTypes.object,
};

DelinquencyTrackingLateEventFilter.defaultProps = {
    entityId: '',
    accountId: '',
    extraFilters: {},
};

export default memo(DelinquencyTrackingLateEventFilter);
