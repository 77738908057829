import { memo } from 'react';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import localisable from 'Commons/config/strings/localisable';
import OVERLOCK_UNLOCK_PENDING_STATUS
from 'Commons/components/business/filtersAndSorts/components/pendingOverlockUnlock/config/Constants';
import PendingOverlockUnlockFilterStyle from '../styles/PendingOverlockUnlockFilterStyle';

const useStyles = makeStyles(PendingOverlockUnlockFilterStyle, { name: 'PendingOverlockUnlockFilterStyle' });


const PendingOverlockUnlockFilter = () => {
    const classes = useStyles();

    return (
        <Grid container>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.overlockStatus"
                list={Object.values(OVERLOCK_UNLOCK_PENDING_STATUS)}
                noGrid
                noIndicatorWrapper
                required
                hideErrorText
            />
        </Grid>
    );
};

export default memo(PendingOverlockUnlockFilter);
