import { Grid, withStyles } from 'Generic/componentlibrary/components/Components';
import Link from 'Generic/link/components/Link';
import Typography from 'Generic/typography/components/Typography';
import { clsx } from 'Commons/helpers/utils/clsx';
import { DASHBOARD_TYPE } from 'Commons/config/constants/Constants';
import INTERNAL_ROUTES from 'Internal/redux/config/RouteNames';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import GeneralPagesStyles from '../styles/GeneralPagesStyles';

import { variantConfig, VARIANT } from '../config/Config';
import localisable from '../../../../config/strings/localisable';


class GeneralPages extends React.PureComponent {
    renderPageLink = (variant) => {
        const { classes } = this.props;
        return (
            <button type="button" className={classes.pageLinkButton}>
                <Typography
                    className={classes.pageLink}
                >
                    {
                        variant === VARIANT.SERVER_ERROR
                            ? localisable.reportIssue
                            : localisable.goToHomePage
                    }
                </Typography>
            </button>
        );
    }

    render() {
        const { classes, variant, useAnchorTag, facilityId, dashboardType } = this.props;
        const { source, header, title, imgAlt } = variantConfig[variant];
        const { deviceInfo: { isDesktop }, accountId } = window;
        let defaultPageUrl = facilityId ? buildUrl('facilitySelect', { fid: facilityId }, DASHBOARD_TYPE.EXTERNAL, accountId) : '/a';
        if (dashboardType === DASHBOARD_TYPE.INTERNAL) defaultPageUrl = `/${INTERNAL_ROUTES.INTERNAL_MAIN}`;
        return (
            <>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    wrap="nowrap"
                    className={clsx(classes.pageContainer, { [classes.fullHeight]: useAnchorTag })}
                >
                    <Grid
                        container
                        className={classes.imageContainer}
                        alignItems="center"
                        justify="center"
                        item
                        xs={12}
                        lg={6}
                    >
                        <img className={classes.image} src={source} alt={imgAlt} />
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        direction="column"
                        item
                        xs={12}
                        lg={6}
                    >
                        <Grid
                            container
                            alignItems="center"
                            justify="center"
                            direction="column"
                        >
                            <Typography
                                align={!isDesktop ? 'center' : 'left'}
                                className={classes.header}
                                fontFamily="Oxygen, sans-serif"
                            >
                                {header}
                            </Typography>
                            <Typography
                                align={!isDesktop ? 'center' : 'left'}
                                className={classes.title}
                                fontFamily="Patrick Hand, cursive"
                            >
                                {title}
                            </Typography>
                        </Grid>
                        {
                            variant !== VARIANT.SERVER_ERROR // Uncomment this when internal server error issue is speced out
                            && (
                                <Grid className={classes.linkContainer}>
                                    {
                                        useAnchorTag
                                            ? (
                                                <a className={classes.link} href={defaultPageUrl}>
                                                    {this.renderPageLink(variant)}
                                                </a>
                                            )
                                            : (
                                                <Link to={defaultPageUrl}>
                                                    {this.renderPageLink(variant)}
                                                </Link>
                                            )
                                    }
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </>
        );
    }
}

GeneralPages.propTypes = {
    classes: PropTypes.object,
    variant: PropTypes.number,
    useAnchorTag: PropTypes.bool,
    facilityId: PropTypes.string,
    dashboardType: PropTypes.string,
};

GeneralPages.defaultProps = {
    variant: VARIANT.NOT_FOUND,
    useAnchorTag: false,
    dashboardType: DASHBOARD_TYPE.EXTERNAL,
};

export default withStyles(GeneralPagesStyles)(GeneralPages);
