const calculateTextWidth = (
    text,
    fontSize = 16,
    fontWeight = '600',
    fontFamily = 'Open Sans',
    fontStyle = 'normal',
    fontVariant = 'normal',
) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize} ${fontFamily}`;
    return context.measureText(`${text}`).width;
};

export default calculateTextWidth;
