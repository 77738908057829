import { VIEW } from 'Commons/config/constants/Constants';

const getApiConfig = (contextId, view = VIEW.detail.value) => {
    const endPoint = 'user/search';
    const apiConfig = {
        api: {
            dynamic: {
                endPoint,
                body: { view },
                headers: {
                    'context-id': contextId,
                    'context-type': 'Account',
                },
            },
        },
    };
    return apiConfig;
};

const getUserApiConfig = accountId => ({
    search: getApiConfig(accountId),
    autoSuggest: getApiConfig(accountId),
    sendCustomHeader: false,
});
export { getUserApiConfig };
