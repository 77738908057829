const bevStyles = theme => ({
    form: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    page: { overflow: 'hidden' },
    pageBody: {
        flexWrap: 'nowrap',
        backgroundColor: 'transparent',
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
    },
    footer: { marginLeft: theme.spacing(1) },
    canvasGrid: {
        position: 'relative',
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        overflow: 'hidden',
    },
    viewCanvasGrid: { marginLeft: 0, overflow: 'hidden' },
    canvasWrapper: { outline: 'none', position: 'absolute', top: 0, left: 0 },
    canvasHeader: {
        marginLeft: theme.spacing(1),
        marginBottom: 0,
        borderBottomLeftRadius: 0,
        padding: 0,
    },
    viewCanvasHeader: { marginLeft: 0 },
    levelManagementButton: { marginRight: theme.spacing(4) },
    canvasMask: {
        top: 0,
        left: 0,
        position: 'absolute',
        height: '100%',
        backgroundColor: theme.hexWithOpacity(theme.palette.common.white, 5),
        zIndex: 1000,
    },
    loader: {
        height: '100%',
        position: 'absolute',
        backgroundColor: theme.hexWithOpacity(theme.palette.common.white, 5),
    },
    fullPageLoader: {
        height: '100%',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
    },
});

export default bevStyles;
