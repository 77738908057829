import { clsx } from 'Commons/helpers/utils/clsx';
import { Grid, withStyles } from '../../componentlibrary/components/Components';
import labelWithIconStyle from '../styles/LabelWithIconStyle';


class LabelWithIcon extends React.Component {
    render() {
        const {
            children, addon: { start, end }, classes, className, onClick,
            enablePointerEventsWithoutOnClick = false, ...props
        } = this.props;
        return (
            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                className={clsx(classes.labelWithIcon,
                    className,
                    { [classes.noPointerEvent]: !onClick && !enablePointerEventsWithoutOnClick })}
                onClick={onClick}
                {...props}
            >
                {start && <div className={classes.addonStart}>{start}</div>}
                {children && <div className={`${classes.label} ${start ? classes.spacing : ''}`}>{children}</div>}
                {end && <div className={`${classes.addonEnd} ${children ? classes.spacing : ''}`}>{end}</div>}
            </Grid>
        );
    }
}

LabelWithIcon.propTypes = {
    addon: PropTypes.shape({
        start: PropTypes.node,
        end: PropTypes.node,
    }),
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    enablePointerEventsWithoutOnClick: PropTypes.bool,
};

LabelWithIcon.defaultProps = {
    addon: {},
    className: '',
    enablePointerEventsWithoutOnClick: false,
};

export default withStyles(labelWithIconStyle)(LabelWithIcon);
