import { convertToNumber } from 'Commons/helpers/utils/Utils';

const generateRandomNumber = (minRange = 0, maxRange = 1, numbersToAvoid = {}) => {
    let randomNumber;
    const max = convertToNumber(maxRange);
    const min = convertToNumber(minRange);
    while (numbersToAvoid[randomNumber] || !randomNumber) {
        randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    return randomNumber;
};

export { generateRandomNumber };
