import { EMPTY_STRING, STATUS, VIEW } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import { VARIANT } from 'Generic/snackbar/config/Constants';
import LabelWithAddons from 'Generic/labelwithaddons/components/LabelWithAddons';
import Icon from 'Generic/icon/components/Icon';
import { ENTITY_LEVEL_MAPPING } from 'External/containers/configuration/config/EntityLevel';
import REQUEST_TYPES from 'Commons/config/constants/RequestTypes';
import { PRINTER_ENDPOINTS } from 'Commons/config/constants/Endpoints';
import PrinterUnavailable from '../../assets/images/PrinterUnavailable.svg';
import PrinterAvailable from '../../assets/images/PrinterAvailable.svg';
import PrinterAvailableWhite from '../../assets/images/PrinterAvailableWhite.svg';
import PrinterUnavailableWhite from '../../assets/images/PrinterUnavailableWhite.svg';

const getPrinterAvailableIcon = requireWhite => (requireWhite ? PrinterAvailableWhite : PrinterAvailable);
const getPrinterUnavailableIcon = requireWhite => (requireWhite ? PrinterUnavailableWhite : PrinterUnavailable);

const getPrinterIcon = (isOnline, renderWhite) => (
    // <Icon
    //     type="custom"
    //     icon={isOnline ? 'cp-printer-available' : 'cp-printer-unavailable'}
    //     display="block"
    //     size="medium"
    // />
    // NOTE: Following is temporary, until we figure out the multi color font icon issue
    <Icon>
        <img
            src={isOnline ? getPrinterAvailableIcon(renderWhite) : getPrinterUnavailableIcon(renderWhite)}
            alt="printer"
            width={24}
            height={24}
        />
    </Icon>
);


const getPrinterLabel = (
    { isOnline, printerName, metaInfo: { printerAlias = EMPTY_STRING } = {} } = {},
    addIcon,
    renderWhite = false, // NOTE: Temporary until we fix the multi color font icon issue
) => {
    const label = printerAlias || printerName;
    return (
        addIcon
            ? (
                <LabelWithAddons
                    start={getPrinterIcon(isOnline, renderWhite)}
                >
                    {label}
                </LabelWithAddons>
            )
            : label
    );
};

const getPrinterInfo = (err, data, setSnackbarProps, labelWithIcon = false) => {
    let defaultPrinterId;
    let printerList = [];
    const printerObject = {};
    if (err) {
        setSnackbarProps(true, localisable.somethingWentWrong, VARIANT.error.value);
    } else {
        printerList = data.map((printerInfo) => {
            const { id, isDefault } = printerInfo;
            if (isDefault) {
                defaultPrinterId = id;
            }
            printerObject[id] = printerInfo;
            return {
                label: getPrinterLabel(printerInfo, labelWithIcon),
                value: id,
            };
        });
    }
    return { defaultPrinterId, printerList, printerObject };
};

const callApi = (onAction, endPoint, body, callback = () => {}, customHeaderOptions,
    method = 'POST', storeMethod = 'read', store = null, requestType = REQUEST_TYPES.INIT) => {
    const api = {
        method: storeMethod,
        action: {
            methodType: method,
            endPoint,
            body,
            ...customHeaderOptions && { customHeaderOptions },
        },
    };
    if (onAction) {
        onAction({
            config: [{
                api,
                requestType,
                callback,
                ...store && { store: { key: store } },
            }],
        });
    }
};

const getRequestHeaders = (selectedEntityAndLevel) => {
    const { selectedLevel, selectedEntity } = selectedEntityAndLevel || {};
    return {
        type: 'default',
        context: { id: selectedEntity, type: ENTITY_LEVEL_MAPPING[selectedLevel].value },
    };
};

const fetchPrinter = (onAction,
    selectedEntityAndLevel, callback,
    requestType = REQUEST_TYPES.INIT, store = 'printer') => {
    const customHeader = getRequestHeaders(selectedEntityAndLevel);
    const body = { view: VIEW.detail.value, filter: [{ terms: { status: [STATUS.Active.value] } }] };
    callApi(onAction, PRINTER_ENDPOINTS.search, body, callback, customHeader, 'POST', 'read', store, requestType);
};

export { getPrinterInfo, getPrinterLabel, fetchPrinter, getPrinterIcon };
