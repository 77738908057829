import media from 'Commons/theme/MediaQueries';

const TenantInformationStyle = theme => ({
    tenantInfoContainer: {
        [media.mobileUp]: { padding: '8px 16px' },
        [media.tabletUp]: { padding: theme.spacing(0, 4, 2, 4) },
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
    },
    marginFromIconInRightContainer: { marginLeft: 20 },
    input: { width: '100%' },
    ledger: {
        [media.mobileUp]: { paddingTop: theme.spacing(1), width: '52%' },
        [media.tabletUp]: { paddingTop: theme.spacing(4), width: '100%' },
    },
    leftContainer: { [media.tabletUp]: { paddingRight: 16 } },
    rightContainer: {
        [media.mobileUp]: { padding: theme.spacing(2, 0, 0, 0) },
        [media.tabletUp]: { padding: theme.spacing(0, 0, 0, 2) },
    },
    fullWidth: { width: '100%' },
    disabled: { color: theme.palette.text.disabled },
    rightContentTopMargin: { marginTop: 8 },
    defaultCursor: { cursor: 'default' },
    prefilled: {
        marginLeft: theme.spacing(1),
        borderBottom: '1px solid',
        paddingBottom: theme.spacing(1),
        width: '100%',
    },
    ledgerPrefilled: { paddingTop: theme.spacing(4) },
    iconAlignment: { alignItems: 'flex-end' },
    itemLabelContainer: { width: 'auto', overflow: 'hidden' },
    prepayButton: { padding: 0, minHeight: 0, marginTop: 8 },
});

export default TenantInformationStyle;
