import React from 'react';
import { Grid, withStyles, Card as MuiCard }
    from 'Commons/components/generic/componentlibrary/components/Components';
import cardStyle from '../styles/CardStyle';

class Card extends React.Component {
    render() {
        const { addon, children, className, classes, elevation, ...props } = this.props;
        return (
            <MuiCard className={`${classes.card} ${className}`} elevation={elevation} {...props}>
                <Grid container className={`${classes.cardContent}`}>
                    {
                        Object.keys(addon).map((key) => {
                            const { direction: addonDirection, components, spacing, ...GridProps } = addon[key];
                            const direction = (addonDirection === 'column')
                                ? `${addonDirection}-reverse` : addonDirection;
                            return (
                                classes[key]
                                    && (
                                        <Grid
                                            key={key}
                                            className={`${classes.addon} ${classes[key]}`}
                                            spacing={spacing}
                                            direction={direction}
                                            container
                                            {...GridProps}
                                        >
                                            {
                                                components.map((component, index) => (
                                                    <Grid key={index} item>
                                                        {component}
                                                    </Grid>
                                                ))
                                            }

                                        </Grid>
                                    )
                            );
                        })
                    }
                    {children}
                </Grid>
            </MuiCard>
        );
    }
}

Card.propTypes = {
    addon: PropTypes.shape({
        topLeft: PropTypes.shape({
            components: PropTypes.arrayOf(PropTypes.node),
            direction: PropTypes.oneOf(['row', 'column']),
        }),
        topRight: PropTypes.shape({
            components: PropTypes.arrayOf(PropTypes.node),
            direction: PropTypes.oneOf(['row', 'column']),
        }),
        bottomLeft: PropTypes.shape({
            components: PropTypes.arrayOf(PropTypes.node),
            direction: PropTypes.oneOf(['row', 'column']),
        }),
        bottomRight: PropTypes.shape({
            components: PropTypes.arrayOf(PropTypes.node),
            direction: PropTypes.oneOf(['row', 'column']),
        }),
    }),
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    elevation: PropTypes.number,
    spacing: PropTypes.number,
};

Card.defaultProps = {
    addon: {},
    className: '',
    elevation: 0,
    spacing: 0,
};

export default withStyles(cardStyle)(Card);
