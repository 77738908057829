import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import Icon from 'Generic/icon/components/Icon';
import { SEVERITY_LEVEL } from 'External/containers/viewtenant/config/Constants';
import Typography from 'Generic/typography/components/Typography';
import TenantAlertDialogStyles from '../styles/TenantAlertDialogStyles';

const useStyles = makeStyles(TenantAlertDialogStyles, { name: 'TenantAlertDialog' });

const TenantAlertDialog = ({
    isTenantAlertOpen, changeText, changeTextFunc, tenantAlerts,
    onAlertClose, onAlertIgnore, ignoreText, dialogClasses,
}) => {
    const classes = useStyles();

    return (
        <AlertDialog
            open={isTenantAlertOpen}
            onClose={onAlertClose}
            title={localisable.alert}
            dialogClasses={dialogClasses}
            actions={(
                <>
                    <Button
                        onClick={onAlertIgnore}
                        variant="text"
                    >
                        {ignoreText}
                    </Button>
                    {
                        changeText && (
                            <Button
                                onClick={changeTextFunc}
                                variant="text"
                            >
                                {changeText.toUpperCase()}
                            </Button>
                        )
                    }
                </>
            )}
        >
            <Grid container direction="column" wrap="nowrap">
                {
                    tenantAlerts.map(({ comments, severity, id }) => (
                        <Grid container key={id} spacing={1}>
                            <Grid item>
                                <Icon
                                    type="custom"
                                    icon={SEVERITY_LEVEL[severity].icon}
                                    className={severity === SEVERITY_LEVEL.Error.value
                                        ? classes.error : classes.warning}
                                />
                            </Grid>
                            <Grid item className={classes.alignCenter}>
                                <Typography>{comments}</Typography>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </AlertDialog>
    );
};

TenantAlertDialog.propTypes = {
    isTenantAlertOpen: PropTypes.bool,
    changeText: PropTypes.string,
    changeTextFunc: PropTypes.func,
    tenantAlerts: PropTypes.array,
    onAlertIgnore: PropTypes.func,
    onAlertClose: PropTypes.func,
    ignoreText: PropTypes.string,
    dialogClasses: PropTypes.object,
};

TenantAlertDialog.defaultProps = { ignoreText: localisable.ignore };

export default TenantAlertDialog;
