import NotFound from 'Commons/assets/images/NotFound.svg';
import ServerError from 'Commons/assets/images/ServerError.svg';
import UnderMaintenance from 'Commons/assets/images/UnderMaintenance.svg';
import Unauthorised from 'Commons/assets/images/Unauthorised.svg';

import localisable from 'Commons/config/strings/localisable';

const VARIANT = {
    NOT_FOUND: -1,
    SERVER_ERROR: 0,
    UNDER_MAINTENANCE: 1,
    UNAUTHORISED: 2,
};

const variantConfig = {
    [VARIANT.SERVER_ERROR]: {
        source: ServerError,
        header: localisable.serverErrorTitle,
        title: localisable.serverErrorDetail,
        imgAlt: localisable.serverError,
    },
    [VARIANT.UNDER_MAINTENANCE]: {
        source: UnderMaintenance,
        header: localisable.underMaintenanceTitle,
        title: localisable.underMaintenanceDetail,
        imgAlt: localisable.underMaintenanceTitle,
    },
    [VARIANT.NOT_FOUND]: {
        source: NotFound,
        header: localisable.notFoundTitle,
        title: localisable.notFoundDetail,
        imgAlt: localisable.notFound,
    },
    [VARIANT.UNAUTHORISED]: {
        source: Unauthorised,
        header: localisable.noAccess,
        imgAlt: localisable.unauthorised,
    },
};

export { variantConfig, VARIANT };
