/* eslint-disable no-unused-vars */
const DateRangeStyle = theme => ({
    dateContainer: {
        marginLeft: 16,
        marginTop: 16,
    },
    title: { fontWeight: 'bolder' },
});

export default DateRangeStyle;
