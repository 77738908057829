import media from '../../../../../theme/MediaQueries';
import { LIST_ITEMS_FONT_SIZE, LIST_ITEMS_FONT_WEIGHT } from '../config/Constants';

const tenantLeadCardStyle = theme => ({
    typography: { wordBreak: 'break-all' },
    addressLine2: { display: 'inline-block' },
    photo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        border: `1px solid ${theme.palette.disabled}`,
        opacity: 0.8,
        boxSizing: 'border-box',
    },
    tenantImage: {
        [media.smallMobile]: { marginRight: 16 },
        [media.mobile]: { marginRight: 16 },
        marginRight: 32,
    },
    dataContainer: {
        position: 'relative',
        height: '100%',
    },
    textWithChip: {
        '& > :nth-last-child(2)': {
            [media.mobile]: { maxWidth: '70%' },
            maxWidth: '75%',
        },
    },
    name: {
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Open sans',
        color: theme.palette.text.primary,
    },
    card: { fontSize: '0.875rem' },
    disabled: { color: theme.palette.disabled },
    cardChip: {
        position: 'absolute',
        bottom: 2,
        right: 2,
    },
    icon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 'auto',
    },
    icons: {
        marginLeft: 2,
        fontSize: 24,
    },
    fullWidthMax: { maxWidth: '100%' },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    labelsContainer: { display: 'box' },
    label: { '&:hover': { color: theme.palette.primary.main } },
    listItemsFont: { fontSize: `${LIST_ITEMS_FONT_SIZE}`, fontWeight: `${LIST_ITEMS_FONT_WEIGHT}` },
    topMargin: { marginTop: theme.spacing(0.5) },
    topMarginForAddress: { marginTop: theme.spacing(2) },
    fullWidth: { width: '100%' },
});
export default tenantLeadCardStyle;
