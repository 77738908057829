import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { FormDatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import LabelWithIcon from 'Generic/labelwithicon/components/LabelWithIcon';
import { memo } from 'react';
import InterestedUnitTypeSelector from 'External/containers/lead/form/components/crmdetails/components/InterestedUnitTypeSelector';
import { LEAD_STATUS, DATE_FIELD_WIDTH, MOBILE_DATE_FIELD_WIDTH } from '../config/Constants';
import leadFilterStyle from '../styles/LeadFilterStyle';

const useStyles = makeStyles(leadFilterStyle, { name: 'LeadFilter' });

const LeadFilter = ({
    entityId = '',
    formProps,
    formProps: { setFieldValue } = {},
}) => {
    const { deviceInfo: { isMobile } = {} } = window;
    const classes = useStyles({ isMobile });

    const onClearDate = (field) => { setFieldValue(`range.${field}`, {}); };

    const renderDateRangeFilters = () => ['appointmentDate', 'expectedMoveIn'].map(type => (
        <React.Fragment key={type}>
            <Typography variant="subtitle1" className={`${classes.dateRangeLabel} ${type === 'appointmentDate' ? classes.firstLabel : classes.restLabel}`}>
                {type === 'appointmentDate' ? localisable.appointmentBetween : localisable.moveInBetween}
            </Typography>
            <Grid container>
                <Grid item>
                    <FormDatePicker
                        name={`range.${type}.from`}
                        label={localisable.fromDate}
                        noGrid
                        noIndicatorWrapper
                        width={isMobile ? MOBILE_DATE_FIELD_WIDTH : DATE_FIELD_WIDTH}
                    />
                </Grid>
                <Grid item>
                    <FormDatePicker
                        name={`range.${type}.to`}
                        label={localisable.toDate}
                        noGrid
                        noIndicatorWrapper
                        width={isMobile ? MOBILE_DATE_FIELD_WIDTH : DATE_FIELD_WIDTH}
                        className={classes.toDateField}
                    />
                    <LabelWithIcon
                        classes={{ labelWithIcon: classes.autoWidth }}
                        className={classes.clearDate}
                        onClick={() => onClearDate(type)}
                        justify="flex-end"
                    >
                        <Typography color="primary" variant="body2">
                            {localisable.clearDate}
                        </Typography>
                    </LabelWithIcon>
                </Grid>
            </Grid>
        </React.Fragment>
    ));

    return (
        <Grid container direction={!isMobile ? 'row-reverse' : 'column'} className={isMobile ? classes.overflow : ''}>
            <Grid item className={!isMobile ? classes.marginForLeadStatus : ''}>
                <Typography variant="subtitle1" className={classes.firstLabel}>
                    {localisable.leadStatus}
                </Typography>
                <FormCheckboxGroup
                    name="terms.status"
                    list={Object.values(LEAD_STATUS)}
                    noGrid
                    noIndicatorWrapper
                />
            </Grid>
            <Grid item>
                <>
                {renderDateRangeFilters()}
                </>
                <Typography variant="subtitle1" className={`${classes.unitTypeTopMargin} ${classes.restLabel}`}>
                    {localisable.unitType}
                </Typography>
                <InterestedUnitTypeSelector
                    formField="terms.interestedUnitTypes"
                    formProps={formProps}
                    entityId={entityId}
                    isSourceFilter
                />
            </Grid>
        </Grid>
    );
};

LeadFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
};

LeadFilter.defaultProps = { entityId: '' };

export default memo(LeadFilter);
