const CreditCardBatchesFilterStyle = theme => ({
    label: { padding: theme.spacing(2, 2, 2, 0) },
    toDateField: {
        marginLeft: theme.spacing(5),
        marginRight: ({ isMobile }) => theme.spacing(isMobile ? 2 : 0),
    },
    clearDate: {
        alignSelf: 'flex-end',
        paddingTop: theme.spacing(1),
        marginRight: ({ isMobile }) => theme.spacing(isMobile ? 2 : 0),
    },
});

export default CreditCardBatchesFilterStyle;
