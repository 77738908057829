import React, { memo } from 'react';
import { Grid, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { getFirstDateOfMonth, getMonthStartingDayIndex } from 'Commons/helpers/utils/DateTime';
import { addDatePadding, getCompleteDate, getDataForDay } from 'Commons/helpers/utils/CalendarHelper';
import { clsx } from 'Commons/helpers/utils/clsx';
import Day from './Day';
import weekStyle from '../styles/WeekStyle';

const useStyles = makeStyles(weekStyle, { name: 'Week' });

const Week = ({
    renderInRows, calendarWeekStartingDay,
    numberOfWeeksToDisplay, daysOfWeek, numberOfDays, startingDay,
    prevMonthLastDay, month: monthIndex, year: yearNumber, currentDate,
    data, maxVisibleContent, ...restProps
}) => {
    const classes = useStyles({ renderInRows, maxVisibleContent });
    const getDisplayDayInfo = (rowIndex, colIndex, month, year, monthStartingDayIndex = undefined, calendarsWeekStartingDay = 0) => {
        let monthStartingDay = monthStartingDayIndex;
        if (!monthStartingDay) monthStartingDay = getMonthStartingDayIndex(year, month);
        const monthStartingDayAndCalendarStartDayAreSame = monthStartingDay === calendarsWeekStartingDay - 1;
        const weekIndex = monthStartingDayAndCalendarStartDayAreSame ? rowIndex - 1 : rowIndex;
        const dayNumber = weekIndex * 7 + colIndex - monthStartingDay + 1 + calendarsWeekStartingDay;
        const isWithinMonth = dayNumber > 0 && dayNumber <= numberOfDays;
        const isPrevMonth = dayNumber <= 0;
        const isNextMonth = dayNumber > numberOfDays;
        let displayDay = dayNumber;
        const selectedMonthFirstDate = getFirstDateOfMonth(year, month);
        const completeDate = getCompleteDate(selectedMonthFirstDate, displayDay - 1);
        const dataForDay = getDataForDay(data, completeDate) || [];
        if (isPrevMonth) {
            if (monthStartingDayAndCalendarStartDayAreSame) {
                displayDay = prevMonthLastDay + dayNumber;
            } else {
                displayDay = prevMonthLastDay - monthStartingDay + calendarsWeekStartingDay + colIndex + 1;
            }
        }
        if (isNextMonth) displayDay = dayNumber - numberOfDays;
        displayDay = addDatePadding(displayDay);
        const isDisabled = !isWithinMonth;
        return { displayDay, isDisabled, isWithinMonth, isPrevMonth, isNextMonth, dataForDay, completeDate };
    };

    return (
        <>
        {[...Array(numberOfWeeksToDisplay).keys()].map((_, rowIndex) => (
            <Grid key={rowIndex} container xs={renderInRows ? 12 : true} direction={renderInRows ? 'row' : 'column'} className={classes.weekDays}>
                {daysOfWeek.map((__, index) => {
                    const {
                        displayDay,
                        isDisabled,
                        dataForDay = [],
                        completeDate,
                    } = getDisplayDayInfo(rowIndex, index, monthIndex, yearNumber, startingDay, calendarWeekStartingDay);
                    return (
                        <Grid
                            item
                            xs
                            className={clsx(classes.paper, classes.dayContainer)}
                            key={completeDate}
                        >
                            <Day
                                index={index}
                                displayDay={displayDay}
                                completeDate={completeDate}
                                dataForDay={dataForDay}
                                currentDate={currentDate}
                                disabled={isDisabled}
                                renderInRows={renderInRows}
                                maxVisibleContent={maxVisibleContent}
                                {...restProps}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        ))}
        </>
    );
};


Week.propTypes = {
    daysOfWeek: PropTypes.array,
    numberOfWeeksToDisplay: PropTypes.number,
    startingDay: PropTypes.number,
    numberOfDays: PropTypes.number,
    prevMonthLastDay: PropTypes.number,
    calendarWeekStartingDay: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number,
    currentDate: PropTypes.object,
    renderInRows: PropTypes.bool,
    data: PropTypes.object,
    maxVisibleContent: PropTypes.number,
};

Week.defaultProps = {
    numberOfWeeksToDisplay: 6,
    daysOfWeek: [],
    startingDay: 0,
    calendarWeekStartingDay: 0,
    data: {},
    maxVisibleContent: 1,
};


export default memo(Week);
