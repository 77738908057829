import { Grid } from 'Generic/componentlibrary/components/Components';
import { FormDropdown } from 'Generic/dropdown/components/Dropdown';
import { YES_NO_LIST, INSURANCE_SOURCE, EMPTY_OBJECT } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import Typography from 'Commons/components/generic/typography/components/Typography';
import Icon from 'Commons/components/generic/icon/components/Icon';
import getFormattedAmount from 'Commons/helpers/utils/AmountHelper';
import { FormRadioGroup } from 'Generic/radiogroup/components/RadioGroup';
import { XS_6 } from 'Commons/config/constants/GridSpacingConstants';
import { FormTextField } from 'Commons/components/generic/textfield/components/TextField';
import { SMALL_TEXTFIELD_WIDTH } from 'External/containers/configuration/config/Constants';
import { useMemo } from 'react';
import { BILLING_TYPE } from 'External/containers/rentalConfiguration/config/RentalFormConfigs';

const InsuranceBody = ({
    classes, source, insuranceCoverage, insuranceCoverageById, coverageId,
    initialCoverageId, policyRequired, onChangeSkipFirstPeriod,
    facilityProrateInfo: { type: { billing } = {}, isEnabled: isProrateEnabledFacility = false }, skipFirstPeriod,
}) => {
    const isProrateApplicable = useMemo(() => (isProrateEnabledFacility && billing === BILLING_TYPE.Prorate_Second.value
        ? skipFirstPeriod : !skipFirstPeriod
    ), [billing, initialCoverageId, skipFirstPeriod, isProrateEnabledFacility]);

    const proratePremiumLabel = useMemo(() => {
        const periodNumber = (!isProrateEnabledFacility || billing === BILLING_TYPE.Prorate_First.value)
            ? localisable.first.toLowerCase() : localisable.second.toLowerCase();
        return `${localisable.shouldProrate}${' '}${localisable.the.toLowerCase()}${' '}${periodNumber}${' '}
        ${localisable.period.toLowerCase()}`;
    }, [isProrateEnabledFacility, billing]);

    const getCoverageDetails = () => (
        <Grid
            container
            spacing={4}
            className={source === INSURANCE_SOURCE.EDIT_UNIT ? classes.policyPremium : classes.policyPremiumMoveIn}
        >
            <Grid container item direction="column" xs={6}>
                <Typography variant="caption" className={classes.heading}>
                    {localisable.monthlyPremium}
                </Typography>
                <Grid container>
                    <Icon type="custom" icon="cp-dollar" size="medium" />
                    <Typography className={classes.amountField}>
                        {getFormattedAmount(insuranceCoverageById[coverageId]
                            ? insuranceCoverageById[coverageId].monthlyPremium : 0)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item direction="column" xs={6}>
                <Typography variant="caption" className={classes.heading}>
                    {localisable.coverageAmount}
                </Typography>
                <Grid container>
                    <Icon type="custom" icon="cp-dollar" size="medium" />
                    <Typography className={classes.amountField}>
                        {getFormattedAmount(insuranceCoverageById[coverageId]
                            ? insuranceCoverageById[coverageId].coverageAmount : 0)}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );

    const getSkipFirstPeriodDetails = () => (
        <FormRadioGroup
            label={localisable.skipFirstPeriod}
            name={`${source === INSURANCE_SOURCE.EDIT_UNIT ? 'insurance' : 'extra'}.skipFirstPeriod`}
            list={YES_NO_LIST}
            row
            disabled={!coverageId}
            key={coverageId}
            formVariant={VARIANT.NO_PADDING}
            noIndicatorWrapper
            defaultValueWhenNull={false}
            onChange={onChangeSkipFirstPeriod}
            GridProps={{ className: classes.formFieldMargin }}
        />
    );

    const getProrateDetails = () => (
        <FormRadioGroup
            label={proratePremiumLabel}
            name={`${source === INSURANCE_SOURCE.EDIT_UNIT ? 'insurance' : 'extra'}.proratePremium`}
            list={YES_NO_LIST}
            row
            disabled={!coverageId}
            key={coverageId}
            formVariant={VARIANT.NO_PADDING}
            noIndicatorWrapper
            defaultValueWhenNull={false}
            GridProps={{ className: classes.formFieldMargin }}
        />
    );

    const getCoverageDropdown = () => (
        <FormDropdown
            name={`${source === INSURANCE_SOURCE.EDIT_UNIT ? 'insurance' : 'extra'}.coverageId`}
            list={insuranceCoverage}
            label={localisable.planName}
            placeholder={localisable.planName}
            formVariant={VARIANT.NO_PADDING}
            noIndicatorWrapper
            key={insuranceCoverage.length}
        />
    );

    const getPolicyDetails = () => (
        <FormTextField
            key={coverageId}
            name={`${source === INSURANCE_SOURCE.EDIT_UNIT ? 'insurance' : 'extra'}.policyId`}
            label={localisable.policyNumber}
            placeholder={localisable.policyNumber}
            width={SMALL_TEXTFIELD_WIDTH}
            disabled={source === INSURANCE_SOURCE.EDIT_UNIT
                ? coverageId === initialCoverageId
                : !coverageId}
            required={coverageId ? policyRequired : false}
            formVariant={VARIANT.NO_LEFT_RIGHT_PADDING}
            GridProps={XS_6}
        />
    );

    return (
        source === INSURANCE_SOURCE.EDIT_UNIT
            ? (
                <Grid container className={classes.fitHeight}>
                    <Grid container item xs={6}>
                        {getCoverageDropdown()}
                    </Grid>
                    <Grid container item xs={6}>
                        {getCoverageDetails()}
                    </Grid>
                    {
                        coverageId
                        && (
                            <>
                                <Grid container>{getSkipFirstPeriodDetails()}</Grid>
                                {isProrateEnabledFacility && isProrateApplicable && <Grid container>{getProrateDetails()}</Grid>}
                            </>
                        )
                    }
                    {getPolicyDetails()}
                </Grid>
            )
            : (
                <Grid container alignItems="center">
                    {getCoverageDropdown()}
                    <Grid container className={classes.coverageDetails}>
                        {getCoverageDetails()}
                    </Grid>
                    <Grid container className={classes.coverageDetails}>
                        {
                            coverageId
                            && (
                                <>
                                    {getSkipFirstPeriodDetails()}
                                    {isProrateEnabledFacility && isProrateApplicable && getProrateDetails()}
                                </>
                            )

                        }
                    </Grid>
                    {getPolicyDetails()}
                </Grid>
            )
    );
};

InsuranceBody.propTypes = {
    source: PropTypes.string,
    classes: PropTypes.object,
    coverageId: PropTypes.string,
    policyRequired: PropTypes.bool,
    skipFirstPeriod: PropTypes.bool,
    insuranceCoverage: PropTypes.array,
    initialCoverageId: PropTypes.string,
    facilityProrateInfo: PropTypes.object,
    insuranceCoverageById: PropTypes.object,
    onChangeSkipFirstPeriod: PropTypes.func,
};

InsuranceBody.defaultProps = { facilityProrateInfo: EMPTY_OBJECT };

export default InsuranceBody;
