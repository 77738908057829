import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import { BATCH_PAYMENT_TASKS } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { FormDatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import LabelWithIcon from 'Generic/labelwithicon/components/LabelWithIcon';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { DATE_FIELD_WIDTH, MOBILE_DATE_FIELD_WIDTH } from '../../leadFilter/config/Constants';
import CreditCardBatchesFilterStyle from '../styles/CreditCardBatchesFilterStyle';


const useStyles = makeStyles(CreditCardBatchesFilterStyle, { name: 'CreditCardBatchesFilter' });

const CreditCardBatchesFilter = ({ formProps: { setFieldValue } }) => {
    const onClearDate = () => { setFieldValue('range.createdDate', {}); };
    const classes = useStyles();
    const { labelWithIconClass } = useConstructor(() => ({ labelWithIconClass: { labelWithIcon: classes.autoWidth } }));

    const { deviceInfo: { isMobile } = {} } = window;
    return (
        <>
            <Typography variant="h6" className={classes.label}>
                {localisable.filters}
            </Typography>
            <Typography variant="subtitle1" className={classes.label}>
                {localisable.selectMode}
            </Typography>
            <FormCheckboxGroup
                name="terms.task"
                list={Object.values(BATCH_PAYMENT_TASKS)}
                row
                noGrid
                noIndicatorWrapper
            />
            <Typography variant="subtitle1" className={classes.label}>
                {localisable.batchCreatedBetween}
            </Typography>
            <Grid container>
                <Grid item>
                    <FormDatePicker
                        name="range.createdDate.from"
                        label={localisable.fromDate}
                        noGrid
                        noIndicatorWrapper
                        width={isMobile ? MOBILE_DATE_FIELD_WIDTH : DATE_FIELD_WIDTH}
                    />
                </Grid>
                <Grid item>
                    <FormDatePicker
                        name="range.createdDate.to"
                        label={localisable.toDate}
                        noGrid
                        noIndicatorWrapper
                        width={isMobile ? MOBILE_DATE_FIELD_WIDTH : DATE_FIELD_WIDTH}
                        className={classes.toDateField}
                    />
                    <LabelWithIcon
                        classes={labelWithIconClass}
                        className={classes.clearDate}
                        onClick={onClearDate}
                        justify="flex-end"
                    >
                        <Typography color="primary" variant="body2">
                            {localisable.clearDate}
                        </Typography>
                    </LabelWithIcon>
                </Grid>
            </Grid>
        </>
    );
};

CreditCardBatchesFilter.propTypes = { formProps: PropTypes.object };

export default memo(CreditCardBatchesFilter);
