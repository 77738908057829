import { memo, useMemo } from 'react';
import LevelSelector from 'Commons/components/business/bev/components/common/components/LevelSelector';
import Button from 'Generic/button/components/Button';
import { Grid, Loader, makeStyles, Typography } from 'Generic/componentlibrary/components/Components';
import { ZOOM_BY, ZOOM_LIMITS, ZOOM_VALUE_DISPLAY_OFFSET } from 'Commons/components/business/bev/config/Config';
import Icon from 'Generic/icon/components/Icon';
import canvasHeaderStyles from 'Commons/components/business/bev/components/common/styles/CanvasHeaderStyles';
import localisable from 'Commons/config/strings/localisable';
import { SmartSearchableDropdown } from 'Commons/components/generic/dropdown/components/SearchableDropdown';
import { XS_12 } from 'Commons/config/constants/GridSpacingConstants';
import { MEDIUM_SD_HEIGHT } from 'Commons/components/generic/dropdown/config/Constants';
import { getOptionProps } from 'Commons/helpers/utils/SDFormatter';
import { SOURCE } from 'Commons/config/constants/Constants';
import useDidUpdateEffect from 'Commons/helpers/hooks/useDidUpdateEffect';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { getSearchConfig, refreshColor } from '../../../config/ApiRequests';
import ScrollActionItems from './ScrollActionItems';

const useStyles = makeStyles(canvasHeaderStyles, { name: 'canvasHeader' });


const CanvasHeader = (props) => {
    const {
        levelsList, canvasRef, isSetupMode, zoomValue, onLevelChange, defaultZoomValue,
        saveCurrentZoomAsDefault, isButtonLoading, zoomCanvas, setShowInfoCard, onAction,
        setShowFullPageLoader, setSnackbarProps, handleErrors, unitList = [], selectedLevel,
        setScrollToIndex, searchedUnit, setSearchedUnit, searchedUnit: { label: searchedUnitLabel },
        scrollCanvas,
    } = props;

    const { deviceInfo: { isMobile } } = window;
    const unitApiConfig = useConstructor(() => getSearchConfig(SOURCE.unit.value, undefined));

    const classes = useStyles({ isSetupMode, isMobile });

    const findAndSetUnitIndex = (currentUnitId) => {
        const index = unitList.findIndex(({ id }) => id === currentUnitId);
        setScrollToIndex(index);
    };

    useDidUpdateEffect(() => {
        const { id: currentUnitId } = searchedUnit;
        findAndSetUnitIndex(currentUnitId);
    }, [selectedLevel]);

    const colorRefreshApiResponse = (apiError, response) => {
        if (apiError) {
            handleErrors(apiError);
            setSnackbarProps(true, localisable.bevRefreshColorErrorMsg, 'error');
        } else if (response) {
            setSnackbarProps(true, localisable.colorRefreshInitiatedMsg);
        }
        setShowFullPageLoader(false);
    };

    const onClickRefreshButton = () => {
        setShowFullPageLoader(true);
        refreshColor(onAction, colorRefreshApiResponse);
    };

    const zoomValueToDisplay = useMemo(() => (`${Math.ceil(zoomValue * 100 - ZOOM_VALUE_DISPLAY_OFFSET).toString().split('.')[0]}%`), [zoomValue]); // Displays 60% less than actual zoom that is set. Eg- 100% will be displayed as 40%, 160% as 100%

    const onUnitSearch = (_, __, unitDetails = {}) => {
        const { id: currentUnitId, bev: { level: searchedUnitLevel } = {} } = unitDetails;
        if (searchedUnitLevel) {
            if (selectedLevel === searchedUnitLevel) findAndSetUnitIndex(currentUnitId);
            else onLevelChange(_, searchedUnitLevel, [], true);
        }
        if (!currentUnitId) setScrollToIndex(-1);
        setSearchedUnit(unitDetails);
    };

    const renderScrollActions = () => (
        <ScrollActionItems
            classes={classes}
            canvasRef={canvasRef}
            scrollCanvas={scrollCanvas}
        />
    );

    return (
        <Grid container justify="space-between" alignItems="flex-start" className={classes.canvasHeaderContainer}>
            <Grid item direction="row" wrap="nowrap" container md={6}>
                <Grid item>
                    <Grid className={classes.zoomButtonContainer}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={`${classes.zoomButton} ${classes.zoomOutButton}`}
                            classes={{ disabled: classes.zoomButtonDisabled }}
                            onClick={() => (isSetupMode ? canvasRef.zoom(ZOOM_BY.OUT) : zoomCanvas(ZOOM_BY.OUT))}
                            disabled={zoomValue <= ZOOM_LIMITS.LOWER}
                        >
                            <Icon
                                icon="remove"
                            />
                        </Button>
                        <Typography className={classes.zoomPercentage}>{zoomValueToDisplay}</Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={`${classes.zoomButton} ${classes.zoomInButton}`}
                            classes={{ disabled: classes.zoomButtonDisabled }}
                            onClick={() => (isSetupMode ? canvasRef.zoom(ZOOM_BY.IN) : zoomCanvas(ZOOM_BY.IN))}
                            disabled={zoomValue >= ZOOM_LIMITS.UPPER}
                        >
                            <Icon
                                icon="cp-plus"
                                type="custom"
                            />
                        </Button>
                        <span
                            className={classes.divider}
                        />
                        <Button
                            variant="text"
                            color="primary"
                            className={`${classes.zoomButton} ${classes.textButton} ${classes.resetButton}`}
                            onClick={() => (isSetupMode ? canvasRef.zoom(0, true)
                                : zoomCanvas(0, undefined, undefined, true))} // The second arg of canvasRef.zoom() is shouldReset. false by default
                            disabled={zoomValue === defaultZoomValue}
                        >
                            <Typography className={classes.textFontSize}>{localisable.reset}</Typography>
                        </Button>
                    </Grid>
                    <Grid item container className={classes.setAsDefaultContainer} alignItems="center" justify="center">
                        {
                            zoomValue !== defaultZoomValue && (
                                <>
                                    <Typography
                                        className={classes.textFontSize}
                                    >
                                        {localisable.shouldSetThisAsDefault}
                                    </Typography>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        className={`${classes.zoomButton} ${classes.textButton} 
                                            ${classes.setDefaultButton}`}
                                        onClick={saveCurrentZoomAsDefault}
                                    >
                                        {!isButtonLoading
                                            ? (
                                                <Typography
                                                    className={classes.textFontSize}
                                                >{localisable.yes}
                                                </Typography>
                                            )
                                            : <Loader disableShrink size={24} />
                                        }
                                    </Button>
                                </>
                            )
                        }
                    </Grid>
                </Grid>
                {isSetupMode && renderScrollActions()}
                {!isSetupMode && (
                    <Grid className={classes.searchableUnitDropdownGrid}>
                        <SmartSearchableDropdown
                            value={searchedUnitLabel}
                            options={unitList}
                            placeholder={localisable.selectUnit}
                            GridProps={XS_12}
                            maxHeight={MEDIUM_SD_HEIGHT}
                            keyToMatch="label"
                            getOptionProps={getOptionProps}
                            onChange={onUnitSearch}
                            apiConfig={unitApiConfig}
                            className={classes.searchableUnitDropdown}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item md={6}>
                <Grid container justify="flex-end">
                    <LevelSelector
                        levelsList={levelsList}
                        onLevelChange={onLevelChange}
                    />
                    {!isSetupMode && (
                        <Button onClick={onClickRefreshButton} className={classes.refreshButton}>
                            <Icon
                                type="custom"
                                icon="cp-refresh"
                                color="primary"
                                display="block"
                            />
                            <Typography variant="body1" color="primary">{localisable.refreshColor}</Typography>
                        </Button>
                    )}
                    <Button
                        variant="text"
                        className={classes.infoButton}
                        onClick={() => setShowInfoCard(true)}
                    >
                        <Typography variant="subtitle1">
                            {localisable.key}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

CanvasHeader.propTypes = {
    levelsList: PropTypes.array,
    canvasRef: PropTypes.object,
    isSetupMode: PropTypes.bool,
    zoomValue: PropTypes.number,
    defaultZoomValue: PropTypes.number,
    onLevelChange: PropTypes.func,
    saveCurrentZoomAsDefault: PropTypes.func,
    isButtonLoading: PropTypes.bool,
    zoomCanvas: PropTypes.func.isRequired,
    setShowInfoCard: PropTypes.func,
    onAction: PropTypes.func,
    setShowFullPageLoader: PropTypes.func,
    setSnackbarProps: PropTypes.func,
    handleErrors: PropTypes.func,
    unitList: PropTypes.array,
    setScrollToIndex: PropTypes.func,
    selectedLevel: PropTypes.string,
    searchedUnit: PropTypes.object,
    setSearchedUnit: PropTypes.func,
    scrollCanvas: PropTypes.func,
};

CanvasHeader.defaultProps = { unitList: [] };

export default memo(CanvasHeader);
