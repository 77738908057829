import media from 'Commons/theme/MediaQueries';

const BillAheadStyle = theme => ({
    periodsSection: {
        padding: theme.spacing(1, 0, 0, 0),
        marginLeft: theme.spacing(-1),
        [media.desktop]: { padding: theme.spacing(2, 0, 0, 0) },
    },
    unitsSection: {
        padding: theme.spacing(2, 0),
        [media.desktop]: { padding: theme.spacing(4, 0, 2, 0) },
    },
    units: { padding: theme.spacing(2, 0, 0, 0) },
    dateAndAmountSection: {
        padding: theme.spacing(3, 0, 2, 0),
        [media.desktop]: { padding: theme.spacing(4, 0, 2, 0) },
    },
    firstColumn: { paddingLeft: 0 },
    lastColumn: { paddingRight: 0 },
    autoWidth: { width: 'auto' },
    loader: { zIndex: 802, height: '100%' },
    totalAmountSection: {
        padding: theme.spacing(2, 4, 0, 0),
        [media.tabletUp]: { padding: theme.spacing(0, 4, 0, 0) },
    },
    closeButton: {
        position: 'absolute',
        borderRadius: 0,
        outline: 'none',
        border: 'none',
        top: 0,
        right: 0,
        margin: theme.spacing(2, 2, 0, 0),
        zIndex: 100,
        width: 'auto',
        height: 'auto',
        '&:hover': { backgroundColor: 'transparent' },
    },
    closeIcon: { color: theme.palette.text.primary },
    info: { color: 'Red', paddingTop: theme.spacing(2) },
    submit: { paddingTop: theme.spacing(2), [media.desktop]: { paddingTop: theme.spacing(4) } },
    lastColumnList: { paddingLeft: theme.spacing(8) },
    input: { paddingBottom: theme.spacing(0.25), paddingTop: 0 },
    chip: {
        height: theme.spacing(4),
        background: theme.palette.chip.reserved,
        borderColor: theme.palette.chip.reserved,
        borderRadius: theme.spacing(2),
        '&:hover, &:focus, &:active': { backgroundColor: theme.palette.chip.reserved },
    },
    chipLabel: {
        color: theme.palette.common.white,
        fontSize: theme.elements.input.label.fontSize,
    },
});

export default BillAheadStyle;
