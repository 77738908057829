import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { FormDatePicker } from 'Commons/components/generic/datepicker/components/DatePicker';
import { memo } from 'react';
import { SM_6 } from 'Commons/config/constants/GridSpacingConstants';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import LabelWithIcon from 'Generic/labelwithicon/components/LabelWithIcon';
import ConversationListFilterStyle from '../styles/ConversationListFilterStyle';
import { DISPLAY } from '../config/Constants';

const useStyles = makeStyles(ConversationListFilterStyle, { name: 'ConversationListFilterStyle' });

const ConversationListFilter = ({ formProps: { setFieldValue } }) => {
    const classes = useStyles();
    const onClearDate = () => { setFieldValue('range', {}); };

    const handleDisplayCheckboxChange = (_, value) => {
        if (value.includes(DISPLAY.READ)) {
            setFieldValue('terms.display', [...value, DISPLAY.NA]);
        } else {
            setFieldValue('terms.display', value.filter(item => item !== DISPLAY.NA));
        }
    };

    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.display}
            </Typography>
            <FormCheckboxGroup
                name="terms.display"
                list={[{ label: localisable.unreadMessages, value: DISPLAY.UNREAD },
                    { label: localisable.readMessages, value: DISPLAY.READ }]}
                noIndicatorWrapper
                formVariant={VARIANT.NO_LEFT_RIGHT_PADDING}
                onChange={handleDisplayCheckboxChange}
            />
            <Typography variant="subtitle1" className={classes.restLabel}>
                {localisable.dateRange}
            </Typography>
            <Grid container justify="space-between" spacing={2}>
                <FormDatePicker
                    name="range.createdDate.fromDate"
                    label={localisable.fromDate}
                    GridProps={SM_6}
                    formVariant={VARIANT.NO_LEFT_RIGHT_PADDING}
                />
                <FormDatePicker
                    name="range.createdDate.toDate"
                    label={localisable.toDate}
                    GridProps={SM_6}
                    formVariant={VARIANT.NO_LEFT_RIGHT_PADDING}
                />
            </Grid>
            <LabelWithIcon
                classes={{ labelWithIcon: classes.autoWidth }}
                className={classes.clearDate}
                onClick={onClearDate}
            >
                <Typography color="primary" variant="body2">
                    {localisable.clearDate}
                </Typography>
            </LabelWithIcon>
        </>
    );
};

ConversationListFilter.propTypes = { formProps: PropTypes.object };

export default memo(ConversationListFilter);
