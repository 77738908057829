import React, { PureComponent } from 'react';
import CustomIcon from 'Generic/icon/components/Icon';
import { Chip as MaterialChip, Grid, Typography, withStyles } from '../../componentlibrary/components/Components';
import ChipStyle from '../style/ChipStyle';
import { CHIP_COLOR_MAPPING } from '../config/ChipColorMapping';

class Chip extends PureComponent {
    renderValue = data => data.map(({ value, label, size, color, child, disabled }) => this.renderChip({ value, label, size, color, child, disabled }))

    deleteChip = (event) => {
        const { data, onDelete, elementIndex, parentIndex } = this.props;
        event.stopPropagation();
        onDelete(parentIndex, elementIndex, data);
    };

    renderChip = ({ value, label, size, child = [], color, disabled, labelTypographyProps }) => {
        const { classes, onSelectChip, onDelete, labelTypographyProps: labelTypographyPropsExtract, ...props } = this.props;
        const mappedColor = CHIP_COLOR_MAPPING[color] || color;
        return (
            <MaterialChip
                key={value}
                classes={{
                    root: `${classes.root} ${classes[`bg${mappedColor}`]}`,
                    label: `${classes.label}`,
                    icon: `${classes.leftIcon}`,
                }}
                size={size || 'small'}
                clickable={!!onSelectChip}
                onClick={onSelectChip}
                icon={child.length ? (
                    <Grid
                        onClick={event => this.deleteChip(event)}
                    >
                        <CustomIcon
                            type="custom"
                            variant="rounded"
                            icon="cp-close"
                            className={`${classes.frontCloseIcon} ${classes[`icon${mappedColor}`]} ${disabled ? classes.disabled : ''}`}
                        />
                    </Grid>
                ) : undefined}
                label={(
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                    >
                        <Typography
                            variant="body2"
                            className={`${classes.labelText} ${child.length ? classes.labelPadding : ''}`}
                            component={typeof label === 'string' ? 'div' : 'span'}
                            {...labelTypographyProps}
                        >
                            {label}
                        </Typography>
                        {
                            child.length
                                ? (
                                    <Grid
                                        container
                                        wrap="wrap"
                                        className={classes.nestedChipPadding}
                                    >
                                        {this.renderValue(child)}
                                    </Grid>
                                )
                                : undefined
                        }
                    </Grid>
                )}
                deleteIcon={
                    !child.length
                        ? (
                            <CustomIcon
                                type="custom"
                                variant="rounded"
                                icon="cp-close"
                                className={`${classes.closeIcon} ${classes[`icon${mappedColor}`]} ${disabled ? classes.disabled : ''}`}
                            />
                        )
                        : null
                }
                onDelete={(!child.length && onDelete) ? event => this.deleteChip(event, value) : undefined}
                disabled={disabled}
                {...props}
            />
        );
    }

    render() {
        const { data: { value, label, size, child, color } = {}, disabled, labelTypographyProps } = this.props;
        return this.renderChip({ label, size, child, value, color, disabled, labelTypographyProps });
    }
}

Chip.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.any,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        size: PropTypes.oneOf(['small', 'medium']),
        color: PropTypes.oneOf(['statusActive', 'statusInactive', 'statusLocked', 'statusCreated',
            'rolesPrimary', 'rolesSecondary', 'rolesTertiary', 'rentalStatusVacant',
            'rentalStatusRented', 'rentalStatusUnavailable',
            'statusReserved', 'statusCancelled', 'error', 'light', 'maintenanceeventstatusUpcoming',
            'maintenanceeventstatusPending', 'maintenanceeventstatusCompleted', 'maintenanceeventstatusClosed',
            'maintenanceeventstatusCancelled', 'highlight', 'leadstatusActive',
            'leadstatusInactive', 'leadstatusConverted', 'leadstatusCold',
            'leadstatusLost', 'leadstatusDuplicate', 'estimatestatusActive',
            'estimatestatusMovedIn', 'estimatestatusExpired', 'statusError', 'ratehistorystatusUnknown',
            'ratehistorystatusPending', 'ratehistorystatusApplied', 'ratehistorystatusReversed',
            'ratehistorystatusMovedIn', 'ratehistorystatusMovedOut', 'ratehistorystatusWaived',
            'ratehistorystatusReversedTo', 'setUpPending', 'migrationNotStarted', 'migrationDumpGenerationSuccess',
            'migrationProgress', 'migrationCompleted', 'migrationErrored', 'migrationDeleted',
            'migrationServerActive', 'migrationServerInactive', 'transparent', 'autoPayment', 'batchPayment', 'delinquencyScheduled',
        ]),
        child: PropTypes.array,
    }),
    onDelete: PropTypes.func,
    onSelectChip: PropTypes.func,
    classes: PropTypes.object,
    parentIndex: PropTypes.number,
    elementIndex: PropTypes.number,
    disabled: PropTypes.bool,
    labelTypographyProps: PropTypes.object,
};

Chip.defaultProps = { data: null, labelTypographyProps: {} };

export default withStyles(ChipStyle)(Chip);
