import media from '../../../../../theme/MediaQueries';

const contactStyle = theme => ({
    phone: { '& .MuiFormHelperText-root': { marginTop: 0 } },
    leftMarginIcon: { margin: 0 },
    checkbox: {
        marginLeft: 0,
        padding: 0,
    },
    phoneContainer: { '&:hover': { '& $closeButton': { visibility: 'visible' } } },
    closeButton: { [media.laptopUp]: { visibility: 'hidden' } },
    indicatorPadding: {
        padding: 0,
        alignItems: 'center',
    },
    formLabel: { marginRight: 0 },
    autoWidth: { width: 'auto' },
    iconOrder: { order: 1 },
    showAlways: { display: 'block' },
    phoneTopMargin: { marginTop: theme.spacing(1) },
    tooltipRoot: { display: 'flex' },
    leftAlign: { [media.mobile]: { marginLeft: '-7px' } },
    smallLeftMargin: { marginLeft: theme.spacing(1.5) },
    defaultCursor: { cursor: 'default' },
    lightTooltip: {
        maxWidth: '500px',
        [media.tabletUp]: { marginLeft: '50px' },
        [media.laptopUp]: { marginLeft: '200px' },
    },
    additionalEmailAddress: { margin: theme.spacing(1, 2, 0, 4) },
});

export default contactStyle;
