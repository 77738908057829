
const roleSelectionStyle = theme => ({
    rootClass: { width: '50%' },
    flexContainer: { justifyContent: 'flex-end', paddingRight: 16 },
    block: { padding: theme.spacing(1, 4, 0, 4) },
    title: {
        minHeight: 48,
        maxHeight: 48,
    },
    dropDownGrid: {
        alignItems: 'center',
        paddingLeft: 20,
        display: 'grid',
    },
    dropdown: { width: '100%' },
    tablePadding: { width: '100%' },
    onHoverRole: { '&:hover': { backgroundColor: theme.palette.hover } },
    popper: {
        zIndex: 5000,
        padding: 16,
    },
    paperPadding: { padding: 16 },
    listRenderer: {
        maxHeight: 150,
        overflowY: 'auto',
    },
    iconPadding: { padding: 4 },
    checkbox: {
        padding: 0,
        marginTop: -2,
    },
});

export default roleSelectionStyle;
