import media from 'Commons/theme/MediaQueries';

const PaymentInformationStyle = theme => ({
    paymentInfoContainer: {
        [media.mobileUp]: { padding: theme.spacing(1, 2) },
        [media.tabletUp]: { padding: theme.spacing(2, 4) },
        borderRadius: 4,
    },
    amountContainer: {
        padding: theme.spacing(1, 2, 2, 2),
        backgroundColor: theme.hexWithOpacity(theme.palette.primary.main, 1),
        borderRadius: '4px',
        border: `1px solid ${theme.palette.primary.main}`,
        width: 'auto',
    },
    dateAndReceipt: { paddingBottom: theme.spacing(4) },
    noWrap: { flexWrap: 'nowrap' },
    formControlCheckbox: { margin: 0 },
    checkboxLabel: {
        marginLeft: 8,
        // fontSize: 10,
        // [media.desktop]: { fontSize: 12 },
        ...theme.typography.caption,
    },
    checkbox: { color: theme.palette.text.primary, '&$modifierCheckbox': { padding: 0 } },
    modifierCheckbox: { },
    noPadding: { padding: 0 },
    dollarIcon: { verticalAlign: 'super', marginLeft: theme.spacing(-1), marginBottom: theme.spacing(-1) },
    datePickerWidth: {
        maxWidth: 140,
        paddingLeft: 0,
    },
    processCard: { marginTop: 24, marginLeft: 8 },
    helperText: { textAlign: 'center', color: theme.palette.text.primary, width: 'auto' },
    viewIcon: { paddingLeft: theme.spacing(2) },
    viewIconContainer: { paddingTop: theme.spacing(2) },
    input: { textAlign: 'center' },
    noDisplay: { display: 'none' },
    textAlignCenter: { textAlign: 'center' },
});

export default PaymentInformationStyle;
