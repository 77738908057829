import { MAINTENANCE_EVENT_ACTION_ITEM } from 'External/containers/operations/components/maintenanceEvents/config/Constants';

const getEditEndpoints = (id, source, status) => {
    const sourceToUpdate = source === 'userRole' ? 'user-role' : source;
    return status ? `${sourceToUpdate}/${id}/${status}` : `${sourceToUpdate}/${id}`;
};

const AUTH_ENDPOINTS = { UPDATE_SESSION_TIMEOUT: 'auth/session/update-timeout' };

const TFW_AUTH_ENDPOINTS = {
    decryptApiKey: apiKey => `auth/tfw/decrypt-key/${apiKey}`,
    regenerateApiKey: (configId, apiKey) => `auth/tfw/regenerate-key/${configId}/${apiKey}`,
};

const DAILY_SUMMARY_ENDPOINTS = {
    search: 'scheduler/daily-summary',
    viewBatch: batchId => `scheduler/daily-summary/${batchId}`,
};

const BATCH_PAYMENT_ENDPOINTS = {
    ledgerSearch: 'payment/batch-payment/search',
    aggregation: 'payment/batch-payment/aggregation',
    processBatchPayment: 'payment/batch-payment',
};

const RENT_ASSESSMENT_ENDPOINTS = { history: 'rental/assessment/history/search' };

const ACCOUNT_ENDPOINTS = { search: 'account/search' };

const FACILITY_ENDPOINTS = {
    create: 'facility',
    search: 'facility/search',
    searchAll: 'facility/search/all',
    regenerateInvalidCodes: 'facility/regenerate-access-codes',
    update: fid => `facility/${fid}`,
    acceptLegalAgreement: fid => `facility/${fid}/accept-agreement`,
};

const BUSINESS_GROUP_ENDPOINTS = {
    create: 'businessgroup',
    search: 'businessgroup/search',
};

const MOVE_IN_ENDPOINTS = {
    validate: 'occupancy/move-in/validate',
    moveIn: 'occupancy/move-in',
};

const RENTAL_ENDPOINTS = {
    rentalInfo: 'rental/info',
    prerequisite: 'rental/prerequisite',
    promoPlans: 'rental/promo-plans',
    billAhead: 'rental/bill-ahead',
};

const UNIT_ENDPOINTS = {
    search: 'unit/search',
    createUnit: 'unit',
    getNextAmountPayable: 'unit/get-next-amount-payable',
    aggregation: 'unit/aggregation',
    bulk: 'unit/bulk',
    nradChange: 'unit/next-rent-assessment-date-change',
    count: 'unit/count',
    rateHistorySearch: 'unit/rate-history/search',
    rateHistoryReverse: rateHistoryId => `unit/rate-history/${rateHistoryId}/reverse`,
    rateHistoryWaive: rateHistoryId => `unit/rate-history/${rateHistoryId}/waive`,
    chargesAfterAdjustment: 'unit/get-charges-after-adjustment',
    insuranceInfo: unitId => `unit/${unitId}/insurance/info`,
    saveInsurance: unitId => `unit/${unitId}/insurance`,
    cancelInsurance: unitId => `unit/${unitId}/insurance/cancel`,
    lateEvents: 'unit/late-event/search',
    syncColor: 'unit/sync-color-cache',
    autoSuggest: 'unit/autosuggest',
};

const TX_ENDPOINTS = {
    token: 'oauth/token',
    mergeAndGenerate: 'documentprocessing/document/merge',
    info: 'documentprocessing/document/info',
};

const CONFIG_ENDPOINTS = {
    search: 'configuration/search',
    taxCodeBulk: 'configuration/Tax_Code/bulk',
    chargeCategoryBulk: 'configuration/Charge_Category/bulk',
    placeholderBulk: 'configuration/Placeholder/bulk',
    getDependencies: (configType, configId) => `configuration/${configType}/${configId}/get-dependencies`,
    deactivate: (configType, configId) => `configuration/${configType}/${configId}/deactivate`,
};

const UNIT_CONFIGURATION_ENDPOINTS = { unitBulk: 'configuration/Unit_Type/bulk' };

const TENANT_ENDPOINTS = {
    search: 'tenant/search',
    autoSuggest: 'tenant/autosuggest',
    tenantAlertSearch: 'tenant/alert/search',
    contactLogSearch: 'tenant/contact-log/search',
    tenantAlertBulk: 'tenant/alert/bulk',
    contactLogBulk: 'tenant/contact-log/bulk',
    sendSignUpLink: 'tenant/auth/send-sign-up-link',
    bulkSendSignUpLink: 'tenant/auth/sign-up-link/bulk-send',
    promoPlan: 'tenant/promo-plan',
    update: tenantId => `tenant/${tenantId}`,
    activate: tenantId => `tenant/${tenantId}/activate`,
    tenantVehicleBulk: tenantId => `tenant/${tenantId}/vehicle/bulk`,
    tenantVehicleSearch: tenantId => `tenant/${tenantId}/vehicle/search`,
    tenantDocumentUpload: (tenantId, documentType) => `tenant/${tenantId}/document/${documentType}/upload`,
    invalidCodeLedgerCount: 'tenant/invalid-code-ledger/count',
    bulk: 'tenant/bulk',
};

const RETAIL_SALE_ENDPOINTS = {
    search: 'inventory/search',
    getOrderInfo: 'retail-sale/order-info',
    sellAndPay: 'retail-sale/sell-and-pay',
    sell: 'retail-sale/sell',
    vendorSearch: 'vendor/search',
    inventoryCount: 'inventory/count',
    purchaseOrderSearch: 'purchase-order/search',
    return: 'retail-sale/return',
    returnAndRefund: 'retail-sale/return-and-refund',
    orderDetailForPurchaseOrder: purchaseOrderId => `retail-sale/get-order-details-for-purchase-order/${purchaseOrderId}`,
    returnsCount: 'purchase-order/count',
    cancelRefund: purchaseOrderId => `retail-sale/refund/${purchaseOrderId}/cancel`,
    refund: 'retail-sale/refund',
};

const RESERVATION_ENDPOINTS = {
    search: 'reservation/search',
    validate: 'reservation/validate',
    createReservation: 'reservation',
    count: 'reservation/count',
    bulk: 'reservation/bulk',
    editReservation: 'reservation/:reservationId',
};

const PAYMENT_ENDPOINTS = {
    search: 'payment/search',
    count: 'payment/count',
    bulk: 'payment/bulk',
};

const ESTIMATE_ENDPOINTS = {
    validate: 'estimate/validate',
    createEstimate: 'estimate',
    search: 'estimate/search',
};

const REPORT_WC_ENDPOINTS = {
    category: {
        search: 'category/search',
        create: 'category',
        update: id => `category/${id}`,
        delete: id => `category/${id}`,
    },
    report: {
        search: 'report/search',
        generate: reportId => `report/${reportId}/generate`,
    },
    deliver: 'report/deliver',
};

const FINANCIAL_ENDPOINTS = {
    unpaidAr: 'financial/get-unpaid-charges',
    pay: 'financial/pay',
    refund: 'financial/refund',
    arSearch: 'financial/ar/search',
    createManualCharge: 'financial/manual-charge',
    reverse: 'financial/reverse',
    delete: 'financial/delete',
    arAggregation: 'financial/ar/aggregation',
    nsfCharge: 'financial/generate-nsf-charge',
    calculateRefundAmount: 'financial/calculate-refund-amount',
    authenticateCardProcessorIntegrator: 'financial/auth/card-processor',
    getSyrapayDevices: 'financial/syrapay/get-devices',
    deleteUnusedTokens: 'financial/syrapay/delete-unused-tokens',
    stripeIntent: 'financial/stripe/payment-intent',
    paymentInfo: 'payment/info',
    financialExport: 'financial/export',
};

const LETTER_ENDPOINTS = { generate: 'document/Letter' };

const DOCUMENT_ENDPOINTS = {
    search: 'document/saved-document/search',
    searchForInternalConsole: 'document/saved-document-for-ic/search',
    upload: docType => `document/${docType}/upload`,
    redeliver: 'document/saved-document/redeliver',
    bulkDeliver: 'document/saved-document/deliver/bulk',
    generateDocument: 'document/generate-document',
};

const ACH_ENDPOINTS = {
    searchTransactions: 'financial/ach-transactions',
    generateNacha: 'financial/generate-nacha',
    downloadNacha: 'financial/download-nacha',
    updateNacha: 'financial/update-nacha',
};

const MOVE_OUT_ENDPOINTS = {
    info: 'occupancy/move-out/info',
    validate: 'occupancy/move-out/validate',
    moveOut: 'occupancy/move-out',
};


const POST_MOVE_OUT_ENDPOINTS = {
    info: 'occupancy/post-move-out/resolve-balance/info',
    resolveBalance: 'occupancy/post-move-out/resolve-balance',
    complete: 'occupancy/post-move-out/complete',
};

const OPERATIONS_ENDPOINTS = { lateEvent: 'delinquency/search' };

const MAINTENANCE_OPERATION_ENDPOINTS = {
    search: 'maintenance-operation/search',
    schedule: 'maintenance-operation',
    getUpdateEndpoint: eventId => `maintenance-operation/${eventId}`,
    getStatusChangeEndpoint: (eventId, source) => ([MAINTENANCE_EVENT_ACTION_ITEM.Mark_as_pending.value, MAINTENANCE_EVENT_ACTION_ITEM.Complete_Event.value].includes(source) ? `maintenance-operation/${eventId}/status` : `maintenance-operation/${eventId}/${source === MAINTENANCE_EVENT_ACTION_ITEM.Close_Event.value ? 'close' : 'cancel'}`),
};

const CONVERSATION_ENDPOINTS = {
    listText: 'conversation/text/list',
    searchText: 'conversation/text/search',
    searchEmail: 'conversation/email/search',
    unreadCount: 'conversation/get-unread-count',
    sendText: 'conversation/text/send',
    sendEmail: 'conversation/email/send',
    listEmail: 'conversation/email/list',
    markAsRead: (selectedConversation, identifier) => `conversation/${selectedConversation.toLowerCase()}/mark-as-read/${identifier}`,
};

const LEAD_ENDPOINTS = { search: 'lead/search' };

const DELINQUENCY_ENDPOINTS = {
    overview: 'delinquency/overview',
    wcSearch: 'delinquency/wc/search',
    wrongEntries: 'delinquency/wrong-entries',
    updateSchedulers: 'delinquency/scheduler/bulk',
    searchSchedulers: 'delinquency/scheduler/search',
    createMissingSchedulers: 'delinquency/missing-scheduler',
    syncDelinquencySystem: 'delinquency/sync-delinquency-system',
    searchMissingSchedulers: 'delinquency/missing-scheduler/search',
};

const PENDING_TASKS_ENDPOINTS = {
    applyLateEventsCount: 'unit/count-ledgers-which-are-late',
    printFailuresCount: 'document/saved-document/print/count',
    pendingInvoiceDeliveryCount: 'document/saved-document/print/count',
};

const SCHEDULER_ENDPOINTS = {
    search: 'scheduler/search',
    ledgerSearch: 'scheduler/ledgers',
};

const REMINDER_ENDPOINTS = { search: 'reminder/search' };

const UNIT_TRANSFER_ENDPOINTS = {
    transfer: 'occupancy/transfer',
    info: 'occupancy/transfer/info',
};

const STANDALONE_APP_ENDPOINTS = {
    createAndDownload: 'standalone-app/create-and-download',
    search: 'standalone-app/search',
};

const ONBOARDING_ENDPOINTS = {
    search: 'onboarding/search',
    occupancyConfigs: 'onboarding/occupancy-configs',
    saveTenant: 'onboarding/save/tenant',
    saveUnit: 'onboarding/save/unit',
};

const RENT_ADJUSTMENT_ENDPOINTS = {
    search: 'rental/rent-adjustment/search',
    getStopEndpoint: rentAdjustmentId => `rental/rent-adjustment/${rentAdjustmentId}/stop`,
    schedule: 'rental/rent-adjustment/schedule',
    fetchWarningData: 'rental/rent-adjustment/fetch-warning-data',
    fetchRentAdjForUnits: 'rental/rent-adjustment/fetch-rent-adjustments-for-units',
};

const GATE_ENDPOINTS = { performInitialLoad: 'gate/initial-load' };

const CARD_PROCESSOR_TRANSACTION_ENDPOINTS = {
    search: 'payment-card/transactions/search',
    count: 'payment-card/transactions/count',
    reProcess: 'payment-card/transactions/re-process',
    bulk: 'payment-card/transactions/bulk',
};

const ZIP_CODE_ENDPOINTS = { search: 'zip-code/search' };

const TENANT_CHANGE_TRACKING_ENDPOINTS = {
    count: 'journal/tenant/count',
    search: 'journal/tenant/search',
    update: 'journal/tenant/bulk',
    aggregation: 'journal/tenant/aggregation',
};

const WAITING_LIST_ENDPOINTS = {
    search: 'waiting-list/search',
    cancel: id => `waiting-list/${id}/cancel`,
    create: 'waiting-list',
    edit: id => `waiting-list/${id}`,
};

const OCCUPANCY_HISTORY_ENDPOINTS = {
    count: 'occupancy/history/count',
    search: 'occupancy/history/search',
    bulk: 'occupancy/history/bulk',
};

const DEPOSIT_LOG_ENDPOINTS = {
    create: 'financial/deposit-log',
    search: 'financial/deposit-log/search',
};

const SANDBOX_ENDPOINTS = {
    create: 'sandbox',
    search: 'sandbox/search',
};

const DASHBOARD_ENDPOINTS = { info: 'dashboard/get-info' };

const PRINTER_ENDPOINTS = {
    search: 'printer/search',
    update: id => `printer/${id}`,
};

const VEHICLE_ENDPOINTS = {
    bulk: 'vehicle/bulk',
    search: 'vehicle/search',
};

const USER_ENDPOINTS = {
    checkIfEmailExists: 'user/check-if-email-exists',
    searchAll: 'user/search/all',
};

const PRINT_FAILURES_ENDPOINTS = {
    search: 'document/saved-document/print-failures/search',
    reprint: 'document/saved-document/reprint',
};

const JOURNAL_ENDPOINTS = { search: 'journal/search' };

export {
    MOVE_IN_ENDPOINTS, TENANT_ENDPOINTS, UNIT_ENDPOINTS, CONFIG_ENDPOINTS, RENTAL_ENDPOINTS,
    RESERVATION_ENDPOINTS, getEditEndpoints, REPORT_WC_ENDPOINTS, RETAIL_SALE_ENDPOINTS,
    FINANCIAL_ENDPOINTS, ESTIMATE_ENDPOINTS, LETTER_ENDPOINTS, MOVE_OUT_ENDPOINTS, POST_MOVE_OUT_ENDPOINTS,
    OPERATIONS_ENDPOINTS, MAINTENANCE_OPERATION_ENDPOINTS, DOCUMENT_ENDPOINTS, DELINQUENCY_ENDPOINTS,
    PENDING_TASKS_ENDPOINTS, SCHEDULER_ENDPOINTS, REMINDER_ENDPOINTS, CONVERSATION_ENDPOINTS,
    ACH_ENDPOINTS, FACILITY_ENDPOINTS, BUSINESS_GROUP_ENDPOINTS, AUTH_ENDPOINTS, UNIT_TRANSFER_ENDPOINTS,
    RENT_ADJUSTMENT_ENDPOINTS, STANDALONE_APP_ENDPOINTS, GATE_ENDPOINTS, CARD_PROCESSOR_TRANSACTION_ENDPOINTS,
    ZIP_CODE_ENDPOINTS, TENANT_CHANGE_TRACKING_ENDPOINTS, WAITING_LIST_ENDPOINTS, DAILY_SUMMARY_ENDPOINTS,
    RENT_ASSESSMENT_ENDPOINTS, OCCUPANCY_HISTORY_ENDPOINTS, PAYMENT_ENDPOINTS, TX_ENDPOINTS, DEPOSIT_LOG_ENDPOINTS,
    SANDBOX_ENDPOINTS, ONBOARDING_ENDPOINTS, UNIT_CONFIGURATION_ENDPOINTS, LEAD_ENDPOINTS, PRINTER_ENDPOINTS,
    DASHBOARD_ENDPOINTS, ACCOUNT_ENDPOINTS, VEHICLE_ENDPOINTS, BATCH_PAYMENT_ENDPOINTS, USER_ENDPOINTS, PRINT_FAILURES_ENDPOINTS,
    JOURNAL_ENDPOINTS, TFW_AUTH_ENDPOINTS,
};
