import { useState } from 'react';
import { makeStyles, TreeView as MuiTreeView, TreeItem } from 'Generic/componentlibrary/components/Components';
import LabelWithIcon from 'Generic/labelwithicon/components/LabelWithIcon';
import Button from 'Generic/button/components/Button';
import treeViewStyles from '../styles/TreeViewStyle';

const useStyles = makeStyles(treeViewStyles, { name: 'TreeView' });

const TreeView = ({ defaultExpanded, TreeItemProps, onNodeSelect, ...props }) => {
    const classes = useStyles(props);
    const [expanded, setExpanded] = useState(defaultExpanded);

    // eslint-disable-next-line react/prop-types
    const processTreeItem = ({ children, nodeId, label, searchField, ...others }) => {
        const node = { nodeId, label };
        return (
            <TreeItem
                classes={{
                    root: classes.root,
                    content: classes.content,
                    group: classes.group,
                }}
                key={nodeId}
                nodeId={nodeId}
                label={children
                    ? (
                        <LabelWithIcon
                            addon={{
                                end:
                            <Button
                                variant="icon"
                                iconType="custom"
                                icon={expanded.includes(nodeId) ? 'cp-expand' : 'cp-chevron-right'}
                            />,
                            }}
                        >
                            {label}
                        </LabelWithIcon>
                    )
                    : label
                }
                onClick={(event) => {
                    onNodeSelect(event, node);
                }}
                {...TreeItemProps}
                {...others}
            >
                {/* eslint-disable-next-line no-use-before-define */}
                {children ? renderNodeList(children) : null}
            </TreeItem>
        );
    };

    const renderNodeList = itemList => itemList.map(treeItem => processTreeItem(treeItem));

    const { config, className, children, classes: ignore, ...others } = props;

    return (
        <MuiTreeView
            className={className}
            onNodeToggle={(_, nodeIds) => setExpanded(nodeIds)}
            defaultExpanded={defaultExpanded}
            {...others}
        >
            {children || renderNodeList(config)}
        </MuiTreeView>
    );
};

TreeView.propTypes = {
    config: PropTypes.arrayOf(PropTypes.shape({
        nodeId: PropTypes.string.isRequired,
        label: PropTypes.node,
    })),
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    defaultExpanded: PropTypes.array,
    TreeItemProps: PropTypes.object,
    onNodeSelect: PropTypes.func,
    searchField: PropTypes.string,
};

TreeView.defaultProps = {
    defaultExpanded: [],
    onNodeSelect: () => {},
};

export default TreeView;
