import localisable from 'Commons/config/strings/localisable';
import { Checkbox, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { createEnumMap } from 'Commons/helpers/utils/Formatter';
import useFormatter from 'Commons/components/business/formatters/components/FormatterHOC';
import roleSelectionStyle from '../styles/RoleSelectionStyle';

const {
    roleSelectionName: name,
    roleSelectionCity: city,
    roleSelectionID: id,
    roleSelectionAbbreviation: abbr,
    roleSelectionAppliedRoles: appliedRoles,
} = localisable;

const {
    RowSelector, AppliedRole,
    Ellipsis: commentsFormatter,
// eslint-disable-next-line react-hooks/rules-of-hooks
} = useFormatter({ formattersRequested: ['RowSelector', 'AppliedRole', 'Ellipsis'], stylesheet: roleSelectionStyle });

const ENTITY_TYPE = createEnumMap(['account', 'businessGroup', 'facility']);

const renderLabelCheckbox = (listType, selectAll) => {
    const classes = makeStyles(roleSelectionStyle)();
    return (
        <Checkbox
            color="primary"
            disableRipple
            disabled
            onChange={() => selectAll(listType)}
            className={classes.checkbox}
        />
    );
};

const getFacilityColumns = selectAll => [
    {
        label: renderLabelCheckbox('facility', selectAll),
        key: 'onSelectRow',
        width: 12,
        formatter: RowSelector,
    },
    {
        label: name,
        key: 'name',
        width: 20,
        formatter: commentsFormatter,
    },
    {
        label: city,
        key: 'city',
        width: 15,
    },
    {
        label: id,
        key: 'facilityNumber',
        width: 15,
    },
    {
        label: abbr,
        key: 'abbreviation',
        width: 15,
    },
    {
        label: appliedRoles,
        key: 'appliedRoleData',
        width: 23,
        formatter: AppliedRole,
    },
];

const getBusinessGroupColumns = selectAll => [
    {
        label: renderLabelCheckbox('businessGroup', selectAll),
        key: 'onSelectRow',
        width: 12,
        formatter: RowSelector,
    },
    {
        label: name,
        key: 'name',
        width: 63,
        formatter: commentsFormatter,
    },
    {
        label: appliedRoles,
        key: 'appliedRoleData',
        width: 25,
        formatter: AppliedRole,
    },
];

const getAccountColumns = selectAll => [
    {
        label: renderLabelCheckbox('account', selectAll),
        key: 'onSelectRow',
        width: 12,
        formatter: RowSelector,
    },
    {
        label: name,
        key: 'name',
        width: 25,
        formatter: commentsFormatter,
    },
    {
        label: city,
        key: 'city',
        width: 20,
    },
    {
        label: id,
        key: 'accountNumber',
        width: 20,
    },
    {
        label: appliedRoles,
        key: 'appliedRoleData',
        width: 23,
        formatter: AppliedRole,
    },
];

export { getFacilityColumns, getBusinessGroupColumns, getAccountColumns, ENTITY_TYPE };
