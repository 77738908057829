import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { STATUS } from 'Commons/config/constants/Constants';
import localisable from 'Commons/config/strings/localisable';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import VendorFilterStyle from '../styles/VendorFilterStyle';


const useStyles = makeStyles(VendorFilterStyle);


const VendorFilter = () => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={[STATUS.Active, STATUS.Inactive]}
                row
                noGrid
                noIndicatorWrapper
            />
        </>
    );
};
export default memo(VendorFilter);
