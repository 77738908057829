import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

const environment = process.env.MODE.toLowerCase();

const ROLLBAR_CONFIG = {
    environment,
    accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,

    payload: {
        environment,
        client: {
            javascript: {
                source_map_enabled: true, // false by default

                // -- Add this into your configuration ---
                code_version: process.env.CODE_VERSION,
                // ---------------------------------------

                // Optionally have Rollbar guess which frames the error was
                // thrown from when the browser does not provide line
                // and column numbers.
                guess_uncaught_frames: true,
            },
        },
    },
};


const AIRBRAKE_CONFIG = {
    environment,
    projectId: process.env.AIRBRAKE_PROJECT_ID,
    projectKey: process.env.AIRBRAKE_PROJECT_KEY,
};


const SENTRY_CONFIG = {
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
};

export { ROLLBAR_CONFIG, AIRBRAKE_CONFIG, SENTRY_CONFIG };
