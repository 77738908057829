import { mapEnum, createEnumMap } from 'Commons/helpers/utils/Formatter';
import enums from './Enums';

const VARIANT = mapEnum(enums.variant);
const POSITION = createEnumMap(enums.position);

const SNACKBAR_POSITION = {
    TOP_CENTER: {
        vertical: POSITION.TOP,
        horizontal: POSITION.CENTER,
    },
    // TODO: Add more when needed
};

const AUTO_HIDE_DURATION = {
    [VARIANT.error.value]: null,
    [VARIANT.success.value]: 2000,
    [VARIANT.warning.value]: 6000,
    [VARIANT.info.value]: 6000,
    [VARIANT.rtns.value]: null,
};


export { AUTO_HIDE_DURATION, VARIANT, POSITION, SNACKBAR_POSITION };
