import enums from 'Commons/config/constants/Enums';
import { createEnumMap } from 'Commons/helpers/utils/Formatter';

const [cellPhone, businessPhone, homePhone, faxPhone] = enums.primary;

const PHONE_TYPE = createEnumMap(enums.primary);

const FIELD_MAP = {
    [cellPhone]: 'phone.cellPhone',
    [businessPhone]: 'phone.businessPhone',
    [homePhone]: 'phone.homePhone',
    [faxPhone]: 'phone.faxPhone',
};

const NO_PHONE = 'No_Phone';

const TEXT_SUPPORTED_COUNTRIES = ['us', 'ca', 'mx'];

const DEFAULT_PHONE = {
    type: NO_PHONE,
    phone: {
        countryCode: undefined,
        areaCode: undefined,
        number: undefined,
        country: undefined,
    },
}; // Setting undefined to avoid Formik validation on Add Phone click

export {
    NO_PHONE,
    FIELD_MAP,
    PHONE_TYPE,
    DEFAULT_PHONE,
    TEXT_SUPPORTED_COUNTRIES,
};
