const actionStyle = theme => ({
    actions: {
        color: theme.palette.text.primary,
        '& $disabled, & $disabled:hover': { color: theme.palette.disabled, cursor: 'initial', pointerEvents: 'none' },
    },
    disabled: {},
    item: {
        '&:hover': { color: theme.palette.primary.main },
        margin: '2px 8px 2px 0px',
    },
});

export default actionStyle;
