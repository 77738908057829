const filterStyle = theme => ({
    filterSection: { padding: theme.spacing(1, 2) },
    filterTextField: { '& *': { boxSizing: 'content-box' } },
    buttonLabel: {
        fontWeight: 600,
        cursor: 'pointer',
    },
    firstLabel: { paddingBottom: '16px' },
    restLabel: {
        paddingTop: '32px',
        paddingBottom: '16px',
    },
});

export default filterStyle;
