import media from '../../../../theme/MediaQueries';

const listItemsStyle = theme => ({
    topSection: {
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: 1,
    },
    root: {
        color: theme.components.dashboard.sideBar.contentColor,
        '&:hover': {
            backgroundColor: theme.components.dashboard.sideBar.highlight,
            color: theme.components.dashboard.sideBar.contentColoronHover,
        },
    },
    divider: { borderBottomColor: theme.components.dashboard.sideBar.lineSeperator },
    listItems: {
        padding: 0,
        '& $link': {
            flex: '1 1 auto',
            height: '100%',
        },
        '& $reactlink': {
            padding: theme.spacing(1.5, 2),
            [media.laptopUp]: { padding: theme.spacing(1.5, 2.5) },
            color: theme.components.dashboard.sideBar.contentColor,
            '&:hover': { color: theme.components.dashboard.sideBar.contentColoronHover },
        },
        '& $labelWithIcon': { '& $label, & $addonEnd': { marginLeft: theme.spacing(2) } },
        '& $activeLink': {
            '&$reactlink': {
                backgroundColor: theme.components.dashboard.sideBar.highlight,
                color: theme.components.dashboard.sideBar.contentColoronHover,
            },
        },
    },
    companyLogo: {
        height: 72,
        padding: '8px 16px',
        [media.laptopUp]: { padding: '8px 20px' },
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
    },
    footerTextDiv: {
        minHeight: 28,
        marginBottom: 28,
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        color: theme.components.dashboard.sideBar.contentColor,
        '& $footerText': {
            color: theme.components.dashboard.sideBar.contentColor,
            fontSize: 10,
            fontWeight: 600,
            [media.mobile]: { fontWeight: 400 },
            [media.phablet]: { fontWeight: 400 },
        },
    },
    footerText: {},
    activeLink: {},
    link: {},
    reactlink: {},
    labelWithIcon: {},
    addonEnd: {},
    label: {},
    copyrightIcon: { pointerEvents: 'none' },
    entityRoot: {
        color: theme.components.dashboard.sideBar.contentColor,
        '&:hover': { backgroundColor: theme.components.dashboard.sideBar.contentColoronHover },
    },
    parentEntityPadding: { padding: theme.spacing(1.5, 1, 1.5, 3) },
    childEntityPadding: { padding: theme.spacing(0, 1.5) },
    childEntityItem: {
        borderRadius: theme.spacing(0.5),
        '& $label': {
            fontSize: 14,
            lineHeight: '1.25rem',
            letterSpacing: '0.5px',
        },
    },
    iconColorWhite: { color: theme.components.dashboard.sideBar.contentColor },
    labelWithIconSpacing: { justifyContent: 'space-between' },
    noDivider: { borderBottomColor: theme.palette.secondary.main },
    borderBottom: { borderBottom: `1px solid ${theme.components.dashboard.sideBar.lineSeperator}` },
    childListItems: {
        '& $link': {
            flex: '1 1 auto',
            height: '100%',
        },
        '& $reactlink': {
            padding: theme.spacing(0, 1),
            margin: theme.spacing(1, 1, 1.25, 0.75),
            color: theme.components.dashboard.sideBar.contentColor,
            '&:hover': {
                color: theme.components.dashboard.sideBar.contentColoronHover,
                backgroundColor: theme.components.dashboard.sideBar.highlight,
            },
        },
        '& $labelWithIcon': { '& $label, & $addonEnd': { marginLeft: theme.spacing(1) } },
        '& $activeLink': {
            '&$reactlink': {
                backgroundColor: theme.components.dashboard.sideBar.highlight,
                color: theme.components.dashboard.sideBar.contentColoronHover,
                '&:hover': { color: theme.components.dashboard.sideBar.contentColoronHover },
            },
        },
        borderRadius: theme.spacing(0.5),
    },
    noPadding: { padding: 0 },
    selectedEntityIndicator: {
        padding: theme.spacing(0, 1.5, 0, 1.875),
        margin: theme.spacing(1.5, 1, 1.5, 0.5),
        borderLeft: `5px solid ${theme.components.dashboard.sideBar.contentColor}`,
        borderRadius: theme.spacing(0.5),
    },
    labelWithIconMargin: { '& $labelWithIcon': { '& $label, & $addonEnd': { marginLeft: theme.spacing(1) } } },
    stickyItems: {
        marginTop: theme.spacing(2.5),
        '&:hover': { backgroundColor: theme.components.dashboard.sideBar.contentColoronHover },
        '& $reactlink': {
            '&:hover': {
                color: theme.components.dashboard.sideBar.contentColoronHover,
                backgroundColor: theme.components.dashboard.sideBar.highlight,
            },
        },
        '& $labelWithIcon': {
            '& $label': {
                fontFamily: 'Open Sans',
                fontSize: 14,
                lineHeight: '1.25rem',
                letterSpacing: '1.25px',
            },
        },
    },
    textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Open Sans',
        fontSize: 14,
        lineHeight: '1rem',
        letterSpacing: '1.25px',
    },
    iconCenter: { justifyContent: 'center' },
    collapsePadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

export default listItemsStyle;
