import { withStyles } from '../../componentlibrary/components/Components';
import threeDotProgressStyle from '../styles/ThreeDotProgressStyle';

class ThreeDotProgress extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.spinner}>
                <div className={classes.bounce1} />
                <div className={classes.bounce2} />
                <div className={classes.bounce3} />
            </div>
        );
    }
}

ThreeDotProgress.propTypes = { classes: PropTypes.object };

export default withStyles(threeDotProgressStyle)(ThreeDotProgress);
