import localisable from 'Commons/config/strings/localisable';

const getPaymentHeaderActionItems = ({
    isBillAheadOpen, togglePromoPlan, showModal,
    toggleBillAhead, isBillAheadDisabled, isPromoPlanDisabled,
}) => {
    const { deviceInfo: { isDesktop } } = window;
    return [
        {
            shouldShow: true,
            onClick: togglePromoPlan,
            icon: 'cp-promo-plan',
            isDisabled: isPromoPlanDisabled,
            label: localisable.promoPlan,
        },
        {
            shouldShow: !isBillAheadOpen && (showModal || !isDesktop),
            onClick: toggleBillAhead,
            icon: 'cp-manual-charge',
            isDisabled: isBillAheadDisabled,
            label: localisable.billAhead,
        },
    ];
};

export { getPaymentHeaderActionItems };
