import { withStyles, Chip as DefaultChip, Typography, Grid } from 'Generic/componentlibrary/components/Components';
import CustomIcon from 'Generic/icon/components/Icon';
import ChipStyle from '../style/FilterChipStyle';

const Chip = ({
    data,
    isInModal,
    onDeleteChip,
    onSelectChip,
    classes,
}) => {
    const deleteChip = (event, index) => {
        event.stopPropagation();
        onDeleteChip(data, index);
    };

    const renderNestedChips = () => {
        const nestedChips = [];
        for (let index = 0; index < data.value.length; index += 1) {
            if (data.value[index] === '...') {
                nestedChips.push(
                    <Typography
                        key={index}
                        className={classes.detail}
                    >
                        ...
                    </Typography>,
                );
                break;
            } else {
                nestedChips.push(
                    <DefaultChip
                        classes={{
                            root: `${classes.nestedChipRoot}
                            ${isInModal ? classes.nestedChipRootInModal : undefined}`,
                            deleteIcon: classes.deleteIcon,
                            label: classes.label,
                        }}
                        key={index}
                        size="small"
                        label={data.value[index]}
                        deleteIcon={(
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                            >
                                <CustomIcon
                                    type="custom"
                                    variant="rounded"
                                    icon="cp-close"
                                    className={classes.closeIcon}
                                />
                            </Grid>
                        )}
                        onDelete={event => deleteChip(event, index)}
                    />,
                );
            }
        }
        return nestedChips;
    };

    const renderValue = () => {
        if (Array.isArray(data.value)) {
            return renderNestedChips();
        }
        return <Typography className={classes.detail}>{data.value}</Typography>;
    };

    const isNested = () => {
        if (isInModal && Array.isArray(data.value)) {
            return true;
        }
        return false;
    };

    return (
        <DefaultChip
            classes={{
                root: `${classes.root} ${isInModal ? classes.rootInModal : undefined}`,
                label: `${classes.label} ${classes.labelInModal}`,
                icon: `${classes.iconRoot} ${classes.leftIcon}`,
            }}
            size="medium"
            clickable
            onClick={onSelectChip}
            icon={(
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    onClick={event => deleteChip(event)}
                >
                    <CustomIcon
                        type="custom"
                        variant="rounded"
                        icon="cp-close"
                        className={classes.closeIcon}
                    />
                </Grid>
            )}
            label={(
                <Grid
                    container
                    wrap="nowrap"
                    alignItems={isInModal ? 'flex-start' : 'center'}
                    direction={isNested() ? 'column' : 'row'}
                >
                    <Typography className={classes.title}>
                        {data.label}
                    </Typography>
                    <Grid
                        container
                        wrap="wrap"
                        className={isNested()
                            ? classes.nestedChipContainerExpanded
                            : undefined}
                    >
                        {renderValue()}
                    </Grid>
                </Grid>
            )}
        />
    );
};

Chip.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
        ]),
    }),
    isInModal: PropTypes.bool,
    onDeleteChip: PropTypes.func,
    onSelectChip: PropTypes.func,
    classes: PropTypes.object,
};

Chip.defaultProps = { data: null, isInModal: false };

export default withStyles(ChipStyle)(Chip);
