import { mapEnum } from 'Commons/helpers/utils/Formatter';
import enums from './Enums';

const labelFormatter = label => (label === 'Active' ? 'Lead' : label);

const LEAD_STATUS = mapEnum(enums.leadStatus, labelFormatter);

const DATE_FIELD_WIDTH = 170;
const MOBILE_DATE_FIELD_WIDTH = 130;

export { LEAD_STATUS, DATE_FIELD_WIDTH, MOBILE_DATE_FIELD_WIDTH };
