import { memo } from 'react';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import { SM_8 } from 'Commons/config/constants/GridSpacingConstants';
import { FormDropdown } from 'Generic/dropdown/components/Dropdown';
import localisable from 'Commons/config/strings/localisable';
import levelSelectorStyles from '../styles/LevelSelectorStyles';

const useStyles = makeStyles(levelSelectorStyles, { name: 'LevelSelector' });

const LevelSelector = ({ levelsList, onLevelChange }) => {
    const classes = useStyles();

    return (
        <Grid
            className={classes.container}
        >
            <FormDropdown
                name="bev.level"
                list={levelsList}
                label={localisable.level}
                placeholder={localisable.selectLevel}
                GridProps={SM_8}
                useField
                noGrid
                onChange={onLevelChange}
            />
        </Grid>
    );
};

LevelSelector.propTypes = {
    levelsList: PropTypes.array,
    onLevelChange: PropTypes.func,
};

LevelSelector.defaultProps = { onLevelChange: () => {}, levelsList: [] };

export default memo(LevelSelector);
