import { useEffect, memo, useRef } from 'react';
import { makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { DEMO_FACILITY_ID_LIST, EMPTY_FUNC } from 'Commons/config/constants/Constants';
import { convertToNumber, isProduction } from 'Commons/helpers/utils/Utils';
import {
    ACCOUNT_TOKEN, HOSTED_FORM_CARD_ENTRY_CONTEXT, HOSTED_FORM_DATA, HOSTED_FORM_ID, HOSTED_FORM_OPERATION,
    HOSTED_FORM_PAYMENT_SOURCE_TYPE, HOSTED_PAYMENTS_RESPONSE, TRANSACTION_ID,
} from '../config/Constants';
import syrapayHostedFormStyle from '../styles/SyrapayHostedFormStyle';

const useStyle = makeStyles(syrapayHostedFormStyle, { name: 'SyrapayHostedForm' });

/*
    This component opens up the Hosted Form to accept the payments. It is built as recommended by the Fullsteam docs.
    It needs Jquery to run and some hidden inputs to get the data back from the hosted form.
*/
const SyrapayHostedForm = ({
    paymentAmount, shouldSaveCard, shouldUseTenantAddress, tokenOnly, formId, onSubmit,
    tenantInfo: { name: { firstName, lastName } = {}, address: { line1, zipCode } = {} } = {},
    authenticationKey, disableFormSubmit, closeHostedForm, facilityId,
}) => {
    const classes = useStyle();
    const submitButtonRef = useRef(null);
    const isCaptureEnabled = useRef(false);

    let onCompletion = () => {
        disableFormSubmit(false);
        const transactionId = document.getElementById(TRANSACTION_ID).value;
        const token = document.getElementById(ACCOUNT_TOKEN).value;
        const response = document.getElementById(HOSTED_PAYMENTS_RESPONSE).value;
        const hostedFormScriptElement = document.getElementById(authenticationKey);
        if (hostedFormScriptElement && (transactionId || token || response) && isCaptureEnabled.current) {
            isCaptureEnabled.current = false;
            onSubmit({ transactionId, token, response });
        }
    };

    const onLoadFullSteamForm = () => {
        // eslint-disable-next-line no-undef
        setupFullsteamHostedPayments({
            showZip: true,
            authenticationKey,
            showAddress1: true,
            formId,
            showNameOnAccount: true,
            showCountry: true,
            includeToken: shouldSaveCard || tokenOnly,
            completionCallback: (...args) => onCompletion(...args),
            ...!tokenOnly && { paymentAmount },
            cancellationCallback: closeHostedForm,
            paymentSourceType: HOSTED_FORM_PAYMENT_SOURCE_TYPE.CARD,
            cardEntryContext: HOSTED_FORM_CARD_ENTRY_CONTEXT.WEB_MERCHANT_ENTERED_PHONE_ORDER,
            operationType: tokenOnly ? HOSTED_FORM_OPERATION.TOKEN : HOSTED_FORM_OPERATION.AUTHORIZATION,
            ...shouldUseTenantAddress
            && {
                nameOnAccount: firstName ? `${firstName} ${lastName}` : lastName,
                ...line1 && { address1: line1 },
                ...zipCode && { zip: zipCode },
            },
        });
        submitButtonRef.current.click(); // Programmatically clicking the hidden submit button to open the hosted form.
    };

    const getHostedFormScriptSrc = () => {
        if (isProduction()) {
            return DEMO_FACILITY_ID_LIST.includes(convertToNumber(facilityId))
                ? process.env.TEST_HOSTED_FORM_URL : process.env.HOSTED_FORM_URL;
        }
        return process.env.HOSTED_FORM_URL;
    };

    const addJQueryScriptOfHostedForm = () => {
        const currentForm = document.getElementById(formId);
        const hostedFormScript = document.createElement('script');
        hostedFormScript.src = getHostedFormScriptSrc();
        hostedFormScript.id = authenticationKey;
        hostedFormScript.type = 'text/javascript';
        hostedFormScript.onload = onLoadFullSteamForm;
        currentForm.appendChild(hostedFormScript);
    };

    useEffect(() => {
        const { jQuery } = window;
        if (!jQuery) import('jquery').then((jQueryModule) => { window.jQuery = jQueryModule.default; });
        return () => {
            const hostedFormScriptElement = document.getElementById(authenticationKey);
            if (hostedFormScriptElement) {
                onCompletion = EMPTY_FUNC;
                hostedFormScriptElement.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (authenticationKey) {
            isCaptureEnabled.current = true;
            disableFormSubmit(true);
            addJQueryScriptOfHostedForm();
            /*
                Disabling the form submit as clicking(programmatically) on the submit button of this component triggers the
                form submit as well.
            */
        }
    }, [authenticationKey]);

    return (
        <>
            <input type="hidden" name={TRANSACTION_ID} id={TRANSACTION_ID} />
            <input type="hidden" name={ACCOUNT_TOKEN} id={ACCOUNT_TOKEN} />
            <input type="hidden" name={HOSTED_PAYMENTS_RESPONSE} id={HOSTED_PAYMENTS_RESPONSE} />
            <input
                ref={submitButtonRef}
                type="submit" /* It should be of type submit, It will get intercepted and hosted form will be shown */
                className={classes.hidden}
                id="submitButton"
            />
            <div id={HOSTED_FORM_DATA} />
        </>
    );
};

SyrapayHostedForm.propTypes = {
    formId: PropTypes.string,
    tokenOnly: PropTypes.bool,
    tenantInfo: PropTypes.object,
    shouldSaveCard: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    shouldUseTenantAddress: PropTypes.bool,
    authenticationKey: PropTypes.string,
    closeHostedForm: PropTypes.func.isRequired,
    disableFormSubmit: PropTypes.func.isRequired,
    facilityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    paymentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SyrapayHostedForm.defaultProps = {
    tenantInfo: {},
    tokenOnly: false,
    shouldSaveCard: false,
    formId: HOSTED_FORM_ID,
    shouldUseTenantAddress: false,
};

export default memo(SyrapayHostedForm);
