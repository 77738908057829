import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { DROPDOWN_MAX_HEIGHT } from 'External/containers/configuration/config/Constants';
import { getOptionProps } from 'Commons/helpers/utils/SDFormatter';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import { labelFormatter } from 'Commons/helpers/utils/Formatter';
import { CONFIGURATION_TYPE, EMPTY_LIST } from 'Commons/config/constants/Constants';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import JournalConfigurationChangesFilterStyle from '../styles/journalConfigurationChangesFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';


const useStyles = makeStyles(JournalConfigurationChangesFilterStyle, { name: 'JournalConfigurationChangesFilter' });

const JournalConfigurationChangesFilter = ({
    formProps,
    formProps: {
        setFieldValue,
        values: { terms: { category: configurationIdList = EMPTY_LIST } = {} },
    } = {},
}) => {
    const classes = useStyles();
    const configList = useConstructor(() => Object.values(CONFIGURATION_TYPE).filter(
        ({ value }) => value !== CONFIGURATION_TYPE.Retail_Sale_Configuration.value,
    ).map(({ value = '', ...restConfig } = {}) => ({ id: value.toLowerCase(), ...restConfig, value: value.toLowerCase() })));

    const onSelectOrRemoveConfigType = (selectedConfigs) => {
        const selectedConfigValues = Object.keys(selectedConfigs);
        setFieldValue('terms.category', selectedConfigValues);
    };

    return (
        <>
            <RangeFilter
                isDateRange
                name="createdDate"
                formProps={formProps}
                setFieldValue={setFieldValue}
                label={localisable.modifiedEffectiveDate}
            />
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.searchConfiguration}
            </Typography>
            <MultiSelect
                list={configList}
                placeholder={localisable.searchConfiguration}
                onSelect={onSelectOrRemoveConfigType}
                getOptionProps={getOptionProps}
                keyToMatch="id"
                inputValueFormatter={labelFormatter}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                autoSuggestKey="matchPhrasePrefix.label"
                onRemove={onSelectOrRemoveConfigType}
                initiallySelectedValues={configurationIdList}
            />
        </>
    );
};

JournalConfigurationChangesFilter.propTypes = { formProps: PropTypes.object };

JournalConfigurationChangesFilter.defaultProps = { formProps: {} };

export default memo(JournalConfigurationChangesFilter);
