import { EMPTY_FUNC } from 'Commons/config/constants/Constants';

const SmartListProps = {
    postProcessOnAction: PropTypes.func,
    permission: PropTypes.object,
    listConfig: PropTypes.oneOfType([
        PropTypes.shape({
            store: PropTypes.string.isRequired,
            columns: PropTypes.any.isRequired,
            createUrl: PropTypes.string,
            searchUrl: PropTypes.string,
            filterSearchUrl: PropTypes.string, // The search url used for filtering
            name: PropTypes.string,
            emptyBodyText: PropTypes.any,
            loadingBodyText: PropTypes.any,
            tableProps: PropTypes.any,
            tablePaperProps: PropTypes.any,
            filterProps: PropTypes.any, // The props for filter overlay
            formatterProps: PropTypes.any,
            actionItemsPanelProps: PropTypes.object,
            quickFilters: PropTypes.array,
            showCount: PropTypes.bool,
            source: PropTypes.string,
            disableDebouncing: PropTypes.bool,
        }),
        PropTypes.func,
    ]),
    clearStoreKeyOnUnmount: PropTypes.bool,
    storesToNotClearSilently: PropTypes.array,
};

const SmartListDefaultProps = {
    postProcessOnAction: () => { },
    clearStoreKeyOnUnmount: false,
    onAutoSuggestTextFieldChange: EMPTY_FUNC,
    disableDebouncing: false,
};

export { SmartListProps, SmartListDefaultProps };
