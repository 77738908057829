import { useParams, useNavigate, useLocation } from 'Commons/components/generic/componentlibrary/components/Components';
import { useContext } from 'react';
import RouterContext from 'Commons/contexts/RouterContext';
import getUUID from 'Commons/helpers/utils/generateUUID';

const getRoutePath = (location, params) => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}?`);
        }
    });
    return path;
};

const useRouter = (path) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { router } = useContext(RouterContext);

    const match = {
        url: location.pathname,
        isExact: false,
        params,
        path: path || getRoutePath(location, params),
    };

    const useBlocker = (shouldBlock) => {
        const blockerId = getUUID();
        router.getBlocker(blockerId, args => !shouldBlock(args));
        return () => router.deleteBlocker(blockerId); // cleanup on unmount
    };

    const navigateRoute = (pathArg, state, replace = false) => {
        if (typeof pathArg === 'string') {
            navigate(pathArg, { state, replace });
        } else {
            const { pathname, state: routeState = {} } = pathArg;
            navigate(pathname, { state: routeState, replace });
        }
    };

    const history = {
        push: (pathArg, state) => navigateRoute(pathArg, state),
        replace: (pathArg, state) => navigateRoute(pathArg, state),
        goBack: () => navigate(-1),
        block: useBlocker,
        location,
        match,
    };

    return { location, match, history };
};

export default useRouter;
