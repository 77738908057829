import { memo, useMemo, useRef, useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import Modal from 'Generic/modal/components/Modal';
import { FooterButton } from 'Commons/components/business/footer/components/Footer';
import Typography from 'Generic/typography/components/Typography';
import localisable from 'Commons/config/strings/localisable';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import { DEFAULT_LEVEL_LABEL } from 'Commons/components/business/bev/config/Constants';
import { clsx } from 'Commons/helpers/utils/clsx';
import AlertDialog from 'Generic/alertdialog/components/AlertDialog';
import { getWhiteSpaceTrimmedString } from 'Commons/helpers/utils/StringHelpers';
import { convertToNumber } from 'Commons/helpers/utils/Utils';
import Level from './Level';
import levelManagementStyles from '../styles/LevelManagementStyles';

const useStyles = makeStyles(levelManagementStyles, { name: 'levelManagement' });

const LevelManagement = (props) => {
    const classes = useStyles();
    const { deviceInfo: { isMobile } = {} } = window;
    const {
        handleLevelsSave,
        openLevelManagementModal,
        closeLevelManagementModal,
        isButtonLoading,
        formProps,
        formProps: {
            setFieldValue,
            setErrors,
            initialValues: {
                extra: {
                    initialLevelsObj = {},
                    levelsList: initialLevelsList = [],
                } = {},
            } = {},
            errors: {
                extra: { levelsList: erredLevelsList = [] } = {},
                ...remainingErrors
            } = {},
            values: { extra: { levelsList = {} } = {} } = {},
        } = {},
        fetchUnitCountData,
    } = props;

    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [idOfLevelBeingEdited, setIdOfLevelBeingEdited] = useState(null);
    const [idOfLevelBeingAdded, setIdOfLevelBeingAdded] = useState(null);
    const [isUnitCountDataLoading, setIsUnitDataCountLoading] = useState(true);
    const [unitCountData, setUnitCountData] = useState({});
    const hasSomethingBeenEdited = useRef(false);

    const onFetchUnitCountData = (apiError, response) => {
        if (response) {
            const { data = {} } = response;
            setUnitCountData(data);
            setIsUnitDataCountLoading(false);
        }
    };

    const setHasSomethingBeenEdited = (value) => {
        hasSomethingBeenEdited.current = value;
    };

    const cleanUpBeforeDiscardOrClose = () => {
        setFieldValue('extra.levelsList', initialLevelsList, false);
        if (openLevelManagementModal) {
            setHasSomethingBeenEdited(false);
            setErrors({ ...remainingErrors });
            setIdOfLevelBeingEdited(null);
            setIdOfLevelBeingAdded(null);
        }
    };

    useEffect(() => {
        fetchUnitCountData(onFetchUnitCountData);
        return () => cleanUpBeforeDiscardOrClose();
    }, [openLevelManagementModal]);

    const levelLabelToLevelKeyMap = useMemo(() => levelsList.reduce((prevValue, level) => {
        const { label, value: levelKey } = level;
        if (label === null) return prevValue;
        return {
            ...prevValue,
            [getWhiteSpaceTrimmedString(label)]: levelKey,
        };
    }, {}), [idOfLevelBeingEdited, idOfLevelBeingAdded, levelsList.length]);

    const onLevelAdd = (arrayHelpers) => {
        const levelsLength = levelsList.length;
        const newLevelKey = levelsLength
            ? (Math.max(...levelsList.map(({ value: levelKey }) => convertToNumber(levelKey))) + 1).toString()
            : '0';

        const newLabel = levelsLength ? DEFAULT_LEVEL_LABEL.UNTITLED : DEFAULT_LEVEL_LABEL.LEVEL_1;

        arrayHelpers.push({ value: newLevelKey, label: newLabel });

        setHasSomethingBeenEdited(true);
        setIdOfLevelBeingEdited(null);
        setIdOfLevelBeingAdded(newLevelKey);
    };

    const onLevelEdit = (levelKey) => {
        setHasSomethingBeenEdited(true);
        setIdOfLevelBeingAdded(null);
        setIdOfLevelBeingEdited(levelKey);
    };

    const onLevelDelete = (arrayHelpers, levelKey, index) => {
        setHasSomethingBeenEdited(true);
        const isAlreadyExistingLevel = !!initialLevelsObj[levelKey]; // If levelKey doesn't exists in initialLevelsObj, then it's a newly added level
        if (isAlreadyExistingLevel) setFieldValue(`extra.levelsList.${index}.label`, null, true);
        else arrayHelpers.remove(index);
        setIdOfLevelBeingEdited(null);
    };

    const onCancelAdd = (arrayHelpers, index) => {
        arrayHelpers.remove(index);
        setIdOfLevelBeingAdded(null);
    };

    const onDiscardChanges = () => {
        if (!hasSomethingBeenEdited.current) return;
        cleanUpBeforeDiscardOrClose();
    };

    const onAlertDialogYesAction = () => {
        setShowAlertDialog(false);
        closeLevelManagementModal();
    };

    const onSaveClick = () => {
        setIdOfLevelBeingEdited(null);
        setIdOfLevelBeingAdded(null);
        handleLevelsSave();
    };

    const renderLevels = arrayHelpers => levelsList.map((level, index) => {
        const { value: levelKey, label } = level;
        const isThisLevelBeingEdited = levelKey === idOfLevelBeingEdited;
        const isThisLevelBeingAdded = levelKey === idOfLevelBeingAdded;
        if (label === null) return null;
        const { [levelKey]: { totalCount: unitsOnLevel = 0 } = {} } = unitCountData;
        const levelData = {
            levelKey,
            unitsOnLevel,
        };
        return (
            <Level
                key={levelKey}
                index={index}
                arrayHelpers={arrayHelpers}
                formProps={formProps}
                levelData={levelData}
                levelLabelToLevelKeyMap={levelLabelToLevelKeyMap}
                onLevelDelete={onLevelDelete}
                onLevelEdit={onLevelEdit}
                onCancelAdd={onCancelAdd}
                isEditMode={isThisLevelBeingEdited}
                isAddMode={isThisLevelBeingAdded}
                isUnitCountDataLoading={isUnitCountDataLoading}
            />
        );
    });

    const renderHeader = arrayHelpers => (
        <Grid container>
            <Typography variant="h6">{localisable.levelManagement}</Typography>
            <Button
                variant="icon"
                icon="cp-add"
                iconType="custom"
                color="primary"
                onClick={() => onLevelAdd(arrayHelpers)}
                className={classes.addButton}
            />
        </Grid>
    );

    const renderBody = arrayHelpers => (
        <Grid className={classes.listContainer}>
            <Grid container className={classes.listHeader}>
                <Grid item lg={4} xs={4} className={classes.listColumnHeading}>
                    <Typography fontFamily="Open sans" variant="subtitle1">{localisable.name}</Typography>
                </Grid>
                <Grid container item lg={3} xs={4} justify="flex-end" className={classes.listColumnHeading}>
                    <Typography
                        fontFamily="Open sans"
                        variant="subtitle1"
                    >{localisable.unitsInTheLevel}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    lg={5}
                    xs={4}
                    justify="flex-end"
                    className={clsx(classes.listColumnHeading, !isMobile && classes.lastColumnPadding)}
                >
                    <Typography
                        fontFamily="Open sans"
                        variant="subtitle1"
                    >{localisable.actionsLower}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.levelsContainer}>
                {renderLevels(arrayHelpers)}
            </Grid>
        </Grid>
    );

    const renderFooter = () => (
        <Grid container justify="flex-end">
            <Grid item>
                <FooterButton
                    variant="outlined"
                    onClick={onDiscardChanges}
                    classes={{ buttonWidth: classes.buttonWidth }}
                    buttonProps={{ label: localisable.discardChangesButton }}
                    disableElevation
                />
            </Grid>
            <Grid item>
                <FooterButton
                    onClick={onSaveClick}
                    classes={{ buttonWidth: classes.buttonWidth }}
                    buttonProps={{
                        label: localisable.save,
                        isButtonLoading,
                    }}
                    disabled={!!erredLevelsList.length || !hasSomethingBeenEdited.current}
                    disableElevation
                />
            </Grid>
        </Grid>
    );

    return (
        <>
            <FieldArray
                name="extra.levelsList"
                render={arrayHelpers => (
                    <Modal
                        open={openLevelManagementModal}
                        header={renderHeader(arrayHelpers)}
                        onClose={hasSomethingBeenEdited.current ? () => setShowAlertDialog(true) : closeLevelManagementModal}
                        classes={{
                            modalHeader: classes.modalHeader,
                            modalBody: classes.modalBody,
                            modalFooter: classes.modalFooter,
                            modal: classes.modal,
                            modalGrid: classes.modalGrid,
                        }}
                        footer={renderFooter()}
                        disablePortal
                    >
                        {renderBody(arrayHelpers)}
                    </Modal>
                )}
            />
            <AlertDialog
                open={showAlertDialog}
                onClose={() => setShowAlertDialog(false)}
                title={localisable.warning}
                actions={(
                    <Button
                        onClick={onAlertDialogYesAction}
                        variant="text"
                    >
                        {localisable.ok}
                    </Button>
                )}
            >
                <Typography>{localisable.discardConfirmation}</Typography>
            </AlertDialog>
        </>
    );
};

LevelManagement.propTypes = {
    formProps: PropTypes.object,
    fetchUnitCountData: PropTypes.func,
    handleLevelsSave: PropTypes.func,
    openLevelManagementModal: PropTypes.bool,
    closeLevelManagementModal: PropTypes.func,
    isButtonLoading: PropTypes.bool,
};

LevelManagement.defaultProps = { formProps: {} };

export default memo(LevelManagement);
