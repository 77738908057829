import PropTypes from 'prop-types';
import { SyrasoftConnectMini, SyrasoftConnectTaglineWhite, SyrasoftConnectTaglineBlack } from './ImageList';

const CompanyLogo = ({ mini, height, width, white, ...props }) => {
    const syrasoftConnectFull = white ? SyrasoftConnectTaglineWhite : SyrasoftConnectTaglineBlack;
    const src = mini ? SyrasoftConnectMini : syrasoftConnectFull;
    return (
        <div {...props}>
            <img src={src} height={height} width={width} alt="logo" />
        </div>
    );
};

CompanyLogo.propTypes = {
    mini: PropTypes.bool,
    white: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
};

CompanyLogo.defaultProps = {
    mini: false,
    height: 50,
    width: 200,
    white: false,
};

export default React.memo(CompanyLogo);
