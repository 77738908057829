import media from 'Commons/theme/MediaQueries';

const PaymentStyle = theme => ({
    footer: {},
    body: { padding: 0 },
    whiteBackground: {
        height: '100%',
        [media.laptopUp]: { backgroundColor: theme.palette.common.white },
    },
    formStyle: { width: '100%', height: '100%' },
    fullWidth: { width: '100%' },
    autoWidth: { width: 'auto' },
    relativePosition: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    overflowHidden: { overflow: 'hidden' },
    mask: {
        position: 'absolute',
        height: '100%',
        backgroundColor: theme.hexWithOpacity(theme.palette.common.white, 5),
        zIndex: 802,
    },
    modalGrid: {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        left: '50%',
        [media.tabletUp]: {
            width: '35vw',
            height: '95vh',
        },
        background: 'transparent',
        transition: '200ms width ease-in',
    },
    modalGridBillAheadOpen: {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        left: '55%',
        [media.tabletUp]: {
            width: '85vw',
            height: '85vh',
        },
        background: 'transparent',
        transition: '200ms width ease-in',
    },
    billAheadOrUnpaidBillsModal: { height: '75%', width: '80%' },
    loader: { zIndex: 802, height: '100%' },
    noDisplay: { display: 'none' },
    pageHeader: { marginBottom: theme.spacing(0), padding: theme.spacing(2, 4) },
    sideContainer: {
        position: 'relative',
        height: '96%',
        maxWidth: '85%',
        [media.tabletUp]: { maxWidth: '100%' },
        backgroundColor: theme.palette.common.white,
        marginLeft: theme.spacing(1),
    },
    billAheadContainer: { padding: theme.spacing(2, 4) },
    unpaidBillsContainer: { padding: theme.spacing(2, 2) },
    fullHeight: { height: '100%' },
    fitPage: {
        [media.laptopUp]: { width: '30vw' },
        transition: '200ms width ease-in',
    },
    fitPageBillAheadOpen: {
        [media.laptopUp]: { width: '84vw' },
        transition: '200ms width ease-in',
    },
    clickHereForMoreButtonContainer: { paddingTop: theme.spacing(4) },
    clickHereForMoreButton: { fontWeight: 500 },
    unpaidBillsCloseButton: { position: 'absolute', right: 8, top: 8 },
    unpaidBillsListContainer: { paddingTop: theme.spacing(4) },
    infoIcon: { marginBottom: theme.spacing(1), color: theme.palette.error.main },
    errorAlertContainer: { padding: theme.spacing(1) },
    exitButton: { minWidth: 0, marginRight: theme.spacing(2) },
    paymentLoginContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: theme.hexWithOpacity(theme.palette.common.white, 9),
        display: 'none',
        zIndex: 0,
    },
    visibleLoginContainer: {
        display: 'block',
        zIndex: 1000,
    },
    loginContainer: { width: '100%', height: '100%', boxShadow: 'none', padding: theme.spacing(2, 4) },
});

export default PaymentStyle;
