const enums = {
    modes: ['setup', 'view'],
    positions: ['left', 'right', 'top', 'bottom', 'vCenter', 'hCenter', 'center'],
    trianglePositions: ['bottom_right', 'top_right', 'top_left'],
    triangleTypes: ['bottomRight', 'topRight', 'topLeft'],
    actions: ['drag', 'rotate'],
    directions: ['up', 'right', 'down', 'left'],
    rentalStatus: ['Vacant', 'Rented'],
    defaultLevelLabels: ['Level 1', 'Untitled'],
    eventTriggerTypes: ['manual'],
    entityType: ['Unit', 'Placeholder'],
    placeholderType: ['Office', 'Road', 'Lift', 'Others'],
};

export default enums;
