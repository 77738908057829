const NUMBER_OF_TIMES_REPROCESSED = {
    One_Attempt: {
        label: '1 Attempt',
        value: '',
    },
    Two_Attempts: {
        label: '2 Attempts',
        value: '1',
    },
    Three_Plus_Attempts: {
        label: '3+ Attempts',
        value: '2',
    },
};

export default NUMBER_OF_TIMES_REPROCESSED;
