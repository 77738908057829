import { isValidValue } from 'Commons/helpers/utils/DataHelpers';
import { EMPTY_STRING } from 'Commons/config/constants/Constants';

export function calculateFilters(filters = {}, fixedFiltersKeys = [], valuesToIgnoreInCount = {}) {
    const filterKeys = Object.keys(filters);
    let filterSize = 0;
    if (filterKeys.length > 0) {
        filterKeys.forEach((key) => {
            if (isValidValue(filters[key])) {
                const filterValue = filters[key];
                if (!fixedFiltersKeys.includes(key)) {
                    if (Array.isArray(filterValue) && filterValue.length > 0) {
                        const valuesToIgnore = valuesToIgnoreInCount[key] || [];
                        const filteredFilterValue = filterValue.filter(value => !valuesToIgnore.includes(value));
                        filterSize += filteredFilterValue.length;
                    } else if (typeof filterValue === 'object' && Object.keys(filterValue).length > 0) {
                        filterSize += calculateFilters(filterValue, fixedFiltersKeys, valuesToIgnoreInCount);
                    } else if (typeof filterValue === 'string' && filterValue.length > 0) {
                        filterSize += 1;
                    } else if (typeof filterValue === 'boolean') {
                        filterSize += 1;
                    }
                }
            }
        });
    }
    return filterSize;
}

export function clearAllFields(filters = {}, fixedFiltersKeys = []) {
    const filterKeys = Object.keys(filters);
    const cleansedFilters = {};
    if (filterKeys.length > 0) {
        filterKeys.forEach((key) => {
            if (isValidValue(filters[key])) {
                const filterValue = filters[key];
                if (Array.isArray(filterValue) && filterValue.length > 0) {
                    cleansedFilters[key] = fixedFiltersKeys.includes(key) ? filters[key] : [];
                } else if (typeof filterValue === 'object' && Object.keys(filterValue).length > 0) {
                    cleansedFilters[key] = clearAllFields(filterValue, fixedFiltersKeys);
                } else if (typeof filterValue === 'string' && filterValue.length > 0) {
                    cleansedFilters[key] = fixedFiltersKeys.includes(key) ? filters[key] : EMPTY_STRING;
                } else if (typeof filterValue === 'boolean') {
                    cleansedFilters[key] = fixedFiltersKeys.includes(key) ? filters[key] : null;
                }
            }
        });
    }
    return cleansedFilters;
}
