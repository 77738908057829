const breadCrumbStyle = theme => ({
    pageHeading: {
        margin: theme.spacing(1.5, 0, 0, 0),
        width: '100%',
        minHeight: theme.spacing(5),
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
    },
});

export default breadCrumbStyle;
