import { ONE_MINUTE } from 'Commons/config/constants/Constants';

const fetchWithTimeout = async (url, options = {}, timeout = ONE_MINUTE) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);
    return response;
};

export default fetchWithTimeout;
