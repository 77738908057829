import React, { memo } from 'react';
import { Grid, Typography, makeStyles } from 'Commons/components/generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import { WEEKDAYS_SUNDAY_FIRST } from 'External/containers/pendingTasks/components/reminders/config/Constants';
import { MAX_WEEKS_IN_MONTH } from 'Commons/config/constants/Constants';
import Week from './Week';
import monthStyle from '../styles/MonthStyle';


const useStyles = makeStyles(monthStyle, { name: 'Month' });

const Month = ({
    calendarWeekStartingDay,
    numberOfWeeksToDisplay,
    daysOfWeek,
    numberOfDays,
    startingDay,
    prevMonthLastDay,
    month,
    year,
    currentDate,
    renderInRows,
    data,
    shouldTruncateTitle,
    truncateTitleLength,
    dayComponentProps = {},
    ...restProps
}) => {
    const classes = useStyles({ renderInRows });
    const { shouldTruncateTitleForSixWeekMonth, truncateTitleLengthForSixWeekMonth } = dayComponentProps;
    const shouldTruncateTitleValue = numberOfWeeksToDisplay === MAX_WEEKS_IN_MONTH ? shouldTruncateTitleForSixWeekMonth : shouldTruncateTitle;
    const truncateTitleLengthValue = numberOfWeeksToDisplay === MAX_WEEKS_IN_MONTH ? truncateTitleLengthForSixWeekMonth : truncateTitleLength;

    return (
        <>
            <Grid container direction={renderInRows ? 'row' : 'column'} className={clsx(classes.weekDays)}>
                {daysOfWeek.map(({ value: day }) => (
                    <Grid
                        item
                        key={day}
                        xs
                        className={clsx(classes.paper, classes.weekDayContainer)}
                    >
                        <Typography variant="h6">{day}</Typography>
                    </Grid>
                ))}
            </Grid>
            <Week
                calendarWeekStartingDay={calendarWeekStartingDay}
                numberOfWeeksToDisplay={numberOfWeeksToDisplay}
                daysOfWeek={daysOfWeek}
                numberOfDays={numberOfDays}
                startingDay={startingDay}
                prevMonthLastDay={prevMonthLastDay}
                month={month}
                year={year}
                currentDate={currentDate}
                renderInRows={renderInRows}
                data={data}
                shouldTruncateTitle={shouldTruncateTitleValue}
                truncateTitleLength={truncateTitleLengthValue}
                {...restProps}
            />
        </>
    );
};

Month.propTypes = {
    calendarWeekStartingDay: PropTypes.number,
    numberOfWeeksToDisplay: PropTypes.number,
    daysOfWeek: PropTypes.array,
    numberOfDays: PropTypes.number,
    startingDay: PropTypes.number,
    prevMonthLastDay: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    currentDate: PropTypes.object,
    renderInRows: PropTypes.bool,
    data: PropTypes.object,
    dayComponentProps: PropTypes.object,
    shouldTruncateTitle: PropTypes.bool,
    truncateTitleLength: PropTypes.number,
};

Month.defaultProps = {
    calendarWeekStartingDay: 0,
    numberOfWeeksToDisplay: 6,
    daysOfWeek: Object.values(WEEKDAYS_SUNDAY_FIRST),
    renderInRows: false,
    data: {},
};

export default memo(Month);
