import Typography from 'Generic/typography/components/Typography';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import InheritedConfigLabelStyle from '../styles/InheritedConfigLabelStyle';

const useStyles = makeStyles(InheritedConfigLabelStyle, { name: 'InheritedConfigLabel', index: 1 });

const InheritedConfigLabel = ({
    readOnly,
    label,
    variant,
    fontFamily,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Typography
            variant={variant}
            className={clsx({ [classes.readOnly]: readOnly })}
            fontFamily={fontFamily}
            {...props}
        >
            {label}
        </Typography>
    );
};

InheritedConfigLabel.propTypes = {
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.string,
    fontFamily: PropTypes.string,
};

InheritedConfigLabel.defaultProps = {
    variant: 'body2',
    fontFamily: 'Open Sans',
};

export default React.memo(InheritedConfigLabel);
