const enums = {
    primary: ['Cell_Phone', 'Business_Phone', 'Home_Phone', 'Fax_Phone'],
    actionType: ['Update', 'Delete', 'Create', 'Deactivate', 'Activate', 'Lock', 'Unlock'],
    view: ['list', 'detail', 'mini', 'micro', 'card', 'mini_list', 'access_code', 'walk_around', 'walk_around_config'],
    status: ['Active', 'Inactive', 'Inactive_For_Future_Use', 'Locked', 'Created'],
    rentAssessmentMode: ['Automatic', 'Manual'],
    nameSuffix: ['Sr', 'Jr', 'III', 'IV'],
    nameSalutation: ['Mr', 'Ms', 'Mrs', 'Dr'],
    browsers: ['Safari', 'Mobile Safari', 'Chrome', 'Opera', 'Edge', 'Brave'],
    source: ['dashboard', 'tenant', 'lead', 'unit', 'user', 'chargeCategory', 'paymentMethod', 'taxCode', 'taxExempt',
        'unitType', 'vendor', 'reservation', 'inventoryList', 'userRole', 'configuration', 'tenantAlert', 'textList',
        'promoPlan', 'ledger', 'users', 'ar', 'payment', 'manualLateEvent', 'maintenanceEvent', 'promoPlanTemplate',
        'maintenanceEventConfig', 'maintenance_operation', 'delinquency', 'estimate', 'unpaidAr', 'allAchBatches',
        'pendingTasks', 'pendingOverlockUnlockList', 'emailVerificationPending', 'reminder', 'retailSale', 'text', 'facility', 'businessgroup',
        'emailList', 'conversationList', 'email', 'contactlog', 'rentAdjustment', 'rateHistory', 'customMergeFields',
        'automaticCreditCardDeclines', 'retailSaleReturns', 'failedReturns', 'standard', 'account',
        'tenantChangeTracking', 'waitingList', 'printFailures', 'Edit_Reservation', 'vehicle', 'creditCardBatches',
        'createCCBatch', 'ledgerReview', 'delinquencyTrackingOverview', 'delinquencyTrackingLateEvent',
        'delinquencyTrackingLockoutEvent', 'delinquencyTrackingScheduledEvent', 'delinquencyTrackingWrongEntry',
        'ccAboutToExpire', 'pendingInvoiceDelivery', 'emailAudit', 'journalConfigurationChanges', 'journalLateEvent',
        'journalLockoutEvent', 'journalUserRole', 'journalUserManagement', 'unitDepositUpdate', 'tenantDepositUpdate',
    ],
    auto_suggest_resource: ['unit', 'tenant', 'lead', 'tenant_lead'],
    internalSource: ['accountInt', 'facilityInt', 'userInt'],
    feature: ['movein', 'moveout', 'payment', 'transfer'],
    dashboardType: ['internal', 'external', 'settings'],
    formMode: ['create', 'edit', 'copy'],
    slideDirection: ['left', 'right', 'up', 'down'],
    transitionVariant: ['slide', 'fade', 'grow'],
    state: ['loading'],
    reservationStatus: ['Reserved', 'Cancelled', 'Moved-In'],
    delinquencyStatus: ['Processed', 'Not_Processed', 'Waived', 'Active', 'Inactive', 'In_Progress', 'Error',
        'Completed', 'Skipped'],
    delinquencySchedulerStatus: ['Active', 'Inactive'],
    entityLevel: ['Facility', 'Business_Group', 'Account', 'System_Facility'],
    userType: ['Standard', 'Admin', 'Dev_Lead', 'Sales', 'Dev_Junior', 'Dev_Senior', 'Migration'],
    wsEventType: ['Created', 'Updated', 'Initiated', 'Started', 'Finished', 'Success', 'Failed'],
    wsEventSource: ['Rent_Assessment', 'Late_Event', 'Bill_Ahead'],
    unitAggregation: ['All_Units_Count_By_Level', 'Vacant_Units_Count_By_Unit_Type', 'Vacant_And_Reservation_Units_Count_By_Unit_Type'],
    timeWindow: ['years', 'months', 'days', 'quarters', 'weeks', 'hours', 'minutes', 'seconds', 'milliseconds'],
    militaryBranch: ['NA', 'Army', 'Navy', 'Air_Force', 'Marines', 'Coast_Guard'],
    militaryType: ['NA', 'Active', 'Reserve', 'Veteran', 'Retired', 'Deployed'],
    reportType: ['Ledger_History', 'Invoice_All_Charges_Due_Detailed', 'Invoice_All_Charges_Due_Summarized',
        'Invoice_Pre_Billing_Detailed', 'Invoice_Pre_Billing_Summarized', 'Invoice', 'Receipt', 'Occupancy_History', 'Deposit_Log'],
    transactionType: ['Payment', 'Charge'],
    comparisonOperation: ['lt', 'lte', 'gt', 'gte', 'eq', 'ne'],
    configResource: ['customerConfiguration', 'financialConfiguration', 'facilityConfiguration'],
    letterEditorSource: ['Letter', 'Email', 'Email_Compose'],
    roundingType: ['Nearest_Cent', 'Round_To_Nearest_Dollar', 'Round_Up_To_Nearest_Dollar',
        'Round_Down_To_Nearest_Dollar'],
    cardProcessorTransactionStatus: ['Approved', 'Duplicate', 'Declined', 'Failed', 'Error',
        'Unknown', 'Duplicate_Approved', 'Ignored'],
    chargeStyles: ['Flat', 'Percent', 'Higher_Of_Flat_Percent'],
    insuranceSource: ['Edit_Unit', 'Move_In'],
    tokenType: ['Payment', 'Standard'],
    documentType: ['Tenant_Photo', 'Tenant_Files'],
    tenantChangeTrackingStatus: ['Queued', 'Approved', 'Declined', 'Read'],
    tenantChangeType: ['email', 'address', 'phone', 'alternate_contact'],
    otherExternalActivityTrackingStatus: ['Queued', 'Read'],
    taxes: ['State_Tax'],
    osList: ['Mac', 'Windows', 'Ios', 'Android', 'Linux'],
    batchPaymentMode: ['Credit_Card', 'Ach', 'Others'],
    batchPaymentTasks: ['Auto_Payment', 'Batch_Payment'],
    summaryStatus: ['Error', 'Success'],
    chargeStyleConfig: ['Flat', 'Percentage', 'Higher_Of_Flat_Percent'],
    facilityActionType: ['play', 'edit', 'settings'],
    facilityLicenseStatus: ['Active', 'Expired'],
    configurationStoreType: ['Required_Fields', 'Tenant_Sections', 'Customer_General', 'Move_In', 'Rental_General', 'Move_Out', 'ReservationConfig', 'Lease_General', 'Transfer', 'Facility_General', 'Level', 'Bev_Colors', 'Financial_General_Read_Only', 'Financial_Payment', 'Inventory_Config', 'Unit_Type', 'Placeholder'],
    documentStatus: ['Active', 'Pending', 'Inactive', 'Generate_Error', 'Upload_Error', 'Delivery_Error', 'No_Data_Error'],
    delinquencyActionType: ['preview', 'edit', 'delete'],
    lockoutType: ['Unit_Overlock', 'Gate_Lockout', 'Tenant_Portal_Lockout'],
    calendarViewType: ['Month', 'Week', 'Day'],
    configurationType: ['Facility_Configuration', 'Financial_Configuration', 'Unit_Configuration',
        'Customer_Configuration', 'Rental_Configuration', 'Customer_Engagement_Configuration', 'Retail_Sale_Configuration'],
};

export default enums;
