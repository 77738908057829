const optionsFormatter = (options = {}, _, dataKey = 'Unit_Type') => {
    const {
        data: {
            [dataKey]: {
                data
                = [],
            } = {},
        } = {},
    } = options;
    return data.map(({ value: { description, ...restValues } = {}, id }) => ({
        data: { ...restValues },
        label: description,
        id,
    }));
};

const labelFormatter = ({ label } = {}) => label;

const getOptionProps = ({ label } = {}) => ({ label });

const getValues = options => Object.values(options);

export { optionsFormatter, labelFormatter, getOptionProps, getValues };
