import LabelWithIcon from 'Generic/labelwithicon/components/LabelWithIcon';
import Typography from 'Generic/typography/components/Typography';
import Icon from 'Generic/icon/components/Icon';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import actionItemsStyle from 'Commons/components/business/smartlistActionItemsPanel/styles/ActionItemStyle';
import { memo } from 'react';

const useStyles = makeStyles(actionItemsStyle, { name: 'ActionItem' });

const ActionItem = (props) => {
    const { config: { label, icon, onClick, disabled = false, iconProps, labelWithIconProps, labelProps } = {} } = props;
    const classes = useStyles();
    return (
        <LabelWithIcon
            addon={{
                start: <Icon
                    type="custom"
                    icon={icon}
                    color="primary"
                    disabled={disabled}
                    {...iconProps}
                />,
            }}
            onClick={onClick}
            className={disabled ? classes.disabledAction : ''}
            {...labelWithIconProps}
        >
            <Typography
                variant="body2"
                color="primary"
                className={`${disabled ? classes.disabledText : ''}`}
                {...labelProps}
            >
                {label}
            </Typography>
        </LabelWithIcon>
    );
};

ActionItem.propTypes = { config: PropTypes.object };

export default memo(ActionItem);
