import { memo, useMemo } from 'react';
import localisable from 'Commons/config/strings/localisable';
import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import { clsx } from 'Commons/helpers/utils/clsx';
import Actions from 'Commons/components/business/actions/components/Actions';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import { FormTextField } from 'Generic/textfield/components/TextField';
import ThreeDotProgress from 'Generic/threedotprogress/components/ThreeDotProgress';
import Typography from 'Generic/typography/components/Typography';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import { DEFAULT_LEVEL_LABEL } from 'Commons/components/business/bev/config/Constants';
import { IS_NOT_EMPTY_STRING } from 'Commons/config/constants/Validators';
import levelManagementStyles from '../styles/LevelManagementStyles';

const useStyles = makeStyles(levelManagementStyles, { name: 'levelManagement' });

const Level = (props) => {
    const classes = useStyles();
    const { deviceInfo: { isMobile } = {} } = window;
    const {
        index,
        isAddMode,
        isEditMode,
        onLevelEdit,
        onCancelAdd,
        arrayHelpers,
        onLevelDelete,
        isUnitCountDataLoading,
        levelLabelToLevelKeyMap,
        levelData: { levelKey, unitsOnLevel = 0 } = {},
        formProps: { values: { extra: { levelsList = [] } = {} } = {} } = {},
        formProps: { errors: { extra: { levelsList: erredLevelsList = [] } = {} } = {} } = {},
    } = props;

    const actionItems = useMemo(() => {
        const { label } = levelsList[index];
        const actionsConfig = [
            {
                icon: 'cp-edit',
                title: localisable.edit,
                disabled: isEditMode || (label !== DEFAULT_LEVEL_LABEL.UNTITLED && !!erredLevelsList.length),
                isVisible: !isAddMode,
                action: { onClick: () => onLevelEdit(levelKey) },
            },
            {
                icon: 'cp-delete',
                title: unitsOnLevel ? localisable.levelDeleteTooltip : localisable.delete,
                disabled: isUnitCountDataLoading,
                isVisible: !isAddMode,
                action: { onClick: unitsOnLevel ? EMPTY_FUNC : () => onLevelDelete(arrayHelpers, levelKey, index) },
                isDeleteAction: true,
            },
            {
                icon: 'cp-close',
                title: localisable.cancel,
                isVisible: isAddMode,
                action: { onClick: () => onCancelAdd(arrayHelpers, index) },
            },
        ].map(({ isVisible = true, icon, disabled, title, action, isDeleteAction = false }) => (isVisible ? ({
            component: (
                <Tooltip
                    title={title}
                >
                    <Button
                        variant="icon"
                        iconType="custom"
                        icon={icon}
                        color="primary"
                        className={clsx(isDeleteAction && unitsOnLevel && classes.deleteButton)}
                        disabled={disabled}
                    />
                </Tooltip>
            ),
            disabled,
            action,
        }) : {}));
        return (
            <Actions
                config={actionsConfig}
                justify="flex-end"
            />
        );
    }, [isEditMode, isAddMode, unitsOnLevel, levelKey, isUnitCountDataLoading, erredLevelsList.length]);

    return (
        <Grid container alignItems="center">
            <Grid container item lg={4} xs={4}>
                <FormTextField
                    key={`${isEditMode}-${isAddMode}`}
                    name={`extra.levelsList.${index}.label`}
                    validate={{
                        isLevelLabelDuplicate: {
                            value: {
                                levelLabelToLevelKeyMap,
                                levelKey,
                            },
                        },
                        ...IS_NOT_EMPTY_STRING,
                    }}
                    readOnly={!(isEditMode || isAddMode)}
                    noGrid
                    noIndicatorWrapper
                    autoFocus
                />
            </Grid>
            <Grid
                container
                item
                lg={3}
                xs={4}
                justify="flex-end"
                className={clsx(isMobile && classes.rightPadding)}
            >
                {isUnitCountDataLoading
                    ? <ThreeDotProgress />
                    : <Typography fontFamily="Open sans" className={classes.unitsOnLevel}>{unitsOnLevel}</Typography>}
            </Grid>
            <Grid
                container
                item
                lg={5}
                xs={4}
                className={clsx(!isMobile && classes.lastColumnValuePadding)}
            >
                {actionItems}
            </Grid>
        </Grid>
    );
};

Level.propTypes = {
    index: PropTypes.number,
    isAddMode: PropTypes.bool,
    isEditMode: PropTypes.bool,
    formProps: PropTypes.object,
    levelData: PropTypes.object,
    onLevelEdit: PropTypes.func,
    onCancelAdd: PropTypes.func,
    onLevelDelete: PropTypes.func,
    arrayHelpers: PropTypes.object,
    isUnitCountDataLoading: PropTypes.bool,
    levelLabelToLevelKeyMap: PropTypes.object,
};

export default memo(Level);
