import { TRANSITION_VARIANT } from 'Commons/config/constants/Constants';
import { Slide, Fade, Grow, Grid, makeStyles } from 'Generic/componentlibrary/components/Components';
import { memo, useRef } from 'react';
import { clsx } from 'Commons/helpers/utils/clsx';
import transitionStyle from '../styles/TransitionStyle';

const useStyles = makeStyles(transitionStyle);

const transitionComponents = {
    [TRANSITION_VARIANT.SLIDE]: Slide,
    [TRANSITION_VARIANT.FADE]: Fade,
    [TRANSITION_VARIANT.GROW]: Grow,
};


const Transition = ({ variant, needRef, fullHeight, children, ...props }) => {
    const { current: Component } = useRef(transitionComponents[variant]);
    const classes = useStyles();

    return (
        <Component {...props}>
            {
                needRef
                    ? (
                        <Grid container className={clsx({ [classes.fullHeight]: fullHeight })}>
                            {children}
                        </Grid>
                    )
                    : children
            }
        </Component>
    );
};

Transition.propTypes = {
    variant: PropTypes.oneOf(Object.values(TRANSITION_VARIANT)),
    needRef: PropTypes.bool, /* This prop is used to wrap children to a div, when there is no top level
    DOM element present for Transition to get the ref */
    children: PropTypes.node,
    fullHeight: PropTypes.bool,
};

Transition.defaultProps = {
    variant: TRANSITION_VARIANT.SLIDE,
    needRef: false,
    fullHeight: false,
};

export default memo(Transition);
