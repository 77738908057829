import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { memo, useCallback, useMemo } from 'react';
import MultiSelect from 'Commons/components/generic/multiselect/components/MultiSelect';
import getName from 'Commons/helpers/utils/NameHelper';
import { EMPTY_LIST } from 'Commons/config/constants/Constants';
import JournalLockoutEventFilterStyle from '../styles/JournalLockoutEventFilterStyle';
import RangeFilter from '../../rangeFilter/components/RangeFilter';
import { getTenantApiConfig } from '../config/ApiRequests';
import { EVENTS_TYPE_LIST, GATE_ACCESS_TYPE } from '../config/Constants';


const useStyles = makeStyles(
    JournalLockoutEventFilterStyle, { name: 'JournalLockoutEventFilter' },
);

const JournalLockoutEventFilter = ({
    formProps: {
        setFieldValue,
        values: { terms: { changeType: selectedEventList = EMPTY_LIST, metaInfo__tenantId: selectedTenants = EMPTY_LIST } = {} },
    } = {},
    formProps, entityId: selectedFacility,
}) => {
    const classes = useStyles();
    const tenantFilterKey = 'terms.metaInfo__tenantId';

    const tenantApiConfig = useMemo(() => getTenantApiConfig(selectedFacility), [selectedFacility]);

    const onSelectOrRemoveTenant = (selectedTenantsIds) => {
        setFieldValue(tenantFilterKey, Object.keys(selectedTenantsIds));
    };
    const tenantOptionFormatter = useCallback(({ data = [] } = {}) => data.map(({ name, ...tenant }) => (
        { label: getName(name), name, ...tenant }
    )), [selectedFacility]);

    const onChangeEvent = (_, selectedEvents = []) => {
        let events = [...selectedEvents];
        if (selectedEvents.includes(GATE_ACCESS_TYPE.Ledger_Gate_Access.value)) {
            if (!selectedEvents.includes(GATE_ACCESS_TYPE.Unit_Gate_Access.value)) {
                events = [...events, GATE_ACCESS_TYPE.Unit_Gate_Access.value];
            }
        } else {
            events = events.filter(val => (val !== GATE_ACCESS_TYPE.Ledger_Gate_Access.value || val !== GATE_ACCESS_TYPE.Unit_Gate_Access.value));
        }
        setFieldValue('terms.changeType', events);
    };
    return (
        <>
            <Typography
                variant="subtitle1"
                className={classes.restLabel}
            >
                {localisable.events}
            </Typography>
            <FormCheckboxGroup
                name="terms.changeType"
                trackValue={false}
                value={selectedEventList}
                list={EVENTS_TYPE_LIST}
                row
                noGrid
                noIndicatorWrapper
                onChange={onChangeEvent}
            />
            <RangeFilter
                isDateRange
                disableFuture
                name="createdDate"
                formProps={formProps}
                setFieldValue={setFieldValue}
                label={localisable.modifiedEffectiveDate}
            />
            <MultiSelect
                placeholder={localisable.selectTenant}
                onSelect={onSelectOrRemoveTenant}
                onRemove={onSelectOrRemoveTenant}
                keyToMatch="id"
                apiConfig={tenantApiConfig}
                maxHeight={150}
                initiallySelectedValues={selectedTenants}
                inputValueFormatter={({ name }) => getName(name)}
                getOptionProps={({ name }) => ({ label: getName(name) })}
                optionFormatter={tenantOptionFormatter}
                componentContainerLabel={localisable.tenants}
            />
        </>
    );
};

JournalLockoutEventFilter.propTypes = {
    formProps: PropTypes.object,
    entityId: PropTypes.string,
};

JournalLockoutEventFilter.defaultProps = { formProps: {} };

export default memo(JournalLockoutEventFilter);
