import { Navigate } from 'Commons/components/generic/componentlibrary/components/Components';
import useRouter from 'Commons/components/business/provider/useRouter';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import ErrorBoundaries from '../../errorboundaries/components/ErrorBoundaries';

const ProtectedRoute = ({ componentProps, loggedIn, path, redirectPathName = '/', componentName, ...props }) => {
    const Component = useConstructor(() => window.withProvider(componentName, componentProps, false));
    const { location, match, history } = useRouter(path);

    const { pathname = '' } = location;

    return loggedIn
        ? (
            <ErrorBoundaries useAnchorTag>
                <Component {...props} {...componentProps} location={location} match={match} history={history} />
            </ErrorBoundaries>
        )
        : (
            <Navigate
                to={redirectPathName}
                state={{
                    prevLocation: pathname,
                    error: 'User Session Expired',
                }}
            />
        );
};

ProtectedRoute.propTypes = {
    componentName: PropTypes.string,
    componentProps: PropTypes.object,
    loggedIn: PropTypes.any,
    path: PropTypes.string,
    redirectPathName: PropTypes.string,
};

export default ProtectedRoute;
