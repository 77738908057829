import { convertToNumber } from 'Commons/helpers/utils/Utils';
import { ENCRYPTED_ID_LENGTH } from '../../config/constants/Constants';

/**
 * String formatter - trim,
 * Number formatter - to int, round off to n decimals
 */

const emptyCallback = () => {};

const beginsWithUnderscores = (key) => {
    if (key.match(/^__/g)) {
        return true;
    }
    return false;
};

const isFirstLetterUppercase = key => /^[A-Z]/.test(key);

/**
 * snake case to camel case
 * @param {string} strToConvert - converts string to camel case
 */
const camelize = (strToConvert) => {
    // QuickFix: Skipping for encrypted Ids
    if (strToConvert.length === ENCRYPTED_ID_LENGTH || isFirstLetterUppercase(strToConvert)) {
        return strToConvert;
    }

    return (!beginsWithUnderscores(strToConvert)
        ? strToConvert.replace(/_\w/g, match => match[1].toUpperCase())
        : strToConvert);
};

/**
 * camel case to snake case
 * @param {string} strToConvert - converts string to snake case
 */
const decamelize = (strToConvert) => {
    // QuickFix: Skipping for encrypted Ids and Enums
    if (strToConvert.length === ENCRYPTED_ID_LENGTH || isFirstLetterUppercase(strToConvert)) {
        return strToConvert;
    }

    return strToConvert.replace(/([A-Z])/g, match => `_${match.toLowerCase()}`);
};

/**
 * @param {object} value - checks if the object is of primitive data type
 */
const isPrimitiveType = value => value !== Object(value);

const isValidValue = value => value !== null;

/**
 * @name getPrimitiveValue
 * @description We allow null values to go through, since to delete object we need to set its value as null
 * @param {*} key Object Key
 * @param {*} value Object Value
 * @param {*} execFunc The Function to execute
 * @param {*} options The additional options
 */
const getPrimitiveValue = (key, value, execFunc, options) => {
    const { allowNull } = options;
    return value === null && allowNull ? { [execFunc(key)]: null } : {};
};

/**
 * Traverses any object type and execute function to all keys
 * request - object to traverse
 * execFunc - function to execute per key
 */
const deepTraverse = (request, execFunc = emptyCallback, options = { allowNull: false }) => {
    if (!request) {
        return request;
    }

    if (Array.isArray(request)) {
        return request.map(item => deepTraverse(item, execFunc, options));
    }

    if (!isPrimitiveType(request)) {
        return Object.keys(request).reduce((accumulator, key) => ({
            ...accumulator,
            ...(isValidValue(request[key]) ? {
                [execFunc(key)]: isPrimitiveType(request[key])
                    ? request[key] : deepTraverse(request[key], execFunc, options),
            } : getPrimitiveValue(key, request[key], execFunc, options)),
        }), {});
    }

    return request;
};

/**
 * converts request to camel case recursively
 * @param {object} request - converts request to camel case recursively
 */
const deepCamelize = request => deepTraverse(request, camelize);

/**
 * converts request to snake case recursively
 * @param {object} request - converts request to snake case recursively
 */
const deepDecamelize = request => deepTraverse(request, decamelize, { allowNull: true });


const processValue = (value, dataType) => {
    const booleansMap = {
        true: true,
        false: false,
    };
    switch (dataType) {
        case 'boolean':
            return booleansMap[value];
        case 'number': return convertToNumber(value);
        default: return value;
    }
};

export {
    camelize,
    decamelize,
    deepCamelize,
    deepDecamelize,
    deepTraverse,
    isPrimitiveType,
    processValue,
};
