import { VIEW } from 'Commons/config/constants/Constants';
import { CONFIG_ENDPOINTS } from 'Commons/config/constants/Endpoints';

const getUnitTypeApiConfig = (entityId) => {
    const apiConfig = {
        api: {
            dynamic: {
                endPoint: CONFIG_ENDPOINTS.search,
                body: {
                    view: VIEW.detail.value,
                    filter: [
                        {
                            terms: {
                                configType: ['Unit_Type'],
                                entityType: ['Facility'],
                                entityId: [entityId],
                            },
                        },
                    ],
                },
            },
        },
    };
    return {
        search: apiConfig,
        autoSuggest: apiConfig,
        sendCustomHeader: false,
    };
};

export { getUnitTypeApiConfig };
