import { memo } from 'react';
import { FieldArray } from 'formik';
import { Grid } from 'Generic/componentlibrary/components/Components';
import { TextField } from 'Generic/textfield/components/TextField';
import { FormDropdown } from 'Generic/dropdown/components/Dropdown';
import { Checkbox } from 'Generic/checkbox/components/CheckBox';
import { ACTION_TYPE, PRIMARY_PHONE } from 'Commons/config/constants/Constants';
import { SM_4 } from 'Commons/config/constants/GridSpacingConstants';
import { IS_MAX_LENGTH_17, IS_MAX_LENGTH_50 } from 'Commons/config/constants/Validators';
import FormItem from 'Generic/formitem/components/FormItem';
import localisable from 'Commons/config/strings/localisable';
import { deepCopy } from 'Commons/helpers/utils/DeepCopy';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import useDidUpdateEffect from 'Commons/helpers/hooks/useDidUpdateEffect';
import { VARIANT } from 'Generic/form/config/FormComponentsConfig';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import { FormPhoneNumberField } from 'Commons/components/business/phoneNumber/components/PhoneNumberField';
import {
    getCleanPhoneNumber,
    getPlainPhoneNumber,
    isAnyExistingFieldTouched,
    isPhoneNumberValid,
} from 'External/containers/tenant/form/utils/Utils';
import Icon from 'Commons/components/generic/icon/components/Icon';
import Typography from 'Commons/components/generic/typography/components/Typography';
import LabelWithAddons from 'Commons/components/generic/labelwithaddons/components/LabelWithAddons';
import Button from 'Generic/button/components/Button';
import { PREFERRED_COUNTRIES_LIST } from 'Commons/components/business/phoneNumber/config/Constants';
import { DEFAULT_PHONE, TEXT_SUPPORTED_COUNTRIES } from '../config/Constants';
import Label from '../../Label/components/Label';

const PhoneItem = memo(
    ({
        index,
        classes,
        touched,
        onRemove,
        arrayHelpers,
        isFormActive,
        setFieldValue,
        setFieldTouched,
        isAnyPhoneRequired,
        onPhoneOrEmailValid,
        phoneExtrasIndex,
        isAnyPhonePreferred,
        currentFacility,
        phone: {
            primaryNumber,
            textEnabledCellNumber,
            phoneNumberList,
            phoneNumberList: {
                [index]: {
                    type,
                    phoneId,
                    phone = {},
                    phone: { country, comments } = {},
                } = {},
            } = [],
        } = {},
        requiredFields: {
            'phone.primaryNumber': isPrimaryNumberRequired,
            'phone.textEnabledCellNumber': isTextEnabledRequired,
            [`phone.phoneNumberList.${type}`]: isPhoneTypeRequired,
        } = {},
        touched: { phone: { phoneNumberList: { [index]: touchedPhone = {} } = [] } = {} },
    }) => {
        const gridProps = useConstructor(() => ({
            ...SM_4,
            className: classes.showAlways,
        }));
        const { number: isNumberTouched = false } = touchedPhone || {};
        let totalRequiredFieldTypeOnScreen = 0;
        let totalPhoneOnScreen = 0;
        const samePhoneNumbersCount = {};
        phoneNumberList.forEach(
            ({ phone: phoneObj, type: phoneType, __action_type: actionType } = {}) => {
                const cleanedPhoneNumber = getCleanPhoneNumber(getPlainPhoneNumber(phoneObj));
                if (cleanedPhoneNumber && actionType !== ACTION_TYPE.DELETE) {
                    samePhoneNumbersCount[cleanedPhoneNumber] = (samePhoneNumbersCount[cleanedPhoneNumber] || 0) + 1;
                }
                if (phoneType === type) {
                    totalRequiredFieldTypeOnScreen += 1;
                }
                if (actionType !== ACTION_TYPE.DELETE) {
                    totalPhoneOnScreen += 1;
                }
            },
        );

        const { data: { isPhoneEnabled: messagingEnabled = false } = {} } = currentFacility || {};

        const onChangeTextEnabled = (_, value) => {
            setFieldValue('phone.textEnabledCellNumber', value ? getPlainPhoneNumber(phone) : null);
            setFieldTouched('phone.textEnabledCellNumber', true, false);
            // eslint-disable-next-line no-param-reassign
            phoneExtrasIndex.textEnabledNumber = index;
        };

        const onChangePrimaryNumber = () => {
            setFieldValue(
                'phone.primaryNumber',
                phone ? getPlainPhoneNumber(phone) : null,
            );
            setFieldTouched('phone.primaryNumber', true, false);
            // eslint-disable-next-line no-param-reassign
            phoneExtrasIndex.primaryNumber = index;
        };

        // Only for new phones
        useDidUpdateEffect(() => {
            if (!phoneId) {
                setFieldTouched(`phone.phoneNumberList.${index}.type`, true, false);
            }
        }, [isNumberTouched, phoneId]);

        const onNumberChange = (_, enteredNumber = '') => {
            const cleanedPhone = getCleanPhoneNumber(enteredNumber);
            if (phoneExtrasIndex.primaryNumber === -1) {
                // eslint-disable-next-line no-param-reassign
                phoneExtrasIndex.primaryNumber = index;
            }

            if (type === PRIMARY_PHONE.Cell_Phone.value && phoneExtrasIndex.textEnabledNumber === -1) {
                // eslint-disable-next-line no-param-reassign
                phoneExtrasIndex.textEnabledNumber = index;
            }

            if (phoneExtrasIndex.primaryNumber === index) {
                setFieldValue('phone.primaryNumber', cleanedPhone);
                setFieldTouched('phone.primaryNumber', true, false);
            }

            if (phoneExtrasIndex.textEnabledNumber === index) {
                setFieldValue('phone.textEnabledCellNumber', TEXT_SUPPORTED_COUNTRIES.includes(country) ? cleanedPhone : null);
                setFieldTouched('phone.textEnabledCellNumber', true, false);
            }
        };

        const onCommentChange = (_, enteredComment = '') => {
            setFieldValue(`phone.phoneNumberList.${index}.phone.comments`, enteredComment);
            setFieldTouched(`phone.phoneNumberList.${index}.phone.comments`, true, false);
        };

        const renderCloseButton = () => (isPhoneTypeRequired
            ? false
            : !isAnyPhoneRequired || totalPhoneOnScreen > 1)
            && isFormActive && (
        /* TODO: <div className={classes.closeButton}> */
            <Tooltip title={localisable.remove}>
                <Button
                    variant="icon"
                    iconType="custom"
                    icon="cp-close"
                    onClick={() => onRemove(index, arrayHelpers)}
                />
            </Tooltip>
        /* </div> */
        );

        const renderPhoneNumber = () => (
            <>
                <FormPhoneNumberField
                    useField
                    fullWidth
                    GridProps={gridProps}
                    disabled={type === DEFAULT_PHONE.type}
                    formVariant={VARIANT.INLINE}
                    className={classes.phone}
                    onChange={onNumberChange}
                    validate={{
                        ...IS_MAX_LENGTH_17,
                        isPhoneNumberDuplicate: { value: samePhoneNumbersCount },
                        isValidPhone: { value: { required: true } },
                    }}
                    required={isAnyPhoneRequired || isPhoneTypeRequired}
                    preferred={isAnyPhonePreferred}
                    name={`phone.phoneNumberList.${index}.phone`}
                    requiredPath={`phone.phoneNumberList.${type}`}
                    componentClasses={{ leftMarginIcon: classes.leftMarginIcon }}
                    preferredCountries={PREFERRED_COUNTRIES_LIST}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    value={phone}
                    defaultCountry={country}
                    onValidCallback={onPhoneOrEmailValid}
                    alwaysShowError={!!phoneId}
                    clearValuesOnEmpty
                />
                <FormItem sm={3} xs={10} padding={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <TextField
                        placeholder={localisable.comments}
                        fullWidth
                        validate={IS_MAX_LENGTH_50}
                        trackValue={false}
                        value={comments}
                        onChange={onCommentChange}
                    />
                </FormItem>
                {renderCloseButton()}
            </>

        );

        const onChangePhoneType = (_, value) => {
            if (
                value !== PRIMARY_PHONE.Cell_Phone.value
                && phoneExtrasIndex.textEnabledNumber === index
                && TEXT_SUPPORTED_COUNTRIES.includes(country)
            ) {
                setFieldValue('phone.textEnabledCellNumber', null);
                setFieldTouched('phone.textEnabledCellNumber', true, false);
            }
        };

        return (
            <Grid container className={classes.phoneContainer} alignItems="center">
                <FormDropdown
                    useField
                    fullWidth
                    formVariant={VARIANT.INLINE}
                    GridProps={gridProps}
                    readOnly={isPhoneTypeRequired && totalRequiredFieldTypeOnScreen <= 1}
                    preferred={isAnyPhonePreferred}
                    list={Object.values(PRIMARY_PHONE)}
                    placeholder={localisable.selectPhone}
                    name={`phone.phoneNumberList.${index}.type`}
                    requiredPath={`phone.phoneNumberList.${type}`}
                    onChange={onChangePhoneType}
                />
                {renderPhoneNumber()}
                <FormItem
                    required={isPrimaryNumberRequired}
                    separatorClassName={classes.indicatorPadding}
                    className={classes.autoWidth}
                >
                    <Checkbox
                        disabled={!isPhoneNumberValid(phone) || !isFormActive}
                        className={classes.checkbox}
                        label={localisable.enablePrimaryNumber}
                        classes={{ formLabel: classes.formLabel }}
                        onChange={onChangePrimaryNumber}
                        checked={
                            isPhoneNumberValid(phone)
                            && getPlainPhoneNumber(phone) === primaryNumber
                        }
                    />
                </FormItem>
                {type === PRIMARY_PHONE.Cell_Phone.value && (
                    <FormItem
                        className={classes.autoWidth}
                        required={isTextEnabledRequired}
                        separatorClassName={classes.indicatorPadding}
                    >
                        {(!messagingEnabled || !TEXT_SUPPORTED_COUNTRIES.includes(country)) ? (
                            <Grid container>
                                <LabelWithAddons
                                    start={(
                                        <Icon type="custom" icon="cp-info" color="primary" className={classes.infoIcon} />
                                    )}
                                    className={classes.leftAlign}
                                >
                                    <Typography>
                                        {!messagingEnabled ? localisable.messagingService : localisable.notSupportedText}
                                    </Typography>
                                </LabelWithAddons>
                            </Grid>
                        ) : (
                            <Checkbox
                                disabled={!isPhoneNumberValid(phone) || !isFormActive}
                                className={classes.checkbox}
                                label={localisable.enableText}
                                classes={{ formLabel: classes.formLabel }}
                                checked={
                                    isPhoneNumberValid(phone)
                                    && getPlainPhoneNumber(phone) === textEnabledCellNumber
                                    && type === PRIMARY_PHONE.Cell_Phone.value
                                }
                                onChange={onChangeTextEnabled}
                            />
                        )}
                    </FormItem>
                )}
            </Grid>
        );
    },
);

const Phone = ({
    phone,
    classes,
    values,
    touched,
    resetForm,
    isFormActive,
    setFieldValue,
    currentFacility,
    setFieldTouched,
    onPhoneOrEmailValid,
    initialValues: { phone: phoneInitialValues = {} } = {},
    phone: { primaryNumber, textEnabledCellNumber, phoneNumberList = [] } = {},
    status: {
        requiredFields,
        preferredFields,
        requiredFieldsObj: { phone: { phoneNumberList: { Any_Phone: isAnyPhoneRequired } = {} } = {} } = {},
        preferredFieldsObj: { phone: { phoneNumberList: { Any_Phone: isAnyPhonePreferred } = {} } = {} } = {},
    } = {},
}) => {
    const phoneExtrasIndex = useConstructor(() => ({
        primaryNumber: phoneNumberList.findIndex(
            ({ phone: eachPhone }) => getPlainPhoneNumber(eachPhone) === primaryNumber,
        ),
        textEnabledNumber: phoneNumberList.findIndex(
            ({ phone: eachPhone }) => getPlainPhoneNumber(eachPhone) === textEnabledCellNumber,
        ),
    }));

    const { data: { isPhoneEnabled: isMessagingEnabled = false } = {} } = currentFacility || {};

    const onRemove = (index, arrayHelpers) => {
        const { [index]: { phoneId, phone: structuredPhoneObj } = {} } = phoneNumberList;
        const fieldsToRemove = ['textEnabledCellNumber', 'primaryNumber'].filter(
            field => phone[field] === getPlainPhoneNumber(structuredPhoneObj),
        );

        const tenantPhone = deepCopy(phone);
        const { phoneNumberList: phones } = tenantPhone;
        const { [index]: phoneToRemove } = phones;

        if (phoneId) {
            phoneToRemove.__action_type = ACTION_TYPE.DELETE; // eslint-disable-line no-underscore-dangle
            setFieldTouched(`phone.phoneNumberList.${index}.phoneId`, true, false);
        } else {
            phones.splice(index, 1);
        }

        if (fieldsToRemove.length) {
            const newPrimaryNumberIndex = phones.findIndex(
                ({ __action_type: actionType, phone: phoneObj }) => !actionType && isPhoneNumberValid(phoneObj),
            );

            phoneExtrasIndex.primaryNumber = newPrimaryNumberIndex;
            if (phoneToRemove.type === PRIMARY_PHONE.Cell_Phone.value) phoneExtrasIndex.textEnabledNumber = -1;

            const { [newPrimaryNumberIndex]: { phone: newPrimaryNumPhoneObj } = {} } = phones;
            const firstPhoneNumber = newPrimaryNumberIndex > -1 && isPhoneNumberValid(newPrimaryNumPhoneObj)
                ? getPlainPhoneNumber(newPrimaryNumPhoneObj)
                : null;

            fieldsToRemove.forEach((field) => {
                if (field === 'primaryNumber') {
                    tenantPhone[field] = firstPhoneNumber;
                } else {
                    tenantPhone[field] = null; // Removing textEnabledPhoneNumber
                }
                setFieldValue(`phone.${field}`, tenantPhone[field]);
                setFieldTouched(`phone.${field}`, true, false);
            });
        }

        if (phoneId) {
            setFieldValue('phone', tenantPhone);
        } else if (isAnyExistingFieldTouched(phoneInitialValues, touched)) {
            // No phoneId and no other dependent fields to remove
            arrayHelpers.remove(index);
        } else {
            // Resetting form as new phones were removed (Technically there is no change in form)
            resetForm({ ...values, phone: tenantPhone });
        }
    };

    const onAdd = (arrayHelpers) => {
        if (isAnyExistingFieldTouched(phoneInitialValues, touched)) {
            arrayHelpers.push(DEFAULT_PHONE);
        } else {
            // Resetting as new phones were just added without number (Technically there is no change in form)
            const tenantPhone = deepCopy(phone);
            const { phoneNumberList: phonesList = [] } = tenantPhone;
            const newPhonesList = [...(phonesList || [])];
            newPhonesList.push(DEFAULT_PHONE);
            resetForm({ ...values, phone: { ...tenantPhone, phoneNumberList: newPhonesList } });
        }
    };

    return (
        <FieldArray
            name="phone.phoneNumberList"
            render={(arrayHelpers) => {
                const phoneNumbers = phoneNumberList.map(
                    ({ __action_type: actionType, phoneId }, index) => {
                        const key = phoneId || `${phoneNumberList.length}-${index}`;

                        return actionType !== ACTION_TYPE.DELETE ? (
                            <PhoneItem
                                key={key}
                                index={index}
                                phone={phone}
                                classes={classes}
                                touched={touched}
                                onRemove={onRemove}
                                arrayHelpers={arrayHelpers}
                                isFormActive={isFormActive}
                                setFieldValue={setFieldValue}
                                requiredFields={requiredFields}
                                setFieldTouched={setFieldTouched}
                                preferredFields={preferredFields}
                                phoneExtrasIndex={phoneExtrasIndex}
                                isAnyPhoneRequired={isAnyPhoneRequired}
                                onPhoneOrEmailValid={onPhoneOrEmailValid}
                                isAnyPhonePreferred={isAnyPhonePreferred}
                                currentFacility={currentFacility}
                            />
                        ) : null;
                    },
                );

                return (
                    <>
                        {!isMessagingEnabled ? (
                            <Label className={classes.label} disabled={!isFormActive}>
                                {localisable.phone}
                            </Label>
                        ) : (
                            <Grid container alignItems="center">
                                <LabelWithAddons
                                    end={(
                                        <Tooltip
                                            title={localisable.tooltipInfoMessage}
                                            placement="bottom"
                                            event="onClick"
                                            disablePortal
                                            classes={{ root: classes.tooltipRoot }}
                                        >
                                            <Icon
                                                size="small"
                                                type="custom"
                                                icon="cp-info"
                                                color="primary"
                                                className={classes.infoIcon}
                                            />
                                        </Tooltip>
                                    )}
                                >
                                    <Label
                                        FormItemProps={{ padding: '0 0 16px 16px' }}
                                        className={classes.label}
                                        disabled={!isFormActive}
                                    >
                                        {localisable.phone}
                                    </Label>
                                </LabelWithAddons>
                            </Grid>
                        )}
                        {phoneNumbers}
                        <FormItem sm={12}>
                            <Button
                                fullWidth
                                variant="text"
                                color="primary"
                                disabled={!isFormActive}
                                onClick={() => onAdd(arrayHelpers)}
                            >
                                {localisable.tenantFormAddPhone}
                            </Button>
                        </FormItem>
                    </>
                );
            }}
        />
    );
};

Phone.propTypes = {
    phone: PropTypes.object,
    status: PropTypes.object,
    values: PropTypes.object,
    classes: PropTypes.object,
    resetForm: PropTypes.func,
    touched: PropTypes.object,
    isFormActive: PropTypes.bool,
    setFieldValue: PropTypes.func,
    initialValues: PropTypes.object,
    setFieldTouched: PropTypes.func,
    currentFacility: PropTypes.object,
    onPhoneOrEmailValid: PropTypes.func,
};

Phone.defaultProps = {
    status: {},
    setFieldValue: () => { },
    onPhoneOrEmailValid: () => { },
};

export default memo(Phone);
