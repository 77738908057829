import React, { memo } from 'react';
import localisable from 'Commons/config/strings/localisable';
import { Grid, makeStyles, Typography } from 'Generic/componentlibrary/components/Components';
import Icon from 'Generic/icon/components/Icon';
import ListRenderer from 'Generic/listrenderer/components/ListRenderer';
import Chip from 'Generic/chip/components/Chip';
import { clsx } from 'Commons/helpers/utils/clsx';
import previewStyles from '../styles/PreviewStyle';

const useStyles = makeStyles(previewStyles);

const Preview = ({ deleteAll, previewData, onDeleteRole }) => {
    const classes = useStyles();
    const getTitle = () => (
        <Grid container item xs={12} justify="space-between" className={classes.title}>
            <Grid item>
                <Typography variant="h6">
                    {localisable.rolePreview}
                </Typography>
            </Grid>
            <Grid item>
                <Icon
                    icon="cp-search"
                    type="custom"
                    disabled
                    className={classes.iconPadding}
                />
                <Icon
                    icon="cp-delete"
                    type="custom"
                    disabled={!previewData.length}
                    onClick={deleteAll}
                    className={classes.iconPadding}
                />
            </Grid>
        </Grid>
    );

    const getPreviewData = () => {
        if (previewData.length) {
            return previewData.map((rolePreview, index) => {
                const { name, permissions, id } = rolePreview;
                if (permissions.length) {
                    const updatedPreviewData = permissions.map(({
                        name: permissionName, type,
                        id: permissionId, ...permission
                    }) => {
                        const entityNumber = permission[`${type}Number`];
                        return ({
                            ...permission,
                            label: `${permissionName} ${clsx(entityNumber && `- ${entityNumber}`)}`,
                            value: permissionId,
                            name: permissionName,
                            id: permissionId,
                            type,
                        });
                    });
                    return (
                        <Grid container spacing={2} key={id} className={classes.roleBlockPadding}>
                            <Grid item xs={12}>
                                {name.toUpperCase()}
                            </Grid>
                            <Grid item xs={12} container spacing={2}>
                                <ListRenderer
                                    component={Chip}
                                    data={updatedPreviewData}
                                    parentIndex={index}
                                    onDelete={onDeleteRole}
                                />
                            </Grid>
                        </Grid>
                    );
                }
                return null;
            });
        }
        return null;
    };

    return (
        <Grid item xs={12} container className={classes.block}>
            <Grid item xs={12}>
                {getTitle()}
            </Grid>
            <Grid item xs={12}>
                {getPreviewData()}
            </Grid>
        </Grid>
    );
};

Preview.propTypes = {
    onDeleteRole: PropTypes.func,
    previewData: PropTypes.array,
    deleteAll: PropTypes.func,
};

Preview.defaultProps = {
    deleteAll: () => {},
    previewData: [],
    onDeleteRole: () => {},
};

export default memo(Preview);
