import Snackbar from 'Commons/components/generic/snackbar/components/Snackbar';
import BaseComponent from '../../basecomponent/components/BaseComponent';
import eventEmitter from '../../../../helpers/EventEmitters';

export default class Logout extends BaseComponent {
    componentDidMount = () => {
        this.logout();
    }

    onSnackbarMount = ({ closeSnackbar }) => closeSnackbar()

    logout = () => {
        const { onAction } = this.props;
        onAction({
            config: [
                {
                    method: 'read',
                    api: {
                        action: {
                            methodType: 'GET',
                            endPoint: 'auth/sign-out',
                        },
                    },
                    callback: this.onLogout,
                },
            ],
        });
    }

    onLogout = (err, resp) => {
        if (err) {
            console.error('Logout Error:', err);
        } else {
            const { success = false } = resp;
            if (success) {
                const { clear } = this.props;
                clear();
                eventEmitter.emit('logout', {});
            }
        }
    }

    // Not using renderComponent method, as it will introduce unnecessary checks
    render() {
        return (
            <Snackbar
                variant=""
                title=""
                onSnackbarMount={this.onSnackbarMount}
            />
        );
    }
}
