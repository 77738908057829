import { Notifier } from '@airbrake/browser';
import * as Sentry from '@sentry/react';
import { AIRBRAKE_CONFIG } from 'Commons/config/constants/ErrorTracking';
import { isProduction, isStaging } from 'Commons/helpers/utils/Utils';
import { withStyles } from 'Generic/componentlibrary/components/Components';
import GeneralPages from 'Generic/generalPages/components/GeneralPages';
import { VARIANT } from 'Generic/generalPages/config/Config';
import { Context, getRollbarFromContext } from '@rollbar/react';
import ErrorBoundariesStyle from '../styles/ErrorBoundariesStyle';


/* eslint-disable react/no-unused-state */
class ErrorBoundaries extends React.Component {
    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            info: null,
        };
        if (isStaging()) {
            this.airbrake = new Notifier(AIRBRAKE_CONFIG);
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error, info });

        if (isStaging()) {
            // Send error to Airbrake
            this.airbrake.notify({
                error,
                params: { info },
            });

            // Send error to Sentry
            Sentry.withScope((scope) => {
                scope.setExtras(info);
                const eventId = Sentry.captureException(error);
                this.setState({ eventId });
            });
        }

        if (isProduction()) {
            const rollbar = getRollbarFromContext(this.context);
            rollbar.error(error);
        }
    }

    render() {
        const { children, useAnchorTag, classes } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return (
                <div className={classes.container}>
                    <GeneralPages useAnchorTag={useAnchorTag} variant={VARIANT.SERVER_ERROR} />
                </div>
            );
        }
        return children;
    }
}

ErrorBoundaries.propTypes = {
    children: PropTypes.node.isRequired,
    useAnchorTag: PropTypes.bool,
    classes: PropTypes.object,
};
ErrorBoundaries.defaultProps = { useAnchorTag: false };

export default withStyles(ErrorBoundariesStyle)(ErrorBoundaries);
