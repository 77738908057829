import round from 'Commons/helpers/utils/Round';
import { SOURCE } from 'Commons/config/constants/Constants';
import { calculateNextAmountPayable } from './BalanceCalculator';

const shouldModifyNegativeAmount = (isRefund, unitList, setFieldValue, id, source, ledgerBalance) => {
    if (!isRefund && Number(ledgerBalance) <= 0) {
        const nextAmountPayable = source === SOURCE.retailSale.value ? 0 : calculateNextAmountPayable(unitList, id);
        if (nextAmountPayable !== 0 && Number(ledgerBalance) === 0) {
            setFieldValue('extra.amountWithoutModifier', round(Number(nextAmountPayable), 2, true));
        } else if (Number(ledgerBalance) <= 0) {
            setFieldValue('amount', round(0, 2, true));
        }
        return true;
    }
    return false;
};

export default shouldModifyNegativeAmount;
