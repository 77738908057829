import { memo, useEffect } from 'react';
import { makeStyles, Grid, Typography } from 'Generic/componentlibrary/components/Components';
import Button from 'Generic/button/components/Button';
import localisable from 'Commons/config/strings/localisable';
import { clsx } from 'Commons/helpers/utils/clsx';
import KeyboardShortcuts from 'Commons/components/business/bev/components/common/components/infoCard/KeyboardShortcuts';
import ColorLegend from 'Commons/components/business/bev/components/common/components/infoCard/ColorLegend';
import infoCardStyles from '../../styles/InfoCardStyles';

const useStyles = makeStyles(infoCardStyles, { name: 'InfoCard' });

const InfoCard = ({
    isSetupMode, colorLegendData,
    showInfoCard, setShowInfoCard,
    canvasGridRef,
}) => {
    useEffect(() => {
        if (showInfoCard) setShowInfoCard(false);
    }, [isSetupMode]);

    const { clientHeight: canvasGridHeight } = canvasGridRef || {};


    const classes = useStyles({ showInfoCard, isSetupMode, canvasGridHeight });

    return (
        <>
            <Grid
                container
                className={
                    clsx(classes.infoCardContainer, showInfoCard && classes.infoCardShow)}
            >
                <Typography variant="h6" className={classes.cardTitle}>
                    {isSetupMode ? localisable.bevKeyboardShortcutsCardTitle : localisable.key}
                </Typography>
                <Button
                    variant="icon"
                    icon="cp-close"
                    iconType="custom"
                    IconProps={{ size: 'medium' }}
                    className={classes.infoCardCloseButton}
                    onClick={() => setShowInfoCard(false)}
                />
                <Grid container className={classes.infoContainer}>
                    {
                        isSetupMode ? (
                            <KeyboardShortcuts />
                        ) : <ColorLegend colorLegendData={colorLegendData} />
                    }
                </Grid>
            </Grid>
        </>
    );
};

InfoCard.propTypes = {
    isSetupMode: PropTypes.bool,
    colorLegendData: PropTypes.object,
    showInfoCard: PropTypes.bool,
    setShowInfoCard: PropTypes.func,
    canvasGridRef: PropTypes.object,
};

export default memo(InfoCard);
