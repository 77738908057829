import PropTypes from 'prop-types';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import Button from 'Generic/button/components/Button';
import { Grid } from 'Generic/componentlibrary/components/Components';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import localisable from 'Commons/config/strings/localisable';
import { capitalize } from 'Commons/helpers/utils/DataHelpers';
import { DIRECTIONS } from '../../../config/Constants';

const DIRECTION_ICON = {
    [DIRECTIONS.LEFT]: 'cp-chevron-left',
    [DIRECTIONS.RIGHT]: 'cp-chevron-right',
    [DIRECTIONS.DOWN]: 'cp-expand',
    [DIRECTIONS.UP]: 'cp-collapse',
};

const ActionItem = ({ classes, direction, fabricInstance, scrollCanvas }) => (
    <Tooltip
        title={`${localisable.scroll} ${capitalize(direction)}`}
    >
        <Button
            variant="icon"
            iconType="custom"
            IconProps={{ size: 'large' }}
            icon={DIRECTION_ICON[direction]}
            className={classes.directionBtn}
            onClick={() => scrollCanvas(direction, fabricInstance)}
        />
    </Tooltip>
);

ActionItem.propTypes = {
    classes: PropTypes.object,
    direction: PropTypes.string,
    fabricInstance: PropTypes.object,
    scrollCanvas: PropTypes.func,
};

const ScrollActionItems = ({ canvasRef, classes, scrollCanvas }) => {
    const fabricInstance = canvasRef ? canvasRef.getFabricCanvasInstance() : EMPTY_FUNC;
    return (
        <Grid
            item
            sm={3}
            container
            wrap="nowrap"
            alignItems="start"
            justify="space-evenly"
            className={classes.scrollActionItems}
        >
            {
                [
                    DIRECTIONS.LEFT, DIRECTIONS.RIGHT,
                    DIRECTIONS.UP, DIRECTIONS.DOWN,
                ].map(direction => (
                    <ActionItem
                        key={direction}
                        classes={classes}
                        direction={direction}
                        scrollCanvas={scrollCanvas}
                        fabricInstance={fabricInstance}
                    />
                ))
            }

        </Grid>
    );
};

ScrollActionItems.propTypes = {
    canvasRef: PropTypes.object,
    classes: PropTypes.object,
    scrollCanvas: PropTypes.func,
};

export default ScrollActionItems;
