import { VIEW } from 'Commons/config/constants/Constants';
import { CONFIG_ENDPOINTS } from 'Commons/config/constants/Endpoints';
import CONFIG_TYPE from 'External/containers/configuration/config/ConfigRequestType';
import { ENTITY_TYPE } from 'External/containers/configuration/config/Constants';

const getUnitTypeApiConfig = (entityId) => {
    const apiConfig = {
        api: {
            dynamic: {
                endPoint: CONFIG_ENDPOINTS.search,
                body: {
                    view: VIEW.detail.value,
                    filter: [
                        {
                            terms: {
                                configType: [CONFIG_TYPE.UNIT_TYPE],
                                entityType: [ENTITY_TYPE.FACILITY],
                                entityId: [entityId],
                            },
                        },
                    ],
                },
            },
        },
    };
    return {
        search: apiConfig,
        autoSuggest: apiConfig,
        sendCustomHeader: false,
    };
};

export { getUnitTypeApiConfig };
