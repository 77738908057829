import { Form } from 'formik';
import { FormTextField } from 'Commons/components/generic/textfield/components/TextField';
import { SM_12_MD_6_LG_6 } from 'Commons/config/constants/GridSpacingConstants';
import { Grid, withStyles } from '../../../../../generic/componentlibrary/components/Components';
import NumberRangeStyle from '../styles/NumberRangeStyle';
import FilterComponent from '../../FilterComponent';

class NumberRange extends FilterComponent {
    render() {
        const {
            section: { filters: [filter = ''] },
            // componentLabel,
            classes,
            appliedFilters,
        } = this.props;
        if (appliedFilters[filter]) {
            this.initialValue = appliedFilters[filter].value;
        }

        return (
            <div className={classes.container}>
                {this.renderComponentTitle()}
                <Form>
                    <Grid container>
                        <FormTextField
                            name={`${filter}.from`}
                            label="From"
                            fullWidth
                            GridProps={SM_12_MD_6_LG_6}
                        />
                        <FormTextField
                            name={`${filter}.to`}
                            label="To"
                            fullWidth
                            GridProps={SM_12_MD_6_LG_6}
                        />
                    </Grid>
                </Form>
            </div>
        );
    }
}

NumberRange.propTypes = {
    section: PropTypes.any.isRequired, // The current section
    componentLabel: PropTypes.string.isRequired, // The label of component
    classes: PropTypes.any,
    device: PropTypes.any, // The type of device
    appliedFilters: PropTypes.any, // The current applied filters, to bind initial value
};

export default withStyles(NumberRangeStyle)(NumberRange);
