import { FastField, Field, getIn } from 'formik';
import React from 'react';
import Icon from 'Generic/icon/components/Icon';
import TenantFormContext from 'Commons/contexts/TenantFormContext';
import { clsx } from 'Commons/helpers/utils/clsx';
import { isValidDate } from 'Commons/helpers/utils/DateTime';
import sectionConfig from 'External/containers/tenant/form/config/SectionConfig';
import { isKeyPrefixForField } from 'Commons/helpers/utils/Utils';
import { FormControl, FormHelperText, Grid, withStyles } from '../../componentlibrary/components/Components';
import * as validators from '../../../../helpers/utils/validator/Validator';
import { EMPTY_FUNC, STATUS } from '../../../../config/constants/Constants';
import formStyle from '../styles/FormStyle';
import { isPureObject } from '../../../../helpers/utils/DataHelpers';
import { FORM_COMPONENTS, VARIANT } from '../config/FormComponentsConfig';
import { getFieldValue } from '../utils/utils';

const Form = (Component, extraProps = {}, requestedProps = {}) => {
    class FormHOC extends React.Component {
        constructor(props) {
            super(props);
            const { classes, useField } = props;
            this.FormikField = useField ? Field : FastField;
            this.formProps = {};
            this.variantToClassMapping = {
                [VARIANT.DEFAULT]: classes.spacingFormGrid,
                [VARIANT.INLINE]: classes.inlineField,
                [VARIANT.LAST_INLINE]: classes.lastInlineField,
                [VARIANT.FIRST_INLINE]: classes.firstInlineField,
                [VARIANT.NO_PADDING]: classes.noPadding,
                [VARIANT.NO_LEFT_RIGHT_PADDING]: classes.noLeftRightPadding,
            };
        }

        componentDidMount() {
            // TODO: Revisit and see if Formik can do this for us,
            const { formProps: { validateField = EMPTY_FUNC } = {}, props: { validateOnMount } = {} } = this;
            if (validateOnMount) {
                validateField();
            }
        }

        getError = (field, form) => {
            const { name } = field;
            const { errors, initialValues, values } = form;
            const { alwaysShowError } = this.props;
            const error = getIn(errors, name);
            const errorMessage = (Array.isArray(error) ? error[0] : error);
            if (alwaysShowError) {
                return errorMessage;
            }
            return getIn(initialValues, name) !== getIn(values, name) && errorMessage; // Showing the first error message only
        }

        validateFields = (value) => {
            const { validate, name } = this.props;
            const { validate: componentValidate } = extraProps;
            const allValidators = { ...validate, ...componentValidate };
            const { status: { nonRequiredFieldErrors } = {} } = this.formProps;
            if (isPureObject(allValidators)) {
                const validateFunctions = Object.keys(allValidators);
                for (let i = 0; i < validateFunctions.length; i += 1) {
                    const validateFuncName = validateFunctions[i];
                    const { [validateFuncName]: validateFunc = () => { } } = validators;
                    const { [validateFuncName]: validatorParams } = allValidators;
                    let customErrorMessage = null;
                    let validateFunArgs = validatorParams;
                    if (isPureObject(validatorParams)) {
                        const { value: additionalArg, errorMessage: errMsg } = validatorParams;
                        validateFunArgs = additionalArg;
                        customErrorMessage = errMsg;
                    }
                    const errorMessage = validateFunc(value, validateFunArgs);
                    if (errorMessage) {
                        if (nonRequiredFieldErrors && !nonRequiredFieldErrors.has(name)) {
                            nonRequiredFieldErrors.add(name);
                        }
                        return customErrorMessage || errorMessage;
                    }
                }
                return undefined;
            }
            nonRequiredFieldErrors.delete(name);
            return undefined;
        }

        validate = (value) => {
            const { isSectionVisible = true } = this;
            const { name } = this.props;
            const { isRequired } = validators;
            const { status: { errors, values: previousValues } = {} } = this.formProps;
            return (errors && (value === getIn(previousValues, name)) && getIn(errors, name))
                || (isSectionVisible && this.required && isRequired(value))
                || this.validateFields(value);
        }

        isDisabled = () => {
            const {
                formProps: { status: { formStatus, editGeneralPermission = true } = {} } = {},
                props: { disabled } = {},
            } = this;
            const isFormInactive = formStatus === STATUS.Inactive.value || formStatus === STATUS.Inactive_For_Future_Use.value;
            return isFormInactive || disabled || !editGeneralPermission;
        }

        setFieldAsRequiredOrPreferred = ({ name } = {}, form) => {
            const { requiredPath, required, preferred } = this.props;
            const { status: { requiredFields = {}, preferredFields = {} } = {} } = form;
            this.required = required;
            this.preferred = preferred;
            const key = requiredPath || name;
            if (Object.keys(requiredFields).length) {
                this.required = requiredFields[key] || required; // Giving required config more priority
            }
            if (!this.required && Object.keys(preferredFields).length) {
                this.preferred = preferredFields[key] || preferred; // Giving preferred config more priority
            }
        }

        renderIndicator = () => {
            const { required, preferred, props: { classes, label, noIndicatorWrapper } } = this;
            return (
                /* NOTE: Required and Preferred Indicators breaks for RadioGroup and CheckboxGroup  */
                <Grid
                    container
                    alignItems={!label ? 'center' : undefined}
                    className={clsx(noIndicatorWrapper ? classes.hidden : classes.indicatorWrapper)}
                    wrap="nowrap"
                >
                    {
                        (required || preferred)
                        && (
                            <Icon
                                type="custom"
                                display="inline-block"
                                className={clsx(classes.indicator, required ? classes.required : classes.preferred)}
                                icon={required ? 'cp-asterisk' : 'cp-preferred'}
                            />
                        )
                    }
                </Grid>
            );
        }

        renderComponent = (field, form, propsRequested, errorText) => {
            const { onChange: formikOnChange, onBlur: formikOnBlur, ...otherFieldProps } = field;
            const {
                props: {
                    FormControlProps, noGrid, GridProps, classes, validate, label, componentClasses, skipValueSet,
                    containerClassName, requiredPath, trackValue, onChange, useField, forwardedRef, disabled,
                    noIndicatorWrapper, onBlur, formVariant, hideErrorText, validateOnMount, alwaysShowError, ...others
                },
                required, preferred, renderIndicator,
            } = this;

            return (
                <Grid
                    container
                    wrap="nowrap"
                    className={containerClassName}
                    id={`#form-${others.name}`}
                >
                    {label ? renderIndicator() : null}
                    <FormControl
                        fullWidth
                        className={classes.formControl}
                        error={!!errorText}
                        {...FormControlProps}
                    >
                        <Grid container wrap="nowrap" direction={label ? 'column' : 'row'}>
                            {!label ? renderIndicator() : null}
                            <Component
                                form={form}
                                label={label}
                                ref={forwardedRef}
                                required={required}
                                preferred={preferred}
                                trackValue={trackValue}
                                classes={componentClasses}
                                disabled={this.isDisabled()}
                                onChange={(event, value, ...args) => this.onChange(event, value, form, ...args)}
                                onBlur={(...args) => this.onBlur(formikOnBlur, ...args)}
                                {...otherFieldProps}
                                {...others}
                                {...extraProps}
                                {...propsRequested}
                            />
                        </Grid>
                        {
                            !!errorText && !hideErrorText && typeof errorText === 'string'
                            && (
                                <FormHelperText
                                    className={clsx(classes.error, { [classes.errorTextPadding]: !label })}
                                >
                                    {errorText}
                                </FormHelperText>
                            )
                        }
                    </FormControl>
                </Grid>
            );
        }


        onChange = (event, value, form = {}, ...args) => {
            const { setFieldValue = () => { }, setFieldTouched = () => { }, touched, initialValues } = form;
            const { props: { onChange, name, skipValueSet }, validate } = this;
            if (!skipValueSet) {
                const fieldValue = getFieldValue(initialValues, name, value);
                setFieldValue(name, fieldValue);
                const { nativeEvent } = event || {};
                const { type } = nativeEvent || {};
                if (!nativeEvent || type !== 'input') {
                    if (!getIn(touched, name)) {
                        setFieldTouched(name, true, false);
                    }
                }
            }
            if (onChange) {
                if (Component.displayName === FORM_COMPONENTS.DATE_PICKER) {
                    if (isValidDate(value) && !validate(value)) {
                        onChange(event, value, ...args);
                    }
                } else {
                    onChange(event, value, ...args);
                }
            }
        }

        onBlur = (formikOnBlur, event, ...args) => {
            const { onBlur } = this.props;
            formikOnBlur(event, ...args);
            if (onBlur) {
                const { target: { value } } = event;
                onBlur(event, value, ...args);
            }
        }

        shouldRender = ({
            required: renderRequired,
            preferred: renderPreferred,
            optional: renderOptional,
        }) => {
            const { required, preferred } = this;
            if (renderRequired && renderPreferred && renderOptional) { return true; }
            return (renderRequired && required) || (renderPreferred && preferred)
                || (renderOptional && (!required && !preferred));
        }

        render() {
            const {
                name, classes, noGrid, GridProps: {
                    classes: { item: itemClasses = '', ...gridClasses } = {},
                    className: gridClassName = '', useField, xs = 12, ...GridProps
                }, requiredPath, formVariant,
            } = this.props;
            const { FormikField, variantToClassMapping } = this;
            return (
                <FormikField
                    name={name}
                    validate={this.validate}
                    render={({ field, form }) => {
                        const { isValid } = form;
                        this.formProps = form;
                        this.setFieldAsRequiredOrPreferred(field, form);
                        const errorText = !isValid ? this.getError(field, form) : undefined;
                        const propsRequested = Object.keys(requestedProps).reduce((accumulator, key) => {
                            if (requestedProps[key]) {
                                return {
                                    ...accumulator,
                                    [key]: key === 'error' ? !!errorText : (form[key] || field[key]),
                                };
                            }
                            const { [key]: omit, ...updatedAccumulator } = accumulator; return updatedAccumulator;
                        }, {});

                        return (
                            <TenantFormContext.Consumer>
                                {({ fieldsVisibilityConfig, sectionState } = {}) => {
                                    const key = requiredPath || name;
                                    if (sectionState) {
                                        let sectionId;
                                        Object.keys(sectionConfig).forEach((sectionName) => {
                                            const { formikKey, id } = sectionConfig[sectionName];
                                            if (isKeyPrefixForField(formikKey, key)) {
                                                sectionId = id;
                                            }
                                        });
                                        this.isSectionVisible = sectionState[sectionId];
                                    }
                                    const shouldRender = fieldsVisibilityConfig
                                        ? (fieldsVisibilityConfig[key] || {}).visibility : true;
                                    return (noGrid ? this.renderComponent(field, form, propsRequested, errorText) : (
                                        <Grid
                                            item
                                            xs={xs}
                                            classes={{ item: `${classes.item} ${itemClasses}`, ...gridClasses }}
                                            className={clsx(variantToClassMapping[formVariant],
                                                !shouldRender && classes.hidden, gridClassName)}
                                            {...GridProps}
                                        >
                                            {this.renderComponent(field, form, propsRequested, errorText)}
                                        </Grid>
                                    ));
                                }}
                            </TenantFormContext.Consumer>
                        );
                    }}
                />
            );
        }
    }

    FormHOC.propTypes = {
        item: PropTypes.bool,
        xs: PropTypes.number,
        label: PropTypes.node,
        noGrid: PropTypes.bool,
        onBlur: PropTypes.func,
        required: PropTypes.bool,
        onChange: PropTypes.func,
        useField: PropTypes.bool,
        disabled: PropTypes.bool,
        preferred: PropTypes.bool,
        classes: PropTypes.object,
        trackValue: PropTypes.bool,
        validate: PropTypes.object,
        GridProps: PropTypes.object,
        className: PropTypes.string,
        forwardedRef: PropTypes.any,
        skipValueSet: PropTypes.bool,
        requiredPath: PropTypes.string,
        name: PropTypes.string.isRequired,
        componentClasses: PropTypes.object,
        FormControlProps: PropTypes.object,
        noIndicatorWrapper: PropTypes.bool,
        containerClassName: PropTypes.string,
        formVariant: PropTypes.oneOf(Object.values(VARIANT)),
        hideErrorText: PropTypes.bool,
        validateOnMount: PropTypes.bool,
        alwaysShowError: PropTypes.bool, // To show error even if field is not touched
    };

    FormHOC.defaultProps = {
        validate: {},
        GridProps: {},
        noGrid: false,
        useField: false,
        trackValue: false,
        skipValueSet: false,
        containerClassName: '',
        noIndicatorWrapper: false,
        formVariant: VARIANT.DEFAULT,
        hideErrorText: false,
        validateOnMount: false,
        alwaysShowError: false,
    };

    // eslint-disable-next-line react/no-multi-comp
    return withStyles(formStyle, { name: 'Form' })(React.forwardRef((props, ref) => <FormHOC {...props} forwardedRef={ref} />));
};

export default Form;
