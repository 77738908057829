const listRendererStyle = theme => ({
    chipContainer: {
        display: 'flex',
        alignContent: 'center',
        flexWrap: 'wrap',
        '& > *': { margin: theme.spacing(1) },
    },
});

export default listRendererStyle;
