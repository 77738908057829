const uploadStatusStyle = theme => ({
    modalGrid: {
        width: 'calc(46vw + 8px)', // 8px is for scrollbar
        height: '62vh',
        padding: theme.spacing(2),
        flexWrap: 'nowrap',
    },
    modalHeader: {
        minHeight: 'auto',
        '& $closeButton': { marginRight: 0 },
    },
    closeButton: {},
    photosToUpload: {
        overflow: 'auto',
        flex: '1 1 1px',
    },
    autoWidth: { width: 'auto' },
    uploadStatus: { marginTop: theme.spacing(2) },
    uploadModalGrid: { padding: theme.spacing(2) },
    uploadSuccess: { color: theme.palette.success.main },
    uploadError: { color: theme.palette.error.main },
    photoName: {
        flex: '1 1 1px',
        overflow: 'hidden',
        maxWidth: 'fit-content',
    },
    modalFooter: {
        marginTop: theme.spacing(1),
        height: theme.spacing(4),
    },
    marginTop8: { marginTop: theme.spacing(1) },
    fullHeight: { height: '100%' },
});

export default uploadStatusStyle;
