const textFieldStyle = theme => ({
    defaultCursor: { pointerEvents: 'none' },
    textField: {
        '&:hover': { '& $underline:not(.Mui-disabled):before': { borderBottomWidth: 2 } },
        '& input[type="search"]': {
            '&::-webkit-search-decoration, &::-webkit-search-cancel-button': { display: 'none' },
            '&::-webkit-search-results-button, &::-webkit-search-results-decoration': { display: 'none' },
        },
    },
    textFieldWidth: ({ width }) => ({ width }),
    inputAdornment: { padding: 0 },
    endAdornment: {},
    startAdornment: {},
    number: {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            appearance: 'none',
            margin: 0,
        },
        '&::placeholder': { textAlign: 'left' },
        textAlign: 'right',
    },
    marginTop: { marginTop: 4 },
    lightTextField: {
        '& $underline': { color: theme.palette.common.white },
        '& $underline:hover:not(.Mui-disabled):before': {
            borderBottomWidth: 2,
            borderBottomColor: theme.palette.common.white,
        },
        '& $underline:before': { borderColor: theme.palette.common.white },
        '& $underline:after': { borderColor: theme.palette.tertiary.main },

        '& $labelRoot': { color: theme.palette.common.white },
        '& $labelRoot.Mui-focused': { color: theme.palette.tertiary.main },
    },
    rightMarginIcon: { marginRight: 8 },
    leftMarginIcon: { marginLeft: 8 },

    multiline: {
        '& $underline': { '&:after, &:before': { display: 'none' } },
        '& $input': {
            boxShadow: `0px 0px 0px 1px ${theme.palette.text.primary}`,
            borderRadius: 4,
            padding: theme.spacing(1),
            '&:focus:not(.Mui-disabled)': { boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}` },
        },
        '&:hover:not(.Mui-disabled)': { '& $input': { boxShadow: `0px 0px 0px 2px ${theme.palette.text.primary}` } },

    },
    labelRoot: {},
    underline: {},
    input: { '&::placeholder': { ...theme.typography.body2 } },
    h5Input: {
        '&::placeholder': { ...theme.typography.h5 },
        ...theme.typography.h5,
    },
    h6Input: {
        '&::placeholder': { ...theme.typography.h6 },
        ...theme.typography.h6,
    },
    subtitle1Input: {
        '&::placeholder': { ...theme.typography.subtitle1 },
        ...theme.typography.subtitle1,
    },
    inputRoot: {},
    alignment: { alignItems: 'center' },

});

export default textFieldStyle;
