const quickFiltersStyle = theme => ({
    form: {
        display: 'flex',
        height: 'auto',
    },
    checkboxContainer: { width: 'auto' },
    chipLabel: { fontWeight: 600 },
    chip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
    },
    filledChip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`,
    },
});

export default quickFiltersStyle;
