import React from 'react';
import { withStyles } from '../../componentlibrary/components/Components';
import listRendererStyle from '../style/ListRendererStyle';

class ListRenderer extends React.Component {
    render() {
        const { data, classes, component: Component, parentIndex, ...otherProps } = this.props;
        return (
            <div className={classes.chipContainer}>
                {data.map((chipData, index) => (
                    <Component
                        key={index}
                        data={chipData}
                        parentIndex={parentIndex}
                        elementIndex={index}
                        {...otherProps}
                    />
                ))}
            </div>
        );
    }
}

ListRenderer.propTypes = { data: PropTypes.array, classes: PropTypes.object, component: PropTypes.any, parentIndex: PropTypes.number };

export default withStyles(listRendererStyle)(ListRenderer);
