import { ZIP_CODE_ENDPOINTS } from 'Commons/config/constants/Endpoints';

const callApi = (onAction, endPoint, body, callback = () => {}, shouldSendCustomHeader = true, method = 'POST', storeMethod = 'read') => {
    if (onAction) {
        onAction({
            config: [{
                api: {
                    method: storeMethod,
                    action: {
                        methodType: method,
                        endPoint,
                        ...(body && { body }),
                        ...shouldSendCustomHeader && {
                            customHeaderOptions: {
                                type: 'store',
                                key: 'facility',
                            },
                        },
                    },
                },
                callback,
            }],
        });
    }
};


const fetchZipCodeData = (onAction, zipCode, callback) => {
    callApi(onAction, `${ZIP_CODE_ENDPOINTS.search}/${zipCode}`, null, callback, false, 'GET');
};

export { fetchZipCodeData };
