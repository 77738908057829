import media from 'Commons/theme/MediaQueries';

const sectionHeaderStyle = theme => ({
    sectionChildren: {},
    section: {
        paddingBottom: theme.spacing(1),
        '& $childPadding': { paddingTop: theme.spacing(2) },
    },
    marginLeft: { marginLeft: 8 },
    header: {
        width: '100%',
        padding: theme.spacing(1.5, 0),
        [media.desktop]: { padding: theme.spacing(2, 0) },
    },
    inlineFlex: {
        width: 'auto',
        display: 'inline-flex',
    },
    divider: {
        backgroundColor: theme.palette.text.disabled,
        marginBottom: 10,
    },
    title: { },
    collapsedSection: { },
    subsection: {
        paddingTop: 0,
        paddingBottom: 0,
        width: '100%',
    },
    childPadding: {},
    noMarginBottom: { marginBottom: 0 },
    addonStart: { width: 'auto' },
    cursorPointer: { cursor: 'pointer' },
    children: {},
    disabled: { color: theme.palette.text.disabled },
});

export default sectionHeaderStyle;
