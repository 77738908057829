const loaderStyle = theme => ({
    loader: {
        ...theme.globalClasses.deactivatedOverlay.style,
        position: 'absolute',
        backgroundColor: `${theme.palette.common.white}D9`,
        opacity: 1,
    },
});

export default loaderStyle;
