import { Component } from 'react';
import Link from './Link';
import { Grid } from '../../componentlibrary/components/Components';
import LabelWithIcon from '../../labelwithicon/components/LabelWithIcon';

class LinkList extends Component {
    renderLink(link) {
        const { LinkProps = {} } = this.props;
        const { classes = {} } = LinkProps;
        const { id, name, showGrid, paymentHandler, ...linkProps } = link;
        const { addon, LabelWithIconProps, className } = linkProps;
        return (
            !showGrid
                ? (
                    <Link key={name} id={id} {...linkProps} {...LinkProps}>
                        {name}
                    </Link>
                )
                : (
                    <Grid key={name} className={`${classes.link}`} item onClick={paymentHandler}>
                        <Grid className={`${classes.reactlink} ${className}`}>
                            <LabelWithIcon addon={addon} {...LabelWithIconProps}>{name}</LabelWithIcon>
                        </Grid>
                    </Grid>
                )
        );
    }

    render() {
        const { list, LinkProps, ...gridProps } = this.props;
        return (
            <Grid container {...gridProps}>
                {list.map(link => this.renderLink(link))}
            </Grid>
        );
    }
}

LinkList.propTypes = {
    className: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string,
        name: PropTypes.string.isRequired,
    })).isRequired,
    alignItems: PropTypes.string,
    LinkProps: PropTypes.object,
};

LinkList.defaultProps = {
    className: '',
    alignItems: 'center',
};

export default LinkList;
