import { createEnumMap, underScoreFormatter } from 'Commons/helpers/utils/Formatter';
import enums from './Enums';

const storeKeyToEntityTypeMapping = {
    account: 'Account',
    businessGroup: 'Business_Group',
    facility: 'Facility',
};

const USER_ACTION_ITEM = createEnumMap(enums.userActionItem);
const ERROR_CODE = createEnumMap(enums.errorCode, underScoreFormatter);
const BAD_REQUEST_STATUS_CODE = 400;

export { storeKeyToEntityTypeMapping, USER_ACTION_ITEM, BAD_REQUEST_STATUS_CODE, ERROR_CODE };
