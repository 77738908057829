const userProfileStyles = theme => ({
    pageHeader: { padding: theme.spacing(2) },
    pageBody: { padding: theme.spacing(2, 4, 2, 2) },
    shadows: {
        boxShadow: 'none',
        marginRight: theme.spacing(1),
        paddingTop: theme.spacing(3),
    },
    checkBoxRoot: {
        color: theme.palette.primary.main,
        padding: 0,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(0.5),
    },
    checkboxLabel: { letterSpacing: 0.75 },
    userDetailsContainer: { paddingBottom: theme.spacing(4) },
    userInfoFieldContainer: { paddingBottom: theme.spacing(2) },
    expandIcon: { marginLeft: theme.spacing(1) },
    editPhoneNumber: { paddingLeft: theme.spacing(2) },
    expansionPanelSummary: { padding: theme.spacing(0), justifyContent: 'flex-start' },
    expansionPanelSummaryContent: { maxWidth: 'max-content' },
    footer: { paddingRight: theme.spacing(2) },
});

export default userProfileStyles;
