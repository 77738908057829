import { makeStyles, Grid } from 'Generic/componentlibrary/components/Components';
import Icon from 'Generic/icon/components/Icon';
import Button from 'Generic/button/components/Button';
import { clsx } from 'Commons/helpers/utils/clsx';
import localisable from 'Commons/config/strings/localisable';
import Typography from 'Generic/typography/components/Typography';
import { useEffect } from 'react';
import { EMPTY_FUNC } from 'Commons/config/constants/Constants';
import snackbarStyles from '../styles/SnackbarStyles';
import { VARIANT } from '../config/Constants';

const useStyles = makeStyles(snackbarStyles, { name: 'SnackMessage' });

const SnackMessage = React.forwardRef((props, ref) => {
    const { variant, title, subtitle, isRealTimeNotification, actions, closeSnackbar, id, onSnackbarMount } = props;
    const classes = useStyles(props);

    // TODO: Kept in case we need body and showMore again
    // const [expanded, setExpanded] = useState(false);
    // const expandMore = () => {
    //     setExpanded(!expanded);
    // };

    const isErrorVariant = () => variant === VARIANT.error.value;

    useEffect(() => {
        onSnackbarMount({ key: id, closeSnackbar });
    }, []);

    return (
        <Grid
            container
            direction="column"
            className={clsx(classes.messageContainer, classes[variant], isRealTimeNotification && classes.rtns)}
            ref={ref}
        >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
                {
                    !isRealTimeNotification
                    && (
                        <Grid>
                            <Icon
                                type="custom"
                                icon={`cp-${variant}`}
                                className={classes.icon}
                            />
                        </Grid>
                    )}
                <Grid
                    container
                    className={classes.titleContainer}
                    justify={isErrorVariant() ? 'space-between' : 'flex-start'}
                >
                    <Grid
                        container
                        className={clsx(classes.titleContainerItem)}
                        direction={isErrorVariant() ? 'column' : 'row'}
                        justify="flex-start"
                    >
                        <Typography
                            variant="body1"
                            component="div"
                        >
                            {title}
                        </Typography>
                        {
                            true
                            && (
                                <Typography
                                    variant="body1"
                                    className={clsx(!isErrorVariant() && classes.subtitle)}
                                >
                                    {subtitle}
                                </Typography>
                            )
                        }
                    </Grid>
                    {
                        (isErrorVariant())
                        && (
                            <Grid>
                                <Icon
                                    type="custom"
                                    onClick={() => closeSnackbar(id)}
                                    color="secondary"
                                    icon="cp-close"
                                    className={classes.icon}
                                />
                            </Grid>
                        )
                    }
                </Grid>
                {/* TODO: Kept it just in case we need body again

                /* <Grid xs={12} item className={`${expanded ? `${classes.expanded} ${classes[`${variant}Expanded`]}` : Theme.globalClasses.multilineEllipsis.className} ${message ? classes.message : ''}`}>
                    {message}
                </Grid>
                {
                    message && (message.length > 100) && (
                        <Grid container item xs={12} justify="flex-end">
                            <Typography onClick={expandMore} className={classes.scroller}>
                                { !expanded && <span className={`${classes.showMore} ${classes[`${variant}ShowMore`]}`}>Show More</span> }
                            </Typography>
                        </Grid>
                    )
                } */}
            </Grid>
            {
                isRealTimeNotification
                    && (
                        <Grid container className={classes.rtnsContainer} justify="flex-end">
                            {
                                actions.map(({ onClick, label }) => (
                                    <Button
                                        onClick={() => {
                                            closeSnackbar(id);
                                            if (onClick) { onClick(); }
                                        }}
                                        variant="text"
                                        key={label}
                                        className={clsx(classes.actionButton,
                                            label === localisable.refresh && classes.refreshButton)}
                                    >
                                        <Typography variant={label === localisable.refresh ? 'body1' : 'body2'}>
                                            {label}
                                        </Typography>
                                    </Button>
                                ))
                            }
                        </Grid>
                    )
            }
        </Grid>
    );
});

SnackMessage.propTypes = {
    subtitle: PropTypes.node,
    variant: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    isRealTimeNotification: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        label: PropTypes.string.isRequired,
    })),
    closeSnackbar: PropTypes.func,
    id: PropTypes.number,
    onSnackbarMount: PropTypes.func,
};

SnackMessage.defaultProps = { actions: [], onSnackbarMount: EMPTY_FUNC };

export default SnackMessage;
