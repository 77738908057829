import media from 'Commons/theme/MediaQueries';

const InsuranceStyle = theme => ({
    heading: {
        marginBottom: theme.spacing(2.75),
        lineHeight: 1,
    },
    coverageDetails: {
        paddingTop: theme.spacing(2),
        [media.laptopUp]: { paddingTop: theme.spacing(1.5) },
        [media.desktop]: { paddingTop: theme.spacing(2) },
    },
    amountField: { alignSelf: 'center' },
    indicator: {
        fontSize: 8,
        [media.laptop]: { fontSize: 6 },
        [media.desktop]: { fontSize: 8 },
        cursor: 'default',
        lineHeight: 2,
    },
    required: { color: theme.palette.error.main, paddingRight: theme.spacing(1) },
    autoWidth: { width: 'auto' },
    modalGrid: {
        width: '90%',
        height: '90%',
        [media.tabletUp]: { width: '50%', height: '45%' },
        [media.laptopUp]: { width: '35%', height: '65%' },
        [media.desktopUp]: { width: '35%', height: '55%' },
        flexWrap: 'nowrap',
    },
    modalBody: { padding: theme.spacing(0, 2), backgroundColor: theme.palette.common.white, justifyContent: 'center' },
    modalHeader: {
        padding: theme.spacing(2, 2, 4, 2),
        minHeight: 0,
    },
    prorateAmount: {
        padding: theme.spacing(2, 0, 0, 2),
        [media.laptopUp]: { padding: theme.spacing(1.5, 0, 0, 2) },
        [media.desktop]: { padding: theme.spacing(2, 0, 0, 2) },
    },
    loader: { alignSelf: 'center' },
    policyPremiumMoveIn: {
        paddingTop: theme.spacing(2),
        [media.laptopUp]: { paddingTop: theme.spacing(1.5) },
        [media.desktop]: { paddingTop: theme.spacing(2) },
    },
    policyPremium: {
        paddingBottom: theme.spacing(2),
        [media.laptopUp]: { paddingBottom: theme.spacing(1.5) },
        [media.desktop]: { paddingBottom: theme.spacing(2) },
    },
    formFieldMargin: { margin: theme.spacing(2, 0, 1, 0) },
    fitHeight: { height: 'fit-content' },
});

export default InsuranceStyle;
