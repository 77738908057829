const getName = (
    {
        firstName = '',
        lastName = '',
        suffix = '',
        businessName = '',
        salutation = '',
    } = {},
    lastNameFirst = true,
) => {
    const name = lastNameFirst ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
    return (
        (firstName || lastName)
            ? (`${salutation ? `${salutation}. ` : ''}${name} ${suffix ? `${suffix} ` : ''}`)
            : businessName
    );
};
export default getName;
