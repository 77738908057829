import { useEffect, useRef, useState } from 'react';
import { Grid, Loader, makeStyles } from 'Generic/componentlibrary/components/Components';
import Modal from 'Generic/modal/components/Modal';
import localisable from 'Commons/config/strings/localisable';
import { compareDatesOrDatetime, getCurrentDate } from 'Commons/helpers/utils/DateTime';
import Footer from 'Commons/components/business/footer/components/Footer';
import { COMPARISON_OPERATION, EMPTY_OBJECT, INSURANCE_SOURCE } from 'Commons/config/constants/Constants';
import Typography from 'Commons/components/generic/typography/components/Typography';
import { RENTAL_INFO_FIELDS } from 'External/containers/createoccupancy/config/Fields';
import ExpansionPanel from 'Commons/components/business/expansionPanel/components/ExpansionPanel';
import Button from 'Commons/components/generic/button/components/Button';
import { BILLING_TYPE } from 'External/containers/rentalConfiguration/config/RentalFormConfigs';
import InsuranceStyle from '../styles/InsuranceStyle';
import {
    cancelInsurance,
    fetchInsuranceConfiguration,
    fetchInsuranceCoverage,
    fetchInsuranceInfo,
    saveInsuranceInfo,
} from '../config/ApiRequests';
import InsuranceBody from './InsuranceBody';

const useStyles = makeStyles(InsuranceStyle, { name: 'Insurance' });

const Insurance = ({
    facilityId, onAction, handleErrors, fetchRentalInfo, setInsuranceUpdated,
    source = INSURANCE_SOURCE.MOVE_IN, closeModal, unitId, setSnackbarProps, isAnniversary = false,
    formProps: {
        setFieldValue, resetForm, values,
        values: {
            rentalInfo: { moveInDate, insurance: { insurancePremiumProrateAmount } = {} } = {},
            [source === INSURANCE_SOURCE.EDIT_UNIT
                ? 'insurance'
                : 'extra']: { policyId, coverageId, proratePremium, prorateAmount, skipFirstPeriod = false } = {},
        },
    }, facilityProrateInfo, facilityProrateInfo: { type: { billing } = {} },
}) => {
    const classes = useStyles();

    const [insuranceCoverageById, setCoverageById] = useState({});
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [insuranceCoverage, setInsuranceCoverage] = useState([]);
    const [initialInsuranceDetails, setInitialInsuranceDetails] = useState({
        initialPolicyId: policyId,
        initialCoverageId: coverageId,
    });
    const [{
        planId,
        provider,
        isInsuranceEnabled,
        policyRequired,
    }, setInsuranceConfigs] = useState({
        planId: null,
        provider: null,
        isInsuranceEnabled: false,
        policyRequired: false,
    });
    const isComponentMounted = useRef(false);

    const onInsuranceCoverageFetch = (apiError, response) => {
        if (!isComponentMounted.current) return; // If the component gets unmounted before the response is received, do not update the state. Otherwise, it will throw an error saying "Can't perform a React state update on an unmounted component"
        if (apiError) {
            handleErrors(apiError);
        } else if (response) {
            const {
                data: {
                    Insurance_Coverage: { data = [] } = {},
                    Insurance_Provider: { data: [{ value: { plans = [] } = {} } = {}] = [] } = {},
                } = {},
            } = response;
            const { description: planDescription = '' } = planId
                ? plans.find(({ planId: id }) => id === planId) || {}
                : {};
            const coverageList = [];
            const coverageById = {};
            data.forEach(({
                id,
                value: { description, monthlyPremium, coverageAmount, startDate, expirationDate },
            }) => {
                const isStartValid = startDate
                    ? compareDatesOrDatetime(startDate, moveInDate, COMPARISON_OPERATION.LTE)
                    : true;
                const isExpirationValid = expirationDate
                    ? compareDatesOrDatetime(expirationDate, moveInDate, COMPARISON_OPERATION.GTE)
                    : true;
                if (isStartValid && isExpirationValid) {
                    coverageList.push({
                        label: planDescription ? `${planDescription} - ${description}` : description,
                        value: id,
                    });
                    coverageById[id] = { description, monthlyPremium, coverageAmount };
                }
            });
            setInsuranceCoverage(coverageList);
            setCoverageById(coverageById);
        }
    };

    useEffect(() => {
        if (isInsuranceEnabled) {
            fetchInsuranceCoverage(onAction, facilityId, planId, undefined, provider, onInsuranceCoverageFetch);
        }
    }, [moveInDate]);

    const onInsuranceConfigFetch = (apiError, response) => {
        if (!isComponentMounted.current) return; // If the component gets unmounted before the response is received, do not update the state. Otherwise, it will throw an error saying "Can't perform a React state update on an unmounted component"
        if (apiError) {
            handleErrors(apiError);
        } else if (response) {
            const { data: { Insurance: { data: [insuranceConfig = {}] = [] } = {} } = {} } = response;
            const { value: { isEnabled = false, planId: pId, mandatePolicyId, providerId } = {} } = insuranceConfig;
            setInsuranceConfigs({
                planId: pId,
                provider: providerId,
                isInsuranceEnabled: isEnabled,
                policyRequired: mandatePolicyId,
            });
            fetchInsuranceCoverage(onAction, facilityId, pId, coverageId, provider, onInsuranceCoverageFetch);
        }
    };

    useEffect(() => {
        isComponentMounted.current = true;
        fetchInsuranceConfiguration(onAction, facilityId, onInsuranceConfigFetch);
        return () => {
            isComponentMounted.current = false;
        };
    }, []);

    const onInsuranceInfo = (apiError, response) => {
        if (apiError) {
            handleErrors(apiError);
        } else if (response) {
            const { data: { insurancePremiumProrateAmount: insuranceProrateAmount } = {} } = response;
            setFieldValue('insurance.prorateAmount', insuranceProrateAmount);
        }
        setInsuranceUpdated();
    };

    const getInsuranceInfo = () => {
        let value = {};
        if (coverageId) {
            value = {
                coverageId,
                proratePremium,
                skipFirstPeriod,
                policyId,
            };
        }
        if (source === INSURANCE_SOURCE.MOVE_IN) {
            fetchRentalInfo(value, RENTAL_INFO_FIELDS.INSURANCE);
        } else if (coverageId && initialInsuranceDetails.initialCoverageId !== coverageId) {
            value.effectiveDate = getCurrentDate();
            fetchInsuranceInfo(onAction, unitId, value, onInsuranceInfo);
        }
    };

    useEffect(() => {
        if (coverageId) {
            const baseFieldName = source === INSURANCE_SOURCE.EDIT_UNIT ? 'insurance' : 'extra';
            setFieldValue(`${baseFieldName}.proratePremium`, false);
            setFieldValue(`${baseFieldName}.skipFirstPeriod`, false);
        }
    }, [coverageId]);

    useEffect(() => {
        getInsuranceInfo();
    }, [proratePremium, skipFirstPeriod]);

    const clearEditUnitFields = () => {
        resetForm({
            ...values,
            insurance: {
                policyId: initialInsuranceDetails.initialPolicyId,
                coverageId: initialInsuranceDetails.initialCoverageId,
            },
        });
    };

    const handleModalClose = () => {
        clearEditUnitFields();
        closeModal();
    };

    const handlePanelCloseOrOpen = () => {
        setFieldValue('extra.coverageId', undefined);
        setFieldValue('extra.policyId', undefined);
        setFieldValue('extra.proratePremium', undefined);
        setFieldValue('extra.skipFirstPeriod', undefined);
    };

    const onInsuranceSave = (apiError, response) => {
        if (apiError) {
            handleErrors(apiError);
        } else if (response) {
            setInitialInsuranceDetails({ initialPolicyId: policyId, initialCoverageId: coverageId });
            setSnackbarProps(true, localisable.insuranceSaved);
            fetchInsuranceCoverage(onAction, facilityId, planId, undefined, provider, onInsuranceCoverageFetch);
        }
        setIsButtonLoading(false);
        setInsuranceUpdated();
    };

    const onCancelInsurance = (apiError, response) => {
        if (apiError) {
            handleErrors(apiError);
        } else if (response) {
            setSnackbarProps(true, localisable.insuranceCancelled);
            resetForm({
                ...values,
                insurance: {},
            });
            setInitialInsuranceDetails({});
        }
        setIsCancelling(false);
        setInsuranceUpdated();
    };

    const onSubmit = () => {
        const body = {
            coverageId,
            proratePremium,
            policyId,
            skipFirstPeriod,
            effectiveDate: getCurrentDate(),
        };
        setIsButtonLoading(true);
        saveInsuranceInfo(onAction, unitId, body, onInsuranceSave);
    };

    const handleInsuranceCancel = () => {
        setIsCancelling(true);
        cancelInsurance(onAction, unitId, onCancelInsurance);
    };

    const onChangeSkipFirstPeriod = (_, skip) => {
        let resetProratePremium = proratePremium && isAnniversary;
        if (!isAnniversary && proratePremium) {
            resetProratePremium = skip
                ? billing === BILLING_TYPE.Prorate_First.value
                : billing === BILLING_TYPE.Prorate_Second.value;
        }
        if (resetProratePremium) {
            setFieldValue(`${source === INSURANCE_SOURCE.EDIT_UNIT ? 'insurance' : 'extra'}.proratePremium`, false);
        }
    };

    const getInsuranceFooter = () => (
        <Footer
            positiveButtonProps={{
                disabled: (policyRequired ? !policyId : false)
                    || !coverageId || isButtonLoading
                    || (proratePremium ? !prorateAmount : false)
                    || (initialInsuranceDetails.initialCoverageId === coverageId),
                isButtonLoading,
                label: localisable.save,
                loadingText: localisable.saving,
                onClick: onSubmit,
            }}
            negativeButtonProps={{
                disabled: false,
                label: localisable.exit,
                onClick: handleModalClose,
            }}
        />
    );

    const getInsuranceBody = () => (isCancelling ? <Loader className={classes.loader} />
        : (
            <InsuranceBody
                classes={classes}
                source={source}
                skipFirstPeriod={skipFirstPeriod}
                insuranceCoverage={insuranceCoverage}
                insuranceCoverageById={insuranceCoverageById}
                coverageId={coverageId}
                insurancePremiumProrateAmount={insurancePremiumProrateAmount}
                prorateAmount={prorateAmount}
                initialCoverageId={initialInsuranceDetails.initialCoverageId}
                policyRequired={policyRequired}
                isAnniversary={isAnniversary}
                facilityProrateInfo={facilityProrateInfo}
                onChangeSkipFirstPeriod={onChangeSkipFirstPeriod}
            />
        ));

    const getInsuranceHeader = () => (source === INSURANCE_SOURCE.MOVE_IN
        ? localisable.insurance
        : (
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h6">
                        {localisable.insurance}
                    </Typography>
                </Grid>
                {
                    initialInsuranceDetails.initialCoverageId
                    && (
                        <Grid item>
                            <Button
                                disableRipple
                                variant="outlined"
                                color="error"
                                onClick={handleInsuranceCancel}
                            >
                                {localisable.cancel}
                            </Button>
                        </Grid>
                    )
                }
            </Grid>
        ));

    return isInsuranceEnabled && (
        source === INSURANCE_SOURCE.MOVE_IN
            ? (
                <ExpansionPanel
                    header={getInsuranceHeader()}
                    open={!!coverageId}
                    onCloseOrOpen={handlePanelCloseOrOpen}
                >
                    {getInsuranceBody()}
                </ExpansionPanel>
            )
            : (
                <Modal
                    open
                    header={getInsuranceHeader()}
                    footer={getInsuranceFooter()}
                    hideCloseButton
                    classes={{
                        modalGrid: classes.modalGrid,
                        modalHeader: classes.modalHeader,
                        modalBody: classes.modalBody,
                    }}
                >
                    {getInsuranceBody()}
                </Modal>
            )
    );
};

Insurance.propTypes = {
    onAction: PropTypes.func,
    unitId: PropTypes.string,
    source: PropTypes.string,
    closeModal: PropTypes.func,
    formProps: PropTypes.object,
    facilityId: PropTypes.string,
    handleErrors: PropTypes.func,
    isAnniversary: PropTypes.bool,
    fetchRentalInfo: PropTypes.func,
    setSnackbarProps: PropTypes.func,
    setInsuranceUpdated: PropTypes.func,
    facilityProrateInfo: PropTypes.object,
};

InsuranceBody.defaultProps = { facilityProrateInfo: EMPTY_OBJECT };

export default Insurance;
