import { FORM_COMPONENTS } from 'Generic/form/config/FormComponentsConfig';
import Form from '../../form/components/Form';
import { Checkbox } from '../../checkbox/components/CheckBox';
import { FormGroup, FormLabel, withStyles } from '../../componentlibrary/components/Components';
import checkboxGroupStyle from '../styles/CheckboxGroupStyle';

class CheckboxGroup extends React.Component {
    static indexByValue = list => list.reduce((accumulator, item) => ({
        ...accumulator,
        [item.value]: item.label,
    }), {});

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const { list } = nextProps;
        if (list !== prevState.list) {
            const indexedList = CheckboxGroup.indexByValue(list);
            return { indexedList };
        }
        return null;
    };


    constructor(props) {
        super(props);
        const { trackValue, value = [] } = this.props;
        this.state = { value: trackValue ? value : undefined };
    }

    getValue = () => {
        const { value: stateValue = [] } = this.state;
        const { value: propValue = [], trackValue } = this.props;
        return trackValue ? stateValue : propValue;
    };

    onChange = (event, item, itemIndex) => {
        const { onChange } = this.props;
        const value = [...this.getValue()];
        if (event.target.checked) {
            value.splice(itemIndex, 0, item.value);
        } else {
            const index = value.indexOf(item.value);
            if (index > -1) { value.splice(index, 1); }
        }
        this.setState({ value });
        onChange(event, value);
    }

    render() {
        const {
            form, onChange, value: propValue, list, onBlur, trackValue, FormControlProps, label, classes,
            required, preferred, readOnly, ...otherProps
        } = this.props;
        const { disabled } = this.props;
        const value = this.getValue();

        return (
            <>
                {label && <FormLabel classes={{ root: classes.root }} component="legend">{label}</FormLabel>}
                <FormGroup {...otherProps}>
                    {
                        list.map((checkBoxProps, index) => {
                            const { value: checkBoxValue } = checkBoxProps;
                            return (
                                <Checkbox
                                    key={checkBoxValue}
                                    checked={value.includes(checkBoxValue)}
                                    onChange={e => this.onChange(e, checkBoxProps, index)}
                                    trackValue={trackValue}
                                    disabled={disabled}
                                    readOnly={readOnly}
                                    {...checkBoxProps}
                                />
                            );
                        })
                    }
                </FormGroup>
            </>
        );
    }
}

CheckboxGroup.propTypes = {
    form: PropTypes.object,
    onChange: PropTypes.func,
    list: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.string,
    })),
    value: PropTypes.array,
    trackValue: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    FormControlProps: PropTypes.object,
    label: PropTypes.node,
    required: PropTypes.bool,
    preferred: PropTypes.bool,
    classes: PropTypes.object,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
    form: { setFieldValue: () => { } },
    onChange: () => { },
    list: [],
    value: [],
    trackValue: true,
    required: false,
    preferred: false,
    readOnly: false,
};

const CheckoxGroupWithStyle = withStyles(checkboxGroupStyle)(CheckboxGroup);
CheckoxGroupWithStyle.displayName = FORM_COMPONENTS.CHECKOX_GROUP;
const FormCheckboxGroup = Form(CheckoxGroupWithStyle);

export {
    CheckoxGroupWithStyle as CheckboxGroup,
    FormCheckboxGroup,
};
