import React from 'react';
import localisable from 'Commons/config/strings/localisable';
import FormTreeView from 'Commons/components/generic/treeview/components/FormTreeView';
import {
    accountData,
    maintenanceEventData,
    reportsData,
    syrapayMerchantPortal,
    systemData,
    tenantData,
} from './RolePermissionsConfig';

const userRoleTabs = ({
    formProps,
    occupancyConfig,
    financialsConfig,
    retailSaleConfig,
    unitConfig,
    configuration,
    isModeCreate,
    isFormActive,
    isStandardUser = false,
    isUserEditingOwnRole = false,
    isSourceInternalDashboard,
}) => {
    const commonCheckBoxProps = { disabled: !isFormActive || isUserEditingOwnRole };
    return (
        [
            {
                id: 0,
                label: localisable.admin,
                children: (
                    <div>
                        <FormTreeView
                            config={accountData({ isModeCreate, isStandardUser })}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            {
                id: 1,
                label: localisable.configuration,
                children: (
                    <div>
                        <FormTreeView
                            config={configuration}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            {
                id: 2,
                label: localisable.financials,
                children: (
                    <div>
                        <FormTreeView
                            config={financialsConfig}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            // Will be implemented later.
            // {
            //     id: 3,
            //     label: localisable.lateEvents,
            //     children: (
            //         <div>
            //             <FormTreeView
            //                 config={lateEvents}
            //                 formProps={formProps}
            //                 CheckboxProps={commonCheckBoxProps}
            //                 userRole
            //             />
            //         </div>
            //     ),
            // },
            {
                id: 4,
                label: localisable.maintenanceEvent,
                children: (
                    <div>
                        <FormTreeView
                            config={maintenanceEventData}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            {
                id: 5,
                label: localisable.occupancy,
                children: (
                    <div>
                        <FormTreeView
                            config={occupancyConfig}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            {
                id: 6,
                label: localisable.reports,
                children: (
                    <div>
                        <FormTreeView
                            config={reportsData}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            {
                id: 7,
                label: localisable.retailSale,
                children: (
                    <div>
                        <FormTreeView
                            config={retailSaleConfig}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            {
                id: 8,
                label: localisable.syrapayMerchantPortal,
                children: (
                    <div>
                        <FormTreeView
                            config={syrapayMerchantPortal}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            ...isSourceInternalDashboard ? [{
                id: 9,
                label: localisable.system,
                children: (
                    <div>
                        <FormTreeView
                            config={systemData}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            }] : [],
            {
                id: 10,
                label: localisable.tenant,
                children: (
                    <div>
                        <FormTreeView
                            config={tenantData}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
            {
                id: 11,
                label: localisable.unit,
                children: (
                    <div>
                        <FormTreeView
                            config={unitConfig}
                            formProps={formProps}
                            CheckboxProps={commonCheckBoxProps}
                            isSourceUserRole
                        />
                    </div>
                ),
            },
        ]
    );
};

export default userRoleTabs;
