
import { Grid, makeStyles, FormLabel, FormHelperText } from 'Generic/componentlibrary/components/Components';
import Icon from 'Generic/icon/components/Icon';
import { clsx } from 'Commons/helpers/utils/clsx';
import { screenMaxWidth } from 'Commons/config/constants/ScreenWidths';
import formItemStyle from '../styles/FormItemStyle';

const useStyles = makeStyles(formItemStyle, { name: 'FormItem' });

const FormItem = React.forwardRef((props, ref) => {
    const {
        label,
        padding,
        disabled,
        children,
        required,
        className,
        preferred,
        helperText,
        onlyPadding,
        separatorClassName,
        classes: classesProp,
        ...others
    } = props;

    let resultantPadding = padding;
    const { innerWidth } = window;
    if (!resultantPadding) {
        resultantPadding = 16;
        if (innerWidth <= screenMaxWidth.laptop) {
            resultantPadding = 12;
        }
        if (innerWidth <= screenMaxWidth.tablet) {
            resultantPadding = 16;
        }
    }

    const classes = useStyles({ classes: classesProp, ...props, padding: resultantPadding });

    const renderWithLabelAndHelperText = () => (
        <Grid container direction="column" className={classes.item}>
            {
                label && (
                    <FormLabel
                        className={clsx(classes.label, disabled && classes.disabled)}
                    >
                        {label}
                    </FormLabel>
                )
            }
            {children}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Grid>
    );

    return (
        <Grid
            item
            ref={ref}
            container
            wrap="nowrap"
            className={`${classes.formPadding} ${className}`}
            {...others}
        >
            {
                !onlyPadding && (
                    <Grid container wrap="nowrap" className={`${classes.formItem} ${separatorClassName}`}>
                        {
                            (required || preferred)
                            && (
                                <Icon
                                    type="custom"
                                    display="inline-block"
                                    className={clsx(classes.indicator, required ? classes.required : classes.preferred)}
                                    icon={required ? 'cp-asterisk' : 'cp-preferred'}
                                />
                            )
                        }
                    </Grid>
                )
            }
            { (label || helperText) ? renderWithLabelAndHelperText() : children}
        </Grid>
    );
});

FormItem.propTypes = {
    label: PropTypes.node,
    required: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    preferred: PropTypes.bool,
    helperText: PropTypes.node,
    className: PropTypes.string,
    onlyPadding: PropTypes.bool,
    separatorClassName: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FormItem.defaultProps = {
    className: '',
    separatorClassName: '',
};

export default FormItem;
