import { FormCheckboxGroup } from 'Generic/checkboxgroup/components/CheckBoxGroup';
import localisable from 'Commons/config/strings/localisable';
import { makeStyles } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';
import { MAINTENANCE_EVENT_STATUS } from '../config/Constants';
import MaintenanceEventFilterStyle from '../styles/MaintenanceEventFilterStyle';

const useStyle = makeStyles(MaintenanceEventFilterStyle, { name: 'MaintenanceEventFilterStyle' });

const MaintenanceEventFilter = () => {
    const classes = useStyle();
    return (
        <>
            <Typography variant="subtitle1" className={classes.firstLabel}>
                {localisable.status}
            </Typography>
            <FormCheckboxGroup
                name="terms.status"
                list={Object.values(MAINTENANCE_EVENT_STATUS)}
                noGrid
                noIndicatorWrapper
            />
        </>
    );
};

export default memo(MaintenanceEventFilter);
