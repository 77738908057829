import { Grid, Loader as CircularLoader, makeStyles } from 'Generic/componentlibrary/components/Components';
import { clsx } from 'Commons/helpers/utils/clsx';
import loaderStyle from '../styles/LoaderStyle';


const useStyles = makeStyles(loaderStyle, { name: 'Loader' });

const Loader = ({ loaderClassName }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            className={clsx(classes.loader, loaderClassName)}
        >
            <CircularLoader disableShrink />
        </Grid>
    );
};

Loader.propTypes = { loaderClassName: PropTypes.string };


export default Loader;
