import theme from 'Commons/theme/Theme';
import { UNIT_CONFIG } from 'Commons/components/business/bev/config/Config';
import { round } from 'Commons/helpers/utils';
import { convertToNumber } from 'Commons/helpers/utils/Utils';
import { TYPE_OF_ENTITY } from 'Commons/components/business/bev/config/Constants';
import { EMPTY_FIELD_TEXT } from 'Commons/config/constants/Constants';

// OLD FORMULAE

const convertWH = (value, isFacilityMigrated = false) => round(value * 5 * UNIT_CONFIG.getMagnification(isFacilityMigrated) - 3);

const getConvertedWH = (width, depth, isFacilityMigrated = false) => ({
    width: convertWH(convertToNumber(width), isFacilityMigrated),
    height: convertWH(convertToNumber(depth), isFacilityMigrated),
});

const reconvertWH = (value, isFacilityMigrated = false) => round((value + 3) / UNIT_CONFIG.getMagnification(isFacilityMigrated) / 5);

const getReconvertedWH = (width, depth, isFacilityMigrated = false) => ({
    width: reconvertWH(convertToNumber(width), isFacilityMigrated),
    depth: reconvertWH(convertToNumber(depth), isFacilityMigrated),
});

const getActualAngle = (angle) => {
    let actualAngle = convertToNumber(angle);
    if (actualAngle >= 360) {
        const timesToSubtract = Math.floor(actualAngle / 360);
        actualAngle -= 360 * timesToSubtract;
    } else if (angle < 0) {
        actualAngle = 360 + angle;
    }
    return round(actualAngle);
};

const getEntitiesDataFromCanvas = (entity, fabricCanvas = undefined, isFacilityMigrated = false) => {
    let entities = Array.isArray(entity) ? entity : [entity]; // entity is either a unit or a placeholder
    if (fabricCanvas && !entity) {
        entities = fabricCanvas.getObjects('group'); // entities can contain both units and placeholders
    }
    if (!entity || !entities.length) return [];
    return entities.map((singleEntity) => {
        const {
            id,
            tempId,
            left,
            top,
            angle,
            level,
            unitType,
            entityType,
            placeholderType,
            period = '',
            rentalStatus,
            _objects: [rectObj, textObj] = [],
        } = singleEntity || {};
        const isEntityUnit = entityType === TYPE_OF_ENTITY.Unit.value;
        const isEntityPlaceholder = entityType === TYPE_OF_ENTITY.Placeholder.value;
        const actualAngle = getActualAngle(angle);
        const { width, height } = rectObj || {};
        const { text: label } = textObj || {};

        return {
            ...(id && { id }),
            ...(tempId && { tempId }),
            entityType,
            label,
            bev: {
                x: round(left),
                y: round(top),
                angle: actualAngle,
                level,
                ...getReconvertedWH(width, height, isFacilityMigrated),
            },
            ...(isEntityUnit && {
                ...tempId && { additionalInfo: { includeInRentableInventoryCount: true } },
                rentalStatus,
                unitType,
                period,
            }),
            ...(isEntityPlaceholder && { placeholderType }),
        };
    });
};

const getProcessedBevValues = (unprocessedBevValues) => {
    const { x, y, width, depth, angle } = unprocessedBevValues;
    return {
        ...unprocessedBevValues,
        ...(x && { x: round(x) }),
        ...(y && { y: round(y) }),
        ...(width && { width: round(width) }),
        ...(depth && { depth: round(depth) }),
        ...(angle && { angle: round(angle) }),
    };
};

/* eslint-disable no-underscore-dangle */
const setTextAngleToZero = (fabricCanvas, actObject, selectionAngle = 0) => {
    const {
        _objects,
        angle: rectObjAngle,
    } = actObject;

    const angleToSet = -(rectObjAngle + selectionAngle);

    _objects.forEach((obj) => {
        const { type } = obj;
        if (type === 'text') {
            obj.set('angle', angleToSet);
            actObject.setCoords();
        }
    });
};

const getTooltip = (fabricInstance) => {
    const tooltipBody = new fabricInstance.Rect({
        ...UNIT_CONFIG.RECT_CONFIG,
        ...UNIT_CONFIG.TOOLTIP_BODY_CONFIG,
    });
    const tooltipText = new fabricInstance.Text('', {
        ...UNIT_CONFIG.LABEL_CONFIG,
        ...UNIT_CONFIG.TOOLTIP_TEXT_CONFIG,
    });
    return new fabricInstance.Group([tooltipBody, tooltipText], UNIT_CONFIG.TOOLTIP_CONFIG);
};

const getFontSize = (zoomValue) => {
    const numericZoomValue = convertToNumber(zoomValue);
    if (numericZoomValue >= 3.2) return 3;
    if (numericZoomValue >= 2) return 10 - 2.25 * numericZoomValue;
    return 8;
};

const getEllipsesTextObj = (fabricInstance, ellipsedText, zoomToSet) => new fabricInstance.Text(ellipsedText, {
    ...UNIT_CONFIG.LABEL_CONFIG,
    fill: theme.palette.black[700],
    fontSize: getFontSize(zoomToSet),
});

const getActualCanvasCoords = (index, cornerCoord, unitCoords, isFacilityMigrated = false) => {
    const getCoordinate = axis => (unitCoords[axis] + cornerCoord[axis] + (cornerCoord[axis] < 0 ? 1 : -1) * UNIT_CONFIG.getLabelOverflowTolerance(isFacilityMigrated));
    return {
        x: getCoordinate('x'),
        y: getCoordinate('y'),
    };
};

const getReducedActualLabelCanvasCoords = (allLabelCornerCoords, amountToReduce, unitCoords, isFacilityMigrated = false) => allLabelCornerCoords.map((cornerCoord, index) => {
    const actualCanvasCoords = getActualCanvasCoords(index, cornerCoord, unitCoords, isFacilityMigrated);
    return {
        x: actualCanvasCoords.x + ((cornerCoord.x < 0 ? 1 : -1) * amountToReduce / 2),
        y: actualCanvasCoords.y,
    };
});

const getEllipsedText = (fabricCanvasInstance, rectObj, textObj, allLabelCornerCoords, unitCoords, isFacilityMigrated = false) => {
    let overflowing = true;

    const {
        width: originalTextWidth,
        __charBounds: [characterBoundsArray] = [],
        _text: originalTextArray,
    } = textObj;

    const individualCharWidths = [];

    characterBoundsArray.forEach((char, index) => {
        if (char.width) individualCharWidths[index] = char.width;
    });

    const minCharWidth = Math.min(...individualCharWidths);
    let widthToReduce = minCharWidth;

    while (overflowing && widthToReduce < originalTextWidth) {
        const reducedActualLabelCanvasCoords = getReducedActualLabelCanvasCoords(allLabelCornerCoords,
            widthToReduce, unitCoords, isFacilityMigrated);

        overflowing = reducedActualLabelCanvasCoords.some(reducedActualCornerCoord => fabricCanvasInstance.isTargetTransparent(rectObj, reducedActualCornerCoord.x, reducedActualCornerCoord.y));

        if (overflowing) widthToReduce += minCharWidth;
    }

    const allowedWidth = originalTextWidth - widthToReduce - UNIT_CONFIG.getLabelOverflowTolerance(isFacilityMigrated);

    let i = individualCharWidths.length - 1;
    let addedWidth = 0;
    while (addedWidth < allowedWidth && i >= 0) {
        addedWidth += individualCharWidths[i];
        i -= 1;
    }

    return `${originalTextArray.slice(i + 1).join('')}`;
};

/* This piece of code has been taken from https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/ */
const calculateYIQRatio = (hexColor) => {
    let unitBackgroundColor = hexColor;

    // If a leading # is provided, remove it
    if (unitBackgroundColor.slice(0, 1) === '#') {
        unitBackgroundColor = unitBackgroundColor.slice(1);
    }

    // If a three-character hex code, make it six-character
    if (unitBackgroundColor.length === 3) {
        unitBackgroundColor = unitBackgroundColor.split('')
            .map(hex => hex + hex)
            .join('');
    }

    // Convert to RGB value
    const r = parseInt(unitBackgroundColor.substr(0, 2), 16);
    const g = parseInt(unitBackgroundColor.substr(2, 2), 16);
    const b = parseInt(unitBackgroundColor.substr(4, 2), 16);

    // Calculate YIQ ratio
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
};


const getAppropriateTextColor = (backgroundHexColor) => {
    const yiqRatio = calculateYIQRatio(backgroundHexColor);
    // Check contrast
    return (yiqRatio >= 128) ? theme.palette.black[700] : theme.palette.common.white;
};

const calcTriangleDimension = (unitWidth, unitHeight) => {
    let dimensionValue;
    if (unitWidth === unitHeight) {
        dimensionValue = unitWidth / 2;
    } else {
        dimensionValue = Math.min(unitWidth, unitHeight) / 2;
    }
    return dimensionValue;
};

const getNextLabel = (formValues, isActiveObjectAvailable) => {
    const { countType, count, label: oldLabel, entityType } = formValues;
    const isEntityPlaceholder = entityType === TYPE_OF_ENTITY.Placeholder.value;
    if (!isActiveObjectAvailable) return oldLabel;
    const change = count * countType;
    const splitOldLabel = oldLabel.match(/([^\d]*)(\d*)/);
    const alphaPart = splitOldLabel[1];
    const numberPart = splitOldLabel[2];

    if (isEntityPlaceholder && !numberPart) return oldLabel;

    const newNumber = convertToNumber(numberPart || '0') + change;
    if (newNumber < 1) return oldLabel;
    const padLength = numberPart.length || 2;
    return alphaPart + newNumber.toString().padStart(padLength, '0');
};

const getUnitDetails = (unitId, unitType, unitTypeData, unitBevData, unitIdTenantMapper, unitIdReservationMapper) => {
    const {
        value: {
            dimension: {
                width,
                depth,
            } = {},
            category = [],
            rate: { amountByPeriod: { Month: standardMonthlyRate } = {} } = {},
        } = {},
    } = unitTypeData[unitType] || {};

    const unitBevObj = unitBevData.filter(({ id }) => id === unitId)[0];
    const {
        currentRate,
        nextRentAssessmentDate = '',
        gateAccess: { status: unitGateAccessStatus = '' } = {},
        availabilityStatus: unitAvailabilityStatus = '',
        ledger: { id: ledgerIdCurrent } = {},
        comments: unitComments = EMPTY_FIELD_TEXT,
    } = unitBevObj || {};
    const categoryResult = category.map(item => item).join(', ');

    const {
        totalBalance: tenantBalance = 0,
        name: {
            firstName = '',
            lastName = '',
        } = {},
        ledger = [],
    } = unitIdTenantMapper[unitId] || {};

    const ledgerArray = Array.isArray(ledger) ? ledger : [ledger];
    const filteredLedgerArray = ledgerArray.filter(entry => entry.id === ledgerIdCurrent);

    const { lastPaid: { date: lastPaidDate = '', amount: lastPaidAmount = '' } = {} } = filteredLedgerArray[0] || {};
    const {
        reservationCode = '',
        status: reservationStatus = '',
        tenant: {
            name: {
                firstName: reservedFirstName = '',
                lastName: reservedLastName = '',
            } = {},
        } = {},
    } = unitIdReservationMapper[unitId] || {};

    return {
        width,
        depth,
        standardMonthlyRate,
        currentRate,
        nextRentAssessmentDate,
        unitGateAccessStatus,
        unitAvailabilityStatus,
        categoryResult,
        unitComments,
        tenantBalance,
        firstName,
        lastName,
        lastPaidDate,
        lastPaidAmount,
        reservationCode,
        reservationStatus,
        reservedFirstName,
        reservedLastName,
    };
};


export {
    getTooltip,
    getFontSize,
    reconvertWH,
    getNextLabel,
    getEntitiesDataFromCanvas,
    getActualAngle,
    getConvertedWH,
    getEllipsedText,
    getReconvertedWH,
    calculateYIQRatio,
    getEllipsesTextObj,
    setTextAngleToZero,
    calcTriangleDimension,
    getActualCanvasCoords,
    getProcessedBevValues,
    getAppropriateTextColor,
    getUnitDetails,
};
