
const userListFormattersStyle = () => ({
    actionLabel: {
        '&$format$headerCell': {
            alignItems: 'center',
            width: '100%',
        },
    },
    format: { },
    headerCell: {},
});

export default userListFormattersStyle;
