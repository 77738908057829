import localisable from 'Commons/config/strings/localisable';
import { SOURCE } from 'Commons/config/constants/Constants';

export default ({
    [SOURCE.unit.value]: {
        activate: localisable.unitActivateConfirmation,
        deactivate: localisable.unitDeactivateConfirmation,
    },
    [SOURCE.tenant.value]: {
        activate: localisable.tenantActivateConfirmation,
        deactivate: localisable.tenantDeactivateConfirmation,
    },
    [SOURCE.lead.value]: {
        activate: localisable.leadActivateConfirmation,
        deactivate: localisable.leadDeactivateConfirmation,
    },
    [SOURCE.vendor.value]: {
        activate: localisable.vendorActivateConfirmation,
        deactivate: localisable.vendorDeactivateConfirmation,
    },
    [SOURCE.inventoryList.value]: {
        activate: localisable.inventoryActivateConfirmation,
        deactivate: localisable.inventoryDeactivateConfirmation,
    },
    [SOURCE.userRole.value]: {
        activate: localisable.userRoleActivateConfirmation,
        deactivate: localisable.userRoleDeactivateConfirmation,
    },
    [SOURCE.configuration.value]: {
        activate: localisable.configurationActivateConfirmation,
        deactivate: localisable.configurationDeactivateConfirmation,
    },
});
