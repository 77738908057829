import Typography from 'Generic/typography/components/Typography';
import { memo } from 'react';

const HelperText = props => <Typography {...props} />;

HelperText.propTypes = {
    component: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
};

HelperText.defaultProps = {
    component: 'span',
    variant: 'caption',
    color: 'textPrimary',
};

export default memo(HelperText);
