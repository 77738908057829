const HEX_OPACITY = [
    '00', // 0%
    '1A', // 10%
    '33', // 20%
    '4D', // 30%
    '66', // 40%
    '80', // 50%
    '99', // 60%
    'B3', // 70%
    'CC', // 80%
    'E6', // 90%
    'FF', // 100%
];

export default HEX_OPACITY;
