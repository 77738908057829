import media from 'Commons/theme/MediaQueries';

const dayStyle = (theme) => {
    const minHeight = 57.5;
    return {
        dayContainer: { padding: theme.spacing(0), minHeight: `${minHeight / 10 * 12}px`, minWidth: '70px' },
        disabled: { opacity: 0.3 },
        dateBadge: ({ isDateToday, renderInRows, isDesktop, isTablet }) => {
            const laptopAndMobile = {
                width: 30,
                height: isDesktop && renderInRows ? 27 : 28,
            };
            const desktopAndTablet = {
                width: 30,
                // eslint-disable-next-line no-nested-ternary
                height: isTablet ? 22 : isDesktop && renderInRows ? 27 : 28,
            };

            return ({
                ...(isDateToday && { border: `1px solid ${theme.palette.primary.main}` }),
                borderRadius: '50%',
                alignSelf: 'center',
                textAlign: 'center',
                ...desktopAndTablet,
                [media.laptop]: laptopAndMobile,
                [media.tablet]: desktopAndTablet,
                [media.mobile]: laptopAndMobile,
            });
        },
        dot: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '5px',
        },
        inactive: { backgroundColor: '#d14141' }, // inactive data on a perticular day
        active: { backgroundColor: '#6DCF77' }, // active data on a perticular day
        moreItems: {
            '&:hover': {
                textDecoration: 'underline',
                color: theme.palette.secondary.main,
            },
        },
        inline: { maxWidth: '100%' },
    };
};

export default dayStyle;
