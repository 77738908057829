import {
    Typography, Grid, ListItem, Icon, ListItemText, Badge,
    withStyles,
} from 'Generic/componentlibrary/components/Components';
import Chip from 'Generic/filterchip/components/FilterChip';
import AppliedFilterContainerStyle from '../styles/AppliedFilterContainerStyle';
import calculateTextWidth from '../../../../helpers/utils/TextWidthCalculator';
import localisable from '../../../../config/strings/localisable';
import {
    FILTER_LABEL_LEFT_MARGIN,
    SHOW_MORE_BUTTON_RIGHT_MARGIN,
    EXTRA_SPACE_NEEDED_IN_CHIP,
    EXTRA_SPACE_NEEDED_IN_NESTED_CHIP,
    CHIP_RIGHT_MARGIN,
    EXTRA_SPACE_FOR_DROPPED_NESTED_CHIP,
    NESTED_CHIP_RIGHT_MARGIN,
    SHOW_MORE_BUTTON_LEFT_MARGIN,
} from '../config/Constants';

const { noFiltersApplied, filtersApplied } = localisable;

const StyledBadge = withStyles(theme => ({
    badge: {
        top: '-2px',
        left: '-3px',
        backgroundColor: theme.palette.common.secondary,
        borderRadius: '3px',
        fontSize: '0.625rem',
        height: '18px',
        minWidth: '18px',
    },
}))(Badge);

class AppliedFilterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.isShowMoreButtonVisible = false;
        this.isClearAllButtonVisible = true;
        this.availableSpace = 0;
        this.showMoreButtonWidth = calculateTextWidth('Show More', '14px', '600');
        this.appliedFilterLabelWidth = calculateTextWidth('Filters applied', '16px', '600');
    }

    getRenderableData = (filter, index) => {
        const { label, value, hidden = false } = filter;
        const chipLabel = `${label}:`;
        const renderableData = { label: chipLabel };
        const widthNeededForLabel = calculateTextWidth(chipLabel, '16px', '600');
        let valueToRender;
        if (!hidden) {
            if (Array.isArray(value) && value.length > 0) {
                valueToRender = this.getDataForNestedChips(value, widthNeededForLabel);
            } else if (typeof filter.value === 'object') {
                valueToRender = this.getDataForRangeTypeChips(value, widthNeededForLabel);
            } else {
                valueToRender = this.getDataForNormalChips(value, widthNeededForLabel);
            }
            if (valueToRender !== undefined) {
                renderableData.value = valueToRender;
                renderableData.index = index;
                return renderableData;
            }
        }
        this.presentShowMoreButton();
        return undefined;
    };

    getDataForNestedChips = (filterValue, labelWidth) => {
        const { isInModal } = this.props;
        const nestedValues = [];
        if (isInModal) {
            return filterValue;
        }
        let widthNeeded = labelWidth + EXTRA_SPACE_NEEDED_IN_CHIP;
        for (let index = 0; index < filterValue.length; index += 1) {
            const nestedChipWidth = calculateTextWidth(filterValue[index], '14px', '400')
            + EXTRA_SPACE_NEEDED_IN_NESTED_CHIP;
            if (this.availableSpace - (widthNeeded + nestedChipWidth) > EXTRA_SPACE_FOR_DROPPED_NESTED_CHIP) {
                if (index !== 0) {
                    widthNeeded += NESTED_CHIP_RIGHT_MARGIN;
                }
                widthNeeded += nestedChipWidth;
                nestedValues.push(filterValue[index]);
            } else {
                if (index === 0) {
                    this.presentShowMoreButton();
                    return undefined;
                }
                widthNeeded += EXTRA_SPACE_FOR_DROPPED_NESTED_CHIP;
                this.availableSpace -= widthNeeded;
                this.presentShowMoreButton();
                nestedValues.push('...');
                return nestedValues;
            }
        }
        if (widthNeeded < this.availableSpace) {
            this.availableSpace -= widthNeeded;
            return nestedValues;
        }
        return undefined;
    }

    getDataForRangeTypeChips = ({ from, to } = { }, labelWidth) => {
        const { isInModal } = this.props;
        let widthNeeded = labelWidth;
        let range = '';
        if (!from && !to) {
            return undefined;
        } if (from && to) {
            range = `${from} - ${to}`;
        } else if (to) {
            range = `< ${to}`;
        } else {
            range = `> ${from}`;
        }
        if (isInModal) {
            return range;
        }
        widthNeeded += calculateTextWidth(range, '16px', '400')
        + EXTRA_SPACE_NEEDED_IN_CHIP + CHIP_RIGHT_MARGIN;
        if (widthNeeded < this.availableSpace) {
            this.availableSpace -= widthNeeded;
            return range;
        }
        return undefined;
    };

   getDataForNormalChips = (filterValue, labelWidth) => {
       const { isInModal } = this.props;
       if (isInModal) {
           return filterValue;
       }
       let widthNeeded = labelWidth + EXTRA_SPACE_NEEDED_IN_CHIP + CHIP_RIGHT_MARGIN;
       widthNeeded += calculateTextWidth(filterValue, '16px', '400');
       if (widthNeeded < this.availableSpace) {
           this.availableSpace -= widthNeeded;
           return filterValue;
       }
       return undefined;
   }

    presentShowMoreButton = () => {
        this.isShowMoreButtonVisible = true;
    }

    hideShowMoreButton = () => {
        this.isShowMoreButtonVisible = false;
    }

    onDeleteChip = (chipData, indexOfNestedChip) => {
        const { data, onFilterChange } = this.props;
        const modifiedData = { ...data };
        const key = Object.keys(modifiedData)[chipData.index];
        if (indexOfNestedChip === undefined
            || (indexOfNestedChip !== undefined && modifiedData[key].value.length === 1)) {
            delete modifiedData[key];
        } else {
            modifiedData[key].value.splice(indexOfNestedChip, 1);
        }
        this.isShowMoreButtonVisible = false;
        if (onFilterChange) {
            onFilterChange(modifiedData);
        }
    }

    chipSelected = (index) => {
        const { data, onSelectChip } = this.props;
        if (onSelectChip) {
            const modifiedData = { ...data };
            const key = Object.keys(modifiedData)[index];
            onSelectChip(key, modifiedData[key]);
        }
    }

    onShowMore = () => {
        const { setFilterOverlay } = this.props;
        this.hideShowMoreButton();
        if (setFilterOverlay) {
            setFilterOverlay(true);
        }
    }

    clearAll = () => {
        const { onFilterChange, onClearAll } = this.props;
        if (onClearAll) {
            onClearAll();
        } else {
            onFilterChange({});
        }
    }

    renderChips() {
        const { data, width, isInModal } = this.props;
        if (!isInModal) {
            if (this.availableSpace === 0) {
                this.availableSpace = width
            - this.appliedFilterLabelWidth
            - this.showMoreButtonWidth
            - FILTER_LABEL_LEFT_MARGIN
            - SHOW_MORE_BUTTON_RIGHT_MARGIN
            - SHOW_MORE_BUTTON_LEFT_MARGIN
            - 15; // Remove this when table width issue is fixed.
            }
            this.hideShowMoreButton();
        }
        const renderedChips = [];
        const keys = Object.keys(data);
        for (let index = 0; index < keys.length; index += 1) {
            const dataToRender = this.getRenderableData(data[keys[index]], index);
            if (dataToRender !== undefined) {
                renderedChips.push(
                    <Chip
                        key={index}
                        filterName={keys[index]}
                        data={dataToRender}
                        isInModal={isInModal}
                        onDeleteChip={this.onDeleteChip}
                        onSelectChip={() => this.chipSelected(index)}
                    />,
                );
            } else {
                break;
            }
        }
        this.availableSpace = 0;
        return renderedChips;
    }

    renderNoFilters() {
        const { classes } = this.props;
        return (
            <>
                <p className={classes.noFiltersApplied}>{ noFiltersApplied }</p>
            </>
        );
    }

    renderSectionTitle() {
        const { device, classes, onBack, data } = this.props;
        switch (device) {
            case 'isMobile':
            case 'isPhablet':
            case 'isTablet':
                return (
                    <div className={classes.mobileSectionHeader}>
                        <ListItem className={classes.listItemContainer}>
                            <Icon onClick={onBack}>chevron_left</Icon>
                            <Grid container alignItems="center" justify="space-between">
                                <ListItemText className={classes.title}>
                                    { filtersApplied }
                                    <StyledBadge
                                        color="primary"
                                        badgeContent={Object.keys(data).length}
                                        className={classes.margin}
                                        showZero
                                    />
                                </ListItemText>
                                {Object.keys(data).length > 0 && (
                                    <Typography
                                        className={`${classes.showMoreButton} ${classes.clearAllButton}`}
                                        onClick={() => this.clearAll()}
                                        variant="body2"
                                    >
                                Clear All

                                    </Typography>
                                )}
                            </Grid>
                        </ListItem>
                    </div>
                );
            default:
                return this.renderAppliedFilterLabel();
        }
    }

    renderAppliedFilterLabel = () => {
        const { isInModal, classes, data } = this.props;
        if (isInModal && Object.keys(data).length > 0) {
            return (
                <Grid container alignItems="center" justify="space-between">
                    <Typography variant="h6" className={classes.label}>
                        { filtersApplied }
                        :
                    </Typography>
                    <Typography
                        className={`${classes.showMoreButton} ${classes.clearAllButton}`}
                        onClick={() => this.clearAll()}
                        variant="body2"
                    >
                        Clear All
                    </Typography>
                </Grid>
            );
        }
        return (
            <Typography variant="h6" className={classes.label}>
                { filtersApplied }
                :
            </Typography>
        );
    }

    render() {
        const { isInModal, classes, data } = this.props;
        return (
            <Grid
                container
                alignItems="center"
                justify="space-between"
                className={!isInModal
                    ? classes.rootContainer : undefined}
            >
                <Grid
                    container
                    direction={isInModal ? 'column' : 'row'}
                    className={!isInModal
                        ? classes.normalContainer : classes.normalContainerInModal}
                >
                    {this.renderSectionTitle()}
                    { Object.keys(data).length > 0 ? this.renderChips() : this.renderNoFilters() }
                </Grid>
                {
                    (!isInModal && this.isShowMoreButtonVisible)
                    && (
                        <Typography
                            className={classes.showMoreButton}
                            onClick={() => this.onShowMore()}
                            variant="body2"
                        >
                            Show More
                        </Typography>
                    )
                }
            </Grid>
        );
    }
}

AppliedFilterContainer.propTypes = {
    data: PropTypes.objectOf(
        PropTypes.shape({
            label: PropTypes.string,
            hidden: PropTypes.bool,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.array,
                PropTypes.shape({
                    from: PropTypes.oneOfType([
                        PropTypes.number,
                        PropTypes.string,
                    ]),
                    to: PropTypes.oneOfType([
                        PropTypes.number,
                        PropTypes.string,
                    ]),
                }),
            ]),
        }),
    ).isRequired, // filters body to render.
    isInModal: PropTypes.bool, // set this to true if showing conatiner is in modal, by-default this property is false.
    width: PropTypes.number.isRequired, // width of the container.
    onFilterChange: PropTypes.func.isRequired, // returns modified filter data when a filter gets removed.
    onClearAll: PropTypes.func, // removes all filters.
    setFilterOverlay: PropTypes.func,
    onSelectChip: PropTypes.func, // returns selected chip data
    classes: PropTypes.object,
    device: PropTypes.any, // The current device.
    onBack: PropTypes.func, // On back button press.
};

AppliedFilterContainer.defaultProps = { isInModal: false };

export default withStyles(AppliedFilterContainerStyle)(AppliedFilterContainer);
