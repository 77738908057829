import { FormTextField, TextField } from 'Generic/textfield/components/TextField';
import { IS_MAX_LENGTH_1024 } from 'Commons/config/constants/Validators';
import localisable from 'Commons/config/strings/localisable';
import InheritedConfigLabel from 'Commons/components/generic/InheritedConfigLabel/components/InheritedConfigLabel';

function Comments(props) {
    const { readOnly = false, isForm, validate } = props;
    const Component = isForm ? FormTextField : TextField;
    return (
        <Component
            multiline
            rows={6}
            label={(
                <InheritedConfigLabel
                    label={localisable.comments}
                    readOnly={readOnly}
                />
            )}
            fullWidth
            validate={validate}
            readOnly={readOnly}
            {...props}
        />
    );
}

Comments.propTypes = { readOnly: PropTypes.bool, isForm: PropTypes.bool, validate: PropTypes.object };

Comments.defaultProps = { readOnly: false, isForm: true, validate: IS_MAX_LENGTH_1024 };

export default Comments;
