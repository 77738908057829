const FILTER_LABEL_LEFT_MARGIN = 28;
const SHOW_MORE_BUTTON_RIGHT_MARGIN = 28;
const EXTRA_SPACE_NEEDED_IN_CHIP = 58;
const EXTRA_SPACE_NEEDED_IN_NESTED_CHIP = 36;
const CHIP_RIGHT_MARGIN = 10;
const EXTRA_SPACE_FOR_DROPPED_NESTED_CHIP = 27;
const NESTED_CHIP_RIGHT_MARGIN = 6;
const SHOW_MORE_BUTTON_LEFT_MARGIN = 10;

export {
    EXTRA_SPACE_NEEDED_IN_CHIP,
    EXTRA_SPACE_NEEDED_IN_NESTED_CHIP,
    CHIP_RIGHT_MARGIN,
    FILTER_LABEL_LEFT_MARGIN,
    SHOW_MORE_BUTTON_RIGHT_MARGIN,
    EXTRA_SPACE_FOR_DROPPED_NESTED_CHIP,
    NESTED_CHIP_RIGHT_MARGIN,
    SHOW_MORE_BUTTON_LEFT_MARGIN,
};
