const STATUS = {
    TOKEN_EXPIRED: -2,
    INVALID: -1,
    UNLOADED: 0,
    LOADING: 1,
    LOADED: 2,
    UPDATING: 3,
    UPDATED: 4,
    ERROR: 5,
    PAGINATE_ERROR: 6,
    QUEUED: 7,

    // status != [INVALID, ERROR]
    RESOLVED: 99,
};
export default STATUS;
