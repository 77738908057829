import { isPrimitiveType } from 'Commons/helpers/api/Formatter';
import * as unflatten from 'unflatten';
import { getIn } from 'formik';
import { deepCopy, deepMerge, deepMergeAll } from './DeepCopy';
import { ensureArray } from './Utils';
/* eslint-disable no-continue */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */

const getRegexPattern = value => new RegExp(value.replace(/\*/g, '[a-zA-Z0-9]+'), 'g');

const processTouchedKeys = (flattenedObject = {}, { key = '', path = '' }) => {
    let keyToPush = '';
    const processedTouchedObject = { ...flattenedObject };
    Object.keys(flattenedObject).forEach((flattenedKey) => {
        const result = getRegexPattern(path).exec(flattenedKey);
        if (result) {
            const [matchedPath = ''] = result;
            if (matchedPath) {
                keyToPush = `${matchedPath}.${key}`;
                processedTouchedObject[keyToPush] = true;
            }
        }
    });
    return processedTouchedObject;
};

const getBaseRegexPathAndKey = (excludedKey = '') => {
    if (excludedKey) {
        const splitKeys = excludedKey.split('.');
        return {
            key: splitKeys.pop(),
            path: splitKeys.join('.'),
        };
    }
    return null;
};

const getWhitelistTouchedKeys = (flattenedTouchObject = {}, keysToWhitelist = []) => {
    let whiteListedTouchedKeys = {};
    if (keysToWhitelist) {
        keysToWhitelist.forEach((keyToWhiteList) => {
            const { key, path } = getBaseRegexPathAndKey(keyToWhiteList);
            // Appending for other excluded keys
            whiteListedTouchedKeys = { ...whiteListedTouchedKeys, ...processTouchedKeys(flattenedTouchObject, { key, path }) };
        });
    }
    return { ...whiteListedTouchedKeys };
};


const isArrayOfJSONs = (arr = []) => {
    if (arr.length > 0) {
        for (const item in arr) {
            if (typeof arr[item] === 'object') continue;
            return false;
        }
        return true;
    }
    return false;
};

const flattenObject = (ob, formatter) => {
    const toReturn = {};
    for (const i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) === 'object' && ob[i] !== null) {
            const flatObject = flattenObject(ob[i], formatter);
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[`${i}.${x}`] = flatObject[x];
            }
        } else {
            toReturn[i] = (formatter ? formatter(ob[i]) : ob[i]);
        }
    }
    return toReturn;
};

const isValidValue = value => (value !== undefined && value !== null); // NOTE: It will allow empty strings, 0 and boolean false

const areTheseObjectsEqual = (ob1, ob2) => JSON.stringify(ob1) === JSON.stringify(ob2);

const isPureObject = object => object instanceof Object && object.constructor === Object;

const getFieldName = (name) => {
    const delimiter = '.';
    return name.includes(delimiter) ? name.split(delimiter) : name;
};

const isValidObject = obj => (typeof obj === 'object' && obj !== null);

const isObjWithKeys = obj => (isValidObject(obj) ? Object.keys(obj).length > 0 : false);

const isNonEmptyArray = array => !!(array && Array.isArray(array) && array.length);

const isEmptyArray = array => !isNonEmptyArray(array);

const getObjWithKeys = obj => (isObjWithKeys(obj) ? obj : undefined);

const setValue = (obj, property, value) => {
    const localObj = obj;
    const fieldNames = getFieldName(property);
    if (Array.isArray(fieldNames)) {
        const lastField = fieldNames.pop();
        let traversedObject = {};
        fieldNames.forEach((fieldName) => {
            traversedObject = traversedObject && typeof traversedObject[fieldName] !== 'undefined'
                ? traversedObject[fieldName]
                : localObj[fieldName];
            if (!traversedObject) {
                traversedObject = { [fieldName]: {} };
            }
        });
        // Traversed n-1 fields
        if ((Array.isArray(traversedObject[lastField]) && isValidObject(traversedObject[lastField]))) {
            const curTraversedArray = traversedObject[lastField];
            curTraversedArray.forEach((element, index) => {
                if (isValidObject(element)) {
                    // Iterate through the value property to set
                    for (const prop in value) {
                        if (element.hasOwnProperty(prop)) {
                            // If property exists set value
                            // eslint-disable-next-line no-param-reassign
                            element[prop] = value[prop];
                        }
                    }
                } else {
                    traversedObject[lastField][index] = value[index];
                }
            });
        } else {
            traversedObject[lastField] = value;
        }
    } else {
        localObj[property] = value;
    }
};


const getValue = (obj, name) => {
    if (!obj || !name) {
        throw new TypeError('Invalid Parameter Values');
    }

    const objToTraverse = deepCopy(obj); // To avoid reference issue
    let traversedObject;
    const fieldNames = getFieldName(name);
    if (Array.isArray(fieldNames)) {
        traversedObject = objToTraverse[fieldNames[0]] || {};
        for (let index = 1; index < fieldNames.length; index += 1) {
            traversedObject = traversedObject[fieldNames[index]];
            if (!traversedObject) {
                break; // Can't traverse deeper
            }
        }
    } else {
        traversedObject = obj && obj[fieldNames];
    }
    return traversedObject;
};

/**
 * @description Determines if the key has a truthy value in the given object.
 * This returns false for empty object and empty array as well.
 * @param {Object} object
 * @param {string} key
 */
const hasValue = (object, key) => {
    const value = getValue(object, key);
    return !!(isPrimitiveType(value) ? value : Object.keys(value).length);
};


/**
 * @name getDiffProperties
 * @description Returns an object with properties are different in ob2 when compared to ob1
 * @param {*} src The source object
 * @param {*} dest The destination object
 * @param {Object} options The additional options
 * @param {Object} options.properties The subset of properties to test for equality
 * @param {Object} options.excludeProperties These properties need not be compared, just copy from dest Ex: [ 'prop1.d.f.*.j', 'prop1.r' ]
 * @param {Boolean} options.updateActionType Will append action type
 * @param {Boolean} options.diffSrc Will store the value from source object if value
 * @param {Boolean} excludeNullUndefinedOnly Will include all falsy values except null and undefined
 * is different, by default the destination object value is stored in diff object
 */
const getDiffProperties = (src, dest, options = {}, excludeNullUndefinedOnly = false) => {
    const { properties: keys = {}, diffSrc = false, excludeProperties = {} } = options;
    let keysList = Object.keys(keys);
    const noOfKeys = keysList.length;
    const diffObj = {};
    // If no optional keys passed, comparing all object properties
    const allFlattenedKeys = (noOfKeys > 0) ? flattenObject(keys) : flattenObject(dest);
    const flattenedKeys = {};
    // If any of the flattened keys are undefined, delete it, to avoid keys in arrays getting touched
    Object.keys(allFlattenedKeys).forEach((flattenedKey) => {
        const valueAgainstKey = allFlattenedKeys[flattenedKey];
        const includeValue = excludeNullUndefinedOnly
            ? valueAgainstKey !== undefined && valueAgainstKey !== null
            : valueAgainstKey;
        if (includeValue) {
            flattenedKeys[flattenedKey] = valueAgainstKey;
        }
    });
    keysList = Object.keys(flattenedKeys);
    const objToCheck = diffSrc ? src : dest; // src
    const otherObj = diffSrc ? dest : src; // dest
    let objToCheckValue;
    let isValidObjToCheckValue;
    let otherObjValue;
    let isValidOtherObjValue;
    const processedKeysList = (excludeProperties && Object.keys(excludeProperties).length > 0) ? Object.keys(getWhitelistTouchedKeys(flattenedKeys, excludeProperties)) : keysList;
    processedKeysList.forEach((key) => {
        objToCheckValue = getValue(objToCheck, key); // dest value
        isValidObjToCheckValue = isValidValue(objToCheckValue); // Holds if it is a valid value (Not Undefined/null)
        otherObjValue = getValue(otherObj, key); // src value
        isValidOtherObjValue = isValidValue(otherObjValue); // Holds if it is a valid value (Not Undefined/null)
        if (isValidObjToCheckValue && isValidOtherObjValue) {
            // If both src & dest have truthy value
            if (otherObjValue !== objToCheckValue) {
                // Append only if those values are not same
                diffObj[key] = objToCheckValue;
            } else if (excludeProperties && excludeProperties.length > 0) {
                // If it is excluded
                excludeProperties.forEach((property) => {
                    const regexPattern = getRegexPattern(property);
                    const result = regexPattern.exec(key);
                    if (result) {
                        const [pathMatched] = result;
                        if (pathMatched && isValidValue(objToCheckValue) && objToCheckValue !== '') {
                            diffObj[key] = objToCheckValue;
                        }
                    }
                });
            }
        } else if (!isValidOtherObjValue && isValidOtherObjValue) {
            // If there was a falsey source value
            // became a truthy value
            diffObj[key] = objToCheckValue;
        } else if (isValidOtherObjValue && !isValidOtherObjValue) {
            // If there was a source value and dest value
            // became falsey
            diffObj[key] = typeof objToCheckValue !== 'boolean'
                ? undefined
                : objToCheckValue;
        } else if (isValidObjToCheckValue) {
            diffObj[key] = objToCheckValue;
        } else if (!isValidObjToCheckValue && isValidOtherObjValue) {
            diffObj[key] = objToCheckValue;
        }
    });
    return unflatten(diffObj) || diffObj;
};

const isPropertyAList = (ob, propName = '') => {
    const objValue = getValue(ob, propName);
    return Array.isArray(objValue);
};


/**
 * @name removeEmptyElementsFromArray
 * @description Returns an array with empty elements like - (null, undefined, {}) - removed.
 * @param {Array} arr The array to be processed
 *
 *  - Eg - removeEmptyElementsFromArray([1, '0', 0, 'null', 'undefined', undefined, null, '1', {}]) -> [1, '0', 0, 'null', 'undefined', '1']
 */
const removeEmptyElementsFromArray = (arr = []) => {
    if (Array.isArray(arr)) {
        return arr.filter((ele) => {
            if (ele === null) return false;
            if (typeof ele === 'object') return isObjWithKeys(ele);
            return ele !== undefined;
        });
    }
    // If not array just return the object
    return arr;
};

const extractValueFromObject = (obj) => {
    if (typeof obj === 'object') {
        // Currently works for first key
        const flattenedObject = flattenObject(obj);
        const [firstKey] = Object.keys(flattenedObject);
        return flattenedObject[firstKey];
    }
    return obj;
};


/**
 * @description Helper function to set values for nested keys and sets the empty object value if key does not exist
 */
const setObjectValueWithDefault = (obj, key, value) => {
    let objCopy = obj;
    const fieldNames = getFieldName(key);
    let lastField = key;

    if (Array.isArray(fieldNames)) {
        lastField = fieldNames.pop();
        fieldNames.forEach((fieldName) => {
            objCopy[fieldName] = typeof objCopy[fieldName] !== 'undefined' ? objCopy[fieldName] : {};
            objCopy = objCopy[fieldName];
        });
    }
    objCopy[lastField] = value;
};

const toObject = (list = []) => list.reduce((dict, { id } = {}, index) => ({ ...dict, [id]: index }), {});

const capitalize = (str = '', global = false) => (
    global ? str.replace(/\b\w/g, match => match.toUpperCase())
        : str.charAt(0).toUpperCase() + str.slice(1)
);

const deCapitalize = (str = '') => str.charAt(0).toLowerCase() + str.slice(1);

const pascalCaseWithUnderscore = key => capitalize(key.replace(/([A-Z])/g, match => `_${match.toUpperCase()}`));

const camelCase = key => capitalize(key.replace(/_\w/g, match => match[1].toUpperCase()));

const deleteValue = (obj, name) => {
    if (!obj || !name) {
        return;
    }
    let objCopy = obj;
    let fieldNames;
    if (typeof name === 'string') {
        fieldNames = name.split('.');
    }

    for (let i = 0; i < fieldNames.length - 1; i += 1) {
        objCopy = objCopy[fieldNames[i]];
        if (typeof objCopy === 'undefined') {
            return;
        }
    }

    delete objCopy[fieldNames.pop()];
};

const convertCamelCaseToWords = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

// eslint-disable-next-line no-restricted-globals
const isNumber = value => !isNaN(parseFloat(value)) && !isNaN(value - 0);

const isFunction = value => value && (
    Object.prototype.toString.call(value) === '[object Function]'
    || typeof value === 'function'
    || value instanceof Function
);

const b64Decode = str => window.atob(str);

const b64Encode = str => window.btoa(str);

// TODO: Remove usage of unescape
const b64EncodeUnicode = str => str && b64Encode(unescape(encodeURIComponent(str)));

const b64DecodeUnicode = str => str && b64Decode(decodeURIComponent(str));

const extendListToSize = (list, size, defaultValue = {}) => ([
    ...list,
    ...Array(size - list.length).fill(0).map(() => ({ ...defaultValue }))]
);

const compareArray = (array1 = [], array2 = []) => {
    const arrayObject = {};
    array1.forEach((item) => { arrayObject[item] = true; });
    return array1.length === array2.length && array2.every(value => arrayObject[value]);
};

/**
 * Sorts an array (in-place) of objects based on the provided keys with support for ascending or descending order.
 *
 * @param {Array} data - The array of objects to be sorted.
 * @param {Array} keys - The list of keys to sort by. Use "-" prefix to indicate descending order for a specific key.
 * @returns {Array} The sorted array of objects.
 */
const sortArray = (data, keys) => {
    const sortKeys = ensureArray(keys);
    const compareFunction = (a, b) => {
        for (let i = 0; i < sortKeys.length; i += 1) {
            const key = sortKeys[i];
            const sortOrder = key.startsWith('-') ? -1 : 1;
            const realKey = key.startsWith('-') ? key.substring(1) : key;
            // We are using getIn instead of getValue to avoid circular JSON issues
            const aValue = getIn(a, realKey);
            const bValue = getIn(b, realKey);

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                const comparison = aValue.localeCompare(bValue);
                if (comparison !== 0) {
                    return sortOrder * comparison;
                }
            } else {
                if (aValue < bValue) {
                    return -sortOrder;
                }
                if (aValue > bValue) {
                    return sortOrder;
                }
            }
        }
        return 0;
    };
    return data.sort(compareFunction);
};

const removeFalseKeys = obj => Object.entries(obj).reduce((result, [key, value]) => {
    if (value) {
        return { ...result, [key]: value };
    }
    return result;
}, {});

const containsFalseValue = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] === false) {
                return true;
            } if (typeof obj[key] === 'object') {
                if (containsFalseValue(obj[key])) {
                    return true;
                }
            }
        }
    }
    return false;
};

export {
    containsFalseValue,
    flattenObject,
    areTheseObjectsEqual,
    getDiffProperties,
    getFieldName,
    setValue, // Can be used in future
    getValue,
    deepMerge,
    deepMergeAll,
    isArrayOfJSONs, // Can be used in future
    isPropertyAList, // Can be used in future
    getWhitelistTouchedKeys,
    removeEmptyElementsFromArray,
    getRegexPattern,
    isValidValue,
    extractValueFromObject,
    isPureObject,
    isObjWithKeys,
    getObjWithKeys,
    toObject,
    capitalize,
    deleteValue,
    pascalCaseWithUnderscore,
    camelCase,
    deCapitalize,
    hasValue,
    isNumber,
    setObjectValueWithDefault,
    convertCamelCaseToWords,
    isNonEmptyArray,
    isEmptyArray,
    b64Encode,
    b64Decode,
    b64EncodeUnicode,
    b64DecodeUnicode,
    extendListToSize,
    compareArray,
    isValidObject,
    isFunction,
    sortArray,
    removeFalseKeys,
};

export { getIn, setIn } from 'formik';
