/* eslint-disable no-param-reassign */

/* eslint-disable react/prop-types */
import {
    DASHBOARD_TYPE,
    DATE_FORMAT,
    DATETIME_FORMAT,
    DELINQUENCY_STATUS,
    EMPTY_FIELD_TEXT,
    EMPTY_STRING,
    FEATURE,
    FORM_MODE,
    FULL_DATE_TIME_FORMAT,
    INTERNAL_SOURCE,
    RESERVATION_STATUS,
    SOURCE,
    STATUS,
} from 'Commons/config/constants/Constants';
import { Checkbox, Grid } from 'Generic/componentlibrary/components/Components';
import Typography from 'Generic/typography/components/Typography';
import Actions from 'Commons/components/business/actions/components/Actions';
import Icon from 'Generic/icon/components/Icon';
import Name from 'Commons/components/business/person/name/components/Name';
import localisable from 'Commons/config/strings/localisable';
import Link from 'Commons/components/generic/link/components/Link';
import Tooltip from 'Generic/tooltip/components/Tooltip';
import { dateFormatter } from 'Commons/helpers/utils/DateTime';
import getName from 'Commons/helpers/utils/NameHelper';
import { RENTAL_STATUS } from 'External/containers/unit/list/config/Constants';
import { NIL_BALANCE } from 'External/containers/tenant/list/config/Constants';
import Button from 'Generic/button/components/Button';
import { clsx } from 'Commons/helpers/utils/clsx';
import Theme from 'Commons/theme/Theme';
import {
    MODIFIER_TYPE,
    PAYMENT_METHOD_TYPE,
} from 'External/containers/financialConfiguration/config/FinancialFormConfigs';
import { useMemo, useState } from 'react';
import { labelFormatter } from 'Commons/helpers/utils/Formatter';
import { MAINTENANCE_EVENT_STATUS } from 'External/containers/operations/components/maintenanceEvents/config/Constants';
import { CONFIG_LEVEL_TYPE } from 'External/containers/configuration/config/Constants';
import { getValue, pascalCaseWithUnderscore } from 'Commons/helpers/utils/DataHelpers';
import { LEAD_STATUS } from 'External/containers/lead/config/Constants';
import { ESTIMATE_STATUS } from 'External/containers/lead/form/components/estimatesSection/listView/config/Constants';
import {
    LEASE_STATUS,
    OVERLOCK_STATUS,
    RATE_HISTORY_STATUS,
    VACANT_AVAILABILITY_STATUS,
} from 'External/containers/unit/form/config/Constants';
import getFormattedAmount from 'Commons/helpers/utils/AmountHelper';
import { phoneFormatter } from 'Commons/helpers/utils/Phone';
import LabelWithIcon from 'Commons/components/generic/labelwithicon/components/LabelWithIcon';
import buildUrl from 'Commons/helpers/utils/UrlBuilder';
import { ROUTE } from 'External/redux/config/RouteNames';
import { getPlainPhoneNumber } from 'External/containers/tenant/form/utils/Utils';
import { FormTextField } from 'Generic/textfield/components/TextField';
import { RENT_ADJUSTMENT_STATUS_LIST } from 'External/containers/operations/components/rentAdjustment/config/Constants';
import useConstructor from 'Commons/helpers/hooks/useConstructor';
import Chip from '../../../generic/chip/components/Chip';
import { MAX_VISIBLE_CONTENT } from '../config/Constants';
import { belongsToAnyOfGivenSources, getTruncatedTitle } from '../utils/Helper';
import { WAITING_STATUS } from '../../filtersAndSorts/components/waitingListFilter/config/Constants';

const isUnit = source => [SOURCE.unit.value, SOURCE.rateHistory.value].includes(source);

const isLead = source => source === SOURCE.lead.value;

const isTenant = source => source === SOURCE.tenant.value;

const isDashboard = source => source === SOURCE.dashboard.value;

const isDelinquency = source => source === SOURCE.delinquency.value;

const isUser = source => source === SOURCE.user.value;

const isVendor = source => source === SOURCE.vendor.value;

const isInventory = source => source === SOURCE.inventoryList.value;

const isManualLateEvent = source => source === SOURCE.manualLateEvent.value;

const isPromoPlanTemplate = source => source === SOURCE.promoPlanTemplate.value;

const isRetailSaleReturns = source => source === SOURCE.retailSaleReturns.value;

const LabelFormatter = (props) => {
    const {
        enablePointerEventsWithoutOnClick = false, isEditDisabled,
        argument: [label, , , data = {}], subtext = '',
        classes, match: { params: { fid, accountId } = {}, path = '' } = {}, source, to: configPath,
    } = props;
    const { name, id, hasTwoFactor } = data;
    const { firstName, lastName, businessName, suffix, salutation, preferredName } = name || {};
    let to = path.includes(ROUTE.SETTINGS_MAIN) || path.includes(ROUTE.SETTINGS_LIVE)
        ? buildUrl('settings', { source, id }, DASHBOARD_TYPE.SETTINGS, accountId)
        : buildUrl('mainEdit', { source, fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId);
    if (isUser(source)) {
        to = `${to}/edit`;
    } else if (isUnit(source)) {
        to = { pathname: to, state: { unitDetail: data } };
    } else if (isTenant(source) || isDelinquency(source)) {
        to = buildUrl('tenantView', { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId);
        if (isDelinquency(source)) {
            to = { pathname: to, state: { source } };
        }
    }

    return (
        <>
            <Grid container direction="column" className={classes.labelWrapper}>
                {
                    isEditDisabled
                        ? label
                        : (
                            <Tooltip
                                title={isTenant(source) || isLead(source) || isManualLateEvent(source) || isDelinquency(source) || isDashboard(source) ? getName(name) : label}
                            >
                                <Link
                                    to={configPath || to}
                                    LabelWithIconProps={{
                                        enablePointerEventsWithoutOnClick,
                                        classes: {
                                            labelWithIcon: classes.labelWithIcon,
                                            label: classes.labelWithIcon,
                                        },
                                    }}
                                >
                                    {isTenant(source) || isLead(source) || isManualLateEvent(source) || isDelinquency(source) || isDashboard(source)
                                        ? (
                                            <Name
                                                firstName={firstName}
                                                lastName={lastName}
                                                businessName={businessName}
                                                suffix={suffix}
                                                salutation={salutation}
                                                preferredName={preferredName}
                                            />
                                        )
                                        : label}
                                </Link>
                            </Tooltip>
                        )
                }
                {!!subtext
                    && (
                        <Tooltip title={subtext}>
                            <Typography variant="body1" noWrap>
                                {subtext}
                            </Typography>
                        </Tooltip>
                    )
                }
            </Grid>

            {isUser(source) && hasTwoFactor && <Icon type="custom" icon="cp-2FA" className={classes.faPadding} />}
        </>
    );
};

const LinkListWithShowMore = (props) => {
    const {
        argument: [list, , , detail = {}, { selectedLevel, selectedConfigType, fid: selectedFacilityId, accountId: selectedAccountId }, status],
        classes, match: { params: { fid: facilityId, accountId: paramsAccountId } = {} } = {}, GridProps,
        component, staticContext, justify, column, to, source, maxVisibleContent = MAX_VISIBLE_CONTENT,
        fullHeight = true, containerGridProps, requireTooltip = true, inline = false, showStatusIcon = false,
        onClickMoreItems, onClickMoreItemsParams = {}, shouldTruncateTitle = false, truncateTitleLength, isLabelClickable = false,
        ...componentProps
    } = props;
    const fid = facilityId || selectedFacilityId;
    const accountId = paramsAccountId || selectedAccountId;
    let valueList = (Array.isArray(list) ? list : [detail.unit]);
    const { id: tenantId = '', status: tenantStatus } = detail || {};
    if (selectedConfigType === CONFIG_LEVEL_TYPE.NORMAL_CONFIG) {
        const { childConfiguration = [] } = detail;
        const selectedConfig = childConfiguration
            .find(({ entityType: childEntityType }) => childEntityType === pascalCaseWithUnderscore(selectedLevel));
        if (selectedConfig) {
            const { value: { taxes = [] } = {} } = selectedConfig;
            valueList = taxes;
        }
    }
    if (valueList === EMPTY_FIELD_TEXT) {
        return (
            <Grid
                container
                className={status === STATUS.Inactive.value ? classes.disabledText : undefined}
            >
                {EMPTY_FIELD_TEXT}
            </Grid>
        );
    }

    let typographyClassName = '';

    if (isLead(source) && tenantStatus === LEAD_STATUS.INACTIVE) {
        typographyClassName = classes.disabledText;
    }

    const contentList = [...valueList];
    const initialListContentsToDisplay = contentList.splice(0, maxVisibleContent);

    const getListContents = listContentsToDisplay => (
        <Grid container direction="column" {...GridProps}>
            {
                listContentsToDisplay.map((content, index) => {
                    const { id, label, value: { description = '' } = {}, status: contentStatus } = content || {};
                    const Container = component || Link;
                    const title = typeof content === 'string' ? content : (label || description);
                    let containerProps = {};
                    let link;
                    if (!component) {
                        switch (column) {
                            case SOURCE.unit.value:
                                link = buildUrl('unitEdit', { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId);
                                break;
                            case SOURCE.ledger.value:
                                link = buildUrl('ledgerReview', { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId);
                                break;
                            default:
                                link = buildUrl('tenantEdit', { fid, id: tenantId }, DASHBOARD_TYPE.EXTERNAL, accountId);
                        }
                        if (to) {
                            link = `${to}/${id}`;
                        }
                        containerProps = {
                            to: link,
                            classes: { link: classes.maxWidth },
                            LabelWithIconProps: { classes: { label: classes.maxWidth } },
                        };
                    }

                    const getLabel = isTooltip => (
                        <Typography
                            component="div"
                            variant="body1"
                            noWrap
                            className={isTooltip && typographyClassName}
                            disabled={!link && status === STATUS.Inactive.value}
                        >
                            {showStatusIcon && <span className={clsx(classes.dot, contentStatus === STATUS.Active.value ? classes.active : classes.inactive)} />}
                            {shouldTruncateTitle ? getTruncatedTitle(title, shouldTruncateTitle, truncateTitleLength) : title}
                        </Typography>
                    );
                    const getLabelWithTooltip = isTooltip => (
                        <Tooltip
                            placement="bottom-end"
                            event="onClick"
                            title={title || ''}
                            {...(onClickMoreItems && { onOpen: e => onClickMoreItems(e, onClickMoreItemsParams) })}
                        >
                            {getLabel(isTooltip)}
                        </Tooltip>
                    );
                    return requireTooltip
                        ? (
                            <Tooltip
                                title={title || ''}
                                disableHoverListener={!link && status === STATUS.Inactive.value}
                            >
                                <Container key={id || content || index} {...containerProps} {...componentProps}>
                                    {isLabelClickable ? getLabelWithTooltip(true) : getLabel(true)}
                                </Container>
                            </Tooltip>
                        )
                        : (
                            <Container key={id || content || index} {...containerProps} {...componentProps}>
                                {getLabel()}
                            </Container>
                        );
                })
            }
        </Grid>
    );

    return (
        <Grid
            item
            container
            alignItems="center"
            justify={justify}
            className={clsx({ [classes.fullHeight]: fullHeight },
                { [classes.inline]: inline })}
            {...containerGridProps}
        >
            {
                initialListContentsToDisplay && initialListContentsToDisplay.length > 0
                && getListContents(initialListContentsToDisplay)
            }
            {
                contentList.length > 0
                && (
                    <Tooltip
                        placement="bottom-end"
                        event="onClick"
                        title={getListContents(contentList)}
                        {...(onClickMoreItems && { onOpen: e => onClickMoreItems(e, onClickMoreItemsParams) })}
                    >
                        <Typography
                            variant="body2"
                            color="primary"
                            className={clsx(typographyClassName || classes.moreItems)}
                        >
                            {`+${contentList.length} more`}
                        </Typography>
                    </Tooltip>
                )
            }
        </Grid>
    );
};

const Balance = (props) => {
    const { argument: [totalBalance, , , { isDelinquent = false } = {}, , status], classes, source } = props;
    let balance = parseFloat(totalBalance);
    balance = Number.isNaN(balance) ? NIL_BALANCE : `$${getFormattedAmount(balance)}`;
    if (source === SOURCE.tenant.value && isDelinquent) {
        const chipData = {
            color: 'error',
            size: 'small',
            label: balance,
            value: balance,
        };
        return (
            <Tooltip title={localisable.delinquent}>
                <Chip data={chipData} />
            </Tooltip>
        );
    }
    return (
        <Typography className={classes.balance} disabled={status === STATUS.Inactive.value}>
            {balance}
        </Typography>
    );
};

const Ledger = props => <LinkListWithShowMore {...props} column="ledger" />;
const LedgerGrid = props => <LinkListWithShowMore {...props} column="ledger" component={Grid} />;
const Unit = props => <LinkListWithShowMore {...props} column="unit" />;
const Tenant = props => <LinkListWithShowMore {...props} column="tenant" />;
const GridListWithShowMore = props => <LinkListWithShowMore {...props} component={Grid} />;
const UnitGrid = props => <LinkListWithShowMore {...props} column="unit" component={Grid} />;


const DelinquencyInlineRows = ({
    argument, field, component: Component,
    getComponentProps, ...props
}) => {
    const { ledger = [] } = argument[3];

    if (ledger && ledger.length) {
        const list = ledger.map((eachLedger = {}) => {
            const { id } = eachLedger;
            const updatedArgument = [...argument];
            let value;

            if (field) {
                value = eachLedger[field];
                updatedArgument[0] = value;
            }
            const componentProps = getComponentProps ? getComponentProps(value) : {};

            return ({
                id,
                label: (
                    <Grid item>
                        <Component
                            argument={updatedArgument}
                            {...componentProps}
                            {...props}
                        />
                    </Grid>
                ),
            });
        });

        const updatedArgument = [...argument];
        updatedArgument[0] = list;

        return (
            <GridListWithShowMore
                item
                fullHeight={false}
                maxVisibleContent={3}
                requireTooltip={false}
                GridProps={{ container: true, spacing: 2 }}
                argument={updatedArgument}
                {...props}
            />
        );
    }

    return EMPTY_FIELD_TEXT;
};

const DelinquencyLedgerBalanceFormatter = props => (
    <DelinquencyInlineRows
        requireTooltip={false}
        field="balance"
        component={Balance}
        containerGridProps={{ justify: 'flex-end' }}
        {...props}
    />
);

const DelinquencyTrackingLedgerBalanceFormatter = props => (
    <DelinquencyInlineRows
        requireTooltip={false}
        field="balance"
        component={Balance}
        containerGridProps={{ justify: 'flex-start' }}
        {...props}
    />
);


const DelinquencyUnitListFormatter = (props) => {
    const getComponentProps = () => ({
        maxVisibleContent: 1,
        requireTooltip: false,
        fullHeight: false,
    });
    const componentGridProps = useConstructor(() => ({
        direction: 'column',
        alignItems: 'flex-start',
    }));


    return (
        <DelinquencyInlineRows
            field="unitList"
            getComponentProps={getComponentProps}
            component={GridListWithShowMore}
            containerGridProps={componentGridProps}
            {...props}
        />
    );
};

const DelinquencyLedgerFormatter = props => (
    <GridListWithShowMore
        item
        fullHeight={false}
        maxVisibleContent={3}
        GridProps={{ container: true, spacing: 2 }}
        {...props}
    />
);

const DelinquencyTenantNameFormatter = props => (
    <LabelFormatter
        enablePointerEventsWithoutOnClick
        {...props}
    />
);

const NameFormatter = (props) => {
    const {
        argument: [name, , , , , status], source, match: { params: { fid, accountId } },
        component, additionalComponentProps = {}, classes = {},
    } = props;
    const id = name.id || '';
    const { firstName, lastName, businessName, suffix, salutation, preferredName } = name.name || name || {};
    const to = isUnit(source) ? buildUrl('tenantView', { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId) : '';
    const Component = component || Link;
    let componentProps = component ? {} : { to };
    componentProps = {
        ...componentProps,
        ...additionalComponentProps,
    };
    return (
        name === EMPTY_FIELD_TEXT
            ? (<Grid className={status === STATUS.Inactive.value ? classes.disabledText : undefined}>{name}</Grid>)
            : (
                <Component
                    {...componentProps}
                >
                    <Tooltip
                        title={getName(name.name || name)}
                    >
                        <Name
                            firstName={firstName}
                            lastName={lastName}
                            businessName={businessName}
                            suffix={suffix}
                            salutation={salutation}
                            preferredName={preferredName}
                        />
                    </Tooltip>
                </Component>
            )
    );
};

const NameFormatterGrid = (props = {}) => {
    const { classes } = props;
    return (
        <NameFormatter
            {...props}
            component={Grid}
            classes={classes}
            additionalComponentProps={{ className: classes.overflowHidden }} // To show ellipsis when it overflows the column width
        />
    );
};

const Address = (props) => {
    const { argument: [address, , , rowData, , status], classes } = props;
    const {
        line1: addressline1 = '', line2: addressline2 = '',
        city: city = '', state: state = '', country: country = '',
    } = rowData.address || address || {};
    const customAddress = `${addressline1} ${addressline2} ${city} ${state} ${country}`;
    return (
        address === EMPTY_FIELD_TEXT
            ? (<Grid className={status === STATUS.Inactive.value ? classes.disabledText : undefined}>{address}</Grid>)
            : (
                <Tooltip
                    title={customAddress}
                    className={classes.cursorDefault}
                    disableHoverListener={status === STATUS.Inactive.value}
                >
                    <Typography
                        className={`${Theme.globalClasses.multilineEllipsis.className} ${classes.cursorDefault}`}
                        display="inline"
                        variant="body1"
                        disabled={status === STATUS.Inactive.value}
                    >
                        {customAddress}
                    </Typography>
                </Tooltip>
            ));
};

const ContactDetails = (props) => {
    const {
        argument: [, , , {
            phone: { primaryNumber: phone = '' } = {},
            email: { emailAddress: email = '' } = {},
        } = {}, , status],
    } = props;
    return (
        phone || email
            ? (
                <Tooltip
                    title={`${phone} ${(phone && email) ? localisable.separator : ''} ${email}`}
                    disableHoverListener={status === STATUS.Inactive.value}
                >
                    <Typography variant="body2" noWrap display="inline" disabled={status === STATUS.Inactive.value}>
                        {phone}
                        {phone && email && localisable.separator}
                        {email && email}
                    </Typography>
                </Tooltip>
            )
            : null
    );
};


const DateFormatter = (props) => {
    const { argument: [date, column, , { status } = {}], format = DATE_FORMAT, source, classes } = props;
    let typographyClassName = '';
    let typographyProps = {
        variant: 'body2',
        fontFamily: 'Open Sans',
    };
    let removeTimeZoneAwareness = false;
    if (isLead(source) && status === LEAD_STATUS.INACTIVE) typographyClassName = classes.disabledText;
    if (isLead(source) && status === LEAD_STATUS.CONVERTED && column === 5) return (<></>);
    if (isRetailSaleReturns(source)) {
        typographyProps = {};
        removeTimeZoneAwareness = true;
    }
    if (isManualLateEvent(source)) removeTimeZoneAwareness = true;
    return (
        <Typography
            {...typographyProps}
            className={typographyClassName}
            disabled={status === STATUS.Inactive.value}
        >
            {date === EMPTY_FIELD_TEXT ? date : dateFormatter(date, format, removeTimeZoneAwareness)}
        </Typography>
    );
};

const DatetimeFormatter = props => <DateFormatter format={DATETIME_FORMAT} {...props} />;
const FullDatetimeFormatter = props => <DateFormatter format={FULL_DATE_TIME_FORMAT} {...props} />;

const Ellipsis = (props) => {
    const { argument: [comment, , , , , status], classes } = props;
    return (
        comment === EMPTY_FIELD_TEXT || comment === ''
            ? (
                <Grid className={status === STATUS.Inactive.value ? classes.disabledText : undefined}>
                    {EMPTY_FIELD_TEXT}
                </Grid>
            )
            : (
                <Tooltip
                    title={comment}
                    disableHoverListener={status === STATUS.Inactive.value}
                >
                    <Typography
                        noWrap
                        className={classes.cursorPointer}
                        disabled={status === STATUS.Inactive.value}
                    >
                        {comment}
                    </Typography>
                </Tooltip>
            )
    );
};

const isFeatureDisabled = (source, feature, detail) => {
    const { unitList, rentalStatus, availabilityStatus, status } = detail;
    if (source === SOURCE.tenant.value && feature === FEATURE.moveout.value && unitList && unitList.length) {
        return false;
    }
    if (source === SOURCE.tenant.value && feature === FEATURE.movein.value
        && status === STATUS.Active.value) {
        return false;
    }
    if (source === SOURCE.unit.value && feature === FEATURE.movein.value
        && rentalStatus === RENTAL_STATUS.Vacant.value
        && availabilityStatus !== VACANT_AVAILABILITY_STATUS.Unavailable.value
        && status === STATUS.Active.value) {
        return false;
    }
    if (source === SOURCE.unit.value && (feature === FEATURE.moveout.value || feature === FEATURE.transfer.value)
        && rentalStatus === RENTAL_STATUS.Rented.value) {
        return false;
    }
    if (source === SOURCE.unit.value && feature === FEATURE.payment.value
        && rentalStatus === RENTAL_STATUS.Rented.value) {
        return false;
    }
    if (source === SOURCE.tenant.value && feature === FEATURE.payment.value && status === STATUS.Active.value) {
        return false;
    }
    return true;
};

const getActionConfig = ({
    argument: [, , , detail = {}, { openForm, selectedEntity, selectedLevel, formName, selectedConfigType, isEditDisabled } = {}],
    source, isMoveInVisible, isMoveOutVisible, isPaymentVisible, isPromoPlanVisible,
    history, match: { params: { fid, accountId }, path = '' },
}, paymentToggler) => {
    const isMoveInDisabled = isFeatureDisabled(source, FEATURE.movein.value, detail);
    const isMoveOutDisabled = isFeatureDisabled(source, FEATURE.moveout.value, detail);
    const isPaymentDisabled = isFeatureDisabled(source, FEATURE.payment.value, detail);
    const isTransferDisabled = isFeatureDisabled(source, FEATURE.transfer.value, detail);
    const { deviceInfo: { isDesktop } } = window;

    const onClick = feature => () => {
        let targetId;
        const { id } = detail;
        targetId = id;
        if (selectedConfigType === CONFIG_LEVEL_TYPE.NORMAL_CONFIG) {
            const { childConfiguration = [] } = detail;
            const selectedConfig = childConfiguration
                .find(({ entityType: childEntityType }) => childEntityType === pascalCaseWithUnderscore(selectedLevel));
            if (selectedConfig) {
                const { id: childId } = selectedConfig;
                targetId = childId;
            }
        }
        let url;
        let state;
        if (typeof feature === 'function') {
            feature('edit', id);
        } else {
            if (path.includes(ROUTE.SETTINGS_MAIN) || path.includes(ROUTE.SETTINGS_LIVE) || path.includes(ROUTE.SETTINGS_PULLED)) {
                if (belongsToAnyOfGivenSources(source, [SOURCE.configuration.value, SOURCE.promoPlan.value])) {
                    if (feature === FORM_MODE.COPY) {
                        url = buildUrl('createNormalConfigForm', { selectedLevel, selectedEntity, formName }, DASHBOARD_TYPE.SETTINGS, accountId);
                        state = {
                            detail,
                            source,
                        };
                    } else {
                        url = selectedConfigType === CONFIG_LEVEL_TYPE.NORMAL_CONFIG
                            ? buildUrl('editNormalConfigForm', { selectedLevel, selectedEntity, formName, targetId }, DASHBOARD_TYPE.SETTINGS, accountId)
                            : buildUrl('editSystemConfigForm', { selectedLevel, formName, targetId }, DASHBOARD_TYPE.SETTINGS, accountId);
                    }
                } else {
                    url = buildUrl('settingsEdit', { source, id }, DASHBOARD_TYPE.SETTINGS, accountId);
                }
            } else if (fid) {
                if (feature) {
                    url = buildUrl('facilityFeatures', { fid, feature }, DASHBOARD_TYPE.EXTERNAL, accountId);
                    state = {
                        activeStep: 0,
                        detail,
                        source,
                    }; // TODO: Remove after Move Out Refactor is done
                } else {
                    url = buildUrl(`${source}Edit`, { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId);
                }
            }
            history.push(url, state);
        }
    };

    return [
        {
            disabled: isMoveInDisabled,
            isVisible: isMoveInVisible && !isVendor(source) && !isInventory(source),
            title: localisable.moveIn,
            icon: 'cp-move-in',
            action: { onClick: onClick(FEATURE.movein.value) },
        },
        {
            disabled: isMoveOutDisabled,
            isVisible: isMoveOutVisible && !isVendor(source) && !isInventory(source),
            title: localisable.moveOut,
            icon: 'cp-move-out',
            action: { onClick: onClick(FEATURE.moveout.value) },
        },
        {
            disabled: isPaymentDisabled,
            isVisible: isPaymentVisible && !isVendor(source) && !isInventory(source),
            title: localisable.makePayment,
            icon: 'cp-payment',
            action: { onClick: onClick(isDesktop ? paymentToggler : FEATURE.payment.value) },
        },
        {
            disabled: isEditDisabled,
            title: `${localisable.edit} ${source}`,
            icon: 'cp-edit',
            action: { onClick: onClick(openForm) },
        },
        {
            title: `${localisable.clone} ${source}`,
            isVisible: isPromoPlanVisible,
            icon: 'cp-copy',
            action: { onClick: onClick(FORM_MODE.COPY) },
        },
        {
            disabled: isTransferDisabled,
            isVisible: isMoveInVisible && isUnit(source),
            title: localisable.transfer,
            icon: 'cp-transfer',
            action: { onClick: onClick(FEATURE.transfer.value) },
        },
    ];
};

const Action = (props) => {
    const { source, argument: [, , , tenantDetails] } = props;
    const [showPayment, setShowPayment] = useState(false);
    const paymentToggler = () => setShowPayment(prevState => !prevState);
    const { deviceInfo: { isDesktop } } = window;
    let PaymentComponent = null;
    // TODO: find a better way to handle this using normal import
    if (!belongsToAnyOfGivenSources(source, [INTERNAL_SOURCE.accountInt.value,
        INTERNAL_SOURCE.facilityInt.value, INTERNAL_SOURCE.userInt.value])) {
        // eslint-disable-next-line global-require
        PaymentComponent = require('Commons/components/business/payment/Payment').Payment;
    }
    const actions = getActionConfig(props, paymentToggler)
        .map(({ isVisible = true, icon, disabled, title, action }) => (isVisible ? ({
            component: (
                <Tooltip
                    title={title}
                >
                    <Button variant="icon" iconType="custom" icon={icon} color="primary" disabled={disabled} />
                </Tooltip>
            ),
            disabled,
            action,
        }) : {}));
    return (
        <>
            <Actions
                config={actions}
                justify={belongsToAnyOfGivenSources(source, [SOURCE.vendor.value,
                    SOURCE.inventoryList.value, SOURCE.promoPlan.value]) ? 'center' : undefined}
                alignItems={belongsToAnyOfGivenSources(source, [SOURCE.promoPlan.value]) ? 'center' : undefined}
            />
            {isDesktop && showPayment && PaymentComponent && (
                <PaymentComponent
                    prefilled
                    open={showPayment}
                    onClose={paymentToggler}
                    detail={tenantDetails}
                />
            )}
        </>
    );
};

const TooltipTypography = ({
    title,
    fontStyle,
    typographyClassName,
    variant,
    tooltipTile,
    disabled = false,
}) => (title ? (
    <Tooltip
        title={tooltipTile || title}
        disableHoverListener={disabled}
    >
        <Typography
            noWrap
            fontStyle={fontStyle}
            className={typographyClassName}
            disabled={disabled}
            variant={variant}
        >
            {title}
        </Typography>
    </Tooltip>
) : null);


const AlternateContact = (props) => {
    const {
        argument: [{
            alternateContact: {
                name: { firstName, lastName } = {},
                phone: { cellPhone = '' } = {},
            } = {},
        } = {}, , , , , status],
    } = props;
    const hasName = firstName || lastName;
    const alternateContactNumber = cellPhone ? phoneFormatter(getPlainPhoneNumber(cellPhone)) : cellPhone;
    return (
        (hasName || alternateContactNumber) ? (
            <Grid container direction="column" disabled={status === STATUS.Inactive.value}>
                {hasName ? (
                    <Tooltip
                        title={getName({
                            firstName,
                            lastName,
                        })}
                        disableHoverListener={status === STATUS.Inactive.value}
                    >
                        <Name firstName={firstName} lastName={lastName} disabled={status === STATUS.Inactive.value} />
                    </Tooltip>
                ) : null}
                <TooltipTypography
                    title={clsx(alternateContactNumber && `(${alternateContactNumber})`)}
                    fontStyle="italic"
                    disabled={status === STATUS.Inactive.value}
                />
            </Grid>
        ) : (
            <Typography disabled={status === STATUS.Inactive.value}>
                {EMPTY_FIELD_TEXT}
            </Typography>
        )
    );
};

const Contact = (props) => {
    const {
        argument: [, , , {
            phone: { primaryNumber: phone = '' } = {},
            email: { emailAddress: email = '' } = {},
            status,
        } = {}], source, classes,
    } = props;

    let typographyClassName = '';
    if (isLead(source) && status === LEAD_STATUS.INACTIVE) {
        typographyClassName = classes.disabledText;
    }

    return (
        (email || phone) ? (
            <Grid container direction="column">
                <TooltipTypography
                    title={email}
                    typographyClassName={typographyClassName}
                    disabled={status === STATUS.Inactive.value}
                />
                <TooltipTypography
                    title={phoneFormatter(phone)}
                    typographyClassName={typographyClassName}
                    disabled={status === STATUS.Inactive.value}
                />
            </Grid>
        ) : (
            <Typography disabled={status === STATUS.Inactive.value}>
                {EMPTY_FIELD_TEXT}
            </Typography>
        )
    );
};

const Rented = (props) => {
    const { argument: [rentalStatus, , , { availabilityStatus, leaseSign: { status: leaseStatus } = {} } = {}] } = props;
    const rentalStatusToShow = leaseStatus === LEASE_STATUS.Pending.value ? localisable.eSignPending : rentalStatus;
    const status = availabilityStatus === 'Unavailable' ? availabilityStatus : rentalStatusToShow;
    const chipData = {
        color: `rentalStatus${status}`,
        size: 'small',
        label: status,
        value: status,
    };
    return <Chip data={chipData} />;
};

const Status = (props) => {
    const { argument: [status], source, classes } = props;
    let chipStatus = status;
    let statusLabel = status;
    let statusColor = status;
    let statusColorPrefix = 'status';
    let chipClasses = {};

    switch (source) {
        case SOURCE.waitingList.value:
            statusColor = status === WAITING_STATUS.Rejected.value ? 'Error' : status;
            break;
        case SOURCE.reservation.value:
            statusLabel = RESERVATION_STATUS[status];
            statusColor = status === RESERVATION_STATUS.MovedIn.replace(/-/g, '') ? STATUS.Active.value : status;
            break;
        case SOURCE.maintenanceEvent.value:
            statusLabel = MAINTENANCE_EVENT_STATUS[status];
            statusColorPrefix = 'maintenanceeventstatus';
            break;
        case SOURCE.lead.value:
            statusLabel = status === LEAD_STATUS.ACTIVE ? localisable.leadActiveStatusChipLabel : status;
            statusColorPrefix = 'leadstatus';
            break;
        case SOURCE.estimate.value:
            statusLabel = ESTIMATE_STATUS[status].label;
            statusColorPrefix = 'estimatestatus';
            break;
        case SOURCE.rateHistory.value:
            /* If a rent adjustment is stopped, we need to show Waived in Rate history in FE. In BE, we are storing it as
            Stopped to keep it in sync with rent adjustment, and modifying in FE to show Waived instead of Stopped */
            if (status === RATE_HISTORY_STATUS.Stopped.label) chipStatus = RATE_HISTORY_STATUS.Waived.value;
            statusColor = chipStatus;
            statusLabel = RATE_HISTORY_STATUS[chipStatus].label;
            statusColorPrefix = 'ratehistorystatus';
            chipClasses = { root: classes.chipFixedWidth };
            break;
        case SOURCE.delinquency.value:
            statusLabel = DELINQUENCY_STATUS[status].label;
            statusColorPrefix = 'delinquencystatus';
            break;
        case SOURCE.rentAdjustment.value:
            statusLabel = RENT_ADJUSTMENT_STATUS_LIST[status].label;
            if (status === RENT_ADJUSTMENT_STATUS_LIST.Pending.value) statusLabel = localisable.scheduled;
            statusColorPrefix = 'rentAdjustment';
            break;
        default:
            break;
    }

    const chipData = {
        color: `${statusColorPrefix}${statusColor.replace(/_/g, '')}`,
        size: 'small',
        label: statusLabel,
        value: chipStatus,
    };

    return (
        <Chip data={chipData} classes={chipClasses} />
    );
};

// To do: Modify the data in delinquencyPostProcessor.js in such a way that one result shall have one ledger only
const DelinquencyStatusFormatter = props => (
    <DelinquencyInlineRows
        requireTooltip={false}
        field="delinquencyStatus"
        component={Status}
        {...props}
    />
);

const OverlockStatus = (props) => {
    const { argument: [overlockStatus], classes } = props;

    const overlockStatusConfig = {
        [OVERLOCK_STATUS.Unlocked.value]: 'statusActive',
        [OVERLOCK_STATUS.Unlock_Pending.value]: 'statusActive',
        [OVERLOCK_STATUS.Overlocked.value]: 'statusError',
        [OVERLOCK_STATUS.Overlock_Pending.value]: 'statusLocked',
    };

    const chipData = {
        color: overlockStatusConfig[overlockStatus],
        size: 'small',
        label: OVERLOCK_STATUS[overlockStatus].label,
        value: overlockStatus,
    };

    return (
        <Chip data={chipData} classes={{ labelText: classes.overlockStatusChip }} />
    );
};

const PromoPlanConfigTextFormatter = (props) => {
    const {
        argument: [description = {}, , , { id, value: { bills = [] } = {} } = {},
            { selectedLevel, selectedEntity, formName, isEditDisabled }], source, ...otherProps
    } = props;
    const { match: { params: { accountId } } } = otherProps;
    const promoPlanDescription = bills.map(b => b.description || '')
        .filter(v => v)
        .join(' || ');
    let path = buildUrl('editNormalConfigForm', { selectedLevel, selectedEntity, formName, targetId: id }, DASHBOARD_TYPE.SETTINGS, accountId);
    if (isPromoPlanTemplate(source)) {
        path = { pathname: path, state: { isTemplateViewedOrCloned: true } };
    }
    return LabelFormatter({
        argument: [description],
        subtext: promoPlanDescription,
        to: path,
        isEditDisabled,
        ...otherProps,
    });
};

const ConfigDescription = (props) => {
    const {
        argument: [description = {}, , , detail = {},
            { selectedLevel, selectedEntity, formName, selectedConfigType, isEditDisabled }], source, ...otherProps
    } = props;
    const { match: { params: { accountId } } } = otherProps;
    let targetId;
    const { id } = detail;
    targetId = id;
    if (selectedConfigType === CONFIG_LEVEL_TYPE.NORMAL_CONFIG) {
        const { childConfiguration = [] } = detail;
        const selectedConfig = childConfiguration.find(({ entityType: childEntityType }) => childEntityType === pascalCaseWithUnderscore(selectedLevel));
        if (selectedConfig) {
            const { id: childId } = selectedConfig;
            targetId = childId;
        }
    }
    const path = selectedConfigType === CONFIG_LEVEL_TYPE.NORMAL_CONFIG
        ? buildUrl('editNormalConfigForm', { selectedLevel, selectedEntity, formName, targetId }, DASHBOARD_TYPE.SETTINGS, accountId)
        : buildUrl('editSystemConfigForm', { selectedLevel, formName, targetId }, DASHBOARD_TYPE.SETTINGS, accountId);
    return isEditDisabled ? description
        : (LabelFormatter({
            argument: [description],
            to: path,
            ...otherProps,
        }));
};

const violationChargeCategoryFormatter = () => localisable.violations;

const TenantNameFormatter = (props) => {
    const {
        argument,
        argument: [name, , , rowData = {}, { fid: selectedFacilityId, accountId: selectedAccountId }],
        match: { params: { fid: facilityId, accountId: paramsAccountId } },
    } = props;
    const fid = facilityId || selectedFacilityId;
    const accountId = paramsAccountId || selectedAccountId;
    const { tenant: { id: tenantId } = {} } = rowData;
    const argumentCopy = [...argument];

    argumentCopy[3] = { ...rowData, name };
    const to = buildUrl('tenantView', { fid, id: tenantId }, DASHBOARD_TYPE.EXTERNAL, accountId);

    return <LabelFormatter {...props} argument={argumentCopy} to={to} />;
};


const unitTypeFormatter = (props) => {
    const { argument: [unitType, , , , , status] } = props;
    const { description } = unitType;
    return (
        unitType
            ? (
                <Typography disabled={status === STATUS.Inactive.value}>
                    {description}
                </Typography>
            )
            : EMPTY_FIELD_TEXT
    );
};

const LinkWithToolTip = (props) => {
    const { argument: [entity], match: { params: { fid, accountId } }, column, component } = props;
    const { id, value: { description = '' } = {}, label = '', name: { firstName = '', lastName = '' } = {} } = entity;
    const { argument: [, , , { tenant: { id: tenantId = '' } = {} } = {}] } = props;
    const { id: entityId } = entity;
    let descriptionValue = label;
    let to;
    let target;

    switch (column) {
        case 'tenant':
            descriptionValue = getName({ firstName, lastName });
            to = buildUrl('tenantView', { fid, id: tenantId }, DASHBOARD_TYPE.EXTERNAL, accountId);
            break;
        case 'ledger':
            to = buildUrl('ledgerReview', { fid, id: entityId }, DASHBOARD_TYPE.EXTERNAL, accountId);
            break;
        case 'unitType':
            descriptionValue = description;
            to = buildUrl('configSelection', { fid, configType: 'unit-type', id }, DASHBOARD_TYPE.SETTINGS, accountId);
            target = '__blank';
            break;
        default:
            to = buildUrl('unitEdit', { fid, id }, DASHBOARD_TYPE.EXTERNAL, accountId);
    }

    const Component = component || Link;
    const linkProps = component ? {} : {
        to,
        target,
    };

    return (
        <Component {...linkProps}>
            <Tooltip
                title={descriptionValue}
            >
                <Typography>{descriptionValue}</Typography>
            </Tooltip>
        </Component>
    );
};

const ConfigTextFormatter = (props) => {
    const { argument: [label = '', , , , , status] } = props;
    const value = PAYMENT_METHOD_TYPE[label] ? PAYMENT_METHOD_TYPE[label].label : label;
    return (
        <Grid>
            <Typography disabled={status === STATUS.Inactive.value}>
                {value === MODIFIER_TYPE.No_Modifier.value ? '--' : value}
            </Typography>
        </Grid>
    );
};

const ListFormatter = (props) => {
    const {
        argument: [, , , , { selectedLevel, selectedEntity, subFormName, selectedConfigType } = {}],
        match: { params: { accountId } },
    } = props;
    const to = selectedConfigType === CONFIG_LEVEL_TYPE.NORMAL_CONFIG
        ? buildUrl('createNormalConfigForm', { selectedLevel, selectedEntity, formName: subFormName }, DASHBOARD_TYPE.SETTINGS, accountId)
        : buildUrl('createSystemConfigForm', { selectedLevel, formName: subFormName }, DASHBOARD_TYPE.SETTINGS, accountId);
    return <LinkListWithShowMore to={to} {...props} />;
};

const RowSelector = (props) => {
    const { classes, argument: [onSelect, , , data] } = props;
    const { checked = false } = data;
    return (
        <Checkbox
            color="primary"
            disableRipple
            onChange={!checked ? (() => onSelect(data)) : null}
            checked={checked}
            disabled={checked}
            className={classes.checkbox}
        />
    );
};

const AppliedRole = (props) => {
    const { classes, argument: [{ id: roleId, name }], match: { params: { accountId } } } = props;
    const to = buildUrl('userRoleEdit', { selectedId: roleId }, DASHBOARD_TYPE.SETTINGS, accountId);
    return (
        <Link
            to={to}
            className={classes.label}
        >
            <Grid>{name}</Grid>
        </Link>
    );
};

const BoolRepresentor = (props) => {
    const { argument: [isTrue], classes, source } = props;
    let iconClassName;
    let typographyClassName;
    if (source === SOURCE.rentAdjustment.value) {
        iconClassName = classes.iconColor;
        typographyClassName = classes.recurringEmptyFieldText;
    }
    return isTrue === true
        ? <Grid container justify="flex-start"> <Icon type="custom" icon="cp-check-simple" className={iconClassName} /> </Grid> : <Typography className={typographyClassName}>{EMPTY_FIELD_TEXT}</Typography>;
};

const TooltipFormatter = (props) => {
    const { argument: [title, , , , , status] } = props;
    return <TooltipTypography title={title} disabled={status === STATUS.Inactive.value} />;
};

const PhoneAndFaxFormatter = (props) => {
    const { argument: [, , , { phone, fax }] } = props;
    return (
        <Grid container>
            {fax
                && (
                    <LabelWithIcon addon={{ start: <Icon type="custom" icon="cp-fax" /> }}>
                        <Typography>{phoneFormatter(fax)}</Typography>
                    </LabelWithIcon>
                )
            }
            {phone
                && (
                    <LabelWithIcon addon={{ start: <Icon type="custom" icon="cp-phone" /> }}>
                        <Typography>{phoneFormatter(phone)}</Typography>
                    </LabelWithIcon>
                )
            }
        </Grid>
    );
};

const LedgerLink = props => <LinkWithToolTip {...props} column="ledger" />;
const UnitTypeLink = props => <LinkWithToolTip {...props} column="unitType" />;
const TenantLink = props => <LinkWithToolTip {...props} column="tenant" />;
const UnitTypeGrid = props => <LinkWithToolTip {...props} column="unitType" component={Grid} />;
const GridWithToolTip = props => <LinkWithToolTip {...props} component={Grid} />;

const AvailableQuantityFormatter = (props) => {
    const { argument: [availableQuantity, , , { reorderPoint }, , status], classes } = props;
    if (availableQuantity < 0) {
        const chipData = {
            color: 'error',
            size: 'small',
            label: availableQuantity.toString(),
            value: availableQuantity,
        };
        return <Chip data={chipData} />;
    }
    const color = availableQuantity < reorderPoint ? 'error' : undefined;
    return (
        <Typography
            title={availableQuantity}
            color={color}
            className={classes.availableQuantityColumnValue}
            disabled={status === STATUS.Inactive.value}
        >{availableQuantity}
        </Typography>
    );
};

const EventFormatter = (props) => {
    const {
        argument: [eventType = '', , , {
            value: {
                [eventType.toLowerCase()]: { type: lateOrLockoutType = '' }
                = {},
            } = {},
        } = {}, , status],
    } = props;
    return (
        <Typography disabled={status === STATUS.Inactive.value}>
            {clsx(labelFormatter(lateOrLockoutType), '-', eventType)}
        </Typography>
    );
};

const AmountFormatter = ({ argument: [amount] }) => <Typography>{getFormattedAmount(amount)}</Typography>;

const PhoneFormatter = (props) => {
    const { argument: [phone, , , data = {}] = [], source } = props;
    let phoneNumber = phone;
    if (source === INTERNAL_SOURCE.accountInt.value) {
        const { alternatePhoneNumber = '' } = data;
        phoneNumber = phoneNumber || alternatePhoneNumber;
    }
    return phoneNumber !== (EMPTY_FIELD_TEXT || EMPTY_STRING)
        ? <TooltipTypography title={phoneFormatter(phoneNumber)} />
        : EMPTY_FIELD_TEXT;
};

const FormFieldFormatter = (props) => {
    const {
        argument: [, , rowIndex, { disabled = false } = {}, {
            formProps,
            onChange,
            formFieldInfo: {
                Component = FormTextField,
                name = '',
                onChange: formFieldOnChange,
                valueFormatter,
                ...restFieldProps
            } = {},
        } = {}],
    } = props;
    const fieldName = useMemo(() => (name ? `${rowIndex}.${name}` : `${rowIndex}`), []);
    const { values = {} } = formProps;
    const fieldValue = getValue(values, fieldName);
    return (
        <Component
            name={fieldName}
            disabled={disabled}
            {...onChange && { onChange: (_, value) => onChange(rowIndex, value, formProps) }}
            {...formFieldOnChange && { onChange: (_, value, item) => formFieldOnChange(rowIndex, value, formProps, item) }}
            {...valueFormatter && fieldValue && { value: valueFormatter(fieldValue) }}
            {...restFieldProps}
        />
    );
};

const HasAttachmentFormatter = (props) => {
    const {
        argument: [
            value = [],
        ] = [],
    } = props;
    const statusColorPrefix = 'emailAudit';
    const curLabel = value ? 'Yes' : 'No';
    const chipData = {
        color: `${statusColorPrefix}${curLabel}`,
        size: 'small',
        label: curLabel,
        value,
    };
    return (
        <Chip data={chipData} />
    );
};


// Please add Alphabetically when exporting a new formatter
export {
    Action,
    Address,
    AlternateContact,
    AmountFormatter as Amount,
    AppliedRole,
    AvailableQuantityFormatter,
    Balance,
    BoolRepresentor,
    ConfigDescription,
    ConfigTextFormatter,
    Contact,
    ContactDetails,
    DateFormatter as Date,
    DatetimeFormatter as Datetime,
    DelinquencyLedgerBalanceFormatter as DelinquencyLedgerBalance,
    DelinquencyStatusFormatter as DelinquencyStatus,
    DelinquencyLedgerFormatter as DelinquencyLedger,
    DelinquencyUnitListFormatter as DelinquencyUnitList,
    DelinquencyTenantNameFormatter as DelinquencyTenant,
    DelinquencyTrackingLedgerBalanceFormatter as DelinquencyTrackingLedgerBalance,
    Ellipsis,
    EventFormatter,
    FormFieldFormatter,
    FullDatetimeFormatter as FullDatetime,
    GridListWithShowMore,
    GridWithToolTip,
    LabelFormatter as Label,
    Ledger,
    LedgerGrid,
    LedgerLink,
    LinkListWithShowMore,
    LinkWithToolTip,
    ListFormatter,
    NameFormatter as Name,
    NameFormatterGrid as NameGrid,
    OverlockStatus,
    PhoneAndFaxFormatter,
    PhoneFormatter as Phone,
    PromoPlanConfigTextFormatter,
    Rented,
    RowSelector,
    Status,
    Tenant,
    TenantLink,
    TenantNameFormatter as TenantName,
    TooltipFormatter,
    Unit,
    UnitGrid,
    unitTypeFormatter as UnitType,
    UnitTypeGrid,
    UnitTypeLink,
    TooltipTypography,
    HasAttachmentFormatter,
    violationChargeCategoryFormatter,
};
// Please add Alphabetically when exporting a new formatter
