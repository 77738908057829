import { makeStyles, FormLabel } from 'Generic/componentlibrary/components/Components';
import FormItem from 'Generic/formitem/components/FormItem';
import { clsx } from 'Commons/helpers/utils/clsx';
import labelStyle from '../styles/LabelStyle';

const useStyles = makeStyles(labelStyle);

const Label = ({ FormItemProps, children, className, disabled, ...props }) => {
    const classes = useStyles();
    return (
        <FormItem
            className={classes.labelItem}
            {...FormItemProps}
        >
            <FormLabel
                className={clsx(classes.label, disabled && classes.disabled, className)}
                {...props}
            >
                {children}
            </FormLabel>
        </FormItem>
    );
};

Label.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    FormItemProps: PropTypes.object,
};

Label.defaultProps = { FormItemProps: {} };

export default Label;
