const downloadFile = async (url, fileName) => {
    // different browsers handle download as per their working,
    // if a content type is recognized by the browser it may open it on the page instead of downloading.
    // Thus we are getting a blob and using that to download file
    try {
        const response = await fetch(url, {
            method: 'GET',
            responseType: 'blob', // Set the responseType to 'blob'
        });

        const blob = await response.blob();
        const fileUrl = URL.createObjectURL(blob);

        // Create an invisible link element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;

        // Trigger a click on the link to initiate the download
        document.body.appendChild(link);
        link.click();

        // Remove the link from the DOM
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

export { downloadFile };
