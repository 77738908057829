const ChipStyle = theme => ({
    root: {
        backgroundColor: theme.palette.disabled,
        opacity: 0.6,
        marginLeft: 10,
    },
    rootInModal: {
        paddingTop: 5,
        paddingBottom: 5,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginLeft: 0,
        marginBottom: 10,
        height: 'auto',
    },
    nestedChipRoot: {
        marginRight: 6,
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        height: 24,
        '&:hover, &:focus, &:active': { backgroundColor: theme.palette.common.white },
    },
    nestedChipRootInModal: { marginBottom: 10 },
    label: { paddingLeft: 6, paddingRight: 6 },
    closeIcon: { fontSize: 14, color: theme.palette.common.black },
    deleteIcon: {
        backgroundColor: theme.palette.border,
        width: 20,
        height: 20,
        borderRadius: 10,
        marginLeft: 0,
    },
    nestedChipContainerExpanded: { marginTop: 10 },
    nestedChip: {
        backgroundColor: theme.palette.common.white,
        marginRight: 6,
        height: 24,
        display: 'flex',
        flexWrap: 'noWrap',
        flex: 1,
    },
    iconRoot: {
        marginLeft: 6,
        marginRight: 0,
    },
    leftIcon: {
        backgroundColor: theme.palette.common.white,
        width: 24,
        height: 24,
        minWidth: 24,
        borderRadius: 12,
    },
    title: {
        fontWeight: 600,
        marginRight: 6,
    },
    detail: { marginRight: 10 },
});

export default ChipStyle;
