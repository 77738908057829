const paymentMethodStyle = theme => ({
    autoWidth: { width: 'auto' },
    expiryCvvContainer: { padding: theme.spacing(0, 2) },
    angledLine: {
        margin: theme.spacing(1.5, 1.5, 0, 2),
        height: 32,
        width: 1,
        backgroundColor: theme.palette.common.black,
        transform: 'rotate(25deg)',
    },
    largeFont: {
        ...theme.typography.h6,
        textAlign: 'left',
    },
    placeholderAlign: { '& input::placeholder': { textAlign: 'center' } },
    placeholderFontSize: { '& input::placeholder': { ...theme.typography.h6 } },
    helperText: { fontSize: 12, color: theme.palette.text.primary, width: 'auto' },
    numberFields: {
        '& input': { ...theme.typography.h5, textOverflow: 'initial' },
        '& input::placeholder': { ...theme.typography.h5, textOverflow: 'initial' },
    },
    info: {
        color: 'Red',
        verticalAlign: 'middle',
        paddingRight: theme.spacing(1),
    },
    paymentField: { width: '50%', paddingTop: theme.spacing(2) },
    disabled: { backgroundColor: theme.palette.text.disabled },
    leftAlignText: { textAlign: 'left' },
    error: { color: theme.palette.error.main },
    cvvContainer: { width: 80 },
    cvvError: { textAlign: 'right' },
});
export default paymentMethodStyle;
